import { createSlice } from '@reduxjs/toolkit';
import { pricingList } from 'state/pricings/pricingList/thunk';

interface InitialState {
    lists: any
    searchQuery: string
    loading: boolean
    error: unknown
    sidebarItemOnClick: string
  }
  
  const initialState: InitialState = {
    lists: {
      result: [],
    },
    sidebarItemOnClick: '',
    searchQuery: '',
    loading: false,
    error: null,
  }
  
  const pricingListSlice = createSlice({
    name: 'pricingsList',
    initialState,
    reducers: {
      clear: () => initialState,
      setSearchQuery: (state, action) => {
        const { value } = action.payload
        state.searchQuery = value
      },
      setSidebarOnClick: (state, action) => {
        const { value } = action.payload
        state.sidebarItemOnClick = value
      },
    },
    extraReducers: (builder) => {
      builder.addCase(pricingList.pending, (state) => {
        state.loading = true
        state.error = null
      })
  
      builder.addCase(pricingList.fulfilled, (state, response) => {
        state.lists = response && response.payload
        state.loading = false
      })
  
      builder.addCase(pricingList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    },
  })

  export const actions = {
    ...pricingListSlice.actions,
    pricingList
  }
  
  export const reducer = pricingListSlice.reducer;
  