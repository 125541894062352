import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";
import PricingListParams from "state/pricings/pricingList/dtos/pricingListParamDto";
import qs from "qs";

export const pricingList = createAsyncThunk(
 "costAnalysis/pricingList",
 async (parameterDto: PricingListParams) => {
  return await HttpService.get("CostAnalysis", {
   params: parameterDto,
   paramsSerializer: (params) => {
    return qs.stringify(params);
   },
  });
 }
);
