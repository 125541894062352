import { createSlice } from "@reduxjs/toolkit";

import {
 getList,
 add,
 edit,
 getById,
 predefineDataConfigurations,
 addCustomField,
 editWithoutStaticProduct,
 editCustomField,
 deleteCustomField,
 addNewCostDriver,
 deleteCostDriver,
 getFormulaField,
 getFormulaByCostDriver,
 getAllFormulaCostDriver,
 validateFormula
} from "state/myJourney/thunk";

interface InitialState {
 lists: any;
 journeyName: string;
 levelName: string;
 analysisJourney: string;
 typeActiveTabFromModal: boolean;
 loading: boolean;
 error: unknown;
 whatInformationDoYouHave: string[];
 whatInformationCanYouGain: string[];
 levelStep1: string;
 levelResponse: any;
 responseAdd: any;
 responseAddCustomField: any;
 loadingAddCustomField: boolean;
 loadingDeleteCustomField: boolean;
 predefineDataConfigurationsResponse: any;
 isEditModalJourney: boolean;
 deleteCustomFieldResponse: boolean;
 customFieldResponse: boolean;
 formulaFields: string[];
 costDriverFields: [];
 formulaList:[],
 validate: any,
 valueCostDriver: string;
}

const initialState: InitialState = {
 lists: {
  result: [],
 },
 journeyName: "",
 levelName: "",
 analysisJourney: "",
 typeActiveTabFromModal: true,
 loading: false,
 error: null,
 whatInformationDoYouHave: [],
 whatInformationCanYouGain: [],
 levelStep1: "Does Cost",
 levelResponse: {},
 predefineDataConfigurationsResponse: [],
 responseAdd: null,
 responseAddCustomField: null,
 loadingAddCustomField: false,
 isEditModalJourney: false,
 deleteCustomFieldResponse: false,
 loadingDeleteCustomField: false,
 customFieldResponse: false,
 formulaFields: [],
 costDriverFields: [],
 formulaList:[],
 validate: {},
 valueCostDriver: ""
};

const myJourneySlice = createSlice({
 name: "myJourney",
 initialState,
 reducers: {
  clear: () => initialState,
  setJourneyName: (state, action) => {
   state.journeyName = action.payload;
  },
  setResetValidation : (state, action) => {
    state.validate = action.payload
  },
  setResetCostDriverFields: (state, action) => {
    state.costDriverFields = action.payload
  },
  setLevelName: (state, action) => {
   state.levelName = action.payload;
  },
  setChooseAnalysis: (state, action) => {
   state.analysisJourney = action.payload;
  },
  setTypeActiveTabFromModal: (state, action) => {
   state.typeActiveTabFromModal = action.payload;
  },
  setWhatInformationDoYouHave: (state, action) => {
   state.whatInformationDoYouHave.push(action.payload);
  },
  removeAllWhatInformationDoYouHave: (state, action) => {
   state.whatInformationDoYouHave = [];
  },
  removeArrayWhatInformationDoYouHave: (state, action) => {
   function removeItem(array: any, item: string) {
    for (var i in array) {
     if (array[i] == item) {
      array.splice(i, 1);
      break;
     }
    }
   }
   removeItem(state.whatInformationDoYouHave, action.payload);
  },
  setWhatInformationCanYouGain: (state, action) => {
   state.whatInformationCanYouGain.push(action.payload);
  },
  removeAllWhatInformationCanYouGain: (state, action) => {
   state.whatInformationCanYouGain = [];
  },
  removeArrayWhatInformationCanYouGain: (state, action) => {
   function removeItem(array: any, item: string) {
    for (var i in array) {
     if (array[i] == item) {
      array.splice(i, 1);
      break;
     }
    }
   }
   removeItem(state.whatInformationCanYouGain, action.payload);
  },
  setJourneyLevel: (state, action) => {
   state.levelStep1 = action.payload;
  },
  setIsEditModalJourney: (state, action) => {
   state.isEditModalJourney = action.payload;
  },
  setCustomFieldResponse: (state, action) => {
   state.customFieldResponse = action.payload;
  },
  setValueCostDriver: (state, action) => {
    state.valueCostDriver = action.payload
  }
 },
 extraReducers: (builder) => {
  builder.addCase(getList.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getList.fulfilled, (state, action) => {
   state.lists = action.payload;
   state.loading = false;
  });

  builder.addCase(getList.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(add.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(add.fulfilled, (state, action) => {
   state.responseAdd = action.payload;
   state.loading = false;
  });

  builder.addCase(add.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(edit.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(edit.fulfilled, (state, action) => {
   state.levelResponse = action.payload;
   state.loading = false;
  });

  builder.addCase(edit.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(editWithoutStaticProduct.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(editWithoutStaticProduct.fulfilled, (state, action) => {
   state.responseAdd = action.payload;
   state.loading = false;
  });

  builder.addCase(editWithoutStaticProduct.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(addNewCostDriver.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(addNewCostDriver.fulfilled, (state, action) => {
   state.responseAdd = action.payload;
   state.loading = false;
  });

  builder.addCase(addNewCostDriver.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(getById.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getById.fulfilled, (state, action) => {
   state.levelResponse = action.payload;
   state.loading = false;
  });

  builder.addCase(getById.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(predefineDataConfigurations.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(predefineDataConfigurations.fulfilled, (state, action) => {
   state.predefineDataConfigurationsResponse = action.payload;
   state.loading = false;
  });

  builder.addCase(predefineDataConfigurations.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(addCustomField.pending, (state) => {
   state.loadingAddCustomField = true;
   state.error = null;
  });

  builder.addCase(addCustomField.fulfilled, (state, action) => {
   state.responseAddCustomField = action.payload;
   state.loadingAddCustomField = false;
  });

  builder.addCase(addCustomField.rejected, (state, action) => {
   state.loadingAddCustomField = false;
   state.error = action.payload;
  });

  builder.addCase(editCustomField.pending, (state) => {
   state.loadingAddCustomField = true;
   state.error = null;
  });

  builder.addCase(editCustomField.fulfilled, (state, action) => {
   state.responseAddCustomField = action.payload;
   state.loadingAddCustomField = false;
  });

  builder.addCase(editCustomField.rejected, (state, action) => {
   state.loadingAddCustomField = false;
   state.error = action.payload;
  });

  builder.addCase(deleteCustomField.pending, (state) => {
   state.loadingAddCustomField = true;
   state.error = null;
  });

  builder.addCase(deleteCustomField.fulfilled, (state, action) => {
   state.deleteCustomFieldResponse = action.payload.success;
   state.loadingAddCustomField = false;
  });

  builder.addCase(deleteCustomField.rejected, (state, action) => {
   state.loadingAddCustomField = false;
   state.error = action.payload;
  });

  builder.addCase(deleteCostDriver.pending, (state) => {
   state.loadingAddCustomField = true;
   state.error = null;
  });

  builder.addCase(deleteCostDriver.fulfilled, (state, action) => {
   state.responseAdd = action.payload;
   state.loadingAddCustomField = false;
  });

  builder.addCase(deleteCostDriver.rejected, (state, action) => {
   state.loadingAddCustomField = false;
   state.error = action.payload;
  });
  builder.addCase(getFormulaField.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getFormulaField.fulfilled, (state, action) => {
   state.formulaFields = action.payload;
   state.loading = false;
  });

  builder.addCase(getFormulaField.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(getFormulaByCostDriver.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getFormulaByCostDriver.fulfilled, (state, action) => {
   state.costDriverFields = action.payload;
   state.loading = false;
  });

  builder.addCase(getFormulaByCostDriver.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(getAllFormulaCostDriver.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
 
   builder.addCase(getAllFormulaCostDriver.fulfilled, (state, action) => {
    state.formulaList = action.payload;
    state.loading = false;
   });
 
   builder.addCase(getAllFormulaCostDriver.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });

   builder.addCase(validateFormula.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
 
   builder.addCase(validateFormula.fulfilled, (state, action) => {
    state.validate = action.payload;
    state.loading = false;
   });
 
   builder.addCase(validateFormula.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });
 },
});

export const actions = {
 ...myJourneySlice.actions,
 getList,
 add,
 edit,
 getById,
 predefineDataConfigurations,
 addCustomField,
 editWithoutStaticProduct,
 editCustomField,
 deleteCustomField,
 addNewCostDriver,
 deleteCostDriver,
 getFormulaField,
 getFormulaByCostDriver,
 getAllFormulaCostDriver,
 validateFormula
};

export const reducer = myJourneySlice.reducer;
