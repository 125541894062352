import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";
import OptipediaToast from "utils/notification-util";
import parameterDto from "state/masterDataConfiguration/dtos/parameterDto"

export const createMasterDataConfigurations = createAsyncThunk(
 "MasterDataConfigurations",
 async (payload: any) => {
  const data = payload as any;
  const response: any = await HttpService.post(
   `MasterDataConfigurations`,
   data
  );
  return response;
 }
);

export const editMasterDataConfigurations = createAsyncThunk(
 "editMasterDataConfigurations",
 async (payload: any) => {
  const data = payload as any;
  const response: any = await HttpService.put(`MasterDataConfigurations`, data);
  return response;
 }
);

export const masterDataConfigurationsDetails = createAsyncThunk(
 "masterDataConfigurationsDetails",
 async (payload : any) => {
    const response: any = await HttpService.get(`MasterDataConfigurations/${payload}`);
    return response.result
}
);

export const deleteMasterDataConfigurations = createAsyncThunk(
 "deleteMasterDataConfigurations",
 async (id: any) => {
  const response: any = await HttpService.delete(
   `/MasterDataConfigurations/${id}`
  );
  if (response.success) {
   return OptipediaToast.Success(
    "The Master Data Configuration has been removed successfully."
   );
  } else {
   return response;
  }
 }
);

export const masterDataConfigurationsList = createAsyncThunk(
 "masterDataConfigurationsList",
 async (parameterDto: parameterDto) => {
  return await HttpService.get(`MasterDataConfigurations`, { params: parameterDto });
 }
);
