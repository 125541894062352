import { RootState } from "state/store";
import { FC, useEffect, useState } from "react";
import {
 Box,
 Button,
 Grid,
 Stat,
 StatLabel,
 StatNumber,
 StatHelpText,
 StatArrow,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Td,
 Tbody,
 Flex,
 Switch,
 Spacer,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { BarChartLoading, NumberLoading } from "components/Loading";
import { isEmpty, isNull, orderBy, sum } from "lodash";
import { actions as approvalAction } from "state/approval/slice";
import { TreemapChart, BarChart } from "components/ApexChart";
import Magnificiant from "components/Magnificiant";
import ToolbarBottom from "views/AnnualSpendImpact/ToolbarBotton";

const Summary: FC = () => {
 const router = useHistory();
 const dispatch = useAppDispatch();
 const [level, setLevel] = useState(0);
 const [isPercentage, setIsPercentage] = useState<boolean>(false);

 let {
  loading,
  analyze,
  pricings,
  barChart,
  mapChart,
  mapLoading,
  barLoading,
 } = useSelector(
  (state: RootState) => ({
   loading: state.approval.loading,
   barLoading: state.approval.barLoading,
   mapLoading: state.approval.mapLoading,
   analyze: state.approval.analyze.result,
   pricings: state.approval.pricings,
   barChart: state.approval.barChart.result,
   mapChart: state.approval.mapChart.result,
  }),
  shallowEqual
 );

 const { isOpen, onOpen, onClose } = useDisclosure();
 const [barSummary, setBarSummary] = useState(null);
 const [treemapSummary, setTeemapSummary] = useState(null);
 const [thirdPartyType, setThirdPartyType] = useState(false);
 const [chartType, setChartType] = useState(false);

 const sendData = {
  payload: { costAnalysisPerVolumeIds: pricings },
  isCurrent: chartType,
  level: level,
 };

 useEffect(() => {
  if (pricings.length === 0) {
   router.push("/approval/annual-spend-impact");
  } else {
   dispatch(
    approvalAction.approvalAnalyze({
     costAnalysisPerVolumeIds: pricings,
    })
   );
   dispatch(approvalAction.approvalAnalyzeBarChart(sendData));
   dispatch(approvalAction.approvalAnalyzeMapChart(sendData));
  }
 }, []);
 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);
 const changeBar = (value: any) => {
  const payload = {
    payload: { costAnalysisPerVolumeIds: pricings },
    isCurrent: chartType,
    level: value,
   };
  dispatch(approvalAction.approvalAnalyzeBarChart(payload));
  setBarSummary(value);
 };
 const changeTreemap = (value: any) => {
  const payload = {
    payload: { costAnalysisPerVolumeIds: pricings },
    isCurrent: chartType,
    level: value,
   };
  dispatch(approvalAction.approvalAnalyzeMapChart(payload));
  setTeemapSummary(value);
 };
 const chartData = analyze?.thirdPartySummaryChart?.map((x: any) => ({
  x: x.data.map((item: any) => item.identityName),
  y: !thirdPartyType
   ? x.data.map((item: any) => item.currentTotalSpend)
   : x.data.map((item: any) => item.whatIfTotalSpend),
  label: x.thirdPartyDescription,
 }));
 
 const series = chartData
  ? chartData?.map((item: any) => ({
     name: item.label,
     data: item.y,
     labelName: item.x,
    }))
  : [];
 const sequenceBar: any = orderBy(barChart, ["sequence"], ["asc"]);
 const barData = !isEmpty(barChart)
  ? sequenceBar.map((x: any) => ({
     x: x.identityName,
     y: x.data?.map((item: any) => item.currentTotalSpend),
     whatIf: x.data?.map((item: any) => item.whatIfTotalSpend),
    }))
  : [];

 const barSeries = !isEmpty(barChart)
  ? [
     {
      name: "Current",
      data: barData?.map((x: any) => ({
       x: x.x,
       y: sum(x.y),
      })),
      labelName: barData?.map((x: any) => {
       return x["x"];
      }),
     },
     {
      name: "To-be",
      data: barData.map((x: any) => ({
       x: x.x,
       y: sum(x.whatIf),
      })),
      labelName: barData.map((x: any) => {
       return x["x"];
      }),
     },
    ]
  : [];
 const mapSeries = !isEmpty(mapChart)
  ? mapChart?.map((x: any) => ({
     name: x.identityName,
     data: orderBy(x.data, 'currentTotalSpend', 'desc')?.map((item: any) => ({
      x: item.identityName,
      y: !chartType
       ? isPercentage
         ? (item.currentContribution * 100).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
           }) + "%"
         : item.currentTotalSpend
       : isPercentage
       ? (item.whatIfContribution * 100).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         }) + "%"
       : item.whatIfTotalSpend,
      i: item.identityName,
      z: !chartType ? item.currentTotalSpend : item.whatIfTotalSpend,
      addon: [],
     })),
    }))
  : [];

 return (
  <Box mb="6rem">
   <Grid templateColumns="repeat(4, 1fr)" gap={2} pt="2rem" pb="1rem">
    {!loading ? (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
     >
      <Stat>
       <StatLabel color="#9DA1A7" fontSize="16px">
        Current total spend
       </StatLabel>
       <StatNumber fontSize="24px">
        $
        {analyze?.currentTotalSpend?.toLocaleString(undefined, {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
        })}
       </StatNumber>
      </Stat>
     </Box>
    ) : (
     <Box
      textAlign="center"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
     >
      <NumberLoading />
     </Box>
    )}

    {!loading ? (
     <Box
      pl="28px"
      pt="29px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
     >
      <Stat>
       <StatLabel color="#9DA1A7" fontSize="16px">
        To-be total spend
       </StatLabel>
       <StatNumber fontSize="24px">
        $
        {analyze?.whatIfTotalSpend?.toLocaleString(undefined, {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
        })}
       </StatNumber>
      </Stat>
     </Box>
    ) : (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
     >
      <NumberLoading />
     </Box>
    )}
    {!loading ? (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
     >
      <Stat>
       <StatLabel color="#9DA1A7" fontSize="16px">
        Difference
       </StatLabel>
       <StatNumber fontSize="24px">
        $
        {analyze?.difference?.toLocaleString(undefined, {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
        })}
       </StatNumber>
       {analyze?.difference <= 0 ? (
        <StatHelpText>
         <StatArrow type="decrease" color={"green"} />
         {(analyze?.differenceInPercentage * 100)?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         })}
         %
        </StatHelpText>
       ) : (
        <StatHelpText>
         <StatArrow type="increase" color={"red"} />
         {(analyze?.differenceInPercentage * 100)?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         })}
         %
        </StatHelpText>
       )}
      </Stat>
     </Box>
    ) : (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
     >
      <NumberLoading />
     </Box>
    )}
   </Grid>
   {analyze?.isEnableThirdParty && (
    <Box border="1px solid #D7DBE2" w="100%" p={4} borderRadius="5px">
     <Box pl="1rem" pb="2rem">
      <Box alignItems="center" fontWeight="600" mt="1rem" mr="1rem">
       {thirdPartyType ? "What-If " : "Current "}
       <Switch
        defaultChecked={thirdPartyType ? true : false}
        ml="5px"
        onChange={() => setThirdPartyType(!thirdPartyType)}
       />
      </Box>
     </Box>
     <BarChart data={series} height={300} type="bar" />
    </Box>
   )}
   <Grid templateColumns="repeat(2, 1fr)" gap={4} pb="1rem" pt="1rem">
    <Box border="1px solid #D7DBE2" w="100%" p={4} borderRadius="5px">
     <Box>
      <Flex>
       <Button
        disabled={isEmpty(barSeries) ? true : false}
        size={"sm"}
        variant="ghost"
        color={isEmpty(barSummary) ? "#0061BD" : "#C3C7CD"}
        onClick={() => changeBar(null)}
       >
        Summary
       </Button>
        <Button
         disabled={isEmpty(barSeries) ? true : false}
         size={"sm"}
         variant="ghost"
         color={barSummary === "1" ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeBar("1")}
        >
         Level 1
        </Button>
        <Button
         disabled={isEmpty(barSeries) ? true : false}
         size={"sm"}
         variant="ghost"
         color={barSummary === "2" ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeBar("2")}
        >
         Level 2
        </Button>
       <Spacer />
       <Magnificiant
        isActive={isEmpty(barSeries) ? true : false}
        data={
         <Box p="1rem">
          <Button
           disabled={isEmpty(barSeries) ? true : false}
           size={"sm"}
           variant="ghost"
           color={isEmpty(barSummary) ? "#0061BD" : "#C3C7CD"}
           onClick={() => changeBar(null)}
          >
           Summary
          </Button>
          <Button
            disabled={isEmpty(barSeries) ? true : false}
            size={"sm"}
            variant="ghost"
            color={barSummary === "1" ? "#0061BD" : "#C3C7CD"}
            onClick={() => changeBar("1")}
           >
            Level 1
           </Button>
           <Button
            disabled={isEmpty(barSeries) ? true : false}
            size={"sm"}
            variant="ghost"
            color={barSummary === "2" ? "#0061BD" : "#C3C7CD"}
            onClick={() => changeBar("2")}
           >
            Level 2
           </Button>
          {!loading && <BarChart data={barSeries} type="bar" height={600} />}
          {loading && <BarChartLoading />}
         </Box>
        }
       />
      </Flex>
     </Box>
     {!loading && <BarChart data={barSeries} type="bar" />}
     {loading && <BarChartLoading />}
    </Box>
    <Box
     border="1px solid #D7DBE2"
     w="100%"
     minH={300}
     p={4}
     borderRadius="5px"
    >
     <Box>
      <Flex>
       <Button
        disabled={isEmpty(mapSeries) ? true : false}
        size={"sm"}
        variant="ghost"
        color={isEmpty(treemapSummary) ? "#0061BD" : "#C3C7CD"}
        onClick={() => changeTreemap(null)}
       >
        Summary
       </Button>
       <Button
         disabled={isEmpty(mapSeries) ? true : false}
         size={"sm"}
         variant="ghost"
         color={treemapSummary === "1" ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeTreemap("1")}
        >
         Level 1
        </Button>
        <Button
         disabled={isEmpty(mapSeries) ? true : false}
         size={"sm"}
         variant="ghost"
         color={treemapSummary === "2" ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeTreemap("2")}
        >
         Level 2
        </Button>
       <Spacer />
       <Magnificiant
        isActive={isEmpty(mapSeries) ? true : false}
        data={
         <Box p="1rem">
          <Button
           disabled={isEmpty(mapSeries) ? true : false}
           size={"sm"}
           variant="ghost"
           color={isEmpty(treemapSummary) ? "#0061BD" : "#C3C7CD"}
           onClick={() => changeTreemap(null)}
          >
           Summary
          </Button>
          <Button
           disabled={isEmpty(mapSeries) ? true : false}
           size={"sm"}
           variant="ghost"
           color={treemapSummary === "1" ? "#0061BD" : "#C3C7CD"}
           onClick={() => changeTreemap("1")}
          >
           Level 1
          </Button>
          <Button
           disabled={isEmpty(mapSeries) ? true : false}
           size={"sm"}
           variant="ghost"
           color={treemapSummary === "2" ? "#0061BD" : "#C3C7CD"}
           onClick={() => changeTreemap("2")}
          >
           Level 2
          </Button>

          <Flex>
           <Box alignItems="center" fontWeight="600" ml="1rem">
            {chartType ? "What-If" : "Current"}
            <Switch
             defaultChecked={chartType ? true : false}
             ml="5px"
             onChange={() => setChartType(!chartType)}
            />
           </Box>
           <Box alignItems="center" fontWeight="600" mr="1rem" ml="1rem">
            {isPercentage ? "Percentage" : "Cost Number"}
            <Switch
             defaultChecked={isPercentage}
             ml="5px"
             mr="5px"
             onChange={() => setIsPercentage(!isPercentage)}
            />
           </Box>
          </Flex>
          {!loading && <TreemapChart data={mapSeries} height={600} />}
          {loading && <BarChartLoading />}
         </Box>
        }
       />
      </Flex>
      <Flex>
       <Box alignItems="center" fontWeight="600" ml="1rem">
        {chartType ? "What-If" : "Current"}
        <Switch
         defaultChecked={chartType ? true : false}
         ml="5px"
         onChange={() => setChartType(!chartType)}
        />
       </Box>
       <Box alignItems="center" fontWeight="600" mr="1rem" ml="1rem">
        {isPercentage ? "Percentage" : "Cost Number"}
        <Switch
         defaultChecked={isPercentage}
         ml="5px"
         mr="5px"
         onChange={() => setIsPercentage(!isPercentage)}
        />
       </Box>
      </Flex>
     </Box>
     {!loading && <TreemapChart data={mapSeries} type="treemap" height={300} />}
     {loading && <BarChartLoading />}
    </Box>
   </Grid>
   <ToolbarBottom handleChange={onOpen} />
   <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent minW="60%" borderRadius="5px">
     <ModalCloseButton />
     <ModalHeader>Simulation Changes Log</ModalHeader>
     <ModalBody>
      <TableContainer>
       <Table variant="mytable">
        <Thead borderBottom="1px solid #D7DBE2">
         <Tr>
          <Th>Components</Th>
          <Th textAlign="right" isNumeric>
           Change From
          </Th>
          <Th textAlign="right" isNumeric>
           Change To
          </Th>
          <Th textAlign="right" isNumeric>
           % Change
          </Th>
         </Tr>
        </Thead>
        <Tbody>
         {!isEmpty(series) &&
          barData?.map((item: any, index: number) => (
           <Tr key={index}>
            <Td>{item.x}</Td>
            <Td textAlign="right" isNumeric>
             {String.toFormatSeparator(sum(item.y))}
            </Td>
            <Td textAlign="right">
             {String.toFormatSeparator(sum(item.whatIf))}
            </Td>
            <Td textAlign="right">
             <Stat>
              <StatHelpText>
               <StatArrow
                type={
                 ((sum(item.whatIf) - sum(item.y)) / sum(item.y)) * 100 >= 0
                  ? "increase"
                  : "decrease"
                }
                color={
                 ((sum(item.whatIf) - sum(item.y)) / sum(item.y)) * 100 >= 0
                  ? "red"
                  : "green"
                }
               />
               {`(${String.toFormatSeparator(
                ((sum(item.whatIf) - sum(item.y)) / sum(item.y)) * 100
               )} %)`}
              </StatHelpText>
             </Stat>
            </Td>
           </Tr>
          ))}
        </Tbody>
       </Table>
       <Table variant="mytable">
        <Tbody>
         <Tr>
          <Td width="100vh" textAlign="right" fontWeight={600}>
           Total spend (Current) :{" "}
          </Td>
          <Td textAlign="right" fontWeight={600}>
           {String.toFormatSeparator(analyze?.currentTotalSpend)}
          </Td>
         </Tr>
         <Tr>
          <Td textAlign="right" fontWeight={600}>
           Total spend (What-If) :{" "}
          </Td>
          <Td textAlign="right" fontWeight={600}>
           {String.toFormatSeparator(analyze?.whatIfTotalSpend)}
          </Td>
         </Tr>
        </Tbody>
       </Table>
      </TableContainer>
     </ModalBody>
    </ModalContent>
   </Modal>
  </Box>
 );
};

export default Summary;
