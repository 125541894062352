import { createSlice } from "@reduxjs/toolkit";
import { getMasterData } from "state/pricings/predefinedData/thunk";

interface IMasterData {
 code: string;
}
interface InitialState {
 loading: boolean;
 error: unknown;
 result: IMasterData[];
}

const initialState: InitialState = {
 error: null,
 loading: false,
 result: [],
};

const predefinedDataSlice = createSlice({
 name: "predefinedData",
 initialState,
 reducers: {
  clear: () => initialState,
 },
 extraReducers: (builder) => {
  builder.addCase(getMasterData.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getMasterData.fulfilled, (state, action) => {
   state.result = action.payload;
   state.loading = false;
  });

  builder.addCase(getMasterData.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
 },
});

export const actions = {
 ...predefinedDataSlice.actions,
 getMasterData,
};

export const reducer = predefinedDataSlice.reducer;
