import { ReactElement, useState, useEffect } from "react";
import { Box, Flex, Button, Text, Heading, Input } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextInput from "components/TextInput";
import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import Swal from "sweetalert2";

interface Values {
 id?: string;
 code: string;
 description: string;
}

const CurrencyCreated = (): ReactElement => {
 const { currencyUpdated, currencyDataToEdit, searchQuery } = useSelector(
  (state: RootState) => ({
   currencyUpdated: state.multiCurrency.currencyUpdated,
   currencyDataToEdit: state.multiCurrency.currencyDataToEdit,
   searchQuery: state.multiCurrency.searchQuery,
  }),
  shallowEqual
 );
 const maxResultCount = 10;
 const [initialState, setInitialState] = useState<Values>({
  id: "",
  code: "",
  description: "",
 });
 const dispatch = useAppDispatch();

 useEffect(() => {
  if (currencyDataToEdit) {
   setInitialState(currencyDataToEdit);
  } else {
   setInitialState(initialState);
  }
 }, [currencyUpdated]);

 const validations = Yup.object().shape({
  code: Yup.string().required("Currency code is a required field"),
  description: Yup.string().required(
   "Currency description is a required field"
  ),
 });
 const fetchList = () => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   keyword: searchQuery,
   manageExchangeRate: false
  };
  dispatch(multiCurrencyAction.listMultiCurrency(payload));
 };
 return (
  <Box>
   <Heading fontSize={18} pb={5}>
    Create New Currency
   </Heading>
   <Formik
    enableReinitialize
    initialValues={initialState}
    validationSchema={validations}
    onSubmit={async (values: Values, actions) => {
     const { id } = currencyDataToEdit;
     if (id) {
      const payload = {
       id: id,
       values,
      };
      await dispatch(multiCurrencyAction.edit(payload)).then((res: any) => {
       if (res.payload) {
         actions.resetForm()
         dispatch(multiCurrencyAction.setCurrencyDataToEdit({}));
        fetchList();
        dispatch(multiCurrencyAction.resetPage(false));
       }
      });
     } else {
      await dispatch(multiCurrencyAction.add(values)).then((res: any) => {
       if (res.payload) {
         actions.resetForm();
         dispatch(multiCurrencyAction.setCurrencyDataToEdit({}));
        fetchList();
        dispatch(multiCurrencyAction.resetPage(false));
       }
      });
     }
    }}
   >
    {({
     values,
     errors,
     touched,
     handleChange,
     handleBlur,
     handleSubmit,
     isSubmitting,
     resetForm,
    }) => (
     <Form>
      <Box width={500} py="5px">
       <Text color={"gray.500"}>Currency Code *</Text>
       <Input
        name="code"
        value={values.code}
        id="code"
        placeholder="e.g USD"
        onChange={handleChange}
        border="1px solid"
        borderColor={"rgba(216, 216, 217, 1)"}
        bgColor="white"
        borderRadius="5px"
        h="51px"
        isInvalid={errors.code ? true : false}
        mt={2}
       />

       <small style={{ color: "red" }}>{errors.code}</small>
      </Box>

      <Box width={500} py="25px">
       <Text color={"gray.500"}>Currency Description *</Text>
       <Input
        name="description"
        id="description"
        value={values.description}
        placeholder="e.g USD"
        onChange={handleChange}
        border="1px solid"
        borderColor={"rgba(216, 216, 217, 1)"}
        bgColor="white"
        borderRadius="5px"
        h="51px"
        isInvalid={errors.description ? true : false}
        mt={2}
       />
       <small style={{ color: "red" }}>{errors.description}</small>
      </Box>
      <Box
       position="fixed"
       bottom="0"
       backgroundColor="#ffffff"
       height="5.5rem"
       w="100%"
      >
       <Flex float="right" mr="20rem">
        <Box mt={4}>
         <Flex alignItems="center" gap="2">
          <>
           <Button
            variant={"outline"}
            onClick={() =>
             Swal.fire({
              showCancelButton: true,
              html:
               "The changes that you have made will be lost. Are you sure you want to proceed",
              cancelButtonText: "No",
              confirmButtonText: "Yes",
             }).then(async (result) => {
              if (result.isConfirmed) {
               setInitialState(initialState);
               resetForm();
               dispatch(multiCurrencyAction.setCurrencyDataToEdit({}));
               dispatch(multiCurrencyAction.resetPage(false));
              }
             })
            }
           >
            Cancel
           </Button>
           <Button type="submit">Save</Button>
          </>
         </Flex>
        </Box>
       </Flex>
      </Box>
     </Form>
    )}
   </Formik>
  </Box>
 );
};

export default CurrencyCreated;
