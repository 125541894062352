import { FC } from "react";
import { List, Box } from "@chakra-ui/react";
import s from "shortid";
import { RootState } from "state/store";
import { useSelector, shallowEqual } from "react-redux";

import NavItem, { NavItemProps } from "components/NavItem";

type NavbarProps = {
 menu: NavItemProps[];
};
const Navbar: FC<NavbarProps> = ({ menu }) => {
 const { profile } = useSelector(
  (state: RootState) => ({
   profile: state.auth.result,
  }),
  shallowEqual
 );
 return (
  <Box>
   <List>
    {profile.isAdmin
     ? menu.map((item: NavItemProps) => {
        return <NavItem key={s.generate()} {...item} />;
       })
     : menu
        .filter(
         (item: NavItemProps) =>
         !["Journey", "Settings", "Demand", "Approval"].includes(item.label)
        )
        .map((item: NavItemProps) => {
         return <NavItem key={s.generate()} {...item} />;
        })}
   </List>
  </Box>
 );
};

export default Navbar;
