import { RootState } from "state/store";
import { FC, useEffect, useState } from "react";
import {
 Box,
 Button,
 Flex,
 SimpleGrid,
 Progress,
 RadioGroup,
 Radio,
 Stack,
 Text,
 Spacer,
 Spinner,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { actions as annualSpendAction } from "state/annualSpend/slice";
import { Formik, Form, FieldArray } from "formik";
import FormInput from "./FormInput";
import { sortBy } from "lodash";

const DescribeChange: FC = () => {
 const dispatch = useAppDispatch();
 const router = useHistory();

 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);
 let {
  loading,
  barLoading,
  treemapLoading,
  uploadResponse,
  describeResponse,
  journeyId,
 } = useSelector(
  (state: RootState) => ({
   loading: state.annualSpendSimulations.loading,
   barLoading: state.annualSpendSimulations.barLoading,
   treemapLoading: state.annualSpendSimulations.treemapLoading,
   uploadResponse: state.annualSpendSimulations.uploadResponse,
   component: state.annualSpendSimulations.component,
   subComponent: state.annualSpendSimulations.subComponent,
   subComponentsResponse: state.annualSpendSimulations.subComponentsResponse,
   describeResponse: state.annualSpendSimulations.describeResponse,
   journeyId: state.annualSpendSimulations.journeyId,
  }),
  shallowEqual
 );

 const [initialValues, setInitialValues] = useState<any>();

 let result: any = {};
 describeResponse.forEach((item: any) => {
  if (item.name) {
   if (result[item.name]) {
    result[item.name] = result[item.name].concat(item.data);
   } else {
    result[item.name] = item.data;
   }
  }
 });

 let dataManipulation: any = [];
 Object.getOwnPropertyNames(result).map((item: any, index: number) => {
  dataManipulation.push({
   name: item,
   data: Object.values(result)[index],
  });
 });

 useEffect(() => {
  const dataToSend: any = [];
  dataManipulation.map((item: any) => {
   const { data } = item;
   data.map((item: any) => {
    const { identityCode, additional } = item;
    dataToSend.push({
     identityCode,
     value: 0,
     byPercentage: true,
    });
    if (additional?.length > 0) {
     additional?.map((item: any) => {
      const { id } = item;
      dataToSend.push({
       id,
       value: 0,
       byPercentage: true,
      });
     });
    }
   });
  });

  setInitialValues({
   dataSubmit: dataManipulation,
  });
 }, [describeResponse]);
 let stringToBoolean = (value: any) => {
  if (value && typeof value === "string") {
   if (value.toLowerCase() === "true") return true;
   if (value.toLowerCase() === "false") return false;
  }
  return value;
 };

 return (
  <Box mt="1.5rem" mb="6rem">
   {/* <Box fontWeight="700" fontSize="24px" pb="2rem">
    Select percentage change
   </Box> */}
   {/* {loading && <Progress m="1rem" mb="2rem" size="xs" isIndeterminate />}
   {treemapLoading && <Progress m="1rem" mb="2rem" size="xs" isIndeterminate />}
   {barLoading && <Progress m="1rem" mb="2rem" size="xs" isIndeterminate />} */}
   <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={async (values) => {
     const valueToSend: any = [];
     values.dataSubmit.map((items: any, index: number) => {
      const { data } = items;
      data.map((item: any) => {
       const { identityCode, value, byPercentage, additional } = item;
       valueToSend.push({
        value: byPercentage ? Number(value) / 100 : Number(value),
        identityCode,
        byPercentage,
        additionalFields: additional?.map((item: any) => {
         return {
          byPercentage: item.byPercentage,
          id: item.id,
          value: stringToBoolean(item.byPercentage)
           ? Number(item.value) / 100
           : Number(item.value),
         };
        }),
       });
      });
     });
     const sendData = {
      payload: {
       journeyId,
       costAnalysises: uploadResponse,
       simulationValues: valueToSend,
      },
     };
     dispatch(annualSpendAction.setValueToSend(sendData.payload));
     await dispatch(annualSpendAction.simulateTreemapChart(sendData));
     await dispatch(annualSpendAction.simulateBarChart(sendData));
     await dispatch(annualSpendAction.simulate(sendData));
     router.push("/annual-spend-simulations/create?page=summary");
    }}
    render={({ values, setFieldValue }) => (
     <Form noValidate>
      <Box>
       {describeResponse.length == 0 && !loading && (
        <Box padding="1rem" textAlign="center" fontWeight="700" fontSize="18px">
         There is no component that can be simulated. Please proceed to the next
         step directly.
        </Box>
       )}
       <FieldArray
        name="dataSubmit"
        render={() => {
          console.log(values);
          
         return (
          <Box>
           {values?.dataSubmit.map(
            (item: { name: string; data: any[] }, index: number) => {
             return (
              <Box
               border="1px solid #D7DBE2"
               borderRadius="5px"
               p="2rem"
               key={index}
               mb="1rem"
               fontWeight="bold"
              >
               <Box color="#0072CF" fontSize={24}>
                {" "}
                {item.name}
               </Box>
               {/* <SimpleGrid minChildWidth="120px" spacing="40px"> */}
               {item.data.map((data: any, idx: number) => {
                return (
                 <Box mt="1rem" key={idx} p={5} border="1px solid #D7DBE2" borderRadius="5px">
                  <Text fontSize={18} mb={5} color="#0072CF">{data.identityName}</Text>
                  <Flex>
                   <Box pr="20" pb="8" width={500}>
                    <FormInput
                     isPercentage={values.dataSubmit[index].data[
                      idx
                     ].byPercentage.toString()}
                     name={`dataSubmit[${index}].data[${idx}].value`}
                     label={`${data.identityName} ${data.costLabelName}`}
                     onChange={(item) => {
                      const val = item.currentTarget.value;
                      let itemState = Object.assign({}, data);

                      itemState.value = val;
                      setFieldValue(
                       `dataSubmit[${index}].data[${idx}].value`,
                       val
                      );
                     }}
                    />
                   </Box>
                   <Stack direction={["column", "row"]} spacing="24px" pr="20">
                    <Box>
                     <Text>Lowest</Text>
                     <Text textAlign="center" pt="4">
                      { data.minValue % 1 === 0 ? data.minValue : data.minValue.toFixed(5)}
                     </Text>
                    </Box>
                    <Box>
                     <Text>Highest</Text>
                     <Text textAlign="center" pt="4">
                      {data.maxValue % 1 === 0 ? data.maxValue : data.maxValue.toFixed(5)}
                     </Text>
                    </Box>
                   </Stack>
                   <RadioGroup
                    name={`dataSubmit[${index}].data[${idx}].byPercentage`}
                    onChange={(dataItem) => {
                     setFieldValue(
                      `dataSubmit[${index}].data[${idx}].byPercentage`,
                      stringToBoolean(dataItem)
                     );
                    }}
                    value={data.byPercentage.toString()}
                   >
                    <Stack direction="row" m="auto" pt="2.5rem">
                     <Radio value="false">Absolute</Radio>
                     <Radio value="true">Percentage</Radio>
                    </Stack>
                   </RadioGroup>
                  </Flex>
                  {data?.additional &&
                   data?.additional?.map((item: any, count: number) => (
                    <Flex key={count}>
                     <Box pr="20" pb="8" width={500}>
                      <FormInput
                       isPercentage={values.dataSubmit[index].data[
                        idx
                       ].additional[count].byPercentage.toString()}
                       name={`dataSubmit[${index}].data[${idx}].additional[${count}].value`}
                       label={`${data.identityName} ${item.name}`}
                       onChange={(item) => {
                        const val = item.currentTarget.value;
                        let itemState = Object.assign({}, data);
                        itemState.value = val;
                        setFieldValue(
                         `dataSubmit[${index}].data[${idx}].additional[${count}].value`,
                         val
                        );
                       }}
                      />
                     </Box>
                     <Stack
                      direction={["column", "row"]}
                      spacing="24px"
                      pr="20"
                     >
                      <Box>
                       <Text>Lowest</Text>
                       <Text textAlign="center" pt="4">
                        {item.minValue % 1 === 0? item.minValue : item.minValue.toFixed(5)}
                       </Text>
                      </Box>
                      <Box>
                       <Text>Highest</Text>
                       <Text textAlign="center" pt="4">
                        {item.maxValue % 1 === 0 ? item.maxValue : item.maxValue.toFixed(5)}
                       </Text>
                      </Box>
                     </Stack>
                     <RadioGroup
                      name={`dataSubmit[${index}].data[${idx}].additional[${count}].byPercentage`}
                      onChange={(dataItem) => {
                       setFieldValue(
                        `dataSubmit[${index}].data[${idx}].additional[${count}].byPercentage`,
                        dataItem
                       );
                      }}
                      value={item.byPercentage.toString()}
                     >
                      <Stack direction="row" m="auto" pt="2.5rem">
                       <Radio value="false">Absolute</Radio>
                       <Radio value="true">Percentage</Radio>
                      </Stack>
                     </RadioGroup>
                    </Flex>
                   ))}
                 </Box>
                );
               })}
               {/* </SimpleGrid> */}
              </Box>
             );
            }
           )}
          </Box>
         );
        }}
       />
      </Box>
      <Box
       position="fixed"
       bottom="0"
       backgroundColor="#ffffff"
       height="5.5rem"
       w="100%"
       zIndex={2}
      >
       <Flex mr="20rem">
        {treemapLoading ? (
         <Flex alignItems="center" gap="2">
          <Spinner
           thickness="4px"
           speed="0.65s"
           emptyColor="gray.200"
           color="blue.500"
           size="sm"
          />
          <Text>Processing…</Text>
         </Flex>
        ) : barLoading ? (
         <Flex alignItems="center" gap="2">
          <Spinner
           thickness="4px"
           speed="0.65s"
           emptyColor="gray.200"
           color="blue.500"
           size="sm"
          />
          <Text>Processing…</Text>
         </Flex>
        ) : (
         loading && (
          <Flex alignItems="center" gap="2">
           <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="sm"
           />
           <Text>Processing…</Text>
          </Flex>
         )
        )}
        <Spacer />
        <Box mt={4}>
         <Flex alignItems="right" gap="2">
          <>
           <Button
            variant={'outline'}
            isDisabled={
             barLoading
              ? barLoading
              : treemapLoading
              ? treemapLoading
              : loading && loading
            }
            _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
            onClick={() => router.goBack()}
           >
            Back
           </Button>
           <Button
            isDisabled={
             barLoading
              ? barLoading
              : treemapLoading
              ? treemapLoading
              : loading && loading
            }
            _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
            type="submit"
           >
            Next
           </Button>
          </>
         </Flex>
        </Box>
       </Flex>
      </Box>
     </Form>
    )}
   ></Formik>
  </Box>
 );
};

export default DescribeChange;
