import { ReactElement, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Button, Spacer, Flex, Text, Spinner } from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { usePrompt } from "hooks";
import Swal from "sweetalert2";
import { useAppDispatch } from "hooks";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import { actions as pricingCostSummaryAction } from "state/pricings/costSummary/slice";
import { actions as copyAndReplaceCostDriverAction } from "state/pricings/copyAndReplace/slice";

const ToolbarBottom = ({
 costAnalysis,
 costAnalysisId,
 resetForm,
}: any): ReactElement => {
 const dispatch = useAppDispatch();
 const history = useHistory();
 const location = useLocation<{ status: string; volumeId: string }>();
 const searchParams = new URLSearchParams(location.search);

 const [auditLog, setAuditLog] = useState<boolean>(false);
 const [isDisabled, setDisabled] = useState<boolean>(true);
 const [isResetForm, setResetForm] = useState<boolean>(false);
 const currentPageName = location.pathname.split("/")[2];
 const pricingId = location.pathname.split("/")[3];
 const urls = location.pathname.split("/")[2];
 const {
  isEditing,
  isReplace,
  dataToSend,
  isSimulate,
  valueIsIncludeCalculation,
  loadingDownloadReport,
  loadingSave,
  resetFormData,
  detailPerVolumeId,
  tmpChangeRow,
  dataToSendDelete,
  levelName,
  tmpChangeRowNoCustomField,
  statusAndVolume,
  dataToSendUpdates,
  dataToSendAdds,
  changeRowSimulate,
  isCopy,
  loadingSaveCopyAndReplace,
  isDisable,
 } = useSelector(
  (state: RootState) => ({
   loadingSave: state.editPricingCostDrivers.loadingSave,
   loadingSaveCopyAndReplace: state.copyAndReplaceCostDrivers.loading,
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId.result,
   isEditing: state.editPricingCostDrivers.isEditing,
   isSimulate: state.editPricingCostDrivers.isSimulate,
   loadingDownloadReport: state.editPricingCostDrivers.loadingHistoryReport,
   dataToSend: state.editPricingCostDrivers.dataToSend,
   valueIsIncludeCalculation:
    state.editPricingCostDrivers.valueIsIncludeCalculation,
   resetFormData: state.editPricingCostDrivers.resetForm,
   tmpChangeRow: state.editPricingCostDrivers.tmpChangeRow,
   dataToSendDelete: state.editPricingCostDrivers.dataToSend.deletes,
   levelName: state.pricingCostSummary.costSummaryById.levelName,
   tmpChangeRowNoCustomField:
    state.editPricingCostDrivers.tmpChangeRowNoCustomField,
   statusAndVolume: state.editPricingCostDrivers.statusAndVolume,
   dataToSendUpdates: state.editPricingCostDrivers.dataToSend.updates,
   dataToSendAdds: state.editPricingCostDrivers.dataToSend.adds,
   changeRowSimulate: state.editPricingCostDrivers.tmpChangeRow,
   isReplace: state.copyAndReplaceCostDrivers.isReplace,
   isCopy: state.copyAndReplaceCostDrivers.isCopy,
   isDisable: state.editPricingCostDrivers.isDisabled,
  }),
  shallowEqual
 );

 usePrompt(false);

 useEffect(() => {
  if (
   tmpChangeRow?.length > 0 ||
   dataToSendUpdates?.length > 0 ||
   dataToSendAdds?.length > 0
  ) {
   setDisabled(false);
  } else {
   setDisabled(true);
  }
 }, [
  valueIsIncludeCalculation,
  tmpChangeRow,
  dataToSendUpdates,
  dataToSendAdds,
 ]);

 var mainCostDrivers = costAnalysis.costDrivers
  .filter((i: any) => i.parentCostDriver === null)
  .sort((a: any, b: any) =>
   a.configuration.sequence < b.configuration.sequence ? -1 : 1
  );

 var componentConfigurations: any = [];

 var componentCostSummary = {
  sequence: 1,
  name: "cost-summary-by-sku",
  displayName: "Cost Summary",
 };

 var componentGeneralData = {
  sequence: 2,
  name: "sku-characteristic",
  displayName: "General Data",
 };

 var componentFinalCost = {
  sequence: 3,
  name: "final-cost",
  displayName: "Final Cost",
 };

 const openAuditLog = () => {
  setAuditLog(true);
 };

 componentConfigurations.push(componentCostSummary);
 componentConfigurations.push(componentGeneralData);
 if (levelName && levelName === "Does Cost") {
  componentConfigurations.push(componentFinalCost);
 }
 var pageNumber = 3;
 mainCostDrivers.map((costDriver: any, index: number) => {
  const component = {
   sequence: pageNumber + index,
   name: costDriver.configuration.identityName
    .replace(/\s+/g, "-")
    .toLowerCase(),
   description: costDriver.configuration.identityName,
  };
  componentConfigurations.push(component);
 });

 const getCurrentComponentSequence = () => {
  var currentComponent = componentConfigurations.find(
   (i: any) => i.name == currentPageName
  );
  if (!currentComponent) return 1;
  else return currentComponent.sequence;
 };

 const getComponentConfigurationBySequence = (sequence: any) => {
  return componentConfigurations.find((i: any) => i.sequence == sequence);
 };

 const navigateToPrevComponent = () => {
  var currentSequence = getCurrentComponentSequence();
  var prevComponent = getComponentConfigurationBySequence(currentSequence - 1);
  history.push({
   pathname: `/pricings/${prevComponent.name}/${costAnalysisId}`,
   state: {
    status: location.state.status,
    volumeId: location.state.volumeId,
   },
  });
  window.scrollTo(0, 0);
 };

 const navigateToNextComponent = () => {
  var currentSequence = getCurrentComponentSequence();
  var nextComponent = getComponentConfigurationBySequence(currentSequence + 1);

  history.push({
   pathname: `/pricings/${nextComponent.name}/${costAnalysisId}`,
   state: {
    status: location.state.status,
    volumeId: location.state.volumeId,
   },
  });
  window.scrollTo(0, 0);
 };

 const isFirstComponent = () => {
  var currentSequence = getCurrentComponentSequence();
  return currentSequence == 1;
 };

 const isLastComponent = () => {
  var currentSequence = getCurrentComponentSequence();
  return currentSequence == componentConfigurations.length;
 };

 const haveArrayInDataToSend = dataToSendDelete?.length > 0;
 const haveArrayInTmpChangeRowNoCustomField =
  tmpChangeRowNoCustomField.length > 0;

 const isDisableButtonSave =
  dataToSendAdds?.length > 0 ||
  dataToSendUpdates?.length > 0 ||
  dataToSendDelete?.length > 0;

 return (
  <Box
   position="fixed"
   bottom="0"
   right="0"
   backgroundColor="#ffffff"
   height="4.5rem"
   w="100%"
  >
   <Flex>
    <Box ml="19rem" mt={5}>
     {valueIsIncludeCalculation && (
      <Flex alignItems="center" gap="2">
       <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="sm"
       />
       <Text>Processing…</Text>
      </Flex>
     )}
     {loadingDownloadReport && (
      <Flex alignItems="center" gap="2">
       <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="sm"
       />
       <Text>Generating…</Text>
      </Flex>
     )}
     {loadingSave ||
      (loadingSaveCopyAndReplace && (
       <Flex alignItems="center" gap="2">
        <Spinner
         thickness="4px"
         speed="0.65s"
         emptyColor="gray.200"
         color="blue.500"
         size="sm"
        />
        <Text>Saving…</Text>
       </Flex>
      ))}
    </Box>
    <Spacer />
    <Box mt={4} mr="2rem">
     {isEditing || isReplace || isCopy ? (
      <>
       <Button
        variant={"outline"}
        mx="5"
        isDisabled={valueIsIncludeCalculation}
        onClick={() => {
         Swal.fire({
          showCancelButton: true,
          html:
           "The changes that you have made will be lost. Are you sure you want to proceed?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
         }).then(async (result) => {
          if (result.isConfirmed) {
           dispatch(pricingCostDriverAction.setIsEditing(false));
           dispatch(copyAndReplaceCostDriverAction.setIsReplace(false));
           dispatch(copyAndReplaceCostDriverAction.setIsCopy(false));
           if (resetForm) {
            resetForm();
           }
           resetFormData();

           setResetForm(true);
           history.push({
            pathname: `/pricings/cost-summary-by-sku/${pricingId}`,
            state: {
             status: location.state.status,
             volumeId: location.state.volumeId || null,
            },
           });
           window.location.reload();
          }
         });
        }}
       >
        Cancel
       </Button>

       {currentPageName === "sku-characteristic" ? (
        <Button
         bg="#0072CF"
         size="md"
         color="white"
         mx="5"
         isDisabled={!dataToSendDelete || isDisable}
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         onClick={async () => {
          let value = {
           ...dataToSend,
          };
          const copyAndReplacePayload = {
           ...dataToSend,
          };
          // delete copyAndReplacePayload.customFields;
          // delete copyAndReplacePayload.uoMCode;
          // delete copyAndReplacePayload.formatCode;
          // delete copyAndReplacePayload.description;
          if (isEditing) {
           await dispatch(
            pricingCostDriverAction.updateAsNewVersion(value)
           ).then(async (response: any) => {
            if (response?.payload) {
             dispatch(pricingCostDriverAction.setIsEditing(false));
             dispatch(pricingCostDriverAction.setIsSimulate(false));

             //  bug ini jika di disable https://innoark.atlassian.net/browse/OPTSC-1461
             history.push({
              pathname: `/pricings/${urls}/${response?.payload?.result?.costAnalysis?.id}`,
              state: {
               status: response?.payload?.result?.status,
               volumeId: detailPerVolumeId?.result?.id || null,
              },
             });
             window.location.reload();
            }
           });
          }
          if (isReplace) {
           await dispatch(
            copyAndReplaceCostDriverAction.replaceCostAnalysisVolume(
             copyAndReplacePayload
            )
           ).then(async (response: any) => {
            if (response?.payload) {
             dispatch(copyAndReplaceCostDriverAction.setIsReplace(false));
             history.push({
              pathname: `/pricings/${urls}/${response?.payload?.result?.costAnalysis?.id}`,
              state: {
               status: response?.payload?.result?.costAnalysis?.status,
               volumeId: detailPerVolumeId?.result?.id || null,
              },
             });
             window.location.reload();
            }
           });
          }
          if (isCopy) {
           await dispatch(
            copyAndReplaceCostDriverAction.copyCostAnalysisVolume(
             copyAndReplacePayload
            )
           ).then(async (response: any) => {
            if (response?.payload) {
             dispatch(copyAndReplaceCostDriverAction.setIsCopy(false));
             history.push({
              pathname: `/pricings/${urls}/${response?.payload?.result?.costAnalysis?.id}`,
              state: {
               status: response?.payload?.result?.costAnalysis?.status,
               volumeId: detailPerVolumeId?.result?.id || null,
              },
             });
             window.location.reload();
            }
           });
          }
         }}
        >
         Save
        </Button>
       ) : // haveArrayInTmpChangeRowNoCustomField
       detailPerVolumeId?.costAnalysis?.level === 0 ? (
        <Button
         bg="#0072CF"
         size="md"
         mx="5"
         //  isDisabled={valueIsIncludeCalculation || isDisabled}
         isDisabled={isDisable}
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         onClick={async () => {
          let value = {
           ...dataToSend,
          };

          if (detailPerVolumeId?.whatIfUnitCost) {
           value.unitCost = detailPerVolumeId?.whatIfUnitCost;
          }

          const copyAndReplacePayload = {
           ...dataToSend,
          };
          if (isEditing) {
           const payload2 = {
            costAnalysisId: pricingId,
            perVolumeId: detailPerVolumeId?.id,
           };
           await dispatch(
            pricingCostDriverAction.updateAsNewVersion(value)
           ).then(async (response: any) => {
            if (response?.payload) {
             dispatch(pricingCostDriverAction.setIsEditing(false));
             dispatch(pricingCostDriverAction.setIsSimulate(false));
             history.push({
              pathname: `/pricings/${urls}/${response?.payload?.result?.costAnalysis?.id}`,
              state: {
               status: response?.payload?.result?.costAnalysis?.status,
               volumeId: detailPerVolumeId?.result?.id || null,
              },
             });
             await dispatch(
              pricingCostDriverAction.getDetailCostDriversVolume(payload2)
             );
             window.location.reload();
            }
           });
          }

          if (isReplace) {
           await dispatch(
            copyAndReplaceCostDriverAction.replaceCostAnalysisVolume(
             copyAndReplacePayload
            )
           ).then(async (response: any) => {
            if (response?.payload) {
             dispatch(copyAndReplaceCostDriverAction.setIsReplace(false));
             history.push({
              pathname: `/pricings/${urls}/${response?.payload?.result?.costAnalysis?.id}`,
              state: {
               status: response?.payload?.result?.costAnalysis?.status,
               volumeId: detailPerVolumeId?.result?.id || null,
              },
             });
             window.location.reload();
            }
           });
          }

          if (isCopy) {
           await dispatch(
            copyAndReplaceCostDriverAction.copyCostAnalysisVolume(
             copyAndReplacePayload
            )
           ).then(async (response: any) => {
            if (response?.payload) {
             dispatch(copyAndReplaceCostDriverAction.setIsCopy(false));
             history.push({
              pathname: `/pricings/${urls}/${response?.payload?.result?.costAnalysis?.id}`,
              state: {
               status: response?.payload?.result?.costAnalysis?.status,
               volumeId: detailPerVolumeId?.result?.id || null,
              },
             });
             window.location.reload();
            }
           });
          }
         }}
        >
         Save
        </Button>
       ) : (
        <Button
         bg="#0072CF"
         size="md"
         mx="5"
         isDisabled={!isDisableButtonSave || valueIsIncludeCalculation}
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         onClick={async () => {
          let newVal: any = {};
          if (dataToSend.updates.length > 0) {
           const ids = dataToSend.adds.map((i: any) => i.costDriverId);
           const newCustomFiledsData: any = [];
           for (var i = 0; i < ids.length; i++) {
            const newCustomFields = dataToSend.updates.find(
             (item: any) => item.parentCostDriverId === ids[i]
            );
            newCustomFiledsData.push(newCustomFields);
            const dataAdd = dataToSend.adds.map((obj: any, index: number) => ({
             ...obj,
             customFields: newCustomFiledsData[index]?.customFields,
            }));
            newVal = {
             ...dataToSend,
              adds: dataAdd, 
            };
           }
          }
          let value: any =
           dataToSend.adds.length > 0 && dataToSend.updates.length > 0
            ? newVal
            : { ...dataToSend };
          if (detailPerVolumeId?.whatIfUnitCost) {
           value.unitCost = detailPerVolumeId?.whatIfUnitCost;
          }

          const copyAndReplacePayload = {
           ...dataToSend,
          };
          if (isEditing) {
           const payload2 = {
            costAnalysisId: pricingId,
            perVolumeId: detailPerVolumeId?.id,
           };
           await dispatch(
            pricingCostDriverAction.updateAsNewVersion(value)
           ).then(async (response: any) => {
            if (response?.payload) {
             dispatch(pricingCostDriverAction.setIsEditing(false));
             dispatch(pricingCostDriverAction.setIsSimulate(false));
             history.push({
              pathname: `/pricings/${urls}/${response?.payload?.result?.costAnalysis?.id}`,
              state: {
               status: response?.payload?.result?.costAnalysis?.status,
               volumeId: detailPerVolumeId?.result?.id || null,
              },
             });
             await dispatch(
              pricingCostDriverAction.getDetailCostDriversVolume(payload2)
             );
             window.location.reload();
            }
           });
          }

          if (isReplace) {
           await dispatch(
            copyAndReplaceCostDriverAction.replaceCostAnalysisVolume(
             copyAndReplacePayload
            )
           ).then(async (response: any) => {
            if (response?.payload) {
             dispatch(copyAndReplaceCostDriverAction.setIsReplace(false));
             history.push({
              pathname: `/pricings/${urls}/${response?.payload?.result?.costAnalysis?.id}`,
              state: {
               status: response?.payload?.result?.costAnalysis?.status,
               volumeId: detailPerVolumeId?.result?.id || null,
              },
             });
             window.location.reload();
            }
           });
          }

          if (isCopy) {
           await dispatch(
            copyAndReplaceCostDriverAction.copyCostAnalysisVolume(
             copyAndReplacePayload
            )
           ).then(async (response: any) => {
            if (response?.payload) {
             dispatch(copyAndReplaceCostDriverAction.setIsCopy(false));
             history.push({
              pathname: `/pricings/${urls}/${response?.payload?.result?.costAnalysis?.id}`,
              state: {
               status: response?.payload?.result?.costAnalysis?.status,
               volumeId: detailPerVolumeId?.result?.id || null,
              },
             });
             window.location.reload();
            }
           });
          }
         }}
        >
         Save 
        </Button>
       )}
      </>
     ) : (
      <>
       {isFirstComponent() && (
        <Button
         variant={"outline"}
         onClick={async () => {
          history.push(`/pricings`);
          dispatch(pricingCostDriverAction.clear());
          dispatch(pricingCostSummaryAction.clear());
         }}
        >
         Back
        </Button>
       )}
       {isLastComponent() && (
        <Button variant={"outline"} onClick={navigateToPrevComponent}>
         Back
        </Button>
       )}
       {!isLastComponent() && (
        <>
         {location.state.status !== undefined && !isFirstComponent() && (
          <Button variant={"outline"} onClick={navigateToPrevComponent}>
           Back
          </Button>
         )}
         <Button
          bg="#0072CF"
          size="md"
          color="white"
          mx="5"
          onClick={navigateToNextComponent}
         >
          Next
         </Button>
        </>
       )}
      </>
     )}
    </Box>
   </Flex>
  </Box>
 );
};

export default ToolbarBottom;
