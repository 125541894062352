import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";

export const getDetailCostDriversVolume = createAsyncThunk(
 "costAnalysis/getDetailCostAnalysisVolume",
 async ({ costAnalysisId, perVolumeId }: any) => {
   return await HttpService.get(
    `/CostAnalysis/${costAnalysisId}/volume/${perVolumeId}`
   );
 }
);

