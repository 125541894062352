import { FC, ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface StepInfoProps {
 children: ReactNode;
}

const StepInfo: FC<StepInfoProps> = ({ children }) => {
 return (
  <Box
   minH="188px"
   borderRadius="5px"
   bg="rgba(247, 249, 253, 1)"
   p="36px"
  >
   {children}
  </Box>
 );
};

export default StepInfo;
