import { ReactElement, useState, useEffect } from "react";
import { Box, Flex, Heading, Text, Stack } from "@chakra-ui/layout";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import TextInput from "components/TextInput";
import Select from "components/Select";
import ToolbarBottom from "../ToolbarBottom";
import { Formik, Form } from "formik";
import { actions as masterDataConfigurationAction } from "state/masterDataConfiguration/slice";
import { actions as masterDataAction } from "state/masterData/slice";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";
import { TableLoading } from "components/Loading";
import { isEmpty, orderBy } from "lodash";
import * as Yup from "yup";
import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
// getThirdPartyToShow

const FormInput = (): ReactElement => {
 const [optionFormat, setOptionFormat] = useState<any>([]);
 const [optionThirdParty, setOptionThirdParty] = useState<any>([]);
 const [optionCurrency, setOptionCurrency] = useState<any>([]);
 const [optionUoM, setOptionUoM] = useState<any>([]);
 const [optionCountry, setOptionCountry] = useState<any>([]);
 const [optionBrand, setOptionBrand] = useState<any>([]);
 const [optionSupplier, setOptionSupplier] = useState<any>([]);
 const [optionFactory, setOptionFactory] = useState<any>([]);
 const [optionAny, setOptionAny] = useState<any>([]);
 const dispatch = useAppDispatch();
 const router = useHistory();
 const location = useLocation();
 const masterDataId = location.pathname.split("/")[2];
 const {
  loading,
  isEditing,
  dataToSend,
  data,
  detail,
  dataId,
  isView,
  isCreated,
  lists,
  currencyId,
  exchangeRateDetail,
 } = useSelector(
  (state: RootState) => ({
   loading: state.masterDataConfiguration.loading,
   searchQuery: state.masterDataConfiguration.searchQuery,
   data: state.masterDataConfiguration.data,
   isEditing: state.masterData.isEditing,
   dataToSend: state.masterData.dataToSend,
   detail: state.masterData.detail.result,
   dataId: state.masterData.masterDataId,
   isView: state.masterData.isView,
   isCreated: state.masterData.isCreated,
   thirdParty: state.thirdPartySetting.thirdParty,
   masterDataList: state.masterData.lists,
   lists: state.multiCurrency.lists,
   currencyId: state.thirdPartySetting.currencyId,
   exchangeRateDetail: state.multiCurrency.exchangeRateDetail,
  }),
  shallowEqual
 );

 const fetchExchangeDetail = async (payload: any) => {
  await dispatch(multiCurrencyAction.exchangeDetail(payload));
 };
 const fetchList = async (payload: any) => {
  await dispatch(multiCurrencyAction.listMultiCurrency(payload));
 };
 const getDefaultCurrency = async () => {
  await dispatch(thirdPartySettingAction.configurationStatus());
 };
 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);

 useEffect(() => {
  const payload = {
   skipCount: 0,
   MaxResultCount: 1000,
  };
  Promise.all([fetchList(payload), getDefaultCurrency()]);
  if (!isEmpty(currencyId) && lists.length > 0) {
   const validCurrency = lists.find(
    (item: any) => item?.currency?.id === currencyId
   );
   fetchExchangeDetail(validCurrency?.id);
  }
 }, [currencyId]);

 useEffect(() => {
  if (isEditing || isView) {
   editConfig();
  } else {
   config();
  }
 }, []);
 useEffect(() => {
  if (isEditing || isView) {
   dispatch(
    masterDataAction.setDataToSend({
     configurationId: isView
      ? detail?.configuration?.id || "-"
      : detail?.configuration?.id || "",
     code: isView ? detail?.code || "-" : detail?.code || "",
     description: isView
      ? detail?.description || "-"
      : detail?.description || "",
     cost: detail?.cost || 0,
     thirdPartyId: isView
      ? detail?.thirdParty?.id || "-"
      : isEditing
      ? detail?.thirdParty?.id
      : detail?.thirdParty?.id || "-",
     customFields:
      orderBy(detail?.customFields, (item) => item.configuration.sequence, [
       "asc",
      ]).map((item: any) => ({
       masterDataCustomFieldConfigurationId: isView
        ? item?.configuration?.id
        : item?.configuration?.id || "",
       value:
        item.configuration.dataType !== 30
         ? item?.value
         : item.masterDataReference?.id,
       masterDataReferenceId: isView
        ? item?.masterDataReference?.id
        : item?.masterDataReference?.id || "",
       dataType: item.dataType,
       currencyCode: item?.currencyCode || "",
       rateValue: "",
      })) || [],
     currencyCode: detail?.currency?.code || "",
     rateValue: "",
    })
   );
   detail?.configuration?.isAssociateWithThirdParty && fetchListOf3rdParty(0);
  } else {
   dispatch(
    masterDataAction.setDataToSend({
     configurationId: data?.id,
     code: "",
     description: "",
     cost: 0,
     thirdPartyId: "",
     customFields:
      data?.customFields?.map((item: any) => ({
       masterDataCustomFieldConfigurationId: item.id,
       value: item.dataType === 20 || item.dataType === 50 ? 0 : "",
       masterDataReferenceId: "",
       dataType: item.dataType,
       currencyCode:
        (item.dataType === 50 && exchangeRateDetail?.currency?.code) || "",
       rateValue: "",
      })) || [],
     currencyCode: currencyId ? exchangeRateDetail.currency?.code : "",
     rateValue: "",
    })
   );
   data?.isAssociateWithThirdParty && fetchListOf3rdParty(0);
  }
 }, [detail, data, location]);
 const fetchListOf3rdParty = (skipCount: number) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: 200,
  };

  return dispatch(thirdPartySettingAction.getThirdPartyToShow(payload)).then(
   (response: any) => {
    const options = response?.payload;
    const newOptions: any = [];
    if (options?.items?.length > 0) {
     options?.items?.map((item: any) =>
      newOptions.push({
       label: item.description,
       value: item.id,
      })
     );
     const sort = (x: any, y: any) => {
      return x.label.localeCompare(y.label);
     };
     setOptionThirdParty(newOptions.sort(sort));
    }
   }
  );
 };
 const config = async () => {
  dispatch(masterDataAction.resetData());
  await dispatch(
   masterDataConfigurationAction.masterDataConfigurationsDetails(masterDataId)
  ).then((masterDataResponse: any) => {
   const options = masterDataResponse?.payload;

   if (options.customFields.length > 0) {
    const isMasterDataConfiguration = options.customFields.filter(
     (item: any) => item.masterDataConfiguration !== null
    );
    isMasterDataConfiguration.map((item: any) =>
     dispatch(
      masterDataAction.masterDataList({
       skipCount: 0,
       maxResultCount: 1000,
       searchKey: "",
       configurationId: item?.masterDataConfiguration?.id,
       type: 0,
       thirdPartyId: "",
      })
     ).then((response: any) => {
      const items = response?.payload?.result?.items;
      const newOptions: any = [];
      items.map((item: any) =>
       newOptions.push({
        label: item.code,
        value: item.id,
       })
      );
      const sort = (x: any, y: any) => {
       return x.label.localeCompare(y.label);
      };
      if (items[0]?.configuration?.identityCode === "Format") {
       setOptionFormat(newOptions.sort(sort));
      }
      if (items[0]?.configuration?.identityCode === "Currency") {
       setOptionCurrency(newOptions.sort(sort));
      }
      if (items[0]?.configuration?.identityCode === "UoM") {
       setOptionUoM(newOptions.sort(sort));
      }
      if (items[0]?.configuration?.identityCode === "Country") {
       setOptionCountry(newOptions.sort(sort));
      }
      if (items[0]?.configuration?.identityCode === "Brand") {
       setOptionBrand(newOptions.sort(sort));
      }
      if (items[0]?.configuration?.identityCode === "Supplier") {
       setOptionSupplier(newOptions.sort(sort));
      }
      if (items[0]?.configuration?.identityCode === "Factory") {
       setOptionFactory(newOptions.sort(sort));
      } else {
       setOptionAny(newOptions);
      }
     })
    );
   }
  });
 };

 const editConfig = async () => {
  const payload = {
   configurationId: masterDataId,
   id: dataId,
  };
  dispatch(masterDataConfigurationAction.resetData());
  await dispatch(masterDataAction.masterDataListDetail(payload)).then(
   (masterDataResponse: any) => {
    const options = masterDataResponse?.payload.result;
    if (options.customFields.length > 0) {
     options.customFields.map((item: any) =>
      dispatch(
       masterDataAction.masterDataList({
        skipCount: 0,
        maxResultCount: 1000,
        searchKey: "",
        configurationId: item?.configuration?.masterDataConfiguration?.id,
        type: 0,
        thirdPartyId: "",
       })
      ).then((response: any) => {
       const data = response?.payload?.result?.items;

       const newOptions: any = [];
       data?.map((item: any) =>
        newOptions.push({
         label: item.code,
         value: item.id,
        })
       );
       const sort = (x: any, y: any) => {
        return x.label.localeCompare(y.label);
       };
       if (data[0]?.configuration?.identityCode === "Format") {
        setOptionFormat(newOptions.sort(sort));
       }
       if (data[0]?.configuration?.identityCode === "Currency") {
        setOptionCurrency(newOptions.sort(sort));
       }
       if (data[0]?.configuration?.identityCode === "UoM") {
        setOptionUoM(newOptions.sort(sort));
       }
       if (data[0]?.configuration?.identityCode === "Country") {
        setOptionCountry(newOptions.sort(sort));
       }
       if (data[0]?.configuration?.identityCode === "Brand") {
        setOptionBrand(newOptions.sort(sort));
       }
       if (data[0]?.configuration?.identityCode === "Supplier") {
        setOptionSupplier(newOptions.sort(sort));
       }
       if (data[0]?.configuration?.identityCode === "Factory") {
        setOptionFactory(newOptions.sort(sort));
       } else {
        setOptionAny(newOptions);
       }
      })
     );
    }
   }
  );
 };
 let newDataConfiguration: any = [];
 if (data) {
  newDataConfiguration = [];
  if (data.customFields?.length > 0) {
   data?.customFields.map((item: any) =>
    newDataConfiguration.push({
     label: item.displayName,
     value: item.dataType,
     ref: item?.masterDataConfiguration?.identityCode,
     isRequired: item?.isRequired,
    })
   );
  }
 }
 if (detail) {
  newDataConfiguration = [];
  if (detail.customFields?.length > 0) {
   orderBy(detail?.customFields, (item) => item.configuration.sequence, [
    "asc",
   ]).map((item: any) =>
    newDataConfiguration.push({
     label: item?.configuration?.displayName,
     value: item?.configuration?.dataType,
     ref: item?.configuration?.masterDataConfiguration?.identityCode,
     isRequired: item?.configuration?.isRequired,
    })
   );
  }
 }
 if (!isEditing && !isView && !isCreated) {
  return <Redirect to="/master-data" />;
 }
 const validation = Yup.object().shape({
  code: Yup.string().required("This information are required"),
  description: Yup.string().required("This information are required"),
 });
 const currencyOpt: any = [];
 lists?.items?.map((item: any) => {
  currencyOpt.push({
   value: item?.code,
   label: item?.code,
  });
 });

 return (
  <Box>
   <Heading as="h2" fontSize="22px" mt="2rem" mb={5}>
    {isEditing && "Edit Data"}
    {isView && "View Data"}
    {isCreated && "Create New"}
   </Heading>
   {!loading ? (
    <Box
     border={"1px"}
     borderColor="#1A1A1A29"
     borderRadius="5px"
     p={5}
     mb="3rem"
    >
     <Formik
      enableReinitialize
      initialValues={dataToSend}
      validationSchema={validation}
      validateOnMount
      onSubmit={(values: any) => {
       const data = {
        payload: values,
        id: detail?.id,
       };
       isEditing
        ? dispatch(masterDataAction.updateMasterData(data)).then(
           (response: any) => {
            if (response.payload.success) {
             dispatch(masterDataAction.setEditing(false));
             router.goBack();
            }
           }
          )
        : dispatch(masterDataAction.createMasterData(values)).then(
           (response: any) => {
            if (response.payload.success) {
             dispatch(masterDataAction.setCreated(false));
             router.goBack();
            }
           }
          );
      }}
     >
      {({ values, setFieldValue, setFieldError, setValues }) => (
       <Form>
        <Flex pl="4">
         <Box mt={"1rem"} w={150}>
          <Text my={5}>
           {data?.code || detail?.configuration?.code}
           <span style={{ color: "red" }}>*</span>
          </Text>
         </Box>
         <Box pl="5" mt={"1rem"} w={250}>
          <TextInput
           name="code"
           label=""
           isDisabled={isView || isEditing}
           id={data?.code}
           placeholder="e.g Material Code"
           onChange={(val) => {
            setFieldValue(`code`, val.target.value);
            //  setActived(!actived);
           }}
          />
         </Box>
        </Flex>
        <Flex pl="4">
         <Box mt={"1rem"} w={150}>
          <Text my={5}>
           {data?.description || detail?.configuration?.description}
           <span style={{ color: "red" }}>*</span>
          </Text>
         </Box>
         <Box pl="5" mt={"1rem"} w={250}>
          <TextInput
           name="description"
           label=""
           isDisabled={isView}
           id={data?.description}
           placeholder="e.g Material Desc"
          />
         </Box>
        </Flex>
        {!isEditing && !isView && data?.cost !== "" && (
         <Flex pl="4">
          <Box mt={"1rem"} w={150}>
           <Text my={5}>
            {data?.cost || detail?.configuration?.cost}
            <span style={{ color: "red" }}>*</span>
           </Text>
          </Box>
          <Stack
           direction={["column", "row"]}
           mt={"1rem"}
           spacing="24px"
           alignItems="center"
          >
           <Box pl="5" w={250}>
            <TextInput
             name="cost"
             value={values.cost || 0}
             label=""
             isDisabled={isView}
             id={data?.cost}
             placeholder="e.g 1000"
             onPaste={(event) => {
              event.preventDefault();
              return false;
             }}
             onKeyPress={(event) => {
              if (!/^\d*\.?\d*$/.test(event.key)) {
               event.preventDefault();
               setFieldError(`cost`, "The field should have number only");
              }
             }}
             onChange={(val) => {
              setFieldValue(`cost`, val.target.value);
             }}
            />
           </Box>
           <Box>
            <SelectWithAutoComplete
             width={200}
             defaultValue={currencyId}
             mt={9}
             options={currencyOpt}
             name="currencyCode"
             id="currencyCode"
             onChange={(e: any) => {
              setFieldValue(`currencyCode`, e.value);
             }}
            />
           </Box>
          </Stack>
         </Flex>
        )}
        {isView && detail?.configuration?.isIncludeCostValue && (
         <Flex pl="4">
          <Box mt={"1rem"} w={150}>
           <Text my={5}>
            {data?.cost || detail?.configuration?.cost}
            <span style={{ color: "red" }}>*</span>
           </Text>
          </Box>
          <Stack direction={["column", "row"]} mt={"1rem"} spacing="24px">
           <Box pl="5" w={250}>
            <TextInput
             name="cost"
             label=""
             isDisabled={isView}
             id={data?.cost}
             placeholder="e.g 1000"
            />
           </Box>
           <Box>
            <SelectWithAutoComplete
             width={200}
             defaultValue=""
             options={currencyOpt}
             name="currencyCode"
             id="currencyCode"
             isDisabled
             onChange={(e: any) => {}}
            />
           </Box>
          </Stack>
         </Flex>
        )}
        {isEditing && detail?.configuration?.isIncludeCostValue && (
         <Flex pl="4">
          <Box mt={"1rem"} w={150}>
           <Text my={5}>
            {data?.cost || detail?.configuration?.cost}
            <span style={{ color: "red" }}>*</span>
           </Text>
          </Box>
          <Stack direction={["column", "row"]} mt={"1rem"} spacing="24px">
           <Box pl="5" w={250}>
            <TextInput
             name="cost"
             label=""
             isDisabled={isView}
             id={data?.cost}
             placeholder="e.g 1000"
             onPaste={(event) => {
              event.preventDefault();
              return false;
             }}
             onKeyPress={(event) => {
              if (!/^\d*\.?\d*$/.test(event.key)) {
               event.preventDefault();
               setFieldError(`cost`, "The field should have number only");
              }
             }}
             onChange={(val) => {
              setFieldValue(`cost`, val.target.value);
             }}
            />
           </Box>
           <Box pt={"10px"}>
            <SelectWithAutoComplete
             width={200}
             defaultValue=""
             options={currencyOpt}
             mt={-2}
             name="currencyCode"
             id="currencyCode"
             onChange={(e: any) => {
              setFieldValue(`currencyCode`, e.value);
             }}
            />
           </Box>
          </Stack>
         </Flex>
        )}
        {isEditing && detail?.configuration?.isAssociateWithThirdParty && (
         <Flex pl="4">
          <Box mt={"1rem"} w={150}>
           <Text my={5}>
            Third-party
            <span style={{ color: "red" }}>*</span>
           </Text>
          </Box>
          <Box pl="5" mt={"1rem"} w={250}>
           <Select
            name={`thirdPartyId`}
            id={`thirdPartyId`}
            isDisabled={isView}
            onChange={(value) => {
             setFieldValue(`thirdPartyId`, value.currentTarget.value);
            }}
            data={optionThirdParty}
           />
          </Box>
         </Flex>
        )}
        {isView && detail?.configuration?.isAssociateWithThirdParty && (
         <Flex pl="4">
          <Box mt={"1rem"} w={150}>
           <Text my={5}>
            Third-party
            <span style={{ color: "red" }}>*</span>
           </Text>
          </Box>
          <Box pl="5" mt={"1rem"} w={250}>
           <Select
            name={`thirdPartyId`}
            id={`thirdPartyId`}
            isDisabled={isView}
            onChange={(value) => {
             setFieldValue(`thirdPartyId`, value.currentTarget.value);
            }}
            data={optionThirdParty}
           />
          </Box>
         </Flex>
        )}
        {!isEditing && data?.isAssociateWithThirdParty && (
         <Flex pl="4">
          <Box mt={"1rem"} w={150}>
           <Text my={5}>
            Third-party
            <span style={{ color: "red" }}>*</span>
           </Text>
          </Box>
          <Box pl="5" mt={"1rem"} w={250}>
           <Select
            name={`thirdPartyId`}
            id={`thirdPartyId`}
            isDisabled={isView}
            onChange={(value) => {
             setFieldValue(`thirdPartyId`, value.currentTarget.value);
            }}
            data={optionThirdParty}
           />
          </Box>
         </Flex>
        )}
        {newDataConfiguration.length > 0 &&
         newDataConfiguration.map((item: any, index: number) => {
          return (
           <Flex pl="4" key={index}>
            <Box mt={"1rem"} w={150}>
             <Text my={5}>
              {item.label}
              {item.isRequired && <span style={{ color: "red" }}>*</span>}
             </Text>
            </Box>
            <Box pl="5" mt={"1rem"}>
             {item.value === 30 ? (
              isView && !values.customFields[index]?.value ? (
               <TextInput
                name={`customFields[${index}].value`}
                isDisabled={isView}
                placeholder=""
                value="-"
                id={item.value}
               />
              ) : (
               <SelectWithAutoComplete
                width={230}
                options={
                 newDataConfiguration[index].ref === "UoM"
                  ? optionUoM
                  : newDataConfiguration[index].ref === "Brand"
                  ? optionBrand
                  : newDataConfiguration[index].ref === "Country"
                  ? optionCountry
                  : newDataConfiguration[index].ref === "Currency"
                  ? optionCurrency
                  : newDataConfiguration[index].ref === "Format"
                  ? optionFormat
                  : newDataConfiguration[index].ref === "Supplier"
                  ? optionSupplier
                  : newDataConfiguration[index].ref === "Factory"
                  ? optionFactory
                  : optionAny || []
                }
                isDisabled={isView}
                name={`customFields[${index}].masterDataReferenceId`}
                id={`customFields[${index}].masterDataReferenceId`}
                onChange={(e: any) => {
                 setFieldValue(
                  `customFields[${index}].masterDataReferenceId`,
                  e.value
                 );
                }}
               />
              )
             ) : (
              <Stack direction={["column", "row"]} spacing="24px">
               <Box>
                <TextInput
                 width={230}
                 type={[20, 50].includes(item.value) ? "number" : "text"}
                 name={`customFields[${index}].value`}
                 isDisabled={isView}
                 placeholder={
                  item.value === 10 ? "e.g Material Name" : "e.g 1000"
                 }
                 value={
                  [20, 50].includes(item.value) &&
                  isEmpty(values?.customFields[index]?.value)
                   ? 0
                   : item.value === 10 &&
                     isEmpty(values?.customFields[index]?.value) &&
                     isView
                   ? "-"
                   : values?.customFields[index]?.value
                 }
                 label=""
                 id={item.value}
                 onChange={(val) => {
                  setFieldValue(
                   `customFields[${index}].value`,
                   val.target.value
                  );
                 }}
                 onPaste={(event) => {
                  event.preventDefault();
                  return false;
                 }}
                 onKeyPress={(event) => {
                  if (
                   [20, 50].includes(item.value) &&
                   !/^\d*\.?\d*$/.test(event.key)
                  ) {
                   event.preventDefault();
                   setFieldError(
                    `customFields[${index}].value`,
                    "The field should have number only"
                   );
                  }
                 }}
                />
               </Box>
               {item.value === 50 && (
                <Box pt={"10px"}>
                 <SelectWithAutoComplete
                  width={200}
                  //  defaultValue={currencyId}
                  options={currencyOpt}
                  isDisabled={isView}
                  name={`customFields[${index}].currencyCode`}
                  id={`customFields[${index}].currencyCode`}
                  onChange={(e: any) => {
                   setFieldValue(
                    `customFields[${index}].currencyCode`,
                    e.value
                   );
                  }}
                 />
                </Box>
               )}
              </Stack>
             )}
            </Box>
           </Flex>
          );
         })}
        {!isView && <ToolbarBottom loading={loading} />}
       </Form>
      )}
     </Formik>
    </Box>
   ) : (
    <TableLoading />
   )}
  </Box>
 );
};

export default FormInput;
