import { ReactElement } from "react";
import { Image, Flex, Text } from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";

import OptipediaToast from "utils/notification-util";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import AddIcon from "assets/images/plus-icon.png";

// test
const AddRow = ({
 values,
 costDriver,
 paramName,
 arrayHelpers,
 onClick,
}: any): ReactElement => {
 const dispatch = useAppDispatch();
 const {
  detailPerVolumeId,
  temporaryDataCostDriver,
  valueIsIncludeCalculation,
  loading
 } = useSelector(
  (state: RootState) => ({
    loading: state.editPricingCostDrivers.loading,
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId.result,
   temporaryDataCostDriver:
    state.editPricingCostDrivers.temporaryDataCostDriver,
   valueIsIncludeCalculation:
    state.editPricingCostDrivers.valueIsIncludeCalculation,
   emptyCostDrivers: state.editPricingCostDrivers.emptyCostDrivers,
   objectsIndexToDelete: state.editPricingCostDrivers.objectsIndexToDelete,
  }),
  shallowEqual
 );

 return (
  <Flex
   alignItems="center"
   gap="2"
   cursor={
    !valueIsIncludeCalculation && costDriver.canAddNew && !loading
     ? "pointer"
     : "not-allowed"
   }
   onClick={async (e) => {
    if (!valueIsIncludeCalculation && costDriver.canAddNew && !loading) {
     e.preventDefault();
     const arr: any = [];
     let abc: any = {};
     const latestArray = values?.customFields[values?.customFields.length - 1];
     dispatch(pricingCostDriverAction.setIsEditOpen(true));
     dispatch(pricingCostDriverAction.setNullCustomFields());
     const newCustomFields = [] as any;
      costDriver.headers.map(({ convertName }: any) => {
       arr.push({
        [convertName]:
         convertName === "currency" || convertName === "totalCost" ? "" : "",
       });
      });

      arr.map((obj: any) => {
       const objKey = Object.keys(obj)[0];
       abc[objKey] =
        objKey === "currency"
         ? detailPerVolumeId?.costAnalysis?.currencyCode
         : "";
      });

      costDriver.data.length > 0 &&
       costDriver.data[0].customFields.map((customField: any) => {
        newCustomFields.push({
         ...customField,
         value:
          customField.typeData === 20
           ? 0
           : customField.typeData === 30 &&
             costDriver.data[0].masterDataConfiguration === null
           ? ""
           : customField.typeData === 10
           ? ""
           : customField.value,
        });
       });

      abc.isNew = true;
      abc.isErrorDuplicate = false;
      abc.id = costDriver.costDriverId;
      abc.customFields = newCustomFields;
      abc.masterDataCustomFields = costDriver?.data[0]?.masterDataCustomFields;
      arrayHelpers.push(abc);
      const findIdParam = temporaryDataCostDriver.findIndex(
       (el: any) => el.name === paramName
      );
      const findIndex = temporaryDataCostDriver[findIdParam]?.data.findIndex(
       (el: any) => el.identityName == costDriver.identityName
      );
      abc.index = findIndex;
      abc.temporaryDataCostDriverId = findIdParam;
      abc.totalCost = 0;
      abc.unitCost = 0;
      abc.masterDataId = "";
      abc.currencyId = costDriver?.data[0].currencyId;
      abc.masterDataConfiguration =
       costDriver?.data[0]?.masterDataConfiguration;
      dispatch(pricingCostDriverAction.setAddNew(true));
      setTimeout(() => dispatch(pricingCostDriverAction.setAddTemporaryDataCostDriver(abc)), 700)
      onClick(abc);
     
    //  if (latestArray && latestArray.isNew) {
    //   if (latestArray?.masterDataConfiguration === null) {
    //    delete latestArray.masterDataId;
    //    delete latestArray.masterDataConfiguration;
    //    delete latestArray.masterDataCustomFields;
    //   }
    //   const entries = Object.entries(latestArray);
    //   let isNull = false;
    //   entries.forEach(([key, value]) => {
    //    const staticValue =
    //     key === "currency" ||
    //     key === "totalCost" ||
    //     key === "isNew" ||
    //     key === "id";

    //    if (!staticValue && value === "") {
    //     isNull = true;
    //    }
    //   });

    //   values?.customFields.map((row: any) => {
    //    const currentRow = new Map(Object.entries(row));
    //    costDriver.headers.map(({ convertName }: any) => {
    //     arr.push({
    //      [convertName]:
    //       convertName === "currency" || convertName === "totalCost"
    //        ? currentRow.get(convertName)
    //        : "",
    //     });
    //    });

    //    arr.map((obj: any, item: any) => {
    //     const objKey = Object.keys(obj)[0];
    //     const customFields: any = currentRow.get("customFields");
    //     const findKey = customFields?.find(
    //      (item: any, index: any) => item.name === objKey
    //     );
    //     abc[objKey] =
    //      objKey === "currency"
    //       ? currentRow.get(objKey)
    //       : findKey?.isIncludeInCalculation
    //       ? 0
    //       : "";
    //    });
    //    const newCustomFields = [] as any;
    //    const customFields = currentRow.get("customFields");

    //    Array.isArray(customFields) &&
    //     customFields.map((customField) => {
    //      if (!customField.isRequired) {
    //       isNull = false;
    //      }

    //      newCustomFields.push({
    //       ...customField,
    //       value: customField.typeData === 20 ? 0 : "",
    //      });
    //     });

    //    const getIdMasterData: any = currentRow.get("masterDataConfiguration");
    //    abc.name = getIdMasterData ? getIdMasterData?.id : "";

    //    abc.totalCost = 0;
    //    abc.unitCost = 0;
    //    abc.masterDataId = "";
    //    abc.isNew = true;
    //    abc.isErrorDuplicate = false;
    //    abc.id = costDriver.costDriverId;
    //    abc.customFields = newCustomFields;
    //    abc.masterDataCustomFields = costDriver.data[0].masterDataCustomFields;
    //    abc.masterDataConfiguration = currentRow.get("masterDataConfiguration");
    //   });

    //   if (isNull) {
    //    OptipediaToast.Warning("First complete the newly added please.");
    //   } else {
    //    const newCustomFields = [] as any;
    //    arrayHelpers.push(abc);
    //    const findIdParam = temporaryDataCostDriver.findIndex(
    //     (el: any) => el.name === paramName
    //    );
    //    const findIndex = temporaryDataCostDriver[findIdParam]?.data.findIndex(
    //     (el: any) => el.identityName == costDriver.identityName
    //    );
    //    costDriver.data.length > 0 &&
    //     costDriver.data[0].customFields.map((customField: any) => {
    //      newCustomFields.push({
    //       ...customField,
    //       value: customField.typeData === 20 ? 0 : "",
    //      });
    //     });
    //    abc.index = findIndex;
    //    abc.temporaryDataCostDriverId = findIdParam;
    //    abc.totalCost = 0;
    //    abc.customFields = newCustomFields;
    //    abc.unitCost = 0;

    //    if (abc.masterDataConfiguration) {
    //     abc.masterDataId = "";
    //    }
    //    abc.isNew = true;
    //    abc.isErrorDuplicate = false;
    //    dispatch(pricingCostDriverAction.setAddNew(true));
    //    dispatch(pricingCostDriverAction.setAddTemporaryDataCostDriver(abc));
    //    onClick(abc);
    //   }
    //  } else {
    //   const newCustomFields = [] as any;
    //   costDriver.headers.map(({ convertName }: any) => {
    //    arr.push({
    //     [convertName]:
    //      convertName === "currency" || convertName === "totalCost" ? "" : "",
    //    });
    //   });

    //   arr.map((obj: any) => {
    //    const objKey = Object.keys(obj)[0];
    //    abc[objKey] =
    //     objKey === "currency"
    //      ? detailPerVolumeId?.costAnalysis?.currencyCode
    //      : "";
    //   });

    //   costDriver.data.length > 0 &&
    //    costDriver.data[0].customFields.map((customField: any) => {
    //     newCustomFields.push({
    //      ...customField,
    //      value:
    //       customField.typeData === 20
    //        ? 0
    //        : customField.typeData === 30 &&
    //          costDriver.data[0].masterDataConfiguration === null
    //        ? ""
    //        : customField.typeData === 10
    //        ? ""
    //        : customField.value,
    //     });
    //    });

    //   abc.isNew = true;
    //   abc.isErrorDuplicate = false;
    //   abc.id = costDriver.costDriverId;
    //   abc.customFields = newCustomFields;
    //   abc.masterDataCustomFields = costDriver?.data[0]?.masterDataCustomFields;
    //   arrayHelpers.push(abc);
    //   const findIdParam = temporaryDataCostDriver.findIndex(
    //    (el: any) => el.name === paramName
    //   );
    //   const findIndex = temporaryDataCostDriver[findIdParam]?.data.findIndex(
    //    (el: any) => el.identityName == costDriver.identityName
    //   );
    //   abc.index = findIndex;
    //   abc.temporaryDataCostDriverId = findIdParam;
    //   abc.totalCost = 0;
    //   abc.unitCost = 0;
    //   abc.masterDataId = "";
    //   abc.currencyId = costDriver?.data[0].currencyId;
    //   abc.masterDataConfiguration =
    //    costDriver?.data[0]?.masterDataConfiguration;
    //   dispatch(pricingCostDriverAction.setAddNew(true));
    //   dispatch(pricingCostDriverAction.setAddTemporaryDataCostDriver(abc));
    //   onClick(abc);
    //  }
    }
   }}
  >
   <Image src={AddIcon} alt="Bulp" />
   <Text pt="5px" color="rgba(0, 112, 199, 1)">
    Add
   </Text>
  </Flex>
 );
};

export default AddRow;
