import { RootState } from "state/store";
import { FC, useEffect, useState, useRef } from "react";
import {
 Box,
 Button,
 Stack,
 Grid,
 Stat,
 StatLabel,
 StatNumber,
 StatHelpText,
 StatArrow,
 Breadcrumb,
 BreadcrumbItem,
 BreadcrumbLink,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Td,
 Tbody,
 Input,
 IconButton,
 Tooltip,
 Flex,
 Switch,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import { useAppDispatch } from "hooks";
import { useHistory } from "react-router-dom";
import { actions as simulateAction } from "state/simulation/slice";
import { BarChartLoading, NumberLoading } from "components/Loading";
import ReactApexChart from "react-apexcharts";
import { isEmpty, orderBy } from "lodash";
import ChartModal from "./chartModal";
import SaveBusinessCase from "./saveBusinessCase";

const SummaryOverview: FC = () => {
 const dispatch = useAppDispatch();
 const router = useHistory();
 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);
 let {
  loading,
  describeChangeResponse,
  describeChangePayload,
  journey,
  journeyId,
  journeyLevel,
 } = useSelector(
  (state: RootState) => ({
   loading: state.simulationAggregate.loading,
   describeChangeResponse: state.simulationAggregate.describeChangeResponse,
   describeChangePayload: state.simulationAggregate.describeChangePayload,
   journey: state.simulationAggregate.journey,
   journeyId: state.simulationAggregate.journeyId,
   journeyLevel: state.simulationAggregate.journeyLevel,
  }),
  shallowEqual
 );
 useEffect(() => {
  if (describeChangeResponse === isEmpty()) {
   router.push("/simulations");
  }
 }, []);

 const { isOpen, onOpen, onClose } = useDisclosure();
 const [status, setStatus] = useState(false);
 const [barSummary, setBarSummary] = useState("summary");
 const [treemapSummary, setTeemapSummary] = useState("summary");
 const [chartType, setChartType] = useState(false);

 const initialRef = useRef();
 const finalRef = useRef();

 const changeBar = (value: any) => {
  setBarSummary(value);
 };
 const changeTreemap = (value: any) => {
  setTeemapSummary(value);
 };

 const treemapDataSummary = describeChangeResponse.summaryChart;
 const treemapDataLevel = describeChangeResponse.chartLevel1;
 const treemapDataLevel2 = describeChangeResponse.chartLevel2;
 const tmpTreemap =
  treemapSummary == "summary"
   ? treemapDataSummary?.map((x: any) => ({
      x: x.identityName,
      y: !chartType ? x.currentContribution * 100 : x.whatIfContribution * 100,
      z: x.whatIfTotalSpend,
     }))
   : treemapSummary === "level1"
   ? treemapDataLevel?.map((x: any) => ({
      x: x.identityName,
      y: !chartType ? x.currentContribution * 100 : x.whatIfContribution * 100,
      z: x.currentTotalSpend,
     }))
   : treemapDataLevel2?.map((x: any) => ({
      x: x.identityName,
      y: !chartType ? x.currentContribution * 100 : x.whatIfContribution * 100,
      z: x.currentTotalSpend,
     }));

 const series = [
  {
   name: "Name",
   data: orderBy(tmpTreemap, "y", "desc"),
  },
 ];
 const options = {
  chart: {
   toolbar: {
    show: false,
   },
  },
  grid: {
   padding: {
    left: 15,
   },
  },
  colors: ["#0061BD"],
  legend: {
   show: true,
  },
  tooltip: {
   custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
    var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

    return (
     '<ul style="margin: 1rem; list-style-type:none;">' +
     `<li><b>- ${data.x}: </b>` +
     data.y.toLocaleString("en-US") +
     "%" +
     "</li>" +
     "<li><b>- Total Spend: </b>" +
     "$" +
     data.z.toLocaleString("en-US") +
     "</li>" +
     "</ul>"
    );
   },
   style: {
    fontSize: "12px",
    padding: "2rem",
   },
  },
  plotOptions: {
   treemap: {
    enableShades: true,
   },
  },
  dataLabels: {
   enabled: true,
   style: {
    fontSize: "12px",
   },
   formatter: function (text: any, op: any) {
    const data: any =
     op.value !== 0 ? [text, op.value.toLocaleString("en-US") + " %"] : "";
    return data;
   },
  },
 };

 const barDataSummary = describeChangeResponse.summaryChart;
 const barDataLevel = describeChangeResponse.chartLevel1;
 const barDataLevel2 = describeChangeResponse.chartLevel2;

 const tmpBarCurrent =
  barSummary == "summary"
   ? barDataSummary?.map((x: any) => ({
      x: x.identityName,
      y: x.currentCost,
     }))
   : barSummary == "level1"
   ? barDataLevel?.map((x: any) => ({
      x: x.identityName,
      y: x.currentCost,
     }))
   : barDataLevel2?.map((x: any) => ({
      x: x.identityName,
      y: x.currentCost,
     }));
 const tmpBarWhatif =
  barSummary == "summary"
   ? barDataSummary?.map((x: any) => ({
      x: x.identityName,
      y: x.whatIfCost,
     }))
   : barSummary === "level1"
   ? barDataLevel?.map((x: any) => ({
      x: x.identityName,
      y: x.whatIfCost,
     }))
   : barDataLevel2?.map((x: any) => ({
      x: x.identityName,
      y: x.whatIfCost,
     }));

 const seriesBar = [
  {
   name: "Current",
   data: tmpBarCurrent,
  },
  {
   name: "What-If",
   data: tmpBarWhatif,
  },
 ];
 const barOptions = {
  colors: ["#C3C7CD", "#0061BD"],
  chart: {
   toolbar: {
    show: false,
   },
   parentHeightOffset: 0,
  },
  plotOptions: {
   bar: {
    horizontal: false,
    barWidth: "50%",
    dataLabels: {
     position: "bottom",
    },
   },
  },
  dataLabels: {
   enabled: false,
  },
  xaxis: {
   categories: tmpBarCurrent?.map((x: any) => x.x),
  },
  yaxis: {
   labels: {
    formatter: function (val: any) {
     return val.toLocaleString("en-US");
    },
   },
  },
  legend: {
   show: true,
   showForSingleSeries: false,
   showForNullSeries: true,
   showForZeroSeries: true,
   fontSize: "14px",
   fontFamily: "Helvetica, Arial",
   fontWeight: 400,
   inverseOrder: false,
  },
 };
 const dataToMap =
  journeyLevel === "Level 1"
   ? describeChangeResponse.chartLevel1
   : journeyLevel === "Level 2"
   ? describeChangeResponse.chartLevel2
   : describeChangeResponse.summaryChart;

 return (
  <Box mb="6rem">
   <Grid templateColumns="repeat(4, 1fr)" gap={2} pt="2rem" pb="1rem">
    {!loading ? (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="40px"
     >
      <Stat>
       <StatLabel color="#9DA1A7" fontSize="16px">
        Current total spend
       </StatLabel>
       <StatNumber fontSize="24px">
        $
        {String.toFormatSeparator(
         Math.ceil(describeChangeResponse.currentTotalSpend)
        )}
       </StatNumber>
      </Stat>
     </Box>
    ) : (
     <Box
      textAlign="center"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="40px"
     >
      <NumberLoading />
     </Box>
    )}

    {!loading ? (
     <Box
      pl="28px"
      pt="29px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="40px"
     >
      <Stat>
       <StatLabel color="#9DA1A7" fontSize="16px">
        What-if total spend
       </StatLabel>
       <StatNumber fontSize="24px">
        $
        {String.toFormatSeparator(
         Math.ceil(describeChangeResponse.whatIfTotalSpend)
        )}
       </StatNumber>
      </Stat>
     </Box>
    ) : (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="40px"
     >
      <NumberLoading />
     </Box>
    )}
    {!loading ? (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="40px"
     >
      <Stat>
       <StatLabel color="#9DA1A7" fontSize="16px">
        Difference
       </StatLabel>
       <StatNumber fontSize="24px">
        $
        {describeChangeResponse.difference?.toLocaleString(undefined, {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
        })}
       </StatNumber>
       {describeChangeResponse.difference <= 0 ? (
        <StatHelpText>
         <StatArrow type="decrease" />
         {(describeChangeResponse.differenceInPercentage * 100)?.toLocaleString(
          undefined,
          {
           minimumFractionDigits: 2,
           maximumFractionDigits: 2,
          }
         )}
         %
        </StatHelpText>
       ) : (
        <StatHelpText>
         <StatArrow type="increase" />
         {(describeChangeResponse.differenceInPercentage * 100)?.toLocaleString(
          undefined,
          {
           minimumFractionDigits: 2,
           maximumFractionDigits: 2,
          }
         )}
         %
        </StatHelpText>
       )}
      </Stat>
     </Box>
    ) : (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="40px"
     >
      <NumberLoading />
     </Box>
    )}
    {!loading ? (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="40px"
     >
      <Stat>
       <StatLabel color="#9DA1A7" fontSize="16px">
        Current average cost
       </StatLabel>
       <StatNumber fontSize="24px">
        $
        {describeChangeResponse.currentAverageCost?.toLocaleString(undefined, {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
        })}
       </StatNumber>
      </Stat>
     </Box>
    ) : (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="40px"
     >
      <NumberLoading />
     </Box>
    )}
    {!loading ? (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="40px"
     >
      <Stat>
       <StatLabel color="#9DA1A7" fontSize="16px">
        What if average cost
       </StatLabel>
       <StatNumber fontSize="24px">
        $
        {describeChangeResponse.whatIfAverageCost?.toLocaleString(undefined, {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
        })}
       </StatNumber>
      </Stat>
     </Box>
    ) : (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="40px"
     >
      <NumberLoading />
     </Box>
    )}
   </Grid>
   <Grid templateColumns="repeat(2, 1fr)" gap={4} pb="1rem" pt="1rem">
    <Box border="1px solid #D7DBE2" w="100%" p={4} borderRadius="40px">
     <Box pl="1rem" pb="2rem">
      <Breadcrumb separator="">
       <BreadcrumbItem>
        <BreadcrumbLink
         onClick={() => changeBar("summary")}
         color={barSummary == "summary" ? "#0061BD" : "#C3C7CD"}
        >
         Summary
        </BreadcrumbLink>
       </BreadcrumbItem>
       {describeChangeResponse.chartLevel1 &&
        describeChangeResponse.chartLevel1.length !== 0 && (
         <BreadcrumbItem>
          <BreadcrumbLink
           onClick={() => changeBar("level1")}
           color={barSummary == "level1" ? "#0061BD" : "#C3C7CD"}
          >
           Level 1
          </BreadcrumbLink>
         </BreadcrumbItem>
        )}
       {describeChangeResponse.chartLevel1 &&
        describeChangeResponse.chartLevel1.length !== 0 && (
         <BreadcrumbItem>
          <BreadcrumbLink
           onClick={() => changeBar("level2")}
           color={barSummary == "level2" ? "#0061BD" : "#C3C7CD"}
          >
           Level 2
          </BreadcrumbLink>
         </BreadcrumbItem>
        )}
       <ChartModal data={describeChangeResponse} chartType="bar" />
      </Breadcrumb>
     </Box>
     <ReactApexChart
      options={barOptions}
      series={seriesBar}
      type="bar"
      height={300}
     />
    </Box>
    {!loading ? (
     <Box border="1px solid #D7DBE2" w="100%" p={4} borderRadius="40px">
      <Box pl="1rem">
       <Breadcrumb separator="">
        <BreadcrumbItem>
         <BreadcrumbLink
          onClick={() => changeTreemap("summary")}
          color={treemapSummary == "summary" ? "#0061BD" : "#C3C7CD"}
         >
          Summary
         </BreadcrumbLink>
        </BreadcrumbItem>
        {describeChangeResponse.chartLevel1 &&
         describeChangeResponse.chartLevel1.length !== 0 && (
          <BreadcrumbItem>
           <BreadcrumbLink
            onClick={() => changeTreemap("level1")}
            color={treemapSummary == "level1" ? "#0061BD" : "#C3C7CD"}
           >
            Level 1
           </BreadcrumbLink>
          </BreadcrumbItem>
         )}
        {describeChangeResponse.chartLevel1 &&
         describeChangeResponse.chartLevel1.length !== 0 && (
          <BreadcrumbItem>
           <BreadcrumbLink
            onClick={() => changeTreemap("level2")}
            color={treemapSummary == "level2" ? "#0061BD" : "#C3C7CD"}
           >
            Level 2
           </BreadcrumbLink>
          </BreadcrumbItem>
         )}
        <ChartModal data={describeChangeResponse} chartType="treemap" />
       </Breadcrumb>

       <Box alignItems="center" fontWeight="600" mt="1rem" mr="1rem">
        {chartType ? "What-If" : "Current"}{" "}
        <Switch
         defaultChecked={chartType ? true : false}
         pr="15px"
         onChange={() => setChartType(!chartType)}
        />
       </Box>
      </Box>
      <ReactApexChart
       options={options}
       series={series}
       type="treemap"
       height={300}
      />
     </Box>
    ) : (
     <BarChartLoading />
    )}
   </Grid>

   {!loading ? (
    <Box
     position="fixed"
     bottom="0"
     backgroundColor="#ffffff"
     height="5.5rem"
     w="100%"
     zIndex={2}
    >
     <Flex float="right" mr="20rem">
      <Box mt={4}>
       <Flex alignItems="right" gap="2">
        <>
         <Button
          bg="#C3C7CD"
          w="202px"
          h="59"
          borderRadius="50"
          fontSize="16"
          color="white"
          _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
          onClick={() => router.goBack()}
         >
          Back
         </Button>
         <Button
          bg="#0072CF"
          w="202px"
          h="59"
          mb="1rem"
          borderRadius="50"
          fontSize="16px"
          color="white"
          _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
          onClick={onOpen}
         >
          View Changes
         </Button>
         <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent minW="60%" borderRadius="25px">
           <ModalCloseButton />
           <ModalHeader>Simulation Changes Log</ModalHeader>
           <ModalBody>
            <TableContainer>
             <Table variant="mytable">
              <Thead borderBottom="1px solid #D7DBE2">
               <Tr>
                <Th>Components</Th>
                <Th textAlign="right" isNumeric>
                 Change From
                </Th>
                <Th textAlign="right" isNumeric>
                 Change To
                </Th>
                <Th textAlign="right" isNumeric>
                 % Change
                </Th>
               </Tr>
              </Thead>
              <Tbody>
               {dataToMap !== undefined &&
                dataToMap.map((item: any, index: number) => (
                 <Tr key={index}>
                  <Td>{item.identityName}</Td>
                  <Td textAlign="right" isNumeric>
                   {item.currentCost.toLocaleString("en-US")}
                  </Td>
                  <Td textAlign="right">{`${item.whatIfCost.toLocaleString(
                   "en-US"
                  )}`}</Td>
                  <Td textAlign="right">
                   <Stat>
                    <StatHelpText>
                     <StatArrow
                      type={
                       ((item.whatIfCost - item.currentCost) /
                        item.currentCost) *
                        100 >=
                       0
                        ? "increase"
                        : "decrease"
                      }
                     />
                     {`(${(
                      ((item.whatIfCost - item.currentCost) /
                       item.currentCost) *
                      100
                     )?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                     })} %)`}
                    </StatHelpText>
                   </Stat>
                  </Td>
                 </Tr>
                ))}
              </Tbody>
             </Table>
             <Table variant="mytable">
              <Tbody>
               <Tr>
                <Td width="100vh" textAlign="right" fontWeight={600}>
                 Total spend (Current) :{" "}
                </Td>
                <Td textAlign="right" fontWeight={600}>
                 {describeChangeResponse.currentTotalSpend?.toLocaleString(
                  undefined,
                  {
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2,
                  }
                 )}
                </Td>
               </Tr>
               <Tr>
                <Td textAlign="right" fontWeight={600}>
                 Total spend (What-If) :{" "}
                </Td>
                <Td textAlign="right" fontWeight={600}>
                 {describeChangeResponse.whatIfTotalSpend?.toLocaleString(
                  undefined,
                  {
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2,
                  }
                 )}
                </Td>
               </Tr>
              </Tbody>
             </Table>
            </TableContainer>
           </ModalBody>
          </ModalContent>
         </Modal>
         <SaveBusinessCase />
        </>
       </Flex>
      </Box>
     </Flex>
    </Box>
   ) : (
    <BarChartLoading />
   )}
  </Box>
 );
};

export default SummaryOverview;
