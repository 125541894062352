import { ReactElement, useEffect, useState, useMemo } from "react";
import { useAppDispatch, usePrompt } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import debounce from "lodash/debounce";
import { Box, Heading, Flex } from "@chakra-ui/layout";
import { Image, Grid, GridItem, VStack, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { actions as pricingCostSummaryAction } from "state/pricings/costSummary/slice";
import { actions as pricingCostDriverAction } from "state/pricings/costDrivers/slice";
import Layout from "views/Layout";
import Stepper from "views/NewSimulationBySku/Stepper";
import ToolbarBottom from "../ToolbarBottom";
import SimulateTableCostDriver from "./SimulateTableCostDriver";
import GeneralData from "views/NewSimulationBySku/GeneralData";
import CostSummary from "views/NewSimulationBySku/CostSummary";
import { useParams } from "react-router-dom";
import EditButton from "assets/images/Edit.png";
import VolumeSelection from "./VolumeSelection";

const CostDrivers = (): ReactElement => {
 const dispatch = useAppDispatch();
 const location = useLocation<{
  status: string;
  volumeId: string;
  isEditing: boolean;
 }>();
 const searchParams = new URLSearchParams(location.search);
 const { costDrivers }: any = useParams();
 const pricingId = location.pathname.split("/")[3];
 const pricingPage = location.pathname.split("/")[2];
 const [costDriverTable, setCostDriverTable] = useState<any>([]);
 const [tmpCostDriver, setTmpCostDriver] = useState<any>();
 const [tmpOriginalCostDriver, setTmpOriginalCostDriver] = useState<any>();
 const {
  volumeId,
  result,
  volumes,
  isEditing,
  isSimulate,
  temporaryDataCostDriver,
  costAnalysis,
  dataToSendUpdates,
  dataTOSendDeletes,
  dataTOSendCustomFields,
  dataToSend,
  originalCostDrivers,
  resultSimulate,
  detailPerVolumeId,
  costAnalysisPerVolumeId,
 } = useSelector(
  (state: RootState) => ({
   volumeId: state.pricingCostDrivers.volumeId,
   result: state.pricingCostDrivers.detailPerVolumeId.result,
   resultSimulate: state.pricingCostDrivers.responseSimulate.result,
   result1: state.pricingCostDrivers.detailPerVolumeId,
   levelName: state.pricingCostDrivers.levelName,
   volumes: state.pricingCostSummary.costSummaryById.volumes,
   costAnalysis: state.pricingCostSummary.costSummaryById,
   isEditing: state.pricingCostDrivers.isEditing,
   isSimulate: state.pricingCostDrivers.isSimulate,
   temporaryDataCostDriver: state.pricingCostDrivers.temporaryDataCostDriver,
   originalCostDrivers: state.pricingCostDrivers.originalCostDrivers,
   dataToSendAdds: state.pricingCostDrivers.dataToSend.adds,
   dataToSendUpdates: state.pricingCostDrivers.dataToSend.updates,
   dataTOSendDeletes: state.pricingCostDrivers.dataToSend.deletes,
   dataTOSendCustomFields: state.pricingCostDrivers.dataToSend.customFields,
   dataToSend: state.pricingCostDrivers.dataToSend,
   detailPerVolumeId: state.pricingCostDrivers.detailPerVolumeId,
   costAnalysisPerVolumeId: state.pricingCostDrivers.costAnalysisPerVolumeId,
  }),
  shallowEqual
 );
 usePrompt(isSimulate);
 useEffect(() => {
  if (searchParams.get("volumeId")) {
   dispatch(pricingCostDriverAction.setVolumeId(searchParams.get("volumeId")));
  }
  dispatch(pricingCostDriverAction.setIsSimulate(true));
 }, [pricingId, volumes]);

 const debounced = useMemo(
  () =>
   debounce((args) => {
    const simuateFeat = async () => {
     dispatch(pricingCostDriverAction.setValueIsIncludeCalculation(true));
     await dispatch(pricingCostDriverAction.simulate(args));
     dispatch(pricingCostDriverAction.setValueIsIncludeCalculation(false));
    };

    return simuateFeat();
   }, 800),
  [dispatch]
 );

 useEffect(() => {
  if (dataTOSendDeletes.length > 0) {
   debounced(dataToSend);
  }
 }, [dataTOSendDeletes]);

 useEffect(() => {
  if (location.state) {
   dispatch(pricingCostDriverAction.setStatus(location.state));
  }
  if (Object.keys(costAnalysis).length !== 0) {
   const { currency, format, uoM, description, name } = costAnalysis;
   const data = {
    costAnalysisPerVolumeId: detailPerVolumeId?.result?.id,
    uoMCode: dataToSend?.uoMCode || uoM?.code,
    // currencyCode: dataToSend?.currencyCode || currency?.code,
    currencyId: detailPerVolumeId?.result?.currency?.id || null,
    formatCode: dataToSend?.formatCode || format?.code,
    description: dataToSend?.description || description,
    name: dataToSend?.name || name,
    volume: dataToSend?.volume || 0 || result?.volume,
    thirdPartyCode: dataToSend?.thirdPartyCode || "",
    adds: dataToSend.adds,
    updates: dataToSendUpdates,
    deletes: dataTOSendDeletes,
    customFields: dataTOSendCustomFields,
   };
   dispatch(pricingCostDriverAction.setPerVolumeId(data));
  }
 }, [costAnalysis, detailPerVolumeId]);

 useEffect(() => {
  if (!searchParams.get("volumeId")) {
   const payload = {
    id: pricingId,
    status: location.state.status,
   };
   dispatch(pricingCostSummaryAction.pricingCostSummary(payload));
  }
 }, [pricingId, isEditing, isSimulate]);

 useEffect(() => {
  if (volumeId) {
   const payload = {
    costAnalysisId: pricingId,
    perVolumeId: volumeId || costAnalysisPerVolumeId,
   };
   dispatch(pricingCostDriverAction.getDetailCostDriversVolume(payload));
  }
 }, [volumeId, pricingId, isEditing, volumes, searchParams.get("volumeId")]);

 useEffect(() => {
  if (resultSimulate) {
   const toProcess = async () => {
    const currentCostDriverName = pricingPage
     .replaceAll("-", " ")
     .toUpperCase();
    var costDriver = resultSimulate.costDrivers?.find(
     (i: any) =>
      i.configuration.identityName.toUpperCase() == currentCostDriverName
    );
    if (costDriver && costDriver.costDrivers.length === 0) {
     var tableHeader = generateHeaderTable(costDriver);
     var tableData = [];

     var data = generateDataTable(costDriver, resultSimulate.costAnalysis);

     tableData.push(data);
     var tablePayload = [
      {
       tableName: costDriver.configuration.identityName,
       identityName: costDriver.configuration.identityName,
       sequence: costDriver.configuration.sequence,
       headers: tableHeader,
       data: tableData,
       costDriverId: costDriver.id,
       identityCode: costDriver.identityCode,
       costDriverUrl: costDrivers,
      },
     ];
     const dataTmpCostDriver = {
      name: costDrivers,
      configurationId: costDriver?.configuration?.id,
      data: tablePayload,
      volume: resultSimulate.volume,
     };
     await dispatch(
      pricingCostDriverAction.setTemporaryOriginalDataCostDriver(
       dataTmpCostDriver
      )
     );
    } else {
     var tablePayloadLevel1 = [] as any;
     if (costDriver) {
      costDriver.costDrivers.forEach(function (costLevel1: any) {
       var tableHeader = [] as any;
       var tableData = [];
       if (costLevel1.costDrivers && costLevel1.costDrivers.length > 0) {
        tableHeader = generateHeaderTable(costLevel1.costDrivers[0]);
        costLevel1.costDrivers.map(function (costLevel2: any) {
         var data = generateDataTable(costLevel2, resultSimulate.costAnalysis);
         tableData.push(data);
        });
       } else {
        tableHeader = generateHeaderTable(costLevel1);
        var data = generateDataTable(costLevel1, resultSimulate.costAnalysis);
        tableData.push(data);
       }

       var payload = {
        tableName: costLevel1.configuration.identityName,
        identityName: costLevel1.configuration.identityName,
        sequence: costLevel1.configuration.sequence,
        headers: tableHeader,
        data: tableData,
        costDriverId: costLevel1.id,
        costDriverUrl: costDrivers,
       };
       tablePayloadLevel1.push(payload);
      });
     }

     tablePayloadLevel1 = tablePayloadLevel1.sort((a: any, b: any) =>
      a.sequence < b.sequence ? -1 : 1
     );

     const dataTmpCostDriver = {
      name: costDrivers,
      configurationId: costDriver?.configuration?.id,
      data: tablePayloadLevel1,
      volume: resultSimulate.volume,
     };

     const haveConstDriver =
      originalCostDrivers.length > 0 &&
      originalCostDrivers.find((item: any) => item.name === costDrivers);
     if (haveConstDriver && haveConstDriver.volume === resultSimulate.volume) {
      await dispatch(
       pricingCostDriverAction.setTemporaryOriginalDataCostDriver(
        haveConstDriver
       )
      );
     } else {
      await dispatch(
       pricingCostDriverAction.setTemporaryOriginalDataCostDriver(
        dataTmpCostDriver
       )
      );
     }
    }
   };
   toProcess();
  }
 }, [pricingPage, resultSimulate]);

 useEffect(() => {
  if (result) {
   const modifyRedux = async () => {
    const currentCostDriverName = pricingPage
     .replaceAll("-", " ")
     .toUpperCase();
    var costDriver = result.costDrivers?.find(
     (i: any) =>
      i.configuration.identityName.toUpperCase() == currentCostDriverName
    );
    if (costDriver && costDriver.costDrivers.length === 0) {
     // Base Level
     var tableHeader = generateHeaderTable(costDriver);
     var tableData = [];

     var data = generateDataTable(costDriver, result.costAnalysis);

     tableData.push(data);
     var tablePayload = [
      {
       tableName: costDriver.configuration.identityName,
       costDriverUrl: costDrivers,
       identityName: costDriver.configuration.identityName,
       sequence: costDriver.configuration.sequence,
       headers: tableHeader,
       data: tableData,
       costDriverId: costDriver.id,
       costDriverLenght: costDriver.costDrivers.length,
       canAddNew:
        costDriver.configuration.costDrivers.length > 0 &&
        costDriver.configuration.costDrivers[0].isMainCostDriver === false,
       parentCostDriver: costDriver.parentCostDriver
        ? costDriver.parentCostDriver?.id
        : null,
      },
     ];
     setCostDriverTable(tablePayload);
     const dataTmpCostDriver = {
      name: costDrivers,
      configurationId: costDriver?.configuration?.id,
      data: tablePayload,
      volume: result.volume,
     };
     await dispatch(
      pricingCostDriverAction.setTemporaryDataCostDriver(dataTmpCostDriver)
     );
    } else {
     var tablePayloadLevel1 = [] as any;
     if (costDriver) {
      costDriver.costDrivers.forEach(function (costLevel1: any) {
       var tableHeader = [] as any;
       var tableData = [];
       if (costLevel1.costDrivers && costLevel1.costDrivers.length > 0) {
        tableHeader = generateHeaderTable(costLevel1.costDrivers[0]);
        costLevel1.costDrivers.map(function (costLevel2: any) {
         var data = generateDataTable(costLevel2, result.costAnalysis);
         tableData.push(data);
        });
       } else {
        tableHeader = generateHeaderTable(costLevel1);
        var data = generateDataTable(costLevel1, result.costAnalysis);
        const level20 = detailPerVolumeId?.result?.costAnalysis?.level === 20;
        const level30 = detailPerVolumeId?.result?.costAnalysis?.level === 30;
        if (level20 || level30 || costLevel1.costDrivers.length > 0) {
         tableData.push(data);
        }

        const findIsMainCostDriverFalse = (costDrivers: any) => {
         return costDrivers;
        };

        if (costLevel1.customFields.length === 0) {
         if (
          costLevel1?.configuration.isMainCostDriver &&
          Array.isArray(costLevel1?.configuration.costDrivers) &&
          costLevel1?.configuration.costDrivers.length > 0
         ) {
          if (
           !findIsMainCostDriverFalse(costLevel1?.configuration.costDrivers[0])
            .isMainCostDriver
          ) {
           costLevel1?.configuration.costDrivers[0].customFields.map(
            (item: any) => {
             const customFieldNeedToPush = {
              id: item.id,
              customFieldConfigurationId: item.id,
              costDriverId: item.costDriver.id,
              costDriverName: item.costDriver.name,
              costDriverDescription: item.costDriver.description,
              predefinedData:
               item.definedData !== ""
                ? {
                   code: item.definedData,
                   description: item.definedData,
                   id: "",
                   configuration: {
                    identityName: item.definedData,
                   },
                  }
                : null,
              costDriverUnitCost: 0,
              isIncludeInCalculation: item.isIncludeInCalculation,
              name: item.name.replace(/[\[\]']+/g, ""),
              realName: item.name,
              isRequired: item.isRequired,
              isDisplayOnPricing: item.isDisplayOnPricing,
              currencyId: "",
              value: item.isIncludeInCalculation ? 0 : "",
             };
             data.customFields.push(customFieldNeedToPush);
            }
           );
           dispatch(pricingCostDriverAction.setDataEmptyCostDrivers(data));
          }
         }
        }
       }
       //  const findNull = tableData.find((itemData: any) => itemData.name === "");
       var payload = {
        tableName: costLevel1.configuration.identityName,
        identityName: costLevel1.configuration.identityName,
        sequence: costLevel1.configuration.sequence,
        headers: tableHeader,
        data: tableData,
        costDriverId: costLevel1.id,
        parentCostDriver: costLevel1.parentCostDriver
         ? costLevel1.parentCostDriver?.id
         : null,
        costDriverUrl: costDrivers,
        identityCode: costLevel1.configuration.identityCode,
        canAddNew:
         costLevel1.configuration.costDrivers.length > 0 &&
         costLevel1.configuration.costDrivers[0].isMainCostDriver === false,
       };
       tablePayloadLevel1.push(payload);
      });
     }

     tablePayloadLevel1 = tablePayloadLevel1.sort((a: any, b: any) =>
      a.sequence < b.sequence ? -1 : 1
     );
     const dataTmpCostDriver = {
      name: costDrivers,
      configurationId: costDriver?.configuration?.id,
      data: tablePayloadLevel1,
      volume: result.volume,
     };

     const haveConstDriver =
      tablePayloadLevel1.length > 0 &&
      tablePayloadLevel1.find((item: any) => item.name === costDrivers);

     if (haveConstDriver && haveConstDriver.volume === result.volume) {
      setCostDriverTable(haveConstDriver);

      dispatch(
       pricingCostDriverAction.setTemporaryDataCostDriver(haveConstDriver)
      );
     } else {
      setCostDriverTable(tablePayloadLevel1);
      dispatch(
       pricingCostDriverAction.setTemporaryDataCostDriver(dataTmpCostDriver)
      );
     }
    }
   };
   modifyRedux();
  }
 }, [volumeId, result, pricingPage, setCostDriverTable, isEditing]);

 const generateHeaderTable = (costDriver: any) => {
  var tableHeader = [];
  tableHeader.push({
   name: "name",
   convertName: "name",
   label: costDriver["configuration"]["labelName"],
   isRequired: false,
  });
  tableHeader.push({
   name: "description",
   convertName: "description",
   label: costDriver["configuration"]["labelDescription"],
   isRequired: false,
  });

  if (costDriver.customFields && costDriver.customFields.length > 0) {
   costDriver.customFields.forEach(function (customField: any) {
    tableHeader.push({
     name: customField.configuration.name,
     convertName: customField.configuration.name.replace(/[\[\]']+/g, ""),
     label: customField.configuration.displayName,
     isDisplayOnPricing: customField.configuration.isDisplayOnPricing,
     maintenanceWithMasterData: false,
     isRequired: customField?.configuration?.isRequired,
    });
   });
  }
  if (costDriver.masterData && costDriver.masterData.customFields.length > 0) {
   costDriver.masterData.customFields.forEach(function (customField: any) {
    tableHeader.push({
     name: `${customField.configuration.name}`,
     convertName: customField.configuration.name.replace(/[\[\]']+/g, ""),
     label: customField.configuration.displayName,
     isDisplayOnPricing: customField.configuration.isDisplayOnPricing,
     maintenanceWithMasterData: true,
     sequence: customField.configuration.sequence,
     isRequired: customField?.configuration?.isRequired,
    });
   });
  }
  //  tableHeader = tableHeader.sort((a: any, b: any) =>
  //  a.sequence < b.sequence ? -1 : 1
  // );
  const findIsMainCostDriverFalse = (costDrivers: any) => {
   return costDrivers;
  };

  if (costDriver.customFields.length === 0) {
   if (
    costDriver?.configuration.isMainCostDriver &&
    Array.isArray(costDriver?.configuration.costDrivers) &&
    costDriver?.configuration.costDrivers.length > 0
   ) {
    if (
     !findIsMainCostDriverFalse(costDriver?.configuration.costDrivers[0])
      .isMainCostDriver
    ) {
     costDriver?.configuration.costDrivers[0].customFields.map((item: any) => {
      const customFieldNeedToPush = {
       name: item.name,
       convertName: item.name.replace(/[\[\]']+/g, ""),
       label: item.displayName,
       isDisplayOnPricing: item.isDisplayOnPricing,
      };
      tableHeader.push(customFieldNeedToPush);
     });
    } else {
    }
   }
  }

  if (!costDriver.configuration.isPercentage) {
   tableHeader.push({
    name: "currency",
    label: "Currency",
    convertName: "currency",
   });
  }

  tableHeader.push({
   name: "unitCost",
   convertName: "unitCost",
   label: costDriver["configuration"]["labelCost"],
  });
  if (!costDriver.configuration.isPercentage) {
   tableHeader.push({
    name: "totalCost",
    label: costDriver.configuration.labelTotalCost,
    convertName: "totalCost",
   });
  }
  tableHeader.push({
   name: "exchangeRateToCurrency",
   convertName: "exchangeRateToCurrency",
   label: `Exchange Rate to ${"IDR"}`,
   isRequired: false,
  });

  tableHeader.push({
   name: "totalCostToCurrency",
   convertName: "totalCostToCurrency",
   label: `Total Cost in ${"IDR"}`,
   isRequired: false,
  });

  return tableHeader;
 };

 const generateDataTable = (costDriver: any, costAnalysis: any) => {
  var data = {
   id: costDriver.id,
   identityCode: costDriver.identityCode,
   name: costDriver.name,
   description: costDriver.description,
   currency: costAnalysis.currencyCode,
   unitCost: String.toFormatSeparator(costDriver.unitCost) || 0,
   totalCost: String.toFormatSeparator(costDriver.totalCost),
   customFields: [],
   masterDataCustomFields: [],
   currencyId: costDriver.currency.id,
   masterDataConfiguration: costDriver.masterData,
   masterDataId: costDriver.masterData ? costDriver.masterData.id : "",
   constDriverId: costDriver.configuration.isPercentage
    ? costDriver.id
    : costDriver?.parentCostDriver?.id,
  } as any;

  data.unitCost = data.unitCost;
  data.isNew = costDriver.creatorUserId === null;
  data.isErrorDuplicate = costDriver.creatorUserId === null && false;
  data.totalCostPricing = costDriver.totalCostPricing;
  data.convertedCurrency = costDriver.pricingCurrency;
  let exchangeRateToCurrency = 0;
  data.exchangeRateToCurrency = exchangeRateToCurrency;
  data.totalCostToCurrency = costDriver.totalCostCurrency;
  // data.currencyId = costDriver.currency.id;
  data.currencyConversionExchangeRate = costDriver.pricingCurrencyExchangeRate;

  if (data.masterData !== null) {
   data.currency = costDriver.currency.code;
  } else {
   data.currency = costDriver?.masterData?.currencyCode;
  }

  if (costDriver.customFields.length > 0) {
   costDriver.customFields.forEach(function (customField: any) {
    data.customFields.push({
     customFieldId: customField.id,
     customFieldConfigurationId: customField.configuration.id,
     costDriverId: customField.costDriver.id,
     costDriverName: customField.costDriver.name,
     costDriverDescription: customField.costDriver.description,
     masterData: customField.masterData,
     costDriverUnitCost: customField.costDriver.unitCost,
     isIncludeInCalculation: customField.configuration.isIncludeInCalculation,
     name: customField.configuration.name.replace(/[\[\]']+/g, ""),
     realName: customField.configuration.name,
     isRequired: customField.configuration.isRequired,
     isDisplayOnPricing: customField.configuration.isDisplayOnPricing,
     exchangeRateId: customField?.exchangeRate?.id,
     totalCostPricing: 0,
     totalCostCurrency: 0,
     typeData: customField.configuration.type,
     isMaintenanceByMasterData: false,
     currencyCode: customField?.currency?.code,
     currencyId: customField?.currency?.id,
     value: customField.configuration.isIncludeInCalculation
      ? parseFloat(customField.value.replace(/,/g, "")).toLocaleString("en-US")
      : customField.value,
    });
   });
  }
  if (costDriver?.masterData?.customFields.length > 0) {
   costDriver?.masterData?.customFields.forEach(function (customField: any) {
    const isNeedRounding =
     customField.configuration.dataType === 20 ||
     customField.configuration.dataType === 40 ||
     customField.configuration.dataType === 50;
    data.customFields.push({
     name: `${customField.configuration.name.replace(/[\[\]']+/g, "")}`,
     realName: customField.configuration.name,
     isRequired: customField.configuration.isRequired,
     isDisplayOnPricing: customField.configuration.isDisplayOnPricing,
     typeData: customField.configuration.dataType,
     value: isNeedRounding
      ? parseFloat(parseFloat(customField.value).toFixed(5))
      : customField.value,
     isMaintenanceByMasterData: true,
     currencyCode: customField.currencyCode,
     currencyId: customField?.currency?.id,
     exchangeRateCurrencyId: customField.exchangeRateCurrencyId,
    });
   });
  }
  const findIsMainCostDriverFalse = (costDrivers: any) => {
   return costDrivers;
  };
  if (costDriver.customFields.length === 0) {
   if (
    costDriver?.configuration.isMainCostDriver &&
    Array.isArray(costDriver?.configuration.costDrivers) &&
    costDriver?.configuration.costDrivers.length > 0
   ) {
    if (
     !findIsMainCostDriverFalse(costDriver?.configuration.costDrivers[0])
      .isMainCostDriver
    ) {
     // joko test
     costDriver?.configuration.costDrivers[0].customFields.map((item: any) => {
      let customFieldNeedToPush: any = {
       customFieldId: item.id,
       customFieldConfigurationId: item.id,
       costDriverId: item.costDriver.id,
       costDriverName: item.costDriver.name,
       costDriverDescription: item.costDriver.description,
       predefinedData:
        item.definedData !== ""
         ? {
            code: item.definedData,
            description: item.definedData,
            id: "",
            configuration: {
             identityName: item.definedData,
            },
           }
         : null,
       costDriverUnitCost: 0,
       isIncludeInCalculation: item.isIncludeInCalculation,
       name: item.name.replace(/[\[\]']+/g, ""),
       realName: item.name,
       isRequired: item.isRequired,
       isDisplayOnPricing: item.isDisplayOnPricing,
       value: item.isIncludeInCalculation ? 0 : "",
      };
     });
    }
   }
  }
  return data;
 };

 useEffect(() => {
  const ids = temporaryDataCostDriver.map((o: any) => o.name);

  const filtered = temporaryDataCostDriver.filter(
   ({ name }: any, index: any) => !ids.includes(name, index + 1)
  );
  setTmpCostDriver(filtered);
 }, [temporaryDataCostDriver, result, costDrivers, volumeId]);

 const itemCostDriver =
  Array.isArray(tmpCostDriver) &&
  tmpCostDriver.length > 0 &&
  tmpCostDriver.find((costDriver: any) => {
   if (costDriver.name === costDrivers) {
    return costDriver;
   }
  });

 useEffect(() => {
  dispatch(pricingCostDriverAction.setConstDrivers(itemCostDriver));
 }, [itemCostDriver, resultSimulate]);

 const itemOriginalCostDriver =
  Array.isArray(tmpOriginalCostDriver) &&
  tmpOriginalCostDriver.length > 0 &&
  tmpOriginalCostDriver.find((costDriver: any) => {
   if (costDriver.name === costDrivers) {
    return costDriver;
   }
  });

 useEffect(() => {
  const ids = originalCostDrivers.map((o: any) => o.name);
  const filtered = originalCostDrivers.filter(
   ({ name }: any, index: any) => !ids.includes(name, index + 1)
  );
  setTmpOriginalCostDriver(filtered);
 }, [originalCostDrivers, resultSimulate]);

 //  const filteredData: any = itemCostDriver?.data;
 useEffect(() => {
  dispatch(pricingCostDriverAction.setConstDriversOri(itemOriginalCostDriver));
 }, [itemCostDriver]);

 useEffect(() => {
  const actionChangeRedux = async () => {
   const findCostDriver = temporaryDataCostDriver.find(
    (item: any) => item.name === costDrivers
   );
   temporaryDataCostDriver.forEach((state: any) => {
    let itemState = Object.assign({}, state);
    itemState.data = state.data.map((item: any) => {
     dataToSendUpdates?.map((updateItem: any) => {
      const exitingConfigurationName = item?.data?.find(
       (item: any) => item.id === updateItem.costDriverId
      );
      if (exitingConfigurationName) {
       let data = Object.assign({}, exitingConfigurationName);
       data.name = updateItem.name;
       data.description = updateItem.description;
       data.unitCost = updateItem.unitCost;
       const newArr: any = [];

       item.data.map((object: any) => {
        const findData = dataToSendUpdates.find(
         (it: any) => it.costDriverId === object.id
        );
        if (findData) {
         let costDriver = Object.assign({}, object);
         const newCostdriver: any = [];
         costDriver?.customFields.map((item: any) => {
          let dataCostDriver = Object.assign({}, item);
          const haveCustomField = findData.customFields.find(
           (i: any) => i.customFieldId === item.id
          );
          dataCostDriver.value = haveCustomField?.value;
          newCostdriver.push(dataCostDriver);
          costDriver[dataCostDriver.name] = haveCustomField?.value;
         });

         costDriver.name = findData.name;
         costDriver.description = findData.description;
         costDriver.unitCost = findData.unitCost;

         costDriver.customFields = newCostdriver;
         newArr.push(costDriver);
        } else {
         newArr.push(object);
        }
       });

       let mergeObject = Object.assign({}, item);
       mergeObject.data = newArr;

       for (let i = 0; i < temporaryDataCostDriver.length; i++) {
        if (
         temporaryDataCostDriver[i]?.configurationId ===
         findCostDriver?.configurationId
        ) {
         const newDataToPush: any = [];

         Array.isArray(temporaryDataCostDriver[i].data) &&
          temporaryDataCostDriver[i].data.map((tmpCostDriver: any) => {
           if (mergeObject.costDriverId === tmpCostDriver.costDriverId) {
            newDataToPush.push(mergeObject);
           } else {
            newDataToPush.push(tmpCostDriver);
           }
          });

         const newObject = {
          configurationId: findCostDriver.configurationId,
          name: findCostDriver.name,
          data: newDataToPush,
          fromCostDriver: true,
         };

         dispatch(
          pricingCostDriverAction.replaceTemporaryDataCostDriver(newObject)
         );
        }
       }
      }
     });
    });
   });
  };
  actionChangeRedux();
 }, [dataToSendUpdates, isEditing]);
 return (
  <Layout>
   <Flex gap={4}>
    <Heading mb="2rem" as="h1" fontSize="48px" textTransform="capitalize">
     {pricingPage === "cost-summary-by-sku" && "Simulation by SKU - Simulation"}
     {pricingPage === "sku-characteristic" && "SKU Characteristic - Simulation"}
     {pricingPage !== "cost-summary-by-sku" &&
      pricingPage !== "sku-characteristic" &&
      `${pricingPage.replaceAll("-", " ")} - Simulation`}
    </Heading>
    <Flex gap={4} mt={isEditing || isSimulate ? "0" : "2.5"}>
     {isSimulate ||
      (!isEditing && (
       <Image
        src={EditButton}
        width="38"
        height="38"
        cursor="pointer"
        onClick={() => {
         dispatch(pricingCostDriverAction.setIsEditing(true));
        }}
       />
      ))}
     {/* {isEditing ||
      (!isSimulate && (
       <Button
        onClick={() => {
         dispatch(pricingCostDriverAction.setIsSimulate(true));
        }}
       >
        Simulate
       </Button>
      ))}
     {isSimulate && pricingPage !== "cost-summary-by-sku" && (
      <Heading mb="2rem" as="h1" fontSize="48px" textTransform="capitalize">
       - Simulation
      </Heading>
     )} */}
    </Flex>
   </Flex>
   <Stepper />
   <Grid templateColumns="repeat(6, 1fr)" gap={4}>
    <GridItem colSpan={5}>
     <Box pt="2.188rem" mb="3rem">
      {isSimulate &&
       itemCostDriver?.data?.map((item: any, index: number) => {
        return (
         <Box key={index}>
          <SimulateTableCostDriver
           costDriver={item}
           paramName={costDrivers}
           level={costAnalysis.level}
          />
         </Box>
        );
       })}
      {/* {isSimulate &&
       pricingPage !== "sku-characteristic" &&
       pricingPage !== "cost-summary-by-sku" &&
       itemCostDriver?.data?.length > 0 && <ResultSimulateTable />} */}
      {pricingPage === "sku-characteristic" && <GeneralData />}
      {pricingPage === "cost-summary-by-sku" && <CostSummary />}
     </Box>
    </GridItem>
    <GridItem colSpan={1}>
     <VStack spacing={4} align="stretch">
      <VolumeSelection />
      <Box pl="1rem">
       <Text fontWeight={600} mb="3">
        Cost Summary
       </Text>
       <Box border="1px solid #D7DBE2" p="1rem" borderRadius="5px">
        <VStack spacing={5} align="stretch">
         <VStack spacing={1} align="stretch">
          <Text fontSize="16px" color="rgba(26, 26, 26, 0.36)">
           Volume
          </Text>
          <Text
           fontSize="16px"
           color="
rgba(26, 26, 26, 1)"
           fontWeight="700"
          >
           {String.toFormatSeparator(result?.volume, 0)}
          </Text>
         </VStack>
         <VStack spacing={1} align="stretch">
          <Text fontSize="16px" color="rgba(26, 26, 26, 0.36)">
           Currency
          </Text>
          <Text
           fontSize="16px"
           color="
rgba(26, 26, 26, 1)"
           fontWeight="700"
          >
           {result?.currency?.code}
          </Text>
         </VStack>
         <VStack spacing={1} align="stretch">
          <Text fontSize="16px" color="rgba(26, 26, 26, 0.36)">
           Final Price
          </Text>
          <Text
           fontSize="16px"
           color="
rgba(26, 26, 26, 1)"
           fontWeight="700"
          >
           {result?.unitCost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
           })}
          </Text>
         </VStack>
        </VStack>
       </Box>
      </Box>
     </VStack>
    </GridItem>
   </Grid>
   {volumes && volumes.length > 0 && (
    <ToolbarBottom costAnalysis={volumes[0]} costAnalysisId={pricingId} />
   )}
  </Layout>
 );
};

export default CostDrivers;
