const BoxStyled = {
    textStyles: {
        body1: {
            fontSize: ['16px', '72px'],
            fontWeight: 400,
            lineHeight: '19.36px',
            color: 'rgba(26, 26, 26, 0.36)'
        }
    }
}


export default BoxStyled;