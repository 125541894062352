import { FC, useEffect } from "react";
import {
 Box,
 Button,
 Table,
 Thead,
 Tbody,
 Tr,
 Th,
 Td,
 Stack,
 Image,
 Text,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import CheckIcon from "assets/images/Check.png";
import CostStructureAnalysis from "assets/images/cost-structure-analysis.png";
import DriverIdentification from "assets/images/driver-indentification.png";
import CostBreakdown from "assets/images/cost-breakdown.png";
import CostSpendSimulation from "assets/images/cost-spend-simulation.png";
import Reduction from "assets/images/reduction.png";
import Dinamic from "assets/images/dinamic.png";
import Designcost from "assets/images/designcost.png";
import Lost from "assets/images/lost.png";

const LevelDetail: FC = () => {
 const router = useHistory();
 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);
 return (
  <>
   <Box mt="2rem">
    <Table>
     <Thead>
      <Tr>
       <Th border="none" borderRight="1px solid #9DA1A7"></Th>
       <Th
        border="none"
        borderRight="1px solid #9DA1A7"
        textAlign="center"
        textTransform="none"
        fontSize="18px"
        fontWeight="600"
        color="#00050D"
       >
        Does cost
       </Th>
       <Th
        border="none"
        borderRight="1px solid #9DA1A7"
        textAlign="center"
        textTransform="none"
        fontSize="18px"
        fontWeight="600"
        color="#00050D"
       >
        Base level
       </Th>
       <Th
        border="none"
        borderRight="1px solid #9DA1A7"
        textAlign="center"
        textTransform="none"
        fontSize="18px"
        fontWeight="600"
        color="#00050D"
       >
        Level 1
       </Th>
       <Th
        border="none"
        borderRight="1px solid #9DA1A7"
        textAlign="center"
        textTransform="none"
        fontSize="18px"
        fontWeight="600"
        color="#00050D"
       >
        Level 2
       </Th>
       <Th
        border="none"
        borderRight="1px solid #9DA1A7"
        textAlign="center"
        textTransform="none"
        fontSize="18px"
        fontWeight="600"
        color="#00050D"
       >
        Level 3
       </Th>
       <Th
        border="none"
        borderRight="1px solid #9DA1A7"
        textAlign="center"
        textTransform="none"
        fontSize="18px"
        fontWeight="600"
        color="#00050D"
       >
        Level 4
       </Th>
      </Tr>
     </Thead>
     <Tbody>
      <Tr>
       <Td
        border="none"
        borderRight="1px solid #9DA1A7"
        fontSize="16px"
        fontWeight="500"
        color="9DA1A7"
       >
        <Box display="flex" alignItems="center">
         <Image src={CostStructureAnalysis} />
         <Text ml="1rem" fontWeight="500" color="#00050D">
          Cost structure analysis
         </Text>
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
      </Tr>
      <Tr>
       <Td
        border="none"
        borderRight="1px solid #9DA1A7"
        fontSize="16px"
        fontWeight="500"
        color="9DA1A7"
       >
        <Box display="flex" alignItems="center">
         <Image src={DriverIdentification} />
         <Text ml="1rem" fontWeight="500" color="#00050D">
          Cost driver identification
         </Text>
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
      </Tr>
      <Tr>
       <Td
        border="none"
        borderRight="1px solid #9DA1A7"
        fontSize="16px"
        fontWeight="500"
        color="9DA1A7"
       >
        <Box display="flex" alignItems="center">
         <Image src={CostBreakdown} />
         <Text ml="1rem" fontWeight="500" color="#00050D">
          Cost and spend breakdown analysis
         </Text>
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
      </Tr>
      <Tr>
       <Td
        border="none"
        borderRight="1px solid #9DA1A7"
        fontSize="16px"
        fontWeight="500"
        color="9DA1A7"
       >
        <Box display="flex" alignItems="center">
         <Image src={CostSpendSimulation} />
         <Text ml="1rem" fontWeight="500" color="#00050D">
          Cost and spend simulation
         </Text>
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
      </Tr>
      <Tr>
       <Td
        border="none"
        borderRight="1px solid #9DA1A7"
        fontSize="16px"
        fontWeight="500"
        color="9DA1A7"
       >
        <Box display="flex" alignItems="center">
         <Image src={Reduction} />
         <Text ml="1rem" fontWeight="500" color="#00050D">
          Complexity reduction
         </Text>
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
      </Tr>
      <Tr>
       <Td
        border="none"
        borderRight="1px solid #9DA1A7"
        fontSize="16px"
        fontWeight="500"
        color="9DA1A7"
       >
        <Box display="flex" alignItems="center">
         <Image src={Dinamic} />
         <Text ml="1rem" fontWeight="500" color="#00050D">
          Dynamic costing (Fixed and variable)
         </Text>
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
      </Tr>
      <Tr>
       <Td
        border="none"
        borderRight="1px solid #9DA1A7"
        fontSize="16px"
        fontWeight="500"
        color="9DA1A7"
       >
        <Box display="flex" alignItems="center">
         <Box width="32px" textAlign="center">
          <Image src={Designcost} display="inline" />
         </Box>
         <Text ml="1rem" fontWeight="500" color="#00050D">
          Design to a cost
         </Text>
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
      </Tr>
      <Tr>
       <Td
        border="none"
        borderRight="1px solid #9DA1A7"
        fontSize="16px"
        fontWeight="500"
        color="9DA1A7"
       >
        <Box display="flex" alignItems="center">
         <Image src={Lost} />
         <Text ml="1rem" fontWeight="500" color="#00050D">
          Loss analysis
         </Text>
        </Box>
       </Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7"></Td>
       <Td border="none" borderRight="1px solid #9DA1A7">
        <Box textAlign="center">
         <Image src={CheckIcon} alt="CheckIcon" display="initial" />{" "}
        </Box>
       </Td>
      </Tr>
     </Tbody>
    </Table>
    <Box pt="2rem" textAlign="right" w="100%">
     <Stack spacing={4} direction="row" align="center" float="right">
      <Button
       variant={"outline"}
       onClick={() => router.goBack()}
      >
       Back
      </Button>
      <Button
       onClick={() =>
        router.push("/journeys/create?page=target-discovery-step-1")
       }
      >
       Next
      </Button>
     </Stack>
    </Box>
   </Box>
  </>
 );
};

export default LevelDetail;
