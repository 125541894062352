import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";

export const getDetailCostDriversVolume = createAsyncThunk(
    "editcostAnalysis/getDetailCostAnalysisVolume",
    async ({ costAnalysisId, perVolumeId, thirdPartyId }: any) => {
        if (thirdPartyId) {
            return await HttpService.get(
                `/CostAnalysis/${costAnalysisId}/volume/${perVolumeId}?thirdPartyId=${thirdPartyId}`
            );
        } else {
            return await HttpService.get(
                `/CostAnalysis/${costAnalysisId}/volume/${perVolumeId}`
            );
        }
    }
);

export const deleteDetailCostAnalysisVolume = createAsyncThunk(
    "editcostAnalysis/deleteDetailCostAnalysisVolume",
    async ({ perVolumeId }: any) => {
        const response: any = await HttpService.delete(`/CostAnalysis/volume/${perVolumeId}`);
        return response;
    }
);

export const getCostBreakdownContributionnDetail = createAsyncThunk(
    "getCostBreakdownContributionnDetail",
    async ({ perVolumeId }: any) => {
        return await HttpService.get(
            `/CostAnalysis/${perVolumeId}/CostBreakdownContribution`
        );
    }
);

export const getCostBreakdownContributionnDetail2nd = createAsyncThunk(
    "getCostBreakdownContributionnDetail2nd",
    async ({ perVolumeId }: any) => {
        return await HttpService.get(
            `/CostAnalysis/${perVolumeId}/CostBreakdownContribution`
        );
    }
);

export const getExhangeRateByAmountFromToCurrencyCode = createAsyncThunk(
    "getExhangeRateByAmountFromToCurrencyCode",
    async ({ amount, fromCurrencyCode, toCurrencyCode }: any) => {
        return await HttpService.get(
            `/ExhangeRates/${amount}/${fromCurrencyCode}/${toCurrencyCode}`
        );
    }
);
