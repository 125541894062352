import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks";
import { useFormikContext } from "formik";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { Box, Text } from "@chakra-ui/layout";
import { Grid } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { actions as pricingCostSummaryAction } from "state/pricings/costSummary/slice";
import { isEmpty } from "lodash";
import { actions as pricingCostDriverAction } from "state/pricings/costDrivers/slice";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import { actions as masterDataAction } from "state/masterData/slice";
import { TableLoading } from "components/Loading";
const SkuCharacteristic = () => {
 const dispatch = useAppDispatch();
 const [dataTmp, setDataTmp] = useState<any>();
 const location = useLocation();
 const [initialValues, setInitialValues] = useState<any>();
 const [dataAdditional, setDataAdditional] = useState<any>([]);
 const pricingId = location.pathname.split("/")[3];

 const [optionFormat, setOptionFormat] = useState<any>([]);
 const [optionThirdParty, setOptionThirdParty] = useState<any>([]);
 const [optionCurrency, setOptionCurrency] = useState<any>([]);
 const [optionUoM, setOptionUoM] = useState<any>([]);
 const [optionCountry, setOptionCountry] = useState<any>([]);
 const [optionBrand, setOptionBrand] = useState<any>([]);
 const [optionSupplier, setOptionSupplier] = useState<any>([]);
 const [optionFactory, setOptionFactory] = useState<any>([]);

 const {
  costAnalysis,
  detailPerVolumeId,
  dataToSendUpdates,
  dataTOSendDeletes,
  dataTOSend,
  loading,
  summaryLoading
 } = useSelector(
  (state: RootState) => ({
    loading: state.pricingCostDrivers.loading,
    summaryLoading: state.pricingCostSummary.loading,
   costAnalysis: state.pricingCostSummary.costSummaryById,
   volumes: state.pricingCostSummary.costSummaryById.volumes,
   isEditing: state.pricingCostDrivers.isEditing,
   isSimulate: state.pricingCostDrivers.isSimulate,
   detailPerVolumeId: state.pricingCostDrivers.detailPerVolumeId.result,
   dataToSendUpdates: state.pricingCostDrivers.dataToSend.updates,
   dataTOSendDeletes: state.pricingCostDrivers.dataToSend.deletes,
   dataTOSendCustomFields: state.pricingCostDrivers.dataToSend.customFields,
   dataTOSend: state.pricingCostDrivers.dataToSend,
  }),
  shallowEqual
 );

 useEffect(() => {
  if (initialValues == isEmpty()) {
   const payload = { id: pricingId };
   dispatch(pricingCostSummaryAction.pricingCostSummary(payload));
  }
 }, [pricingId]);

 useEffect(() => {
  const fetchThirdParty = async (value: number) => {
   let dataOption: any = [];
   dispatch(
    thirdPartySettingAction.getThirdPartyToShow({ skipCount: value })
   ).then((response: any) => {
    response.payload.items.map((item: any) => {
     const { code, description } = item;
     dataOption.push({
      label: description,
      value: code,
     });
    });
   });
  };
  if (costAnalysis?.thirdParties?.length > 0) {
   fetchThirdParty(0);
  }
 }, [costAnalysis?.thirdParties]);

 const fetchListFormat = async (value: any) => {
  await dispatch(masterDataAction.masterDataList(value)).then(
   (predefinedDataResponse: any) => {
    const options = predefinedDataResponse?.payload;
    if (options.result.items.length > 0) {
     const newOptions: any = [];
     options.result.items.map((item: any) => {
      newOptions.push({
       label: item.code,
       value: item.code,
      });
     });
     const sort = (x: any, y: any) => {
      return x.label.localeCompare(y.label);
     };
     if (options.result.items[0]?.configuration?.identityCode === "Format") {
      setOptionFormat(newOptions.sort(sort));
     }
     if (options.result.items[0]?.configuration?.identityCode === "Currency") {
      setOptionCurrency(newOptions.sort(sort));
     }
     if (options.result.items[0]?.configuration?.identityCode === "UoM") {
      setOptionUoM(newOptions.sort(sort));
     }
     if (options.result.items[0]?.configuration?.identityCode === "Country") {
      setOptionCountry(newOptions.sort(sort));
     }
     if (options.result.items[0]?.configuration?.identityCode === "Brand") {
      setOptionBrand(newOptions.sort(sort));
     }
     if (options.result.items[0]?.configuration?.identityCode === "Supplier") {
      setOptionSupplier(newOptions.sort(sort));
     }
     if (options.result.items[0]?.configuration?.identityCode === "Factory") {
      setOptionFactory(newOptions.sort(sort));
     }
    }
   }
  );
 };

 useEffect(() => {
  const productConfigList: any = [];
  if (!isEmpty(costAnalysis)) {
   productConfigList.push(
    costAnalysis.currency,
    // costAnalysis.format,
    costAnalysis.uoM
   );
  }
  productConfigList.map((item: any) =>
   fetchListFormat({
    skipCount: 0,
    maxResultCount: 100,
    searchKey: "",
    configurationId: item?.configuration?.id,
    type: 0,
    thirdPartyId: "",
   })
  );
 }, []);

 useEffect(() => {
  const volume = costAnalysis?.volumes?.find(
   (element: any) => element?.volume === costAnalysis?.summary?.volume
  );
  if (volume) {
   const payload = {
    costAnalysisId: pricingId,
    perVolumeId: volume.id,
   };
   if (initialValues !== isEmpty()) {
    setInitialValues(initialValues);
   } else {
    dispatch(pricingCostDriverAction.getDetailCostDriversVolume(payload));
   }
  }
 }, [costAnalysis]);

 useEffect(() => {
  if (!detailPerVolumeId) return;
  if (Object.keys(detailPerVolumeId).length == 0) return;
 }, [detailPerVolumeId]);

 useEffect(() => {
  if (!costAnalysis || !detailPerVolumeId) return;
  if (
   Object.keys(costAnalysis).length == 0 &&
   Object.keys(detailPerVolumeId).length == 0
  )
   return;
  const customFields: any = [];
  if (detailPerVolumeId.customFields.length > 0) {
   detailPerVolumeId.customFields.map(
    async (customField: any, index: number) => {
     if (!customField.costDriver) {
      //  if (customField.configuration.type === 10) {
      var customFieldUpdated = dataTOSend.customFields.find(
       (i: any) => i.customFieldId === customField.id
      );
      const data = {
       customFieldId: customField.id,
       customFieldConfigurationId: customField.configuration.id,
       name: customFieldUpdated ? customFieldUpdated.value : customField.value,
       displayName: customField.configuration.displayName,
       type: customField.configuration.type,
       sequence: customField.configuration.sequence,
      };
      customFields.push(data);
     }
    }
    //  }
   );
  }
  if (!isEmpty(detailPerVolumeId)) {
   setDataAdditional([
    {
     label: "Valid Date From",
     value:
      detailPerVolumeId.validDateFrom !== null
       ? detailPerVolumeId.validDateFrom
       : "-",
    },
    {
     label: "Valid Date To",
     value:
      detailPerVolumeId.validDateTo !== null
       ? detailPerVolumeId.validDateTo
       : "-",
    },
    {
     label: "Approved Date",
     value:
      detailPerVolumeId.approvedDate !== null
       ? detailPerVolumeId.approvedDate
       : "-",
    },
    {
     label: "Last Modified Date",
     value:
      detailPerVolumeId.modifiedDate !== null
       ? detailPerVolumeId.modifiedDate
       : "-",
    },
    {
     label: "Approved By",
     value:
      detailPerVolumeId.approvedBy !== null
       ? detailPerVolumeId.approvedBy
       : "-",
    },
    {
     label: "Last Modified By",
     value:
      detailPerVolumeId.updatedBy !== null ? detailPerVolumeId.updatedBy : "-",
    },
   ]);
  }

  const volume = detailPerVolumeId;

  const newCostAnalysis = {
   costAnalysisPerVolumeId: volume.id,
   name: dataTOSend.name || costAnalysis.name,
   description: dataTOSend.description || costAnalysis.description,
   //  formatCode: dataTOSend.formatCode || costAnalysis?.format?.code,
   currencyCode: dataTOSend.currencyCode || costAnalysis?.currency?.code,
   uoMCode: dataTOSend.uoMCode || costAnalysis?.uoM?.code,
   adds: dataTOSend.adds,
   updates: dataToSendUpdates,
   deletes: dataTOSendDeletes,
   customFields: customFields,
  };

  if (newCostAnalysis.customFields.length >= 0) {
   setInitialValues(newCostAnalysis);
  }
 }, [costAnalysis, detailPerVolumeId]);

 useEffect(() => {
  if (initialValues == isEmpty()) {
   dispatch(
    pricingCostDriverAction.setDirectSetTemporaryDataCostDriver(dataTmp)
   );
  }
 }, [dataTmp]);

 const handleChangeForm = (value: any) => {
  setDataTmp(value);
 };
 
 return (
  <Box>
   {!loading && !summaryLoading ? <>
    <Box border={"solid 1px #D7DBE2"} borderRadius="5px">
    <Grid w={"80%"} templateColumns="repeat(2, 1fr)" gap={8} m="1rem">
     <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
      <Text w="140px" fontWeight={600}>
       {costAnalysis?.configuration?.productLabelName}
      </Text>
      <Text>{costAnalysis?.name}</Text>
     </Grid>
     <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
      <Text w="140px" fontWeight={600}>
       {costAnalysis?.configuration?.productLabelDescription}
      </Text>
      <Text>{costAnalysis?.description}</Text>
     </Grid>
     <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
      <Text w="140px" fontWeight={600}>
      {costAnalysis?.configuration?.currencyLabelName}
      </Text>
      <Text>{costAnalysis?.currency?.description}</Text>
     </Grid>
     <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
      <Text w="140px" fontWeight={600}>
       {costAnalysis?.configuration?.uoMLabelName === "UoM"
        ? costAnalysis?.uoM?.configuration?.identityName
        : costAnalysis?.configuration?.uoMLabelName}
      </Text>
      <Text>{costAnalysis?.uoM?.description}</Text>
     </Grid>
     {initialValues &&
      Array.isArray(initialValues?.customFields) &&
      initialValues?.customFields.length > 0 &&
      initialValues?.customFields
       .sort((prev: any, next: any) => (prev.sequence < next.sequence ? -1 : 1))
       .map((customField: any, index: number) => {
        const { name, displayName, type, options } = customField;
        if ([20,40,50].includes(type)) {
         const findDescrition = options?.find(
          (itemName: any) => itemName.value === name
         );

         return (
          <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
           <Text w="140px" fontWeight={600}>
            {displayName}
           </Text>
           <Text>{!isEmpty(name) ? parseFloat(name).toFixed(5) : "-"}</Text>
          </Grid>
         );
        } else {
         return (
          <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
           <Text w="140px" fontWeight={600}>
            {displayName}
           </Text>
           <Text>{!isEmpty(name) ? name : "-"}</Text>
          </Grid>
         );
        }
       })}
    </Grid>
   </Box>
   <Box border={"solid 1px #D7DBE2"} borderRadius="5px" mt="1rem">
   <Grid w={"80%"} templateColumns="repeat(2, 1fr)" gap={8} m="1rem">
   {dataAdditional?.map((item: any, index: number) => (
    <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8} key={index}>
     <Text w="140px" fontWeight={600}>
      {item.label}
     </Text>
     <Text>{item.value}</Text>
    </Grid>
   ))}
   {detailPerVolumeId?.volumeReferenceType === 10 &&
    detailPerVolumeId?.volumeReferenceName && (
     <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
      <Text w="140px" fontWeight={600}>
       Copied from
      </Text>
      <Text>{detailPerVolumeId?.volumeReferenceName}</Text>
     </Grid>
    )}
   {detailPerVolumeId?.volumeReferenceType === 20 &&
    detailPerVolumeId?.volumeReferenceName && (
     <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
      <Text w="140px" fontWeight={600}>
       Replace from
      </Text>
      <Text>{detailPerVolumeId?.volumeReferenceName}</Text>
     </Grid>
    )}
   </Grid>
   </Box></> : <TableLoading />}
  </Box>
 );
};

export default SkuCharacteristic;

const FormObserver = () => {
 const { values, submitForm } = useFormikContext();
 useEffect(() => {
  if (values) submitForm();
 }, [values, submitForm]);
 return null;
};
