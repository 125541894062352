import { ReactElement, useState, useRef, useEffect, useMemo } from "react";
import { Box, Flex, Spacer } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { RootState } from "state/store";
import { useSelector, shallowEqual } from "react-redux";
import { useAppDispatch } from "hooks";
import debounce from "lodash/debounce";
import { FieldArray, Form, Formik } from "formik";
import { HStack } from "@chakra-ui/react";

import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import TableView from "views/EditPricings/CostDrivers/EditTableCostDriver/TableView";
// import AddRowView from "views/EditPricings/CostDrivers/EditTableCostDriver/AddRow";

import unCollapse from "assets/images/upArrow.png";
import collapse from "assets/images/downArrow.png";

const ReplaceCostDriver = ({
 costDriver,
 paramName,
 level,
}: any): ReactElement => {
 const dispatch = useAppDispatch();
 const [isShowTableDetail, toogleShowTableDetail] = useState(true);
 const [removeLogistic, setRemoveLogistic] = useState<boolean>(false);
 const [widthBox, setWidthBox] = useState(0);
 const ref = useRef<HTMLDivElement>(null);
 const [initialValues, setInitialValues] = useState<any>();

 const {
  detailPerVolumeId,
  temporaryDataCostDriver,
  constDriversOri,
  tmpChangeRow,
  stateTmpId,
  isAddNew,
  volumeId,
 } = useSelector(
  (state: RootState) => ({
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId.result,
   dataToSendadds: state.editPricingCostDrivers.dataToSend.adds,
   dataToSend: state.editPricingCostDrivers.dataToSend,
   loadingSave: state.editPricingCostDrivers.loading,
   temporaryDataCostDriver:
    state.editPricingCostDrivers.temporaryDataCostDriver,
   valueIsIncludeCalculation:
    state.editPricingCostDrivers.valueIsIncludeCalculation,
   constDriversOri: state.editPricingCostDrivers.constDriversOri,
   tmpChangeRow: state.editPricingCostDrivers.tmpChangeRow,
   stateTmpId: state.editPricingCostDrivers.stateTmpId,
   isAddNew: state.editPricingCostDrivers.isAddNew,
   volumeId: state.editPricingCostDrivers.volumeId,
  }),
  shallowEqual
 );

 const onToogleShowTableDetail = () => {
  toogleShowTableDetail(!isShowTableDetail);
 };

 const debouncedRunSimulate = useMemo(
  () => debounce(async (dataToSendProp: any) => {}, 0),
  []
 );

 useEffect(() => {
  if (ref.current && ref.current.offsetWidth) {
   setWidthBox(ref.current.offsetWidth);
  }
 }, [ref.current]);

 useEffect(() => {
  const costDrivers: any = [];
  Array.isArray(costDriver?.data) &&
   costDriver?.data?.map((item: any) => {
    var dataItem = Object.assign({}, item);
    let itemIndex = tmpChangeRow.find((item2: any) => {
     return item2.idRow == dataItem.id;
    });
    dataItem.unitCost =
     item?.unitCost === 0
      ? 0
      : isNaN(item?.unitCost)
      ? Number.isNaN(item.unitCost)
        ? ""
        : item?.unitCost && item?.unitCost?.replace("%", "")
        ? item?.unitCost
        : ""
      : item?.unitCost;

    const findIdParam = temporaryDataCostDriver.findIndex(
     (el: any) => el.name === paramName
    );

    let ishaveBgColor = false;

    if (itemIndex?.addBgColor) {
     ishaveBgColor = true;
     temporaryDataCostDriver[findIdParam].data.map(
      (itemTmp: any, index: number) => {
       if (constDriversOri.data) {
        itemTmp.data.map((tmpD: any, inx: number) => {
         if (
          constDriversOri?.data.length > 0 &&
          constDriversOri?.data[index]?.data.length > 0 &&
          constDriversOri?.data[index]?.data[inx] !== undefined
         ) {
         }
        });
       }
      }
     );
    } else {
     temporaryDataCostDriver[findIdParam].data.map(
      (itemTmp: any, index: number) => {
       if (constDriversOri?.data) {
        itemTmp?.data?.map((tmpD: any, inx: number) => {
         if (
          constDriversOri?.data.length > 0 &&
          constDriversOri?.data[index]?.data.length > 0 &&
          constDriversOri?.data[index]?.data[inx] === undefined
         ) {
          if (!constDriversOri?.data[index]?.data[inx]) {
           ishaveBgColor = true;
          }
         }
        });
       }
      }
     );
    }

    dataItem.bgColor =
     itemIndex?.addBgColor || ishaveBgColor ? "#BBDEFB" : "#ffffff";
    const objectArray: any = [];
    Array.isArray(item.customFields) &&
     item.customFields.length > 0 &&
     item.customFields.map((itemCustomField: any) => {
      const value = itemCustomField.name;

      if (itemCustomField?.isMaintenanceByMasterData) {
       dataItem[`${value}-From-Masterdata`] = itemCustomField.value;
      } else {
       dataItem[value] = itemCustomField.value;
      }

      const data = {
       name: itemCustomField.isMaintenanceByMasterData
        ? `${value}-From-Masterdata`
        : value,
       value: itemCustomField.value,
       masterData: itemCustomField.masterData,
       typeData: itemCustomField.typeData,
       predefinedData: itemCustomField.predefinedData,
       isDisplayOnPricing: itemCustomField.isDisplayOnPricing,
       isIncludeInCalculation: itemCustomField.isIncludeInCalculation || false,
       isRequired: itemCustomField.isRequired,
       customFieldId: itemCustomField.id,
       originalValue: "",
       currencyId: itemCustomField?.currencyId || null,
       isMaintenanceByMasterData: itemCustomField.isMaintenanceByMasterData,
       customFieldConfigurationId: itemCustomField.customFieldConfigurationId,
      };
      objectArray.push(data);
      Array.isArray(item?.masterDataCustomFields) &&
       item.masterDataCustomFields.map((itemCustomField: any) => {
        dataItem[itemCustomField.name] = itemCustomField.value;
       });
     });
    dataItem.customFields = objectArray;
    costDrivers.push(dataItem);
   });
  setInitialValues({
   customFields: costDrivers,
  });
  dispatch(pricingCostDriverAction.setAddNew(false));
 }, [costDriver, tmpChangeRow, isAddNew, volumeId]);

 useEffect(() => {
  if (removeLogistic) {
   dispatch(pricingCostDriverAction.removeOneLogistic(stateTmpId));
   dispatch(
    pricingCostDriverAction.removeOnetmpChangeRow(costDriver.costDriverId)
   );
   setRemoveLogistic(false);
  }
 }, [removeLogistic]);

 useEffect(() => {
  dispatch(
   pricingCostDriverAction.setCostAnalysisPerVolumeId(detailPerVolumeId?.id)
  );
 }, [detailPerVolumeId, volumeId]);

 return (
  <Formik
   enableReinitialize
   initialValues={initialValues}
   onSubmit={async (values) => {}}
   validateOnChange
  >
   {({ setFieldValue, values }: any) => {
    return (
     <Form>
      <Box
       w="100%"
       h={!isShowTableDetail ? "7rem" : "100%"}
       mb="2rem"
       position="relative"
       padding="0.5rem"
      >
       <Flex
        ref={ref}
        //  spacing="75%"
        alignItems="baseline"
       >
        <Box as="p" fontWeight="bold" ml="1.5rem" mt="1rem">
         {costDriver.identityName}
        </Box>
        <Spacer />
        <Box pr="2rem">
         <HStack spacing="24px">
          <Box>
           {!isShowTableDetail && (
            <Image
             src={unCollapse}
             onClick={onToogleShowTableDetail}
             pt="0.8rem"
             cursor="pointer"
             alt="Bulp"
            />
           )}
           {isShowTableDetail && (
            <Image
             src={collapse}
             onClick={onToogleShowTableDetail}
             pt="0.8rem"
             cursor="pointer"
             alt="Bulp"
            />
           )}
          </Box>
         </HStack>
        </Box>
       </Flex>
       {isShowTableDetail && (
        <Box overflowX="auto" maxW={`${widthBox}px`}>
         <FieldArray
          name="customFields"
          render={(arrayHelpers) => {
           return (
            <>
             <TableView
              costDriver={costDriver}
              paramName={paramName}
              level={level}
              values={values}
              arrayHelpers={arrayHelpers}
              setFieldValue={setFieldValue}
             />

             {/* {level !== 30 ||
              (costDriver.canAddNew && (
               <Box
                float="right"
                pb="5"
                pt="5"
                position="absolute"
                right="4rem"
                top="-1.2rem"
               >
                <AddRowView
                 values={values}
                 costDriver={costDriver}
                 paramName={paramName}
                 arrayHelpers={arrayHelpers}
                />
               </Box>
              ))} */}
            </>
           );
          }}
         />
        </Box>
       )}
      </Box>
     </Form>
    );
   }}
  </Formik>
 );
};

export default ReplaceCostDriver;
