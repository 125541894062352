import { useEffect, useRef } from "react";
import Layout from "views/Layout";
import { Box, Heading, VStack } from "@chakra-ui/layout";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import Stepper from "views/AnnualSpend/Compare/Stepper";
import Case from "views/AnnualSpend/Compare/Case";
import Journey from "views/AnnualSpend/Compare/Journey";
import Summary from "views/AnnualSpend/Compare/Summary";

export default function Comparation() {
 const router = useHistory();
 const messagesEndRef = useRef<null | HTMLDivElement>(null);
 const { location } = router;
 const { search } = location;
 const query = queryString.parse(search);

 useEffect(() => {
  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
 }, [search]);

 useEffect(() => {
  window.scrollTo(0, 0);
  if (query?.page !== "select-journey") {
   window.location.href = "/annual-spend-simulations/comparation/create?page=select-journey";
  }
 }, []);

 return (
  <Layout>
   <VStack h="90vh" align="flex-start" ref={messagesEndRef}>
    <Box display="flex" w="100%">
     <Heading>
      {query?.page === "select-journey" && "Comparation"}
      {query?.page === "select-business-case" && "Comparation"}
      {query?.page === "summary" && "Comparation"}
     </Heading>
    </Box>
    <Stepper />
    {/* {query?.page !== "summary" ? <Stepper /> : <></>} */}
    <Box w="100%">
     {query?.page === "select-journey" && <Journey />}
     {query?.page === "select-business-case" && <Case />}
     {query?.page === "summary" && <Summary />}
    </Box>
   </VStack>
  </Layout>
 );
}
