import { ChangeEvent } from "react";
import {
 Box,
 Button,
 FormControl,
 InputRightElement,
 InputGroup,
 HStack,
 Input,
 useNumberInput,
} from "@chakra-ui/react";
import { Field, useField } from "formik";

interface FormInputProps {
 label: string;
 name: string;
 onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
 isPercentage: any;
}

export default function FormInput({
 label,
 name,
 onChange,
 isPercentage,
}: FormInputProps) {
 const [field, meta, helpers] = useField(name);
 const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
  useNumberInput({
   ...field,
   step: 0.01,
   defaultValue: 0,
   precision: 2,
   onChange: (valueAsString, valueAsNumber) => helpers.setValue(valueAsNumber),
  });
 const inc = getIncrementButtonProps();
 const dec = getDecrementButtonProps();
 const input = getInputProps();
 const percentage = isPercentage === "true";
 return (
  <Field name={name}>
   {({ form }: any) => {
    return (
     <FormControl>
      <Box pb="10px" fontWeight="bold">
       {label}
      </Box>
      <HStack>
       <Button size={'sm'} {...dec}>-</Button>
       <InputGroup>
        {percentage ? (
         <InputRightElement pointerEvents="none" children="%" />
        ) : (
         <InputRightElement pointerEvents="none" children="$" />
        )}
        <Input
         {...input}
         onChange={(e) => {
          if (onChange) {
           onChange(e);
          }
          helpers.setValue(e.currentTarget.value);
         }}
        />
       </InputGroup>
       <Button size={'sm'} {...inc}>+</Button>
      </HStack>
     </FormControl>
    );
   }}
  </Field>
 );
}
