import axios from "axios";
import { getStorage } from "utils/storage-util";
import AppConsts from "constants/AppConst";
import AuthService from "services/authService";
import OptipediaToast from "utils/notification-util";

export const http = axios.create({
 baseURL: AppConsts.REACT_APP_BACKEND_URL,
//  timeout: 30000,
 timeoutErrorMessage:
  "Sorry! Something unexpected happened, hence your request can not be completed.",
});
http.interceptors.request.use(
 async function (config) {
  var access_token = getStorage("access_token");
  if (access_token) {
   config.headers.Authorization = "Bearer " + access_token;
  } else {
   var oidcAuthService = new AuthService();
   if (oidcAuthService.isAuthenticated()) {
    var user = await oidcAuthService.getUser();
    if (user) config.headers.Authorization = "Bearer " + user.access_token;
   }
  }
  return config;
 },
 function (error) {
  return Promise.reject(error);
 }
);

http.interceptors.response.use(
 (response) => responseHandler(response),
 errorResponseHandler
);

function responseHandler(response: any) {
 if (response.config.method === "post" || response.config.method === "put") {
  if (response.config.headers.notUseToast) {
   //
  } else {
   OptipediaToast.Success("All changes have been saved.");
  }
 }
 return response.data;
}

async function errorResponseHandler(error: any) {
 if (!error.config) return Promise.reject(error);
 if(error.response.status === 500 && error.response.data instanceof Blob){
  OptipediaToast.Error(
    JSON.parse(await error.response.data.text()).Message || JSON.parse(await error.response.data.text()).message
   );
 }
 if (!error.response) {
  OptipediaToast.Error(
   "Sorry! Something unexpected happened, hence your request can not be completed."
  );
 } else if (
  (error.response.status === 401 || error.response.status === 403) &&
  !error.config._retry
 ) {
  return unAuthrorizeHandler(error);
 } else {
  if (error.response.headers["token-expired"] !== "true") {
   var defaultErrorMessage =
    "Sorry! Something unexpected happened, hence your request can not be completed.";
   if (error.response) {
    var message = "";
    if (typeof error.response.data === "string") {
     message = error.response.data;
    } else {
     if (error.response.data.error && error.response.data.error.message) {
      message = error.response.data.error.message;
     } else if (error.response.data.message) {
      message = error.response.data.message;
     } else if (error.response.data.Message) {
      message = error.response.data.Message;
     } else if (typeof error.response.data.errors === "object") {
      message =
       error.response.data.errors[
        Object.keys(error.response.data.errors)[0]
       ][0];
     } else if (typeof error.response.data.result.errors === "object") {
      let errorMessages: any = [];
      const ObjectKey = Object.keys(error.response.data.result.errors);
      ObjectKey.map((item) => {
       error.response.data.result.errors[item].map((message: any) => {
        errorMessages.push(`${message}`);
       });
      });
      message = errorMessages.join(", ");
     } else {
      message = defaultErrorMessage;
     }
    }
    OptipediaToast.Error(message);
   } else {
    OptipediaToast.Error(defaultErrorMessage);
   }
  }
 }
 return Promise.reject(error);
}

async function unAuthrorizeHandler(error: any) {
 if (error.response.headers["token-expired"] === "true") {
  var authService = new AuthService();
  const originalRequest = error.config;

  await authService
   .signinSilent()
   .then((access_token) => {
    originalRequest._retry = true;
    originalRequest.headers.Authorization = "Bearer " + access_token;
    return axios.request(originalRequest);
   })
   .catch(() => {
    return Promise.reject(error);
   });
 }
 return Promise.reject(error);
}

export default http;
