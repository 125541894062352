import { ReactElement, useEffect } from "react";
import { Box, Flex, Text, VStack, Spacer } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { ChevronRight } from "components/Icons";
import { useAppDispatch } from "hooks";
import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { actions as thirdPartySettingAction } from "state/settings/slice";
const MultiCurrency = (): ReactElement => {
 const dispatch = useAppDispatch();
 const { currencyId, listExchange } = useSelector(
  (state: RootState) => ({
   listExchange : state.multiCurrency.listsExchange,
   currencyId: state.thirdPartySetting.currencyId,
  }),
  shallowEqual
 );
 const history = useHistory();
 const fetchList = (payload: any) => {
  dispatch(multiCurrencyAction.listExchange(payload));
 };
 const getDefaultCurrency = () => {
  dispatch(thirdPartySettingAction.configurationStatus());
 };
 useEffect(() => {
  const payload = {
   skipCount: 0,
   maxResultCount: 100,
  };
  fetchList(payload);
  getDefaultCurrency();
 }, []);
 const currencyOpt: any = [];
 listExchange?.items?.map((item: any) => {
  currencyOpt.push({
   label: item?.currency?.code,
   value: item?.currency?.id,
  });
 });

 return (
  <Box>
   <Flex alignItems="center" mb="1.5rem" gap="9">
    <Text color="rgba(26, 26, 26, 1)">Company Currency</Text>
   {currencyId && <Box border="solid 1px" borderRadius={5} px={5}>
        {currencyOpt?.find((data: any) => data?.value === currencyId)?.label}
    </Box>}
   </Flex>
   <VStack spacing={1} align="stretch">
    <Flex
     cursor={"pointer"}
     onClick={() => history.push("/settings/multi-currency?page=currency")}
     alignItems="center"
     mb="1.5rem"
     gap="9"
    >
     <Text color="rgba(26, 26, 26, 1)">Currency</Text>
     <Spacer />
     <ChevronRight width="24px" height="24px" />
    </Flex>
    <Flex
     cursor={"pointer"}
     onClick={() => history.push("/settings/multi-currency?page=exchange")}
     alignItems="center"
     mb="1.5rem"
     gap="9"
    >
     <Text color="rgba(26, 26, 26, 1)">Exchange Rate</Text>
     <Spacer />
     <ChevronRight width="24px" height="24px" />
    </Flex>
   </VStack>
  </Box>
 );
};

export default MultiCurrency;
