import { ReactElement, useEffect, useState } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { Link, useHistory } from "react-router-dom";
import { Box, Heading, Flex, Stack, Spacer } from "@chakra-ui/layout";
import { actions as approvalListAction } from "state/approval/slice";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";

import {
 Button,
 InputGroup,
 InputRightElement,
 Select,
 Checkbox,
} from "@chakra-ui/react";
import Layout from "views/Layout";
import SearchBar from "components/SearchBar";
import Table from "components/Table/Table";
import { MdArrowDropDown } from "react-icons/md";
import { isEmpty } from "lodash";
import ToolbarBottom from "./ToolbarBottom";
import { actions as approvalAction } from "state/approval/slice";
import EmptyState from "components/EmptyState";
import { Formik, Form } from "formik";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";

const Approval = (): ReactElement => {
 const dispatch = useAppDispatch();
 const history = useHistory();
 const maxResultCount = 100;
 const [statusPricing, setStatusPricing] = useState<any>();
 const [journeySelection, setJourneySelection] = useState("");
 const [thirdrdPartySelection, setThirdPartySelection] = useState("");
 const {
  loading,
  searchQuery,
  approvalListData,
  isAdmin,
  thirdParty,
  journeyLists,
  isEnableThirdPartyStatus,
  selection,
  user,
  configuration,
  approvalSelect,
 } = useSelector(
  (state: RootState) => ({
   loading: state.approval.loading,
   searchQuery: state.approval.searchQuery,
   approvalListData: state.approval.lists.result.items,
   totalCountApproval: state.approval.lists.result.totalCount,
   selection: state.approval.approvalSelect,
   isAdmin: state.auth.result.isAdmin,
   thirdParty: state.thirdPartySetting.thirdParty.items,
   journeyLists: state.productConfigurations.result.items,
   isEnableThirdPartyStatus: state.thirdPartySetting.isEnableThirdPartyStatus,
   user: state.auth.result,
   configuration: state.approval.configuration.result,
   approvalSelect: state.approval.approvalSelect,
  }),
  shallowEqual
 );

 const dataTable: any = [];
 const fetchListOf3rdParty = (skipCount: number) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: maxResultCount,
  };
  return dispatch(thirdPartySettingAction.getThirdPartyToShow(payload));
 };

 const fetch3rdStatus = () => {
  return dispatch(thirdPartySettingAction.configurationStatus());
 };

 const fetchList = () => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   search: searchQuery,
   journeyCode: journeySelection,
   thirdPartyCode: thirdrdPartySelection,
  };

  if (!user.isAdmin) {
   dispatch(approvalListAction.approvalList(payload));
  } else {
   dispatch(approvalListAction.approvalList(payload));
  }
 };
 const productConfiguration = () => {
  dispatch(approvalAction.getApprovalConfiguration());
 };
 useEffect(() => {
  productConfiguration();
  fetchList();
  // fetchListOfJourney(0);
  fetch3rdStatus();
 }, [searchQuery, thirdrdPartySelection, journeySelection]);

 useEffect(() => {
  if (isEnableThirdPartyStatus) {
   fetchListOf3rdParty(0);
  }
 }, [isEnableThirdPartyStatus]);

 const COLUMN_HEADERS : any = [
  { name: "name", label: "Product ID" },
  { name: "description", label: "Product Description" },
  { name: "thirdPartyName", label: "Third-Party" },
  { name: "volume", label: "Volume" },
  { name: "journeyName", label: "Journey" },
 ];
 approvalListData?.length > 0 &&
  approvalListData.map((item: any) => {
   dataTable.push({
    volumeId: item?.id,
    id: item?.costAnalysis?.id,
    name: item?.costAnalysis?.name,
    description: item?.costAnalysis?.description,
    thirdPartyName: item?.thirdParty?.description,
    volume: item?.volume,
    status: item?.status,
    journeyName: item?.costAnalysis?.configuration?.journeyName,
   });
  });

 const handleJourneySelection = (value: any) => {
  value !== "all" ? setJourneySelection(value) : setJourneySelection("");
 };

 const handle3rdPartySelection = (value: any) => {
  value !== "all" ? setThirdPartySelection(value) : setThirdPartySelection("");
 };
 const handleChangeSearchBar = (event: any) => {
  dispatch(
   approvalListAction.setSearchQuery({
    value: event.currentTarget.value,
   })
  );
 };
 const setCheckBox = (isChecked: boolean, value: string) => {
  if (isChecked) {
   dispatch(approvalListAction.setApprovalSelection(value));
  } else {
   dispatch(approvalListAction.removeApprovalSelection(value));
  }
 };
 const setAllCheckBox = (isChecked: boolean) => {
  const ids = dataTable.map((item:any) => item.volumeId);
  if (isChecked) {
  dispatch(approvalListAction.setAllApprovalSelection(ids));
   } else {
    dispatch(approvalListAction.setAllApprovalSelection([]));
   }
 };
 
 const optSelect: any = [{ value: "all", label: "All" }];
 configuration?.map((item: any, index: number) =>
  optSelect.push({
   value: item.journeyCode,
   label: `${item.journeyCode} -  ${item.journeyName}`,
  })
 );
 const optThirdparty: any = [{ value: "all", label: "All" }];
 {
  !isEmpty(thirdParty) &&
   thirdParty.map((item: any) =>
    optThirdparty.push({
     value: item.code,
     label: item.description,
    })
   );
 }


 return (
  <Layout>
   <Flex>
    <Heading mb="1rem">Approval Page</Heading>
    <Spacer />
    <Box>
     <SearchBar
      value={searchQuery}
      onChange={handleChangeSearchBar}
      placeholder={"Search"}
      isLoading={loading}
     />
    </Box>
   </Flex>
   <Flex my={"1rem"}>
    <Box>
     {user.isAdmin && (
      <Flex>
       <Formik
        initialValues={{ optSelect: "", optThirdparty: "" }}
        onSubmit={() => {}}
       >
        {({}) => (
         <Form>
          <Flex>
           <Box mx={1}>
            <SelectWithAutoComplete
            width={200}
             name="optSelect"
             defaultValue="all"
             onChange={(e: any) => handleJourneySelection(e.value)}
             options={optSelect}
            />
            </Box>
            {isEnableThirdPartyStatus && (
             <Box mx={1}>
              <SelectWithAutoComplete
              width={200}
              name="value"
              defaultValue="all"
              onChange={(e: any) => handle3rdPartySelection(e.value)}
              options={optThirdparty}
             />
            </Box>
           )}
          </Flex>
         </Form>
        )}
       </Formik>
       <Button
        size="custom"
        h="52px"
        w="250px"
        onClick={() => history.push("/approval/annual-spend-impact")}
       >
        Impact Analysis
       </Button>
      </Flex>
     )}
    </Box>
    <Spacer />
   </Flex>
   <Box w="100%">
    {!loading && (
     <Box w="100%" mt="2rem" mb="2rem">
      {!loading && Array.isArray(dataTable) && dataTable.length > 0 && (
       <Table
        loading={loading}
        columnHeaders={COLUMN_HEADERS}
        data={dataTable}
        isSelectAll={false}
        isChecked={approvalSelect.length >= dataTable?.length}
        checkBoxSelectAll={(val: any) => {
          setAllCheckBox(val.target.checked)
          setStatusPricing(1);
        }}
        actionButtons={(approvalId: string, rowData: any) => (
         <Stack spacing={4} direction="row" align="center" float="right">
          <Button
           variant={"link"}
           onClick={() => {
            dispatch(
             pricingCostDriverAction.setVolumeFromApprovalPageList(true)
            );
            history.push({
             pathname: `/pricings/cost-summary-by-sku/${approvalId}`,
             state: {
              status: rowData.status,
              volumeId: rowData.volumeId,
             },
            });
           }}
          >
           View
          </Button>
         </Stack>
        )}
        checkBoxSelect={(pricingId: string, rowData: any) => (
         <Checkbox
          isChecked={approvalSelect.includes(rowData.volumeId)}
          value={rowData.volumeId}
          onChange={(val) => {
           setStatusPricing(rowData.status);
           setCheckBox(val.target.checked, val.currentTarget.value);
          }}
         />
        )}
       />
      )}
     </Box>
    )}
    {!loading && Array.isArray(dataTable) && dataTable.length === 0 && (
     <EmptyState isSearch={!isEmpty(searchQuery)}>

     </EmptyState>
    )}
    {selection.length > 0 && (
     <ToolbarBottom status={statusPricing} user={user.userName} />
    )}
   </Box>
  </Layout>
 );
};

export default Approval;
