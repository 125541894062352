import { ReactElement, useEffect, useState } from "react";
import {
 Box,
 Flex,
 Menu,
 MenuButton,
 MenuList,
 MenuItem,
 Button,
 IconButton,
 Image,
 Spacer,
} from "@chakra-ui/react";
import Table from "components/Table/Table";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useAppDispatch } from "hooks";
import IconKebab from "assets/images/3dots.png";
import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import EmptyState from "components/EmptyState";
import CurrencyUpload from "./Upload";
import CurrencyCreated from "./CreatedForm";
import Swal from "sweetalert2";
import CurrencyDetails from "./Details";
import SearchBar from "components/SearchBar";
import OptipediaToast from "utils/notification-util";
import NewPagination from "components/NewPagination";
import { isEmpty } from "lodash";

const CurrencyExchange = ({ history }: RouteComponentProps): ReactElement => {
 const dispatch = useAppDispatch();
 const [pageNumber, setPageNumber] = useState(1);
 const maxResultCount = 10;
 const {
  currencyUpload,
  currencyCreated,
  currencyUpdated,
  currencyView,
  listCurrency,
  searchQuery,
  totalPage,
  totalCount,
 } = useSelector(
  (state: RootState) => ({
   currencyUpload: state.multiCurrency.currencyUpload,
   currencyCreated: state.multiCurrency.currencyCreated,
   currencyUpdated: state.multiCurrency.currencyUpdated,
   currencyView: state.multiCurrency.currencyView,
   listCurrency: state.multiCurrency.lists,
   searchQuery: state.multiCurrency.searchQuery,
   totalPage: Math.ceil(state.multiCurrency.lists.totalCount / maxResultCount),
   totalCount: state.multiCurrency.lists.totalCount,
  }),
  shallowEqual
 );
 const COLUMN_HEADER = [
  { name: "code", label: "Currency Code" },
  { name: "description", label: "Currency Description" },
 ];

 const fetchList = (payload: any) => {
  dispatch(multiCurrencyAction.listMultiCurrency(payload));
 };
 useEffect(() => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   keyword: searchQuery,
   ManageExchangeRate: false
  };
  fetchList(payload);
 }, [searchQuery]);
 const handleChangeSearchBar = (event: any) => {
  dispatch(
   multiCurrencyAction.setSearchQuery({
    value: event.currentTarget.value,
   })
  );
 };

 useEffect(() => {
  dispatch(multiCurrencyAction.setSearchQuery({ value: "" }));
 }, [history.location.pathname]);

 useEffect(() => {
  const clearSearchQuery = () => {
   dispatch(multiCurrencyAction.setSearchQuery({ value: "" }));
  };
  clearSearchQuery();
 }, []);

 const handleChangePagination = (pageNumber: number) => {
  setPageNumber(pageNumber);
  var skipCount = (pageNumber - 1) * maxResultCount;
  const payload = {
   skipCount,
   maxResultCount: maxResultCount,
   keyword: searchQuery,
   ManageExchangeRate:false
  };
  fetchList(payload);
 };
 return (
  <Box mt={5}>
   {!currencyUpload &&
    !currencyCreated &&
    !currencyUpdated &&
    !currencyView && (
     <Flex>
      {isEmpty(searchQuery) && listCurrency?.items?.length !== 0 && (
       <>
        <Button
         mr={5}
         onClick={() => {
          dispatch(multiCurrencyAction.setSearchQuery({ value: "" }));
          dispatch(multiCurrencyAction.setCurrencyCreated(true));
         }}
        >
         Create New
        </Button>
        <Button
         variant={"outline"}
         onClick={() => {
          dispatch(multiCurrencyAction.setSearchQuery({ value: "" }));
          dispatch(multiCurrencyAction.setCurrencyUpload(true));
         }}
        >
         Upload
        </Button>
       </>
      )}
      <Spacer />
      <Box mt={2}>
       <SearchBar
        value={searchQuery}
        placeholder="Search..."
        onChange={handleChangeSearchBar}
       />
      </Box>
     </Flex>
    )}
   {listCurrency?.items?.length > 0 &&
    !currencyUpload &&
    !currencyCreated &&
    !currencyUpdated &&
    !currencyView && (
     <>
      <Table
       havePagination={true}
       loading={false}
       columnHeaders={COLUMN_HEADER}
       data={listCurrency?.items || []}
       pageNumber={totalPage}
       currentPage={pageNumber}
       totalAllPage={totalCount}
       totalRowsPage={maxResultCount}
       actionButtons={(id: string, rowData: any) => {
        return (
         <Menu>
          <MenuButton
           as={IconButton}
           variant="ghost"
           size="custom"
           height="30px"
           width="30px"
           aria-label="Options"
           _focus={{
            borderColor: "#ffffff",
           }}
           icon={
            <Image src={IconKebab} cursor="pointer" alt="Option" w="4px" />
           }
          />
          <MenuList>
           <MenuItem
            onClick={() => {
             dispatch(multiCurrencyAction.setCurrencyDataToEdit(rowData));
             dispatch(multiCurrencyAction.setCurrencyUpdated(true));
            }}
           >
            Edit
           </MenuItem>
           <MenuItem
            onClick={() =>
             Swal.fire({
              showCancelButton: true,
              html: "Are you sure you want to delete this Currency?",
              cancelButtonText: "No",
              confirmButtonText: "Yes",
             }).then(async (result) => {
              if (result.isConfirmed) {
               await dispatch(multiCurrencyAction.deletes(id)).then(
                (res: any) => {
                 if (res.payload !== undefined) {
                  OptipediaToast.Success(
                   "The Currencies has been removed successfully."
                  );
                 }
                }
               );
               dispatch(
                multiCurrencyAction.setSearchQuery({
                 value: "",
                })
               );
               const payload = {
                skipCount: 0,
                maxResultCount: maxResultCount,
                keyword: searchQuery,
                manageExchangeRate: false
               };
               fetchList(payload);
              }
             })
            }
           >
            Delete
           </MenuItem>
           <MenuItem
            onClick={() => {
             dispatch(multiCurrencyAction.setCurrencyDataToEdit(rowData));
             dispatch(multiCurrencyAction.setCurrencyView(true));
            }}
           >
            View
           </MenuItem>
          </MenuList>
         </Menu>
        );
       }}
      />
     </>
    )}
   {Array.isArray(listCurrency?.items) &&
    listCurrency?.items?.length > 0 &&
    !currencyUpload &&
    !currencyCreated &&
    !currencyUpdated &&
    !currencyView && (
     <Box w="100%" mt="1rem" p="20px">
      <NewPagination
       onChange={handleChangePagination}
       totalRowsPage={maxResultCount}
       pageNumber={totalPage}
       currentPage={pageNumber}
       totalAllPage={totalCount}
      />
     </Box>
    )}
   {listCurrency?.items?.length === 0 &&
    !currencyUpload &&
    !currencyCreated && (
     <EmptyState isSearch={false}>
      <Flex>
       <Button
        mr={5}
        onClick={() => {
         dispatch(multiCurrencyAction.setSearchQuery({ value: "" }));
         dispatch(multiCurrencyAction.setCurrencyCreated(true));
        }}
       >
        Create New
       </Button>
       <Button
        variant={"outline"}
        onClick={() => {
         dispatch(multiCurrencyAction.setSearchQuery({ value: "" }));
         dispatch(multiCurrencyAction.setCurrencyUpload(true));
        }}
       >
        Upload
       </Button>
      </Flex>
     </EmptyState>
    )}
   {currencyUpload && <CurrencyUpload />}
   {currencyCreated && <CurrencyCreated />}
   {currencyUpdated && <CurrencyCreated />}
   {currencyView && <CurrencyDetails />}
  </Box>
 );
};

export default withRouter(CurrencyExchange);
