import { ChangeEvent } from "react";
import {
 Box,
 Button,
 FormControl,
 FormErrorMessage,
 InputRightElement,
 InputGroup,
 HStack,
 Input,
 useNumberInput,
} from "@chakra-ui/react";
import { Field, useField } from "formik";

interface FormInputProps {
 label: string;
 name: string;
 onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function FormInput({ label, name, onChange }: FormInputProps) {
 const [field, meta, helpers] = useField(name);
 const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
  useNumberInput({
   ...field,
   step: 0.01,
   defaultValue: 0,
   precision: 2,
   onChange: (valueAsString, valueAsNumber) => helpers.setValue(valueAsNumber),
  });
 const inc = getIncrementButtonProps();
 const dec = getDecrementButtonProps();
 const input = getInputProps();

 return (
  <Field name={name}>
   {({ form }: any) => {
    return (
     <FormControl>
      <Box pb="10px" fontWeight="bold">
       {label}
      </Box>
      <HStack>
       <Button {...dec}>-</Button>
       <InputGroup>
        <InputRightElement pointerEvents="none" children="%" />
        <Input
         {...input}
         onChange={(e) => {
          if (onChange) {
           onChange(e);
          }
          helpers.setValue(e.currentTarget.value);
         }}
        />
       </InputGroup>
       <Button {...inc}>+</Button>
      </HStack>
     </FormControl>
    );
   }}
  </Field>
 );
}
