import { useErrorFocus } from "hooks";
import {
 FormControl,
 FormLabel,
 InputProps,
 Box,
 Stack,
} from "@chakra-ui/react";
import { useField } from "formik";
import { NumericFormat } from "react-number-format";
import { FC, LegacyRef, ReactNode, useRef, ChangeEvent, useState } from "react";
import { css } from "@emotion/react";
import { removeThousandSeparator } from "utils";

interface TextInputProps extends InputProps {
 name: string;
 id: string;
 label?: string;
 placeholder?: string;
 description?: string;
 type?: string;
 isDisabled?: boolean;
 topLabel?: boolean;
 isReadOnly?: boolean;
 isRequired?: boolean;
 isLeftElementClickable?: boolean;
 isRightElementClickable?: boolean;
 LeftElement?: ReactNode;
 RightElement?: ReactNode;
 ref?: LegacyRef<HTMLInputElement>;
 value?: number;
 isLoading?: boolean;
 onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
 minW?: string;
 width?: any;
 isNeedExchangeRate?: boolean;
}

const InputNumber: FC<TextInputProps> = ({
 name,
 id,
 label = "",
 placeholder = "",
 description = "",
 bgColor = false,
 type = "text",
 topLabel = false,
 isDisabled = false,
 isReadOnly = false,
 isRequired = false,
 isLeftElementClickable = false,
 isRightElementClickable = false,
 LeftElement = null,
 RightElement = null,
 ref = null,
 value: numberValue = 0,
 minW = "100%",
 width= "",
 isLoading =false,
 onChange = () => {},
 isNeedExchangeRate = false,
 ...props
}) => {
 const [{ value, onBlur }, meta, { setValue }] = useField(name);
 const inputRef = useRef(null);
 const [newValue, setNewValue] = useState<any>()
 useErrorFocus(inputRef, name);
 const changeDectoFive = () => {
  setNewValue(removeThousandSeparator(value).toFixed(5))
 }
 const changeDectoTwo = () => {
  setNewValue(removeThousandSeparator(value).toFixed(2))
 }

 return (
  <FormControl
   id={id}
   isInvalid={Boolean(meta.error && meta.touched)}
   isDisabled={isDisabled}
   isReadOnly={isReadOnly}
   isRequired={isRequired}
  >
   {!topLabel && label && (
    <FormLabel color="GrayText" textTransform="uppercase">
     {label}
    </FormLabel>
   )}
   <Box w={minW}>
    <NumericFormat
     disabled={isDisabled}
     thousandSeparator=","
     allowLeadingZeros={false}
     allowNegative={false}
    //  allowedDecimalSeparators={["%"]}
     placeholder={isLoading ? 'Loading' : ''}
     onChange={(valueString) => {
      if (onChange) {
       onChange(valueString.target.value as any);
      }
      setValue(valueString.target.value);
     }}
     onBlur={changeDectoTwo}
     onClick={changeDectoFive}
     value={isLoading || newValue ? newValue : !isDisabled ? removeThousandSeparator(value)?.toFixed(2) : removeThousandSeparator(value)?.toFixed(5)}
     style={{
      width: width ? width : "100%",
      outline: "2px solid transparent",
      border: "1px solid rgba(216, 216, 217, 1)",
      padding: "1rem",
      borderRadius: "0.375rem",
      height: "2.5rem",
      textAlign: "right",
      minHeight: "51px",
      backgroundColor: bgColor ? "#BBDEFB" : "#E6EAF1",
      opacity: isDisabled ? "0.4" : "1",
      marginTop: "7px",
      cursor: isDisabled ? "not-allowed" : "default",
    //   borderTopLeftRadius: isNeedExchangeRate ? 0 : "unset",
    //   borderBottomLeftRadius: isNeedExchangeRate ? 0 : "unset",
     }}
    />
   </Box>
  </FormControl>
 );
};

export default InputNumber;
