import { IDENTITY_CONFIG, METADATA_OIDC } from "../utils/authConst";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { getStorage, setStorage } from "../utils/storage-util";
import AppConst from "constants/AppConst";

export default class AuthService {
 UserManager;

 constructor() {
  this.UserManager = new UserManager({
   ...IDENTITY_CONFIG,
   userStore: new WebStorageStateStore({ store: window.localStorage }),
   metadata: {
    ...METADATA_OIDC,
   },
  });
  // Logger
  Log.logger = console;
  Log.level = Log.DEBUG;
  this.UserManager.events.addUserLoaded((user) => {
   if (window.location.href.indexOf("signin-oidc") !== -1) {
    setStorage("access_token", user.access_token, 7 * 24 * 60 * 60); //expired 7 days
    this.navigateToScreen();
   }
  });
  this.UserManager.events.addSilentRenewError((e) => {
  });

  this.UserManager.events.addAccessTokenExpired(() => {
   this.signinSilent();
  });
 }

 signinRedirectCallback = () => {
  this.UserManager.signinRedirectCallback().then(function (user) {});
 };

 getUser = async () => {
  const user = await this.UserManager.getUser();
  if (!user) {
   return await this.UserManager.signinRedirectCallback();
  }
  var access_token = getStorage("access_token");
  if (!access_token) {
   setStorage("access_token", user.access_token, 7 * 24 * 60 * 60); //expired 7 days
  }
  return user;
 };

 parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
 };

 signinRedirect = () => {
  localStorage.setItem(
   "redirectUri",
   window.location.pathname + window.location.search
  );
  this.UserManager.signinRedirect({});
 };

 navigateToScreen = () => {
  window.location.replace("/getStarted");
 };

 isAuthenticated = () => {
  const oidcStorage = JSON.parse(
   localStorage.getItem(
    `oidc.user:${AppConst.REACT_APP_AUTH_URL}:${AppConst.REACT_APP_IDENTITY_CLIENT_ID}`
   )
  );

  return !!oidcStorage && !!oidcStorage.access_token;
 };

 signinSilent = () => {
  return this.UserManager.signinSilent()
   .then((user) => {
    setStorage("access_token", user.access_token, 7 * 24 * 60 * 60); //expired 7 days
    return user.access_token;
   })
   .catch((err) => {
    window.location = "/logout";
    throw err;
   });
 };
 signinSilentCallback = () => {
  this.UserManager.signinSilentCallback();
 };

 createSigninRequest = () => {
  return this.UserManager.createSigninRequest();
 };

 logout = () => {
  this.UserManager.signoutRedirect({
   id_token_hint: localStorage.getItem("id_token"),
  });
  this.UserManager.clearStaleState();
 };

 signoutRedirectCallback = () => {
  this.UserManager.signoutRedirectCallback().then(() => {
   localStorage.clear();
   window.location.replace(AppConst.REACT_APP_PUBLIC_URL);
  });
  this.UserManager.clearStaleState();
 };
}
