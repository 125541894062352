import { ReactElement, useMemo, useState, useEffect } from "react";
import { removeThousandSeparator } from "utils";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";
import debounce from "lodash/debounce";
import { Button } from "@chakra-ui/react";
import { actions as pricingCostDriverAction } from "state/pricings/costDrivers/slice";
import { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import OptipediaToast from "utils/notification-util";

const Recalculate = ({
  row,
  costDriver,
 }: any): ReactElement => {
 const dispatch = useAppDispatch();
 const [newRow, setNewRow] = useState<any>(row);
 const [dataSend, setDataSend] = useState<any>(null);
 let customsFields: any = [];
 const {
  loading,
  dataConstDriversOri,
  dataToSendUpdates,
  detailPerVolumeId,
  temporaryDataCostDriver,
  changeRowSimulate,
  customFieldsDatas,
  dataToSendAdds,
  dataToSimulates
 } = useSelector(
  (state: RootState) => ({
   loading: state.pricingCostDrivers.loading,
   dataConstDriversOri: state.pricingCostDrivers.constDriversOri,
   dataToSendUpdates: state.pricingCostDrivers.dataToSend.updates,
   detailPerVolumeId: state.pricingCostDrivers.detailPerVolumeId.result,
   temporaryDataCostDriver: state.pricingCostDrivers.temporaryDataCostDriver,
   changeRowSimulate: state.pricingCostDrivers.tmpChangeRow,
   customFieldsDatas: state.pricingCostDrivers.customFields,
   dataToSendAdds: state.pricingCostDrivers.dataToSend.adds,
   dataToSimulates: state.pricingCostDrivers.dataToSimulate
  }),
  shallowEqual
 );

 useEffect(() => {
  setNewRow(row);
  const findIndexDataToSend = dataToSendAdds.findIndex(
   (dataAdd: any) => dataAdd.simulationCostDriverId === row.id
  );
  const dataCreateToUpdate = {
   index: findIndexDataToSend,
   data: row.customFields,
  };
  if (findIndexDataToSend !== -1) {
   dispatch(pricingCostDriverAction.setChangeAddRowByIndex(dataCreateToUpdate));
  }
 }, [row]);

 useEffect(() => {
  if (row.name === "") {
   return;
  }

  const findLastConstDriver = detailPerVolumeId?.costDrivers.find(
   (item: any) => item.id === row.constDriverId
  );
  const isBaseLevel = detailPerVolumeId?.costDrivers.find(
   (item: any) => item.name === row.name
  );

  const firstName = findLastConstDriver?.costDrivers.find(
   (costDriver: any) => costDriver.id === row?.id
  );
  changeRowSimulate.find((tmp: any, indexRow: number) => {
   const findDataFromCustomFields = row.customFields.find(
    (customField: any) => customField.name === tmp.name
   );

   if (!row.constDriverId) {
    const findSameCustomFieldConfigurationId = isBaseLevel?.customFields?.find(
     (customField: any) => customField?.id === tmp?.customFieldConfigurationId
    );
    if (findSameCustomFieldConfigurationId) {
     dispatch(
      pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
       index: indexRow,
       // component: componentNameChange,
       idRow: firstName?.id,
       // componentName: findLastConstDriver?.name,
       currencyTo: findSameCustomFieldConfigurationId?.currency?.code,
      })
     );
    }
   }

   if (
    tmp?.idRow === findLastConstDriver?.id &&
    tmp?.name === findDataFromCustomFields?.name
   ) {
    const componentNameChange = `${firstName?.name} - ${findDataFromCustomFields?.name}`;
    dispatch(
     pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
      index: indexRow,
      component: componentNameChange,
      idRow: findLastConstDriver?.id,
      componentName: findLastConstDriver?.name,
     })
    );
   }

   const findSameCustomFieldConfigurationId = firstName?.customFields?.find(
    (customField: any) =>
     customField.configuration.id === tmp?.customFieldConfigurationId
   );

   if (
    findSameCustomFieldConfigurationId &&
    tmp?.customFieldConfigurationId ===
     findSameCustomFieldConfigurationId?.configuration?.id &&
    findSameCustomFieldConfigurationId?.costDriver?.id === row?.id
   ) {
    const componentNameChange = `${firstName?.name} - ${findSameCustomFieldConfigurationId?.configuration?.name}Code`;
    dispatch(
     pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
      index: indexRow,
      // component: componentNameChange,
      idRow: firstName?.id,
      // componentName: findLastConstDriver?.name,
      currencyTo: findSameCustomFieldConfigurationId?.currency?.code,
     })
    );
   }
   if (
    tmp?.idRow === row?.id &&
    tmp.name === row?.masterDataConfiguration?.configuration?.code
   ) {
    const componentNameChange = `${firstName?.name} - ${firstName?.configuration?.labelName}`;
    const findSameCustomFieldConfigurationId = firstName?.customFields?.find(
     (customField: any) => customField?.id === tmp?.customFieldConfigurationId
    );
    dispatch(
     pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
      index: indexRow,
      component: componentNameChange,
      idRow: findLastConstDriver?.id,
      componentName: findLastConstDriver?.name,
      currencyTo: findSameCustomFieldConfigurationId?.currency?.code,
      before: findLastConstDriver?.unitCost,
      name: findLastConstDriver?.configuration?.labelName,
     })
    );
   }
  });
 }, [detailPerVolumeId]);
 
 useEffect(() => {
  setNewRow(row);
 }, [row]);

 useEffect(() => {
  if (dataSend) {
   const timer = setTimeout(async () => {
    await dispatch(pricingCostDriverAction.setDataToSend(dataSend));
    dispatch(pricingCostDriverAction.setIsEditOpen(false));
    dispatch(pricingCostDriverAction.setNullCustomFields());
   }, 100);

   return () => clearTimeout(timer);
  }
 }, [dataSend]);

 useEffect(() => {
  const ids = customFieldsDatas.map((o: any) => o.id);
  const filtered = customFieldsDatas.filter(
   ({ id }: any, index: number) => !ids.includes(id, index + 1)
  );
 }, [customFieldsDatas]);
 const runSimulate = useMemo(
  () =>
   debounce((dataToUpdate) => {
    const newCustomFieldDataToUpdate: any = [];
    dataToUpdate?.customFields?.map((customField: any) => {
     if (!customField.isMaintenanceByMasterData) {
      newCustomFieldDataToUpdate.push(customField);
     }
    });
    const newDataToUpdate = {
     ...dataToUpdate,
     customFields: newCustomFieldDataToUpdate,
    };
    dispatch(pricingCostDriverAction.resetDataSimulate([]))
    dispatch(
     pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(
      newDataToUpdate
     )
    );
   }, 600),
  [dispatch, dataToSendUpdates]
 );


 const debouncedRunSimulate = useMemo(
  () =>
   debounce(async (dataOnChange: any) => {
    dataOnChange.map((el:any) => {
    const {
      name,
      value,
      currentRow1,
      tableName,
      dataFromSimulate,
      unitCostLabel,
     } = el;
     let isNewCostDriver: any;
     if (dataFromSimulate || currentRow1) {
      isNewCostDriver = dataFromSimulate?.isNew ? dataFromSimulate : currentRow1;
     } else {
      isNewCostDriver = row;
     }
     const newCustomField: any = [];
     let itemToObject = Object.assign({}, isNewCostDriver);
     const originalNewMap = new Map(Object.entries(itemToObject));
     const findCost = changeRowSimulate.find(
      (item: any) =>
       item.name === unitCostLabel &&
       item.componentName === dataFromSimulate?.name &&
       item.idRow === dataFromSimulate.id
     );
     if (
      (row.isNew && 0 === value) ||
      removeThousandSeparator(originalNewMap.get(name) as string) === value
     ) {
      const tmpChangeToDeletes = {
       component: `${originalNewMap.get("name")} - ${
        name === "unitCost" ? unitCostLabel : name
       }`,
       name: name,
       constDriverId: originalNewMap.get("constDriverId"),
      };
      dispatch(
       pricingCostDriverAction.removeOnetmpChangeRow({ tmpChangeToDeletes })
      );
 
      let indexCustomField = null;
      const customFields: any = [];
      dataFromSimulate?.customFields?.map((item: any, index: number) => {
       if (item.name === name) {
        indexCustomField = index;
        customFields.push({
         ...item,
         value,
        });
       } else {
        customFields.push(item);
       }
      });
      const dataToUpdate = {
       costAnalysisPerVolumeId: detailPerVolumeId?.id,
       costDriverId: isNewCostDriver.id,
       parentCostDriverId: isNewCostDriver.constDriverId,
       name: isNewCostDriver.name,
       masterDataId: isNewCostDriver.masterDataId,
       description: isNewCostDriver.description,
       unitCost:
        name === "unitCost"
         ? value
         : findCost?.before ?? itemToObject?.unitCost,
       customFields: customFields,
       currencyId: isNewCostDriver.currencyId,
      };
      runSimulate(dataToUpdate);
     } else {
      let indexCustomField = null;
      let isCustomFieldAllHaveValue = true;
      newCustomField.length > 0 &&
       newCustomField.map((item: any) => {
        if (item.masterData !== null && item.value === "") {
         isCustomFieldAllHaveValue = false;
        }
       });
      const customFields: any = [];
      isNewCostDriver?.customFields.map(
       (item: any, indexCustomFieldNumber: number) => {
        if (item.name === name) {
         indexCustomField = indexCustomFieldNumber;
         if (changeRowSimulate.length > 0) {
          for (let i = 0; i < changeRowSimulate.length; i++) {
           if (
            item.customFieldConfigurationId ===
             changeRowSimulate[i]?.customFieldConfigurationId &&
            item.costDriverId === changeRowSimulate[i]?.idRow &&
            item.name === changeRowSimulate[i]?.name
           ) {
            customFields.push({
             ...item,
             value,
            });
           } else {
            if (
             item?.name === name &&
             item?.costDriverName === currentRow1?.name
            ) {
             customFields.push({
              ...item,
              value,
             });
            } else {
             const sameValue = changeRowSimulate.find(
              (data: any) =>
               data?.idRow === item?.costDriverId &&
               data?.customFieldConfigurationId ===
                item?.customFieldConfigurationId &&
               item?.name === data.name
             );
             if (sameValue) {
              customFields.push({
               ...item,
               value: sameValue?.before,
              });
             } else {
              customFields.push(item);
             }
            }
           }
          }
         } else {
          customFields.push({
           ...item,
           value,
          });
         }
        } else {
         for (let i = 0; i < changeRowSimulate.length; i++) {
          if (
           item?.customFieldConfigurationId ===
            changeRowSimulate[i]?.customFieldConfigurationId &&
           item?.costDriverName === changeRowSimulate[i]?.componentName &&
           item?.name === changeRowSimulate[i]?.name
          ) {
           customFields.push({
            ...item,
            value: changeRowSimulate[i]?.before,
           });
          } else {
           if (item?.name === name && item?.costDriverName === row?.name) {
            customFields.push({
             ...item,
             value,
            });
           } else {
            const sameValue = changeRowSimulate.find(
             (data: any) =>
              data?.idRow === item?.costDriverId &&
              data?.customFieldConfigurationId ===
               item?.customFieldConfigurationId &&
              item?.name === data?.name
            );
            if (sameValue) {
             customFields.push({
              ...item,
              value: sameValue?.before,
             });
            } else {
             customFields.push(item);
            }
           }
          }
         }
        }
       }
      );
 
      const customFieldsFromSimulate: any = [];
      row?.customFields.map((customField: any) => {
       if (customField?.name === name) {
        customFieldsFromSimulate.push({
         ...customField,
         value,
        });
       } else {
        if (row[customField?.name] || !customField?.isRequired) {
         if (
          name === `${customField?.name}-currencyId` &&
          customField?.typeData === 50
         ) {
          customFieldsFromSimulate.push({
           ...customField,
           currencyId: value,
          });
         } else {
          customFieldsFromSimulate.push({
           ...customField,
           value: row[customField.name],
          });
         }
        }
       }
      });
      const dataSendEdit = customFieldsFromSimulate?.map((o: any) => o.name);
      const filteredCustomFields = customFieldsFromSimulate?.filter(
       ({ name, isMaintenanceByMasterData }: any, index: number) =>
        !isMaintenanceByMasterData && !dataSendEdit.includes(name, index + 1)
      );
 
      const dataToUpdate = {
       costAnalysisPerVolumeId: detailPerVolumeId?.id,
       costDriverId: isNewCostDriver
        ? isNewCostDriver?.id
        : dataFromSimulate?.id,
       parentCostDriverId: isNewCostDriver
        ? isNewCostDriver?.constDriverId
        : dataFromSimulate?.constDriverId,
       name: row.masterDataId
        ? dataFromSimulate?.name
        : name === "name"
        ? value
        : dataFromSimulate?.name,
       description: row.masterDataId
        ? dataFromSimulate?.description
        : name === "description"
        ? value
        : dataFromSimulate?.description,
       masterDataId: dataFromSimulate.masterDataConfiguration?.id,
       unitCost: name === "unitCost" ? value : dataFromSimulate?.unitCost,
       customFields: filteredCustomFields,
       remove: row?.isNew && 0 === value,
       currencyId: dataFromSimulate.currencyId,
      };
      runSimulate(dataToUpdate);
      let itemNewOriginal;
      if (isNewCostDriver) {
       itemNewOriginal = new Map(Object.entries(isNewCostDriver));
       let after = isNewCostDriver?.customFields.find(
        (item: any) => item?.name === name
       );
 
       if (
        (after && removeThousandSeparator(after?.value) !== value) ||
        (name === "unitCost" &&
         removeThousandSeparator(isNewCostDriver?.unitCost) !== value)
       ) {
        const findCust = customFields?.find(
         (item: any) =>
          item?.name === name && item?.costDriverId === isNewCostDriver?.id
        );
        const findCustFromSimulate = dataFromSimulate?.customFields?.find(
         (item: any) => name === item?.realName
        );
        if (findCustFromSimulate?.typeData !== 10) {
         dispatch(
          pricingCostDriverAction.settmpChangeRow({
           id: row?.id,
           costDriver: tableName,
           currencyFrom:
            findCustFromSimulate?.typeData === 50
             ? findCustFromSimulate?.currencyCode
             : name === "unitCost"
             ? dataFromSimulate?.currency
             : "-",
           currencyTo:
            findCustFromSimulate?.typeData === 50
             ? findCustFromSimulate?.currencyCode
             : name === "unitCost"
             ? dataFromSimulate?.currency
             : "-",
           component: `${dataFromSimulate.name} - ${
            name === "unitCost" ? unitCostLabel : name
           }`,
           before: value,
           after: isNewCostDriver.isNew
            ? findCustFromSimulate.value || 0
            : findCustFromSimulate
            ? findCustFromSimulate.value
            : itemNewOriginal.get("unitCost"),
           idRow: findCust ? findCust?.costDriverId : row?.id,
           customFieldConfigurationId: isNewCostDriver.constDriverId
            ? after?.customFieldConfigurationId
            : after?.customFieldId,
           typeData: after?.typeData,
           parentCostDriverId: findCust
            ? findCust?.id
            : costDriver?.costDriverId,
           componentName: originalNewMap.get("name"),
           name: name === "unitCost" ? unitCostLabel : name,
          })
         );
        }
       }
      } else {
       itemNewOriginal = new Map(Object.entries(dataFromSimulate));
       let after = dataFromSimulate?.customFields?.find(
        (item: any) => item?.name === name
       );
 
       if (
        (after && removeThousandSeparator(after.value) !== value) ||
        (name === "unitCost" &&
         removeThousandSeparator(dataFromSimulate?.unitCost) !== value)
       ) {
        const findCust = customFields.find(
         (item: any) =>
          item?.name === name && item?.costDriverId === dataFromSimulate?.id
        );
        dispatch(
         pricingCostDriverAction.settmpChangeRow({
          id: name,
          costDriver: tableName,
          currencyFrom: row.currency,
          currencyTo: row.currency,
          component: `${originalNewMap.get("name")} - ${
           name === "unitCost" ? unitCostLabel : name
          }`,
          before: value,
          after: dataFromSimulate?.isNew
           ? 0
           : after
           ? after.value
           : itemNewOriginal.get("unitCost"),
          idRow: row.id,
          customFieldConfigurationId: findCust?.customFieldConfigurationId,
          typeData: after?.typeData,
          parentCostDriverId: costDriver?.costDriverId,
          componentName: originalNewMap.get("name"),
          name: name === "unitCost" ? unitCostLabel : name,
         })
        );
       }
      }
     }
    
    })
   }, 800),
  [dataToSendUpdates, row]
 );

 useEffect(() => {
  return () => {
   debouncedRunSimulate.cancel();
  };
 }, []);


 return (
  <Button
   variant={"outline"}
   isDisabled={
    loading ||
    (newRow?.isNew && isEmpty(newRow.name)) ||
    (newRow?.isNew && isEmpty(newRow.description)) 
    // ||  newRow.customFields.length > 0 && isEmpty(newRow.customFields[findIndexCustomField()]?.value)
   }
   ml={3}
   onClick={() => {
    debouncedRunSimulate(dataToSimulates);
   }}
  >
   Recalculate
  </Button>
 );
};
export default Recalculate;
