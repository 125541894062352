import { useEffect, useState } from "react";
import {
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalFooter,
 ModalBody,
 ModalCloseButton,
 useDisclosure,
 Flex,
 Text,
 Spinner,
 Spacer,
} from "@chakra-ui/react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { Button, Box } from "@chakra-ui/react";
import { actions as annualSpendAction } from "state/annualSpend/slice";
import { actions as pricingCostSummaryAction } from "state/costAnalysis/slice";
import { actions as approvalAction } from "state/approval/slice";
import MultiSelectCheckBox from "components/MultiSelectCheckBox";
import { isEmpty, orderBy } from "lodash";

const ModalGenerateReport = () => {
 const [dataToSend, setDataToSend] = useState([]);
 const [statusToSend, setStatusToSend] = useState([]);
 const [dataJourney, setDataJourney] = useState([]);
 const [dataStatus, setDataStatus] = useState([]);
 const { isOpen, onOpen, onClose } = useDisclosure();
 const dispatch = useAppDispatch();

 const { journeysData, loading, approvalStatus } = useSelector(
  (state: RootState) => ({
   journeysData: state.annualSpendSimulations.journey,
   loading: state.costAnalysis.loading,
   approvalStatus: state.approval.approvalStatus.result,
  }),
  shallowEqual
 );

 let dataOptions = [] as any;
 let dataOptionStatus = [] as any;

 useEffect(() => {
  window.scrollTo(0, 0);
  dispatch(annualSpendAction.journey()).then((response) =>
   setDataJourney(response.payload)
  );
  dispatch(approvalAction.getApprovalStatus()).then((response: any) =>
   setDataStatus(response && response?.payload?.result)
  );
 }, [isOpen]);

 useEffect(() => {
  if (dataJourney.length > 0) {
   dataOptions.push({
    id: "00000000-0000-0000-0000-000000000000",
    value: "All",
    label: "All Journey",
   });
   journeysData.map((journey: any) => {
    dataOptions.push({
     id: journey.id,
     value: journey.journeyCode,
     label: journey.journeyCode + " - " + journey.journeyName,
    });
   });
  }

  if (dataStatus.length > 0) {
   orderBy(approvalStatus, ["description"], ["asc"])?.map((item: any) => {
    dataOptionStatus.push({
     label: item.description,
     value: item.description,
    });
   });
  }
 }, [dataOptions, dataOptionStatus]);

 const handleClose = () => {
  setDataToSend([]);
  setStatusToSend([]);
  onClose();
 };

 const handleChange = (values: any) => {
  var payload = [] as any;
  let checkSelectAll = values.find((option: any) => option.value === "All");
  if (checkSelectAll) {
   values.splice(0, values.length, {
    id: "00000000-0000-0000-0000-000000000000",
    value: "All",
    label: "All Journey",
   });
   dataOptions = [];
   setDataJourney([]);
  } else {
   // asdfsad
   dispatch(annualSpendAction.journey()).then((response) =>
    setDataJourney(response.payload)
   );
  }
  if (values.length === dataJourney.length) {
   values.map((value: any) => {
    payload.push({ id: value.id });
   });
  } else {
   values.map((value: any) => {
    payload.push({ id: value.id });
   });
  }

  setDataToSend(payload);
 };
 const handleChangeStatus = (values: any) => {
  var payload = [] as any;
  let cekStatus = values?.find((item: any) => item.value === "All Status");
  if (cekStatus) {
   dataOptionStatus = [];
   setDataStatus([]);
  } else {
   dispatch(approvalAction.getApprovalStatus()).then((response: any) =>
    setDataStatus(response && response?.payload?.result)
   );
  }

  if (cekStatus || values.length === 4) {
   values.splice(0, values.length, {
    label: "All Status",
    value: "All Status",
   });
   values.map((value: any) => {
    payload.push({ value: value.value });
   });
  } else {
   values.map((value: any) => {
    payload.push({ value: value.value });
   });
  }

  setStatusToSend(payload);
 };

 const handleGenerate = () => {
  const payload = {
   JourneyId: dataToSend?.map((item: any) => item.id),
   ApprovalStatus: statusToSend?.map((item: any) => item.value),
  };
  dispatch(pricingCostSummaryAction.generateReport(payload));
 };

 return (
  <Box>
   <Button variant="outline" onClick={onOpen}>
    Generate Report
   </Button>

   <Modal
    closeOnOverlayClick={false}
    isOpen={isOpen}
    onClose={handleClose}
    isCentered
    size="md"
   >
    <ModalOverlay />
    <ModalContent>
     <ModalHeader fontWeight={600} fontSize="20px" color="rgba(26, 26, 26, 1)">
      Report Generation
     </ModalHeader>
     <ModalCloseButton
      position="absolute"
      top="-8"
      right={-8}
      borderRadius="100%"
      width="30px"
      height="30px"
      backgroundColor="rgba(26, 26, 26, 0.76)"
      color="white"
     />
     <ModalBody>
      <Box>
       <Box mb="2">
        <Box
         as="p"
         fontSize="16"
         textColor="rgba(26, 26, 26, 0.56)"
         pt="0.3rem"
         mb="1"
         fontWeight={400}
        >
         Journey <span style={{ color: "red" }}>*</span>
        </Box>
        <MultiSelectCheckBox data={dataOptions} onChange={handleChange} />
       </Box>
       <Box mb="2">
        <Box
         as="p"
         fontSize="16"
         textColor="rgba(26, 26, 26, 0.56)"
         pt="0.3rem"
         mb="1"
         fontWeight={400}
        >
         Status <span style={{ color: "red" }}>*</span>
        </Box>
        <MultiSelectCheckBox
         data={dataOptionStatus}
         onChange={handleChangeStatus}
        />
       </Box>
      </Box>
     </ModalBody>
     <ModalFooter>
      {loading && (
       <Box>
        <Flex>
         <Flex alignItems="center" gap="2" ml="20rem">
          <Spinner
           thickness="4px"
           speed="0.65s"
           emptyColor="gray.200"
           color="blue.500"
           size="sm"
          />
          <Text mr="240">Generating…</Text>
         </Flex>
         <Spacer />
         <Box></Box>
        </Flex>
       </Box>
      )}
      {!loading && (
       <Button
        variant="base"
        width="full"
        isDisabled={isEmpty(dataToSend) || isEmpty(statusToSend)}
        onClick={() => handleGenerate()}
       >
        Generate
       </Button>
      )}
     </ModalFooter>
    </ModalContent>
   </Modal>
  </Box>
 );
};

export default ModalGenerateReport;
