import { FC, ReactNode, LegacyRef, useRef, ChangeEvent } from "react";
import {
 FormControl,
 FormLabel,
 FormErrorMessage,
 Input,
 FormHelperText,
 InputGroup,
 InputLeftElement,
 InputRightElement,
 InputProps,
 Stack,
 NumberInput,
 NumberInputField,
} from "@chakra-ui/react";
import { useField, Field } from "formik";
import { useErrorFocus } from "hooks";

interface TextInputProps extends InputProps {
 name: string;
 id: string;
 fieldName?: string;
 dataId?: string;
 label?: any;
 placeholder?: string;
 description?: string;
 type?: string;
 isDisabled?: boolean;
 isReadOnly?: boolean;
 isRequired?: boolean;
 topLabel?: boolean;
 isLeftElementClickable?: boolean;
 isRightElementClickable?: boolean;
 LeftElement?: ReactNode;
 RightElement?: ReactNode;
 ref?: LegacyRef<HTMLInputElement>;
 minW?: string;
 isLoading?: boolean;
 onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
 isDuplicate?: boolean;
}

const TextInput: FC<TextInputProps> = ({
 name,
 id,
 fieldName = "",
 dataId = "",
 label = "",
 placeholder = "",
 description = "",
 type = "text",
 isDisabled = false,
 isReadOnly = false,
 isRequired = false,
 topLabel = false,
 isLeftElementClickable = false,
 isRightElementClickable = false,
 LeftElement = null,
 RightElement = null,
 ref = null,
 minW = "",
 isLoading = false,
 onChange = () => {},
 isDuplicate,
 ...props
}) => {
 const [field, meta, { setValue, setTouched }] = useField(name);
 const inputRef = useRef(null);
 //  useErrorFocus(inputRef, name);
 const handleBlur = (e: any) => {
  field.onBlur(e);
 };

 return (
  <FormControl
   id={id}
   isInvalid={Boolean(meta.error && meta.touched)}
   isDisabled={isDisabled}
   isReadOnly={isReadOnly}
   isRequired={isRequired}
  >
   {topLabel && label && (
    <FormLabel
     pb="10px"
     alignSelf="center"
     margin="0"
     minW="40%"
     fontWeight="bold"
     textTransform="capitalize"
     opacity="1!important"
    >
     {label}
    </FormLabel>
   )}
   <Stack spacing={4} direction="row">
    {!topLabel && label && (
     <FormLabel
      alignSelf="center"
      margin="0"
      minW="40%"
      fontWeight="bold"
      textTransform="capitalize"
      opacity="1!important"
     >
      {label}
     </FormLabel>
    )}
    <InputGroup>
     {LeftElement && (
      <InputLeftElement
       pointerEvents={isLeftElementClickable ? undefined : "none"}
       color="gray.300"
       fontSize="1.2em"
      >
       {LeftElement}
      </InputLeftElement>
     )}
     {type === "number" ? (
      <>
       <NumberInput
        name={name}
        defaultValue={field.value}
        value={field.value}
        onBlur={(e) => {
         setValue(e.target.ariaValueNow);
        }}
       >
        <NumberInputField
         mt={2}
         h="51px"
         border="1px solid"
         borderColor={"rgba(216, 216, 217, 1)"}
         placeholder={isLoading? "Loading": placeholder}
        //  bgColor="rgba(216, 216, 217, 0.36)"
         bgColor="white"
         borderRadius="5px"
         minW={minW}
         ref={inputRef}
         onChange={(e) => {
          if (onChange) {
           onChange(e);
          }
          setValue(e.target.value);
          setTouched(true, true);
         }}
        />
       </NumberInput>
      </>
     ) : (
      <Input
       mt={2}
       h="51px"
       onChange={(e) => {
        if (onChange) {
         onChange(e);
        }
        setValue(e.target.value);
        setTouched(true, true);
       }}
       //   {...field}
       isInvalid={isDuplicate}
       value={field.value}
       data-fieldname={fieldName}
       data-dataId={dataId}
       placeholder={isLoading? "Loading": placeholder}
       //   bgColor="#E6EAF1"
       border="1px solid"
       borderColor={"rgba(216, 216, 217, 1)"}
    //    bgColor="rgba(216, 216, 217, 0.36)"
       bgColor="white"
       borderRadius="5px"
       //   minH="60px"
       onKeyUp={(e) => handleBlur(e)}
       minW={minW}
       ref={inputRef}
       {...props}
      />
     )}
     {RightElement && (
      <InputRightElement
       pointerEvents={isRightElementClickable ? undefined : "none"}
       color="gray.300"
       fontSize="1.2em"
      >
       {RightElement}
      </InputRightElement>
     )}
    </InputGroup>
   </Stack>

   {description && <FormHelperText>{description}</FormHelperText>}
   <Stack spacing={4} direction="column-reverse" align={"start"}>
    {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
   </Stack>
  </FormControl>
 );
};

export default TextInput;
