declare global {
  interface StringConstructor {
    toFormatSeparator(obj:string | number, minimumFractionDigits?: number, maximumFractionDigits?: number): string;
  }

  interface NumberConstructor {
    isNumber(obj:any): boolean;
  }
}

String.toFormatSeparator = function (obj:string | number, minimumFractionDigits:number = 0, maximumFractionDigits: number = 5): string  
{
  const num = Number(obj);
  return num.toLocaleString(undefined, { minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits });
};

Number.isNumber = function(obj:any): boolean
{
  const num = Number(obj);
  if(Number.isInteger(num))
    return true;
  return false;
}

export {}
