import { RootState } from "state/store";
import { FC, useEffect, useState, createRef } from "react";
import {
 Box,
 Grid,
 GridItem,
 Text,
 Button,
 Stack,
 Checkbox,
} from "@chakra-ui/react";
import { TextLoading } from "components/Loading";
import { useSelector, shallowEqual } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { actions as approvalAction } from "state/approval/slice";
import { actions as tenantConfActions } from "state/settings/slice";
import ToolbarBottom from "views/AnnualSpendImpact/ToolbarBotton";
import Table from "components/Table/Table";
import { orderBy } from "lodash";

const Pricings: FC = () => {
 const router = useHistory();
 const dispatch = useAppDispatch();
 const maxResultCount = 100;

 let { loading, searchQuery, approvalListData, pricings, thirdPartySelection, nonThirdPartySelection, isEnableThirdPartyStatus, comparison } = useSelector(
  (state: RootState) => ({
   loading: state.approval.loading,
   thirdParties: state.approval.thirdParties.result,
   thirdPartySelection: state.approval.thirdPartySelection,
   nonThirdPartySelection: state.approval.nonThirdPartySelection,
   searchQuery: state.approval.searchQuery,
   approvalListData: state.approval.pendingList.result.items,
   pricings: state.approval.pricings,
   comparison: state.approval.comparison,
   isEnableThirdPartyStatus: state.thirdPartySetting.isEnableThirdPartyStatus,
  }),
  shallowEqual
 );

 useEffect(() => {
  if(!comparison){
     router.push("/approval/annual-spend-impact")
    }
}, [])

 useEffect(() => {
  const fetchList = () => {
   const payload = {
    skipCount: 0,
    maxResultCount: maxResultCount,
    searchKey: searchQuery,
    thirdParties: thirdPartySelection.concat(nonThirdPartySelection)
   };

   dispatch(approvalAction.pendingApprovalList(payload));
  };
  fetchList();
 }, [searchQuery]);
 let dataTable: any = [];
 approvalListData?.length > 0 &&
  approvalListData.map((item: any) => {
   dataTable.push({
    volumeId: item?.id,
    id: item?.costAnalysis?.id,
    name: item?.costAnalysis?.name,
    description: item?.costAnalysis?.description,
    thirdPartyName: item?.thirdParty?.description,
    volume: item?.volume,
    status: item?.status,
    journeyName: item?.costAnalysis?.configuration?.journeyName,
   });
  });
const journeySelected : any = []
  if(pricings.length > 0){
   const newData = dataTable.filter((item:any) => pricings.includes(item.volumeId))
   newData.map((item:any) => {
      journeySelected.push(item.journeyName)
   })
   const data = dataTable.filter((item:any) => journeySelected.includes(item.journeyName))
   
   dataTable = data.slice(0);
   // data.map((item:any) => {
   //    dataTable.push(item)
   // })
  }

 const COLUMN_HEADERS_WITH = [
  { name: "name", label: "Product ID" },
  { name: "description", label: "Product Description" },
  { name: "thirdPartyName", label: "Third-Party" },
  { name: "volume", label: "Volume" },
  { name: "journeyName", label: "Journey" },
 ];

 const COLUMN_HEADERS_WITHOUT = [
    { name: "name", label: "Product ID" },
    { name: "description", label: "Product Description" },
    { name: "volume", label: "Volume" },
    { name: "journeyName", label: "Journey" },
   ];

 const setCheckBox = (isChecked: boolean, value: string) => {
  if (isChecked) {
   dispatch(approvalAction.setPricingSelection(value));
  } else {
   dispatch(approvalAction.removePricingSelection(value));
  }
 };
 return (
  <Box my={5}>
   {!loading && (
    <Box w="100%">
     {dataTable.length > 0 ? <Box w="100%" mt="2rem" mb="2rem">
      <Table
      loading={loading}
      columnHeaders={isEnableThirdPartyStatus ? COLUMN_HEADERS_WITH : COLUMN_HEADERS_WITHOUT}
      data={orderBy(dataTable, "name", "asc")}
      checkBoxSelect={(pricingId: string, rowData: any) => (
       <Checkbox
        isChecked={pricings.includes(rowData.volumeId)}
        value={rowData.volumeId}
        onChange={(val) => {
         setCheckBox(val.target.checked, val.currentTarget.value);
        }}
       />
      )}
     />
     </Box> : <Box mt={20}><Text textAlign={"center"}>No Pricings available to be shown</Text></Box>}
    </Box>
   )}
   <ToolbarBottom />
  </Box>
 );
};

export default Pricings;
