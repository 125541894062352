import { FC, useRef, useState, useEffect } from "react";
import {
 Wrap,
 Box,
 Button,
 SimpleGrid,
 VStack,
 Flex,
 Spacer,
 Stack,
 Image,
 Badge,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks";

import { actions as myJourneyActions } from "state/myJourney/slice";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import CaretDown from "assets/images/CaretDown.png";
import CaretUp from "assets/images/CaretUp.png";
import Line1 from "assets/images/line1.png";
import Futurej from "assets/images/futurej.png";
import MapPin from "assets/images/MapPin.png";
import OptipediaToast from "utils/notification-util";

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

const TargetDiscoveryStep3: FC = () => {
 let { level, levelName, whatInformationDoYouHave, whatInformationCanYouGain } =
  useSelector(
   (state: RootState) => ({
    level: state.myJourney.levelStep1,
    levelName: state.myJourney.levelName,
    whatInformationDoYouHave: state.myJourney.whatInformationDoYouHave,
    whatInformationCanYouGain: state.myJourney.whatInformationCanYouGain,
   }),
   shallowEqual
  );
 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);
 const dispatch = useAppDispatch();
 const router = useHistory();
 const [isOpenView, setOpenView] = useState("");

 const doesCost = useRef(null);
 const executeScrollDoesCost = () => {
  setOpenView("doesCost");
  scrollToRef(doesCost);
 };

 const baseLevel = useRef(null);
 const executeScrollBaseLevel = () => {
  setOpenView("baseLevel");
  scrollToRef(baseLevel);
 };

 const level1 = useRef(null);
 const executeScrollLevel1 = () => {
  setOpenView("level1");
  scrollToRef(level1);
 };

 const level2 = useRef(null);
 const executeScrollLevel2 = () => {
  setOpenView("level2");
  scrollToRef(level2);
 };

 const level3 = useRef(null);
 const executeScrollLevel3 = () => {
  setOpenView("level3");
  scrollToRef(level3);
 };

 const level4 = useRef(null);
 const executeScrollLevel4 = () => {
  setOpenView("level4");
  scrollToRef(level4);
 };

 const saveLevel = (value: string) => {
  dispatch(myJourneyActions.setLevelName(value));
  router.push("/journeys/create?page=name-journey");
 };

 let MapPinPosisition = "5.3rem";

 switch (level) {
  case "Does Cost":
   MapPinPosisition = "53rem";
   break;

  case "Base Level":
   MapPinPosisition = "25.4rem";
   break;

  case "Level 1":
   MapPinPosisition = "2rem";
   break;

  case "Level 2":
   MapPinPosisition = "29.4rem";
   break;

  case "Level 3":
   MapPinPosisition = "57rem";
   break;

  case "Level 4":
   MapPinPosisition = "84rem";
   break;

  default:
   MapPinPosisition = "5.3rem";
   break;
 }

 return (
  <>
   <VStack align="flex-start" pt="2rem" pb="1rem">
    <Box display="flex" alignItems="flex-start">
     <Box
      w="40%"
      border="1px solid #D7DBE2"
      borderRadius="40px"
      p="3rem"
      pt="4rem"
      mr="4rem"
      minH="249px"
     >
      <Box textAlign="center" as="p" pb="1rem" fontWeight="600" fontSize="24px">
       {level}
      </Box>
      <Box as="p" color="#00050D" fontWeight={500} fontSize="16px">
       The Target indicates that your goal is to reach this level in your Should
       Cost journey.
      </Box>
     </Box>
     <Box
      w="60%"
      color="#00050D"
      fontWeight={500}
      border="1px solid #D7DBE2"
      borderRadius="40px"
      p="2rem"
      pl="4rem"
      pr="4rem"
      minH="216px"
     >
      <Box
       as="p"
       pb="1rem"
       fontWeight="bold"
       fontSize="36px"
       color="#0072CF"
       textAlign="center"
      >
       Level 2
      </Box>
      <Box as="p" pb="1rem" textAlign="center" fontSize="16px">
       The Discovery stage indicates that you are at this level along that
       journey.
      </Box>
      <Box as="p" fontSize="16px">
       InnoArk team is looking forward to discuss your specific needs and to
       support you in scoping and configuring Optipedia, and then to map the
       next stages of your journey in order to reach your target.
      </Box>
     </Box>
    </Box>
   </VStack>
   <Wrap justify="center" mb="-3rem" mt="3rem">
    <Box ml="-16rem!important">
     <Image
      src={MapPin}
      w="25px!important"
      alt="MapPin"
      mr={
       level === "Level 2" ||
       level === "Level 1" ||
       level === "Level 3" ||
       level === "Level 4"
        ? 0
        : MapPinPosisition
      }
      ml={
       level === "Level 2" ||
       level === "Level 1" ||
       level === "Level 3" ||
       level === "Level 4"
        ? MapPinPosisition
        : 0
      }
     />
    </Box>
   </Wrap>
   <Wrap justify="center" mb="-4.5rem">
    <Box ml="-14rem!important">
     <Image src={Line1} w="440px" alt="Line1" />
    </Box>
   </Wrap>
   <Box>
    <Wrap justify="center" pt="4rem" mb="5rem">
     <Box
      bg="#D7DBE2"
      h="100px"
      w="140px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
      p="1.3rem"
      textAlign="center"
      pt="2.3rem"
      cursor="pointer"
      onClick={executeScrollDoesCost}
     >
      <Box as="p" fontWeight="bold">
       Does Cost
      </Box>
     </Box>
     <Box textAlign="center" pt="3rem" zIndex={-10}>
      <Box
       as="p"
       w="63px"
       fontWeight="bold"
       h="1"
       borderTop="5px solid #8DC7F7"
      ></Box>
     </Box>
     <Box
      bg="#8DC7F7"
      h="100px"
      w="140px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
      p="1.3rem"
      textAlign="center"
      pt="2.3rem"
      cursor="pointer"
      onClick={executeScrollBaseLevel}
     >
      <Box as="p" fontWeight="bold" color="white">
       Base Level
      </Box>
     </Box>
     <Box textAlign="center" pt="3rem" zIndex={-10}>
      <Box
       as="p"
       w="63px"
       fontWeight="bold"
       h="1"
       borderTop="5px solid #8DC7F7"
      ></Box>
     </Box>
     <Box
      bg="#8DC7F7"
      h="100px"
      w="140px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
      p="1.3rem"
      textAlign="center"
      pt="2.3rem"
      cursor="pointer"
      onClick={executeScrollLevel1}
     >
      <Box as="p" fontWeight="bold" color="white">
       Level 1
      </Box>
     </Box>
     <Box textAlign="center" pt="3rem" zIndex={-10}>
      <Box
       as="p"
       w="63px"
       fontWeight="bold"
       h="1"
       borderTop="5px solid #0072CF"
      ></Box>
     </Box>
     <Box
      bg="#0072CF"
      h="100px"
      w="140px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
      p="1.3rem"
      textAlign="center"
      pt="2.3rem"
      cursor="pointer"
      onClick={executeScrollLevel2}
     >
      <Box as="p" fontWeight="bold" color="white">
       Level 2
      </Box>
     </Box>
     <Box textAlign="center" pt="3rem" zIndex={-10}>
      <Box
       as="p"
       w="63px"
       fontWeight="bold"
       h="1"
       borderTop="5px solid #AEEAE6"
      ></Box>
     </Box>
     <Box
      bg="#AEEAE6"
      h="100px"
      w="140px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
      p="1.3rem"
      textAlign="center"
      pt="2.3rem"
      cursor="pointer"
      onClick={executeScrollLevel3}
     >
      <Box as="p" fontWeight="bold">
       Level 3
      </Box>
     </Box>
     <Box textAlign="center" pt="3rem" zIndex={-10}>
      <Box
       as="p"
       w="63px"
       fontWeight="bold"
       h="1"
       borderTop="5px solid #00CFC5"
      ></Box>
     </Box>
     <Box
      bg="#00CFC5"
      h="100px"
      w="145px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
      p="1.3rem"
      textAlign="center"
      pt="2.3rem"
      cursor="pointer"
      onClick={executeScrollLevel4}
     >
      <Box as="p" fontWeight="bold" color="white">
       Level 4
      </Box>
     </Box>
    </Wrap>
    <Wrap justify="center" marginBottom={10} marginTop="-5.5rem">
     <Box ml="55rem!important">
      <Image src={Futurej} alt="Futurej" />
     </Box>
    </Wrap>
   </Box>
   <Box
    w="100%"
    mt="3rem"
    border="1px solid #D7DBE2"
    borderRadius="17px"
    ref={doesCost}
   >
    <Flex w="100%" p="1rem">
     <Stack direction="row" spacing={4} align="center">
      <Box pl={4} fontWeight="bold" fontSize="36px" w="260px">
       Does Cost
      </Box>
      <Button
       onClick={() => saveLevel("DoesCost")}
      >
       Select level
      </Button>
     </Stack>
     <Spacer />
     <Stack direction="row" spacing={4} align="center">
      <Image
       src={isOpenView === "doesCost" ? CaretUp : CaretDown}
       alt="CaretDown"
       cursor="pointer"
       onClick={() => {
        if (isOpenView !== "doesCost") {
         setOpenView("doesCost");
        } else {
         setOpenView("");
        }
       }}
      />
     </Stack>
    </Flex>
    {isOpenView === "doesCost" && (
     <Box ml={4} mb="2rem">
      <Badge
       bg="#EDFAE6"
       borderRadius="5px"
       p="8px"
       ml={4}
       pl="16px"
       pr="16px"
       color="#007400"
       textTransform="capitalize"
      >
       Information Available
      </Badge>
      <Box mt="0.5rem" mb="1rem" ml={4}>
       <Stack direction="row" spacing={4} align="center">
        <Button background="transparant" borderRadius="50px" variant="outline">
         Unit Price
        </Button>
       </Stack>
      </Box>
     </Box>
    )}
   </Box>
   <Box
    w="100%"
    mt="3rem"
    border="1px solid #D7DBE2"
    borderRadius="17px"
    ref={baseLevel}
   >
    <Flex w="100%" p="1rem">
     <Stack direction="row" spacing={4} align="center">
      <Box pl={4} fontWeight="bold" fontSize="36px" w="260px">
       Base Level
      </Box>
      <Button
       onClick={() => saveLevel("BaseLevel")}
      >
       Select level
      </Button>
     </Stack>

     <Spacer />
     <Stack direction="row" spacing={4} align="center">
      <Image
       src={isOpenView === "baseLevel" ? CaretUp : CaretDown}
       alt="CaretDown"
       cursor="pointer"
       onClick={() => {
        if (isOpenView !== "baseLevel") {
         setOpenView("baseLevel");
        } else {
         setOpenView("");
        }
       }}
      />
     </Stack>
    </Flex>
    {isOpenView === "baseLevel" && (
     <Box ml={4} mb="2rem">
      <Box>
       <Badge
        bg="#EDFAE6"
        borderRadius="5px"
        p="8px"
        ml={4}
        pl="16px"
        pr="16px"
        color="#007400"
        textTransform="capitalize"
       >
        Information Available
       </Badge>
       <Box mt="0.5rem" mb="1rem" ml={4}>
        <Stack direction="row" spacing={4} align="center">
         <Button background="transparant" borderRadius="50px" variant="outline">
          Unit Price
         </Button>
        </Stack>
       </Box>
      </Box>
      <Box mt="1.5rem">
       <Badge
        bg="#FEFEE7"
        borderRadius="5px"
        p="8px"
        ml={4}
        pl="16px"
        pr="16px"
        color="#F68400"
        textTransform="capitalize"
       >
        Information Can Be Gained
       </Badge>
       <Box mt="0.5rem" mb="1rem" ml={4}>
        <Stack direction="row" spacing={4} align="center">
         <Button background="transparant" borderRadius="50px" variant="outline">
          Material
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Manufacturing
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Logistic
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Profit Element
         </Button>
        </Stack>
       </Box>
      </Box>
     </Box>
    )}
   </Box>
   <Box
    w="100%"
    mt="3rem"
    border="1px solid #D7DBE2"
    borderRadius="17px"
    ref={level1}
   >
    <Flex w="100%" p="1rem">
     <Stack direction="row" spacing={4} align="center">
      <Box pl={4} fontWeight="bold" fontSize="36px" w="260px">
       Level 1
      </Box>
      {isOpenView === "level1" && (
       <Box as="span" pr="2rem">
        (Does cost + Base level)
       </Box>
      )}
      <Button
       onClick={() => saveLevel("Level1")}
      >
       Select level
      </Button>
     </Stack>

     <Spacer />
     <Stack direction="row" spacing={4} align="center">
      <Image
       src={isOpenView === "level1" ? CaretUp : CaretDown}
       alt="CaretDown"
       cursor="pointer"
       onClick={() => {
        if (isOpenView !== "level1") {
         setOpenView("level1");
        } else {
         setOpenView("");
        }
       }}
      />
     </Stack>
    </Flex>
    {isOpenView === "level1" && (
     <Box ml={4} mb="2rem">
      <Box>
       <Badge
        bg="#EDFAE6"
        borderRadius="5px"
        p="8px"
        ml={4}
        pl="16px"
        pr="16px"
        color="#007400"
        textTransform="capitalize"
       >
        Information Available
       </Badge>
       <Box mt="0.5rem" mb="1rem" ml={4}>
        <Stack direction="row" spacing={4} align="center">
         <Button background="transparant" borderRadius="50px" variant="outline">
          Material Category
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Primary Manufacturing
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Secondary Manufacturing
         </Button>
        </Stack>
       </Box>
      </Box>
      <Box mt="1.5rem">
       <Badge
        bg="#FEFEE7"
        borderRadius="5px"
        p="8px"
        ml={4}
        pl="16px"
        pr="16px"
        color="#F68400"
        textTransform="capitalize"
       >
        Information Can Be Gained
       </Badge>
       <Box mt="0.5rem" mb="1rem" ml={4}>
        <Stack direction="row" spacing={4} align="center">
         <Button background="transparant" borderRadius="50px" variant="outline">
          Transportation
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Profit Element
         </Button>
        </Stack>
       </Box>
      </Box>
     </Box>
    )}
   </Box>
   <Box
    w="100%"
    mt="3rem"
    border="1px solid #D7DBE2"
    borderRadius="17px"
    ref={level2}
   >
    <Flex w="100%" p="1rem">
     <Stack direction="row" spacing={4} align="center">
      <Box pl={4} fontWeight="bold" fontSize="36px" w="260px">
       Level 2
      </Box>
      {isOpenView === "level2" && (
       <Box as="span" pr="2rem">
        (Does cost + Base level + Level 1)
       </Box>
      )}
      <Button
       onClick={() => saveLevel("Level2")}
      >
       Select level
      </Button>
     </Stack>

     <Spacer />
     <Stack direction="row" spacing={4} align="center">
      <Image
       src={isOpenView === "level2" ? CaretUp : CaretDown}
       alt="CaretDown"
       cursor="pointer"
       onClick={() => {
        if (isOpenView !== "level2") {
         setOpenView("level2");
        } else {
         setOpenView("");
        }
       }}
      />
     </Stack>
    </Flex>
    {isOpenView === "level2" && (
     <Box ml={4} mb="2rem">
      <Box>
       <Badge
        bg="#EDFAE6"
        borderRadius="5px"
        p="8px"
        ml={4}
        pl="16px"
        pr="16px"
        color="#007400"
        textTransform="capitalize"
       >
        Information Available
       </Badge>
       <Box mt="0.5rem" mb="1rem" ml={4}>
        <Stack direction="row" spacing={4} align="center">
         <Button background="transparant" borderRadius="50px" variant="outline">
          Material Name
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Specific Production Process
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Profit Element
         </Button>
        </Stack>
       </Box>
      </Box>
      <Box mt="2rem">
       <Badge
        bg="#FEFEE7"
        borderRadius="5px"
        p="8px"
        ml={4}
        pl="16px"
        pr="16px"
        color="#F68400"
        textTransform="capitalize"
       >
        Information Can Be Gained
       </Badge>
       <Box mt="0.5rem" mb="1rem" ml={4}>
        <Stack direction="row" spacing={4} align="center">
         <Button background="transparant" borderRadius="50px" variant="outline">
          Material Price
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Transportation Modes
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Packaging Information
         </Button>
        </Stack>
       </Box>
      </Box>
      <Box mt="2rem">
       <Badge
        bg="#FEE9E7"
        borderRadius="5px"
        p="8px"
        ml={4}
        pl="16px"
        pr="16px"
        color="#CF3200"
        textTransform="capitalize"
       >
        Information Cannot Be Gained
       </Badge>
       <Box mt="0.5rem" mb="1rem" ml={4}>
        <Stack direction="row" spacing={4} align="center">
         <Button background="transparant" borderRadius="50px" variant="outline">
          Setup For Production
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Waste Production
         </Button>
        </Stack>
       </Box>
      </Box>
     </Box>
    )}
   </Box>
   <Box
    w="100%"
    mt="3rem"
    border="1px solid #D7DBE2"
    borderRadius="17px"
    ref={level3}
   >
    <Flex w="100%" p="1rem">
     <Stack direction="row" spacing={4} align="center">
      <Box pl={4} fontWeight="bold" fontSize="36px" w="260px" color="#9DA1A7">
       Level 3
      </Box>
      {isOpenView === "level3" && (
       <Box as="span" pr="2rem">
        (Does cost + Base level + Level 1 + Level 2)
       </Box>
      )}
      <Button isDisabled>
       Select level
      </Button>
     </Stack>

     <Spacer />
     <Stack direction="row" spacing={4} align="center">
      <Image
       src={isOpenView === "level3" ? CaretUp : CaretDown}
       alt="CaretDown"
       cursor="pointer"
       onClick={() => {
        if (isOpenView !== "level3") {
         setOpenView("level3");
        } else {
         setOpenView("");
        }
       }}
      />
     </Stack>
    </Flex>
    {isOpenView === "level3" && (
     <Box ml={4} mb="2rem">
      <Box>
       <Badge
        bg="#EDFAE6"
        borderRadius="5px"
        p="8px"
        ml={4}
        pl="16px"
        pr="16px"
        color="#007400"
        textTransform="capitalize"
       >
        Information Available
       </Badge>
       <Box mt="0.5rem" mb="1rem" ml={4}>
        <Stack direction="row" spacing={4} align="center">
         <Button background="transparant" borderRadius="50px" variant="outline">
          Material Name
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Material Specification
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Material Price
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Good Production
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Transportation Modes
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Palletization
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Profit Element
         </Button>
        </Stack>
       </Box>
      </Box>
      <Box mt="2rem">
       <Badge
        bg="#FEFEE7"
        borderRadius="5px"
        p="8px"
        ml={4}
        pl="16px"
        pr="16px"
        color="#F68400"
        textTransform="capitalize"
       >
        Information Can Be Gained
       </Badge>
       <Box mt="0.5rem" mb="1rem" ml={4}>
        <Stack direction="row" spacing={4} align="center">
         <Button background="transparant" borderRadius="50px" variant="outline">
          Setup For Production
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Waste Production
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Shipping Terms
         </Button>
        </Stack>
       </Box>
      </Box>
      <Box mt="2rem">
       <Badge
        bg="#FEE9E7"
        borderRadius="5px"
        p="8px"
        ml={4}
        pl="16px"
        pr="16px"
        color="#CF3200"
        textTransform="capitalize"
       >
        Information Cannot Be Gained
       </Badge>
       <Box mt="0.5rem" mb="1rem" ml={4}>
        <Stack direction="row" spacing={4} align="center">
         <Button background="transparant" borderRadius="50px" variant="outline">
          Shipping Cost For Specific Material
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Production Setup By Machine
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Production Waste By Machine
         </Button>
        </Stack>
       </Box>
      </Box>
     </Box>
    )}
   </Box>
   <Box
    w="100%"
    mt="3rem"
    border="1px solid #D7DBE2"
    borderRadius="17px"
    ref={level4}
   >
    <Flex w="100%" p="1rem">
     <Stack direction="row" spacing={4} align="center">
      <Box pl={4} fontWeight="bold" fontSize="36px" w="260px" color="#9DA1A7">
       Level 4
      </Box>
      {isOpenView === "level4" && (
       <Box as="span" pr="2rem">
        (Does cost + Base level + Level 1 + Level 2 + Level 3)
       </Box>
      )}
      <Button isDisabled>
       Select level
      </Button>
     </Stack>

     <Spacer />
     <Stack direction="row" spacing={4} align="center">
      <Image
       src={isOpenView === "level4" ? CaretUp : CaretDown}
       alt="CaretDown"
       cursor="pointer"
       onClick={() => {
        if (isOpenView !== "level4") {
         setOpenView("level4");
        } else {
         setOpenView("");
        }
       }}
      />
     </Stack>
    </Flex>
    {isOpenView === "level4" && (
     <Box ml={4} mb="2rem">
      <Box>
       <Badge
        bg="#EDFAE6"
        borderRadius="5px"
        p="8px"
        ml={4}
        pl="16px"
        pr="16px"
        color="#007400"
        textTransform="capitalize"
       >
        Information Available
       </Badge>
       <Box mt="0.5rem" mb="1rem" ml={4}>
        <Stack direction="row" spacing={4} align="center">
         <Button background="transparant" borderRadius="50px" variant="outline">
          Material Name
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Material Specification
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Material Price
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Transportation Modes
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Palletization
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Profit Element
         </Button>
        </Stack>
       </Box>
      </Box>
      <Box mt="2rem">
       <Badge
        bg="#FEFEE7"
        borderRadius="5px"
        p="8px"
        ml={4}
        pl="16px"
        pr="16px"
        color="#F68400"
        textTransform="capitalize"
       >
        Information Can Be Gained
       </Badge>
       <Box mt="0.5rem" mb="1rem" ml={4}>
        <Stack direction="row" spacing={4} align="center">
         <Button background="transparant" borderRadius="50px" variant="outline">
          Shipping Cost For Specific Material
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Production Setup By Machine
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Production Waste By Machine
         </Button>
         <Button background="transparant" borderRadius="50px" variant="outline">
          Shipping Terms
         </Button>
        </Stack>
       </Box>
      </Box>
     </Box>
    )}
   </Box>
   <Box pt="2rem" textAlign="right" w="100%">
    <Stack spacing={4} direction="row" align="center" float="right">
     <Button
     variant={"outline"}
      onClick={() => router.goBack()}
     >
      Back
     </Button>
     <Button
      onClick={() => {
       if (levelName !== "") {
        router.push("/journeys/create?page=name-journey");
       }
       OptipediaToast.Warning(
        "Please select one of the levels before proceeding to the next step."
       );
      }}
     >
      Next
     </Button>
    </Stack>
   </Box>
  </>
 );
};

export default TargetDiscoveryStep3;
