import { FC, useEffect, useState } from "react";
import Table from "components/Table/Table";
import Pagination from "components/Pagination";
import { useHistory } from "react-router-dom";
import { TableLoading } from "components/Loading";
import { Box, Image, Button, HStack } from "@chakra-ui/react";
import Swal from "sweetalert2";
import IconDelete from "assets/images/Delete.png";
import IconEdit from "assets/images/Edit.png";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import "utils/date-utils";
import OptipediaToast from "utils/notification-util";

const TABLE_HEADERS = [
 { name: "code", label: "Code" },
 { name: "description", label: "Description" },
];
const data = [{ code: "-", description: "-" }];

const PredefinedThirdPartyTable: FC = () => {
 const history = useHistory();
 const dispatch = useAppDispatch();
 const maxResultCount = 10;

 const [pageNumber, setPageNumber] = useState(1);

 let { loading, thirdParty, totalCountData, totalPage } = useSelector(
  (state: RootState) => ({
   loading: state.thirdPartySetting.loading,
   thirdParty: state.thirdPartySetting.thirdParty,
   totalPage: Math.ceil(
    state.thirdPartySetting.thirdParty.totalCount / maxResultCount
   ),
   totalCountData: state.thirdPartySetting.thirdParty.totalCount,
  }),
  shallowEqual
 );

 const fetchListOf3rdParty = (skipCount: number) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: maxResultCount,
  };

  return dispatch(thirdPartySettingAction.getThirdPartyToShow(payload));
 };

 useEffect(() => {
  fetchListOf3rdParty(0);
 }, [dispatch]);
 const handleChangePagination = (pageNumber: number) => {
  setPageNumber(pageNumber);
  var skipCount = (pageNumber - 1) * maxResultCount;
  fetchListOf3rdParty(skipCount);
 };
 const deleteThirdParty = async (thirdPartyId: string) => {
  await dispatch(
   thirdPartySettingAction.deleteThirdPartyById({ id: thirdPartyId })
  ).then((res: any) => {
   if (res.payload !== undefined) {
    OptipediaToast.Success("Third-Party has been removed successfully.");
   }
   fetchListOf3rdParty(0);
  });
 };

 return (
  <>
   {/* {(!loading && thirdParty?.items?.length) > 0 && (<Box p="1rem"> */}
   <Table
    loading={loading}
    columnHeaders={TABLE_HEADERS}
    data={thirdParty && thirdParty?.items?.length > 0 ? thirdParty.items : data}
    actionButtons={(thirdPartyId: string) => (
     <HStack spacing="24px">
      <Box w="32px" h="32px">
       <Image
        display={thirdParty && thirdParty?.items?.length > 0 ? "" : "none"}
        src={IconEdit}
        cursor="pointer"
        alt="IoMdEdit"
        mr="1rem"
        w="24px"
        h="24px"
        onClick={() =>
         history.push({
            search: "page=edited",
            state: thirdPartyId
         })
        }
       />
      </Box>
      <Box w="32px" h="32px">
       <Image
        display={thirdParty && thirdParty?.items?.length > 0 ? "" : "none"}
        src={IconDelete}
        onClick={async () => {
         Swal.fire({
          showCancelButton: true,
          html: "Are you sure you want to delete this Third-Party?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
         }).then(async (result) => {
          if (result.isConfirmed) {
           deleteThirdParty(thirdPartyId);
          }
         });
        }}
        cursor="pointer"
        alt="IoMdTrash"
        mr="1rem"
        w="24px"
        h="24px"
       />
      </Box>
     </HStack>
    )}
   />
   {/* </Box>))} */}
   <Box w="100%" mt="1rem" mb="2rem" p="20px">
    {thirdParty && thirdParty?.items?.length > 0 && (
     <Pagination
      onChange={handleChangePagination}
      totalRowsPage={maxResultCount}
      pageNumber={totalPage}
      currentPage={pageNumber}
      totalAllPage={totalCountData}
     />
    )}
   </Box>
  </>
 );
};

export default PredefinedThirdPartyTable;
