import { createSlice } from '@reduxjs/toolkit';

import { upload, downloadTemplate } from 'state/pricings/dataLoading/thunk';

interface historyTs {
  userName: string
  userFullName: string
  startUploadTime: string
  endUploadTime: string
}
interface InitialState {
  profile: Record<string, unknown>
  loading: boolean
  loadingDownload: boolean
  error: unknown
  histories: historyTs
  successUpload: boolean
}

const initialState: InitialState = {
  profile: {},
  loading: false,
  loadingDownload: false,
  error: null,
  histories: {
    userName: "",
    userFullName: "",
    startUploadTime: "",
    endUploadTime: ""
  },
  successUpload: false
};

const dataLoadingSlice = createSlice({
  name: 'dataloading',
  initialState,
  reducers: {
    clear: () => initialState,
    updateSuccessUpload: (state, action) => {
      state.successUpload = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(upload.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(upload.fulfilled, (state, action) => {
      state.successUpload = action.payload
      state.loading = false
    })

    builder.addCase(upload.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    builder.addCase(downloadTemplate.pending, (state) => {
      state.loadingDownload = true;
      state.error = null;
    })

    builder.addCase(downloadTemplate.fulfilled, (state, action) => {
      state.loadingDownload = false;
    })

    builder.addCase(downloadTemplate.rejected, (state, action) => {
      state.loadingDownload = false;
      state.error = action.payload;
    })
  }
})

export const actions = {
  ...dataLoadingSlice.actions,
  downloadTemplate,
  upload
}

export const reducer = dataLoadingSlice.reducer;
