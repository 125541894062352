import { useEffect, useRef } from "react";
import Layout from "views/Layout";
import { Box, Heading, VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import Stepper from "views/Simulation/Stepper";
import Journey from "views/AnnualSpend/Simulation/Journey";
import DataUpload from "views/AnnualSpend/Simulation/DataUpload";
import Step1 from "views/AnnualSpend/Simulation/Component/step1";
import Step2 from "views/AnnualSpend/Simulation/Component/step2";
import DescribeChange from "views/AnnualSpend/Simulation/DescribeChange";
import Summary from "views/AnnualSpend/Simulation/Summary";
import Lamp from "assets/images/bulp.png";

export default function Create() {
 const router = useHistory();
 const messagesEndRef = useRef<null | HTMLDivElement>(null);
 const { location } = router;
 const { search } = location;
 const query = queryString.parse(search);

 useEffect(() => {
  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
 }, [search]);

 useEffect(() => {
  window.scrollTo(0, 0);
  if (query?.page !== "select-journey") {
   window.location.href = "/annual-spend-simulations/create?page=select-journey";
  }
 }, []);

 return (
  <Layout>
   <VStack h="90vh" align="flex-start" ref={messagesEndRef}>
    <Box display="flex" w="100%">
     <Heading as="h1" fontSize="36px" pb="36px">
      {query?.page === "select-journey" && "Select journey"}
      {query?.page === "select-dataset" && "Select dataset"}
      {query?.page === "select-component-step-1" && "Select component"}
      {query?.page === "select-component-step-2" && "Select component"}
      {query?.page === "describe-change" && "Describe changes"}
      {query?.page === "summary" && "Summary"}
     </Heading>
     <Box
      width="276px"
      as="p"
      color="white"
      fontSize="16px"
      ml="25px"
      textColor="#9DA1A7"
      fontWeight="500"
     >
      {query?.page === "summary" &&
       `This is the result of your simulation. Click here to see your changes.`}
     </Box>
     {query?.page === "summary" && (
      <Box
       width="500px"
       borderRadius="20px"
       mt="-1rem"
       mb="1rem"
       ml="25px"
       backgroundColor="#F7F9FD"
      >
       <Image src={Lamp} alt="lampicon" float="left" m="1.5rem" />
       <Box
        as="p"
        p="1rem"
        color="#7B7F85"
        fontSize="16px"
        fontWeight="500"
        justifyContent="center"
       >
        To unlock more data, contact InnoArk staff here <br /> to unlock more
        levels of your cost journey.
       </Box>
      </Box>
     )}
    </Box>
    {query?.page !== "summary" ? <Stepper /> : <></>}
    <Box w="100%">
     {query?.page === "select-journey" && <Journey />}
     {query?.page === "select-dataset" && <DataUpload />}
     {query?.page === "select-component-step-1" && <Step1 />}
     {query?.page === "select-component-step-2" && <Step2 />}
     {query?.page === "describe-change" && <DescribeChange />}
     {query?.page === "summary" && <Summary />}
    </Box>
   </VStack>
  </Layout>
 );
}
