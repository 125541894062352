import { ReactElement, useState, useEffect } from "react";
import {
 Box,
 Flex,
 Heading,
 Text,
 Stack,
 Grid,
 GridItem,
 Center,
 Divider,
} from "@chakra-ui/layout";
import { useHistory, useLocation } from "react-router-dom";
import {
 Checkbox,
 Image,
 useDisclosure,
 Button,
 IconButton,
} from "@chakra-ui/react";
import TextInput from "components/TextInput";
import Select from "components/Select";
import ToolbarBottom from "../ToolbarBottom";
import { Formik, Form } from "formik";
import CustomField from "../CustomFIeld";
import { actions as masterDataAction } from "state/masterDataConfiguration/slice";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { useAppDispatch, usePrompt } from "hooks";
import IconDelete from "assets/images/Delete.png";
import Swal from "sweetalert2";
import { isEmpty, lowerCase } from "lodash";
import * as Yup from "yup";
import EmptyIcon from "assets/images/EmptyData.png";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import { TextLoading } from "components/Loading";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
import shortid from "shortid";

const Created = (): ReactElement => {
 const dispatch = useAppDispatch();
 const router = useHistory();
 const location = useLocation<{ id: string; isEditing: boolean }>();
 const {
  loading,
  searchQuery,
  masterDataConfigurations,
  dataToUpdate,
  dataToSend,
  customFields,
  data,
  isEnableThirdPartyStatus,
 } = useSelector(
  (state: RootState) => ({
   loading: state.masterDataConfiguration.loading,
   searchQuery: state.masterDataConfiguration.searchQuery,
   masterDataConfigurations: state.masterDataConfiguration.lists.result.items,
   data: state.masterDataConfiguration.data,
   dataToSend: state.masterDataConfiguration.dataToSend,
   dataToUpdate: state.masterDataConfiguration.dataToUpdate,
   customFields: state.masterDataConfiguration.customFields,
   isEnableThirdPartyStatus: state.thirdPartySetting.isEnableThirdPartyStatus,
  }),
  shallowEqual
 );
 const [actived, setActived] = useState(true);
 const [saveActived, setSaveActived] = useState(true);
 const [codeType, setCodeType] = useState("");
 const [descType, setDescType] = useState("");
 const [costType, setCostType] = useState("");
 const [codeValue, setCodeValue] = useState("");
 const [descValue, setDescValue] = useState("");
 const [costValue, setCostValue] = useState("");
 const [isEditing, setEditing] = useState(false);
 const [isIncludeCostValue, setIsIncludeCostValue] = useState(false);

 const COLUMN_HEADERS = [
  { name: "name", label: "Label Name" },
  { name: "type", label: "Data Type" },
  { name: "list", label: "Master List" },
  { name: "required", label: "Required" },
  { name: "display", label: "Display Pricing" },
 ];

 useEffect(() => {
  dispatch(thirdPartySettingAction.configurationStatus());
 }, [isEnableThirdPartyStatus, location]);
 useEffect(() => {
  const getDetail = async () => {
   await dispatch(
    masterDataAction.masterDataConfigurationsDetails(location.state.id)
   );
   dispatch(masterDataAction.setDataToUpdate());
   setEditing(location.state.isEditing);
  };
  if (location?.state?.id) {
   getDetail();
  }
 }, [location]);

 useEffect(() => {
  if (!isEmpty(dataToUpdate)) {
   const updateData = async () => {
    const newData = dataToUpdate.customFields.map((item:any)=> ({
      id: shortid.generate(),
      ...item
    }))
    dispatch(masterDataAction.setDataToSend(dataToUpdate));
    dispatch(masterDataAction.setCustomField(newData));
   };
   updateData();
  }
 }, [dataToUpdate]);

 useEffect(() => {
  setCodeValue(lowerCase(dataToSend.code));
  setDescValue(lowerCase(dataToSend.description));
  dataToSend.cost !== "" && setCostValue(lowerCase(dataToSend.cost));
 }, [dataToSend]);

 const newMasterDataConfigurations: any = [];
 if (Array.isArray(masterDataConfigurations)) {
  masterDataConfigurations.map((item: any) => {
   newMasterDataConfigurations.push({
    label: item.identityName,
    value: item.id,
   });
  });
 }

 const { isOpen, onOpen, onClose } = useDisclosure();
 const validation = Yup.object().shape({
  identityCode: Yup.string()
   .required("This information are required")
   .max(8, "The text input has exceed the maximum characters."),
  identityName: Yup.string().required("This information are required"),
  code: Yup.string()
   .required("This information are required")
   .test(
    "codeCheck",
    "The value you entered is already in use. Please enter a unique values.",
    (value: any) =>
     value !== undefined
      ? codeValue.toLocaleLowerCase() !== descValue.toLocaleLowerCase()
      : isIncludeCostValue
      ? costValue.toLocaleLowerCase() !== codeValue.toLocaleLowerCase()
      : true
   ),
  description: Yup.string()
   .required("This information are required")
   .test(
    "desCheck",
    "The value you entered is already in use. Please enter a unique value.",
    (value: any) => (value !== undefined ? descValue.toLocaleLowerCase() !== codeValue.toLocaleLowerCase() : true)
   ),
  cost:
   !isIncludeCostValue || isIncludeCostValue === undefined
    ? Yup.string()
    : Yup.string()
       .required("This information are required")
       .test(
        "costCheck",
        "The value you entered is already in use. Please enter a unique value.",
        (value: any) =>
         value !== undefined || value !== ""
          ? costValue.toLocaleLowerCase() !== descValue.toLocaleLowerCase() && costValue.toLocaleLowerCase() !== codeValue.toLocaleLowerCase()
          : true
       ),
  customFields: Yup.array().of(
   Yup.object().shape({
    name: Yup.string(),
   })
  ),
 });
 const optionType: any = [
  { label: "Text", value: 10 },
  { label: "Numeric", value: 20 },
  { label: "List", value: 30 },
  { label: "Currency", value: 50 }
 ];

 usePrompt(true);
 return (
  <Box>
   <Heading as="h2" fontSize="22px" my="1rem">
    {!isEditing ? "Create New" : "Edit data"}
   </Heading>
   <Formik
    enableReinitialize
    initialValues={dataToSend}
    validationSchema={validation}
    validateOnMount
    validateOnChange
    validateOnBlur
    onSubmit={(values: any, { resetForm }) => {
     if (isEditing) {
      const data = {
       ...values,
       customFields: values.customFields.filter(
        (item: any) => item.name !== ""
       ),
      };
      dispatch(masterDataAction.editMasterDataConfigurations(data)).then(
       (response: any) => {
        if (response.payload) {
         dispatch(masterDataAction.clear());
         router.push("/settings/master-data/configuration");
        }
       }
      );
     } else {
      const data = {
       ...values,
       customFields: values.customFields.filter(
        (item: any) => item.name !== ""
       ),
      };

      dispatch(masterDataAction.createMasterDataConfigurations(data)).then(
       (response: any) => {
        if (response.payload) {
         dispatch(masterDataAction.clear());
         router.push("/settings/master-data/configuration");
        }
       }
      );
     }
    }}
   >
    {({ values, setFieldValue, setFieldError }) => (
     <Form>
      <Box
       w="50%"
       border={"1px solid rgba(26, 26, 26, 0.16)"}
       borderRadius="5px"
      >
       <Box>
        <Stack direction={["column", "row"]}>
         <Flex pl="4">
          <Box mt={"1rem"}>
           <Text my={5}>
            Master Data Code <span style={{ color: "red" }}>*</span>
           </Text>
          </Box>
          <Box pl="5" mt={"1rem"}>
           <TextInput
            w={300}
            name="identityCode"
            id="identityCode"
            placeholder="e.g. RM 001"
            isDisabled={isEditing}
            textTransform="uppercase"
            value={values.identityCode}
            onKeyPress={(event) => {
             if (!/[a-zA-Z0-9]+/gu.test(event.key)) {
              event.preventDefault();
              setFieldError(
               `identityCode`,
               "The field should have alphanumeric only"
              );
             }
            }}
           />
          </Box>
         </Flex>
        </Stack>
        <Stack direction={["column", "row"]}>
         <Flex pl="4">
          <Box mt={"1rem"}>
           <Text my={5}>
            Master Data Name <span style={{ color: "red" }}>*</span>
           </Text>
          </Box>
          <Box pl="4" mt={"1rem"}>
           <TextInput
            w={300}
            name="identityName"
            id="identityName"
            placeholder="e.g. Raw Material"
           />
          </Box>
         </Flex>
        </Stack>
       </Box>
       <Box pl="4" mt="1.5rem" mb="0.5rem">
        <Flex>
         {loading ? (
          <TextLoading />
         ) : (
          <Box mb="5" mr="8">
           <Checkbox
            name="isIncludeCostValue"
            isChecked={values.isIncludeCostValue}
            onChange={(e) => {
             setFieldValue("isIncludeCostValue", e.target.checked);
             setFieldValue(`isCodeDisplayOnPricing`, e.target.checked);
             setFieldValue(`isCostDisplayOnPricing`, e.target.checked);
             setIsIncludeCostValue(e.target.checked);
             e.target.checked && setFieldValue("cost", "");
             e.target.checked && setCostValue("");

             // // valueCheck(values)
            }}
           >
            <Text fontWeight={"600"} fontSize="14px">
             Include Cost Value
            </Text>
           </Checkbox>
          </Box>
         )}
         {isEnableThirdPartyStatus && (
          <Box ml="8">
           <Checkbox
            name="isAssociateWithThirdParty"
            isChecked={values.isAssociateWithThirdParty}
            onChange={() => {
             setFieldValue(
              "isAssociateWithThirdParty",
              !values.isAssociateWithThirdParty
             );
             // valueCheck(values)
            }}
           >
            <Box>
             <Text fontWeight={"600"} fontSize="14px">
              Associate with Third-Party
             </Text>
            </Box>
           </Checkbox>
          </Box>
         )}
         {loading && <TextLoading />}
        </Flex>
       </Box>
      </Box>
      <Heading as="h2" fontSize="22px" mt="2rem">
       Required Field
      </Heading>
      <Box
       mt="2rem"
       mb={"5rem"}
       border={"1px solid rgba(26, 26, 26, 0.16)"}
       borderRadius="5px"
      >
       <Grid templateColumns="repeat(5, 1fr)" gap={6}>
        {COLUMN_HEADERS.map((item: any, index: number) => (
         <GridItem
          py="5"
          key={index}
          justifyContent={
           item.label === "Required"
            ? "center"
            : item.label === "Display Pricing"
            ? "center"
            : ""
          }
          display={
           item.label === "Required"
            ? "flex"
            : item.label === "Display Pricing"
            ? "flex"
            : ""
          }
         >
          <Text fontSize={18} key={index} pl="4">
           {item.label}
          </Text>
         </GridItem>
        ))}
       </Grid>
       <Center>
        <Divider w="98%" />
       </Center>
       <Grid templateColumns="repeat(5, 1fr)" gap={6} my="4" pl="4">
        <GridItem>
         <TextInput
          name="code"
          label=""
          id="code"
          placeholder="e.g Material Code"
          onChange={(val) => {
           const newVal = val.target.value;
           setCodeValue(newVal);
           setFieldValue(`code`, newVal);
          }}
         />
        </GridItem>
        <GridItem>
         <Box pt="10px">
         <SelectWithAutoComplete
         width={200}
         isDisabled
                   label=""
                   name="codeType"
                   id="codeType"
                   options={optionType}
                   defaultValue={'10'}
                   onChange={(e: any) => {
                    setCodeType(e.value)
                   }}
                  />
         </Box>
        </GridItem>
        <GridItem>
         {codeType === "30" && (
          // <Select
          //  name="definedData"
          //  label=""
          //  id="definedData"
          //  isCustomField
          //  data={newMasterDataConfigurations}
          // />
          <Box pt={"10px"}>
            <SelectWithAutoComplete
            width={200}
         isDisabled
                   label=""
                   name="definedData"
                   id="definedData"
                   options={newMasterDataConfigurations}
                   onChange={(e: any) => {
                    setFieldValue('definedData',e.value)
                   }}
                  />
          </Box>
         )}
        </GridItem>
        <GridItem py="6">
         <Center>
          <Checkbox isChecked={true} isDisabled />
         </Center>
        </GridItem>
        <GridItem py="6">
         <Center>
          <Checkbox isChecked={values.isCodeDisplayOnPricing} isDisabled />
         </Center>
        </GridItem>
       </Grid>
       <Grid templateColumns="repeat(5, 1fr)" gap={6} my="6" pl="4">
        <GridItem>
         <TextInput
          name="description"
          label=""
          id="description"
          placeholder="e.g Material Desc"
          onChange={(val) => {
           const newVal = val.target.value;
           setDescValue(newVal);
           setFieldValue(`description`, newVal);
          }}
         />
        </GridItem>
        <GridItem>
        <Box pt={"10px"}>
        <SelectWithAutoComplete
        width={200}
         isDisabled
                   label=""
                   name="descType"
                   id="descType"
                   defaultValue="10"
                   options={optionType}
                   onChange={(e: any) => {
                    setDescType(e.value);
                   }}
                  />
         
        </Box>
        </GridItem>
        <GridItem>
         <Box pt={"10px"}>
         {descType === "30" && (
          <Select
           name="descTypes"
           label=""
           id="descTypes"
           isCustomField
           data={newMasterDataConfigurations}
          />
         )}
         </Box>
        </GridItem>
        <GridItem py="6">
         <Center>
          <Checkbox isChecked={true} isDisabled />
         </Center>
        </GridItem>
        <GridItem py="6">
         <Center>
          <Checkbox
           isDisabled
           isChecked={values.isDescriptionDisplayOnPricing}
          />
         </Center>
        </GridItem>
       </Grid>
       {values.isIncludeCostValue && (
        <Grid templateColumns="repeat(5, 1fr)" gap={6} my="4" pl="4">
         <GridItem>
          <TextInput
           name="cost"
           label=""
           id="cost"
           placeholder="e.g Material Cost"
           onChange={(val) => {
            const newVal = val.target.value;
            setCostValue(newVal);
            setFieldValue(`cost`, newVal);
           }}
          />
         </GridItem>
         <GridItem>
         <Box pt={"10px"}>
         <SelectWithAutoComplete
         width={200}
         isDisabled
                   label=""
                   name="costType"
                   id="costType"
                   defaultValue="50"
                   options={optionType}
                   onChange={(e: any) => {
                    setCostType(e.value);
                   }}
                  />
         </Box>
         </GridItem>
         <GridItem>
          {costType === "30" && (
           <Select
            name="costTypes"
            label=""
            id="costTypes"
            isCustomField
            data={newMasterDataConfigurations}
           />
          )}
         </GridItem>
         <GridItem py="6">
          <Center>
           <Checkbox isChecked={true} isDisabled />
          </Center>
         </GridItem>
         <GridItem py="6">
          <Center>
           <Checkbox
            isDisabled
            isChecked={values.isCostDisplayOnPricing}
            onChange={(val) => {
             setFieldValue(
              `isCostDisplayOnPricing`,
              !values.isCostDisplayOnPricing
             );
            }}
           />
          </Center>
         </GridItem>
        </Grid>
       )}
      </Box>
      <Box width={"80%"}>
       <ToolbarBottom
        isValid={
         values.identityCode === "" ||
         values.identityName === "" ||
         values.code === "" ||
         values.description === "" ||
         (values.isIncludeCostValue && values.cost === "")
        }
        onOpen={() => {
         dispatch(masterDataAction.setDataToSend(values));
         if (values.customFields.length > 0) {
          setActived(false);
          setSaveActived(false);
         }
         onOpen();
        }}
        loading={loading}
       />
       <CustomField
        isShow={values.customFields.length === 0 ? "none" : ""}
        onClose={() => {
         const findEmptyIndex = values.customFields.filter(
          (item: any) => item.name === ""
         );
         findEmptyIndex !== undefined &&
         values.customFields.length !== customFields.length
          ? Swal.fire({
             showCancelButton: true,
             html: "Leave site? Changes you made may not be saved",
             confirmButtonText: "Yes",
             cancelButtonText: "No",
            }).then(async (result) => {
             if (result.isConfirmed) {
              setFieldValue("customFields", [...customFields]);
              onClose();
             }
            })
          : JSON.stringify(values.customFields) !== JSON.stringify(customFields)
          ? Swal.fire({
             showCancelButton: true,
             html: "Leave site? Changes you made may not be saved",
             confirmButtonText: "Yes",
             cancelButtonText: "No",
            }).then(async (result) => {
             if (result.isConfirmed) {
              setFieldValue("customFields", [...customFields]);
              onClose();
             }
            })
          : onClose();
        }}
        isOpen={isOpen}
        isDisabled={actived}
        isDisabledSave={saveActived}
        onClick={() => {
         dispatch(
          masterDataAction.setDataToSend({
           ...values,
           customFields: [
            ...values.customFields,
            {
             id: shortid.generate(),
             name: "",
             displayName: "",
             isRequired: false,
             dataType: "",
             isDisplayOnPricing: false,
             masterDataConfigurationId: "",
            },
           ],
          })
         );
         setActived(true);
         setSaveActived(true);
        }}
        onSave={() => {
         dispatch(masterDataAction.setCustomField(values.customFields));
         setActived(true);
         onClose();
        }}
       >
        <Box mt="2rem" mb={"5rem"}>
         {/* <Flex> */}
         {values.customFields.length > 0 && (
          <Grid templateColumns="repeat(6, 1fr)" gap={6}>
           {COLUMN_HEADERS.map((item: any, index: number) => (
            <GridItem
             key={index}
             justifyContent={
              item.label === "Required"
               ? "center"
               : item.label === "Display Pricing"
               ? "flex"
               : ""
             }
             display={
              item.label === "Required"
               ? "flex"
               : item.label === "Display Pricing"
               ? "flex"
               : ""
             }
            >
             <Text fontSize={18} key={index} pl="4">
              {item.label}
             </Text>
            </GridItem>
           ))}
          </Grid>
         )}
         {Array.isArray(dataToSend.customFields) &&
          dataToSend.customFields.length > 0 &&
          dataToSend.customFields.map(
           ({ name, isRequired, isDisplayOnPricing }: any, index: number) => {
            return (
             <>
              <Grid
               key={index}
               templateColumns="repeat(6, 1fr)"
               gap={8}
               my="4"
               pl="4"
              >
               <GridItem>
                <TextInput
                 name={`customFields[${index}].name`}
                 label=""
                 // isDisabled={index < values.customFields.length - 1}
                 id={name}
                 placeholder="e.g. Height"
                 onChange={(val) => {
                  setFieldValue(
                   `customFields[${index}].name`,
                   val.target.value
                  );
                  setFieldValue(
                   `customFields[${index}].displayName`,
                   val.target.value
                  );
                  if (
                   val.target.value !== "" &&
                   values.customFields[index].dataType !== ""
                  ) {
                   setSaveActived(false);
                   setActived(false);
                  }
                 }}
                />
               </GridItem>
               <GridItem>
                <Box pt="10px">
                <SelectWithAutoComplete
                width={200}
                 label=""
                 name={`customFields[${index}].dataType`}
                 id={`customFields[${index}].dataType`}
                 options={optionType}
                 defaultValue={dataToSend.customFields[index].dataType}
                 onChange={(e: any) => {
                  setFieldValue(
                   `customFields[${index}].dataType`,
                   e.value
                  );
                  if (
                   !isEmpty(values.customFields[index]?.name) &&
                   e.value !== ""
                  ) {
                   if (e.value.toString() === "30") {
                    if (
                     isEmpty(
                      values.customFields[index].masterDataConfigurationId
                     )
                    ) {
                     setActived(true);
                     setSaveActived(true);
                    } else {
                     setActived(false);
                     setSaveActived(false);
                    }
                   }
                   if (e.value.toString() === "10" || e.value.toString() === "20" ||  e.value.toString() === "50") {
                    setActived(false);
                    setSaveActived(false);
                   }
                  }
                 }}
                />
                </Box>
                {/* <Select
                 name={`customFields[${index}].dataType`}
                 label=""
                 id="dataType"
                 // isDisabled={index < values.customFields.length - 1}
                 isCustomField
                 data={optionType}
                 onChange={(val) => {
                  setFieldValue(
                   `customFields[${index}].dataType`,
                   parseInt(val.target.value)
                  );
                  if (
                   !isEmpty(values.customFields[index]?.name) &&
                   val.target.value !== ""
                  ) {
                   if (val.target.value === "30") {
                    if (
                     isEmpty(
                      values.customFields[index].masterDataConfigurationId
                     )
                    ) {
                     setActived(true);
                     setSaveActived(true);
                    } else {
                     setActived(false);
                     setSaveActived(false);
                    }
                   }
                   if (val.target.value === "10" || val.target.value === "20") {
                    setActived(false);
                    setSaveActived(false);
                   }
                  }
                 }}
                /> */}
               </GridItem>
               <GridItem>
                <Box pt="10px">
                {Number(values?.customFields[index]?.dataType) === 30 && (
                 <SelectWithAutoComplete
                 width={200}
                  label=""
                  name={`customFields[${index}].masterDataConfigurationId`}
                  id={`customFields[${index}].masterDataConfigurationId`}
                  options={newMasterDataConfigurations}
                  defaultValue={
                   values.masterDataConfigurationId ||
                   values.masterDataConfiguration?.id
                  }
                  onChange={(e: any) => {
                   setFieldValue(
                    `customFields[${index}].masterDataConfigurationId`,
                    e.value
                   );
                   if (!isEmpty(values.customFields[index]?.name)) {
                    if (!isEmpty(e.value)) {
                     setActived(false);
                     setSaveActived(false);
                    }
                   } else {
                    setActived(true);
                    setSaveActived(true);
                   }
                  }}
                 />
                )}
                </Box>
               </GridItem>
               <GridItem justifyContent={"center"} display={"flex"}>
                <Checkbox
                 name={`customFields[${index}].isRequired`}
                 isChecked={values?.customFields[index]?.isRequired}
                 // isDisabled={index < values.customFields.length - 1}
                 onChange={(val) => {
                  setFieldValue(
                   `customFields[${index}].isRequired`,
                   !values.customFields[index].isRequired
                  );
                  // valueCheck(values)
                 }}
                />
               </GridItem>
               <GridItem justifyContent={"center"} display={"flex"}>
                <Checkbox
                 name={`customFields[${index}].isDisplayOnPricing`}
                 isChecked={values?.customFields[index]?.isDisplayOnPricing}
                 // isDisabled={index < values.customFields.length - 1}
                 onChange={(val) => {
                  setFieldValue(
                   `customFields[${index}].isDisplayOnPricing`,
                   !values?.customFields[index]?.isDisplayOnPricing
                  );
                  // valueCheck(values)
                 }}
                />
               </GridItem>
               <GridItem justifyContent={"center"} display={"flex"} pr="4">
                <IconButton
                 onClick={() => {
                  Swal.fire({
                   showCancelButton: true,
                   html: "Are you sure you want to delete this Field?",
                   confirmButtonText: "Yes",
                   cancelButtonText: "No",
                  }).then((result) => {
                   if (result.isConfirmed) {
                    setActived(false);
                    setSaveActived(false);
                    dispatch(masterDataAction.setDataToSend(values));
                    dispatch(
                     masterDataAction.removeCustomeFields(
                      values.customFields[index].id
                     )
                    );
                   }
                  });
                 }}
                 disabled={
                  values.customFields.length - 1 !== index && saveActived
                 }
                 mt="0.5rem"
                 variant={"ghost"}
                 aria-label="delete"
                 icon={
                  <Image
                   src={IconDelete}
                   _disabled={{}}
                   alt="IoMdTrash"
                   w="30px"
                   h="30px"
                  />
                 }
                />
               </GridItem>
              </Grid>
             </>
            );
           }
          )}
         {Array.isArray(dataToSend.customFields) &&
          dataToSend.customFields.length === 0 && (
           <Box marginTop={"5rem"} textAlign="center">
            <Center>
             <Image src={EmptyIcon} />
            </Center>
            <Heading my={4} as={"h2"} fontSize="18px">
             No Data Available
            </Heading>
            <Text>
             Currently, there are no additional fields in this master data.{" "}
             <br />
             To add them, simply click the 'Add Additional Field' button below.
            </Text>
            <Button
             bg="#0072CF"
             w="190px"
             h="45px"
             mt="2rem"
             borderRadius="5px"
             fontSize="16"
             color="white"
             display={values.customFields.length > 0 ? "none" : ""}
             _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
             onClick={() => {
              dispatch(
               masterDataAction.setDataToSend({
                ...values,
                customFields: [
                 ...values.customFields,
                 {
                  id: shortid.generate(),
                  name: "",
                  displayName: "",
                  isRequired: false,
                  dataType: "",
                  isDisplayOnPricing: false,
                  masterDataConfigurationId: "",
                  isDisabled: false,
                 },
                ],
               })
              );
              if (dataToSend.customFields.length === 0) {
               setSaveActived(true);
               setActived(true);
              } else {
               setActived(false);
               setSaveActived(false);
              }
             }}
            >
             Add New Field
            </Button>
           </Box>
          )}
        </Box>
       </CustomField>
      </Box>
     </Form>
    )}
   </Formik>
  </Box>
 );
};

export default Created;
