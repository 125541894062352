import { RootState } from "state/store";
import {
 createRef,
 FC,
 ReactElement,
 useEffect,
 useRef,
 useState,
} from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { Box, Button, Text, Flex, Checkbox } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { TextLoading } from "components/Loading";
import OptipediaToast from "utils/notification-util";
import { actions as annualSpendAction } from "state/annualSpend/slice";

const Case = (): ReactElement => {
 const dispatch = useAppDispatch();
 const router = useHistory();

 let { loading, journeys, selection } = useSelector(
  (state: RootState) => ({
   loading: state.annualSpendSimulations.loading,
   journeys: state.annualSpendSimulations.journeys,
   selection: state.annualSpendSimulations.selection,
  }),
  shallowEqual
 );

 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);

 const setCheckBox = (isChecked: boolean, value: string) => {
  if (isChecked) {
   dispatch(annualSpendAction.setSelection(value));
  } else {
   dispatch(annualSpendAction.removeArraySelection(value));
  }
 };

 const compareAction = () => {
  if (selection.length === 2) {
   dispatch(
    annualSpendAction.compare({
     simulationIds: selection,
    })
   );
   router.push("/annual-spend-simulations/comparation/create?page=summary");
  } else {
   OptipediaToast.Warning(
    "Please select 2 business case before proceeding to the next step."
   );
  }
 };

 return (
  <Box mb="6rem">
   <Box>
    <Flex
     pt="2rem"
     flexWrap="wrap"
     _after={{
      width: "100%",
     }}
    >
     {!loading ? (
      journeys &&
      journeys.length !== undefined &&
      journeys &&
      journeys?.map((dataToCompare: any, index: number) => {
       const ref = createRef<HTMLInputElement>();
       return (
        <Box key={index} padding="1rem">
         <Box fontWeight="700" fontSize="24px">
          {loading ? (
           <TextLoading />
          ) : (
           <>
            <Button
            variant={'outline'}
             isDisabled={
              selection.length == 2
               ? selection.includes(dataToCompare.id)
                 ? false
                 : true
               : false
             }
             name={dataToCompare.name}
             color={selection.includes(dataToCompare.id) ? "white" : "#0072CF"}
             onClick={(e: any) => {
              ref.current?.click();
             }}
             bg={selection.includes(dataToCompare.id) ? "#0072CF" : ""}
             _disabled={{
              bgColor: "#C3C7CD",
              cursor: "not-allowed",
              color: "white",
              border: "solid 1px white",
             }}
            >
             {dataToCompare.name}
            </Button>
            <Checkbox
             ref={ref}
             display="none"
             defaultChecked={selection.includes(dataToCompare.id)}
             value={dataToCompare.id}
             onChange={(val) => {
              setCheckBox(val.target.checked, val.currentTarget.value);
             }}
            >
             {dataToCompare.name}
            </Checkbox>
           </>
          )}
         </Box>
        </Box>
       );
      })
     ) : (
      <TextLoading />
     )}

     {journeys.length === 0 && (
      <Text fontWeight={600}>
       No Business Case available for the selected journey
      </Text>
     )}
    </Flex>
   </Box>
   <Box
    position="fixed"
    bottom="0"
    backgroundColor="#ffffff"
    height="5.5rem"
    w="100%"
    zIndex={2}
   >
    <Flex float="right" mr="20rem">
     <Box mt={4}>
      <Flex alignItems="center" gap="2">
       <>
        <Button
        variant={'outline'}
         onClick={() => router.goBack()}
        >
         Back
        </Button>
        <Button
         onClick={compareAction}
        >
         Next
        </Button>
       </>
      </Flex>
     </Box>
    </Flex>
   </Box>
  </Box>
 );
};

export default Case;
