import { ReactElement, useEffect, useMemo } from "react";
import Layout from "views/Layout";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { actions as annualSpendAction } from "state/annualSpend/slice";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { Box, Heading } from "@chakra-ui/layout";
import debounce from "lodash/debounce";
import { Formik, Form } from "formik";
import {
 Button,
 Flex,
 Spacer
} from "@chakra-ui/react";
import SearchBar from "components/SearchBar";
import ListTable from "./ListTable";
import { actions as demandForecastActions } from "state/demandForecast/slice";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";

const DemandForecast = (): ReactElement => {
 let location = useLocation();
 const history = useHistory();
 const dispatch = useAppDispatch();
 const maxResultCount = 10;
 const {
  loading,
  user,
  journeyLists,
  isEnableThirdPartyStatus,
  thirdParty,
  searchKey,
  thirdPartySelection,
  journeySelection,
 } = useSelector(
  (state: RootState) => ({
   loading: state.productConfigurations.loading,
   user: state.auth.result,
   journeyLists: state.annualSpendSimulations.journey,
   isEnableThirdPartyStatus: state.thirdPartySetting.isEnableThirdPartyStatus,
   thirdParty: state.thirdPartySetting.thirdParty.items,
   searchKey: state.demandForecast.searchQuery,
   thirdPartySelection: state.demandForecast.thirdPartySelection,
   journeySelection: state.demandForecast.journeySelection,
  }),
  shallowEqual
 );

 const debouncedRefreshList = useMemo(
  () =>
   debounce((args) => {
    return dispatch(demandForecastActions.getList(args));
   }, 800),
  [dispatch]
 );

 useEffect(() => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   searchKey: searchKey,
   thirdPartyId: thirdPartySelection,
   productConfigurationId: journeySelection,
  };
  debouncedRefreshList(payload);
 }, [dispatch, searchKey, thirdPartySelection, journeySelection]);

 useEffect(() => {
  if (isEnableThirdPartyStatus) {
   fetchListOf3rdParty(0);
  }
 }, [isEnableThirdPartyStatus]);

 useEffect(() => {
  fetch3rdStatus();
  fetchListOfJourney(0);
 }, [thirdPartySelection, journeySelection]);

 if (location.pathname === "/") {
  return <Redirect to="/demand-forecast" />;
 }

 const handleChangeSearchKey = (event: any) => {
  dispatch(
   demandForecastActions.setSearchQuery({ value: event.currentTarget.value })
  );
 };

 const handleJourneySelection = (value: any) => {
  value !== "all" ? dispatch(demandForecastActions.setJourneySelection(value)) : dispatch(demandForecastActions.setJourneySelection(""));
 };

 const handle3rdPartySelection = (value: any) => {
  value !== "all" ? dispatch(demandForecastActions.setThirdPartySelection(value)) : dispatch(demandForecastActions.setThirdPartySelection(""));
 };

 const fetchListOf3rdParty = (skipCount: number) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: maxResultCount,
  };
  return dispatch(thirdPartySettingAction.getThirdPartyToShow(payload));
 };

 const fetch3rdStatus = () => {
  return dispatch(thirdPartySettingAction.configurationStatus());
 };

 const fetchListOfJourney = (skipCount: number) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: maxResultCount,
  };

  return dispatch(annualSpendAction.journey());
 };

 const optionThirdParty: any = [
  {value: "all", label: "All"}
 ];
 if (thirdParty?.length > 0) {
  thirdParty.map((item: any) =>
   optionThirdParty.push({ label: item.description, value: item.id })
  );
 }
 const optionJourney: any = [
  {value: "all", label: "All"}
 ];
 if (journeyLists?.length > 0) {
    journeyLists.map((item: any) =>
    optionJourney.push({ label: item.journeyName, value: item.id })
  );
 }

 return (
  <Layout>
   <Flex>
    <Heading>Demand Forecast</Heading>
    <Spacer />
    <SearchBar
     value={searchKey}
     onChange={handleChangeSearchKey}
     placeholder={"Search"}
     isLoading={loading}
    />
   </Flex>
   <Box mt="4" color="rgba(26, 26, 26, 0.36)" fontWeight={400} fontSize="16px">
    In this module, you can maintain the demand and order frequency of SKU in
    the current Year,
    <br />
    You can fill in “Reference” with Months, Year or sequence number like 1, 2,
    3, and so on
   </Box>
   <Flex pt="2.5rem">
    <Box m="auto">
     {user.isAdmin && (
      <Flex>
       <Formik initialValues={{ optionJourney: "", optionThirdParty: "" }} onSubmit={() => {}}>
         {({ }) => (
          <Form>
           <Flex>
            <Box mx={1}>
            <SelectWithAutoComplete
            width={200}
             name="optionJourney"
             defaultValue="all"
             onChange={(e: any) => handleJourneySelection(e.value)}
             options={optionJourney}
            />
            </Box>
            {isEnableThirdPartyStatus && (
             <Box mx={1}>
              <SelectWithAutoComplete
              width={200}
              name="optionThirdParty"
              defaultValue="all"
              onChange={(e: any) => handle3rdPartySelection(e.value)}
              options={optionThirdParty}
             />
             </Box>
            )}
           </Flex>
          </Form>
         )}
        </Formik>
       <Button
       onClick={() => history.push("/demand-forecast/upload-dataset")}
       h="50px"
       w="200px"
       borderRadius={"5px"}
       colorScheme={"blue"}
      >
       Upload
      </Button>
      </Flex>
     )}
    </Box>
    <Spacer />
   </Flex>
   <Box pt="2rem" pb="4rem" w="100%">
    <ListTable />
   </Box>
  </Layout>
 );
};

export default DemandForecast;
