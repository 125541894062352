import { ReactElement, useEffect, useState } from "react";
import { Box, Flex, Button, Text, Heading, Spacer } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextInput from "components/TextInput";
import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import Swal from "sweetalert2";
import OptipediaToast from "utils/notification-util";

const ExchangeDetails = (): ReactElement => {
 const { exchangeDataToEdit, searchQuery, currencyId } = useSelector(
  (state: RootState) => ({
   exchangeDataToEdit: state.multiCurrency.exchangeDataToEdit,
   searchQuery: state.multiCurrency.searchQuery,
   currencyId: state.thirdPartySetting.currencyId
  }),
  shallowEqual
 );
 const dispatch = useAppDispatch();
 const fetchList = (payload: any) => {
    dispatch(multiCurrencyAction.listExchange(payload));
   };
   

 return (
  <Box>
   <Heading fontSize={18} pb={5}>
    View Currency
   </Heading>
   <Box
    border="1px solid rgba(26, 26, 26, 0.16)"
    borderRadius="5px"
    p={10}
    mt="1"
    w={"40%"}
   >
    <Box py="5px">
     <Flex>
      <Text color={"gray.500"}>Currency Code</Text>
      <Spacer />
      {currencyId !== exchangeDataToEdit.currencyId && <>
        <EditIcon
       cursor={"pointer"}
       onClick={() => {
        dispatch(multiCurrencyAction.setExchangeView(false));
        dispatch(multiCurrencyAction.setExchangeUpdated(true));
       }}
       mr={5}
      />
      <DeleteIcon
       cursor={"pointer"}
       onClick={() =>
        Swal.fire({
         showCancelButton: true,
         html: "Are you sure you want to delete this Exchange Rate?",
         cancelButtonText: "No",
         confirmButtonText: "Yes",
        }).then(async (result) => {
         if (result.isConfirmed) {
          await dispatch(
           multiCurrencyAction.deletesExchange(exchangeDataToEdit?.id)
          ).then((res: any) => {
           if (res.payload !== undefined) {
            OptipediaToast.Success(
             "The Exchange rate has been removed successfully."
            );
           }
          });
          const payload = {
            skipCount: 0,
            maxResultCount: 10,
            keyword: searchQuery,
           };
           fetchList(payload);
           dispatch(multiCurrencyAction.resetPage(false));
         }
        })
       }
      /></>}
     </Flex>
     <Text py="3">{exchangeDataToEdit.currencyCode}</Text>
    </Box>
    <Box py="3px">
     <Text color={"gray.500"}>Currency Description</Text>
     <Text py="3">{exchangeDataToEdit.description}</Text>
    </Box>
    <Box py="3px">
     <Text color={"gray.500"}>Date From</Text>
     <Text py="3">{exchangeDataToEdit.dateFrom}</Text>
    </Box>
    <Box py="3px">
     <Text color={"gray.500"}>Date To</Text>
     <Text py="3">{exchangeDataToEdit.dateTo}</Text>
    </Box>
    <Box py="3px">
     <Text color={"gray.500"}>Rate</Text>
     <Text py="3">{exchangeDataToEdit.rate}</Text>
    </Box>
    {/* <Box py="3px">
     <Text color={"gray.500"}>Third-Party</Text>
     <Text py="3">{exchangeDataToEdit.thirdParty}</Text>
    </Box>
    <Box py="3px">
     <Text color={"gray.500"}>Journey</Text>
     <Text py="3">{exchangeDataToEdit.journey}</Text>
    </Box> */}
   </Box>
  </Box>
 );
};

export default ExchangeDetails;
