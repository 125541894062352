import { ReactElement, useState, useEffect, useRef } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "state/store";
import { Box, Flex, Heading, Spacer } from "@chakra-ui/layout";
import { isEmpty } from "lodash";
import {
 Image,
 Button,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalBody,
 InputGroup,
 Text,
 Select,
 InputRightElement,
 FormControl,
 FormLabel,
 Center,
 Spinner
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { actions as demandForecast } from "state/demandForecast/slice";
import { actions as annualSpendAction } from "state/annualSpend/slice";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import UploadIcon from "assets/images/uploadIcon.png";
import Dropzone from "react-dropzone";
import Layout from "views/Layout";
import Success from "assets/images/successImage.png";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";

import IconBack from "assets/images/Back.png";

const UploadDataset = (): ReactElement => {
 const history = useHistory();
 const dispatch = useAppDispatch();
 const [isOpenModalSelectJourney, setOpenModalSelectJourney] = useState(false);
 const [isOpenModal, setOpenModal] = useState(false);
 const [modalDialog, setModalDialog] = useState("");
 const [journeySelection, setJourneySelection] = useState("");
 const maxResultCount = 100;

 const { loading, successUpload, journeyLists } = useSelector(
  (state: RootState) => ({
   loading: state.demandForecast.loading,
   successUpload: state.demandForecast.successUpload,
   journeyLists: state.annualSpendSimulations.journey,
  }),
  shallowEqual
 );

 useEffect(() => {
  return history.listen(() => {});
 }, [history]);

 useEffect(() => {
  if (loading) {
   const unloadCallback = (event: any) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
   };

   window.addEventListener("beforeunload", unloadCallback);
   return () => window.removeEventListener("beforeunload", unloadCallback);
  }

  if (successUpload === true) {
   setOpenModal(true);
   setModalDialog("Data has been uploaded successfully");
   dispatch(demandForecast.updateSuccessUpload(false));
  }

  window.scrollTo(0, 0);
 }, [successUpload, loading]);

 useEffect(() => {
  fetchListOfJourney(0);
 }, []);

 const handleDrop = (acceptedFiles: any) => {
  dispatch(
   demandForecast.upload({
    payload: acceptedFiles as any,
    JourneyId: journeySelection,
   })
  ).then(response => {
    if(response.payload){
      history.push("/demand-forecast")
    }
  });
 };

 const onClickDownload = async () => {
  await dispatch(demandForecast.downloadTemplate(journeySelection));
  setOpenModalSelectJourney(false);
 };

 const fetchListOfJourney = (skipCount: number) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: maxResultCount,
  };

  return dispatch(annualSpendAction.journey());
 };

 const handleJourneySelection = (value: any) => {
  setJourneySelection(value);
 };
 const optSelect: any = [];
 journeyLists?.map((item: any, index: number) =>
  optSelect.push({
   value: item.id,
   label: item.journeyName,
  })
 );

 return (
  <Layout>
    <Flex
    onClick={() => {
      dispatch(demandForecast.setSearchQuery(''))
      dispatch(demandForecast.setJourneySelection(''))
      dispatch(demandForecast.setThirdPartySelection(''))
      history.goBack()
    }}
    cursor="pointer"
   >
    <Image src={IconBack} alt="iconBack" />
   <Heading>
    Upload dataset
   </Heading>
   </Flex>
   <Box
    as="p"
    w="700px"
    pt="23px"
    mb="5rem"
   >
    In this page, you can download the demand data template and upload your product demand data into the system
   </Box>
   <Center my="1rem">
    <Formik initialValues={{ optSelect: "", optThirdparty: "" }} onSubmit={() => {}}>
         {({ values }) => (
          <Form>
           <Flex mt={-2}>
            <Box mx={1}>
              <Text fontSize={"16px"} color="#9DA1A7">Journey *</Text>
            <SelectWithAutoComplete
            width={361}
             name="optSelect"
             onChange={(e: any) => handleJourneySelection(e.value)}
             options={optSelect}
            />
            </Box>
           </Flex>
          </Form>
         )}
        </Formik>
   </Center>


    <Box textAlign={"center"}>
     <Button
     variant={'link'}
      isDisabled={journeySelection === ""}
      onClick={() => onClickDownload()}
     >
      Download template
     </Button>
     <Dropzone
      onDrop={handleDrop}
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
     >
      {({ getRootProps, getInputProps }) => (
       <Center>
        <Box
        {...getRootProps({ className: "dropzone" })}
        textAlign={'center'}
        mt={5}
        w="361px"
        h="247px"
        borderRadius="5px"
        border="1px dashed #9DA1A7"
        // box-sizing="border-box"
        display="inline-block"
       >
        <Center><Image src={UploadIcon} w="33.75px"  alt="Bulp" mt={5} /></Center>
        <Flex>
         <Spacer />
         {!loading ? (
          <Box fontSize="18" textColor="#43464B" pt="23px" textAlign="center">
           Drag and drop <br />
           or
          </Box>
         ) : (
          <Box fontSize="16" textColor="#43464B" pt="23px" textAlign="center">
           {/* {fileUpload.arg.payload.file[0].name} */}
           <Spinner mb={'4rem'}  mt={'4rem'} color="#9DA1A7" size='xl' />
          </Box>
         )}
         <Spacer />
        </Flex>

        <Button
         bg="#0072CF"
         mt={5}
         w="200px"
         h="59"
         borderRadius="5px"
         fontSize="18"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
        >
         Select file
         <input {...getInputProps()} />
        </Button>
       </Box>
       </Center>
      )}
     </Dropzone>
    </Box>

   <Modal isOpen={isOpenModal} onClose={() => setOpenModal(false)}>
    <ModalOverlay />
    <ModalContent
     w="33.188rem"
     h="18.rem"
     margin="auto"
     boxShadow="0px 0px 56px rgba(157, 161, 167, 0.37)"
     borderRadius="3.938rem"
     p="1rem"
     mt="10%"
    >
     <ModalHeader fontSize="1.5rem" textAlign="center">
      {modalDialog}
     </ModalHeader>
     <ModalBody>
      <Image display="block" ml="auto" mr="auto" src={Success} alt="Bulp" />
      <Button
       onClick={() => setOpenModal(false)}
       bg="#0072CF"
       display="block"
       mt="1.594rem"
       ml="auto"
       mr="auto"
       w="252px"
       h="59"
       borderRadius="50"
       fontSize="18"
       color="white"
       _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
      >
       Got it
      </Button>
     </ModalBody>
    </ModalContent>
   </Modal>
   <Box
    position="fixed"
    bottom="0"
    backgroundColor="#ffffff"
    height="5.5rem"
    w="100%"
   >
    <Flex float="right" mr="20rem">
     <Box mt={4}>
      <Flex alignItems="center" gap="2">
       <>
        <Button
         bg="#0072CF"
         w="202px"
         h="59"
         borderRadius="50"
         fontSize="18"
         color="white"
         _hover={{ bgColor: "#bcbfc4" }}
         onClick={() => {
      dispatch(demandForecast.setJourneySelection(''))
      dispatch(demandForecast.setThirdPartySelection(''))
      history.goBack()}
    }
        >
         Back
        </Button>
       </>
      </Flex>
     </Box>
    </Flex>
   </Box>
  </Layout>
 );
};

export default UploadDataset;
