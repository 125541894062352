import React from 'react'
import { AuthConsumer } from 'providers/authProvider'

export const SigninOIDC = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback()
      return <span>loading</span>
    }}
  </AuthConsumer>
)
