import { RootState } from "state/store";
import { FC, useEffect, useState, useRef } from "react";
import {
 Button,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 FormControl,
 useDisclosure,
 Input,
} from "@chakra-ui/react";
import { Formik } from "formik";
import OptipediaToast from "utils/notification-util";

import { useSelector, shallowEqual } from "react-redux";
import { useAppDispatch } from "hooks";
import { useHistory } from "react-router-dom";
import { actions as simulateAction } from "state/simulation/slice";

const SaveBusiness: FC = () => {
 const dispatch = useAppDispatch();
 const router = useHistory();
 const { isOpen, onOpen, onClose } = useDisclosure();

 let { describeChangePayload } = useSelector(
  (state: RootState) => ({
   describeChangePayload: state.simulationAggregate.describeChangePayload,
  }),
  shallowEqual
 );

 return (
  <>
   <Button
    bg="#0072CF"
    w="260px"
    h="59"
    borderRadius="50"
    fontSize="16"
    color="white"
    _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
    onClick={onOpen}
   >
    Save business case
   </Button>
   <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
     <ModalHeader textAlign="left">Business Case Name</ModalHeader>
     <ModalCloseButton />
     <Formik
      enableReinitialize
      initialValues={{
       businessCaseName: "",
       costAnalysises: "",
       level: "",
       components: "",
      }}
      onSubmit={async (values) => {
       try {
        const response: any = await dispatch(
         simulateAction.saveBusiness({
          payload: {
           costAnalysises: describeChangePayload.payload.costAnalysises,
           level: describeChangePayload.payload.level,
           components: describeChangePayload.payload.components,
           businessCaseName: values.businessCaseName,
          },
         })
        );
        if (!response.error) {
         router.push("/simulations");
         dispatch(simulateAction.clear());
        }
       } catch (err: any) {
        OptipediaToast.Error(err.message);
       }
      }}
     >
      {({
       values,
       errors,
       touched,
       handleChange,
       handleBlur,
       handleSubmit,
       isSubmitting,
      }) => (
       <form onSubmit={handleSubmit}>
        <ModalBody pb={6}>
         <FormControl>
          <Input
           name="businessCaseName"
           value={values.businessCaseName}
           onChange={handleChange}
           bg="#E6EAF1"
           size="lg"
          />
         </FormControl>
        </ModalBody>
        <Button
         bg="#0072CF"
         w="380px"
         h="59"
         ml="2rem"
         mb="1rem"
         type="submit"
         borderRadius="50"
         fontSize="16px"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
        >
         Complete
        </Button>
       </form>
      )}
     </Formik>
    </ModalContent>
   </Modal>
  </>
 );
};
export default SaveBusiness;
