import { useEffect, useRef, useState } from "react";
import Layout from "views/Layout";
import ReactFlow from "react-flow-renderer";
import { Box, Heading, VStack, Image, Text } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import PencilIcon from "assets/images/pencil.png";
import AddIcon from "assets/images/24_14.jpg";
import { actions as myJourneyActions } from "state/myJourney/slice";
import ModalAddEditJourney from "views/Journeys/ModalAddEdit";

export default function Home() {
 const router = useHistory();
 const [isOpenModal, setOpenModal] = useState(false);
 const [modalType, setModalType] = useState("");
 const [modalId, setModalId] = useState("");
 const [currentStage, setCurrentStage] = useState<number>(0);
 const [getDataByClickToOpenModal, setDataByClickToOpenModal] = useState({
  identityName: "",
  identityCode: "",
  journeyCode: "",
  journeyName: "",
  id: "",
  labelName: "",
  labelCost: "",
  labelTotalCost: "",
  totalCostFormulaExpression: "",
  type: 10,
  definedData: "",
  labelDescription: "",
  customFields: [],
  productLabelName: "",
  isEnableThirdParty: false,
  sizeUnit: "",
  sizeUnitId: "",
  thirdPartyLabelName: "",
  isPercentage: false,
  isMainCostDriver: false,
  volumeType: 10,
  stage: 0,
  isMaintainWithMasterData: false,
  masterDataConfigurationId: "",
  masterDataConfiguration: {},
 });
 const [arrayElement, setArrayElement] = useState<any>([]);
 const messagesEndRef = useRef<null | HTMLDivElement>(null);
 const dispatch = useAppDispatch();
 const { location } = router;
 const { search } = location;
 const params = useParams();
 const { levelResponse, loading } = useSelector(
  (state: RootState) => ({
   levelResponse: state.myJourney.levelResponse,
   loading: state.myJourney.loading,
  }),
  shallowEqual
 );

 useEffect(() => {
  if (params) {
   dispatch(myJourneyActions.getById(params));
  }
 }, []);

 useEffect(() => {
  var allCostDrivers =
   Array.isArray(levelResponse?.result?.costDrivers) &&
   levelResponse?.result?.costDrivers;
  if (levelResponse?.result) {
   const newArray: any = [];
   const costDrivers: any = [];

   const arrayParentCostDriverIsNull: any = [];

   const functionIfHaveCostDriver = (
    costDrivers: any,
    parentId: string,
    stage: number,
    positionParentY: number,
    positionXParent: number
   ) => {
    let newStage = stage + 1;
    var positionY = 300 + positionParentY;
    var totalWidthBox = costDrivers.length * 200; // salah
    var startPositionXChild =
     positionXParent -
     totalWidthBox / 2 -
     (costDrivers.length == 1 ? 200 : costDrivers.length * 50);
    var sortedCostDrivers: any = [];
    costDrivers.map((costDriver: any) => {
     if (
      costDriver.parentCostDriver != null &&
      costDriver.parentCostDriver.id == parentId
     ) {
      sortedCostDrivers.push(costDriver);
     }
    });

    sortedCostDrivers.sort((prev: any, next: any) => {
     return prev.sequence - next.sequence;
    });

    sortedCostDrivers.map((item: any, index: number) => {
     const {
      labelDescription,
      id,
      identityName,
      identityCode,
      labelName,
      parentCostDriver,
      sequence,
      costDrivers,
      customFields,
      labelCost,
      labelTotalCost,
      totalCostFormulaExpression,
      isPercentage,
      isMainCostDriver,
      isMaintainWithMasterData,
      masterDataConfigurationId,
      masterDataConfiguration,
     } = item;
     var totalChild = costDrivers.length;

     if (stage > 1) {
      startPositionXChild =
       totalChild == 0 ? startPositionXChild + 100 : startPositionXChild;
     }

     var positionLength = (totalChild == 0 ? 1 : totalChild) * 200;

     var positionX = startPositionXChild + positionLength;
     startPositionXChild = startPositionXChild + positionLength + 40;

     let hideAddButton = false;

     if (levelResponse?.result?.level == 10 && stage < 2) hideAddButton = true;
     else if (levelResponse?.result?.level == 20 && stage < 3)
      hideAddButton = true;
     else if (levelResponse?.result?.level == 30 && stage == 1) {
      if (item.costDrivers.length > 0) hideAddButton = true;
     } else if (levelResponse?.result?.level == 30 && stage == 2) {
      hideAddButton = true;
     }

     const itemObject = {
      labelDescription,
      id,
      identityName,
      identityCode,
      labelName,
      labelCost,
      labelTotalCost,
      totalCostFormulaExpression,
      parentCostDriver,
      sequence,
      costDrivers,
      totalCostDrivers: costDrivers.length,
      haveCostDriver: costDrivers.length > 0,
      x: positionX,
      y: positionY,
      no: sequence,
      isLastStage: hideAddButton,
      stage: newStage,
      customFields,
      isPercentage,
      isMainCostDriver,
      isMaintainWithMasterData,
      masterDataConfigurationId,
      masterDataConfiguration,
     };

     arrayParentCostDriverIsNull.push(itemObject);
     newArray.push({
      id: `e${parentId}-${id}`,
      source: parentId,
      target: id,
      type: "step",
     });
     if (costDrivers.length > 0) {
      functionIfHaveCostDriver(
       costDrivers,
       id,
       newStage,
       positionY - 150,
       positionX
      );
     }
    });
   };

   var mainCostDrivers = allCostDrivers
    .filter((i: any) => i.parentCostDriver == null)
    .sort((objA: any, objB: any) => (objA.sequence < objB.sequence ? -1 : 1));

   var startPositionX = 0;
   mainCostDrivers.map((costDriver: any) => {
    const {
     labelDescription,
     id,
     identityName,
     identityCode,
     labelName,
     labelCost,
     labelTotalCost,
     totalCostFormulaExpression,
     parentCostDriver,
     sequence,
     costDrivers,
     customFields,
     isPercentage,
     isMainCostDriver,
     isMaintainWithMasterData,
     masterDataConfigurationId,
     masterDataConfiguration,
    } = costDriver;

    var totalChild_1 = costDrivers.length;
    var childWidth =
     totalChild_1 == 0 ? 200 : totalChild_1 * (startPositionX > 0 ? 280 : 200); // salah

    var positionX = startPositionX + childWidth / 2 + 100;
    startPositionX = startPositionX + childWidth + 40;

    const itemObject = {
     labelDescription,
     id,
     identityName,
     identityCode,
     labelName,
     labelCost,
     labelTotalCost,
     totalCostFormulaExpression,
     parentCostDriver,
     x: positionX,
     y: 150,
     sequence,
     costDrivers,
     haveCostDriver: costDrivers.length > 0,
     parentId: levelResponse?.result?.id,
     no: sequence,
     stage: 1,
     isLastStage: levelResponse?.result?.level === 10,
     customFields,
     isPercentage,
     isMainCostDriver,
     isMaintainWithMasterData,
     masterDataConfigurationId,
     masterDataConfiguration,
    };

    arrayParentCostDriverIsNull.push(itemObject);
    newArray.push({
     id: `e${levelResponse?.result?.id}-${id}`,
     source: parentCostDriver === null && levelResponse?.result?.id,
     target: id,
     type: "step",
    });

    if (costDrivers.length > 0) {
     functionIfHaveCostDriver(costDrivers, id, 1, 0, positionX);
    }
   });

   Array.isArray(allCostDrivers) &&
    allCostDrivers.map((item: any) => {
     costDrivers.push(item);
    });

   var maxPositionXStage1 = 0;
   arrayParentCostDriverIsNull.map((item: any) => {
    if (maxPositionXStage1 == 0) {
     maxPositionXStage1 = item.x;
    }
    if (item.x > maxPositionXStage1) {
     maxPositionXStage1 = item.x;
    }
   });

   var productPositionX = (maxPositionXStage1 + 200) / 2;
   const level = levelResponse.result.level;
   newArray.push({
    id: levelResponse?.result?.id,
    type: "input",
    style: {
     border: "1px solid #777",
     padding: 32,
     width: 200,
     height: 100,
    },
    data: {
     label: (
      <Box textAlign="center">
       <Text fontSize="13px" fontWeight="bold">
        {levelResponse?.result?.journeyName}
       </Text>
       <Image
        w="32px"
        position="absolute"
        right="-15px"
        top="14px"
        src={PencilIcon}
        alt="Edit"
        onClick={() => {
         modalDetail(levelResponse?.result, "Main");
        }}
       />
       {levelResponse?.result?.level !== 0 && (
        <Image
         w="32px"
         position="absolute"
         right="5.1rem"
         bottom="-40px"
         src={AddIcon}
         alt="Add"
         onClick={() => {
          setOpenModal(true);
          const newResult: any = {
           journeyName: "",
           journeyCode: "",
           identityCode: "",
           identityName: "",
           id: levelResponse?.result?.id,
           labelName: "",
           type: 10,
           definedData: "",
           customFields: [],
           productLabelName: "",
           isPercentage: false,
           isMainCostDriver: false,
           volumeType: 10,
           isEnableThirdParty: false,
           sizeUnit:"",
           sizeUnitId: "",
           thirdPartyLabelName: "",
           labelCost: "",
           labelTotalCost: "",
           totalCostFormulaExpression: "",
           isMaintainWithMasterData: false,
           masterDataConfigurationId: "",
           masterDataConfiguration: {},
           stage: 0,
          };
          setDataByClickToOpenModal(newResult);
          dispatch(myJourneyActions.setIsEditModalJourney(false));
         }}
        />
       )}
      </Box>
     ),
    },
    position: {
     x: levelResponse?.result.level === 0 ? 200 : productPositionX,
     y: 0,
    },
   });

   arrayParentCostDriverIsNull.map((result: any, index: number) => {
    newArray.push({
     id: result?.id,
     type: "selectorNode",
     style: {
      border: "1px solid #777",
      padding: 32,
      width: 200,
      height: 100,
     },
     data: {
      label: (
       <Box textAlign="center" key={index}>
        <Text fontSize="13px" fontWeight="bold">
         {result?.identityName}
        </Text>
        <Image
         w="32px"
         position="absolute"
         right="-15px"
         top="14px"
         src={PencilIcon}
         alt="Edit"
         onClick={() => {
          modalDetail(result, "Cost Driver");
         }}
        />
        {!result.isLastStage && (
         <Image
          w="32px"
          position="absolute"
          right="5.1rem"
          bottom="-40px"
          src={AddIcon}
          alt="Add"
          onClick={() => {
           setOpenModal(true);
           const newResult: any = {
            identityName: "",
            identityCode: "",
            journeyCode: "",
            journeyName: "",
            id: result?.id,
            labelName: "",
            type: 10,
            definedData: "",
            customFields: [],
            productLabelName: "",
            isPercentage: false,
            isMainCostDriver: false,
            volumeType: 10,
            labelCost: "",
            labelTotalCost: "",
            totalCostFormulaExpression: "",
            isEnableThirdParty: false,
            isMaintainWithMasterData: false,
            masterDataConfigurationId: "",
            masterDataConfiguration: {},
            sizeUnit: "",
            sizeUnitId: "",
            thirdPartyLabelName: "",
            stage: result?.stage === 2 ? 3 : 0,
           };
           setDataByClickToOpenModal(newResult);

           dispatch(myJourneyActions.setIsEditModalJourney(false));
          }}
         />
        )}
       </Box>
      ),
     },
     position: { x: result?.x, y: result?.y },
    });
    newArray.push({
     id: `e${1}-${result?.id}`,
     source: "1",
     target: result?.id,
     type: "step",
    });
   });

   setArrayElement(newArray);
  }
 }, [levelResponse]);

 const modalDetail = (data: any, type: string) => {
  setOpenModal(true);
  setModalType(type);
  setModalId(data.id);
  setCurrentStage(data.stage);

  dispatch(myJourneyActions.setIsEditModalJourney(true));
  setDataByClickToOpenModal(data);
 };

 const scrollToBottom = () => {
  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
 };

 useEffect(() => {
  scrollToBottom();
 }, [search]);

 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);

 const onNodeDragStop = (event: any, node: any) => {
  event.stopPropagation();
  event.preventDefault();
 };

 const startLevelPosisition = () => {
  let top = "12rem";
  switch (levelResponse?.result?.level) {
   case 10:
    top = "21rem";
    break;
   case 20:
    top = "30.2rem";
    break;
   case 30:
    top = "40rem";
    break;
   default:
    break;
  }
  return top;
 };

 return (
  <Layout>
   <VStack
    align="flex-start"
    minHeight="100vh"
    padding={["7", "10"]}
    ref={messagesEndRef}
   >
    <Heading as="h1" fontSize="48px" marginBottom="5" pb="5">
     {levelResponse?.result?.identityName}
    </Heading>
    <Box pt="rem" w="100%" height={"100vh"}>
     <ReactFlow
      elements={arrayElement}
      onNodeDragStop={onNodeDragStop}
     ></ReactFlow>
    </Box>
   </VStack>
   <ModalAddEditJourney
    productConfiguration={getDataByClickToOpenModal}
    isOpen={isOpenModal}
    onClose={() => {
     setOpenModal(false);
     dispatch(myJourneyActions.getById(params));
    }}
   />
  </Layout>
 );
}
