import moment from  "moment";

declare global {
  interface DateConstructor {
    toString(date:Date, dateFormat?: string): string;
    toLocale(date:Date, dateFormat?: string): string;
  }
}

Date.toString = function (date:Date, dateformat:string = "DD MMM YYYY HH:mm:ss"): string  
{
  return moment(date).format(dateformat);
};
Date.toLocale = function (date:Date, dateformat:string = "DD MMM YYYY"): string  
{
  return moment(date).format(dateformat);
};

export {}
