import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";
import parameterDto from "state/masterData/dtos/parameterDtos";

export const masterDataList = createAsyncThunk(
  "masterDataList",
  async (parameterDto: parameterDto) => {
    if (parameterDto.configurationId) {
      return await HttpService.get(`MasterData`, { params: parameterDto });
    }

  }
);
export const masterDataListDetail = createAsyncThunk(
  "masterDataListDetail",
  async (params: any) => {
    return await HttpService.get(
      `MasterData/${params.configurationId}/${params.id}`
    );
  }
);
export const deleteMasterDataDetail = createAsyncThunk(
  "deleteMasterDataDetail",
  async (payload: any) => {
    const response: any = await HttpService.delete(
      `MasterData/${payload.configurationId}/${payload.id}`);
    return { success: true, payload: payload }
  }
);
export const createMasterData = createAsyncThunk(
  "createMasterData",
  async (payload: any) => {
    const data = payload as any;
    const response: any = await HttpService.post(`MasterData`, data);
    return response;
  }
);
export const updateMasterData = createAsyncThunk(
  "updateMasterData",
  async ({ payload, id }: any) => {
    const response: any = await HttpService.put(`MasterData/${id}`, payload);
    return response;
  }
);
export const downloadMasterData = createAsyncThunk(
  "downloadMasterData",
  async (configurationId: any) => {
    const response: any = await HttpService.get(`MasterData/${configurationId}/UploadTemplate`, {
      responseType: "blob",
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new window.Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Optipedia Master Data Upload.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  });
export const uploadMasterData = createAsyncThunk(
  "uploadMasterData",
  async ({ payload }: any) => {
    const config = {
      headers: {
        notUseToast: true,
      },
    };
    const formData = new FormData();
    const file = payload.file[0] as any;
    const configurationId = payload.configurationId;
    formData.append("file", file);
    formData.append("configurationId", configurationId);
    const response: any = await HttpService.post(
      `MasterData/${configurationId}/Upload`,
      formData,
      config
    );
    return response;
  }
);
export const downloadMasterDataModule = createAsyncThunk(
  "downloadMasterDataModule",
  async ({configurationId, thirdPartId}: any) => {
    const response: any = await HttpService.get(`MasterData/${configurationId}/Report`, {
      params : {thirdPartId },
      responseType: "blob",
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new window.Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Optipedia Master Data List Download.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  });
