import { FC, useEffect } from "react";
import { Image, Box, Button, Stack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Category from "assets/images/gif.gif";

const LevelOverview: FC = () => {
 const router = useHistory();
 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);
 return (
  <Box mt="1.5rem" p="5">
   <img src={Category} alt="Category" width="100%" />
   <Box pt="2rem" textAlign="right" w="100%">
    <Stack spacing={4} direction="row" align="center" float="right">
     {/* <Button bg="#0072CF" w="202px" h="59" borderRadius="50" fontSize="24" color="white" _hover={{bgColor:"rgba(70, 171, 249, 0.8)", color:"white"}} onClick={()=> router.goBack()}>Back</Button> */}
     <Button
      onClick={() => router.push("/journeys/create?page=level-details")}
     >
      Next
     </Button>
    </Stack>
   </Box>
  </Box>
 );
};

export default LevelOverview;
