import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";
import "utils/number-utils";

export const pricingCostSummaryChart = createAsyncThunk(
 "costAnalysis/pricingCostSummaryChart",
 async ({ id, thirdPartyId, status }: any, { rejectWithValue }) => {
  const response: any = await HttpService.get(
   `CostAnalysis/${id}/CostBreakdown/${status}`,
   { params: { thirdPartyId } }
  );
  return response.result;
 }
);

export const pricingCostSummary = createAsyncThunk(
 "costAnalysis/pricingCostSummary",
 async ({ id, status, volumeId }: any) => {
    
  const response: any = await HttpService.get(`CostAnalysis/${id}/${status}`, {
    params: {
      volumeId: volumeId
    }
  });
  return response.result;
 }
);
export const deletePricingCostSummary = createAsyncThunk(
 "costAnalysis/deletePricingCostSummary",
 async ({ id }: any) => {
  const response: any = await HttpService.delete(`CostAnalysis/${id}`);
  return response.result;
 }
);
export const pricingThirdParty = createAsyncThunk(
 "costAnalysis/pricingThirdParty",
 async ({ id }: any) => {
  const response: any = await HttpService.get(
   `CostAnalysis/${id}/ThirdParties`
  );
  return response.result;
 }
);

export const changeDefaultVolume = createAsyncThunk(
 "costAnalysis/changeDefaultVolume",
 async (params: any) => {
  const response: any = await HttpService.put(
   "CostAnalysis/ChangeDefaultVolume",
   params
  );
  return response.result;
 }
);

export const getPricingStatusAndVolume = createAsyncThunk(
  "costAnalysis/getPricingStatusAndVolume",
  async ({ id }: any) => {
   const response: any = await HttpService.get(
    `CostAnalysis/${id}/volumes`
   );
   return response.result;
  }
 );