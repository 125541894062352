import { createSlice } from "@reduxjs/toolkit";
import {
  pricingCostSummary,
  pricingThirdParty,
  changeDefaultVolume,
  pricingCostSummaryChart,
  deletePricingCostSummary,
  getPricingStatusAndVolume
} from "state/pricings/costSummary/thunk";

interface InitialState {
  costSummaryById: any;
  pricingThirdParty: any;
  detailVolume: any;
  costSummaryChart: any;
  loading: boolean;
  loadingChart: boolean;
  loadingChangeDefault: boolean;
  error: unknown;
  pricingDetail: any;
}

const initialState: InitialState = {
  costSummaryById: {},
  pricingThirdParty: {},
  detailVolume: {},
  costSummaryChart: {},
  loading: false,
  loadingChart: false,
  loadingChangeDefault: false,
  error: null,
  pricingDetail: {}
};

const pricingCostSummarySlice = createSlice({
  name: "pricingCostSummary",
  initialState,
  reducers: {
    clear: () => initialState,
    setDetailVolume: (state, action) => {
      const { value } = action.payload;
      state.detailVolume = value;
    },
    setCostSummaryById: (state, action) => {
      state.costSummaryById = action.payload
    },
    resetCostSummaryById: (state) => {
      state.costSummaryById = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(pricingCostSummary.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(pricingCostSummary.fulfilled, (state, action) => {
      state.costSummaryById = action.payload;
      state.loading = false;
    });

    builder.addCase(pricingCostSummary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(pricingThirdParty.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(pricingThirdParty.fulfilled, (state, action) => {
      state.pricingThirdParty = action.payload;
      state.loading = false;
    });

    builder.addCase(pricingThirdParty.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(changeDefaultVolume.pending, (state) => {
      state.loadingChangeDefault = true;
      state.error = null;
    });

    builder.addCase(changeDefaultVolume.fulfilled, (state, action) => {
      state.costSummaryById = action.payload;
      state.loadingChangeDefault = false;
    });

    builder.addCase(changeDefaultVolume.rejected, (state, action) => {
      state.loadingChangeDefault = false;
      state.error = action.payload;
    });

    builder.addCase(pricingCostSummaryChart.pending, (state) => {
      state.loadingChart = true;
      state.error = null;
    });

    builder.addCase(pricingCostSummaryChart.fulfilled, (state, action) => {
      state.costSummaryChart = action.payload;
      state.loadingChart = false;
    });

    builder.addCase(pricingCostSummaryChart.rejected, (state, action) => {
      state.loadingChart = false;
      state.error = action.payload;
    });

    builder.addCase(getPricingStatusAndVolume.pending, (state) => {
      state.loadingChart = true;
      state.error = null;
    });

    builder.addCase(getPricingStatusAndVolume.fulfilled, (state, action) => {
      state.pricingDetail = action.payload;
      state.loadingChart = false;
    });

    builder.addCase(getPricingStatusAndVolume.rejected, (state, action) => {
      state.loadingChart = false;
      state.error = action.payload;
    });
  },
});

export const actions = {
  ...pricingCostSummarySlice.actions,
  pricingCostSummary,
  pricingThirdParty,
  changeDefaultVolume,
  pricingCostSummaryChart,
  deletePricingCostSummary,
  getPricingStatusAndVolume
};

export const reducer = pricingCostSummarySlice.reducer;
