import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";
import qs from "qs";

// gak kepakai
export const getList = createAsyncThunk(
    "costAnalysis/getList",
    async (params) => {
        const response: any = await HttpService.get("CostAnalysis", { params });
        return response.result;
    }
);

export const generateReport = createAsyncThunk(
    "productConfigurations/generateReport",
    async ({ JourneyId, ApprovalStatus }: any) => {
        return await HttpService.get("/CostAnalysis/PricingSummaryReport", {
            params: {
                JourneyId,
                ApprovalStatus
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
            responseType: "blob",
        }).then((response: any) => {
            const url = window.URL.createObjectURL(new window.Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Optipedia Pricing Summary Report.xlsx");
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }
);

export const getDetailById = createAsyncThunk(
    "costAnalysis/getDetailById",
    async ({ id, status }: any) => {
        const response: any = await HttpService.get(`CostAnalysis/${id}/${status}`);
        return response.result;
    }
);

export const transactionViewDetail = createAsyncThunk(
    "costAnalysis/transactionViewDetail",
    async ({ id }: any) => {
        const response: any = await HttpService.get(`CostAnalysis/${id}`);
        return response.result;
    }
);

export const getDetailCostAnalysisVolume = createAsyncThunk(
    "costAnalysis/getDetailCostAnalysisVolume",
    async (params: any) => {
        const response: any = await HttpService.get(
            `CostAnalysis/${params.costAnalysisId}/volume/${params.perVolumeId}`
        );
        return response.result;
    }
);

export const changeDefaultVolume = createAsyncThunk(
    "costAnalysis/changeDefaultVolume",
    async (params) => {
        const response: any = await HttpService.put(
            "CostAnalysis/ChangeDefaultVolume",
            params
        );
        return response.result;
    }
);

export const checkIsExistingProductId = createAsyncThunk(
    "costAnalysis/checkSsExistingProductId",
    async (name: string) => {
        const response: any = await HttpService.get(`CostAnalysis/${name}/isExist`);
        return response.result;
    }
);
