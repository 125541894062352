import { NavItemProps } from "components/NavItem";
import { Journey, Pricing, Approval, Demand, Simulation, MasterData, Settings, MassUpdate } from "components/Icons";

const menu: NavItemProps[] = [
  {
    label: "Journey",
    url: "/journeys",
    icon:Journey,
  },
  {
    label: "Pricing",
    url: "/pricings",
    icon:Pricing,
  },
  {
    label: "Approval",
    url: "/approval",
    icon:Approval
  },
  {
    label: "Demand",
    url: "/demand-forecast",
    icon: Demand
  },
  {
    label: "Simulation",
    url: "/simulations",
    icon:Simulation,
    submenu : [
      {
        label : 'By SKU',
        url: "/simulation-by-sku",
      },
      // {
      //   label : 'By Aggregate',
      //   url: "/simulations",
      // },
      {
        label : 'By Annual Spend',
        url: "/annual-spend-simulations",
      }
    ]
  },
  {
    label: "Master Data",
    url: "/master-data",
    icon:MasterData
    // submenu : [
    //   {
    //     label : 'General',
    //     url: "/general",
    //   },
    //   {
    //     label : 'Cost Driver',
    //     url: "/cost-driver",
    //   }
    // ]
  },
  // {
  //   label: "Mass Update",
  //   url: "/mass-update",
  //   icon:MassUpdate
  // },
  {
    label: "Settings",
    url: "/settings",
    icon:Settings
  },
  // //
   
  
  // {
  
  
  
];

export default menu;
