import { ReactElement } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { NumberLoading, TextLoading } from "components/Loading";
import { Box, Heading, Grid, Flex, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import Layout from "views/Layout";
import SearchBar from "components/SearchBar";
import ListTable from "./ListTable";
import { RootState } from "state/store";
import { actions as simulateAction } from "state/simulation/slice";

const Simulations = (): ReactElement => {
 const dispatch = useAppDispatch();
 const { loading, searchQuery } = useSelector(
  (state: RootState) => ({
   loading: state.simulationAggregate.loading,
   searchQuery: state.simulationAggregate.searchQuery,
  }),
  shallowEqual
 );

 const handleChangeSearchBar = (event: any) => {
  dispatch(
   simulateAction.setSearchQuery({
    value: event.currentTarget.value,
   })
  );
 };

 return (
  <Layout>
   <Heading as="h1" fontSize="48px">
    Simulation
   </Heading>
   <Box>
    <Flex>
     <Box
      as="p"
      color="white"
      w="662px"
      fontSize="18"
      textColor="#43464B"
      pt="23px"
     >
      In this module, you can perform what-if simulations on the prices and{" "}
      <br />
      quantities of various components. There are four types of aggregate
      simulations:
     </Box>
     <Box 
      m="auto" pt="1.5rem">
      <SearchBar
       value={searchQuery}
       onChange={handleChangeSearchBar}
       placeholder={"Search"}
       isLoading={loading}
      />
     </Box>
    </Flex>
   </Box>

   <Box pt="2rem" pb="1rem">
    <Grid templateColumns="repeat(4, 1fr)" gap={2}>
     <Box
      h="202px"
      borderRadius="40px"
      pl="24.49px"
      pt="32px"
      pb="52px"
      pr="25.51"
      bg="#F7F9FD"
      mr="19.39px"
     >
      <Box as="p" pb="3px" fontSize="36" textColor="#9DA1A7" fontWeight="bold">
       {loading ? <NumberLoading /> : "01"}
      </Box>
      <Box as="p">
       {loading ? <TextLoading /> : "Simulation by percentage over all levels"}
      </Box>
     </Box>
     <Box
      h="202px"
      borderRadius="40px"
      pl="24.49px"
      pt="32px"
      pb="52px"
      pr="25.51"
      bg="#F7F9FD"
      mr="19.39px"
     >
      <Box as="p" pb="3px" fontSize="36" textColor="#9DA1A7" fontWeight="bold">
       {loading ? <NumberLoading /> : "02"}
      </Box>
      <Box as="p">
       {loading ? <TextLoading /> : "Simulation by percentage for one level"}
      </Box>
     </Box>
     <Box
      h="202px"
      borderRadius="40px"
      pl="24.49px"
      pt="32px"
      pb="52px"
      pr="25.51"
      bg="#F7F9FD"
      mr="19.39px"
     >
      <Box as="p" pb="3px" fontSize="36" textColor="#9DA1A7" fontWeight="bold">
       {loading ? <NumberLoading /> : "03"}
      </Box>
      <Box as="p">
       {loading ? (
        <TextLoading />
       ) : (
        "Simulation by unit price and quantity (Level 2 only)"
       )}
      </Box>
     </Box>
     <Box
      h="202px"
      borderRadius="40px"
      pl="24.49px"
      pt="32px"
      pb="52px"
      pr="25.51"
      bg="#F7F9FD"
      mr="19.39px"
     >
      <Box as="p" pb="3px" fontSize="36" textColor="#9DA1A7" fontWeight="bold">
       {loading ? <NumberLoading /> : "04"}
      </Box>
      <Box as="p">{loading ? <TextLoading /> : "Combination simulation"}</Box>
     </Box>
    </Grid>
   </Box>
   <Flex mt="2rem" justifyContent="center">
    <Box>
     <Link to="/simulations/create?page=select-journey">
      <Button
       zIndex="9"
       bg="#0072CF"
       w="320px"
       h="79"
       borderRadius="50"
       fontSize="24"
       color="white"
       _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
      >
       Start simulation
      </Button>
     </Link>
    </Box>
   </Flex>

   <Box width="80%" mt="4rem" mb="2rem">
    <Flex>
     <Text fontSize="24px" fontWeight={600} p="1rem">
      My simulations
     </Text>
     <Box m="auto">
      <Link to="/simulations/comparasion/create?page=select-journey">
       <Button
        bg="#0072CF"
        w="202px"
        h="59px"
        borderRadius="50"
        fontSize="18px"
        color="white"
        _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
       >
        Compare
       </Button>
      </Link>
     </Box>
    </Flex>
   </Box>
   <Box mt="2rem">
    <ListTable />
   </Box>
  </Layout>
 );
};

export default Simulations;
