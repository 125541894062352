import { RootState } from "state/store";
import { ReactElement, useEffect } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { Box, Flex, Spacer, Stack } from "@chakra-ui/layout";
import { Image, Button, Center, Spinner } from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import OptipediaToast from "utils/notification-util";
import { actions as annualSpendAction } from "state/annualSpend/slice";
import UploadIcon from "assets/images/uploadIcon.png";

const DataUpload = (): ReactElement => {
 const dispatch = useAppDispatch();
 const router = useHistory();
 const {
  loading,
  loadingDownload,
  journeyId,
  successUpload,
  fileUpload,
  uploadResponse,
 } = useSelector(
  (state: RootState) => ({
   loading: state.annualSpendSimulations.loading,
   loadingDownload: state.annualSpendSimulations.download,
   successUpload: state.annualSpendSimulations.upload,
   fileUpload: state.annualSpendSimulations.file,
   uploadResponse: state.annualSpendSimulations.uploadResponse,
   journeyId: state.annualSpendSimulations.journeyId,
  }),
  shallowEqual
 );

 useEffect(() => {
  if (loading) {
   const unloadCallback = (event: any) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
   };

   window.addEventListener("beforeunload", unloadCallback);
   return () => window.removeEventListener("beforeunload", unloadCallback);
  }
  window.scrollTo(0, 0);
 }, [successUpload, loading]);

 const onClickDownload = () => {
  dispatch(annualSpendAction.download({ id: journeyId }));
 };
 const handleDrop = (acceptedFiles: any) => {
  dispatch(
   annualSpendAction.upload({
    payload: {
     journeyId: journeyId,
     file: acceptedFiles as any,
    },
   })
  );
  dispatch(annualSpendAction.updateFileUpload(acceptedFiles as any));
 };

 const handleNextStep = () => {
  if (successUpload) {
   dispatch(
    annualSpendAction.components({
     payload: {
      journeyId,
      costAnalysises: uploadResponse,
     },
    })
   );
   router.push("/annual-spend-simulations/create?page=select-component-step-1");
  } else {
   OptipediaToast.Warning(
    "Please upload the template before proceeding to the next step."
   );
  }
 };

 return (
  <Box w="100%" mb="6rem">
   
   <Box 
    textAlign={'center'}>
      <Button
       variant={'link'}
       onClick={() => onClickDownload()}
       isLoading={loadingDownload}
       loadingText="Downloading..."
      >
       Download template
      </Button>
      <Dropzone
      onDrop={handleDrop}
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
     >
      {({ getRootProps, getInputProps }) => (
       <Center>
        <Box
        {...getRootProps({ className: "dropzone" })}
        textAlign={'center'}
        mt={5}
        w="361px"
        h="247px"
        borderRadius="5px"
        border="1px dashed #9DA1A7"
        // box-sizing="border-box"
        display="inline-block"
       >
        <Center><Image src={UploadIcon} w="33.75px"  alt="Bulp" mt={5} /></Center>
        <Flex>
         <Spacer />
         {!loading ? (
          <Box fontSize="18" textColor="#43464B" pt="23px" textAlign="center">
           {fileUpload?.arg?.payload ? fileUpload?.arg?.payload?.file[0]?.name : <>Drag and drop <br/> or</>}
          </Box>
         ) : (
          <Box fontSize="16" textColor="#43464B" pt="23px" textAlign="center">
           <Spinner mb={'4rem'}  mt={'4rem'} color="#9DA1A7" size='xl' />
          </Box>
         )}
         <Spacer />
        </Flex>

        <Button
         bg="#0072CF"
         mt={5}
         w="200px"
         h="59"
         borderRadius="5px"
         fontSize="18"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
        >
         Select file
         <input {...getInputProps()} />
        </Button>
       </Box>
       </Center>
      )}
     </Dropzone>
    </Box>
   <Box
    position="fixed"
    bottom="0"
    backgroundColor="#ffffff"
    height="5.5rem"
    w="100%"
    zIndex={2}
   >
    <Flex float="right" mr="20rem">
     <Box mt={4}>
      <Flex alignItems="center" gap="2">
       <>
        <Button
        variant={"outline"}
         onClick={() => router.goBack()}
        >
         Back
        </Button>
        <Button
         onClick={handleNextStep}
        >
         Next
        </Button>
       </>
      </Flex>
     </Box>
    </Flex>
   </Box>
  </Box>
 );
};

export default DataUpload;
