import Table from "components/Table/Table";
import Pagination from "components/Pagination";
import { useAppDispatch, usePrevious } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { useHistory } from "react-router-dom";
import { TableLoading } from "components/Loading";
import {
 Box,
 Image,
 Button,
 Stack,
 Stat,
 StatHelpText,
 StatArrow,
 Flex
} from "@chakra-ui/react";
import { FC, useEffect, useState, useMemo } from "react";
import OptipediaToast from "utils/notification-util";
import { actions as annualSpendAction } from "state/annualSpend/slice";
import Swal from "sweetalert2";
import IconDelete from "assets/images/Delete.png";
import debounce from "lodash/debounce";
import "utils/date-utils";
import Undraw from "assets/images/undraw_revenue.png";
import EmptyState from "components/EmptyState";
import { isEmpty } from "lodash";

const TABLE_HEADERS = [
 { name: "name", label: "Business case" },
 { name: "simulatedBy", label: "Simulated by" },
 { name: "simulationDate", label: "Data created" },
 { name: "differenceInPercentage", label: "% change" },
];

const SimulationTable: FC = () => {
 const dispatch = useAppDispatch();
 const history = useHistory();
 const maxResultCount = 10;
 const [pageNumber, setPageNumber] = useState(1);

 const {
  simulation,
  loading,
  totalPage,
  totalCountSimulation,
  searchKey,
  selection,
  compare,
 } = useSelector(
  (state: RootState) => ({
   simulation: state.annualSpendSimulations.simulation.items,
   totalPage: Math.ceil(
    state.annualSpendSimulations.simulation.totalCount / maxResultCount
   ),
   totalCountSimulation: state.annualSpendSimulations.simulation.totalCount,
   loading: state.annualSpendSimulations.loading,
   searchKey: state.annualSpendSimulations.searchQuery,
   selection: state.annualSpendSimulations.selection,
   compare: state.annualSpendSimulations.compare,
  }),
  shallowEqual
 );

 const fetchListOfSimulation = (skipCount: number, searchKey: string) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: maxResultCount,
   searchKey: searchKey,
  };

  return dispatch(annualSpendAction.simulation(payload));
 };

 const previousSearchKey = usePrevious(searchKey, "");
 const debouncedRefreshList = useMemo(
  () =>
   debounce((searchKey: string) => {
    return fetchListOfSimulation(0, searchKey);
   }, 800),
  [dispatch]
 );

 useEffect(() => {
  fetchListOfSimulation(0, searchKey);
 }, []);

 useEffect(() => {
  if (searchKey !== previousSearchKey) {
   debouncedRefreshList(searchKey);
  }
 }, [searchKey]);

 const deleteSimulation = async (simulationId: string) => {
  await dispatch(annualSpendAction.deletes({ id: simulationId })).then(
   (res) => {
    if (res.payload !== undefined) {
     OptipediaToast.Success("The Simulation has been removed successfully.");
    }
   }
  );

  var skipCount = 0;
  if (simulation?.length - 1 > 0) {
   skipCount = (pageNumber - 1) * maxResultCount;
   setPageNumber(pageNumber);
  } else {
   skipCount = (pageNumber - 1) * maxResultCount;
   setPageNumber(pageNumber - 1);
  }

  fetchListOfSimulation(skipCount, searchKey);
 };

 const handleChangePagination = (pageNumber: number) => {
  setPageNumber(pageNumber);
  var skipCount = (pageNumber - 1) * maxResultCount;
  fetchListOfSimulation(skipCount, searchKey);
 };

 const setCheckBox = (isChecked: boolean, value: string) => {
  if (isChecked) {
   dispatch(annualSpendAction.setSelection(value));
  } else {
   dispatch(annualSpendAction.removeArraySelection(value));
  }
 };

 const compareAction = () => {
  dispatch(
   annualSpendAction.compare({
    simulationIds: selection,
   })
  );
 };

 let listSimulation: any = [];
 simulation &&
  simulation.map((item: any) => {
   const {
    name,
    simulationDate,
    simulatedBy,
    currentTotalSpend,
    whatIfTotalSpend,
    difference,
    differenceInPercentage,
    id,
   } = item;
   listSimulation.push({
    name,
    simulatedBy,
    simulationDate: Date.toString(simulationDate),
    differenceInPercentage: (
     <Stat>
      <StatHelpText>
       <StatArrow
        type={item.differenceInPercentage >= 0 ? "increase" : "decrease"}
        color={item.differenceInPercentage >= 0 ? "red" : "green"}
       />
       {(differenceInPercentage * 100)?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
       })}
       %
      </StatHelpText>
     </Stat>
    ),
    currentTotalSpend,
    whatIfTotalSpend,
    difference,
    id,
   });
  });

 if (loading) {
  return <TableLoading />;
 }

 return (
  <>
   {(!loading && listSimulation.length) > 0 ? (
    <Box>
        <Flex mb="1rem">
     <Button
      mr={1.5}
      onClick={() =>
       history.push("/annual-spend-simulations/create?page=select-journey")
      }
     >
      Start simulation
     </Button>
     <Button
      mx={1.5}
      variant={"outline"}
      onClick={() =>
       history.push(
        "/annual-spend-simulations/comparation/create?page=select-journey"
       )
      }
     >
      Compare
     </Button>
    </Flex>
     <Table
      loading={loading}
      key={listSimulation.index}
      columnHeaders={TABLE_HEADERS}
      data={listSimulation}
      actionButtons={(simulationId: string) => (
       <Stack spacing={4} direction="row" align="center" float="right">
        <Image
         src={IconDelete}
         cursor="pointer"
         onClick={async () => {
          Swal.fire({
           showCancelButton: true,
           html: "Are you sure you want to delete this Simulation?",
           confirmButtonText: "Yes",
           cancelButtonText: "No",
          }).then(async (result) => {
           if (result.isConfirmed) {
            deleteSimulation(simulationId);
           }
          });
         }}
         alt="IoMdTrash"
         mr="1rem"
         w="24px"
         h="24px"
        />
        <Button
        variant={'link'}
         onClick={() =>
          history.push(`/annual-spend-simulations/${simulationId}`)
         }
        >
         View
        </Button>
       </Stack>
      )}
     />
     <Box w="100%" mt="1rem" p="20px">
      {listSimulation.length > 0 && (
       <Pagination
        onChange={handleChangePagination}
        totalRowsPage={maxResultCount}
        pageNumber={totalPage}
        currentPage={pageNumber}
        totalAllPage={totalCountSimulation}
       />
      )}
     </Box>
    </Box>
   ) : (
    <EmptyState isSearch={!isEmpty(searchKey)}>
     <Button
      onClick={() =>
       history.push("/annual-spend-simulations/create?page=select-journey")
      }
      zIndex="9"
     >
      Start simulation
     </Button>
    </EmptyState>
   )}
  </>
 );
};

export default SimulationTable;
