import { ReactElement, useEffect } from "react";
import { Button, Image } from "@chakra-ui/react";
import Swal from "sweetalert2";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";

import { actions as pricingCostDriverAction } from "state/pricings/costDrivers/slice";
import DeleteIcon from "assets/images/Delete.png";

const DeleteRow = ({
 costDriver,
 index,
 paramName,
 arrayHelpers,
 row,
 onClick,
}: any): ReactElement => {
 const dispatch = useAppDispatch();
 const {
  detailPerVolumeId,
  temporaryDataCostDriver,
  originalData,
  dataToSendUpdates,
  isEditOpen,
  dataToSend,
  tmpChangeRow
 } = useSelector(
  (state: RootState) => ({
   isEditing: state.pricingCostDrivers.isEditing,
   detailPerVolumeId: state.pricingCostDrivers.detailPerVolumeId.result,
   temporaryDataCostDriver: state.pricingCostDrivers.temporaryDataCostDriver,
   originalData: state.pricingCostDrivers.originalCostDrivers,
   constDriversOri: state.pricingCostDrivers.constDriversOri,
   dataToSendUpdates: state.pricingCostDrivers.dataToSend.updates,
   dataToSendadds: state.pricingCostDrivers.dataToSend.adds,
   dataToSend: state.pricingCostDrivers.dataToSend,
   dataToSendDelete: state.pricingCostDrivers.dataToSend.deletes,
   tmpChangeRow: state.pricingCostDrivers.tmpChangeRow,
   isEditOpen: state.pricingCostDrivers.isEditOpen,
  }),
  shallowEqual
 );
 useEffect(() => {}, [isEditOpen]);

 let notAllowPointer = false;
 if (costDriver.data.length > 1) {
  costDriver.data.map((item: any) => {
   if (item.unitCost === 0) {
    notAllowPointer = true;
   }
  });
 }
 const isNewRow = costDriver.data.find((item: any) => row.constDriverId === item.constDriverId)
 return (
  <Button
   colorScheme="blue"
   aria-label="delete"
   disabled={
    notAllowPointer ||
    costDriver.data.length === 1
   }
   onClick={async () => {
    if (!notAllowPointer || (isEditOpen && !row.isNew)) {
     Swal.fire({
      showCancelButton: true,
      html: "Are you sure you want to remove this record?",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
     }).then(async (result) => {
      if (result.isConfirmed) {
       dispatch(pricingCostDriverAction.setNullCustomFields());
       await dispatch(pricingCostDriverAction.setIsEditOpen(true));
       if (isNewRow === undefined || !isNewRow.isNew) {
        const arry = [];
        arry.push({
         name: "unitCost",
         value: costDriver.data[index].unitCost,
         component: `${costDriver.data[index].name} - Cost`,
        });
        const customFields = costDriver.data[index].customFields;
        if (customFields.length > 0) {
         customFields.map((itm: any) => {
          arry.push({
           name: itm.name,
           value: itm.value,
           component: `${costDriver.data[index].name} - ${itm.name}`,
          });
         });
        }

        const indexSimulateBySku = temporaryDataCostDriver.findIndex(
         (el: any) => el.name == paramName
        );

        const findIndex = temporaryDataCostDriver[
         indexSimulateBySku
        ]?.data.findIndex(
         (el: any) => el.costDriverId == costDriver.costDriverId
        );

        const dataTmp =
         temporaryDataCostDriver[indexSimulateBySku]?.data[findIndex].data;
        const dataOri = originalData[indexSimulateBySku]?.data[findIndex].data;

        var difference = dataOri.filter((x: any) => !dataTmp.includes(x));

        const findTrueIndex = difference.findIndex(
         (item: any) => item.id === dataTmp[index].id
        );

        let toDelete: any = {
         costAnalysisPerVolumeId: detailPerVolumeId.id,
         costDriverId: costDriver.data[index].id,
         name: costDriver.data[index].name,
         parentCostDriverId: costDriver.costDriverId,
         tmpChangeToDeletes: arry,
         index: findIndex,
         indexToDelete: findTrueIndex !== -1 ? index : index + 1,
         toDeleteUpdate: [],
         indexSimulateBySku: indexSimulateBySku,
         isHaveDataOrigin: findTrueIndex !== -1,
         masterDataId: costDriver.data[index].masterDataId,
        };

        const arraytoDeleteUpdate: any = [];
        await dispatch(
         pricingCostDriverAction.setTemporaryData(
          temporaryDataCostDriver[indexSimulateBySku]?.data[findIndex]?.data[
           index
          ]
         )
        );

        await dispatch(
         pricingCostDriverAction.setIndexCostDriversSimulateBySku([
          indexSimulateBySku,
          findIndex,
          index,
         ])
        );

        const isNew = costDriver.data[index].isNew;

        if (isNew) {
         dispatch(pricingCostDriverAction.setIsEditOpen(false));
         dataToSendUpdates.map((item: any, index: number) => {
          if (item.costDriverId === toDelete.costDriverId) {
           arraytoDeleteUpdate.push(index);
          }
         });
         // toDelete.indexToDelete
         toDelete.toDeleteUpdate = arraytoDeleteUpdate;

         await dispatch(
          pricingCostDriverAction.directSetDeleteOneToTemporaryDataCostDriver(
           toDelete
          )
         );
         await dispatch(pricingCostDriverAction.setNeedReFetch(true));
         onClick()
         return arrayHelpers.remove(index);
        } else {
         await dispatch(
          pricingCostDriverAction.directSetDeleteOneToTemporaryDataCostDriver(
           toDelete
          )
         );
         dispatch(pricingCostDriverAction.setIsEditOpen(false));
        }

        // arrayHelpers.remove(index);
        let objectAssignToDelete = Object.assign({}, dataToSend);
        let toDeletesPush = [];
        if (dataToSend.deletes.length > 0) {
         dataToSend.deletes.map((item: any) => {
          toDeletesPush.push(item);
         });
        }
        toDeletesPush.push({
         costAnalysisPerVolumeId: toDelete.costAnalysisPerVolumeId,
         costDriverId: toDelete.costDriverId,
         name: toDelete.name,
         parentCostDriverId: toDelete.parentCostDriverId,
        });
        objectAssignToDelete["deletes"] = toDeletesPush;
        onClick();
       } else {
        const indexSimulateBySku = temporaryDataCostDriver.findIndex(
         (el: any) => el.name == paramName
        );
        const findIndexOneCostDriverOriginal =
         temporaryDataCostDriver[indexSimulateBySku] &&
         temporaryDataCostDriver[indexSimulateBySku]?.data &&
         temporaryDataCostDriver[indexSimulateBySku]?.data.findIndex(
          (item: any) => item.identityName === costDriver.identityName
         );

        const findIndex = temporaryDataCostDriver[indexSimulateBySku].data[findIndexOneCostDriverOriginal].data.length
        const newRow = temporaryDataCostDriver[indexSimulateBySku].data[findIndexOneCostDriverOriginal].data[index]
        if (newRow.name !== "" && newRow.description !== "") {
         const indexNumberToDelete = {
          index1: indexSimulateBySku,
          index2: findIndexOneCostDriverOriginal,
          index3: index,
         };

         await dispatch(
          pricingCostDriverAction.directSetNullTemporaryDataCostDriver(newRow.id)
         );
         await dispatch(
          pricingCostDriverAction.deleteTemporaryDataCostDriver(
           indexNumberToDelete
          )
         );
         await dispatch(pricingCostDriverAction.deleteOneTmpChangeRow(newRow.id))
        }
        
        dispatch(pricingCostDriverAction.setIsEditOpen(false));
        await dispatch(pricingCostDriverAction.setNeedReFetch(true));
        onClick();
       }
      }
     });
    }
   }}
  >
   Delete
  </Button>
 );
};

export default DeleteRow;
