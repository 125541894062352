import { ReactElement } from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, Flex, Heading } from "@chakra-ui/layout";
import {
 Breadcrumb,
 BreadcrumbItem,
 BreadcrumbLink,
 Button,
} from "@chakra-ui/react";
import Layout from "views/Layout";
import { ChevronRightIcon } from "@chakra-ui/icons";
import PredefinedThirdPartyTable from "./ListTable";
import PredefinedThirdPartyCreated from "./CreatedData";
import queryString from "query-string";
import PredefinedThirdPartyEdited from "./EditedData";
import { useAppDispatch } from "hooks";
import {actions as thirdPartySettingAction} from 'state/settings/slice'
const PredefinedThirdParty = (): ReactElement => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { location } = history;
    const { search } = location;
    const query = queryString.parse(search);
    
 return (
  <Layout>
    <Heading mb="2rem">
    Settings
   </Heading>
   <Box>
    <Breadcrumb
     borderRadius="20px"
     w="50%"
     h="50"
     spacing="8px"
     separator={<ChevronRightIcon color="gray.500" />}
    >
     <BreadcrumbItem onClick={() => {
       dispatch(thirdPartySettingAction.setActiveButton('Third-Party Configuration'))
       history.push(`/settings`)}} color="gray.500">
      <BreadcrumbLink fontWeight={"600"}>
       Third-Party Configuration
      </BreadcrumbLink>
     </BreadcrumbItem>
     <BreadcrumbItem>
      <BreadcrumbLink
       fontWeight={"600"}
      >
       Third-Party List
      </BreadcrumbLink>
     </BreadcrumbItem>
    </Breadcrumb>
   </Box>
   {query.page === "created" ? <Heading>Create New</Heading> : query.page === "edited" ? <Heading>Edit data</Heading> : <Box pb="2rem">
    <Link to="/settings/third-party?page=created">
     <Button>
      Create New
     </Button>
    </Link>
   </Box>}
   {query.page === "created" ? 
   <PredefinedThirdPartyCreated /> : 
   query.page === "edited" ? <PredefinedThirdPartyEdited /> : <PredefinedThirdPartyTable />}
  </Layout>
 );
};

export default PredefinedThirdParty;
