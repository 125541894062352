import { createSlice } from '@reduxjs/toolkit'

import { upload, donloadTemplate, histories } from 'state/dataLoading/thunk'

interface historyTs {
  userName : string
  userFullName: string
  startUploadTime : string
  endUploadTime : string
}
interface InitialState {
  profile: Record<string, unknown>
  loading: boolean
  loadingDownload : boolean
  error: unknown
  histories : historyTs
}

const initialState: InitialState = {
  profile: {},
  loading: false,
  loadingDownload: false,
  error: null,
  histories : {
    userName : "",
    userFullName : "",
    startUploadTime : "",
    endUploadTime : ""
  }
}

const dataLoadingSlice = createSlice({
  name: 'dataloading',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(upload.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(upload.fulfilled, (state, action) => {
      state.profile = action.payload
      state.loading = false
    })

    builder.addCase(upload.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    builder.addCase(donloadTemplate.pending, (state) => {
      state.loadingDownload = true
      state.error = null
    })

    builder.addCase(donloadTemplate.fulfilled, (state, action) => {
      state.loadingDownload = false
    })

    builder.addCase(donloadTemplate.rejected, (state, action) => {
      state.loadingDownload = false
      state.error = action.payload
    })

    builder.addCase(histories.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(histories.fulfilled, (state, action) => {
      state.histories = action.payload
      state.loading = false
    })

    builder.addCase(histories.rejected, (state, action) => {
      state.loading = false
    })
  }
})

export const actions = {
  ...dataLoadingSlice.actions,
  upload,
  donloadTemplate,
  histories
}

export const reducer = dataLoadingSlice.reducer
