import { ReactElement, useState } from "react";
import { useAppDispatch } from "hooks";
import { Box, Flex, HStack, Button } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { actions as pricingCostSummaryAction } from "state/pricings/costSummary/slice";
import "utils/number-utils";
import Swal from "sweetalert2";

interface VolumeSectionProps {
 volumes: { volume: number; isDefault: boolean; id: string }[];
}

const VolumeSection = ({ volumes }: VolumeSectionProps): ReactElement => {
 const dispatch = useAppDispatch();
 const location = useLocation();
 const searchParams = new URLSearchParams(location.search);
 const pricingId = location.pathname.split("/")[3];
 const [getVolume, setVolume] = useState<number>();

 const { isEditing, isSimulate, loadingChangeDefault, status } = useSelector(
  (state: RootState) => ({
   isEditing: state.pricingCostDrivers.isEditing,
   isSimulate: state.pricingCostDrivers.isSimulate,
   loadingChangeDefault: state.pricingCostSummary.loadingChangeDefault,
   status: state.pricingCostDrivers.status
  }),
  shallowEqual
 );

 const onClickDefault = async (volume: number, id: string) => {
  setVolume(volume);
  const payloadChangeVolume = {
   costAnalysisId: pricingId,
   volumeId: id,
   thirdPartyId: searchParams.get("thirdPartyId") || null,
  };
  Swal.fire({
   showCancelButton: true,
   html:
    "Are you sure to change the Default Volume to <b>" +
    String.toFormatSeparator(volume, 0) +
    "</b> ?",
   confirmButtonText: "Yes",
   cancelButtonText: "No",
  }).then(async (result) => {
   if (result.isConfirmed) {
    await dispatch(
     pricingCostSummaryAction.changeDefaultVolume(payloadChangeVolume)
    );
    const payloadCostSummary = {
     id: pricingId,
     status: status
    };
    dispatch(pricingCostSummaryAction.pricingCostSummary(payloadCostSummary));
   }
  });
 };

 return (
  <Box>
   <Box as="p" color="#000000" fontWeight="bold" ml="1rem" mb="1rem">
    Cost Analysis per Volume:
   </Box>
   <Flex justifyContent="space-between" mb="2rem" alignItems="center">
    <HStack spacing="1 px" textAlign="center">
     {Array.isArray(volumes) &&
      volumes.length > 0 &&
      volumes.map(({ volume, isDefault, id }) => {
       return (
        <Button
         key={volume}
         isDisabled={isEditing || isSimulate}
         isLoading={volume === getVolume && loadingChangeDefault}
         w="131px"
         h="38px"
         bg={isDefault ? "#0072CF" : "#F7F9FD"}
         cursor="pointer"
         onClick={!isDefault ? () => onClickDefault(volume, id) : () => null}
         color={isDefault ? "#FFFFFF" : "#7B7F85"}
         border="1px solid #B9DCFA"
         borderRadius="50px"
         textAlign="center"
         pt="0.3rem"
         ml="1rem"
         fontWeight="bold"
        >
         {String.toFormatSeparator(volume, 0)}
        </Button>
       );
      })}
    </HStack>
   </Flex>
  </Box>
 );
};

export default VolumeSection;
