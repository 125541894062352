import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";

export const replaceCostAnalysisVolume = createAsyncThunk(
 "copyAndReplace/replaceCostAnalysisVolume",
 async (objects: any) => {
  return await HttpService.put("CostAnalysis/Replace", objects);
 }
);

export const copyCostAnalysisVolume = createAsyncThunk(
    "copyAndReplace/copyCostAnalysisVolume",
    async (objects: any) => {
     return await HttpService.put("CostAnalysis/Copy", objects);
    }
   );
