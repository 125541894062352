import { ReactElement } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { Text, Box, Badge } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useLocation, useHistory } from "react-router-dom";
import "utils/number-utils";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import { actions as pricingCostDriverActionBySKU } from "state/pricings/costDrivers/slice";

const VolumeSelection = (): ReactElement => {
 const location = useLocation<{
  status: number;
  volumeId: string;
  isEditing: boolean;
  isApproval: boolean;
 }>();
 const history = useHistory();
 const dispatch = useAppDispatch();
 const pricingId = location.pathname.split("/")[3];
 const { detailPerVolumeId } = useSelector(
  (state: RootState) => ({
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId,
  }),
  shallowEqual
 );
 const {
  volumes,
  productName,
  costAnalysisPerVolume,
  isEditing,
  isSimulate,
  isCopy,
  isReplace,
  costAnalysis,
  volumeId,
 } = useSelector(
  (state: RootState) => ({
   volumes: state.pricingCostSummary.costSummaryById.volumes,
   productName: state.pricingCostSummary.costSummaryById.name,
   costAnalysisPerVolume: state.editPricingCostDrivers.detailPerVolumeId.result,
   isEditing: state.editPricingCostDrivers.isEditing,
   isSimulate: state.editPricingCostDrivers.isSimulate,
   isReplace: state.copyAndReplaceCostDrivers.isReplace,
   isCopy: state.copyAndReplaceCostDrivers.isCopy,
   costAnalysis: state.pricingCostSummary.costSummaryById,
   volumeId: state.editPricingCostDrivers.volumeId,
  }),
  shallowEqual
 );

//  console.log("volumes", volumes)
 let findThirdParty: string = "";
 let thirdPartyId: any = "";
 if (volumes && volumes.length > 0) {
  const responseVolumeId = location?.state.volumeId || volumeId;
  const haveThirdParty = volumes.find((item: any) => {
  //  console.log("asd", item.id, responseVolumeId);
   if (item.id === responseVolumeId) {
    return item;
   } else {
    if (item.thirdParty?.id === responseVolumeId) {
     return item;
    }
   }
  });
  if (haveThirdParty) {
   findThirdParty = haveThirdParty?.thirdParty?.code;
   thirdPartyId = haveThirdParty?.thirdParty?.id
  }
 }

 let statusName;
 switch (location.state.status) {
  case 0:
   statusName = "Draft";
   break;
  case 1:
   statusName = "Pending Approval";
   break;
  case 2:
   statusName = "Valid";
   break;
  case 3:
   statusName = "Obsolete";
 }
 const optVolume: any = [];
 volumes?.length > 0 &&
  volumes.map(({ volume, id, thirdParty }: any) =>
   optVolume.push({
    label: thirdParty
     ? `${thirdParty?.code} ${String.toFormatSeparator(volume, 0)}`
     : String.toFormatSeparator(volume, 0),
    value: id,
   })
  );

 const handleChange = async (event: any) => {
  const volumeIds = event.value;
  dispatch(pricingCostDriverActionBySKU.setVolumeId(volumeIds));
  dispatch(pricingCostDriverAction.setCostAnalysisPerVolumeId(volumeIds));
  if (volumeIds) {
   const payload = {
    costAnalysisId: pricingId,
    perVolumeId: volumeIds,
    thirdPartyId: thirdPartyId
   };
   await dispatch(pricingCostDriverAction.getDetailCostDriversVolume(payload));
  }
  history.push({
   pathname: `${location.pathname}`,
   state: {
    status: location.state.status,
    volumeId: volumeIds,
   },
  });
 };

 return (
  <>
   {!isCopy && !isReplace ? (
    <Box ml="1rem" mt="3rem">
     <Text fontWeight={600} mb="3">
      You have selected
     </Text>
     <Box
      border={"1px solid #D7DBE2"}
      fontWeight={600}
      p="1rem"
      borderRadius="5px"
     >
      <Text>
       {productName} {findThirdParty && <> - {findThirdParty} </>}
      </Text>

      <Formik initialValues={{ value: "" }} onSubmit={() => {}}>
       {({ values }) => (
        <Form>
         <SelectWithAutoComplete
          width={200}
          isDisabled={isEditing || isSimulate}
          defaultValue={
           location.state?.volumeId || detailPerVolumeId?.result?.id
          }
          name="volume"
          onChange={(e) => handleChange(e)}
          options={optVolume}
         />
        </Form>
       )}
      </Formik>
      {statusName !== "Pending Approval" && (
       <Text py="1">Version : {costAnalysisPerVolume?.version}</Text>
      )}
      <Badge mt="2" colorScheme={"yellow"}>
       {statusName}
      </Badge>
     </Box>
    </Box>
   ) : (
    <Box
     width={250}
     border={"1px solid #D7DBE2"}
     fontWeight={600}
     p="1rem"
     borderRadius="5px"
    >
     {isCopy ? (
      <Box>
       <Text>{`Copied from : ${costAnalysis.name.toUpperCase()}`}</Text>
       <Text>{`Volume : ${costAnalysisPerVolume?.volume}`}</Text>
      </Box>
     ) : (
      <Box>
       <Text>{`Replace from : ${costAnalysis.name.toUpperCase()}`}</Text>
       <Text>{`Volume : ${costAnalysisPerVolume?.volume}`}</Text>
      </Box>
     )}
    </Box>
   )}
  </>
 );
};

export default VolumeSelection;
