import { RootState } from "state/store";
import { ReactElement, useEffect } from "react";
import { useAppDispatch } from "hooks";
import { TextLoading } from "components/Loading";
import { useSelector, shallowEqual } from "react-redux";
import {
 Box,
 Button,
 Heading,
 Radio,
 Stack,
 RadioGroup,
 Flex,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import OptipediaToast from "utils/notification-util";
import { actions as annualSpendAction } from "state/annualSpend/slice";
import { isEmpty } from "lodash";
const Journey = (): ReactElement => {
 const dispatch = useAppDispatch();
 const router = useHistory();

 let { loading, journey, journeyId } = useSelector(
  (state: RootState) => ({
   loading: state.annualSpendSimulations.loading,
   journey: state.annualSpendSimulations.journey,
   journeyId: state.annualSpendSimulations.journeyId,
  }),
  shallowEqual
 );
 useEffect(() => {
  window.scrollTo(0, 0);
  dispatch(annualSpendAction.journey());
 }, []);

 const onClickRadioButton = (journeyID: string) => {
  dispatch(annualSpendAction.setJourneyId(journeyID));
 };

 const handleBack = () => {
  dispatch(annualSpendAction.clear());
  router.goBack();
 };

 const handleNextStep = () => {
  if (!isEmpty(journey)) {
   if (!isEmpty(journeyId)) {
    router.push("/annual-spend-simulations/create?page=select-dataset");
   } else {
    OptipediaToast.Error(
     "Please select 1 journey before proceeding with the next step."
    );
   }
  } else {
   OptipediaToast.Error(
    "Please maintain at least 1 journey in the tenant and 1 valid pricing in it before proceeding with the simulation."
   );
  }
 };

 return (
  <Box w="100%" mb="6rem">
   {!loading && isEmpty(journey) ? (
    <Box
     border="1px solid #D7DBE2"
     borderRadius="5px"
     p="2rem"
     mt="1rem"
     textAlign="center"
     fontWeight="700"
     fontSize="18px"
    >
     “There is no journey available for the simulation. Please maintain at least
     1 journey with a valid pricing in the system before proceeding with the
     simulation.“
    </Box>
   ) : (
    <Heading my="1rem">
     What journey do you want to perform the simulation on?
    </Heading>
   )}
   <RadioGroup
    onChange={(journeyID) => annualSpendAction.setJourneyId(journeyID)}
    value={journeyId}
   >
    {!loading ? (
     journey.length !== undefined &&
     journey.map(
      (
       item: {
        id: string;
        journeyCode: string;
        journeyName: string;
        levelName: string;
       },
       index: number
      ) => (
       <Stack spacing={4} key={index} direction="row" pt="12px" align="center">
        <Box
         border="1px solid #D7DBE2"
         borderRadius="5px"
         minW="20%"
         padding="1rem"
         fontWeight="600"
        >
         <Radio value={item.id} onChange={() => onClickRadioButton(item.id)}>
          {item.journeyName} / {item.journeyCode}
         </Radio>
        </Box>
       </Stack>
      )
     )
    ) : (
     <TextLoading />
    )}
    <Box
     position="fixed"
     bottom="0"
     backgroundColor="#ffffff"
     height="5.5rem"
     w="100%"
    >
     <Flex float="right" mr="20rem">
      <Box mt={4}>
       <Flex alignItems="center" gap="2">
        <>
         <Button
          variant={"outline"}
          onClick={handleBack}
         >
          Back
         </Button>
         <Button
          onClick={handleNextStep}
         >
          Next
         </Button>
        </>
       </Flex>
      </Box>
     </Flex>
    </Box>
   </RadioGroup>
  </Box>
 );
};

export default Journey;
