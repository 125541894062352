import { createSlice } from "@reduxjs/toolkit";
import {
 masterDataList,
 createMasterData,
 updateMasterData,
 masterDataListDetail,
 deleteMasterDataDetail,
 downloadMasterData,
 uploadMasterData,
 downloadMasterDataModule
} from "state/masterData/thunk";

interface InitialState {
 lists: any;
 detail: any
 searchQuery: string;
 loading: boolean;
 loadingDelete: boolean;
 error: unknown;
 dataToSend: any;
 isEditing: boolean;
 masterDataId: string;
 isView: boolean;
 isCreated: boolean;
 title: string
}
const initialState: InitialState = {
 lists: {},
 detail: {},
 searchQuery: "",
 loading: false,
 loadingDelete: false,
 error: null,
 dataToSend: {},
 isEditing: false,
 isCreated: false,
 masterDataId: "",
 isView: false,
 title: ""
};

const masterDataSlice = createSlice({
 name: "masterData",
 initialState,
 reducers: {
  clear: () => initialState,
  setSearchQuery: (state, action) => {
   const { value } = action.payload;
   state.searchQuery = value;
  },
  setEditing: (state, action) => {
   state.isEditing = action.payload;
  },
  setView: (state, action) => {
    state.isView = action.payload;
   },
   setCreated: (state, action) => {
     state.isCreated = action.payload;
    },
  setMasterDataID: (state, action) => {
    state.masterDataId = action.payload;
   },
  setMasterDataTitle: (state, action) => {
    state.title = action.payload;
   },
  setDataToSend: (state, action) => {
   state.dataToSend = action.payload;
  },
  resetData: (state) => {
    state.detail = {}
  }
 },

 extraReducers: (builder) => {
  //  GET ALL
  builder.addCase(masterDataList.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(masterDataList.fulfilled, (state, action) => {
   state.loading = false;
   state.lists = action.payload;
  });

  builder.addCase(masterDataList.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //  CREATE
  builder.addCase(createMasterData.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(createMasterData.fulfilled, (state, action) => {
   state.loading = false;
  });

  builder.addCase(createMasterData.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //  EDIT
  builder.addCase(updateMasterData.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(updateMasterData.fulfilled, (state, action) => {
   state.loading = false;
  });

  builder.addCase(updateMasterData.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  //  DETAILS
  builder.addCase(masterDataListDetail.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(masterDataListDetail.fulfilled, (state, action) => {
   state.loading = false;
   state.detail = action.payload;
  });

  builder.addCase(masterDataListDetail.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //  DELETE
  builder.addCase(deleteMasterDataDetail.pending, (state) => {
    state.loadingDelete = true;
    state.error = null;
   });
 
   builder.addCase(deleteMasterDataDetail.fulfilled, (state) => {
    state.loadingDelete = false;
   });
 
   builder.addCase(deleteMasterDataDetail.rejected, (state, action) => {
    state.loadingDelete = false;
    state.error = action.payload;
   });
   //  DOWNLOAD
   builder.addCase(downloadMasterData.pending, (state) => {
     state.loading = true;
     state.error = null;
    });
  
    builder.addCase(downloadMasterData.fulfilled, (state) => {
     state.loading = false;
    });
  
    builder.addCase(downloadMasterData.rejected, (state, action) => {
     state.loading = false;
     state.error = action.payload;
    });
    //  UPLOAD
    builder.addCase(uploadMasterData.pending, (state) => {
      state.loading = true;
      state.error = null;
     });
   
     builder.addCase(uploadMasterData.fulfilled, (state) => {
      state.loading = false;
     });
   
     builder.addCase(uploadMasterData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
     }); 
     //  DOWNLOAD LIST
     builder.addCase(downloadMasterDataModule.pending, (state) => {
       state.loading = true;
       state.error = null;
      });
    
      builder.addCase(downloadMasterDataModule.fulfilled, (state) => {
       state.loading = false;
      });
    
      builder.addCase(downloadMasterDataModule.rejected, (state, action) => {
       state.loading = false;
       state.error = action.payload;
      });
 },
});

export const actions = {
 ...masterDataSlice.actions,
 masterDataList,
 createMasterData,
 updateMasterData,
 masterDataListDetail,
 deleteMasterDataDetail,
 downloadMasterData,
 uploadMasterData,
 downloadMasterDataModule
};

export const reducer = masterDataSlice.reducer;
