import { ReactElement, useEffect } from "react";
import Layout from "views/Layout";
import { Link, useLocation } from "react-router-dom";
import { Box, Heading, Text } from "@chakra-ui/layout";
import { useHistory } from "react-router-dom";
import { Flex, Center, Button } from "@chakra-ui/react";
import { useAppDispatch } from "hooks";
import { actions as tenantConfActions } from "state/settings/slice";
import { actions as approvalAction } from "state/approval/slice";

const AnnualSpendImpact = (): ReactElement => {
 const router = useHistory();
 const location = useLocation();
 const dispatch = useAppDispatch();
 useEffect(() => {
    if(location.pathname === '/approval/annual-spend-impact'){
     dispatch( approvalAction.clear())
    }
   }, [])
 return (
  <Layout>
   <Heading>
    Annual Spend Impact
   </Heading>

   <Flex pt="1rem">
    <Box w="100%" color="white" fontSize="18px" textColor="#43464B" mr="2rem">
     With Annual Spend Impact you can perform to-be comparison on the pending{" "}
     <br /> approval prices.
    </Box>
   </Flex>
   <Box mt="3rem">
    <Center>
     <Box
      h="202px"
      w="85%"
      borderRadius="5px"
      p="3rem"
      bg="#F7F9FD"
      textAlign="center"
      fontSize="18px"
     >
      <Text>
      The impact analysis will be using data from the demand forecast module,
      please make <br />
      sure if the demand and order frequency data is updated before starting the
      <br />
      comparison.
      </Text>
      <Text>
      Please click
       <Button px="10px" fontSize={18} size={'custom'} onClick={() => router.push("/demand-forecast")} colorScheme="blue" variant="link">
        here
       </Button>
      to maintain Demand Forecast data.
      </Text>
     </Box>
    </Center>
    <Center>
     <Box mt="3rem">
      {/* <Link to="/approval/annual-spend-impact/comparison?page=select-third-party"> */}
      <Button
       zIndex="9"
       bg="#0072CF"
       w="320px"
       h="79"
       onClick={() => {
        dispatch(approvalAction.setComparison(true))
        dispatch(tenantConfActions.configurationStatus()).then((response) => {
         if (response?.payload) {
          router.push("annual-spend-impact/comparison?page=select-third-party");
         } else {
          router.push("annual-spend-impact/comparison?page=select-pricing");
         }
        });
       }}
      >
       Start comparison
      </Button>
      {/* </Link> */}
     </Box>
    </Center>
   </Box>

   <Box pt="2rem" pb="4rem" w="100%"></Box>
  </Layout>
 );
};

export default AnnualSpendImpact;
