import { ChangeEvent, useRef, useState, useEffect } from "react";
import {
 FormControl,
 FormLabel,
 FormErrorMessage,
 FormHelperText,
 Stack,
} from "@chakra-ui/react";
import AsyncSelect from "react-select/async";
import { useField } from "formik";
import { useAppDispatch } from "hooks";
import { actions as masterDataAction } from "state/masterData/slice";
import { useErrorFocus } from "hooks";

const AutoComplete: any = ({
 name = "",
 id = "",
 label = "",
 code = "",
 thirdPartyId = "",
 description = "",
 isDisabled = false,
 isReadOnly = false,
 isClearable = true,
 isSearchable = true,
 isRequired = false,
 isCustomField = false,
 costDriver = [],
 isLoading = false,
 onChange = (e: ChangeEvent<HTMLInputElement>) => {},
 ...props
}) => {
 const [defaultOptions, setDefaultOptions] = useState(true);
 const [{ value }, meta, { setValue }] = useField(name);
 const selectRef = useRef(null);
 const dispatch = useAppDispatch();
 useErrorFocus(selectRef, name);
 const [options, setOptions] = useState<any>([]);

 useEffect(() => {
  processFetch("");
  setOptions([]);
 }, [value, costDriver]);

 const fetchListFormat = async (value: any) => {
  return await dispatch(masterDataAction.masterDataList(value)).then(
   (masterDataResponse: any) => {
    const optionsData = masterDataResponse?.payload?.result?.items;
    if (optionsData) {
     const newOptions: any = [];
     optionsData.map((item: any) => {
      const findOptionId = costDriver.find((item2: any) => {
       return item2.masterDataId === item.id || value === item.id;
      });
      newOptions.push({
       label: item.code,
       value: item.id,
       isDisabled: findOptionId,
      });
     });
     const sort = (x: any, y: any) => {
      return x.label.localeCompare(y.label);
     };

     const haveValue = value ? newOptions.sort(sort) : [];
     setDefaultOptions(haveValue);
     return newOptions.sort(sort);
    }
   }
  );
 };

 const processFetch = async (inputValue: string) => {
  const response = await fetchListFormat({
   skipCount: 0,
   maxResultCount: 100,
   searchKey: inputValue,
   configurationId: code,
   type: 0,
   thirdPartyId: thirdPartyId ? thirdPartyId : "",
  });
  setOptions(response);
  return response;
 }; 

 return (
  <FormControl
   id={id}
   isInvalid={Boolean(meta.error && meta.touched)}
//    isDisabled={isDisabled}
   isReadOnly={isReadOnly}
   isRequired={isRequired}
  >
   <Stack spacing={4} direction="row" position='relative'>
    {label && (
     <FormLabel
      alignSelf="center"
      margin="0"
      minW="40%"
      fontWeight="bold"
      textTransform="capitalize"
      opacity="1!important"
     >
      {label}
     </FormLabel>
    )}
    <AsyncSelect
     className="basic-single"
     classNamePrefix="select"
     isClearable
     key={id}
     isSearchable={isSearchable}
     name={name}
     placeholder={isLoading ? "Loading":"Please select.."}
     cacheOptions
     defaultOptions={defaultOptions}
     loadOptions={processFetch}
     value={
      value ? options.filter((item: any) => item.value.includes(value)) : ""
     }
     isDisabled={isDisabled}
     onChange={(e: any) => {
      setValue(e.value);
      if (onChange) {
       onChange(e);
      }
     }}
     styles={{
      control: (base) => ({
       ...base,
       height: 51,
       minHeight: 51,
       width: 200,
       minWidth: 200,
       marginTop: 9,
       pointerEvents: "auto",
       cursor: isDisabled ? "not-allowed !important" : "" ,
      }),
      option: (base) => ({
        ...base,
        cursor: isDisabled ? "not-allowed !important" : "",
      }),
      indicatorSeparator: (base) => ({
       ...base,
       width: 0,
       cursor: isDisabled ? "not-allowed !important" : "" ,
      }),
      menu : (base)=> ({
        ...base,
        zIndex: 9999
      })
     }}
     {...props}
    />
    {description && <FormHelperText>{description}</FormHelperText>}
   </Stack>
   <Stack spacing={4} direction="column-reverse" align={"end"}>
    {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
   </Stack>
  </FormControl>
 );
};

export default AutoComplete;
