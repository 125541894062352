import { ReactElement, useMemo, useState, useEffect } from "react";
import { removeThousandSeparator } from "utils";
import { useSelector, shallowEqual } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";
import { ValidationData } from "utils/validation-values";
import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import debounce from "lodash/debounce";
import OptipediaToast from "utils/notification-util";

import { actions as pricingCostDriverAction } from "state/pricings/costDrivers/slice";
import TextInputCustomNumber from "components/NewTextInputCustomNumber";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
import TextInput from "components/TextInput";
import { HStack } from "@chakra-ui/react";
import { isEmpty } from "lodash";
const maxResultCountCurrency = 1000;
const TextInputInTableView = ({
 index,
 convertName,
 row,
 costDriver,
 currentRow,
 paramName,
 isErrorDuplicate,
 setFieldValue,
 isMaintenanceByMasterData,
 mantenanceMasterdata,
 isRequired,
 values,
 maintenanceMasterdata,
 valueIsIncludeCalculation,
 options,
}: any): ReactElement => {
 const dispatch = useAppDispatch();
 const [errorDuplicate, setErrorDuplicate] = useState<boolean>(false);
 const [newRow, setNewRow] = useState<any>(row);
 const [customFieldsNew, setCustomFieldsNew] = useState<any>([]);
 const [dataSend, setDataSend] = useState<any>(null);
 const [customFieldsData, setCustomFieldsData] = useState<any>([]);

 const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
 let customsFields: any = [];
 const {
  loading,
  dataConstDriversOri,
  dataToSendUpdates,
  tmpChangeRow,
  detailPerVolumeId,
  isEditOpen,
  temporaryDataCostDriver,
  changeRowSimulate,
  customFieldsDatas,
  dataToSendAdds,
  listCurrency,
  haveEmptyName,
 } = useSelector(
  (state: RootState) => ({
   loading: state.pricingCostDrivers.loading,
   dataConstDriversOri: state.pricingCostDrivers.constDriversOri,
   constDrivers: state.pricingCostDrivers.constDrivers,
   detailPerVolumeId: state.pricingCostDrivers.detailPerVolumeId.result,
   dataToSendUpdates: state.pricingCostDrivers.dataToSend.updates,
   dataToSendAdds: state.pricingCostDrivers.dataToSend.adds,
   tmpChangeRow: state.pricingCostDrivers.tmpChangeRow,
   isEditOpen: state.pricingCostDrivers.isEditOpen,
   temporaryDataCostDriver: state.pricingCostDrivers.temporaryDataCostDriver,
   changeRowSimulate: state.pricingCostDrivers.tmpChangeRow,
   customFieldsDatas: state.pricingCostDrivers.customFields,
   totalRequest: state.pricingCostDrivers.totalRequest,
   newListsExchange: state.multiCurrency.newListsExchange,
   listCurrency: state.multiCurrency.lists,
   haveEmptyName: state.pricingCostDrivers.haveEmptyName,
  }),
  shallowEqual
 );

 useEffect(() => {
  setNewRow(row);
  const findIndexDataToSend = dataToSendAdds.findIndex(
   (dataAdd: any) => dataAdd.simulationCostDriverId === row.id
  );
  const dataCreateToUpdate = {
   index: findIndexDataToSend,
   data: row.customFields,
  };
  if (findIndexDataToSend !== -1) {
   dispatch(pricingCostDriverAction.setChangeAddRowByIndex(dataCreateToUpdate));
  }
 }, [row]);

 //  useEffect(() => {
 //   if (row.name === "") {
 //    return;
 //   }

 //   const findLastConstDriver = detailPerVolumeId?.costDrivers.find(
 //    (item: any) => item.id === row.constDriverId
 //   );
 //   const isBaseLevel = detailPerVolumeId?.costDrivers.find(
 //    (item: any) => item.name === row.name
 //   );

 //   const firstName = findLastConstDriver?.costDrivers.find(
 //    (costDriver: any) => costDriver.id === row?.id
 //   );
 //   changeRowSimulate.find((tmp: any, indexRow: number) => {
 //    const findDataFromCustomFields = row.customFields.find(
 //     (customField: any) => customField.name === tmp.name
 //    );

 //    if (!row.constDriverId) {
 //     const findSameCustomFieldConfigurationId = isBaseLevel?.customFields?.find(
 //      (customField: any) => customField?.id === tmp?.customFieldConfigurationId
 //     );
 //     if (findSameCustomFieldConfigurationId) {
 //      dispatch(
 //       pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
 //        index: indexRow,
 //        // component: componentNameChange,
 //        idRow: firstName?.id,
 //        // componentName: findLastConstDriver?.name,
 //        currencyTo: findSameCustomFieldConfigurationId?.currency?.code,
 //       })
 //      );
 //     }
 //    }

 //    if (
 //     tmp?.idRow === findLastConstDriver?.id &&
 //     tmp?.name === findDataFromCustomFields?.name
 //    ) {
 //     const componentNameChange = `${firstName?.name} - ${findDataFromCustomFields?.name}`;
 //     dispatch(
 //      pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
 //       index: indexRow,
 //       component: componentNameChange,
 //       idRow: findLastConstDriver?.id,
 //       componentName: findLastConstDriver?.name,
 //      })
 //     );
 //    }

 //    const findSameCustomFieldConfigurationId = firstName?.customFields?.find(
 //     (customField: any) =>
 //      customField.configuration.id === tmp?.customFieldConfigurationId
 //    );

 //    if (
 //     findSameCustomFieldConfigurationId &&
 //     tmp?.customFieldConfigurationId ===
 //      findSameCustomFieldConfigurationId?.configuration?.id &&
 //     findSameCustomFieldConfigurationId?.costDriver?.id === row?.id
 //    ) {
 //     const componentNameChange = `${firstName?.name} - ${findSameCustomFieldConfigurationId?.configuration?.name}Code`;
 //     dispatch(
 //      pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
 //       index: indexRow,
 //       // component: componentNameChange,
 //       idRow: firstName?.id,
 //       // componentName: findLastConstDriver?.name,
 //       currencyTo: findSameCustomFieldConfigurationId?.currency?.code,
 //      })
 //     );
 //    }
 //    if (
 //     tmp?.idRow === row?.id &&
 //     tmp.name === row?.masterDataConfiguration?.configuration?.code
 //    ) {
 //     const componentNameChange = `${firstName?.name} - ${firstName?.configuration?.labelName}`;
 //     const findSameCustomFieldConfigurationId = firstName?.customFields?.find(
 //      (customField: any) => customField?.id === tmp?.customFieldConfigurationId
 //     );
 //     dispatch(
 //      pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
 //       index: indexRow,
 //       component: componentNameChange,
 //       idRow: findLastConstDriver?.id,
 //       componentName: findLastConstDriver?.name,
 //       currencyTo: findSameCustomFieldConfigurationId?.currency?.code,
 //       before: findLastConstDriver?.unitCost,
 //       name: findLastConstDriver?.configuration?.labelName,
 //      })
 //     );
 //    }
 //   });
 //  }, [detailPerVolumeId]);

 const valueConvertName = isMaintenanceByMasterData
  ? `${convertName}-From-Masterdata`
  : convertName;

 const findIndexCustomField = () => {
  return row.customFields.findIndex(
   (customField: any, index: number) =>
    customField.name === convertName || customField.name === valueConvertName
  );
 };

 //  useEffect(() => {
 //   setNewRow(row);
 //  }, [row]);

 //  useEffect(() => {
 //   setCustomFieldsData(customFieldsDatas);
 //  }, [customFieldsDatas]);

 useEffect(() => {
  if (dataSend) {
   const timer = setTimeout(async () => {
    await dispatch(pricingCostDriverAction.setDataToSend(dataSend));
    dispatch(pricingCostDriverAction.setIsEditOpen(false));
    dispatch(pricingCostDriverAction.setNullCustomFields());
   }, 100);

   return () => clearTimeout(timer);
  }
 }, [dataSend]);

 //  useEffect(() => {
 //   const ids = customFieldsDatas.map((o: any) => o.id);
 //   const filtered = customFieldsDatas.filter(
 //    ({ id }: any, index: number) => !ids.includes(id, index + 1)
 //   );
 //   setCustomFieldsNew(filtered);
 //  }, [customFieldsDatas]);

 const findOriginalCostDriver =
  dataConstDriversOri &&
  dataConstDriversOri?.data &&
  dataConstDriversOri?.data.find(
   (item: any) => item.identityName === costDriver.identityName
  );

 const indexSimulateBySku = temporaryDataCostDriver.findIndex(
  (el: any) => el.name == paramName
 );

 const findOneCostDriverOriginal =
  temporaryDataCostDriver[indexSimulateBySku] &&
  temporaryDataCostDriver[indexSimulateBySku]?.data &&
  temporaryDataCostDriver[indexSimulateBySku]?.data.find(
   (item: any) => item.costDriverId === costDriver.costDriverId
  );

 const findIndex = findOneCostDriverOriginal?.data.findIndex((item: any) => {
  return item.id === currentRow?.get("id");
 });

 const runSimulate = useMemo(
  () =>
   debounce((dataToUpdate) => {
    const newCustomFieldDataToUpdate: any = [];
    dataToUpdate?.customFields?.map((customField: any) => {
     if (!customField.isMaintenanceByMasterData) {
      newCustomFieldDataToUpdate.push(customField);
     }
    });
    const newDataToUpdate = {
     ...dataToUpdate,
     customFields: newCustomFieldDataToUpdate,
    };
    dispatch(
     pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(
      newDataToUpdate
     )
    );
   }, 600),
  [dispatch, dataToSendUpdates]
 );

 const isNewDebounce = useMemo(
  () =>
   debounce(async (dataToAdd) => {
    const { name, value } = dataToAdd;
    const { dataCustomFields, newRow, customFieldConfigurationId } = dataToAdd;
    let myuuid = uuidv4();
    const isItName = name === "name";
    const isItDes = name === "description";
    const isItUnitCost = name === "unitCost";
    const isName = isItName ? value : row?.name;
    const isDes = isItDes ? value : row?.description;
    const isUnitCost = isItUnitCost ? value : row?.unitCost;
    row.name = isName;
    row.description = isDes;
    row.unitCost = isUnitCost;
    let newData: any = {};
    if (!isItName && !isItDes && !isItUnitCost) {
     for (var i = 0; i < row?.customFields?.length; i++) {
      if (row?.customFields[i]?.name === name) {
       const dataToPush = {
        ...row.customFields[i],
        value,
       };
       newData = dataToPush;
      }
     }
    }

    let isCustomFieldAllHaveValue = true;

    let isHaveSelectbox = false;
    let customFieldsValueNotIncludeCalculation: any = [];
    for (var i = 0; i < row?.customFields.length; i++) {
     if (row?.customFields[i].masterData !== null) {
      isHaveSelectbox = true;
      break;
     } else {
      if (!row?.customFields[i].isIncludeInCalculation) {
       customFieldsValueNotIncludeCalculation.push(row?.customFields[i]);
      }
     }
    }

    if (row.isNew) {
     row?.customFields.map((item: any) => {
      return dispatch(pricingCostDriverAction.setCustomFields(item));
     });

     row?.customFields.length > 0 &&
      row?.customFields.map((item: any) => {
       if (item.masterData !== null && item.value === "") {
        isCustomFieldAllHaveValue = false;
       }
      });

     const customFields: any = [];

     if (isHaveSelectbox) {
      dataCustomFields?.customFields.map((item: any) => {
       return dispatch(pricingCostDriverAction.setCustomFields(item));
      });
      dataCustomFields?.customFields.length > 0 &&
       dataCustomFields?.customFields.map((item: any) => {
        if (item.masterData !== null && item.value === "") {
         isCustomFieldAllHaveValue = false;
        }
       });
     } else {
      row?.customFields.map((item: any) => {
       return dispatch(pricingCostDriverAction.setCustomFields(item));
      });

      row?.customFields.length > 0 &&
       row?.customFields.map((item: any) => {
        if (item.masterData !== null && item.value === "") {
         isCustomFieldAllHaveValue = false;
        }
       });
     }

     let totalNotIncludeCalculations = 0;
     let totalIsRequired = 0;
     let totalRowCustomFields = 0;

     row?.customFields.map((customField: any) => {
      if (customField.isIncludeInCalculation) {
       customsFields.push(customField);
      } else {
       totalNotIncludeCalculations += 1;
      }
     });

     row?.customFields.map((customField: any, index: number) => {
      if (customField.name === name) {
       customFields.push({
        ...customField,
        value,
       });
       //  row.customFields[index].value = value;
      } else {
       customFields.push({
        ...customField,
        value: newRow[customField.name],
       });
      }
     });
     const findObjectEmptyValue = customFields.find(
      (customField: any) => customField.isRequired && customField.value === ""
     );

     const removeCustomFields: any = [];

     customFields.map((customField: any) => {
      if (!customField.isMaintenanceByMasterData) {
       removeCustomFields.push(customField);
      }
     });

     const data = {
      costAnalysisPerVolumeId: detailPerVolumeId.id,
      costDriverId: newRow?.id,
      simulationCostDriverId: myuuid,
      masterDataId: newRow?.masterDataId ? newRow?.masterDataId : "",
      name: newRow?.masterDataId ? "T" : newRow?.name,
      description: newRow?.masterDataId ? "T" : row?.description,
      unitCost: newRow?.unitCost || 0,
      customFields: removeCustomFields,
      currencyId: newRow?.currencyId,
      newData,
     };
     const findDuplicate = findOneCostDriverOriginal?.data.findIndex(
      (item: any) => item.name.toLowerCase() === value.toLowerCase()
     );

     customFields?.map((filteredCustomField: any) => {
      if (!filteredCustomField.isIncludeInCalculation) {
       totalRowCustomFields += 1;
      }
     });
     setFieldDisabled(true);
     const validateToAdd = data.name !== "" && data.description !== "";
     const findNull = customFields.find(
      (item: any) =>
       !item.isMaintenanceByMasterData &&
       item.isRequired &&
       (item.value === "" || item.value === null)
     );

     if (findDuplicate !== -1 && name === "name") {
      setErrorDuplicate(true);
      dispatch(pricingCostDriverAction.setIsErrorDuplicate(true));
      return OptipediaToast.Warning(
       "The name you typed is duplicated, please use another name"
      );
     } else {
      setErrorDuplicate(false);
      dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
      if (validateToAdd && !findNull) {
       setDataSend(data);
      }
     }
    } else {
     await customFieldsValueNotIncludeCalculation.map(
      (item: any, index: number) => {
       if (item.name === name) {
        customFieldsValueNotIncludeCalculation[index].value = value;
       }
      }
     );
     const findNothaveValue = customFieldsValueNotIncludeCalculation.find(
      (itemValue: any) => itemValue.value === ""
     );

     if (!findNothaveValue) {
      for (var i = 0; i < row?.customFields.length; i++) {
       if (row?.customFields[i].isIncludeInCalculation) {
        customFieldsValueNotIncludeCalculation.push(row?.customFields[i]);
       }
      }

      const data = {
       costAnalysisPerVolumeId: detailPerVolumeId?.id,
       costDriverId: row?.id,
       simulationCostDriverId: myuuid,
       name: row?.name,
       description: row?.description,
       unitCost: row?.unitCost || 0,
       customFields: customFieldsValueNotIncludeCalculation,
       newData,
      };

      const findDuplicate = findOneCostDriverOriginal?.data.findIndex(
       (item: any) => item.name.toLowerCase() === value.toLowerCase()
      );

      if (findDuplicate !== -1 && name === "name") {
       setErrorDuplicate(true);
       dispatch(pricingCostDriverAction.setIsErrorDuplicate(true));
       return OptipediaToast.Warning(
        "The name you typed is duplicated, please use another name"
       );
      } else {
       setErrorDuplicate(false);
       dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
       dispatch(
        pricingCostDriverAction.directSetAddOneToTemporaryDataCostDriver(data)
       );
      }
     }
    }
   }, 800),
  [dispatch]
 );

 const debouncedRunSimulate = useMemo(
  () =>
   debounce(async (dataOnChange: any) => {
    const {
     name,
     value,
     currentRow1,
     tableName,
     dataFromSimulate,
     unitCostLabel,
     currencyId,
    } = dataOnChange;
    let isNewCostDriver: any;
    if (dataFromSimulate || currentRow1) {
     isNewCostDriver = dataFromSimulate?.isNew ? dataFromSimulate : currentRow1;
    } else {
     isNewCostDriver = row;
    }
    const newCustomField: any = [];
    let itemToObject = Object.assign({}, isNewCostDriver);
    const originalNewMap = new Map(Object.entries(itemToObject));
    const findCost = changeRowSimulate.find(
     (item: any) =>
      item.name === unitCostLabel &&
      item.componentName === dataFromSimulate?.name &&
      item.idRow === dataFromSimulate.id
    );

    if (
     (row.isNew && 0 === value) ||
     removeThousandSeparator(originalNewMap.get(name) as string) === value
    ) {
     const tmpChangeToDeletes = {
      component: `${originalNewMap.get("name")} - ${
       name === "unitCost" ? unitCostLabel : name
      }`,
      name: convertName,
      constDriverId: originalNewMap.get("constDriverId"),
     };
     dispatch(
      pricingCostDriverAction.removeOnetmpChangeRow({ tmpChangeToDeletes })
     );

     let indexCustomField = null;
     const customFields: any = [];
     dataFromSimulate?.customFields?.map((item: any, index: number) => {
      if (item.name === name) {
       indexCustomField = index;
       customFields.push({
        ...item,
        value,
       });
      } else {
       customFields.push(item);
      }
     });
     const dataToUpdate = {
      costAnalysisPerVolumeId: detailPerVolumeId?.id,
      costDriverId: isNewCostDriver.id,
      parentCostDriverId: isNewCostDriver.constDriverId,
      name: isNewCostDriver.name,
      masterDataId: isNewCostDriver.masterDataId,
      description: isNewCostDriver.description,
      unitCost:
       convertName === "unitCost"
        ? value
        : findCost?.before ?? itemToObject?.unitCost,
      customFields: customFields,
      currencyId: currencyId ? currencyId : isNewCostDriver.currencyId,
     };
     runSimulate(dataToUpdate);
     setFieldDisabled(true);
    } else {
     let indexCustomField = null;
     let isCustomFieldAllHaveValue = true;
     newCustomField.length > 0 &&
      newCustomField.map((item: any) => {
       if (item.masterData !== null && item.value === "") {
        isCustomFieldAllHaveValue = false;
       }
      });
     const customFields: any = [];
     isNewCostDriver?.customFields.map(
      (item: any, indexCustomFieldNumber: number) => {
       if (item.name === name) {
        indexCustomField = indexCustomFieldNumber;
        if (changeRowSimulate.length > 0) {
         for (let i = 0; i < changeRowSimulate.length; i++) {
          if (
           item.customFieldConfigurationId ===
            changeRowSimulate[i]?.customFieldConfigurationId &&
           item.costDriverId === changeRowSimulate[i]?.idRow &&
           item.name === changeRowSimulate[i]?.name
          ) {
           customFields.push({
            ...item,
            value,
           });
          } else {
           if (
            item?.name === name &&
            item?.costDriverName === currentRow1?.name
           ) {
            customFields.push({
             ...item,
             value,
            });
           } else {
            const sameValue = changeRowSimulate.find(
             (data: any) =>
              data?.idRow === item?.costDriverId &&
              data?.customFieldConfigurationId ===
               item?.customFieldConfigurationId &&
              item?.name === data.name
            );
            if (sameValue) {
             customFields.push({
              ...item,
              value: sameValue?.before,
             });
            } else {
             customFields.push(item);
            }
           }
          }
         }
        } else {
         customFields.push({
          ...item,
          value,
         });
        }
       } else {
        for (let i = 0; i < changeRowSimulate.length; i++) {
         if (
          item?.customFieldConfigurationId ===
           changeRowSimulate[i]?.customFieldConfigurationId &&
          item?.costDriverName === changeRowSimulate[i]?.componentName &&
          item?.name === changeRowSimulate[i]?.name
         ) {
          customFields.push({
           ...item,
           value: changeRowSimulate[i]?.before,
          });
         } else {
          if (item?.name === name && item?.costDriverName === row?.name) {
           customFields.push({
            ...item,
            value,
           });
          } else {
           const sameValue = changeRowSimulate.find(
            (data: any) =>
             data?.idRow === item?.costDriverId &&
             data?.customFieldConfigurationId ===
              item?.customFieldConfigurationId &&
             item?.name === data?.name
           );
           if (sameValue) {
            customFields.push({
             ...item,
             value: sameValue?.before,
            });
           } else {
            customFields.push(item);
           }
          }
         }
        }
       }
      }
     );

     const customFieldsFromSimulate: any = [];

     row?.customFields.map((customField: any) => {
      if (customField?.name === name) {
       customFieldsFromSimulate.push({
        ...customField,
        value,
       });
      } else {
       if (row[customField?.name] || !customField?.isRequired) {
        if (
         name === `${customField?.name}-currencyId` &&
         customField?.typeData === 50
        ) {
         customFieldsFromSimulate.push({
          ...customField,
          currencyId: value,
         });
        } else {
         customFieldsFromSimulate.push({
          ...customField,
          value: row[customField.name],
         });
        }
       }
      }
     });
     const dataSendEdit = customFieldsFromSimulate?.map((o: any) => o.name);
     const filteredCustomFields = customFieldsFromSimulate?.filter(
      ({ name, isMaintenanceByMasterData }: any, index: number) =>
       !isMaintenanceByMasterData && !dataSendEdit.includes(name, index + 1)
     );

     const dataToUpdate = {
      costAnalysisPerVolumeId: detailPerVolumeId?.id,
      costDriverId: isNewCostDriver
       ? isNewCostDriver?.id
       : dataFromSimulate?.id,
      parentCostDriverId: isNewCostDriver
       ? isNewCostDriver?.constDriverId
       : dataFromSimulate?.constDriverId,
      name: row.masterDataId
       ? dataFromSimulate?.name
       : name === "name"
       ? value
       : dataFromSimulate?.name,
      description: row.masterDataId
       ? dataFromSimulate?.description
       : name === "description"
       ? value
       : dataFromSimulate?.description,
      masterDataId: dataFromSimulate.masterDataConfiguration?.id,
      unitCost: name === "unitCost" ? value : row?.unitCost,
      customFields: filteredCustomFields,
      remove: row?.isNew && 0 === value,
      currencyId: currencyId ? currencyId : dataFromSimulate.currencyId,
     };

     runSimulate(dataToUpdate);
     setFieldDisabled(true);
     let itemNewOriginal;
     if (isNewCostDriver) {
      itemNewOriginal = new Map(Object.entries(isNewCostDriver));
      let after = isNewCostDriver?.customFields.find(
       (item: any) => item?.name === convertName
      );

      if (
       (after && removeThousandSeparator(after?.value) !== value) ||
       (convertName === "unitCost" &&
        removeThousandSeparator(isNewCostDriver?.unitCost) !== value)
      ) {
       const findCust = customFields?.find(
        (item: any) =>
         item?.name === name && item?.costDriverId === isNewCostDriver?.id
       );
       const findCustFromSimulate = dataFromSimulate?.customFields?.find(
        (item: any) => convertName === item?.realName
       );
       if (findCustFromSimulate?.typeData !== 10) {
        dispatch(
         pricingCostDriverAction.settmpChangeRow({
          id: row?.id,
          costDriver: tableName,
          currencyFrom:
           findCustFromSimulate?.typeData === 50
            ? findCustFromSimulate?.currencyCode
            : convertName === "unitCost"
            ? dataFromSimulate?.currency
            : "-",
          currencyTo:
           findCustFromSimulate?.typeData === 50
            ? findCustFromSimulate?.currencyCode
            : convertName === "unitCost"
            ? dataFromSimulate?.currency
            : "-",
          component: `${dataFromSimulate.name} - ${
           name === "unitCost" ? unitCostLabel : name
          }`,
          before: value,
          after: isNewCostDriver.isNew
           ? findCustFromSimulate.value || 0
           : findCustFromSimulate
           ? findCustFromSimulate.value
           : itemNewOriginal.get("unitCost"),
          idRow: findCust ? findCust?.costDriverId : row?.id,
          customFieldConfigurationId: isNewCostDriver.constDriverId
           ? after?.customFieldConfigurationId
           : after?.customFieldId,
          typeData: after?.typeData,
          parentCostDriverId: findCust
           ? findCust?.id
           : costDriver?.costDriverId,
          componentName: originalNewMap.get("name"),
          name: name === "unitCost" ? unitCostLabel : name,
         })
        );
       }
      }
     } else {
      itemNewOriginal = new Map(Object.entries(dataFromSimulate));
      let after = dataFromSimulate?.customFields?.find(
       (item: any) => item?.name === convertName
      );

      if (
       (after && removeThousandSeparator(after.value) !== value) ||
       (convertName === "unitCost" &&
        removeThousandSeparator(dataFromSimulate?.unitCost) !== value)
      ) {
       const findCust = customFields.find(
        (item: any) =>
         item?.name === name && item?.costDriverId === dataFromSimulate?.id
       );
       dispatch(
        pricingCostDriverAction.settmpChangeRow({
         id: convertName,
         costDriver: tableName,
         currencyFrom: row.currency,
         currencyTo: row.currency,
         component: `${originalNewMap.get("name")} - ${
          name === "unitCost" ? unitCostLabel : name
         }`,
         before: value,
         after: dataFromSimulate?.isNew
          ? 0
          : after
          ? after.value
          : itemNewOriginal.get("unitCost"),
         idRow: row.id,
         customFieldConfigurationId: findCust?.customFieldConfigurationId,
         typeData: after?.typeData,
         parentCostDriverId: costDriver?.costDriverId,
         componentName: originalNewMap.get("name"),
         name: name === "unitCost" ? unitCostLabel : name,
        })
       );
      }
     }
    }
   }, 800),
  [dataToSendUpdates, row]
 );

 useEffect(() => {
  return () => {
   debouncedRunSimulate.cancel();
  };
 }, []);

 const responseFetch = findOneCostDriverOriginal?.data[findIndex];
 const isMaintainWithMasterData =
  values.customFields[index]?.customFields?.find((i: any) => {
   return i.name == `${convertName}-From-Masterdata`;
  }) ||
  (values.customFields[index]?.masterDataConfiguration &&
   convertName === "unitCost") ||
  (values.customFields[index]?.masterDataConfiguration &&
   convertName === "description");
 const isValidDisableNew =
  responseFetch?.isNew &&
  responseFetch?.name !== "" &&
  responseFetch?.description;

 const newCustomFieldTmp: any = [];
 newRow.customFields.map((customField: any) => {
  if (newRow[customField.name]) {
   newCustomFieldTmp.push({
    ...customField,
    value: newRow[customField.name],
   });
  }
 });

 const typeData = row.customFields[findIndexCustomField()]?.typeData;

 //  const findCurencyId =
 //   currencies &&
 //   currencies.length &&
 //   currencies.find((cur: any) => {
 //    if (
 //     cur.id === findIndexCustomField() &&
 //     values.customFields[index].customFields[findIndexCustomField()].currencyId
 //    ) {
 //     return cur;
 //    }
 //   });

 const isMasterData = row.masterDataConfiguration && row.masterDataId;

 let isUseMasterData = false;

 let isOpenField = false;
 if (isMasterData) {
  isUseMasterData = true;
  isOpenField =
   convertName === "description" ||
   convertName === "totalCost" ||
   convertName === "unitCost";
 } else {
  isUseMasterData = false;
  isOpenField = row?.isRequired || convertName === "totalCost";
 }

 let isRequiredData = false;
 newRow.customFields?.find((i: any) => {
  return (isRequiredData = i.isRequired && isEmpty(i.value));
 });

 return (
  <>
   {typeData === 20 ||
   typeData === 50 ||
   typeData === 40 ||
   convertName === "unitCost" ||
   convertName === "currency" ? (
    <>
     <HStack spacing="0">
      {typeData === 50 ||
       (convertName === "currency" && (
        <>
         <SelectWithAutoComplete
          name={
           convertName === "currency"
            ? "currencyId"
            : `customFields[${index}].customFields[${findIndexCustomField()}].currencyId`
          }
          defaultValue={convertName === "currency" ? row.currencyId : ""}
          isLoading={loading}
          isDisabled={
           loading ||
           isOpenField ||
           valueIsIncludeCalculation ||
           maintenanceMasterdata ||
           isRequiredData ||
           [40].includes(typeData) ||
           (isErrorDuplicate && convertName !== "name") ||
           row.customFields[findIndexCustomField()]
            ?.isMaintenanceByMasterData ||
           isErrorDuplicate
          }
          onChange={(e: any) => {
           const dataToEdit = {
            name: `${convertName}-currencyId`,
            value: e.value,
            currentRow1: findOriginalCostDriver?.data[findIndex],
            tableName: findOriginalCostDriver?.tableName,
            dataFromSimulate: findOneCostDriverOriginal?.data[findIndex],
            isSameData: false,
            currencyId: e.value,
           };
           // setFieldValue(
           //  `customFields[${index}].customFields[${findIndexCustomField()}].currencyId`,
           //  e.value
           // );
           dispatch(pricingCostDriverAction.resetDataSimulate([]));
           debouncedRunSimulate(dataToEdit);
          }}
          options={options || []}
          //  width={convertName === "currency" ? 200 : 125}
          mt={7}
          noBorder={true}
         />
        </>
       ))}
      {typeData !== 50 && convertName !== "currency" && (
       <TextInputCustomNumber
        name={`customFields[${index}].${valueConvertName}`}
        id={`customFields[${index}].${valueConvertName}`}
        isLoading={loading}
        //  isDisabled={
        //   typeData === 40 ||
        //   row.customFields[findIndexCustomField()]?.isMaintenanceByMasterData ||
        //   (row.masterDataId !== "" && convertName === "unitCost") ||
        //   isErrorDuplicate ||
        //   (isNew && row.index === 0 && !required) ||
        //   (!isNew && isEditOpen && !isNaN(parseFloat(newRow[convertName])))
        //   // ||
        //   // (isNew && isEditOpen && !isNaN(parseFloat(newRow[convertName]))) ||
        //  }
        isDisabled={
         loading ||
         isOpenField ||
         valueIsIncludeCalculation ||
         maintenanceMasterdata ||
         // (isNew && row.index === 0 ) ||
         !isRequired ||
         typeData === 40 ||
         row.customFields[findIndexCustomField()]?.isMaintenanceByMasterData ||
         isErrorDuplicate ||
         (row.masterDataConfiguration === null &&
          convertName !== "description" &&
          haveEmptyName) ||
         (row.masterDataConfiguration === null &&
          convertName !== "name" &&
          haveEmptyName) ||
         (isErrorDuplicate && convertName !== "name")
        }
        onChange={(value: any) => {
         const dataSendToSimulate = {
          name: convertName,
          value: removeThousandSeparator(value),
          currentRow1: findOriginalCostDriver?.data[findIndex],
          tableName: findOriginalCostDriver?.tableName,
          dataFromSimulate: findOneCostDriverOriginal?.data[findIndex],
          isSameData: false,
          unitCostLabel: "",
         };
         const findUnitCostName = findOriginalCostDriver?.headers.find(
          (item: any) => item.name === convertName
         );

         let unitCostLabel = findUnitCostName?.label;

         if (findOriginalCostDriver?.costDriverUrl === paramName) {
          if (convertName === "unitCost" && index !== -1) {
           // setFieldValue(`customFields[${index - 1}].${convertName}`, value);
          }
          const isCustomField = convertName !== "unitCost";
          const isSameData = ValidationData(
           findOneCostDriverOriginal?.data[findIndex],
           convertName,
           value,
           isCustomField,
           "isIncludeCalculation"
          );
          dataSendToSimulate.unitCostLabel = unitCostLabel;
          if (isSameData) {
           dataSendToSimulate.isSameData = true;
           const tmpChangeToDeletes = {
            component: `${findOneCostDriverOriginal?.data[findIndex].name} - ${
             convertName === "unitCost" ? unitCostLabel : convertName
            }`,
            name: convertName,
            constDriverId:
             findOneCostDriverOriginal?.data[findIndex].constDriverId,
           };
           dispatch(
            pricingCostDriverAction.removeOnetmpChangeRow({
             tmpChangeToDeletes,
            })
           );
          }
          const isRequired = values.customFields[index]?.customFields?.find(
           (i: any) => i.isRequired && i.value === ""
          );
          if (!isRequired) {
           // debouncedRunSimulate(dataSendToSimulate);
           dispatch(
            pricingCostDriverAction.addDataToSimulate(dataSendToSimulate)
           );
          }
         }
        }}
       />
      )}
     </HStack>
    </>
   ) : (
    <TextInput
     isLoading={loading}
     name={`customFields[${index}].${valueConvertName}`}
     id={`customFields[${index}].${valueConvertName}`}
     isDuplicate={errorDuplicate}
     onChange={(value) => {
      const dataToAdd = {
       name: convertName,
       value: value.target.value,
       dataCustomFields: findOneCostDriverOriginal?.data[findIndex],
       datalatest: costDriver,
       latestCustomFields: customFieldsData,
       customFieldConfigurationId:
        row?.customFields[index]?.customFieldConfigurationId,
       newRow,
      };
      const dataFromServer = findOneCostDriverOriginal?.data[findIndex];
      const isSameId =
       row?.isNew && dataFromServer?.name === ""
        ? true
        : dataFromServer?.id !== row?.id;
      if (
       (row?.isNew && row?.masterDataId !== "" && dataFromServer.index === 0) ||
       isSameId
      ) {
       isNewDebounce(dataToAdd);
      } else {
       if (value.currentTarget.value !== "") {
        const dataToEdit = {
         name: convertName,
         value: value.currentTarget.value,
         currentRow1: findOriginalCostDriver?.data[findIndex],
         tableName: findOriginalCostDriver?.tableName,
         dataFromSimulate: findOneCostDriverOriginal?.data[findIndex],
         isSameData: false,
        };

        setFieldValue(
         `customFields[${
          index - 1
         }].customFields[${findIndexCustomField()}].${convertName}`,
         value.currentTarget.value
        );
        // debouncedRunSimulate(dataToEdit);
        dispatch(pricingCostDriverAction.addDataToSimulate(dataToEdit));
        const isSameData = ValidationData(
         findOriginalCostDriver?.data[findIndex],
         convertName,
         value.currentTarget.value,
         false,
         "isNotIncludeCalculation"
        );

        if (isSameData) {
         const tmpChangeToDeletes = {
          component: `${findOriginalCostDriver?.data[findIndex].name} - ${convertName}`,
          name: convertName,
          constDriverId: findOriginalCostDriver?.data[findIndex].constDriverId,
         };

         dispatch(
          pricingCostDriverAction.removeOnetmpChangeRow({ tmpChangeToDeletes })
         );
        }
       }
      }
     }}
     isDisabled={
      loading ||
      valueIsIncludeCalculation ||
      (isRequired &&
       !isEmpty(row.customFields[findIndexCustomField()]?.originalValue)) ||
      isOpenField ||
      (!newRow.isNew && convertName === "name") ||
      row.constDriverId ||
      (!newRow.isNew && convertName === "description") ||
      row.constDriverId ||
      (isErrorDuplicate && convertName !== "name") ||
      isMaintainWithMasterData
     }
     //  isDisabled={
     //   row.masterDataId === "" ||
     //   (isEditOpen && newRow.masterDataConfiguration && !isRequired) ||
     //   (isEditOpen && row?.masterDataId && isNew && !isRequired) ||
     //   newRow.customFields[findIndexCustomField()]?.isMaintenanceByMasterData ||
     //   (row.masterDataConfiguration === null &&
     //    convertName !== "description" &&
     //    haveEmptyName) ||
     //   (row.masterDataConfiguration === null &&
     //    convertName !== "name" &&
     //    haveEmptyName) ||
     //   valueIsIncludeCalculation ||
     //   isMaintainWithMasterData ||
     //   (isErrorDuplicate && convertName !== "name")
     //  }
    />
   )}
  </>
 );
};

export default TextInputInTableView;
