import { combineReducers } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducer as toastrReducer } from "react-redux-toastr";

import { reducer as authReducer } from "state/auth/slice";
import { reducer as myJourneyReducer } from "state/myJourney/slice";
import { reducer as costAnalysisReducer } from "state/costAnalysis/slice";

import { reducer as productConfigurationsReducer } from "state/productConfigurations/slice";
import { reducer as dataLoadingReducer } from "state/dataLoading/slice";
import { reducer as pricingDataLoadingReducer } from "state/pricings/dataLoading/slice";
import { reducer as pricingListReducer } from "state/pricings/pricingList/slice";
import { reducer as pricingCostSummaryReducer } from "state/pricings/costSummary/slice";
import { reducer as pricingCostDriversReducer } from "state/pricings/costDrivers/slice";
import { reducer as editPricingCostDriversReducer } from "state/pricings/editCostDrivers/slice";
import { reducer as copyAndReplacePricingCostDriversReducer} from "state/pricings/copyAndReplace/slice"
import { reducer as updateNewVersionReducer } from "state/pricings/costAnalysis/slice";
import { reducer as predefinedDataReducer } from "state/pricings/predefinedData/slice";
import { reducer as annualSpendReducer } from "state/annualSpend/slice";
import { reducer as simulationReducer } from "state/simulation/slice";
import { reducer as thirdPartySettingReducer } from "state/settings/slice";
import { reducer as startedReducer } from "state/started/slice";
import { reducer as approvalReducer } from "state/approval/slice"
import { reducer as masterDataReducer } from "state/masterData/slice"
import { reducer as masterDataConfigurationReducer } from "state/masterDataConfiguration/slice"
import { reducer as demandForecastReducer } from "state/demandForecast/slice";
import { reducer as sizeOfUnitReducer } from "state/sizeOfPricingUnit/slice";
import { reducer as multiCurrecyReducer } from "state/multiCurency/slice";
import { reducer as massUpdateReducer } from "state/massUpdate/slice"

const rootReducer = combineReducers({
 auth: authReducer,
 toastr: toastrReducer,
 myJourney: myJourneyReducer,
 costAnalysis: costAnalysisReducer,
 productConfigurations: productConfigurationsReducer,
 dataLoading: dataLoadingReducer,
 pricingDataLoading: pricingDataLoadingReducer,
 pricingList: pricingListReducer,
 pricingCostSummary: pricingCostSummaryReducer,
 pricingCostDrivers: pricingCostDriversReducer,
 editPricingCostDrivers: editPricingCostDriversReducer,
 copyAndReplaceCostDrivers: copyAndReplacePricingCostDriversReducer,
 updateNewVersion: updateNewVersionReducer,
 predefinedData: predefinedDataReducer,
 simulationAggregate: simulationReducer,
 annualSpendSimulations: annualSpendReducer,
 thirdPartySetting: thirdPartySettingReducer,
 started: startedReducer,
 approval: approvalReducer,
 masterData: masterDataReducer,
 masterDataConfiguration: masterDataConfigurationReducer,
 demandForecast: demandForecastReducer,
 sizeOfUnit: sizeOfUnitReducer,
 multiCurrency: multiCurrecyReducer,
 massUpdate: massUpdateReducer
});

const persistConfig = {
 key: "root",
 storage,
 whitelist: ["authReducer", "lastHeartBeat", "settings"],
 blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
 reducer: persistedReducer,
 middleware: getDefaultMiddleware({
  serializableCheck: false,
 }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
