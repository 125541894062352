import { ReactElement, useEffect, useState, useRef } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { StepperLoading } from "components/Loading";
import "utils/number-utils";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import LeftIcon from "assets/images/arrow-left-stepper.png";
import RightIcon from "assets/images/arrow-right-stepper.png";
import {
 Flex,
 Box,
 Grid,
 Circle,
 Select,
 Spacer,
 Image,
 Text,
} from "@chakra-ui/react";
import { actions as pricingCostDriverAction } from "state/pricings/costDrivers/slice";
import { actions as pricingCostSummaryAction } from "state/pricings/costSummary/slice";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.css";

const Stepper = (): ReactElement => {
 const dispatch = useAppDispatch();
 const history = useHistory();
 const location = useLocation<{
  status: string;
  volumeId: string;
  isEditing: boolean;
  isApproval: boolean;
 }>();
 const pricingId = location.pathname.split("/")[3];
 const pricingPage = location.pathname.split("/")[2];
 const startSequence = 2;
 const generalDataSequence = 1;
 const [data, setData] = useState<any>({});
 const [isChangeVolume, setIsChangeVolume] = useState<boolean>(false);
 const [indexStepper, setIndexStepper] = useState<number>(0);
 const [currentVolume, setCurrentVolume] = useState<number>(0);
 const [currentThirdParty, setCurrentThirdParty] = useState<any>(null);
 const [isLoadingChangeVolume, setLoadingChangeVolume] =
  useState<boolean>(false);
 const [isLoadingChangeThirdParty, setLoadingChangeThirdParty] =
  useState<boolean>(false);
 const currentSeq: any = [];

 const stepperReff = useRef<null | HTMLDivElement>(null);
 const scrollToStepper = () => {
  stepperReff.current?.scrollIntoView({ behavior: "smooth" });
 };
 const {
  detailPerVolumeId,
  volumes,
  thirdParties,
  levelName,
  productName,
  costAnalysisPerVolume,
  volumeId,
  loading,
 } = useSelector(
  (state: RootState) => ({
   volumes: state.pricingCostSummary.costSummaryById.volumes,
   thirdParties: state.pricingCostSummary.pricingThirdParty,
   productName: state.pricingCostSummary.costSummaryById.name,
   currency: state.pricingCostSummary.costSummaryById.currency,
   loading: state.pricingCostSummary.loading,
   levelName: state.pricingCostSummary.costSummaryById.levelName,
   costAnalysisPerVolume: state.pricingCostDrivers.detailPerVolumeId.result,
   volumeId: state.pricingCostDrivers.volumeId,
   isEditing: state.pricingCostDrivers.isEditing,
   isSimulate: state.pricingCostDrivers.isSimulate,
   dataToSendAdds: state.pricingCostDrivers.dataToSend.adds,
   prevPage: state.pricingCostDrivers.prevPage,
   thirdPartyId: state.pricingCostDrivers.thirdPartyId,
   detailPerVolumeId: state.pricingCostDrivers.detailPerVolumeId.result,
  }),
  shallowEqual
 );

 const searchParams = new URLSearchParams(location.search);

 const handleChange = async (event: any) => {
  const currentOption = event.currentTarget.selectedIndex;
  const volumeId = event.currentTarget.options[currentOption].id;
  Swal.fire({
   showCancelButton: true,
   html:
    "The changes that you have made will be lost. Are you sure you want to change and reset the simulation?",
   confirmButtonText: "Yes",
   cancelButtonText: "No",
  }).then(async (result) => {
   if (result.isConfirmed) {
    setLoadingChangeVolume(true);
    await dispatch(pricingCostDriverAction.resetTmpChangeRow());
    await dispatch(pricingCostDriverAction.setVolumeId(volumeId));
    await dispatch(
     pricingCostDriverAction.setCostAnalysisPerVolumeId(volumeId)
    );
    if (volumeId) {
     const payload = {
      costAnalysisId: pricingId,
      perVolumeId: volumeId,
     };
     await dispatch(
      pricingCostDriverAction.getDetailCostDriversVolume(payload)
     );
    }
    setCurrentVolume(volumeId);
    if (searchParams.get("thirdPartyId")) {
     await history.push({
      pathname: `${location.pathname}?thirdPartyId=${searchParams.get(
       "thirdPartyId"
      )}&&thirdPartyName=${searchParams.get(
       "thirdPartyName"
      )}&defauldVolume=${volumeId}`,
      state: location.state,
     });
    }
   }
  });
 };

 useEffect(() => {
  if (thirdParties.length > 0 && searchParams.get("thirdPartyId")) {
   const payload = {
    id: pricingId,
    thirdPartyId: searchParams.get("thirdPartyId"),
   };
   const functionSetthirdParty = async () => {
    await dispatch(
     pricingCostDriverAction.setThirdPartyId(searchParams.get("thirdPartyId"))
    );
    await dispatch(pricingCostSummaryAction.pricingCostSummary(payload));
   };
   functionSetthirdParty();
   setCurrentThirdParty(searchParams.get("thirdPartyName"));
  }
 }, [
  thirdParties,
  searchParams.get("thirdPartyName"),
  searchParams.get("thirdPartyId"),
 ]);

 useEffect(() => {
  setLoadingChangeVolume(false);
  if (costAnalysisPerVolume) {
   const newCostDrivers: any = [];
   costAnalysisPerVolume.costDrivers.map((item: any) => {
    if (item.parentCostDriver === null) {
     newCostDrivers.push(item);
    }
   });
  }
 }, [costAnalysisPerVolume]);

 useEffect(() => {
  Array.isArray(volumes) &&
   volumes.length > 0 &&
   volumes.map((defaultVolume: any) => {
    let costDriverData = [] as any;
    const { costDrivers, id, volume } = defaultVolume;

    if (isChangeVolume != true) {
     setCurrentVolume(id);
    }

    dispatch(pricingCostDriverAction.setVolumeId(id));
    dispatch(pricingCostDriverAction.setLevelName(levelName));

    const costDriverDefaultVolume = [] as any;
    costDrivers.map((v: any, i: number) => {
     if (v.parentCostDriver === null) {
      costDriverDefaultVolume.push({
       sequence: v.configuration.sequence,
       data: v,
      });
     }
    });

    costDriverDefaultVolume.sort(function (a: any, b: any) {
     let keyA = a.sequence,
      keyB = b.sequence;
     if (keyA < keyB) return -1;
     if (keyA > keyB) return 1;
     return 0;
    });

    costDriverDefaultVolume.map((value: any) => {
     costDriverData.push(value.data);
    });
    setData(costDriverData);
   });
  if (!stepperReff) {
   scrollToStepper();
  }
 }, [volumes, loading]);
 const stepperData: any = [];
 Array.isArray(data) &&
  data.length > 0 &&
  data.map((value: any, index: number) => {
   const pageName = value.configuration.identityName
    .replace(/\s+/g, "-")
    .toLowerCase();
   const sequence = startSequence + index;
   const description = value.configuration.identityName;

   const newStepper = {
    sequence: sequence,
    pageName: pageName,
    description: description,
   };
   if (pageName === pricingPage) {
    currentSeq.push(sequence);
   } else {
    //
   }
   stepperData.push(newStepper);
  });

 const prevRef = useRef(null);
 const nextRef = useRef(null);

 useEffect(() => {
  if (stepperData) {
   var stepperTo = stepperData.find((i: any) => i.pageName === pricingPage);
   setIndexStepper(stepperTo?.sequence);
  } else {
   setIndexStepper(0);
  }
 }, [pricingPage, stepperData]);

 const totalSlideStepper: number = stepperData.length + 2;

 if (loading) {
  return (
   <Box
    pl="10"
    backgroundColor="#f7f9fd"
    borderRadius="40px"
    p="25px 3rem 19px 3rem"
    // w={levelName === "Does Cost" ? "35%" : "75%"}
   >
    <Box gap={7} display="flex" alignItems="center">
     <StepperLoading />
     <StepperLoading />
     <StepperLoading />
     <StepperLoading />
    </Box>
   </Box>
  );
 }

 return (
  <Flex>
   <Box
    // position="relative"
    w={levelName === "Does Cost" ? "35%" : "80%"}
    // h="105.5px"
    mt="2rem"
   >
    {!loading && (
     <>
      <Image
       src={LeftIcon}
       alt=""
       position="absolute"
       left="-30px"
       h="30px"
       cursor="pointer"
       zIndex="3"
       top="2rem"
       ref={prevRef}
      />
      <Image
       src={RightIcon}
       alt=""
       position="absolute"
       right="-30px"
       h="30px"
       cursor="pointer"
       zIndex="3"
       top="2rem"
       ref={nextRef}
      />
     </>
    )}

    {!loading && (
     <Swiper
      slidesPerView={totalSlideStepper}
      freeMode={true}
      pagination={{
       clickable: true,
      }}
      navigation={{
       prevEl: prevRef.current ? prevRef.current : undefined,
       nextEl: nextRef.current ? nextRef.current : undefined,
      }}
      modules={[Navigation]}
      className="mySwiper"
      onInit={(swiper: any) => {
       swiper.params.navigation.prevEl = prevRef.current;
       swiper.params.navigation.nextEl = nextRef.current;
       swiper.navigation.init();
       swiper.navigation.update();
      }}
      onSwiper={(swiper) => {
       swiper.slideTo(indexStepper, 1000);
      }}
     >
      {!loading && (
       <>
        <SwiperSlide>
         {!loading && (
          // <Box as="span">
          <Flex>
           <Box>
            <Circle
             size="20px"
             bg={pricingPage === "sku-characteristic" ? "#ffffff" : ""}
             color="white"
             border={
              pricingPage !== "sku-characteristic"
               ? "none"
               : "1px solid #0072CF"
             }
            >
             <Box as="span">
              <Circle
               size={pricingPage !== "sku-characteristic" ? "30px" : "27px"}
               cursor="pointer"
               bg={
                pricingPage === "sku-characteristic"
                 ? "#0072CF"
                 : currentSeq > generalDataSequence
                 ? "#0072CF"
                 : pricingPage === "cost-summary-by-sku"
                 ? "#0072CF"
                 : "#D7DBE2"
               }
               color="white"
               fontWeight="600"
               fontSize="12px"
               ref={stepperReff}
               onClick={() => {
                dispatch(pricingCostDriverAction.setIsEditOpen(false));
                dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
                dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
                if (searchParams.get("thirdPartyId")) {
                 history.push({
                  pathname: `/simulation-by-sku/sku-characteristic/${pricingId}?thirdPartyName=${searchParams.get(
                   "thirdPartyName"
                  )}&&thirdPartyId=${searchParams.get("thirdPartyId")}`,
                  state: location.state,
                 });
                } else {
                 history.push({
                  pathname: `/simulation-by-sku/sku-characteristic/${pricingId}`,
                  state: location.state,
                 });
                }

                if (searchParams.get("defauldVolume")) {
                 history.push({
                  pathname: `/simulation-by-sku/sku-characteristic/${pricingId}?defaultVolume=${searchParams.get(
                   "defauldVolume"
                  )}`,
                  state: location.state,
                 });
                }
               }}
              >
               0
              </Circle>
             </Box>
            </Circle>
           </Box>
           <Box
            fontSize="13px"
            cursor="pointer"
            fontWeight="600"
            pl="3"
            color={pricingPage === "sku-characteristic" ? "" : "#7B7F85"}
            onClick={() => {
             dispatch(pricingCostDriverAction.setIsEditOpen(false));
             dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
             dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
             if (searchParams.get("thirdPartyId")) {
              history.push(
               `/simulation-by-sku/sku-characteristic/${pricingId}?thirdPartyName=${searchParams.get(
                "thirdPartyName"
               )}&&thirdPartyId=${searchParams.get("thirdPartyId")}`
              );
             } else {
              history.push({
               pathname: `/simulation-by-sku/sku-characteristic/${pricingId}`,
               state: location.state,
              });
             }
             if (searchParams.get("defauldVolume")) {
              history.push({
               pathname: `/simulation-by-sku/sku-characteristic/${pricingId}?defauldVolume=${searchParams.get(
                "defauldVolume"
               )}`,
               state: location.state,
              });
             }
            }}
           >
            SKU Characteristic
           </Box>

           {levelName && (
            <Box
             w="100px"
             h="2px"
             mt="0.5rem"
             bgColor={
              pricingPage !== "sku-characteristic" &&
              pricingPage !== "cost-summary-by-sku"
               ? "#0072CF"
               : pricingPage === "cost-summary-by-sku"
               ? "#0072CF"
               : "#D7DBE2"
             }
             ml="0.7rem"
            />
           )}
          </Flex>
         )}
        </SwiperSlide>
       </>
      )}

      {Array.isArray(stepperData) &&
       !loading &&
       stepperData.length > 0 &&
       stepperData.map((value: any) => {
        const maxIndex = startSequence + data.length;
        const { sequence, pageName, description } = value;
        return (
         <SwiperSlide key={sequence}>
          <Flex
           onClick={() => {
            dispatch(pricingCostDriverAction.setIsEditOpen(false));
            dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
            dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
            if (searchParams.get("thirdPartyId")) {
             history.push({
              pathname: `/simulation-by-sku/${pageName}/${pricingId}?thirdPartyName=${searchParams.get(
               "thirdPartyName"
              )}&&thirdPartyId=${searchParams.get("thirdPartyId")}`,
              state: location.state,
             });
            } else {
             history.push({
              pathname: `/simulation-by-sku/${pageName}/${pricingId}`,
              state: location.state,
             });
            }
            if (searchParams.get("defauldVolume")) {
             history.push({
              pathname: `/simulation-by-sku/${pageName}/${pricingId}?defauldVolume=${searchParams.get(
               "defauldVolume"
              )}`,
              state: location.state,
             });
            }
           }}
          >
           <Box>
            <Circle
             size="20px"
             ml="0.5rem"
             mr="0.5rem"
             bg={pricingPage === pageName ? "#ffffff" : ""}
             color="white"
             border={pricingPage !== pageName ? "none" : "1px solid #0072CF"}
            >
             <Box as="span">
              <Circle
               size={pricingPage !== pageName ? "30px" : "27px"}
               cursor="pointer"
               bg={
                pricingPage === pageName
                 ? "#0072CF"
                 : currentSeq > sequence
                 ? "#0072CF"
                 : pricingPage === "cost-summary-by-sku"
                 ? "#0072CF"
                 : "#D7DBE2"
               }
               color="white"
               fontWeight="600"
               fontSize="12px"
               ref={stepperReff}
              >
               {sequence - 1}
              </Circle>
             </Box>
            </Circle>
            {/* {maxIndex !== sequence + 1 && ( */}
            {/* )} */}
           </Box>
           <Box
            fontSize="13px"
            cursor="pointer"
            fontWeight="600"
            pl="3"
            color={pricingPage === pageName ? "" : "#7B7F85"}
           >
            {description}
           </Box>

           <Box
            w="100px"
            h="2px"
            mt="0.5rem"
            bgColor={
             currentSeq > sequence
              ? "#0072CF"
              : pricingPage === "cost-summary-by-sku"
              ? "#0072CF"
              : "#D7DBE2"
            }
            ml="0.7rem"
            mr="0.7rem"
           />
           {/* </Box> */}
          </Flex>
         </SwiperSlide>
        );
       })}
      <SwiperSlide>
       {!loading && (
        <Flex>
         <Box>
          <Circle
           size="20px"
           bg={pricingPage === "cost-summary-by-sku" ? "#ffffff" : ""}
           color="white"
           border={
            pricingPage === "cost-summary-by-sku" ? "1px solid #0072CF" : ""
           }
          >
           <Box as="span">
            <Circle
             size={pricingPage !== "cost-summary-by-sku" ? "30px" : "27px"}
             cursor="pointer"
             bg={pricingPage === "cost-summary-by-sku" ? "#0072CF" : "#D7DBE2"}
             color="white"
             fontWeight="600"
             fontSize="12px"
             ref={stepperReff}
             border={
              pricingPage !== "cost-summary-by-sku"
               ? "none"
               : "1px solid #0072CF"
             }
             onClick={() => {
              dispatch(pricingCostDriverAction.setIsEditOpen(false));
              dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
              dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
              if (searchParams.get("thirdPartyId")) {
               history.push(
                `/simulation-by-sku/cost-summary-by-sku/${pricingId}?thirdPartyName=${searchParams.get(
                 "thirdPartyName"
                )}&&thirdPartyId=${searchParams.get("thirdPartyId")}`
               );
              } else {
               history.push({
                pathname: `/simulation-by-sku/cost-summary-by-sku/${pricingId}`,
                state: location.state,
               });
              }
              if (searchParams.get("defauldVolume")) {
               history.push({
                pathname: `/simulation-by-sku/cost-summary-by-sku/${pricingId}?defauldVolume=${searchParams.get(
                 "defauldVolume"
                )}`,
                state: location.state,
               });
              }
             }}
            >
             {stepperData.length + 1}
            </Circle>
           </Box>
          </Circle>
         </Box>
         <Box
          as="p"
          fontSize="13px"
          cursor="pointer"
          fontWeight="600"
          pl="3"
          color={pricingPage === "cost-summary-by-sku" ? "" : "#7B7F85"}
          onClick={() => {
           dispatch(pricingCostDriverAction.setIsEditOpen(false));
           dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
           dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
           if (searchParams.get("thirdPartyId")) {
            history.push({
             pathname: `/simulation-by-sku/cost-summary-by-sku/${pricingId}?thirdPartyName=${searchParams.get(
              "thirdPartyName"
             )}&&thirdPartyId=${searchParams.get("thirdPartyId")}`,
             state: location.state,
            });
           } else {
            history.push({
             pathname: `/simulation-by-sku/cost-summary-by-sku/${pricingId}`,
             state: location.state,
            });
           }
           if (searchParams.get("defauldVolume")) {
            history.push({
             pathname: `/simulation-by-sku/cost-summary-by-sku/${pricingId}?defauldVolume=${searchParams.get(
              "defauldVolume"
             )}`,
             state: location.state,
            });
           }
          }}
         >
          Result
         </Box>
        </Flex>
       )}
      </SwiperSlide>
     </Swiper>
    )}
   </Box>
   <Spacer />
   <Box>
    {/* <Grid>
     {pricingPage && (
      <Box
       ml="1.5rem"
       border="1px solid #D7DBE2"
       borderRadius="40"
       w="12.8rem"
       p="17px 18px 17px 18px"
      >
       <Box as="p" color="#000000" fontSize="13px">
        You have selected: <br />
        <b>
         {productName}
         {getThirdParty && getThirdParty[0]?.thirdParty && (
          <> - {getThirdParty[0]?.thirdParty?.code} </>
         )}
        </b>
        <br />
        <Box display="flex" alignItems="baseline" position="relative">
         <Box as="span">with</Box>
         <Select
          ml="5px"
          variant="outline"
          w="250px"
          h="20px"
          borderColor="#39A1F2"
          color="#0072CF"
          fontWeight={600}
          borderRadius="40px"
          mt="0.5rem"
          fontSize="10px"
          onChange={(e) => handleChange(e)}
          value={volumeId}
         >
          {Array.isArray(volumes) &&
           volumes.length > 0 &&
           volumes.map(({ volume, id, thirdParty }) => {
            return (
             <option key={volume} value={id} id={id}>
              Volume: {String.toFormatSeparator(volume, 0)}
              {thirdParty && " - "}
              {thirdParty && thirdParty.code}
             </option>
            );
           })}
         </Select>
        </Box>
        <br />
        <Text>Version: {costAnalysisPerVolume?.version}.</Text>
       </Box>
      </Box>
      //  )}
      //  {pricingPage === "sku-characteristic" && (
      //   <Box
      //    ml="1.5rem"
      //    border="1px solid #D7DBE2"
      //    borderRadius="40"
      //    w="12.8rem"
      //    p="17px 18px 17px 18px"
      //   >
      //    <Box as="p" color="#000000" fontSize="13px">
      //     You are currently seeing: <br />
      //     <b>
      //      {productName} -{" "}
      //      {thirdPartyName &&
      //       thirdPartyName.length > 0 &&
      //       thirdPartyName[0].description}
      //     </b>
      //     <br />
      //     <Box display="flex" alignItems="baseline" position="relative">
      //      <Box as="span">For</Box>

      //      <Select
      //       ml="5px"
      //       variant="outline"
      //       w="250px"
      //       h="20px"
      //       borderColor="#39A1F2"
      //       color="#0072CF"
      //       fontWeight={600}
      //       borderRadius="40px"
      //       mt="0.5rem"
      //       fontSize="10px"
      //       placeholder="All Third-Party"
      //       onChange={(e) => handleSelectThirdParty(e)}
      //       value={currentThirdParty}
      //      >
      //       {Array.isArray(thirdParties) &&
      //        thirdParties.length > 0 &&
      //        thirdParties.map(({ code, id }) => {
      //         return (
      //          <option key={code} value={code} id={id}>
      //           {code}
      //          </option>
      //         );
      //        })}
      //      </Select>
      //     </Box>
      //     <br />
      //    </Box>
      //   </Box>
      //  )}
     )}
    </Grid> */}
   </Box>
  </Flex>
 );
};

export default Stepper;
