const HeadingStyled = {
    sizes: {
        md: {
            fontSize: '24px',
            wight: 600,
            lineHeight: '29.05px'
        },
        sm: {
            fontSize: '20px',
            wight: 600,
            lineHeight: '24.2px'
        },
    },
    defaultProps: {
        size: 'md'
    }
}


export default HeadingStyled;