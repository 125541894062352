import { ReactElement } from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, Heading } from "@chakra-ui/layout";
import {
 Breadcrumb,
 BreadcrumbItem,
 BreadcrumbLink,
} from "@chakra-ui/react";
import Layout from "views/Layout";
import { ChevronRightIcon } from "@chakra-ui/icons";
import ThirdPartyTable from "./ListTable";
import ModalAddUser from "./Modal";
import ThirdPartyTableEdit from "./Edit";

const UserManagement = (): ReactElement => {
 const history = useHistory();
 const isEdited =
  window.location.pathname === "/settings/third-party/user-management";

 return (
  <Layout>
   <Heading as="h2" fontSize="38px" mb="2rem">
    Settings
   </Heading>
   <Box mb="2rem">
    <Breadcrumb
     borderRadius="20px"
     w="50%"
     h="50"
     spacing="8px"
     separator={<ChevronRightIcon color="gray.500" />}
    >
     <BreadcrumbItem onClick={() => history.goBack()}>
      <BreadcrumbLink fontWeight={"600"} color="gray.500">
       Third-Party Configuration
      </BreadcrumbLink>
     </BreadcrumbItem>
     <BreadcrumbItem>
      <BreadcrumbLink fontWeight={"600"}>
       Third-Party User Management
      </BreadcrumbLink>
     </BreadcrumbItem>
    </Breadcrumb>
   </Box>
   {isEdited && <ModalAddUser />}
   {window.location.pathname === "/settings/third-party/user-management" ? (
    <ThirdPartyTable />
   ) : (
    <ThirdPartyTableEdit />
   )}
  </Layout>
 );
};

export default UserManagement;
