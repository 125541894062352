import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { NumberLoading, TextLoading } from "components/Loading";
import { Box, Grid, Flex, Text, GridItem } from "@chakra-ui/layout";
import { Button, Stat, StatLabel, StatHelpText } from "@chakra-ui/react";
import Layout from "views/Layout";

const ThirdParty = (): ReactElement => {
 return (
  <Box>
   <Grid gap={6}>
    <GridItem>
     <Link to="/settings/third-party">
      <Stat>
       <StatLabel fontSize={18}>Third-Party List</StatLabel>
       <StatHelpText>
        You can maintain your Third-Party List via this configuration.
       </StatHelpText>
      </Stat>
     </Link>
    </GridItem>
    <GridItem>
     <Link to="/settings/third-party/user-management">
      <Stat>
       <StatLabel fontSize={18}>Third-Party User Management</StatLabel>
       <StatHelpText>
        You can maintain and assign Third-Party to your tenant users via this
        configuration.
       </StatHelpText>
      </Stat>
     </Link>
    </GridItem>
   </Grid>
  </Box>
 );
};

export default ThirdParty;
