import { FC, useState, useEffect } from "react";
import s from "shortid";
import {
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 VStack,
 Stack,
 Button,
 Text,
 ModalProps,
 FormControl,
 Image,
 HStack,
 Checkbox,
 Flex,
 Spacer,
 Box,
 IconButton,
 Divider,
 Tooltip,
 border,
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { JourneyFormInitial, CustomField } from "types/journeys";
import { actions as myJourneyActions } from "state/myJourney/slice";
import { JourneyInitialValues } from "constants/form";
import DeleteButton from "assets/images/Delete.png";
import Select from "components/Select";
import TextInput from "components/TextInput";
import OptipediaToast from "utils/notification-util";
import { FieldArray, Form, Formik } from "formik";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import * as Yup from "yup";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import { actions as masterDataConfigurationAction } from "state/masterDataConfiguration/slice";
import { actions as sizeOfUnitAction } from "state/sizeOfPricingUnit/slice";
import { FiAlertCircle } from "react-icons/fi";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
import FX from "assets/images/calculation.svg";
import Formula from "../ModalFormula";

type ReportListModalProps = Omit<ModalProps, "children"> & {
 productConfiguration: {
  identityName: string;
  identityCode: string;
  journeyCode: string;
  journeyName: string;
  id: string;
  labelName: string;
  labelCost: string;
  labelTotalCost: string;
  totalCostFormulaExpression: string;
  type: number;
  customFields: CustomField[];
  productLabelName: string;
  isPercentage: boolean;
  isMainCostDriver: boolean;
  labelDescription: string;
  volumeType: number;
  stage: number;
  isEnableThirdParty: boolean;
  sizeUnit: any;
  sizeUnitId: string;
  thirdPartyLabelName: string;
  isMaintainWithMasterData: boolean;
  masterDataConfigurationId: string;
  masterDataConfiguration: any;
 };
};

const JourneyAddEditModal: FC<ReportListModalProps> = ({
 isOpen,
 onClose,
 productConfiguration = JourneyInitialValues,
}) => {
 const dispatch = useAppDispatch();
 const params = useParams();
 const { id: paramId } = params as any;
 const {
  isEditModal,
  isEnableThirdPartyStatus,
  searchQuery,
  levelResponse,
  formulaList,
  sizeOfUnitList,
 } = useSelector(
  (state: RootState) => ({
   loadingAddCustomField: state.myJourney.loadingAddCustomField,
   isEditModal: state.myJourney.isEditModalJourney,
   levelResponse: state.myJourney.levelResponse.result,
   isEnableThirdPartyStatus: state.thirdPartySetting.isEnableThirdPartyStatus,
   searchQuery: state.masterData.searchQuery,
   masterDataConfigList: state.masterDataConfiguration.lists,
   masterDataDetail: state.masterDataConfiguration.data,
   formulaList: state.myJourney.formulaFields,
   sizeOfUnitList: state.sizeOfUnit.lists,
  }),
  shallowEqual
 );

 const [isLoadingCustomField, setIsLoadingCustomField] =
  useState<boolean>(false);
 const [isLoadingCustomFieldId, setIsLoadingCustomFieldId] = useState("");
 const [isLoadingDeleteCustomFieldId, setIsLoadingDeleteCustomFieldId] =
  useState("");
 const [newAdditionalField, setNewAdditionalField] = useState(false);
 const [initialValues, setInitialValues] =
  useState<JourneyFormInitial>(JourneyInitialValues);
 const [masterDataWithCost, setMasterDataWithCost] = useState<any>([]);
 const [masterDataWithoutCost, setMasterDataWithoutCost] = useState<any>([]);
 const [isDataType, setIsDataType] = useState<any>();
 const [isMaintainThirdParty, setIsMaintainThirdParty] =
  useState<boolean>(false);
 const [isMaintainMasterData, setIsMaintainmasterData] =
  useState<boolean>(false);
 const [openFormula, setOpenFormula] = useState<boolean>(false);
 const [openFormulaCustomField, setOpenFormulaCustomField] =
  useState<string>('');

 useEffect(() => {
  dispatch(thirdPartySettingAction.configurationStatus());
 }, [isEnableThirdPartyStatus]);

 useEffect(() => {
  if (productConfiguration) {
   setInitialValues(productConfiguration);
   setIsMaintainmasterData(productConfiguration.isMaintainWithMasterData);
  } else setInitialValues(JourneyInitialValues);
  setNewAdditionalField(false);
 }, [productConfiguration]);

 useEffect(() => {
  getMasterDataConfiguration(0);
  if (isMaintainMasterData) {
   getMasterDataConfiguration(levelResponse?.isEnableThirdParty ? 1 : 3);
  }
 }, [isMaintainMasterData]);

 useEffect(() => {
  dispatch(myJourneyActions.getFormulaField());
  dispatch(sizeOfUnitAction.getSizeUnit());
 }, []);

 const getMasterDataConfiguration = (type: number) => {
  const payload = {
   skipCount: 0,
   maxResultCount: 100,
   searchKey: searchQuery,
   type,
  };
  dispatch(
   masterDataConfigurationAction.masterDataConfigurationsList(payload)
  ).then((response: any) => {
   if (type !== 0) {
    setMasterDataWithCost(response.payload.result.items);
   } else {
    setMasterDataWithoutCost(response.payload.result.items);
   }
  });
 };

 const handleDeleteCostDriver = async () => {
  Swal.fire({
   showCancelButton: true,
   html: "Are you sure you want to delete this Cost Driver?",
   confirmButtonText: "Yes",
   cancelButtonText: "No",
  }).then(async (result) => {
   if (result.isConfirmed) {
    await dispatch(
     myJourneyActions.deleteCostDriver({ costDriverId: initialValues.id })
    ).then((res) => {
     if (res.payload) {
      OptipediaToast.Success("The Cost Driver has been deleted successfully.");
     }
    });

    await dispatch(myJourneyActions.getById(params));
    onClose();
   }
  });
 };

 const validation = Yup.object().shape({
  identityName: Yup.string().max(
   24,
   "The text input has exceed the maximum characters."
  ),
  identityCode: Yup.string().max(
   8,
   "The text input has exceed the maximum characters."
  ),
  journeyName: Yup.string().max(
   24,
   "The text input has exceed the maximum characters."
  ),
  journeyCode: Yup.string().max(
   8,
   "The text input has exceed the maximum characters."
  ),
  productLabelName: Yup.string().max(
   32,
   "The text input has exceed the maximum characters."
  ),
  productLabelDescription: Yup.string().max(
   128,
   "The text input has exceed the maximum characters."
  ),
  uoMLabelName: Yup.string().max(
   32,
   "The text input has exceed the maximum characters."
  ),
  currencyLabelName: Yup.string().max(
   32,
   "The text input has exceed the maximum characters."
  ),
  volumeLabelName: Yup.string().max(
   32,
   "The text input has exceed the maximum characters."
  ),
  formatLabelName: Yup.string().max(
   32,
   "The text input has exceed the maximum characters."
  ),
  volumeType: Yup.string().max(
   32,
   "The text input has exceed the maximum characters."
  ),

  // name: Yup.string().max(64, 'The text input has exceed the maximum characters.'),
  labelDescription: Yup.string().max(
   128,
   "The text input has exceed the maximum characters."
  ),
  // isMainCostDriver: Yup.string().max(32, 'The text input has exceed the maximum characters.'),
  // isPercentage: Yup.string().max(8, 'The text input has exceed the maximum characters.'),
  // cost: Yup.string().max(
  //  32,
  //  "The text input has exceed the maximum characters."
  // ),
  isEnableThirdParty: Yup.boolean(),
  thirdPartyLabelName:
   isMaintainThirdParty || isMaintainThirdParty === undefined
    ? Yup.string().required(
       "Please fill this information before saving the data."
      )
    : Yup.string(),
  sizeUnitId: Yup.string().required(
   "Please fill this information before saving the data."
  ),
 });

 const handleSubmit = async (values: any) => {
  const {
   identityName,
   identityCode,
   journeyName,
   journeyCode,
   productLabelName,
   productLabelDescription,
   uoMLabelName,
   currencyLabelName,
   volumeLabelName,
   formatLabelName,
   volumeType,
   labelName,
   labelCost,
   labelTotalCost,
   labelDescription,
   totalCostFormulaExpression,
   isMainCostDriver,
   isPercentage,
   isEnableThirdParty,
   sizeUnit,
   sizeUnitId,
   thirdPartyLabelName,
   isMaintainWithMasterData,
   masterDataConfigurationId,
   masterDataConfiguration,
  } = values;
  if (
   identityName === "" ||
   identityCode === "" ||
   labelName === "" ||
   labelDescription === "" ||
   labelCost === "" ||
   labelTotalCost === ""
  ) {
   return OptipediaToast.Warning(
    "Please fill in all of the required information before saving the data."
   );
  }
const checkItSaved = values?.customFields?.filter((i:any) => i.itSave === false)
  if (checkItSaved.length > 0) {
   OptipediaToast.Error(
    "Some of the changes in the Additional Fields have not been saved."
   );
  } else if (!values.uoMLabelName) {
   // to differentiate Product Configuration or Cost Driver, true for Cost Driver
   if (isEditModal) {
    const payload = {
     identityName,
     identityCode,
     labelName,
     labelDescription,
     isMainCostDriver,
     isPercentage,
     labelCost,
     labelTotalCost,
     totalCostFormulaExpression,
     costDriverId: values.id,
     isEnableThirdParty,
     sizeUnit,
     sizeUnitId: sizeUnitId || sizeUnit?.id,
     thirdPartyLabelName,
     isMaintainWithMasterData,
     masterDataConfigurationId:
      masterDataConfigurationId || masterDataConfiguration?.id,
    };
    
    let existingValue = Object.assign({}, values);

    await dispatch(myJourneyActions.editWithoutStaticProduct(payload)).then(
     (result: any) => {
      if (result?.error?.message != "Rejected") {
       var newCostDriver = Object.assign({}, result.payload.result);
       newCostDriver.customFields = existingValue.customFields;
       setInitialValues(newCostDriver);
   dispatch(myJourneyActions.getById(params));
      }
     }
    );
   } else {
    const payload = {
     identityName,
     identityCode,
     labelName,
     labelDescription,
     isMainCostDriver,
     isPercentage,
     labelCost: labelCost,
     labelTotalCost: labelTotalCost,
     totalCostFormulaExpression: totalCostFormulaExpression,
     parentCostDriverId: values.id,
     isEnableThirdParty,
     sizeUnit,
     sizeUnitId: sizeUnitId || sizeUnit.id,
     thirdPartyLabelName,
     isMaintainWithMasterData,
     masterDataConfigurationId:
      masterDataConfigurationId || masterDataConfiguration.id,
    };
    await dispatch(myJourneyActions.addNewCostDriver(payload)).then(
     (result: any) => {
      if (result?.error?.message != "Rejected") {
       setInitialValues(result.payload.result);

       dispatch(myJourneyActions.setIsEditModalJourney(true));
       dispatch(myJourneyActions.getById(params));
      }
     }
    );
   }
  } else {
   const payload = {
    id: values.id,
    journeyCode,
    journeyName,
    productLabelName,
    productLabelDescription,
    uoMLabelName,
    currencyLabelName,
    volumeLabelName,
    formatLabelName,
    volumeType,
    isEnableThirdParty,
    thirdPartyLabelName: isEnableThirdParty ? thirdPartyLabelName : "",
    sizeUnit,
    sizeUnitId: sizeUnitId || sizeUnit.id,
    isMaintainWithMasterData,
    masterDataConfigurationId:
     masterDataConfigurationId || masterDataConfiguration?.id,
   };
   await dispatch(myJourneyActions.edit(payload));
   dispatch(myJourneyActions.setCustomFieldResponse(true));
   dispatch(myJourneyActions.getById(params));
  }
 };
 const masterDataWithoutCostOption: any = [];
 if (masterDataWithoutCost?.length > 0) {
  masterDataWithoutCost.map((item: any) => {
   masterDataWithoutCostOption.push({
    label: item.identityName,
    value: item.id,
   });
  });
 }
 const masterDataWithCostOption: any = [];
 if (masterDataWithCost?.length > 0) {
  masterDataWithCost.map((item: any) => {
   masterDataWithCostOption.push({
    label: item.identityName,
    value: item.id,
   });
  });
 }

 const sizeOfUnitOption: any = [];
 if (sizeOfUnitList?.items?.length > 0) {
  sizeOfUnitList.items.map((item: any) => {
   sizeOfUnitOption.push({
    label: item.name,
    value: item.id,
   });
  });
 }
console.log(initialValues);

 return (
  <>
   <Modal
    closeOnEsc={false}
    size="6xl"
    isOpen={isOpen}
    onClose={()=> {
      onClose()
      dispatch(myJourneyActions.setResetCostDriverFields([]))
    }}
    isCentered
   >
    <ModalOverlay />
    <ModalContent
     boxShadow="0px 0px 56px rgba(157, 161, 167, 0.37)"
     borderRadius="5px"
     overflowX="hidden"
     height="unset"
     minW={"95%"}
    >
     <ModalHeader fontSize="24px" textTransform="capitalize">
      <HStack spacing="24px">
       <Text>
        {initialValues?.identityName || initialValues?.journeyName
         ? `Edit ${initialValues?.identityName || initialValues?.journeyName}`
         : "Add New"}
       </Text>
       {!initialValues?.volumeType && (
        <Image
         src={DeleteButton}
         onClick={handleDeleteCostDriver}
         cursor="pointer"
         alt="Delete Cost Driver"
        />
       )}
      </HStack>
     </ModalHeader>
     <ModalCloseButton mt="1.5rem" mr="1rem" _focus={{ boxShadow: "none" }} />
     <ModalBody>
      <Box
       overflowY="scroll"
       overflowX="hidden"
       pr="10px"
       marginBottom="4rem"
       sx={{
        "&::-webkit-scrollbar": { width: "6px" },
        "&::-webkit-scrollbar-track": { width: "6px" },
        "&::-webkit-scrollbar-thumb": {
         borderRadius: "24px",
         backgroundColor: "#0072CF",
        },
       }}
       height={`${window.innerHeight - 200}px`}
      >
       <Formik
        enableReinitialize
        initialValues={initialValues}
        // validationSchema={validation}
        onSubmit={handleSubmit}
       >
        {({
         values,
         setFieldValue,
         isSubmitting,
         handleChange,
         setFieldError,
        }) => (
         <Form style={{ width: "100%" }}>
          {!initialValues?.journeyName && (
           <Box
            border={"1px solid rgba(26, 26, 26, 0.16)"}
            borderRadius={5}
            px={4}
            pt={"2rem"}
            mb="2rem"
           >
            <Box mb="2rem">
             <Stack
              direction={["column", "row"]}
              align="flex-start"
              spacing="10"
             >
              <VStack spacing="6" w={["100%", "40%"]}>
               <TextInput
                name="identityCode"
                label="Identity Code*"
                id="identityCode"
                placeholder="e.g. Identity Code"
               />
              </VStack>
              {!initialValues.haveCostDriver && initialValues.costDrivers?.length === 0 && (
               <VStack spacing="6" align="stretch">
                <Stack
                 direction={["column", "row"]}
                 align="flex-start"
                 spacing="10"
                 mt="1rem"
                >
                 <VStack spacing="6" ml="1.5rem">
                  <Checkbox
                   name="isMaintainWithMasterData"
                   isChecked={values.isMaintainWithMasterData}
                   onChange={(e) => {
                    setFieldValue(
                     "isMaintainWithMasterData",
                     e.currentTarget.checked
                    );
                    setIsMaintainmasterData(e.currentTarget.checked);
                    if (!e.currentTarget.checked) {
                     setFieldValue(
                      "masterDataConfigurationId",
                      initialValues.masterDataConfigurationId
                     );
                     setFieldValue("labelName", productConfiguration.labelName);
                     setFieldValue(
                      "labelDescription",
                      productConfiguration.labelDescription
                     );
                     setFieldValue("labelCost", productConfiguration.labelCost);
                    }
                   }}
                  >
                   Maintain with Master Data
                  </Checkbox>
                 </VStack>
                 <VStack spacing="6">
                  <Tooltip
                   hasArrow
                   placement="right"
                   label="you will use master data has been you setting for this cost driver"
                   closeOnClick={false}
                  >
                   <Text color="#D9D9D9" fontSize={"22px"}>
                    <FiAlertCircle />
                   </Text>
                  </Tooltip>
                 </VStack>
                </Stack>
               </VStack>
              )}
             </Stack>
            </Box>
            <Box mb="2rem">
             <Stack
              direction={["column", "row"]}
              align="flex-start"
              spacing="10"
             >
              <VStack spacing="6" w={["100%", "40%"]}>
               <TextInput
                name="identityName"
                label="Identity Name*"
                id="identityName"
                placeholder="e.g. Identity Name"
               />
              </VStack>
              <VStack spacing={4} align="stretch" ml="2rem">
               <Box ml="1.5rem">
                {!initialValues.haveCostDriver &&
                 values.isMaintainWithMasterData && (
                  <SelectWithAutoComplete
                  width={200}
                   label={"Master Data"}
                   name="masterDataConfigurationId"
                   id="masterDataConfigurationId"
                   options={masterDataWithCostOption}
                   defaultValue={
                    values.masterDataConfigurationId ||
                    values.masterDataConfiguration?.id
                   }
                   onChange={(e: any) => {
                    setFieldValue("masterDataConfigurationId", e.value);
                    dispatch(
                     masterDataConfigurationAction.masterDataConfigurationsDetails(
                      e.value
                     )
                    ).then((response: any) => {
                     const data = response.payload;
                     setFieldValue("labelName", data.code);
                     setFieldValue("labelDescription", data.description);
                     setFieldValue("labelCost", data.cost);
                     setFieldValue("labelTotalCost", "Total Cost");
                    });
                   }}
                  />
                 )}
               </Box>
              </VStack>
             </Stack>
            </Box>
           </Box>
          )}

          {initialValues?.journeyName && (
           <>
            <Box mb="2rem">
             <Stack
              direction={["column", "row"]}
              align="flex-start"
              spacing="10"
             >
              {isEnableThirdPartyStatus ? (
               <>
                <VStack spacing="6" w={["100%", "40%"]}>
                 <TextInput
                  name="journeyCode"
                  label="Journey Code*"
                  id="journeyCode"
                  placeholder="e.g. Journey Code"
                 />
                </VStack>
                <Box
                 border="1px solid #D7DBE2"
                 borderRadius="40px"
                 padding="10px"
                 pl="20px"
                 ml="1rem"
                 width={"300px"}
                >
                 {
                  <Checkbox
                   isChecked={values.isEnableThirdParty}
                   onChange={(e) => {
                    setFieldValue("isEnableThirdParty", e.target.checked);
                    setIsMaintainThirdParty(e.target.checked);
                   }}
                   name="isEnableThirdParty"
                  >
                   <Text fontWeight={"600"} fontSize="12px">
                    Enable Third-Party on this Journey
                   </Text>
                  </Checkbox>
                 }
                </Box>
               </>
              ) : (
               <VStack spacing="6" w={["100%", "40%"]}>
                <TextInput
                 name="journeyCode"
                 label="Journey Code*"
                 id="journeyCode"
                 placeholder="e.g. Journey Code"
                />
               </VStack>
              )}
             </Stack>
            </Box>
            <Box mb="2rem">
             <Stack
              direction={["column", "row"]}
              align="flex-start"
              spacing="10"
             >
              <VStack spacing="6" w={["100%", "40%"]}>
               <TextInput
                name="journeyName"
                label="Journey Name*"
                id="journeyName"
                placeholder="e.g. Journey Name"
               />
              </VStack>
             </Stack>
            </Box>
           </>
          )}

          <Stack mb="1rem">
           <Text fontSize="24px" color="#43464B" fontWeight="600">
            Required fields
           </Text>
          </Stack>
          <Box
           p={5}
           borderRadius={5}
           border={"1px solid rgba(26, 26, 26, 0.16)"}
          >
           <Stack
            mb={5}
            direction={["column", "row"]}
            align="flex-start"
            spacing="10"
           >
            <VStack spacing="6" w={["100%", "30%"]}>
             <Text fontSize="16px" fontWeight="600" color="#00050D" ml="8.5rem">
              Label name
             </Text>
            </VStack>
            <VStack spacing="6" w={["100%", "25%"]}>
             <Text ml="1rem" fontSize="16px" fontWeight="600" color="#00050D">
              Data Type
             </Text>
            </VStack>
            <VStack spacing="6" w={["100%", "20%"]}>
             <Text fontSize="16px" fontWeight="600" color="#00050D" ml="2.5rem">
              Master list
             </Text>
            </VStack>
           </Stack>

           {!productConfiguration.productLabelName && (
            <Stack
             direction={["column", "row"]}
             align="flex-start"
             spacing="10"
            >
             <VStack spacing="6" w={["100%", "40%"]} mb={4}>
              <TextInput
               name="labelName"
               label={"Cost Driver Name*"}
               id="labelName"
               placeholder="e.g. SAP Code"
               isDisabled={values.isMaintainWithMasterData}
              />
              <TextInput
               name="labelDescription"
               label={"Cost Driver Description*"}
               id="labelDescription"
               placeholder="e.g. Description"
               isDisabled={values.isMaintainWithMasterData}
              />
              <TextInput
               name="labelCost"
               label={"Cost Value*"}
               id="labelCost"
               placeholder="e.g. Cost"
               isDisabled={values.isMaintainWithMasterData}
              />
              <Stack direction={["column", "row"]} w={"100%"}>
               <TextInput
                ml={"20px"}
                name="labelTotalCost"
                label={"Total Cost*"}
                id="cost"
                placeholder="e.g. Total Cost"
                isDisabled={values.isMaintainWithMasterData}
               />
               <Box w={"50px"}>
                <IconButton
                 variant="unstyled"
                 _focus={{ border: "none 0px white" }}
                 size="custom"
                 mt="15px"
                 ml="15px"
                 w="50px"
                 onClick={() => setOpenFormula(true)}
                 aria-label="Formula"
                 icon={<Image w={"36px"} src={FX} />}
                />
                <Formula
                 name="totalCostFormulaExpression"
                 isOpen={openFormula}
                 costDriverList={levelResponse}
                 formulaFields={formulaList}
                 onClose={() => {
                  setOpenFormula(false);
                  dispatch(myJourneyActions.setValueCostDriver("1"))
                  dispatch(myJourneyActions.setResetCostDriverFields([]))
                 }}
                />
               </Box>
              </Stack>
             </VStack>
             <VStack spacing="6" w={["100%", "20%"]}>
              <Select
               isDisabled
               value="10"
               data={[
                { label: "Text Box", value: 10 },
                { label: "Number", value: 20 },
                { label: "List", value: 30 },
                { label: "Currency", value: 50 },
               ]}
               name="dataType"
               id="dataType"
              />
              <Select
               isDisabled
               value="10"
               data={[
                { label: "Text Box", value: 10 },
                { label: "Number", value: 20 },
                { label: "List", value: 30 },
                { label: "Currency", value: 50 },
               ]}
               name="dataType"
               id="dataType"
              />
              <Select
               isDisabled
               value="50"
               data={[
                { label: "Text Box", value: 10 },
                { label: "Number", value: 20 },
                { label: "List", value: 30 },
                { label: "Currency", value: 50 },
               ]}
               name="dataType"
               id="dataType"
              />
              <Select
               isDisabled
               value="40"
               data={[
                { label: "Text Box", value: 10 },
                { label: "Number", value: 20 },
                { label: "List", value: 30 },
                { label: "Calculated", value: 40 },
                { label: "Currency", value: 50 },
               ]}
               name="dataType"
               id="dataType"
              />
             </VStack>
            </Stack>
           )}

           {productConfiguration.productLabelName && (
            <>
             <Stack
              direction={["column", "row"]}
              align="flex-start"
              spacing="10"
             >
              <VStack spacing="6" w={["100%", "40%"]}>
               <TextInput
                name="productLabelName"
                label="Product ID*"
                id="productLabelName"
                placeholder="e.g. SAP Code"
               />
               <TextInput
                name="productLabelDescription"
                label="Product Description*"
                id="productLabelDescription"
                placeholder="e.g. Description"
               />
               <TextInput
                name="currencyLabelName"
                label="Currency*"
                id="currencyLabelName"
                placeholder="e.g. Currency"
               />
               <TextInput
                name="volumeLabelName"
                label="Volume*"
                id="volumeLabelName"
                placeholder="e.g. volume"
               />
               <Select
                name="volumeType"
                label="Volume Type*"
                id="volumeType"
                isCustomField
                onChange={(value: any) => {
                 setFieldValue(`volumeType`, value.currentTarget.value);
                }}
                data={[
                 { label: "Per Annum", value: 10 },
                 { label: "Per Order", value: 20 },
                ]}
               />
              </VStack>

              <VStack spacing="6" w={["100%", "25%"]}>
               <Select
                name="FieldType"
                label=""
                id="FieldType"
                placeholder="Text box"
                isCustomField
                isDisabled
                value="10"
                data={[
                 { label: "Text box", value: 10 },
                 { label: "Number", value: 20 },
                 { label: "List", value: 30 },
                 { label: "Currency", value: 50 },
                ]}
               />
               <Select
                name="FieldType"
                label=""
                id="FieldType"
                placeholder="Text box"
                value={10}
                isCustomField
                isDisabled
                data={[
                 { label: "Text box", value: 10 },
                 { label: "Number", value: 20 },
                 { label: "List", value: 30 },
                 { label: "Currency", value: 50 },
                ]}
               />
               <Select
                name="FieldType"
                label=""
                id="FieldType"
                placeholder="List"
                value={30}
                isCustomField
                isDisabled
                data={[
                 { label: "Text box", value: 10 },
                 { label: "Number", value: 20 },
                 { label: "List", value: 30 },
                 { label: "Currency", value: 50 },
                ]}
               />
              </VStack>

              <VStack spacing="6" w={["100%", "30%"]}>
               <FormControl mt="2.5rem" mb="2.5rem" />
               <FormControl mt="2rem!important" mb="2rem!important" />
               <Select
                name="FieldType"
                label=""
                id="FieldType"
                placeholder="Currency"
                value="Currency"
                isCustomField
                isDisabled
                data={[{ label: "Currency", value: "Currency" }]}
               />
              </VStack>
             </Stack>
             <Stack
              direction={["column", "row"]}
              align="flex-start"
              spacing="10"
              mt="6"
             >
              <VStack spacing="6" w={["100%", "40%"]}>
               <TextInput
                name="uoMLabelName"
                label="UoM*"
                id="uoMLabelName"
                placeholder="e.g. UoM"
               />
              </VStack>

              <VStack spacing="6" w={["100%", "25%"]}>
               <Select
                name="FieldType"
                label=""
                id="FieldType"
                placeholder="List"
                isCustomField
                isDisabled
                value={30}
                data={[
                 { label: "Text box", value: 10 },
                 { label: "Number", value: 20 },
                 { label: "List", value: 30 },
                 { label: "Currency", value: 50 },
                ]}
               />
              </VStack>
              <VStack spacing="6" w={["100%", "30%"]}>
               <Select
                name="FieldType"
                label=""
                id="FieldType"
                placeholder="UoM"
                value="UoM"
                isCustomField
                isDisabled
                data={[{ label: "UoM", value: "UoM" }]}
               />
              </VStack>
             </Stack>
             <Stack
              direction={["column", "row"]}
              align="flex-start"
              spacing="10"
              mt="6"
             >
              <VStack spacing="6" w={["100%", "40%"]}>
               <SelectWithAutoComplete
                width={260}
                label={"Size of Pricing Unit*"}
                name="sizeUnitId"
                id="sizeUnitId"
                options={sizeOfUnitOption}
                defaultValue={
                 values?.sizeUnit?.id
                }
                onChange={(e: any) => {
                 setFieldValue("sizeUnitId", e.value);
                }}
               />
              </VStack>
             </Stack>
             {values.isEnableThirdParty && (
              <Stack
               direction={["column", "row"]}
               align="flex-start"
               spacing="10"
               mt="6"
              >
               <VStack spacing="6" w={["100%", "40%"]}>
                <TextInput
                 name="thirdPartyLabelName"
                 label="Third-Party*"
                 id="thirdPartyLabelName"
                 placeholder="e.g. Third-Party"
                />
               </VStack>

               <VStack spacing="6" w={["100%", "25%"]}>
                <Select
                 name="FieldType"
                 label=""
                 id="FieldType"
                 placeholder="Dropdown"
                 isCustomField
                 isDisabled
                 value={30}
                 data={[
                  { label: "Text box", value: 10 },
                  { label: "Number", value: 20 },
                  { label: "List", value: 30 },
                  { label: "Currency", value: 50 },
                 ]}
                />
               </VStack>
               <VStack spacing="6" w={["100%", "30%"]}>
                <Select
                 name="FieldType"
                 label=""
                 id="FieldType"
                 placeholder="Third-Party"
                 isCustomField
                 isDisabled
                 value={"ThirdParty"}
                 data={[{ label: "Third Party", value: "ThirdParty" }]}
                />
               </VStack>
              </Stack>
             )}
            </>
           )}
          </Box>

          <Box>
           {(initialValues?.identityName || initialValues?.journeyName) && (
            <Stack mb="1rem" mt="2rem">
             <Text fontSize="24px" color="#43464B" fontWeight="600">
              Additional fields
             </Text>
            </Stack>
           )}
           {/* {!loadingAddCustomField && ( */}
           <Box mb={"2rem"} border={"1px solid rgba(26, 26, 26, 0.16)"}>
            <Stack direction={"row"} p={5}>
             <Box w="225px">
              <Text
               textAlign="left"
               fontSize="16px"
               fontWeight="600"
               color="#00050D"
              >
               Label name
              </Text>
             </Box>
             <Box w="300px">
              <Text fontSize="16px" fontWeight="600" color="#00050D">
               Data Type
              </Text>
             </Box>
             <Box w="225px">
              <Text fontSize="16px" fontWeight="600" color="#00050D">
               Master list
              </Text>
             </Box>
             {/* <Box w="225px">
              <Text fontSize="16px" fontWeight="600" color="#00050D">
               Include In Calculation
              </Text>
             </Box> */}
             <Box w="225px">
              <Text fontSize="16px" fontWeight="600" color="#00050D">
               Required
              </Text>
             </Box>
            </Stack>
            <Divider />
            <FieldArray
             name="customFields"
             render={({ push, replace, remove }) => {
              return (
               <>
                {Array.isArray(values.customFields) &&
                 values.customFields?.length > 0 &&
                 values.customFields.map(
                  (
                   {
                    creationTime,
                    creatorUserId,
                    masterDataConfigurationId,
                    masterDataConfiguration,
                    displayName,
                    name,
                    sequence,
                    isRequired,
                    isIncludeInCalculation,
                    id,
                    type,
                   },
                   index
                  ) => {
                   return (
                    <Box key={index} id={id} p={3}>
                     <Stack
                      mb="0.5rem"
                      direction={["column", "row"]}
                      // align="flex-start"
                      spacing="10"
                     >
                      <VStack spacing="6" w={200}>
                       <TextInput
                        w={200}
                        name={`customFields[${index}].name`}
                        label=""
                        id="customFieldName"
                        placeholder="Enter label name"
                        autoFocus={name === "" ? true : false}
                        onChange={(val) => {
                         setFieldValue(
                          `customFields[${index}].name`,
                          val.target.value
                         );
                        }}
                        // onKeyPress={(event) => {
                        //  if (!/[a-zA-Z0-9-_\s]+$/gu.test(event.key)) {
                        //   event.preventDefault();
                        //   setFieldError(
                        //    `customFields[${index}].name`,
                        //    "The field should have alphanumeric only"
                        //   );
                        //  }
                        // }}
                       />
                      </VStack>
                      <VStack spacing="6">
                       <Flex>
                        <Box w={"213px"}>
                         <Select
                          name={`customFields[${index}].type`}
                          label=""
                          w={"213px"}
                          id={`customFields[${index}].type`}
                          isCustomField
                          onChange={(value: any) => {
                           setFieldValue(
                            `customFields[${index}].type`,
                            value.currentTarget.value
                           );
                           value.target.value !== "30" ||
                            (value.target.value !== 30 &&
                             setFieldValue(
                              `customFields[${index}].masterDataConfigurationId`,
                              ""
                             ));
                           setIsDataType(Number(value.currentTarget.value));
                           setFieldValue(`customFields[${index}].isRequired`, false)
                          }}
                          data={[
                           { label: "Text box", value: 10 },
                           { label: "Number", value: 20 },
                           { label: "List", value: 30 },
                           { label: "Calculated", value: 40 },
                           { label: "Currency", value: 50 },
                          ]}
                         />
                        </Box>
                        <Box w={"50px"}>
                         <IconButton
                          variant="unstyled"
                          isDisabled={
                           values.customFields[index].type !== "40" &&
                           values.customFields[index].type !== 40
                          }
                          _focus={{ border: "none 0px white" }}
                          size="custom"
                          w={"50px"}
                          mt="15px"
                          ml="25px"
                          onClick={() => setOpenFormulaCustomField(`customFields[${index}].formulaExpression`)}
                          aria-label="Search database"
                          icon={<Image w={"36px"} src={FX} />}
                         />
                         {values.customFields[index].type === 40 ?
                         (<Formula
                          name={`customFields[${index}].formulaExpression`}
                          formulaFields={formulaList}
                          costDriverList={levelResponse}
                          isOpen={openFormulaCustomField === `customFields[${index}].formulaExpression` ? true : false}
                          onClose={() => {
                           setOpenFormulaCustomField('');
                          }}
                         />) : values.customFields[index].type === "40" ? (<Formula
                          name={`customFields[${index}].formulaExpression`}
                          formulaFields={formulaList}
                          costDriverList={levelResponse}
                          isOpen={openFormulaCustomField === `customFields[${index}].formulaExpression` ? true : false}
                          onClose={() => {
                           setOpenFormulaCustomField('');
                          }}
                         />) : null}
                        </Box>
                       </Flex>
                      </VStack>
                      {/* {type === 20 && ( */}
                      <VStack spacing="6" w={200}>
                       {
                        <Box w={200}>
                         <Box
                          display={
                           Number(values.customFields[index].type) !== 30
                            ? "none"
                            : ""
                          }
                          py="10px"
                         >
                          <SelectWithAutoComplete
                          width={200}
                           name={`customFields[${index}].masterDataConfigurationId`}
                           id={`customFields[${index}].masterDataConfigurationId`}
                           defaultValue={
                            values.customFields[index]?.masterDataConfiguration
                             ?.id ||
                            values.customFields[index].masterDataConfigurationId
                           }
                           options={masterDataWithoutCostOption.filter(
                            (item: any) =>
                             !item.value.includes(
                              values.masterDataConfigurationId || values.masterDataConfiguration?.id
                             )
                           )}
                           onChange={(e: any) => {
                            setFieldValue(
                             `customFields[${index}].masterDataConfigurationId`,
                             e.value
                            );
                            dispatch(
                             masterDataConfigurationAction.masterDataConfigurationsDetails(
                              e.value
                             )
                            ).then((response: any) => {
                             const data = response.payload;
                             const findDisplayOnPricing =
                              data.customFields.filter(
                               (item: any) => item.isDisplayOnPricing
                              );
                             setFieldValue(
                              `customFields[${index}].masterDataCustomFields`,
                              findDisplayOnPricing
                             );
                            });
                           }}
                          />
                         </Box>
                        </Box>
                       }
                      </VStack>
                      {/* <VStack>
                       <Box w={90} textAlign="right">
                        {Number(values.customFields[index].type) === 20 && (
                         <Checkbox
                          py="1rem"
                          isChecked={isIncludeInCalculation}
                          onChange={(val) => {
                           setFieldValue(
                            `customFields[${index}].isIncludeInCalculation`,
                            val.currentTarget.checked
                           );
                          }}
                         />
                        )}
                       </Box>
                      </VStack> */}
                      {/* )} */}
                      <VStack spacing="6">
                       <Box w={70} textAlign="center">
                        <Checkbox
                         py="1rem"
                         isDisabled={Number(values.customFields[index].type) === 40}
                         isChecked={values.customFields[index].isRequired}
                         onChange={(val) => {
                          setFieldValue(
                           `customFields[${index}].isRequired`,
                           val.target.checked
                          );
                         }}
                        />
                       </Box>
                      </VStack>

                      {/* {type === 10 && (
                       <VStack spacing="6" w={["100%", "20%"]}></VStack>
                      )} */}

                      <VStack>
                       <Stack direction={["column", "row"]} spacing="50px">
                        <IconButton
                         ml="30px"
                         aria-label="Search database"
                         background="transparent"
                         minW="36px"
                         _hover={{ background: "none" }}
                         _focus={{ background: "none" }}
                         _active={{ background: "none" }}
                         isLoading={
                          isLoadingCustomField &&
                          values?.customFields[index]?.id ===
                           isLoadingDeleteCustomFieldId
                         }
                         icon={
                          <Image
                           src={DeleteButton}
                           alt="delete icon"
                           cursor="pointer"
                           onClick={async () => {
                            if (newAdditionalField) {
                             setNewAdditionalField(false);
                            }

                            if (id.includes("newCustomField")) {
                             if (values.customFields[index]) {
                              if (document.getElementById(id)) {
                               Swal.fire({
                                showCancelButton: true,
                                html:
                                 "Are you sure you want to delete this Additional Field?",
                                confirmButtonText: "Yes",
                                cancelButtonText: "No",
                               }).then(async (result) => {
                                if (result.isConfirmed) {
                                 remove(index);
                                }
                               });
                              } else {
                               const payloadToDelete = {
                                costDriverId: values.id,
                                customFieldId: values.customFields[index].id,
                               };

                               Swal.fire({
                                showCancelButton: true,
                                html:
                                 "Are you sure you want to delete this Additional Field?",
                                confirmButtonText: "Yes",
                                cancelButtonText: "No",
                               }).then(async (result) => {
                                if (result.isConfirmed) {
                                 setIsLoadingCustomField(true);
                                 setIsLoadingDeleteCustomFieldId(
                                  values?.customFields[index]?.id
                                 );
                                 await dispatch(
                                  myJourneyActions.deleteCustomField(
                                   payloadToDelete
                                  )
                                 ).then(async (response: any) => {
                                  if (response.payload.success) {
                                   OptipediaToast.Success(
                                    "The Additional Field has been removed successfully."
                                   );
                                  } else {
                                   OptipediaToast.Error(
                                    "Sorry! Something unexpected happened, hence your request can not be completed."
                                   );
                                  }
                                 });

                                 remove(index);
                                }
                               });
                              }
                             } else {
                              (
                               document.getElementById(id) as HTMLFormElement
                              ).remove();
                             }
                            } else {
                             const payloadToDelete = {
                              costDriverId: values.id,
                              customFieldId: values.customFields[index].id,
                             };

                             Swal.fire({
                              showCancelButton: true,
                              html:
                               "Are you sure you want to delete this Additional Field?",
                              confirmButtonText: "Yes",
                              cancelButtonText: "No",
                             }).then(async (result) => {
                              if (result.isConfirmed) {
                               await dispatch(
                                myJourneyActions.deleteCustomField(
                                 payloadToDelete
                                )
                               ).then((response: any) => {
                                if (response.payload) {
                                 var formValue = Object.assign({}, values);
                                 var customFields = values.customFields;
                                 var newCustomFields = customFields.filter(
                                  (value: any) =>
                                   value.id !==
                                   response.payload.payload.customFieldId
                                 );

                                 formValue.customFields = newCustomFields;
                                 setInitialValues(formValue);
                                 remove(index);

                                 OptipediaToast.Success(
                                  "The Additional Field has been removed successfully."
                                 );
                                } else {
                                 OptipediaToast.Error(
                                  "Sorry! Something unexpected happened, hence your request can not be completed."
                                 );
                                }
                               });

                               // dispatch(myJourneyActions.getById(params));
                               dispatch(
                                myJourneyActions.setCustomFieldResponse(true)
                               );
                              }
                             });
                            }
                           }}
                          />
                         }
                        />
                        <Button
                         // backgroundColor="#0072CF"
                         justifyContent={"center"}
                         borderRadius="5px"
                         variant={"ghost"}
                         color="#0072CF"
                         width="150px"
                         height="59px"
                         padding="16px 8px"
                         mt="1rem"
                         type="button"
                         isLoading={
                          isLoadingCustomFieldId ===
                           values?.customFields[index]?.id &&
                          isLoadingCustomField
                         }
                         onClick={async () => {
                          if (values.customFields[index]) {
                           let isNewCustomField =
                            values.customFields[index].id.includes(
                             "newCustomField"
                            );

                            setFieldValue(
                              `customFields[${index}].itSave`,
                              true
                             );
                           const valuesToSave = {
                            isRequired:
                             values.customFields[index].isRequired === false
                              ? false
                              : true,
                            isIncludeInCalculation:
                             values.customFields[index]
                              .isIncludeInCalculation || false,
                            name: values.customFields[index].name,
                            displayName: values.customFields[index].name,
                            type: values.customFields[index].type
                             ? values.customFields[index].type
                             : 10,
                            formulaExpression:
                             values.customFields[index].formulaExpression,
                            masterDataConfigurationId:
                             Number(values.customFields[index].type) !== 30
                              ? ""
                              : values.customFields[index]
                                 .masterDataConfigurationId ||
                                values.customFields[index]
                                 .masterDataConfiguration?.id,
                            id: isNewCustomField
                             ? initialValues.id
                             : values.customFields[index].id,
                            masterDataCustomFields: values.customFields[
                             index
                            ].masterDataCustomFields
                             ?.filter((item) => item.dataType === 20)
                             ?.map((data: any) => ({
                              masterDataCustomFieldConfigurationId: data.id,
                              isIncludeInCalculation: data.dataType === 20,
                             })),
                           };
                           if (!isNewCustomField) {
                            setIsLoadingCustomField(true);
                            setIsLoadingCustomFieldId(
                             values.customFields[index].id
                            );
                            await dispatch(
                             myJourneyActions.editCustomField(valuesToSave)
                            );

                            setIsLoadingCustomField(false);
                            //dispatch(myJourneyActions.getById(params));
                           } else {
                            await dispatch(
                             myJourneyActions.addCustomField(valuesToSave)
                            ).then((item: any) => {
                             if (item.payload) {
                              (
                               document.getElementById(id) as HTMLFormElement
                              ).setAttribute("id", item?.payload?.result?.id);
                              setFieldValue(
                               `customFields[${index}].id`,
                               item?.payload?.result?.id
                              );
                             }
                            });

                            //dispatch(myJourneyActions.getById(params));
                           }
                          }

                          if (newAdditionalField) {
                           dispatch(
                            myJourneyActions.setCustomFieldResponse(true)
                           );
                          }
                         }}
                        >
                         Save
                        </Button>
                       </Stack>
                      </VStack>
                     </Stack>
                     {values.customFields[index].masterDataConfigurationId !==
                      "" &&
                      values.customFields[index].masterDataCustomFields
                       ?.length > 0 &&
                      values.customFields[index].masterDataCustomFields
                       .filter((i: any) => i.dataType === 20)
                       .map((customField: any, idx: number) => {
                        return (
                         <Stack direction={["column", "row"]} mb="1rem">
                          <VStack w={750}>
                           <TextInput
                            w={200}
                            name={`customFields[${index}].masterDataCustomFields[${idx}].name`}
                            label=""
                            isDisabled
                            value={customField.name}
                            id={`customFields[${index}].masterDataCustomFields[${idx}].name`}
                            placeholder="e.g. SAP Code"
                           />
                          </VStack>
                          <VStack spacing="6">
                           <Box padding="1rem" w="80px">
                            <Checkbox
                             isChecked={customField.dataType === 20}
                             onChange={(val) => {
                              setFieldValue(
                               `customFields[${index}].masterDataCustomFields[${idx}].isIncludeInCalculation`,
                               val.target.checked
                              );
                             }}
                            />
                           </Box>
                          </VStack>
                         </Stack>
                        );
                       })}
                    </Box>
                   );
                  }
                 )}
                <Box>
                 <Box
                  w="100%"
                  pr="5rem"
                  position="absolute"
                  bottom="0"
                  pb="1rem"
                 >
                  <Flex>
                   {(initialValues?.identityName ||
                    initialValues?.journeyName) && (
                    <Stack direction="row" spacing={4} align="center">
                     <Button
                      backgroundColor="#0072CF"
                      borderRadius="5px"
                      color="#fff"
                      _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
                      width="202px"
                      height="59px"
                      padding="16px 8px"
                      mt="1rem"
                      type="button"
                      onClick={(e) => {
                       e.preventDefault();
                       setNewAdditionalField(true);
                       push({
                        id: `newCustomField-${s.generate()}`,
                        name: "",
                        displayName: "",
                        type: 10,
                        isRequired: true,
                        isIncludeInCalculation: false,
                        masterDataConfigurationId: "",
                        masterDataCustomFields: [],
                        itSave: false,
                       });
                      }}
                     >
                      Add field
                     </Button>
                    </Stack>
                   )}

                   <Spacer />
                   <Box>
                    <Button
                     variant={"outline"}
                     borderRadius="5px"
                     color="#0072CF"
                     width="202px"
                     height="59px"
                     padding="16px 8px"
                     mt="1rem"
                     type="button"
                     _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
                     mr="1rem"
                     onClick={() => {
                      onClose();
                      dispatch(myJourneyActions.setResetCostDriverFields([]))
                     }}
                    >
                     Cancel
                    </Button>
                    <Button
                     backgroundColor="#0072CF"
                     borderRadius="5px"
                     isLoading={isSubmitting}
                     color="#fff"
                     _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
                     width="202px"
                     height="59px"
                     padding="16px 8px"
                     mt="1rem"
                     type="submit"
                    >
                     Save
                    </Button>
                   </Box>
                  </Flex>
                 </Box>
                </Box>
               </>
              );
             }}
            />
           </Box>
           {/* )} */}
          </Box>
         </Form>
        )}
       </Formik>
      </Box>
     </ModalBody>
    </ModalContent>
   </Modal>
  </>
 );
};

export default JourneyAddEditModal;
