import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpService from "services/http-service";

export const getList = createAsyncThunk(
  'demandForecasts/getList',
  async (params: any) => {
    const response: any = await HttpService.get('/DemandForecasts', { params });
    return response.result;
  }
)

export const downloadTemplate = createAsyncThunk(
  'demandForecasts/downloadTemplate',
  async (journeyId:string) => {
    HttpService.get(`/DemandForecasts/download/${journeyId}`, { responseType: 'blob' }).then((response:any) => {
      const url = window.URL.createObjectURL(new window.Blob([response]))
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Demand Forecasts Template.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      return true;
    })
  }
)

export const upload = createAsyncThunk(
  'demandForecasts/upload',
  async ({ payload, JourneyId }:any) => {
    const formData = new FormData();
    const file = payload[0] as any;
    formData.append("JourneyId", JourneyId);
    formData.append('file', file);
    const response:any = await HttpService.post('/DemandForecasts/upload', formData);
    return response.success;
  }
)

export const deleteById = createAsyncThunk(
  'demandForecasts/delete',
  async ({ id,orderId  }: any) => {
    const response: any = await HttpService.delete(`/DemandForecasts/${id}/${orderId}`);
    return response.success
  }
)
