import { createSlice } from '@reduxjs/toolkit'
import {
  getList,
  transactionViewDetail,
  getDetailById,
  getDetailCostAnalysisVolume,
  changeDefaultVolume,
  generateReport,
  checkIsExistingProductId
} from 'state/costAnalysis/thunk'

interface InitialState {
  detailById: any
  defaultVolume : any
  lists: any
  detail: any
  detailPerVolumeId: any
  searchQuery: string
  loading: boolean
  error: unknown
  sidebarItemOnClick: string
  isExist: any
  isExistLoading: boolean
}

const initialState: InitialState = {
  lists: {
    result: [],
  },
  defaultVolume : {},
  detailById: {},
  detail: {},
  detailPerVolumeId: {},
  sidebarItemOnClick: '',
  searchQuery: '',
  loading: false,
  error: null,
  isExist: {},
  isExistLoading: false
}

const costAnalysisSlice = createSlice({
  name: 'costAnalysis',
  initialState,
  reducers: {
    clear: () => initialState,
    setSearchQuery: (state, action) => {
      const { value } = action.payload
      state.searchQuery = value
    },
    setSidebarOnClick: (state, action) => {
      const { value } = action.payload
      state.sidebarItemOnClick = value
    },
    setDetailPerVolumeId: (state, action) => {
      state.detailPerVolumeId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(getList.fulfilled, (state, action) => {
      state.lists = action.payload
      state.loading = false
    })

    builder.addCase(getList.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    builder.addCase(transactionViewDetail.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(transactionViewDetail.fulfilled, (state, action) => {
      state.detailById = action.payload
      state.loading = false
    })

    builder.addCase(transactionViewDetail.rejected, (state, action) => {
      state.detailById = action.payload
      state.loading = false
    })

    builder.addCase(getDetailById.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(getDetailById.fulfilled, (state, action) => {
      state.detail = action.payload
      state.loading = false
    })

    builder.addCase(getDetailById.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    builder.addCase(getDetailCostAnalysisVolume.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(getDetailCostAnalysisVolume.fulfilled, (state, action) => {
      state.detailPerVolumeId = action.payload
      state.loading = false
    })

    builder.addCase(getDetailCostAnalysisVolume.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    builder.addCase(changeDefaultVolume.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(changeDefaultVolume.fulfilled, (state, action) => {
      state.detailById = action.payload
      state.loading = false
    })

    builder.addCase(changeDefaultVolume.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })


    builder.addCase(generateReport.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(generateReport.fulfilled, (state) => {
      state.loading = false
    })

    builder.addCase(generateReport.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    builder.addCase(checkIsExistingProductId.pending, (state) => {
      state.isExistLoading = true
      state.error = null
    })

    builder.addCase(checkIsExistingProductId.fulfilled, (state, action) => {
      state.isExistLoading = false
      state.isExist = action.payload
    })

    builder.addCase(checkIsExistingProductId.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const actions = {
  ...costAnalysisSlice.actions,
  getList,
  transactionViewDetail,
  getDetailById,
  getDetailCostAnalysisVolume,
  changeDefaultVolume,
  generateReport,
  checkIsExistingProductId
}

export const reducer = costAnalysisSlice.reducer
