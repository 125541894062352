import { ReactElement, useState } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { Text, Box, Badge } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useLocation, useHistory } from "react-router-dom";
import "utils/number-utils";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
import { actions as pricingCostDriverAction } from "state/pricings/costDrivers/slice";
import Swal from "sweetalert2";

const VolumeSelection = (): ReactElement => {
 const location = useLocation<{
  status: number;
  volumeId: string;
  isEditing: boolean;
  isApproval: boolean;
 }>();
 const history = useHistory();
 const dispatch = useAppDispatch();
 const pricingId = location.pathname.split("/")[3];
 const { detailPerVolumeId } = useSelector(
  (state: RootState) => ({
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId,
  }),
  shallowEqual
 );
 const {
  volumes,
  productName,
  costAnalysisPerVolume,
  isSimulate,
  isCopy,
  isReplace,
  costAnalysis,
  volumeId,
 } = useSelector(
  (state: RootState) => ({
   volumes: state.pricingCostSummary.costSummaryById.volumes,
   productName: state.pricingCostSummary.costSummaryById.name,
   costAnalysisPerVolume: state.pricingCostDrivers.detailPerVolumeId.result,
   volumeId: state.pricingCostDrivers.volumeId,
   isSimulate: state.pricingCostDrivers.isSimulate,
   isReplace: state.copyAndReplaceCostDrivers.isReplace,
   isCopy: state.copyAndReplaceCostDrivers.isCopy,
   costAnalysis: state.pricingCostSummary.costSummaryById,
  }),
  shallowEqual
 );
 let findThirdParty: string = "";
 if (volumes && volumes.length > 0) {
  const responseVolumeId = location?.state.volumeId || volumeId;
  const haveThirdParty = volumes.find((item: any) => {
   if (item.id === responseVolumeId) {
    return item;
   } else {
    if (item.thirdParty?.id === responseVolumeId) {
     return item;
    }
   }
  });
  if (haveThirdParty) {
   findThirdParty = haveThirdParty?.thirdParty?.code;
  }
 }

 let statusName;
 switch (location.state.status) {
  case 0:
   statusName = "Draft";
   break;
  case 1:
   statusName = "Pending Approval";
   break;
  case 2:
   statusName = "Valid";
   break;
  case 3:
   statusName = "Obsolete";
 }
 const optVolume: any = [];
 volumes?.length > 0 &&
  volumes.map(({ volume, id, thirdParty }: any) =>
   optVolume.push({
    label: thirdParty
     ? `${thirdParty?.code} ${String.toFormatSeparator(volume, 0)}`
     : String.toFormatSeparator(volume, 0),
    value: id,
   })
  );

 return (
  <>
   {!isCopy && !isReplace ? (
    <Box ml="1rem" mt="3rem">
     <Text fontWeight={600} mb="3">
      You have selected
     </Text>
     <Box
      border={"1px solid #D7DBE2"}
      fontWeight={600}
      p="1rem"
      borderRadius="5px"
     >
      <Text>
       {productName} {findThirdParty && <> - {findThirdParty} </>}
      </Text>

      <Formik
       initialValues={{ value: '' }}
       onSubmit={() => {}}
      >
       {({ setFieldValue, values }) => (
        <Form>
         <SelectWithAutoComplete
         width={200}
          // isDisabled={isSimulate}
          defaultValue={volumeId || location.state?.volumeId}
          name="value"
          onChange={(event: any) => {
           Swal.fire({
            showCancelButton: true,
            html:
             "The changes that you have made will be lost. Are you sure you want to change and reset the simulation?",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
           }).then(async (result) => {
            if (result.isConfirmed) {
             await dispatch(pricingCostDriverAction.resetTmpChangeRow());
             await dispatch(pricingCostDriverAction.setVolumeId(volumeId));
             await dispatch(
              pricingCostDriverAction.setCostAnalysisPerVolumeId(event.value)
             );
             if (event.value) {
              const payload = {
               costAnalysisId: pricingId,
               perVolumeId: event.value,
              };
              await dispatch(
               pricingCostDriverAction.getDetailCostDriversVolume(payload)
              );
             }
             history.push({
              pathname: `${location.pathname}`,
              state: {
               status: location.state.status,
               volumeId: event.value,
              },
             });
            } else {
             setFieldValue("value", location.state?.volumeId);
            }
           });
          }}
          options={optVolume}
         />
        </Form>
       )}
      </Formik>
      {statusName !== "Pending Approval" && (
       <Text py="1">Version : {costAnalysisPerVolume?.version}</Text>
      )}
      <Badge mt="2" colorScheme={"yellow"}>
       {statusName}
      </Badge>
     </Box>
    </Box>
   ) : (
    <Box
     width={250}
     border={"1px solid #D7DBE2"}
     fontWeight={600}
     p="1rem"
     borderRadius="5px"
    >
     {isCopy ? (
      <Box>
       <Text>{`Copied from : ${costAnalysis.name.toUpperCase()}`}</Text>
       <Text>{`Volume : ${costAnalysisPerVolume?.volume}`}</Text>
      </Box>
     ) : (
      <Box>
       <Text>{`Replace from : ${costAnalysis.name.toUpperCase()}`}</Text>
       <Text>{`Volume : ${costAnalysisPerVolume?.volume}`}</Text>
      </Box>
     )}
    </Box>
   )}
  </>
 );
};

export default VolumeSelection;
