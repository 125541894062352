import { FC, useEffect } from "react";
import {
 Box,
 Button,
 Heading,
 Checkbox,
 Stack,
 Grid,
 Text,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { RootState } from "state/store";
import { useSelector, shallowEqual } from "react-redux";
import OptipediaToast from "utils/notification-util";

import { JourneyAssessments } from "constants/JourneyAssessments";

import { actions as myJourneyActions } from "state/myJourney/slice";

const toastrOptions = {
 showCloseButton: false,
 position: "top-center",
 timeOut: 3000,
};

const TargetDiscoveryStep2: FC = () => {
 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);
 const dispatch = useAppDispatch();
 const router = useHistory();
 let { whatInformationDoYouHave } = useSelector(
  (state: RootState) => ({
   whatInformationDoYouHave: state.myJourney.whatInformationDoYouHave,
  }),
  shallowEqual
 );

 const setCheckBox = (isChecked: boolean, value: string) => {
  if (isChecked) {
   dispatch(myJourneyActions.setWhatInformationDoYouHave(value));
   dispatch(myJourneyActions.setWhatInformationCanYouGain(value));
  } else {
   dispatch(myJourneyActions.removeArrayWhatInformationDoYouHave(value));
   dispatch(myJourneyActions.removeArrayWhatInformationCanYouGain(value));
  }
 };

 return (
  <>
   <Heading as="h2" size="lg" pt="1rem" pb="1rem">
    What information do you have?
   </Heading>
   <Grid templateColumns="repeat(5, 1fr)" gap={5}>
    {JourneyAssessments.map((item, index) => {
     return (
      <Box
       border="1px solid #D7DBE2"
       borderRadius="40px"
       padding="1rem"
       key={index}
      >
       <Checkbox
        defaultChecked={whatInformationDoYouHave.includes(item.label)}
        value={item.label}
        onChange={(val) => {
         setCheckBox(val.target.checked, val.currentTarget.value);
        }}
       >
        <Text fontSize="12px">{item.label}</Text>
       </Checkbox>
      </Box>
     );
    })}
   </Grid>

   <Box pt="2rem" textAlign="right" w="100%">
    <Stack spacing={4} direction="row" align="center" float="right">
     <Button
     variant={"outline"}
      onClick={() => router.goBack()}
     >
      Back
     </Button>
     <Button
      onClick={() => {
       if (whatInformationDoYouHave.length !== 0) {
        router.push("/journeys/create?page=target-discovery-step-3");
       } else {
        OptipediaToast.Warning(
         "Please select which information that you have before proceeding to the next step."
        );
       }
      }}
     >
      Next
     </Button>
    </Stack>
   </Box>
  </>
 );
};

export default TargetDiscoveryStep2;
