import { RootState } from "state/store";
import { FC, useEffect, useState, useRef } from "react";
import {
 Box,
 Button,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalCloseButton,
 ModalBody,
 useDisclosure,
 Text
} from "@chakra-ui/react";
import Table from "components/Table/Table";

const TABLE_HEADERS:any = [
 { name: "identityName", label: "Cost Breakdown" },
 { name: "currency", label: "Currency" },
 { name: "currentTotalSpend", label: <Text align="right">Total Cost (Current)</Text> },
 { name: "whatIfTotalSpend", label: <Text align="right">Total Cost (What-If)</Text> },
];

export interface ModalProps {
 data: {};
}
const ModalCompare = (data: any) => {
 const { isOpen, onOpen, onClose } = useDisclosure();
// check deploy failed 
 return (
  <Box>
    <Button
     onClick={onOpen}
     variant={'link'}
     _focus={{border:'none'}}
    >
     Details
    </Button>
   <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent minW="70%" borderRadius="5px">
     <ModalCloseButton />
     <ModalBody m="2rem">
      <Box borderRadius="5px">
       <Table
        loading={false}
        key={data.data.index}
        columnHeaders={TABLE_HEADERS}
        data={data.data}
       />
      </Box>
     </ModalBody>
    </ModalContent>
   </Modal>
  </Box>
 );
};

export default ModalCompare;
