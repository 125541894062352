import { createSlice } from "@reduxjs/toolkit";
import {
 createMasterDataConfigurations,
 editMasterDataConfigurations,
 masterDataConfigurationsDetails,
 masterDataConfigurationsList,
 deleteMasterDataConfigurations,
} from "state/masterDataConfiguration/thunk";

interface InitialState {
 lists: any;
 searchQuery: string;
 loading: boolean;
 error: unknown;
 data: any;
 customFields: any;
 dataToSend: any;
 dataToUpdate: any;
}

const initialState: InitialState = {
 lists: {
  result: [],
 },
 searchQuery: "",
 loading: false,
 error: null,
 data: {},
 customFields: [],
 dataToSend: {
  id: "",
  identityCode: "",
  identityName: "",
  code: "",
  isCodeDisplayOnPricing: false,
  description: "",
  isDescriptionDisplayOnPricing: true,
  isIncludeCostValue: false,
  cost: "",
  isCostDisplayOnPricing: false,
  isAssociateWithThirdParty: false,
  customFields: [],
 },
 dataToUpdate: {},
};

const masterDataConfigurationSlice = createSlice({
 name: "masterDataConfiguration",
 initialState,
 reducers: {
  clear: () => initialState,
  setSearchQuery: (state, action) => {
   const { value } = action.payload;
   state.searchQuery = value;
  },
  setCostDriver: (state, action) => {
   state.data.push(action.payload);
  },
  unSetCostDriver: (state) => {
   state.data.pop();
  },
  setCustomField: (state, action) => {
   state.customFields = action.payload
   state.dataToSend.customFields = state.customFields.length > 1 ? state.customFields.filter((item: any) => item.name !== "" && item.dataType !== 50 || item.dataType !== "") : state.customFields
  },
  resetCustomFields: (state, action) => {
    state.dataToSend.customFields = [];
    state.dataToSend.customFields.push(action.payload)
   },
  removeCustomeFields: (state, action) => {
    const objWithIdIndex = state.dataToSend.customFields.filter((obj: any) => obj.id !== action.payload);
    const objWithIdIndexCustomFields = state.customFields.filter((obj: any) => obj.id !== action.payload);
  if (objWithIdIndex !== undefined) {
    state.dataToSend.customFields = objWithIdIndex;
    state.customFields = objWithIdIndex
    state.dataToSend.customFields = state.customFields.length > 1 ? state.customFields.filter((item: any) => item.name !== "" && item.dataType !== 50 || item.dataType !== "") : state.customFields
  }else{
    state.customFields = objWithIdIndexCustomFields;
    state.customFields = objWithIdIndexCustomFields
    state.dataToSend.customFields = state.customFields.length > 1 ? state.customFields.filter((item: any) => item.name !== "" && item.dataType !== 50 || item.dataType !== "") : state.customFields
  }
  },
  setDataToSend: (state, action) => {
   state.dataToSend = action.payload;
  },
  setDataToUpdate: (state) => {
   state.dataToUpdate = {
    id: state.data?.id,
    identityCode: state.data?.identityCode,
    identityName: state.data?.identityName,
    code: state.data?.code,
    isCodeDisplayOnPricing: state.data?.isCodeDisplayOnPricing,
    description: state.data?.description,
    isDescriptionDisplayOnPricing:
     state.data?.isDescriptionDisplayOnPricing,
    isIncludeCostValue: state.data?.isIncludeCostValue,
    cost: state.data?.cost,
    isCostDisplayOnPricing: state.data?.isCostDisplayOnPricing,
    isAssociateWithThirdParty: state.data?.isAssociateWithThirdParty,
    customFields: state.data?.customFields.length > 0 ? state.data?.customFields.map((item: any) => ({
      name: item.name,
      displayName: item.displayName,
      isRequired: item.isRequired,
      dataType: item.dataType,
      isDisplayOnPricing: item.isDisplayOnPricing,
      masterDataConfigurationId: item.masterDataConfiguration?.id || "",
     })) : state.data?.customFields,
   };
  },
  resetData: (state) => {
    state.data = {}
  }
 },
 extraReducers: (builder) => {
  //  GET ALL
  builder.addCase(masterDataConfigurationsList.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(masterDataConfigurationsList.fulfilled, (state, action) => {
   state.loading = false;
   state.lists = action.payload;
  });

  builder.addCase(masterDataConfigurationsList.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  //  GET BY ID
  builder.addCase(masterDataConfigurationsDetails.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(
   masterDataConfigurationsDetails.fulfilled,
   (state, action) => {
    state.loading = false;
    state.data = action.payload;
   }
  );

  builder.addCase(masterDataConfigurationsDetails.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  //  POST
  builder.addCase(createMasterDataConfigurations.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(createMasterDataConfigurations.fulfilled, (state) => {
   state.loading = false;
  });

  builder.addCase(createMasterDataConfigurations.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });


  //  DELETE
  builder.addCase(deleteMasterDataConfigurations.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(deleteMasterDataConfigurations.fulfilled, (state) => {
   state.loading = false;
  });

  builder.addCase(deleteMasterDataConfigurations.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  
  //  UPDATE
  builder.addCase(editMasterDataConfigurations.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(editMasterDataConfigurations.fulfilled, (state) => {
   state.loading = false;
  });

  builder.addCase(editMasterDataConfigurations.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
 },
});

export const actions = {
 ...masterDataConfigurationSlice.actions,
 createMasterDataConfigurations,
 editMasterDataConfigurations,
 masterDataConfigurationsDetails,
 masterDataConfigurationsList,
 deleteMasterDataConfigurations,
};

export const reducer = masterDataConfigurationSlice.reducer;
