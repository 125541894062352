import { ReactElement, useMemo, useState, useEffect } from "react";
import { removeThousandSeparator } from "utils";
import { useSelector, shallowEqual } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";
import debounce from "lodash/debounce";
import { HStack } from "@chakra-ui/react";
import OptipediaToast from "utils/notification-util";
import { ValidationData } from "utils/validation-values";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import TextInputCustomNumber from "components/NewTextInputCustomNumber";
import TextInput from "components/TextInputWithSearch";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
import { isEmpty } from "lodash";

const TextInputInTableView = ({
 index,
 convertName,
 row,
 isMaintenanceByMasterData,
 mantenanceMasterdata,
 costDriver,
 values,
 currentRow,
 paramName,
 isErrorDuplicate,
 setFieldValue,
 options,
}: any): ReactElement => {
 const dispatch = useAppDispatch();
 const [errorDuplicate, setErrorDuplicate] = useState<boolean>(false);
 const [newRow, setNewRow] = useState<any>(row);
 const [customFieldsNew, setCustomFieldsNew] = useState<any>([]);
 const [dataSend, setDataSend] = useState<any>(null);
 const [customFieldsData, setCustomFieldsData] = useState<any>([]);
 let customsFields: any = [];
 const {
  loading,
  dataConstDriversOri,
  dataToSendUpdates,
  detailPerVolumeId,
  valueIsIncludeCalculation,
  temporaryDataCostDriver,
  changeRowSimulate,
  customFieldsDatas,
  haveEmptyName,
  listCurrency,
 } = useSelector(
  (state: RootState) => ({
   loading: state.editPricingCostDrivers.loading,
   dataConstDriversOri: state.editPricingCostDrivers.constDriversOri,
   dataToSendUpdates: state.editPricingCostDrivers.dataToSend.updates,
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId.result,
   valueIsIncludeCalculation:
    state.editPricingCostDrivers.valueIsIncludeCalculation,
   temporaryDataCostDriver:
    state.editPricingCostDrivers.temporaryDataCostDriver,
   changeRowSimulate: state.editPricingCostDrivers.tmpChangeRow,
   customFieldsDatas: state.editPricingCostDrivers.customFields,
   haveEmptyName: state.editPricingCostDrivers.haveEmptyName,
   listCurrency: state.multiCurrency.lists,
  }),
  shallowEqual
 );

 const [keyCurrency, setKeyCurrency] = useState("");

 const valueConvertName = isMaintenanceByMasterData
  ? `${convertName}-From-Masterdata`
  : convertName;

 const findIndexCustomField = () => {
  return row.customFields.findIndex(
   (customField: any) => customField.name === convertName
  );
 };

 useEffect(() => {
  setNewRow(row);
 }, [row]);

 useEffect(() => {
  setCustomFieldsData(customFieldsDatas);
 }, [customFieldsDatas]);

 useEffect(() => {
  if (dataSend) {
   const timer = setTimeout(async () => {
    await dispatch(pricingCostDriverAction.setDataToSend(dataSend));
    dispatch(pricingCostDriverAction.setIsEditOpen(false));
    dispatch(pricingCostDriverAction.setNullCustomFields());
   }, 100);

   return () => clearTimeout(timer);
  }
 }, [dataSend]);

 useEffect(() => {
  const ids = customFieldsDatas.map((o: any) => o.id);
  const filtered = customFieldsDatas.filter(
   ({ id }: any, index: number) => !ids.includes(id, index + 1)
  );
  setCustomFieldsNew(filtered);
 }, [customFieldsDatas]);

 const findOriginalCostDriver =
  dataConstDriversOri &&
  dataConstDriversOri?.data &&
  dataConstDriversOri?.data.find(
   (item: any) => item.identityName === costDriver.identityName
  );

 const indexSimulateBySku = temporaryDataCostDriver.findIndex(
  (el: any) => el.name == paramName
 );

 const findOneCostDriverOriginal =
  temporaryDataCostDriver[indexSimulateBySku] &&
  temporaryDataCostDriver[indexSimulateBySku]?.data &&
  temporaryDataCostDriver[indexSimulateBySku]?.data.find(
   (item: any) => item.costDriverId === costDriver.costDriverId
  );

 const findIndex = findOneCostDriverOriginal?.data.findIndex((item: any) => {
  return item.id === currentRow?.get("id");
 });

 const runSimulate = useMemo(
  () =>
   debounce(async (dataToUpdate, dataToSet) => {
    const newCustomFieldDataToUpdate: any = [];
    dataToUpdate.customFields.map((customField: any) => {
     if (!customField.isMaintenanceByMasterData) {
      newCustomFieldDataToUpdate.push(customField);
     }
    });
    const newDataToUpdate = {
     ...dataToUpdate,
     customFields: newCustomFieldDataToUpdate,
    };

    await dispatch(
     pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(
      newDataToUpdate
     )
    );

    const dataAfterSimulate = findOneCostDriverOriginal?.data[findIndex];
    if (changeRowSimulate.length > 0) {
    } else {
     const codeName = costDriver.headers.find(
      (header: any) => header.name === convertName
     );
     dispatch(
      pricingCostDriverAction.settmpChangeRow({
       id: dataToSet.id,
       costDriver: findOriginalCostDriver?.tableName,
       component: `${dataAfterSimulate?.name} - ${codeName.label}`,
       before: dataAfterSimulate?.unitCost,
       after: dataAfterSimulate?.isNew
        ? 0
        : dataToSet
        ? dataToSet.after
        : dataAfterSimulate.unitCost,
       idRow: row.id,
       customFieldConfigurationId: dataToSet?.customFieldConfigurationId,
       parentCostDriverId: costDriver.costDriverId,
       componentName: dataToSet.componentName,
       name: dataToSet.name === "unitCost" ? "Cost" : codeName.label,
      })
     );
    }
   }, 1000),
  [dispatch, dataToSendUpdates, changeRowSimulate, detailPerVolumeId]
 );

 const isNewDebounce = useMemo(
  () =>
   debounce(async (dataToAdd) => {
    const { name, value } = dataToAdd;
    const { dataCustomFields, newRow, customFieldConfigurationId } = dataToAdd;
    let myuuid = uuidv4();
    const isItName = name === "name";
    const isItDes = name === "description";
    const isItUnitCost = name === "unitCost";
    const isName = isItName ? value : row?.name;
    const isDes = isItDes ? value : row?.description;
    const isUnitCost = isItUnitCost ? value : row?.unitCost;
    row.name = isName;
    row.description = isDes;
    row.unitCost = isUnitCost;
    let newData: any = {};
    if (!isItName && !isItDes && !isItUnitCost) {
     for (var i = 0; i < row?.customFields?.length; i++) {
      if (row?.customFields[i]?.name === name) {
       const dataToPush = {
        ...row.customFields[i],
        value,
       };
       newData = dataToPush;
      }
     }
    }

    let isCustomFieldAllHaveValue = true;

    let isHaveSelectbox = false;
    let customFieldsValueNotIncludeCalculation: any = [];
    for (var i = 0; i < row?.customFields.length; i++) {
     if (row?.customFields[i].masterData !== null) {
      isHaveSelectbox = true;
      break;
     } else {
      if (!row?.customFields[i].isIncludeInCalculation) {
       customFieldsValueNotIncludeCalculation.push(row?.customFields[i]);
      }
     }
    }

    if (row.isNew) {
     row?.customFields.map((item: any) => {
      return dispatch(pricingCostDriverAction.setCustomFields(item));
     });

     row?.customFields.length > 0 &&
      row?.customFields.map((item: any) => {
       if (item.masterData !== null && item.value === "") {
        isCustomFieldAllHaveValue = false;
       }
      });

     const customFields: any = [];

     if (isHaveSelectbox) {
      dataCustomFields?.customFields.map((item: any) => {
       return dispatch(pricingCostDriverAction.setCustomFields(item));
      });
      dataCustomFields?.customFields.length > 0 &&
       dataCustomFields?.customFields.map((item: any) => {
        if (item.masterData !== null && item.value === "") {
         isCustomFieldAllHaveValue = false;
        }
       });
     } else {
      row?.customFields.map((item: any) => {
       return dispatch(pricingCostDriverAction.setCustomFields(item));
      });

      row?.customFields.length > 0 &&
       row?.customFields.map((item: any) => {
        if (item.masterData !== null && item.value === "") {
         isCustomFieldAllHaveValue = false;
        }
       });
     }

     let totalNotIncludeCalculations = 0;
     let totalIsRequired = 0;
     let totalRowCustomFields = 0;

     row?.customFields.map((customField: any) => {
      if (customField.isIncludeInCalculation) {
       customsFields.push(customField);
      } else {
       totalNotIncludeCalculations += 1;
      }
     });

     row?.customFields.map((customField: any, index: number) => {
      if (customField.name === name) {
       customFields.push({
        ...customField,
        value,
       });
       //  row.customFields[index].value = value;
      } else {
       customFields.push({
        ...customField,
        value: newRow[customField.name],
       });
      }
     });
     const findObjectEmptyValue = customFields.find(
      (customField: any) => customField.isRequired && customField.value === ""
     );

     const removeCustomFields: any = [];

     customFields.map((customField: any) => {
      if (!customField.isMaintenanceByMasterData) {
       removeCustomFields.push(customField);
      }
     });
     const data = {
      costAnalysisPerVolumeId: detailPerVolumeId.id,
      costDriverId: newRow?.id,
      simulationCostDriverId: myuuid,
      masterDataId: newRow?.masterDataId ? newRow?.masterDataId : "",
      name: newRow?.masterDataId ? "T" : newRow?.name,
      description: newRow?.masterDataId ? "T" : row?.description,
      unitCost: newRow?.unitCost || 0,
      customFields: removeCustomFields,
      currencyId: newRow?.currencyId,
      newData,
     };
     const findDuplicate = findOneCostDriverOriginal?.data.findIndex(
      (item: any) => item.name.toLowerCase() === value.toLowerCase()
     );

     customFields?.map((filteredCustomField: any) => {
      if (!filteredCustomField.isIncludeInCalculation) {
       totalRowCustomFields += 1;
      }
     });

     const validateToAdd = data.name !== "" && data.description !== "";
     const findNull = customFields.find(
      (item: any) =>
       !item.isMaintenanceByMasterData &&
       item.isRequired &&
       (item.value === "" || item.value === null)
     );

     if (findDuplicate !== -1 && name === "name") {
      setErrorDuplicate(true);
      dispatch(pricingCostDriverAction.setIsErrorDuplicate(true));
      return OptipediaToast.Warning(
       "The name you typed is duplicated, please use another name"
      );
     } else {
      setErrorDuplicate(false);
      dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
      if (validateToAdd && !findNull) {
       setDataSend(data);
      }
     }
    } else {
     await customFieldsValueNotIncludeCalculation.map(
      (item: any, index: number) => {
       if (item.name === name) {
        customFieldsValueNotIncludeCalculation[index].value = value;
       }
      }
     );
     const findNothaveValue = customFieldsValueNotIncludeCalculation.find(
      (itemValue: any) => itemValue.value === ""
     );

     if (!findNothaveValue) {
      for (var i = 0; i < row?.customFields.length; i++) {
       if (row?.customFields[i].isIncludeInCalculation) {
        customFieldsValueNotIncludeCalculation.push(row?.customFields[i]);
       }
      }

      const data = {
       costAnalysisPerVolumeId: detailPerVolumeId?.id,
       costDriverId: row?.id,
       simulationCostDriverId: myuuid,
       name: row?.name,
       description: row?.description,
       unitCost: row?.unitCost || 0,
       customFields: customFieldsValueNotIncludeCalculation,
       newData,
      };

      const findDuplicate = findOneCostDriverOriginal?.data.findIndex(
       (item: any) => item.name.toLowerCase() === value.toLowerCase()
      );

      if (findDuplicate !== -1 && name === "name") {
       setErrorDuplicate(true);
       dispatch(pricingCostDriverAction.setIsErrorDuplicate(true));
       return OptipediaToast.Warning(
        "The name you typed is duplicated, please use another name"
       );
      } else {
       setErrorDuplicate(false);
       dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
       dispatch(
        pricingCostDriverAction.directSetAddOneToTemporaryDataCostDriver(data)
       );
      }
     }
    }
   }, 800),
  [dispatch]
 );

 const debouncedRunSimulate = useMemo(
  () =>
   debounce(async (dataOnChange: any) => {
    const {
     name,
     value,
     currentRow1,
     tableName,
     dataFromSimulate,
     isSameData,
     datalatest,
     customFieldsData,
     currencyId,
    } = dataOnChange;
    let isNewCostDriver: any;

    if (dataFromSimulate || currentRow1) {
     isNewCostDriver = dataFromSimulate.isNew ? dataFromSimulate : currentRow1;
    } else {
     isNewCostDriver = row;
    }

    let newCustomField: any = [];
    let itemToObject = Object.assign({}, isNewCostDriver);
    // data asli yang tidak di edit
    const originalNewMap = new Map(Object.entries(itemToObject));
    if (convertName !== "unitCost") {
     const tmpChangeToDeletes = {
      component: `${originalNewMap.get("name")} - ${
       name === "unitCost" ? "Cost" : name
      }`,
      name: convertName,
      constDriverId:
       originalNewMap.get("constDriverId") || dataFromSimulate?.constDriverId,
     };
     dispatch(
      pricingCostDriverAction.removeOnetmpChangeRow({ tmpChangeToDeletes })
     );
    } else {
    }
    const findCost = changeRowSimulate.find(
     (item: any) =>
      item.name === "Cost" && item.componentName === isNewCostDriver.name
    );

    if (
     (row.isNew && 0 === value) ||
     removeThousandSeparator(originalNewMap.get(name) as string) === value
    ) {
     const tmpChangeToDeletes = {
      component: `${originalNewMap.get("name")} - ${
       name === "unitCost" ? "Cost" : name
      }`,
      name: convertName,
      constDriverId: originalNewMap.get("constDriverId"),
     };
     dispatch(
      pricingCostDriverAction.removeOnetmpChangeRow({ tmpChangeToDeletes })
     );

     const customFields: any = [];

     isNewCostDriver?.customFields.map((item: any) => {
      if (item.name === name) {
       customFields.push({
        ...item,
        value,
       });
      } else {
       customFields.push(item);
      }
     });
     const dataToUpdate = {
      costAnalysisPerVolumeId: detailPerVolumeId.id,
      costDriverId: isNewCostDriver.id,
      parentCostDriverId: costDriver.costDriverId,
      name: isNewCostDriver.name,
      masterDataId: dataFromSimulate.masterDataId,
      description: isNewCostDriver.description,
      unitCost: dataFromSimulate.unitCost,
      customFields:
       dataFromSimulate.customFields.length > 0
        ? dataFromSimulate.customFields
        : customFieldsData,
      currencyId: currencyId ? currencyId : isNewCostDriver.currencyId,
     };

     let isCustomFieldAllHaveValue = true;

     row?.customFields.length > 0 &&
      row?.customFields.map((item: any, index: number) => {
       if (item.value === 0 || item.value === "") {
        isCustomFieldAllHaveValue = false;
       }
      });
     runSimulate(dataToUpdate, null);
    } else {
     let haveSameValue = false;
     let isCustomFieldAllHaveValue = true;
     newCustomField.length > 0 &&
      newCustomField.map((item: any) => {
       if (item.predefinedData !== null && item.value === "") {
        isCustomFieldAllHaveValue = false;
       }
      });
     const customFields: any = [];
     isNewCostDriver?.customFields.map((item: any) => {
      if (item.name === name) {
       if (changeRowSimulate.length > 0) {
        for (let i = 0; i < changeRowSimulate.length; i++) {
         if (
          item.customFieldConfigurationId ===
           changeRowSimulate[i].customFieldConfigurationId &&
          item.costDriverId === changeRowSimulate[i].idRow &&
          item.name === changeRowSimulate[i].name
         ) {
          customFields.push({
           ...item,
           value,
          });
         } else {
          if (
           item?.name === name &&
           item?.costDriverName === currentRow1?.name
          ) {
           customFields.push({
            ...item,
            value,
           });
          } else {
           const sameValue = changeRowSimulate.find(
            (data: any) =>
             data.idRow === item.costDriverId &&
             data.customFieldConfigurationId ===
              item.customFieldConfigurationId &&
             item.name === data.name
           );
           if (item.value === value) {
            haveSameValue = true;
           }
           if (sameValue) {
            customFields.push({
             ...item,
             value: sameValue.before,
            });
           } else {
            customFields.push(item);
           }
          }
         }
        }
       } else {
        customFields.push({
         ...item,
         value,
        });
       }
      } else {
       for (let i = 0; i < changeRowSimulate.length; i++) {
        if (
         item.customFieldConfigurationId ===
          changeRowSimulate[i].customFieldConfigurationId &&
         item.costDriverName === changeRowSimulate[i].componentName &&
         item.name === changeRowSimulate[i].name
        ) {
         customFields.push({
          ...item,
          value: changeRowSimulate[i].before,
         });
        } else {
         if (item.name === name && item.costDriverName === row.name) {
          customFields.push({
           ...item,
           value,
          });
         } else {
          const sameValue = changeRowSimulate.find(
           (data: any) =>
            data.idRow === item.costDriverId &&
            data.customFieldConfigurationId ===
             item.customFieldConfigurationId &&
            item.name === data.name
          );
          if (sameValue) {
           customFields.push({
            ...item,
            value: sameValue.before,
           });
          } else {
           customFields.push(item);
          }
         }
        }
       }
      }
     });

     const customFieldsFromSimulate: any = [];

     row?.customFields.map((customField: any) => {
      if (customField.name === name && row[customField.name]) {
       customFieldsFromSimulate.push({
        ...customField,
        value,
       });
      } else {
       if (row[customField.name]) {
        customFieldsFromSimulate.push({
         ...customField,
         value: row[customField.name],
        });
       }
      }
     });

     const findDuplicate = datalatest?.data.findIndex(
      (item: any) => item.masterDataId === value
     );

     const validateToEdit =
      row.name !== "" ||
      (row.masterDataId !== "" &&
       row.description !== "" &&
       isCustomFieldAllHaveValue);
     const sameValue = changeRowSimulate.find(
      (data: any) => data.id === name && parseFloat(data.after) === value
     );

     if (validateToEdit) {
      const isRowMasterData = row?.masterDataConfiguration;
      const dataToUpdate = {
       costAnalysisPerVolumeId: detailPerVolumeId?.id,
       costDriverId: isRowMasterData ? dataFromSimulate?.id : row?.id,
       parentCostDriverId: costDriver.costDriverId,
       name: isRowMasterData ? dataFromSimulate?.name : row?.name,
       masterDataId: isRowMasterData
        ? dataFromSimulate.masterDataId
        : row?.masterDataId,
       currencyId: currencyId ? currencyId : dataFromSimulate?.currencyId,
       description: isRowMasterData
        ? dataFromSimulate?.description
        : row?.description,
       unitCost: isRowMasterData ? dataFromSimulate?.unitCost : row?.unitCost,
       customFields: customFieldsFromSimulate
        ? customFieldsFromSimulate
        : customFieldsData,
       haveSameValue: sameValue !== undefined,
      };
      if (
       findDuplicate !== -1
       // && name === "masterDataId"
      ) {
       dispatch(pricingCostDriverAction.setIsErrorDuplicate(true));
       return OptipediaToast.Warning(
        "The name you typed is duplicated, please use another name"
       );
      }
      {
       dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
       const findCust = customFields.find((item: any) => item.name === name);
       const codeName = costDriver.headers.find(
        (header: any) => header.name === convertName
       );
       let after = isNewCostDriver?.customFields?.find(
        (item: any) => item.name === convertName
       );
       const dataToSet = {
        id: convertName,
        costDriver: tableName,
        component: `${responseFetch?.name} - ${codeName.label}`,
        before: responseFetch?.unitCost,
        after: isNewCostDriver?.isNew
         ? 0
         : after
         ? after.value
         : isNewCostDriver?.unitCost,
        idRow: row.id,
        currencyId: responseFetch?.currencyId,
        customFieldConfigurationId: findCust?.customFieldConfigurationId,
        parentCostDriverId: costDriver?.costDriverId,
        componentName: originalNewMap.get("name"),
        name: name === "unitCost" ? "Cost" : codeName?.label,
       };
       runSimulate(dataToUpdate, dataToSet);
      }
     }
    }
   }, 10),
  [dataToSendUpdates, row]
 );

 useEffect(() => {
  return () => {
   debouncedRunSimulate.cancel();
  };
 }, []);

 const isNew = newRow.isNew !== undefined && newRow.isNew;
 const responseFetch = findOneCostDriverOriginal?.data[findIndex];
 const isMaintainWithMasterData =
  values.customFields[index]?.customFields?.find((i: any) => {
   return i.name == `${convertName}-From-Masterdata`;
  }) ||
  (values.customFields[index]?.masterDataConfiguration &&
   convertName === "unitCost") ||
  (values.customFields[index]?.masterDataConfiguration &&
   convertName === "description");
 const isValidDisableNew =
  responseFetch?.isNew &&
  responseFetch?.name !== "" &&
  responseFetch?.description;

 const newCustomFieldTmp: any = [];
 newRow.customFields.map((customField: any) => {
  if (newRow[customField.name]) {
   newCustomFieldTmp.push({
    ...customField,
    value: newRow[customField.name],
   });
  }
 });

 const typeData = row.customFields[findIndexCustomField()]?.typeData;
 const isRequired = row.customFields[findIndexCustomField()]?.isRequired;

 const isMasterData = row.masterDataConfiguration && row.masterDataId;

 let isUseMasterData = false;

 let isOpenField = false;
 //  let findIsRequired;
 if (isMasterData) {
  isUseMasterData = true;
  isOpenField =
   convertName === "description" ||
   convertName === "totalCost" ||
   convertName === "unitCost";
 } else {
  isUseMasterData = false;

  // findIsRequired = row?.customFields.find(
  //  (customField: any) => customField?.isRequired
  // );

  isOpenField = row?.isRequired || convertName === "totalCost";
 }

 let required = false;
 const selectOption: any = options;
 let isRequiredData = false;
 newRow.customFields?.find((i: any) => {
  return (isRequiredData = i.isRequired && isEmpty(i.value));
 });

 return (
  <>
   {typeData === 20 ||
   typeData === 50 ||
   typeData === 40 ||
   convertName === "unitCost" ||
   convertName === "currency" ? (
    <>
     <HStack spacing="0">
      {typeData === 50 ||
       (convertName === "currency" && (
        <>
         <SelectWithAutoComplete
          name={
           convertName === "currency"
            ? "currencyId"
            : `customFields[${index}].customFields[${findIndexCustomField()}].currencyId`
          }
          defaultValue={convertName === "currency" ? row.currencyId : ""}
          isDisabled={
           loading ||
           isOpenField ||
           valueIsIncludeCalculation ||
           mantenanceMasterdata ||
           (isNew && row.index === 0 && !required) ||
           typeData === 40 ||
           row.customFields[findIndexCustomField()]
            ?.isMaintenanceByMasterData ||
           isErrorDuplicate ||
           (row.masterDataConfiguration === null &&
            convertName !== "description" &&
            haveEmptyName) ||
           (row.masterDataConfiguration === null &&
            convertName !== "name" &&
            haveEmptyName) ||
           (isErrorDuplicate && convertName !== "name") ||
           isRequiredData
          }
          onChange={(e: any) => {
           const dataToEdit = {
            name: `${convertName}-currencyId`,
            value: e.value,
            currentRow1: findOriginalCostDriver?.data[findIndex],
            tableName: findOriginalCostDriver?.tableName,
            dataFromSimulate: findOneCostDriverOriginal?.data[findIndex],
            datalatest: costDriver,
            newRow: row,
            customFieldsData: [],
            isSameData: false,
            currencyId: e.value,
           };
           setFieldValue(
            `customFields[${index}].customFields[${findIndexCustomField()}].currencyId`,
            e.value
           );
           dispatch(pricingCostDriverAction.resetDataSimulate([]));
           debouncedRunSimulate(dataToEdit);
          }}
          options={selectOption}
          width={180}
          mt={7}
          noBorder={true}
         />
        </>
       ))}
      {typeData !== 50 && convertName !== "currency" && (
       <TextInputCustomNumber
        name={`customFields[${index}].${valueConvertName}`}
        id={`customFields[${index}].${valueConvertName}`}
        isDisabled={
         loading ||
         isOpenField ||
         valueIsIncludeCalculation ||
         mantenanceMasterdata ||
         // (isNew && row.index === 0 && !required) ||
         (!isRequired && valueConvertName !== "unitCost") ||
         typeData === 40 ||
         row.customFields[findIndexCustomField()]?.isMaintenanceByMasterData ||
         isErrorDuplicate ||
         (row.masterDataConfiguration === null &&
          convertName !== "description" &&
          haveEmptyName) ||
         (row.masterDataConfiguration === null &&
          convertName !== "name" &&
          haveEmptyName) ||
         (isErrorDuplicate && convertName !== "name")
        }
        onChange={(value: any) => {
         const dataSendToSimulate = {
          name: convertName,
          value: removeThousandSeparator(value),
          currentRow1: findOriginalCostDriver?.data[findIndex],
          tableName: findOriginalCostDriver?.tableName,
          dataFromSimulate: findOneCostDriverOriginal?.data[findIndex],
          isSameData: false,
          unitCostLabel: "",
         };
         const findUnitCostName = findOriginalCostDriver?.headers.find(
          (item: any) => item.name === convertName
         );

         let unitCostLabel = findUnitCostName?.label;

         if (findOriginalCostDriver?.costDriverUrl === paramName) {
          //  if (convertName === "unitCost" && index !== -1) {
          //   setFieldValue(`customFields[${index - 1}].${convertName}`, value);
          //  }
          const isCustomField = convertName !== "unitCost";
          const isSameData = ValidationData(
           findOneCostDriverOriginal?.data[findIndex],
           convertName,
           value,
           isCustomField,
           "isIncludeCalculation"
          );
          dataSendToSimulate.unitCostLabel = unitCostLabel;
          if (isSameData) {
           dataSendToSimulate.isSameData = true;
           const tmpChangeToDeletes = {
            component: `${findOneCostDriverOriginal?.data[findIndex].name} - ${
             convertName === "unitCost" ? unitCostLabel : convertName
            }`,
            name: convertName,
            constDriverId:
             findOneCostDriverOriginal?.data[findIndex].constDriverId,
           };
           dispatch(
            pricingCostDriverAction.removeOnetmpChangeRow({
             tmpChangeToDeletes,
            })
           );
          }
          const isRequired = values.customFields[index]?.customFields?.find(
           (i: any) => i.isRequired && i.value === ""
          );

          if (!isRequired) {
           dispatch(
            pricingCostDriverAction.addDataToSimulate(dataSendToSimulate)
           );
           //  debouncedRunSimulate(dataSendToSimulate);
          }
         }
        }}
       />
      )}
     </HStack>
    </>
   ) : (
    <TextInput
     name={`customFields[${index}].${valueConvertName}`}
     id={`customFields[${index}].${valueConvertName}`}
     isDuplicate={errorDuplicate}
     onChange={(value) => {
      const dataToAdd = {
       name: convertName,
       value: value.currentTarget.value,
       dataCustomFields: findOneCostDriverOriginal?.data[findIndex],
       datalatest: costDriver,
       latestCustomFields: customFieldsData,
       customFieldConfigurationId:
        row?.customFields[index]?.customFieldConfigurationId,
       newRow,
      };
      const dataFromServer = findOneCostDriverOriginal?.data[findIndex];
      const isSameId =
       row?.isNew && dataFromServer?.name === ""
        ? true
        : dataFromServer?.id !== row?.id;
      if (
       (row?.isNew && row?.masterDataId !== "" && dataFromServer.index === 0) ||
       isSameId
      ) {
       isNewDebounce(dataToAdd);
       //  pricingCostDriverAction.setDataToSimulate(dataToAdd)
      } else {
       if (value.currentTarget.value !== "") {
        const dataToEdit = {
         name: convertName,
         value: value.currentTarget.value,
         currentRow1: findOriginalCostDriver?.data[findIndex],
         tableName: findOriginalCostDriver?.tableName,
         dataFromSimulate: findOneCostDriverOriginal?.data[findIndex],
         isSameData: false,
        };

        setFieldValue(
         `customFields[${
          index - 1
         }].customFields[${findIndexCustomField()}].${convertName}`,
         value.currentTarget.value
        );
        // debouncedRunSimulate(dataToEdit);
        dispatch(pricingCostDriverAction.addDataToSimulate(dataToEdit));
        const isSameData = ValidationData(
         findOriginalCostDriver?.data[findIndex],
         convertName,
         value.currentTarget.value,
         false,
         "isNotIncludeCalculation"
        );

        if (isSameData) {
         const tmpChangeToDeletes = {
          component: `${findOriginalCostDriver?.data[findIndex].name} - ${convertName}`,
          name: convertName,
          constDriverId: findOriginalCostDriver?.data[findIndex].constDriverId,
         };

         dispatch(
          pricingCostDriverAction.removeOnetmpChangeRow({ tmpChangeToDeletes })
         );
        }
       }
      }
     }}
     // isDisabled={
     //  valueIsIncludeCalculation ||
     //  (row.index === 0 && !required && convertName !== "description") ||
     //  isOpenField ||
     //  (isErrorDuplicate && convertName !== "name") ||
     //  (row.masterDataConfiguration === null &&
     //   convertName !== "description" &&
     //   haveEmptyName) ||
     //  (row.masterDataConfiguration === null &&
     //   convertName !== "name" &&
     //   haveEmptyName)
     // }
     isDisabled={
      loading ||
      // (!isEditOpen || !newRow?.isNew) ||
      newRow.customFields[findIndexCustomField()]?.isMaintenanceByMasterData ||
      (newRow.masterDataConfiguration === null &&
       convertName !== "description" &&
       haveEmptyName) ||
      (newRow.masterDataConfiguration === null &&
       convertName !== "name" &&
       haveEmptyName) ||
      valueIsIncludeCalculation ||
      isMaintainWithMasterData ||
      (isErrorDuplicate && convertName !== "name") ||
      (isErrorDuplicate && convertName !== "name") ||
      convertName === "totalCost"
     }
    />
   )}
  </>
 );
};

export default TextInputInTableView;
