import Table from "components/Table/Table";
import Pagination from "components/Pagination";
import { useAppDispatch, usePrevious } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { useHistory, Link } from "react-router-dom";
import { TableLoading } from "components/Loading";
import {
 Box,
 Image,
 Button,
 Stack,
 Center,
 Heading,
 Flex,
} from "@chakra-ui/react";
import { FC, useEffect, useState, useMemo } from "react";
import OptipediaToast from "utils/notification-util";
import { actions as productConfigurationsActions } from "state/productConfigurations/slice";
import Swal from "sweetalert2";
import IconDelete from "assets/images/Delete.png";
import debounce from "lodash/debounce";
import "utils/date-utils";
import EmptyIcon from "assets/images/EmptyData.png";
import HumanIlustrator from "assets/images/human-bottom.png";
import PaginationNumber from "components/PaginationNumber";
import SearchBar from "components/SearchBar";
import NewPagination from "components/NewPagination";

const TABLE_HEADERS = [
 { name: "journeyCode", label: "Journey Code" },
 { name: "journeyName", label: "Journey name" },
 { name: "levelName", label: "Level" },
 { name: "creationTime", label: "Date created" },
];

const JourneyTable: FC = () => {
 const dispatch = useAppDispatch();
 const history = useHistory();
 const maxResultCount = 10;
 const [pageNumber, setPageNumber] = useState(1);

 const { lists, loading, totalPage, totalCountJourney, searchKey } =
  useSelector(
   (state: RootState) => ({
    lists: state.productConfigurations.result.items,
    totalPage: Math.ceil(
     state.productConfigurations.result.totalCount / maxResultCount
    ),
    totalCountJourney: state.productConfigurations.result.totalCount,
    loading: state.productConfigurations.loading,
    searchKey: state.productConfigurations.searchQuery,
   }),
   shallowEqual
  );

 const fetchListOfJourney = (skipCount: number, searchKey: string) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: maxResultCount,
   searchKey: searchKey,
  };

  return dispatch(productConfigurationsActions.getList(payload));
 };

 const previousSearchKey = usePrevious(searchKey, "");
 const debouncedRefreshList = useMemo(
  () =>
   debounce((searchKey: string) => {
    return fetchListOfJourney(0, searchKey);
   }, 800),
  [dispatch]
 );

 useEffect(() => {
  fetchListOfJourney(0, searchKey);
 }, []);

 useEffect(() => {
  if (searchKey !== previousSearchKey) {
   debouncedRefreshList(searchKey);
  }
 }, [searchKey]);

 const deleteJourney = async (journeyId: string) => {
  await dispatch(
   productConfigurationsActions.deleteJourney({ id: journeyId })
  ).then((res) => {
   if (res.payload !== undefined) {
    OptipediaToast.Success("The Journey has been removed successfully.");
   }
  });

  var skipCount = 0;
  if (lists?.length - 1 > 0) {
   skipCount = (pageNumber - 1) * maxResultCount;
   setPageNumber(pageNumber);
  }

  fetchListOfJourney(skipCount, searchKey);
 };

 const handleChangePagination = (pageNumber: number) => {
  setPageNumber(pageNumber);
  var skipCount = (pageNumber - 1) * maxResultCount;
  fetchListOfJourney(skipCount, searchKey);
 };

 let listJourney: any = [];
 lists.map((item: any) => {
  const { journeyCode, journeyName, levelName, creationTime, id } = item;
  listJourney.push({
   journeyCode,
   journeyName,
   levelName,
   id,
   creationTime: Date.toString(creationTime),
  });
 });
 const handleChangeSearchKey = (event: any) => {
  dispatch(
   productConfigurationsActions.setSearchQuery({
    value: event.currentTarget.value,
   })
  );
 };

 if (loading) {
  return <TableLoading />;
 }

 return (
  <>
   {(!loading && listJourney.length) > 0 ? (
    <>
     <Flex mt="2rem">
      <PaginationNumber
       pageNumber={totalPage}
       currentPage={pageNumber}
       totalAllPage={totalCountJourney}
       totalRowsPage={maxResultCount}
      />
      <Box pb="1rem">
      <SearchBar
       value={searchKey}
       onChange={handleChangeSearchKey}
       placeholder={"Search..."}
       isLoading={loading}
      />
      </Box>
     </Flex>
     <Box
      w="100%"
      borderRadius={"4"}
     >
      <Table
       loading={loading}
       columnHeaders={TABLE_HEADERS}
       data={listJourney}
       actionButtons={(journeyId: string) => (
        <Stack spacing={4} direction="row" align="center" float="right">
         <Image
          src={IconDelete}
          cursor="pointer"
          onClick={async () => {
           Swal.fire({
            showCancelButton: true,
            html: "Are you sure you want to delete this Journey?",
            confirmButtonText: "Yes",
            customClass: {
             confirmButton: "swal2-confirm",
            },
           }).then(async (result) => {
            if (result.isConfirmed) {
             deleteJourney(journeyId);
            }
           });
          }}
          alt="IoMdTrash"
          mr="1rem"
         />
         <Button
          variant={"link"}
          fontSize="16px"
          color={"#0072CF"}
          onClick={() => history.push(`/journeys/${journeyId}`)}
         >
          View
         </Button>
        </Stack>
       )}
      />
      <Box w="100%" mt="1rem" p="20px">
       {listJourney.length > 0 && (
        <NewPagination
        onChange={handleChangePagination}
        totalRowsPage={maxResultCount}
        pageNumber={Math.ceil(totalCountJourney / maxResultCount)}
        currentPage={pageNumber}
        totalAllPage={totalCountJourney}
       />
       )}
      </Box>
     </Box>
    </>
   ) : (
    <Box marginTop={"5rem"} textAlign="center">
     <Center>
      <Image src={EmptyIcon} />
     </Center>
     <Heading my={4} as={"h2"} fontSize="18px">
      No Data Available
     </Heading>
     <Box mr="2rem" p="5">
      <Link to="/journeys/create?page=level-overview">
       <Button
        zIndex="9"
        bg="#FFFFFF"
        // w="220px"
        h="49"
        borderRadius="5px"
        border={"1px"}
        borderColor="#0072CF"
        fontSize="16px"
        color="#0072CF"
        // _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
       >
        TAKE A TOUR & CREATE JOURNEY
       </Button>
      </Link>
     </Box>
    </Box>
    // <Box position="absolute" bottom="5" right="10">
    //  <Image src={HumanIlustrator} alt="Bulp" w="474px" float="right" />
    // </Box>
   )}
  </>
 );
};

export default JourneyTable;
