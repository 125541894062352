import { FC, useEffect, useState } from "react";
import {
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 Box,
 ModalProps,
 Text,
 Divider,
 Stack,
 VStack,
 Button,
 List,
 ListItem,
 FormControl,
 FormHelperText
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { useField } from "formik";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
import { orderBy } from "lodash";
import { actions as myJourneyActions } from "state/myJourney/slice";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import { matchSorter } from "match-sorter";
import useUndoable from "use-undoable"

type ReportListModalProps = Omit<ModalProps, "children"> & {
 name: string;
 formulaFields: any;
 costDriverList: any;
};
const Formula: FC<ReportListModalProps> = ({
 isOpen,
 onClose,
 name,
 formulaFields,
 costDriverList,
}) => {
 const dispatch = useAppDispatch();
 const [field, meta, { setValue, setTouched }] = useField(name);
 const { costDriverFields, formulaList, isValidate, valueCostDriver } = useSelector(
  (state: RootState) => ({
   costDriverFields: state.myJourney.costDriverFields,
   formulaList: state.myJourney.formulaList,
   isValidate: state.myJourney.validate,
   valueCostDriver: state.myJourney.valueCostDriver
  }),
  shallowEqual
 );
 const [formulaValue, setFormulaValue, { undo, redo, resetInitialState }] = useUndoable({data:""})
 useEffect(() => {
  setFormulaValue({data: field.value})
  resetInitialState({data: field.value})
 }, []);

 useEffect(() => {
  dispatch(
   myJourneyActions.getAllFormulaCostDriver({
    productConfigurationId: costDriverList.id,
   })
  );
 }, [valueCostDriver]);

 const list: any = [];
 const optionCostdriver: any = [];
 if (costDriverList?.costDrivers.length > 0) {
  list.push(...costDriverList?.costDrivers);
  costDriverList?.costDrivers.map((item: any) => {
   if (item.costDrivers.length > 0) {
    list.push(...item.costDrivers);
    item.costDrivers.map((item: any) => {
     if (item.costDrivers.length > 0) {
      list.push(...item.costDrivers);
     }
    });
   }
  });
 }
 
 if (list.length > 0) {
  orderBy(list, [item => item.identityCode.toLowerCase()], "asc").map((item: any, index: number) => {
   const data = {
    label: `${item.identityCode} - ${item.identityName}`,
    value: item.id,
   };
   optionCostdriver.push(data);
  });
 }

 const Item = ({ entity: { identifier } }: any) => (
  <Text>{identifier}</Text>
 );
 const Loading = () => <div className="loading">Loading...</div>;
 var formula = formulaList.map((item) => {
  const { name, identifier, category } = item;
  return {
   identityName: name,
   identifier: identifier,
   category: category,
  };
 });

 const formulaActionList = (key: string) =>
  matchSorter(formula, key, {keys: ['identityName', 'identifier' ]})

 return (
  <>
   <Modal
    closeOnEsc={false}
    size="6xl"
    isOpen={isOpen}
    onClose={() => {
      dispatch(myJourneyActions.setResetValidation({}))
      dispatch(myJourneyActions.setResetCostDriverFields([]))
      onClose()
    }}
    isCentered
   >
    <ModalOverlay />
    <ModalContent
     boxShadow="0px 0px 56px rgba(157, 161, 167, 0.37)"
     borderRadius="5px"
     overflowX="hidden"
     height="unset"
     minW={"95%"}
     p="0px 10px 32px 10px"
    >
     <ModalHeader fontSize="24px" textTransform="capitalize">
      Expression Builder
     </ModalHeader>
     <Divider />
     <ModalCloseButton mt="1.5rem" mr="1rem" _focus={{ boxShadow: "none" }} />
     <ModalBody>
      <Box
       overflowY="scroll"
       overflowX="hidden"
       pr="10px"
       marginBottom="4rem"
       sx={{
        "&::-webkit-scrollbar": { width: "6px" },
        "&::-webkit-scrollbar-track": { width: "6px" },
        "&::-webkit-scrollbar-thumb": {
         borderRadius: "24px",
         backgroundColor: "#0072CF",
        },
       }}
       height={`${window.innerHeight - 200}px`}
      >
       <Text fontSize={"20px"} pb="10px" fontWeight={600}>
        Expression
       </Text>
       <Stack direction={["column", "row"]} spacing="24px">
        <FormControl>
        <ReactTextareaAutocomplete
         name={name}
         value={formulaValue.data}
         placeholder="Selecting your “Cost Driver” as the first step will let you personalize the calculation."
         style={{
          color: "#0072CF",
          borderRadius: "5px",
          border: isValidate.isValid === false  ?  "1.5px solid red" : "1px solid rgba(216, 216, 217, 1)",
          padding: "8px 12px 40px 12px",
          fontSize: "16px",
          width: "100%",
         }}
         containerStyle={{
          width: "100%",
          height: "90px",
         }}
         onChange={(e) => {
          setFormulaValue({data: e.target.value})
          setTouched(true, true);
         }}
         onBlur={(e)=>{
          setFormulaValue({data: e.target.value.trim()})
         }}
         loadingComponent={Loading}
         itemStyle={{
          position: "relative",
          zIndex: 9999,
          border: "none",
          padding: "5px",
         }}
         listStyle={{
          position: "relative",
          padding: "5px",
          zIndex: 9999,
         }}
         dropdownStyle={{
          color: "#red !important",
         }}
         trigger={{
          "[": {
           dataProvider: (token) => {
            return formulaActionList(token).slice(0, 10).map(({identifier }) => ({identifier }));
           },
           component: Item,
           output: (item: any) => item.identifier,
          },
         }}
        />
        <FormHelperText color="red.600">
          {isValidate.message}
        </FormHelperText>
        </FormControl>
        <VStack spacing={"24px"} align="stretch">
         <Box>
          <Button
           bg="#EDFDFF"
           w="136px"
           h="40px"
           borderRadius="4"
           fontSize="16"
           color="black"
           boxShadow={"0px 2px 4px 0px #00000040"}
           _focus={{ border: "none 0px white" }}
           onClick={() => {
            setValue(formulaValue.data);
            dispatch(myJourneyActions.validateFormula({
              productConfigurationId: costDriverList.id,
              expression: formulaValue.data
             })).then((res: any)=> {
              if(res.payload.isValid){
                dispatch(myJourneyActions.setResetCostDriverFields([]))
                dispatch(myJourneyActions.setValueCostDriver(null))
                onClose()
              }
             })
           }}
          >
           Save
          </Button>
         </Box>
         <Box>
          <Button
           bg="#EDFDFF"
           w="136px"
           h="40px"
           borderRadius="4"
           fontSize="16"
           color="black"
           boxShadow={"0px 2px 4px 0px #00000040"}
           _focus={{ border: "none 0px white" }}
           onClick={() => {
            setFormulaValue({data: field.value})
            dispatch(myJourneyActions.setResetValidation({}))
            onClose()
           }}
           //  _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
          >
           Cancel
          </Button>
         </Box>
        </VStack>
       </Stack>
       <Text py="10px" fontSize={"16px"} fontWeight={700}>
        Click or press the appropriate key on your keyboard to select arithmetic
        operator.
       </Text>
       <Stack py="1rem" direction={["column", "row"]} spacing="25px">
        <Box>
         <Button
          bg="#EDFDFF"
          size={"custom"}
          w="68px"
          h="39px"
          borderRadius="5"
          fontSize="16"
          color="black"
          boxShadow={"0px 2px 4px 0px #00000040"}
          _focus={{ border: "none 0px white" }}
          onClick={() => {
           setFormulaValue({data: formulaValue.data + "*"});
          }}
          //  _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         >
          *
         </Button>
        </Box>
        <Box>
         <Button
          bg="#EDFDFF"
          size={"custom"}
          w="68px"
          h="39px"
          borderRadius="5"
          fontSize="16"
          color="black"
          boxShadow={"0px 2px 4px 0px #00000040"}
          _focus={{ border: "none 0px white" }}
          onClick={() => {
           setFormulaValue({data: formulaValue.data + "-"});
          }}
          //  _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         >
          -
         </Button>
        </Box>
        <Box>
         <Button
          bg="#EDFDFF"
          size={"custom"}
          w="68px"
          h="39px"
          borderRadius="4"
          fontSize="16"
          color="black"
          boxShadow={"0px 2px 4px 0px #00000040"}
          _focus={{ border: "none 0px white" }}
          onClick={() => 
            setFormulaValue({data: formulaValue.data + "+"})}
          //  _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         >
          +
         </Button>
        </Box>
        <Box>
         <Button
          bg="#EDFDFF"
          size={"custom"}
          w="68px"
          h="39px"
          borderRadius="4"
          fontSize="16"
          color="black"
          boxShadow={"0px 2px 4px 0px #00000040"}
          _focus={{ border: "none 0px white" }}
          onClick={() => {
           setFormulaValue({data: formulaValue.data + "/"});
          }}
          //  _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         >
          /
         </Button>
        </Box>
        <Box>
         <Button
          bg="#EDFDFF"
          size={"custom"}
          w="68px"
          h="39px"
          borderRadius="4"
          fontSize="16"
          color="black"
          boxShadow={"0px 2px 4px 0px #00000040"}
          _focus={{ border: "none 0px white" }}
          onClick={() => {
           setFormulaValue({data: formulaValue.data + "\\"});
          }}
          //  _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         >
          \
         </Button>
        </Box>
        <Box>
         <Button
          bg="#EDFDFF"
          size={"custom"}
          w="68px"
          h="39px"
          borderRadius="4"
          fontSize="16"
          color="black"
          boxShadow={"0px 2px 4px 0px #00000040"}
          _focus={{ border: "none 0px white" }}
          onClick={() => {
           setFormulaValue({data: formulaValue.data + "^"});
          }}
          //  _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         >
          ^
         </Button>
        </Box>
        <Box>
         <Button
          bg="#EDFDFF"
          size={"custom"}
          w="89px"
          h="39px"
          borderRadius="4"
          fontSize="16"
          color="black"
          boxShadow={"0px 2px 4px 0px #00000040"}
          _focus={{ border: "none 0px white" }}
          onClick={undo}
          //  _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         >
          Undo
         </Button>
        </Box>
        <Box>
         <Button
          bg="#EDFDFF"
          size={"custom"}
          w="89px"
          h="39px"
          borderRadius="4"
          fontSize="16"
          color="black"
          boxShadow={"0px 2px 4px 0px #00000040"}
          _focus={{ border: "none 0px white" }}
          onClick={redo}
          //  _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         >
          Redo
         </Button>
        </Box>
        <Box>
         <Button
          bg="#EDFDFF"
          w="136px"
          h="40px"
          borderRadius="4"
          fontSize="16"
          color="black"
          boxShadow={"0px 2px 4px 0px #00000040"}
          _focus={{ border: "none 0px white" }}
          onClick={() => {
           setFormulaValue({data: ""})
          }}
          //  _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         >
          Clear
         </Button>
        </Box>
       </Stack>
       <Stack direction={["column", "row"]}>
        <VStack w={150} align="stretch" my="auto">
         <Text textAlign={"left"} fontSize="16px" fontWeight={700}>
          Cost Driver
         </Text>
        </VStack>
        <VStack w={"80%"} align="stretch" my="1rem">
         <SelectWithAutoComplete
          width={275}
          name="costDriver"
          options={optionCostdriver}
          value={optionCostdriver.filter(function(option: any) {
            return option.value === valueCostDriver;
          })}
          placeholder="Please Select Cost Driver..."
          onChange={(e: any) => {
            dispatch(myJourneyActions.setValueCostDriver(e.value))
           dispatch(
            myJourneyActions.getFormulaByCostDriver({
             productConfigurationId: costDriverList.id,
             costDriverConfigurationId: e.value
            })
           );
          }}
         />
        </VStack>
       </Stack>
       <Stack direction={["column", "row"]} spacing="24px" mt="1rem">
        <Box
         my="1rem"
         border="solid 1px rgba(26, 26, 26, 0.16)"
         borderRadius={5}
         h={274}
         minW="433px"
         overflowY="scroll"
         overflowX="hidden"
         sx={{
          "&::-webkit-scrollbar": { width: "6px" },
          "&::-webkit-scrollbar-track": { width: "6px" },
          "&::-webkit-scrollbar-thumb": {
           borderRadius: "24px",
           backgroundColor: "rgba(26, 26, 26, 0.16)",
          },
         }}
        >
         <List p="12px">
          {costDriverFields?.length > 0 &&
           costDriverFields.map((item: any, index: number) => (
            <ListItem onClick={() => {
              setFormulaValue({data: formulaValue.data + item.identifier})
            }} p="5px" _hover={{bgColor: 'rgba(226, 241, 253, 1)', borderRadius: "5px", cursor:"pointer"}} key={index}>{item.name}</ListItem>
           ))}
         </List>
        </Box>
        <Box
         marginTop={"1rem !important"}
         border="solid 1px rgba(26, 26, 26, 0.16)"
         borderRadius={5}
         h={274}
         minW="50%"
         overflowY="scroll"
         overflowX="hidden"
         sx={{
          "&::-webkit-scrollbar": { width: "6px" },
          "&::-webkit-scrollbar-track": { width: "6px" },
          "&::-webkit-scrollbar-thumb": {
           borderRadius: "24px",
           backgroundColor: "rgba(26, 26, 26, 0.16)",
          },
         }}
        >
         <List p="12px">
          {formulaFields?.result.length > 0 &&
           formulaFields?.result.map((item: any, index: number) => (
            <ListItem key={index}>
             {item.name} &nbsp;{" "}
             <span
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
             >{`(${item.description})`}</span>
            </ListItem>
           ))}
         </List>
        </Box>
       </Stack>
      </Box>
     </ModalBody>
    </ModalContent>
   </Modal>
  </>
 );
};

export default Formula;
