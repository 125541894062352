import { createSlice } from "@reduxjs/toolkit";
import {
 getSizeUnit,
 getByIdSizeUnit,
 addSizeUnit,
 editSizeUnit,
 deleteSizeUnit,
} from "state/sizeOfPricingUnit/thunk";
interface InitialState {
 lists: any;
 loading: boolean;
 error: unknown;
}
const initialState: InitialState = {
 lists: {},
 loading: false,
 error: null,
};
const sizeOfUnitSlice = createSlice({
 name: "sizeOfUnit",
 initialState,
 reducers: {
  clear: () => initialState,
 },
 extraReducers: (builder) => {
  builder.addCase(getSizeUnit.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getSizeUnit.fulfilled, (state, action) => {
   state.lists = action.payload;
   state.loading = false;
  });

  builder.addCase(getSizeUnit.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
 },
});
export const actions = {
 ...sizeOfUnitSlice.actions,
 getSizeUnit,
 getByIdSizeUnit,
 addSizeUnit,
 editSizeUnit,
 deleteSizeUnit,
};

export const reducer = sizeOfUnitSlice.reducer;
