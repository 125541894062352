import { RootState } from "state/store";
import { FC, useEffect, useState } from "react";
import {
 Box,
 Button,
 Grid,
 Stat,
 StatLabel,
 StatNumber,
 StatHelpText,
 StatArrow,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Td,
 Tbody,
 Flex,
 Switch,
 Spacer,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { BarChartLoading, NumberLoading } from "components/Loading";
import { isEmpty, isNull, orderBy, sum } from "lodash";
import { actions as annualSpendAction } from "state/annualSpend/slice";
import SaveBusinessCase from "./saveBusinessCase";
import { TreemapChart, BarChart } from "components/ApexChart";
import Magnificiant from "components/Magnificiant";

const Summary: FC = () => {
 const router = useHistory();
 const dispatch = useAppDispatch();
 const [level, setLevel] = useState(0);
 const [isPercentage, setIsPercentage] = useState<boolean>(false);

 let {
  loading,
  barLoading,
  treemapLoading,
  simulate,
  journey,
  valueToSend,
  journeyId,
  simulateTreemapChart,
  simulateBarChart,
 } = useSelector(
  (state: RootState) => ({
   loading: state.annualSpendSimulations.loading,
   barLoading: state.annualSpendSimulations.barLoading,
   treemapLoading: state.annualSpendSimulations.treemapLoading,
   simulate: state.annualSpendSimulations.simulate,
   journey: state.annualSpendSimulations.journey,
   journeyId: state.annualSpendSimulations.journeyId,
   uploadResponse: state.annualSpendSimulations.uploadResponse,
   simulateTreemapChart:
    state.annualSpendSimulations.simulateTreemapChart.result,
   simulateBarChart: state.annualSpendSimulations.simulateBarChart.result,
   valueToSend: state.annualSpendSimulations.valueToSend,
  }),
  shallowEqual
 );
 useEffect(() => {
  window.scrollTo(0, 0);
  if (journey.length !== 0) {
   const data = journey.filter((item: any) => item.id === journeyId);
   setLevel(data[0].level);
  }
 }, []);

 useEffect(() => {
  if (simulate === isEmpty()) {
   router.push("/annual-spend-simulations");
  }
 }, []);

 const { isOpen, onOpen, onClose } = useDisclosure();
 const [barSummary, setBarSummary] = useState(null);
 const [treemapSummary, setTeemapSummary] = useState(null);
 const [thirdPartyType, setThirdPartyType] = useState(false);
 const [chartType, setChartType] = useState(false);

 const changeBar = (value: any) => {
  const sendData = {
   payload: valueToSend,
   isCurrent: chartType,
   level: value,
  };
  dispatch(annualSpendAction.simulateBarChart(sendData));
  setBarSummary(value);
 };
 const changeTreemap = (value: any) => {
  const sendData = {
   payload: valueToSend,
   isCurrent: chartType,
   level: value,
  };
  dispatch(annualSpendAction.simulateTreemapChart(sendData));
  setTeemapSummary(value);
 };
 const thirdPartyChart = simulate.thirdPartySummaryChart;
 const tmpBarThirdParty = thirdPartyChart?.map((x: any) => ({
  x: x.data.map((item: any) => item.identityName),
  y: !thirdPartyType
   ? x.data.map((item: any) => item.currentTotalSpend)
   : x.data.map((item: any) => item.whatIfTotalSpend),
  label: x.thirdPartyDescription,
 }));

 const seriesThirdPartyBar = tmpBarThirdParty?.map((item: any) => ({
  name: item.label,
  data: item.y,
  labelName: item.x,
 }));
 const summary =
  !isEmpty(simulateTreemapChart) &&
  simulateTreemapChart?.map((x: any) => ({
   x: x.identityName,
   y: !chartType ? x.currentContribution * 100 : x.whatIfContribution * 100,
   z: !chartType ? x.currentTotalSpend : x.whatIfTotalSpend,
   i: x.identityName,
  }));

 const series =
  !isEmpty(simulateTreemapChart) &&
  simulateTreemapChart?.map((x: any) => ({
   name: x.identityName,
   data: x.data?.map((item: any) => ({
    x: item.identityName,
    y: !chartType
     ? isPercentage
       ? (item.currentContribution * 100).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         }) + "%"
       : item.currentTotalSpend
     : isPercentage
     ? (item.whatIfContribution * 100).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
       }) + "%"
     : item.whatIfTotalSpend,
    i: item.identityName,
    z: !chartType ? item.currentTotalSpend : item.whatIfTotalSpend,
    addon: [],
   })),
  }));
 const barDataBaseOnSequence: any = orderBy(
  simulateBarChart,
  ["sequence"],
  ["asc"]
 );
 const tmpCurrentDataBarChart =
  !isEmpty(simulateBarChart) &&
  barDataBaseOnSequence.map((x: any) => ({
   x: x.identityName,
   y: x.data?.map((item: any) => item.currentTotalSpend),
   whatIf: x.data?.map((item: any) => item.whatIfTotalSpend),
  }));

 const tmpWhatIfDataBarChart =
  !isEmpty(simulateBarChart) &&
  barDataBaseOnSequence?.map((x: any) => ({
   x: x.identityName,
   y: x.data?.map((item: any) => item.whatIfTotalSpend),
  }));

 const seriesBar = !isEmpty(simulateBarChart) && [
  {
   name: "Current",
   data: tmpCurrentDataBarChart?.map((x: any) => ({
    x: x.x,
    y: sum(x.y),
   })),
   labelName: tmpCurrentDataBarChart?.map((x: any) => {
    return x["x"];
   }),
  },
  {
   name: "What-if",
   data: tmpWhatIfDataBarChart.map((x: any) => ({
    x: x.x,
    y: sum(x.y),
   })),
   labelName: tmpWhatIfDataBarChart.map((x: any) => {
    return x["x"];
   }),
  },
 ];

 return (
  <Box mb="6rem">
   <Grid templateColumns="repeat(4, 1fr)" gap={2} pt="2rem" pb="1rem">
    {!loading ? (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
     >
      <Stat>
       <StatLabel color="#9DA1A7" fontSize="16px">
        Current total spend
       </StatLabel>
       <StatNumber fontSize="24px">
        €
        {simulate.currentTotalSpend?.toLocaleString(undefined, {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
        })}
       </StatNumber>
      </Stat>
     </Box>
    ) : (
     <Box
      textAlign="center"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
     >
      <NumberLoading />
     </Box>
    )}

    {!loading ? (
     <Box
      pl="28px"
      pt="29px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
     >
      <Stat>
       <StatLabel color="#9DA1A7" fontSize="16px">
        What-if total spend
       </StatLabel>
       <StatNumber fontSize="24px">
        €
        {simulate.whatIfTotalSpend?.toLocaleString(undefined, {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
        })}
       </StatNumber>
      </Stat>
     </Box>
    ) : (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
     >
      <NumberLoading />
     </Box>
    )}
    {!loading ? (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
     >
      <Stat>
       <StatLabel color="#9DA1A7" fontSize="16px">
        Difference
       </StatLabel>
       <StatNumber fontSize="24px">
        €
        {simulate.difference?.toLocaleString(undefined, {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
        })}
       </StatNumber>
       {simulate.difference <= 0 ? (
        <StatHelpText>
         <StatArrow type="decrease" color={"green"} />
         {(simulate.differenceInPercentage * 100)?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         })}
         %
        </StatHelpText>
       ) : (
        <StatHelpText>
         <StatArrow type="increase" color={"red"} />
         {(simulate.differenceInPercentage * 100)?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         })}
         %
        </StatHelpText>
       )}
      </Stat>
     </Box>
    ) : (
     <Box
      pl="28px"
      pt="28px"
      w="238px"
      h="139px"
      border="1px solid #D7DBE2"
      borderRadius="5px"
     >
      <NumberLoading />
     </Box>
    )}
   </Grid>
   {simulate.isEnableThirdParty && (
    <Box border="1px solid #D7DBE2" w="100%" p={4} borderRadius="5px">
     <Box pl="1rem" pb="2rem">
      <Box alignItems="center" fontWeight="600" mt="1rem" mr="1rem">
       {thirdPartyType ? "What-If " : "Current "}
       <Switch
        defaultChecked={thirdPartyType ? true : false}
        ml="5px"
        onChange={() => setThirdPartyType(!thirdPartyType)}
       />
      </Box>
     </Box>
     <BarChart data={seriesThirdPartyBar} height={300} type="bar" />
    </Box>
   )}
   {/* <Grid templateColumns="repeat(2, 1fr)" gap={4} pb="1rem" pt="1rem"> */}
    <Box border="1px solid #D7DBE2" w="100%" p={4} my={4} borderRadius="5px">
     <Box pl="1rem" pb="2rem">
      <Flex>
       <Button
        disabled={isEmpty(simulateBarChart) ? true : false}
        size={"sm"}
        variant="ghost"
        color={isEmpty(barSummary) ? "#0061BD" : "#C3C7CD"}
        onClick={() => changeBar(null)}
       >
        Summary
       </Button>
       {level !== 0 && (
        <Button
         disabled={isEmpty(simulateBarChart) ? true : false}
         size={"sm"}
         variant="ghost"
         color={barSummary === "1" ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeBar("1")}
        >
         Level 1
        </Button>
       )}
       {level !== 0 && (
        <Button
         disabled={isEmpty(simulateBarChart) ? true : false}
         size={"sm"}
         variant="ghost"
         color={barSummary === "2" ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeBar("2")}
        >
         Level 2
        </Button>
       )}
       <Spacer />
       <Magnificiant
        isActive={isEmpty(simulateBarChart) ? true : false}
        data={
         <Box p="1rem">
          <Button
           disabled={isEmpty(simulateBarChart) ? true : false}
           size={"sm"}
           variant="ghost"
           color={isEmpty(barSummary) ? "#0061BD" : "#C3C7CD"}
           onClick={() => changeBar(null)}
          >
           Summary
          </Button>
          {level !== 0 && (
           <Button
            disabled={isEmpty(simulateBarChart) ? true : false}
            size={"sm"}
            variant="ghost"
            color={barSummary === "1" ? "#0061BD" : "#C3C7CD"}
            onClick={() => changeBar("1")}
           >
            Level 1
           </Button>
          )}
          {level !== 0 && (
           <Button
            disabled={isEmpty(simulateBarChart) ? true : false}
            size={"sm"}
            variant="ghost"
            color={barSummary === "2" ? "#0061BD" : "#C3C7CD"}
            onClick={() => changeBar("2")}
           >
            Level 2
           </Button>
          )}
          {!barLoading && <BarChart data={seriesBar} type="bar" height={600} />}
          {treemapLoading && <BarChartLoading />}
         </Box>
        }
       />
      </Flex>
     </Box>
     {!barLoading && <BarChart data={seriesBar} height={300} type="bar" />}
     {barLoading && <BarChartLoading />}
    </Box>
    <Box
     border="1px solid #D7DBE2"
     w="100%"
     minH={300}
     p={4}
     my={4}
     borderRadius="5px"
    >
     <Box>
      <Flex>
       <Button
        disabled={isEmpty(simulateTreemapChart) ? true : false}
        size={"sm"}
        variant="ghost"
        color={isEmpty(treemapSummary) ? "#0061BD" : "#C3C7CD"}
        onClick={() => changeTreemap(null)}
       >
        Summary
       </Button>
       {level !== 0 && (
        <Button
         disabled={isEmpty(simulateTreemapChart) ? true : false}
         size={"sm"}
         variant="ghost"
         color={treemapSummary === "1" ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeTreemap("1")}
        >
         Level 1
        </Button>
       )}
       {level !== 0 && (
        <Button
         disabled={isEmpty(simulateTreemapChart) ? true : false}
         size={"sm"}
         variant="ghost"
         color={treemapSummary === "2" ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeTreemap("2")}
        >
         Level 2
        </Button>
       )}
       <Spacer />
       <Magnificiant
        isActive={isEmpty(simulateTreemapChart) ? true : false}
        data={
         <Box p="1rem">
          <Button
           disabled={isEmpty(simulateTreemapChart) ? true : false}
           size={"sm"}
           variant="ghost"
           color={isEmpty(treemapSummary) ? "#0061BD" : "#C3C7CD"}
           onClick={() => changeTreemap(null)}
          >
           Summary
          </Button>
          <Button
           disabled={isEmpty(simulateTreemapChart) ? true : false}
           size={"sm"}
           variant="ghost"
           color={treemapSummary === "1" ? "#0061BD" : "#C3C7CD"}
           onClick={() => changeTreemap("1")}
          >
           Level 1
          </Button>
          <Button
           disabled={isEmpty(simulateTreemapChart) ? true : false}
           size={"sm"}
           variant="ghost"
           color={treemapSummary === "2" ? "#0061BD" : "#C3C7CD"}
           onClick={() => changeTreemap("2")}
          >
           Level 2
          </Button>

          <Flex>
           <Box alignItems="center" fontWeight="600" ml="1rem">
            {chartType ? "What-If" : "Current"}
            <Switch
             defaultChecked={chartType ? true : false}
             ml="5px"
             onChange={() => setChartType(!chartType)}
            />
           </Box>
           <Box alignItems="center" fontWeight="600" mr="1rem" ml="1rem">
            {isPercentage ? "Percentage" : "Cost Number"}
            <Switch
             defaultChecked={isPercentage}
             ml="5px"
             mr="5px"
             onChange={() => setIsPercentage(!isPercentage)}
            />
           </Box>
          </Flex>
          {!treemapLoading && <TreemapChart data={series} height={600} />}
          {treemapLoading && <BarChartLoading />}
         </Box>
        }
       />
      </Flex>
      <Flex>
       <Box alignItems="center" fontWeight="600" ml="1rem">
        {chartType ? "What-If" : "Current"}
        <Switch
         defaultChecked={chartType ? true : false}
         ml="5px"
         onChange={() => setChartType(!chartType)}
        />
       </Box>
       <Box alignItems="center" fontWeight="600" mr="1rem" ml="1rem">
        {isPercentage ? "Percentage" : "Cost Number"}
        <Switch
         defaultChecked={isPercentage}
         ml="5px"
         mr="5px"
         onChange={() => setIsPercentage(!isPercentage)}
        />
       </Box>
      </Flex>
     </Box>
     {!treemapLoading && (
      <TreemapChart data={series} type="treemap" height={300} />
     )}
     {treemapLoading && <BarChartLoading />}
    </Box>
   {/* </Grid> */}

   {!loading ? (
    <Box
     position="fixed"
     bottom="0"
     backgroundColor="#ffffff"
     height="5.5rem"
     w="100%"
     zIndex={2}
    >
     <Flex float="right" mr="20rem">
      <Box mt={4}>
       <Flex alignItems="right" gap="2">
        <>
         <Button
          variant={'outline'}
          onClick={() => router.goBack()}
         >
          Back
         </Button>
         <Button
          onClick={onOpen}
         >
          View Changes
         </Button>
         <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent minW="60%" borderRadius="5px">
           <ModalCloseButton />
           <ModalHeader>Simulation Changes Log</ModalHeader>
           <ModalBody>
            <TableContainer>
             <Table variant="mytable">
              <Thead borderBottom="1px solid #D7DBE2">
               <Tr>
                <Th>Components</Th>
                <Th textAlign="right" isNumeric>
                 Change From
                </Th>
                <Th textAlign="right" isNumeric>
                 Change To
                </Th>
                <Th textAlign="right" isNumeric>
                 % Change
                </Th>
               </Tr>
              </Thead>
              <Tbody>
               {!isEmpty(simulateBarChart) &&
                tmpCurrentDataBarChart?.map((item: any, index: number) => (
                 <Tr key={index}>
                  <Td>{item.x}</Td>
                  <Td textAlign="right" isNumeric>
                   {String.toFormatSeparator(sum(item.y))}
                  </Td>
                  <Td textAlign="right">
                   {String.toFormatSeparator(sum(item.whatIf))}
                  </Td>
                  <Td textAlign="right">
                   <Stat>
                    <StatHelpText>
                     <StatArrow
                      type={
                       ((sum(item.whatIf) - sum(item.y)) / sum(item.y)) * 100 >=
                       0
                        ? "increase"
                        : "decrease"
                      }
                      color={
                       ((sum(item.whatIf) - sum(item.y)) / sum(item.y)) * 100 >=
                       0
                        ? "red"
                        : "green"
                      }
                     />
                     {`(${String.toFormatSeparator(
                      ((sum(item.whatIf) - sum(item.y)) / sum(item.y)) * 100
                     )} %)`}
                    </StatHelpText>
                   </Stat>
                  </Td>
                 </Tr>
                ))}
              </Tbody>
             </Table>
             <Table variant="mytable">
              <Tbody>
               <Tr>
                <Td width="100vh" textAlign="right" fontWeight={600}>
                 Total spend (Current) :{" "}
                </Td>
                <Td textAlign="right" fontWeight={600}>
                 {String.toFormatSeparator(simulate.currentTotalSpend)}
                </Td>
               </Tr>
               <Tr>
                <Td textAlign="right" fontWeight={600}>
                 Total spend (What-If) :{" "}
                </Td>
                <Td textAlign="right" fontWeight={600}>
                 {String.toFormatSeparator(simulate.whatIfTotalSpend)}
                </Td>
               </Tr>
              </Tbody>
             </Table>
            </TableContainer>
           </ModalBody>
          </ModalContent>
         </Modal>
         <SaveBusinessCase />
        </>
       </Flex>
      </Box>
     </Flex>
    </Box>
   ) : null}
  </Box>
 );
};

export default Summary;
