import { FC, useEffect } from "react";
import { useAppDispatch } from "hooks";
import { LineLoading } from "components/Loading";
import {
 Box,
 Link,
 Image,
 Flex,
 Menu,
 MenuButton,
 MenuList,
 MenuItem,
} from "@chakra-ui/react";
import Navbar from "components/Navbar";
import { useSelector, shallowEqual } from "react-redux";
import menu from "constants/menu";
import { RootState } from "state/store";
import { actions as AuthActions } from "state/auth/slice";
import { actions as SettingActions } from "state/settings/slice";
import Logo from "assets/images/logo-optipedia.png";
import Hamburger from "assets/images/hamburger.png";
import Avatar from "assets/images/avatar.png";

const Sidebar: FC = () => {
 const dispatch = useAppDispatch();
 const { profile, loading, isSidebarOpen } = useSelector(
  (state: RootState) => ({
   profile: state.auth.result,
   loading: state.auth.loading,
   isSidebarOpen: state.thirdPartySetting.sidebarOpen,
   navbarOpen: state.thirdPartySetting.navbarOpen,
  }),
  shallowEqual
 );

 useEffect(() => {
  dispatch(AuthActions.getUserInfo());
 }, [dispatch]);

 const clickHamburger = () => {
  dispatch(SettingActions.setSidebarOpen(!isSidebarOpen));
  dispatch(SettingActions.setNavbarOpen(false));
 };

 return (
  <Box
   as="aside"
   bgColor="#F7F9FD"
   position="fixed"
   h="full"
   w={isSidebarOpen ? "250px" : "85px"}
   transition="width 0.5s ease-in-out"
   overflow="auto"
   display="flex"
   flexDirection="column"
   filter={{ dropShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
   p="5"
   zIndex={9}
   sx={{
    "&::-webkit-scrollbar": {
     display: "none",
    },
   }}
  >
   <Flex mb="40px" mt="10px" gap={2}>
    <Image
     src={Hamburger}
     alt="logo"
     m="auto"
     maxW="full"
     cursor="pointer"
     onClick={() => clickHamburger()}
     marginLeft="1rem"
    />
    {isSidebarOpen && (
     <Link href="/pricings" _hover={{ textTransform: "none" }}>
      <Image src={Logo} alt="logo" m="auto" w="80%" maxW="full" />
     </Link>
    )}
   </Flex>
   <Navbar menu={menu} />
   <Box
    flex="1"
    display="flex"
    alignItems="flex-end"
    marginTop="10"
    marginLeft={isSidebarOpen ? "0" : "10px"}
   >
    <Link
     display="flex"
     alignItems="center"
     _hover={{
      textDecoration: "none",
     }}
    >
     <Image src={Avatar} alt="Avatar" width="32px" height="32px" />
     {isSidebarOpen && (
      <Menu>
       {() => (
        <>
         <MenuButton
          bgColor="transparent !important"
          color="#9DA1A7"
          ml="1rem"
          padding="0"
          textDecoration="none"
          _focus={{
           boxShadow: "none",
          }}
         >
          {loading && <LineLoading />}
          {!loading && `${profile?.tenantName} - ${profile?.name}`}
         </MenuButton>
         <MenuList>
          <MenuItem onClick={() => (window.location.href = "/logout")}>
           Logout
          </MenuItem>
         </MenuList>
        </>
       )}
      </Menu>
     )}
    </Link>
   </Box>
  </Box>
 );
};

export default Sidebar;
