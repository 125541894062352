import { createSlice } from "@reduxjs/toolkit";
import { replaceCostAnalysisVolume, copyCostAnalysisVolume } from "state/pricings/copyAndReplace/thunk";

interface InitialState {
 isReplace: boolean;
 isCopy: boolean;
 isReplaceOpen: boolean;
 loading: boolean;
 error: any;
}

const initialState: InitialState = {
 isReplace: false,
 isCopy: false,
 isReplaceOpen: false,
 loading: false,
 error: null,
};

const copyAndReplacePricingCostDriverSlice = createSlice({
 name: "editPricingCostDrivers",
 initialState,
 reducers: {
  clear: () => initialState,
  setIsReplaceOpen: (state, action) => {
   state.isReplaceOpen = action.payload;
  },
  setIsReplace: (state, action) => {
   state.isReplace = action.payload;
  },
  setIsCopy: (state, action) => {
   state.isCopy = action.payload;
  },
 },
 extraReducers: (builder) => {
  builder.addCase(replaceCostAnalysisVolume.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(replaceCostAnalysisVolume.fulfilled, (state, action) => {
   state.loading = false;
  });

  builder.addCase(replaceCostAnalysisVolume.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  builder.addCase(copyCostAnalysisVolume.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(copyCostAnalysisVolume.fulfilled, (state, action) => {
   state.loading = false;
  });

  builder.addCase(copyCostAnalysisVolume.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
 },
});

export const actions = {
 ...copyAndReplacePricingCostDriverSlice.actions,
 replaceCostAnalysisVolume,
 copyCostAnalysisVolume
};

export const reducer = copyAndReplacePricingCostDriverSlice.reducer;
