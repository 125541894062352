import { ReactElement, useMemo, useState, useEffect } from "react";
import { removeThousandSeparator } from "utils";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";
import debounce from "lodash/debounce";
import { Button } from "@chakra-ui/react";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import { isEmpty } from "lodash";

const Recalculate = ({ convertName, row, costDriver }: any): ReactElement => {
 const dispatch = useAppDispatch();
 const [newRow, setNewRow] = useState<any>(row);
 const {
  loading,
  dataToSendUpdates,
  detailPerVolumeId,
  changeRowSimulate,
  dataToSimulates,
 } = useSelector(
  (state: RootState) => ({
   loading: state.editPricingCostDrivers.loading,
   dataToSendUpdates: state.editPricingCostDrivers.dataToSend.updates,
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId.result,
   changeRowSimulate: state.editPricingCostDrivers.tmpChangeRow,
   dataToSimulates: state.editPricingCostDrivers.dataToSimulate,
  }),
  shallowEqual
 );

 const runSimulate = useMemo(
  () =>
   debounce((dataToUpdate) => {
    const newCustomFieldDataToUpdate: any = [];
    dataToUpdate?.customFields?.map((customField: any) => {
     if (!customField.isMaintenanceByMasterData) {
      newCustomFieldDataToUpdate.push(customField);
     }
    });
    const newDataToUpdate = {
     ...dataToUpdate,
     customFields: newCustomFieldDataToUpdate,
    };
    dispatch(pricingCostDriverAction.resetDataSimulate([]));
    dispatch(
     pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(
      newDataToUpdate
     )
    );
   }, 600),
  [dispatch, dataToSendUpdates]
 );

 const debouncedRunSimulate = useMemo(
  () =>
   debounce(async (dataOnChange: any) => {
    dataOnChange.map((item: any) => {
     const {
      name,
      value,
      currentRow1,
      tableName,
      dataFromSimulate,
      unitCostLabel,
     } = item;
     let isNewCostDriver: any;
     if (dataFromSimulate || currentRow1) {
      isNewCostDriver = dataFromSimulate?.isNew
       ? dataFromSimulate
       : currentRow1;
     } else {
      isNewCostDriver = row;
     }
     const newCustomField: any = [];
     let itemToObject = Object.assign({}, isNewCostDriver);
     const originalNewMap = new Map(Object.entries(itemToObject));
     const findCost = changeRowSimulate.find(
      (item: any) =>
       item.name === unitCostLabel &&
       item.componentName === dataFromSimulate?.name &&
       item.idRow === dataFromSimulate.id
     );
     if (
      (row.isNew && 0 === value) ||
      removeThousandSeparator(originalNewMap.get(name) as string) === value
     ) {
      const tmpChangeToDeletes = {
       component: `${originalNewMap.get("name")} - ${
        name === "unitCost" ? unitCostLabel : name
       }`,
       name: convertName,
       constDriverId: originalNewMap.get("constDriverId"),
      };
      dispatch(
       pricingCostDriverAction.removeOnetmpChangeRow({ tmpChangeToDeletes })
      );

      let indexCustomField = null;
      const customFields: any = [];
      dataFromSimulate?.customFields?.map((item: any, index: number) => {
       if (item.name === name) {
        indexCustomField = index;
        customFields.push({
         ...item,
         value,
        });
       } else {
        customFields.push(item);
       }
      });
      const dataToUpdate = {
       costAnalysisPerVolumeId: detailPerVolumeId?.id,
       costDriverId: isNewCostDriver.id,
       currencyId: isNewCostDriver.currencyId,
       parentCostDriverId: isNewCostDriver.constDriverId,
       name: isNewCostDriver.name,
       masterDataId: isNewCostDriver.masterDataId,
       description: isNewCostDriver.description,
       unitCost:
        convertName === "unitCost"
         ? value
         : findCost?.before ?? itemToObject?.unitCost,
       customFields: customFields,
      };

      runSimulate(dataToUpdate);
     } else {
      debugger;
      let indexCustomField = null;
      let isCustomFieldAllHaveValue = true;
      newCustomField.length > 0 &&
       newCustomField.map((item: any) => {
        if (item.masterData !== null && item.value === "") {
         isCustomFieldAllHaveValue = false;
        }
       });
      const customFields: any = [];
      isNewCostDriver?.customFields.map(
       (item: any, indexCustomFieldNumber: number) => {
        if (item.name === name) {
         indexCustomField = indexCustomFieldNumber;
         if (changeRowSimulate.length > 0) {
          for (let i = 0; i < changeRowSimulate.length; i++) {
           if (
            item.customFieldConfigurationId ===
             changeRowSimulate[i]?.customFieldConfigurationId &&
            item.costDriverId === changeRowSimulate[i]?.idRow &&
            item.name === changeRowSimulate[i]?.name
           ) {
            customFields.push({
             ...item,
             value,
            });
           } else {
            if (
             item?.name === name &&
             item?.costDriverName === currentRow1?.name
            ) {
             customFields.push({
              ...item,
              value,
             });
            } else {
             const sameValue = changeRowSimulate.find(
              (data: any) =>
               data?.idRow === item?.costDriverId &&
               data?.customFieldConfigurationId ===
                item?.customFieldConfigurationId &&
               item?.name === data.name
             );
             if (sameValue) {
              customFields.push({
               ...item,
               value: sameValue?.before,
              });
             } else {
              customFields.push(item);
             }
            }
           }
          }
         } else {
          customFields.push({
           ...item,
           value,
          });
         }
        } else {
         for (let i = 0; i < changeRowSimulate.length; i++) {
          if (
           item?.customFieldConfigurationId ===
            changeRowSimulate[i]?.customFieldConfigurationId &&
           item?.costDriverName === changeRowSimulate[i]?.componentName &&
           item?.name === changeRowSimulate[i]?.name
          ) {
           customFields.push({
            ...item,
            value: changeRowSimulate[i]?.before,
           });
          } else {
           if (item?.name === name && item?.costDriverName === row?.name) {
            customFields.push({
             ...item,
             value,
            });
           } else {
            const sameValue = changeRowSimulate.find(
             (data: any) =>
              data?.idRow === item?.costDriverId &&
              data?.customFieldConfigurationId ===
               item?.customFieldConfigurationId &&
              item?.name === data?.name
            );
            if (sameValue) {
             customFields.push({
              ...item,
              value: sameValue?.before,
             });
            } else {
             customFields.push(item);
            }
           }
          }
         }
        }
       }
      );

      const customFieldsFromSimulate: any = [];

      row?.customFields.map((customField: any) => {
       if (customField?.name === name) {
        customFieldsFromSimulate.push({
         ...customField,
         value,
        });
       } else {
        if (row[customField?.name] || !customField?.isRequired) {
         if (
          name === `${customField?.name}-currencyId` &&
          customField?.typeData === 50
         ) {
          customFieldsFromSimulate.push({
           ...customField,
           currencyId: value,
          });
         } else {
          customFieldsFromSimulate.push({
           ...customField,
           value: row[customField.name],
          });
         }
        }
       }
      });
      const dataSendEdit = customFieldsFromSimulate?.map((o: any) => o.name);
      const filteredCustomFields = customFieldsFromSimulate?.filter(
       ({ name, isMaintenanceByMasterData }: any, index: number) =>
        !isMaintenanceByMasterData && !dataSendEdit.includes(name, index + 1)
      );
      const valueName = dataToSimulates.filter((i: any) => i.name === "name");
      const valueDesc = dataToSimulates.filter(
       (i: any) => i.name === "description"
      );
      const dataToUpdate = {
       costAnalysisPerVolumeId: detailPerVolumeId?.id,
       costDriverId: isNewCostDriver
        ? isNewCostDriver?.id
        : dataFromSimulate?.id,
       parentCostDriverId: isNewCostDriver
        ? isNewCostDriver?.constDriverId
        : dataFromSimulate?.constDriverId,
       name:
        valueName.length > 0
         ? valueName[valueName.length - 1].value
         : dataFromSimulate.name,
       description:
        valueDesc.length > 0
         ? valueDesc[valueDesc.length - 1].value
         : dataFromSimulate.description,
       masterDataId: dataFromSimulate.masterDataConfiguration?.id,
       unitCost: name === "unitCost" ? value : dataFromSimulate?.unitCost,
       customFields: filteredCustomFields,
       remove: row?.isNew && 0 === value,
       currencyId: dataFromSimulate.currencyId,
      };

      runSimulate(dataToUpdate);

      let itemNewOriginal;
      if (isNewCostDriver) {
       itemNewOriginal = new Map(Object.entries(isNewCostDriver));
       let after = isNewCostDriver?.customFields.find(
        (item: any) => item?.name === convertName
       );

       if (
        (after && removeThousandSeparator(after?.value) !== value) ||
        (convertName === "unitCost" &&
         removeThousandSeparator(isNewCostDriver?.unitCost) !== value)
       ) {
        const findCust = customFields?.find(
         (item: any) =>
          item?.name === name && item?.costDriverId === isNewCostDriver?.id
        );
        dispatch(
         pricingCostDriverAction.settmpChangeRow({
          id: convertName,
          costDriver: tableName,
          component: `${dataFromSimulate.name} - ${
           name === "unitCost" ? unitCostLabel : name
          }`,
          before: value,
          after: isNewCostDriver.isNew
           ? 0
           : after
           ? after.value
           : itemNewOriginal.get("unitCost"),
          idRow: findCust ? findCust?.costDriverId : row?.id,
          customFieldConfigurationId: after
           ? after?.customFieldConfigurationId
           : costDriver.costDriverId,
          parentCostDriverId: findCust
           ? findCust?.id
           : costDriver?.costDriverId,
          componentName: originalNewMap.get("name"),
          name: name === "unitCost" ? unitCostLabel : name,
         })
        );
       }
      } else {
       itemNewOriginal = new Map(Object.entries(dataFromSimulate));
       let after = dataFromSimulate?.customFields?.find(
        (item: any) => item?.name === convertName
       );

       if (
        (after && removeThousandSeparator(after.value) !== value) ||
        (convertName === "unitCost" &&
         removeThousandSeparator(dataFromSimulate?.unitCost) !== value)
       ) {
        const findCust = customFields.find(
         (item: any) =>
          item?.name === name && item?.costDriverId === dataFromSimulate?.id
        );
        dispatch(
         pricingCostDriverAction.settmpChangeRow({
          id: convertName,
          costDriver: tableName,
          component: `${originalNewMap.get("name")} - ${
           name === "unitCost" ? unitCostLabel : name
          }`,
          before: value,
          after: dataFromSimulate?.isNew
           ? 0
           : after
           ? after.value
           : itemNewOriginal.get("unitCost"),
          idRow: row.id,
          customFieldConfigurationId: findCust?.customFieldConfigurationId,
          parentCostDriverId: costDriver?.costDriverId,
          componentName: originalNewMap.get("name"),
          name: name === "unitCost" ? unitCostLabel : name,
         })
        );
       }
      }
     }
    });
   }, 800),
  [dataToSendUpdates, row]
 );
 useEffect(() => {
  setNewRow(row);
 }, [row]);

 useEffect(() => {
  return () => {
   debouncedRunSimulate.cancel();
  };
 }, []);

 const findIndexCustomField = () => {
  return row.customFields.findIndex(
   (customField: any) => customField.isRequired
  );
 };
 return (
  <Button
   variant={"outline"}
   isDisabled={
    loading ||
    (newRow?.isNew && isEmpty(newRow.name)) ||
    (newRow?.isNew && isEmpty(newRow.description)) ||
    (newRow.customFields.length > 0 &&
     isEmpty(newRow.customFields[findIndexCustomField()]?.value))
   }
   ml={3}
   onClick={() => {
    debouncedRunSimulate(dataToSimulates);
   }}
  >
   Recalculate
  </Button>
 );
};
export default Recalculate;
