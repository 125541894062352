import { createSlice } from "@reduxjs/toolkit";
import { getDetailCostDriversVolume } from "state/pricings/costDrivers/thunk";
import {
  updateAsNewVersion,
  simulate,
  historyReport,
  simulationReport,
} from "state/pricings/costAnalysis/thunk";
import { orderBy } from "lodash";

interface InitialState {
  costAnalysisPerVolumeId: string;
  detailPerVolumeId: any;
  responseSimulate: any;
  volumeId: string;
  thirdPartyId: string;
  isEditing: boolean;
  isSimulate: boolean;
  loadingHistoryReport: boolean;
  valueIsIncludeCalculation: boolean;
  levelName: string;
  pricingId: string;
  loading: boolean;
  loadingReport: boolean;
  error: unknown;
  temporaryDataCostDriver: any;
  temporaryDataCustomFields: any;
  dataToSend: any;
  constDrivers: any;
  originalCostDrivers: any;
  constDriversOri: any;
  tmpChangeRow: any;
  needReFetch: boolean;
  prevPage: string;
  resetForm: any;
  isDataDuplicate: boolean;
  stateTmpId: string;
  isAddNew: boolean;
  temporaryData: any;
  indexCostDriversSimulateBySku: string[];
  oldValue: boolean;
  status: number;
  emptyCostDrivers: any;
  isHaveChangeData: boolean;
  isEditOpen: boolean;
  isErrorDuplicate: boolean;
  customFields: any;
  totalRequest: number;
  objectsIndexToDelete: any;
  scrollToId: string;
  haveEmptyName: boolean;
  dataToSimulate: any[];
}

const initialState: InitialState = {
  costAnalysisPerVolumeId: "",
  detailPerVolumeId: {},
  responseSimulate: {},
  volumeId: "",
  thirdPartyId: "",
  levelName: "",
  pricingId: "",
  loading: false,
  loadingReport: false,
  error: null,
  isEditing: false,
  isSimulate: false,
  loadingHistoryReport: false,
  valueIsIncludeCalculation: false,
  temporaryDataCostDriver: [],
  temporaryDataCustomFields: [],
  resetForm: () => { },
  dataToSend: {
    costAnalysisPerVolumeId: "",
    name: "",
    description: "",
    uoMCode: "",
    currencyCode: "",
    formatCode: "",
    adds: [],
    updates: [],
    deletes: [],
    customFields: [],
  },
  constDrivers: {},
  originalCostDrivers: [],
  constDriversOri: {},
  tmpChangeRow: [],
  needReFetch: false,
  prevPage: "",
  isDataDuplicate: false,
  stateTmpId: "",
  isAddNew: false,
  temporaryData: null,
  indexCostDriversSimulateBySku: [],
  oldValue: false,
  status: 0,
  emptyCostDrivers: [],
  isHaveChangeData: false,
  isEditOpen: false,
  isErrorDuplicate: false,
  customFields: [],
  totalRequest: 0,
  objectsIndexToDelete: {
    index1: null,
    index2: null,
  },
  scrollToId: "",
  haveEmptyName: false,
  dataToSimulate: []
};
function removeItem(array: any, item: string) {
  for (var i in array) {
   if (array[i] == item) {
    array.splice(i, 1);
    break;
   }
  }
 }
const pricingCostDriverSlice = createSlice({
  name: "pricingCostDrivers",
  initialState,
  reducers: {
    clear: () => initialState,
    addDataToSimulate:(state, action) => {
      const findData = orderBy(state.dataToSimulate, ['value'], ['desc']).find((item: any) => item.name === action.payload.name)
      if(findData){
        removeItem(state.dataToSimulate, action.payload);
      }
      state.dataToSimulate.push(action.payload)
    },
    resetDataSimulate: (state, action) => {
      state.dataToSimulate = action.payload
    },
    removeDataToSimulate: (state, action) => {
      removeItem(state.dataToSimulate, action.payload);
    },
    setOldValue: (state, action) => {
      state.oldValue = action.payload;
    },
    setHaveEmptyName: (state, action) => {
      state.haveEmptyName = action.payload;
    },
    setScrollToId: (state, action) => {
      state.scrollToId = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setIsEditOpen: (state, action) => {
      state.isEditOpen = action.payload;
    },
    setValueIsIncludeCalculation: (state, action) => {
      state.valueIsIncludeCalculation = action.payload;
    },
    setTemporaryData: (state, action) => {
      state.temporaryData = action.payload;
    },
    setIndexCostDriversSimulateBySku: (state, action) => {
      state.indexCostDriversSimulateBySku = action.payload;
    },
    setCustomFields: (state, action) => {
      state.customFields.push(action.payload);
    },

    setCustomField: (state, action) => {
      state.customFields.map((item: any, index: number) => {
        if (item.name === action.payload.name) {
          state.customFields[index].value = action.payload.value
        } else {
          state.customFields.push(action.payload);
        }
      })

    },

    setChangeCustomFields: (state, action) => {
      state.customFields[action.payload.findSame].value = action.payload.value;
    },

    setNullCustomFields: (state) => {
      state.customFields = [];
    },
    resetTmpChangeRow: (state) => {
      state.dataToSend = {
        costAnalysisPerVolumeId: "",
        name: "",
        description: "",
        uoMCode: "",
        currencyCode: "",
        formatCode: "",
        adds: [],
        updates: [],
        deletes: [],
        customFields: [],
      };
      state.tmpChangeRow = [];
    },

    setAddNew: (state, action) => {
      state.isAddNew = action.payload;
    },
    setIsErrorDuplicate: (state, action) => {
      state.isErrorDuplicate = action.payload;
    },
    setErrorSendDataDuplicate: (state, action) => {
      state.isDataDuplicate = action.payload;
    },
    setStateTmpId: (state, action) => {
      state.stateTmpId = action.payload;
    },
    setResetForm: (state, action) => {
      state.resetForm = action.payload;
    },
    setPrevPage: (state, action) => {
      state.prevPage = action.payload;
    },
    setVolumeId: (state, action) => {
      state.volumeId = action.payload;
    },
    setThirdPartyId: (state, action) => {
      state.thirdPartyId = action.payload;
    },
    setNeedReFetch: (state, action) => {
      state.needReFetch = action.payload;
    },
    //
    setLevelName: (state, action) => {
      state.levelName = action.payload;
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setIsSimulate: (state, action) => {
      state.isSimulate = action.payload;
    },
    setConstDrivers: (state, action) => {
      state.constDrivers = action.payload;
    },
    setConstDriversOri: (state, action) => {
      state.constDriversOri = action.payload;
    },
    setCostAnalysisPerVolumeId: (state, action) => {
      state.costAnalysisPerVolumeId = action.payload;
    },
    setTmpArrayCustomFields: (state, action) => {
      state.temporaryDataCustomFields = action.payload;
    },
    setTempDataCustomFields: (state, action) => {
      if (state.temporaryDataCustomFields.length > 0) {
        state.temporaryDataCustomFields.map((item: any, index: number) => {
          const customFieldId = action.payload.customFieldId;
          if (customFieldId === item.customFieldId) {
            state.temporaryDataCustomFields[index] = action.payload.customField;
          } else {
            state.temporaryDataCustomFields.push(action.payload);
          }
        });
      } else {
        state.temporaryDataCustomFields.push(action.payload);
      }
    },
    deleteTemporaryDataCostDriver: (state, action) => {
      const index1 = action.payload.index1;
      const index2 = action.payload.index2;
      const index3 = action.payload.index3;
      state.temporaryDataCostDriver.map(() => {
        if (index1 !== -1 && index2 !== -1 && index3 !== -1) {
          state.temporaryDataCostDriver[index1].data[index2].data.splice(index3, 1);
        }
      });
    },
    deleteOneTemporaryDataCostDriver: (state, action) => {
      const index1 = action.payload.index1;
      const index2 = action.payload.index2;
      const index3 = action.payload.index3;
      state.temporaryDataCostDriver[index1].data[index2].data.filter((item: any) => item.id !== index3)
    },
    setAddTemporaryDataCostDriver: (state, action) => {
      const findIndex = action.payload.index;
      const temporaryDataCostDriverId = action.payload.temporaryDataCostDriverId;
      let index = state.temporaryDataCostDriver[0]?.data[
        findIndex
      ]?.data.findIndex((el: any) => el.isNew === true);
      let indexAdd = state.dataToSend?.adds?.findIndex(
        (el: any) => el.isNew === true
      );

      if (index !== -1) {
        delete state.temporaryDataCostDriver[0]?.data[findIndex]?.data[index]
          ?.isNew;
        delete state.temporaryDataCostDriver[0]?.data[findIndex]?.data[index]
          ?.tempId;
      }
      if (
        indexAdd !== -1 &&
        state.dataToSend?.adds &&
        state.dataToSend?.adds[indexAdd]
      ) {
        delete state.dataToSend?.adds[indexAdd]?.isNew;
      }
      state.temporaryDataCostDriver[temporaryDataCostDriverId]?.data[
        findIndex
      ]?.data?.push(action.payload);
    },
    replaceUpdate: (state, action) => {
      let update = state.temporaryDataCostDriver[0].data[0].data;
      update = [];
    },
    removeDataWithComma: (state) => {
      state.tmpChangeRow.map((item: any, index: number) => {
        if (item.value !== undefined) {
          state.tmpChangeRow.splice(index, 1);
        }
      });
    },
    removeAddDataToSend: (state, action) => {
      const indexSimulateBySku = action.payload.indexSimulateBySku;
      const findIndex = action.payload.index;
      const indexToDelete = action.payload.indexToDelete;
      const newTemporaryDataCostDriver: any = [];
      state.temporaryDataCostDriver[indexSimulateBySku].data[findIndex].data.map(
        (item: any, index: any) => {
          if (index !== indexToDelete) {
            newTemporaryDataCostDriver.push(item);
          }
        }
      );
      state.temporaryDataCostDriver[indexSimulateBySku].data[findIndex].data.push(
        newTemporaryDataCostDriver
      );
    },
    removeOneTemporaryDataCostDriver: (state, action) => {
      const index = action.payload;
      state.dataToSend.adds.splice(index - 1, 1);
    },

    replaceTemporaryDataCostDriverByFieldName: (state, action) => {
      const findIndex = action.payload.index;
      const fieldName = action.payload.fieldName;
      const indexSimulateBySku = action.payload.indexSimulateBySku;
      const costAnalysisPerVolumeId = action.payload.costAnalysisPerVolumeId;
      const value = action.payload.value;
      const isCustomField = action.payload.changeCustomField;
      const name = action.payload.name;
      action.payload[name] = value;
      if (!isCustomField) {
        let index = state.temporaryDataCostDriver[indexSimulateBySku]?.data[
          findIndex
        ]?.data.findIndex((el: any) => el.isNew === true);
        if (index !== -1 && findIndex !== -1) {
          state.temporaryDataCostDriver[indexSimulateBySku].data[findIndex].data[
            index
          ][fieldName] = value;
        } else {
          // state.temporaryDataCostDriver[0].data[findIndex].data.push(action.payload)
        }
      } else {
        let index = state.temporaryDataCostDriver[indexSimulateBySku]?.data[
          findIndex
        ]?.data.findIndex((el: any) => el.isNew === true);
        if (index === -1) {
          action.payload[name] = value;
          delete action.payload.value;
          delete action.payload.changeCustomField;
          delete action.payload.index;
        } else {
          if (
            state.temporaryDataCostDriver.length > 0 &&
            state.temporaryDataCostDriver[indexSimulateBySku] &&
            state.temporaryDataCostDriver[indexSimulateBySku].data[findIndex]
          ) {
            const findDataCostAnalysisPerVolumeId = state.dataToSend.adds.findIndex(
              (item: any) => item.costAnalysisPerVolumeId === costAnalysisPerVolumeId
            );

            state.temporaryDataCostDriver[indexSimulateBySku].data[findIndex].data[
              index
            ][name] = value;
          }
        }
      }
    },

    setTemporaryDataCostDriver: (state, action) => {
      let index = state.temporaryDataCostDriver.findIndex(
        (el: any) => el.name == action.payload.name
      );

      if (index === -1) {
        state.temporaryDataCostDriver.push(action.payload);
      } else {
        const array1 = state.temporaryDataCostDriver[index];
        const array2 = action.payload;
        let merged = {
          ...array1,
          ...array2,
        };
        state.temporaryDataCostDriver[index] = array2;
      }
    },

    setTemporaryOriginalDataCostDriver: (state, action) => {
      let index = state.originalCostDrivers.findIndex(
        (el: any) => el.name == action.payload.name
      );

      if (index === -1) {
        state.originalCostDrivers.push(action.payload);
      } else {
        const array1 = state.originalCostDrivers[index];
        const array2 = action.payload;
        let merged = {
          ...array1,
          ...array2,
        };
        state.originalCostDrivers[index] = merged;
      }
    },

    replaceTemporaryDataCostDriver: (state, action) => {
      if (action.payload?.fromCostDriver) {
        let index = state.temporaryDataCostDriver.findIndex(
          (el: any) => el.configurationId == action.payload.configurationId
        );
        if (index !== undefined) {
          state.temporaryDataCostDriver[index] = action.payload;
        }
      }
    },
    setEditOneToTemporaryDataCostDriver: (state, action) => {
      const { customFields, isSame, index, i } = action.payload;
      if (isSame) {
        state.dataToSend.updates[index].customFields[i] = customFields[0];
      } else {
        state.dataToSend.updates[index].customFields.push(customFields[0]);
      }
    },
    directSetEditOneToTemporaryDataCostDriver: (state, action) => {
      delete action.payload.index;
      delete action.payload.isSame;
      delete action.payload.i;
      const isRequired = action.payload.customFields.find((item: any) => 
        item.isRequired && item.value === ""
      )
      var itemIndex = state?.dataToSend?.updates?.findIndex((item: any) => {
        return item.costDriverId === action.payload.costDriverId;
      });
      if (state.dataToSend && state.dataToSend.updates) {
        if (itemIndex !== -1) {
          if (action.payload.remove) {
            state?.dataToSend?.updates.splice(itemIndex, 1);
            state.needReFetch = true;
          } else {
            state.dataToSend.updates[itemIndex] = action.payload;
            state.needReFetch = true;
          }
        } else {
          if(isRequired){
            state?.dataToSend?.adds.push(action.payload);
          }
          else {
          state?.dataToSend?.updates.push(action.payload);
          state.needReFetch = true;
        }
        }
      }

      // state.isEditOpen = false;

    },

    directSetAddOneToTemporaryDataCostDriver: (state, action) => {
      delete action.payload.index;
      delete action.payload.isSame;
      delete action.payload.id;
      let index = state.dataToSend.adds?.findIndex(
        (el: any) =>
          el.simulationCostDriverId === action.payload.costDriverId
      );
      let indexAdds = state.dataToSend.adds?.findIndex(
        (el: any) => el.costDriverId === action.payload.costDriverId
      );
      let isSame = state.dataToSend.adds?.find((el: any) => 
        el.simulationCostDriverId === action.payload.simulationCostDriverId && el.name === action.payload.name && el.masterDataId === action.payload.masterDataId
      )

      if (index !== -1 && state.dataToSend.adds && state.dataToSend.adds[index]) {
        state.dataToSend.adds[index] = action.payload;

        state.needReFetch = true;
      } else {
        if (isSame) {
          state.dataToSend.adds[indexAdds] = action.payload
          state.needReFetch = true;
          } else {
            state.dataToSend?.adds?.push(action.payload);
            state.needReFetch = true;
          }
      }
      state.isEditOpen = false;
    },
    setDataToSend: (state, action) => {
      state.dataToSend.adds.push(action.payload);
      state.needReFetch = true;

    },
    deleteAddFromMasterData: (state, action) => {
      const {
        indexToDelete,
        isNew,
        costAnalysisPerVolumeId,
        costDriverId,
        name,
        parentCostDriverId
      } = action.payload;

      const dataToPush = {
        costAnalysisPerVolumeId,
        costDriverId,
        name,
        parentCostDriverId,
      };

      state.dataToSend.deletes.map((data: any, index: number) => {
        // console.log("data", data)
      })

      state.dataToSend.deletes.push(dataToPush);

    },
    directSetDeleteOneToTemporaryDataCostDriver: (state, action) => {
      const {
        costAnalysisPerVolumeId,
        costDriverId,
        name,
        parentCostDriverId,
        tmpChangeToDeletes,
        isHaveDataOrigin,
        isNew,
      } = action.payload;
      const indexSimulateBySku = action.payload.indexSimulateBySku;
      const findIndex = action.payload.index;

      const dataToPush = {
        costAnalysisPerVolumeId,
        costDriverId,
        name,
        parentCostDriverId,
      };

      const itemIndexToAdd: any = [];
      state.dataToSend.adds.find((item: any, itemIndex: number) => {
        if (
          item?.costDriverId === action.payload.parentCostDriverId 
          && item?.masterDataId ? item?.masterDataId === action.payload.masterDataId : item?.name === action.payload.name
        ) {
          itemIndexToAdd.push(itemIndex);
        }
      });

      let itemIndex: any = [];
      state.dataToSend.updates.find((item: any, indexId: number) => {
        if (
          item.parentCostDriverId === action.payload.parentCostDriverId &&
          item.name === action.payload.name
        ) {
          itemIndex.push(indexId);
        }
      });
      const itemIndexToDelete: any = [];
      state.dataToSend.deletes.findIndex((item: any, itemIndex: any) => {
        if (
          item.costDriverId === action.payload.parentCostDriverId &&
          item.name === action.payload.name
        ) {
          itemIndexToDelete.push(itemIndex);
        }
      });

      const itemIndexUpdateToDelete = state.temporaryDataCostDriver[
        indexSimulateBySku
      ].data[findIndex].data.findIndex((item: any) => {
        return (
          item.constDriverId === action.payload.parentCostDriverId &&
          item.name === action.payload.name
        );
      });

      state.temporaryDataCostDriver[indexSimulateBySku].data[
        findIndex
      ].data.splice(
        isNew ? action.payload.indexToDelete : itemIndexUpdateToDelete,
        1
      );

      const arry: any = []

      state.tmpChangeRow.map((item: any, index: number) => {
        for (let i = 0; i < tmpChangeToDeletes.length; i++) {
          if (
            tmpChangeToDeletes[i].component === item.component
          ) {
            arry.push(index);
          }
        }
      });

      for (var i = arry.length - 1; i >= 0; i--) {
        state.tmpChangeRow.splice(arry[i], 1);
      }

      if (itemIndexToAdd.length > 0) {
        state.dataToSend.adds.splice(itemIndexToAdd, 1);
      }

      itemIndex.map((number: number) => {
        state.dataToSend.updates.splice(number, 1);
      });

      if (itemIndexToDelete.length > 0) {
        state.dataToSend.deletes.splice(itemIndexToDelete, 1);
      }

      if (isHaveDataOrigin) {
        state.dataToSend.deletes.push(dataToPush);
      }

      state.needReFetch = true;
    },
    directDeleteTemporaryDataCostDriver: (state, action) => {
      const { tmpChangeToDeletes } = action.payload;
      state.dataToSend.updates.find((item: any, index: number) => {
        if (
          item.costAnalysisPerVolumeId === action.payload.costAnalysisPerVolumeId &&
          item.costDriverId === action.payload.id &&
          item.name === action.payload.name
        ) {
          state.dataToSend.updates.splice(index, 1);
        }
      });

      if (tmpChangeToDeletes) {
        const arry: any = [];
        state.tmpChangeRow.map((item: any, index: number) => {
          for (let i = 0; i < tmpChangeToDeletes.length; i++) {
            if (
              (tmpChangeToDeletes[i].component === item.component &&
                tmpChangeToDeletes[i].name === item.id &&
                tmpChangeToDeletes[i].value === item.after) ||
              tmpChangeToDeletes[i].customFieldConfigurationId ===
              action.payload.customFieldConfigurationId ||
              tmpChangeToDeletes[i].idRow === action.payload.id
            ) {
              arry.push(index);
            }
          }
        });
        if (Array.isArray(arry) && arry.length > 0) {
          for (var i = arry.length - 1; i >= 0; i--) {
            state.tmpChangeRow.splice(arry[i], 1);
          }
        }
        state.tmpChangeRow.map((item: any, index: number) => {
          if (
            (tmpChangeToDeletes[index].component === item.component &&
              tmpChangeToDeletes[index].name === item.id &&
              item.after === undefined) ||
            (tmpChangeToDeletes[index].component === item.component &&
              tmpChangeToDeletes[index].name === item.id &&
              item.costDriverId === undefined)
          ) {
            state.tmpChangeRow.splice(index, 1);
          }
        });
        state.tmpChangeRow.map((item: any, index: number) => {
          if (item.idRow === action.payload.id) {
            state.tmpChangeRow.splice(index, 1);
          }
        });
      }
    },
    directSetNullTemporaryDataCostDriver: (state, action) => {
      state.dataToSend.adds.map((item: any, index: number) => {
        if (item.simulationCostDriverId == action.payload) {
          state.dataToSend.adds.splice(index, 1);
        }
      });

      state.dataToSend.updates.map(
        (item: any, itemIndexUpdateToBeRemove: number) => {
          if (item.costDriverId == action.payload) {
            state.dataToSend.updates.splice(itemIndexUpdateToBeRemove, 1);
          }
        }
      );
    },
    directSetNullTemporaryAddDataCostDriver: (state) => {
      state.dataToSend.adds = [];
    },
    setDirectSetTemporaryDataCostDriver: (state, action) => {
      if (action.payload) {
        const customFieldsUpdate: any = [];
        action.payload?.customFields?.map((item: any, index: number) => {
          const { customFieldId, name, customFieldConfigurationId } = item;
          customFieldsUpdate.push({
            customFieldId,
            value: name,
            customFieldConfigurationId
          });
        });
        const data = {
          costAnalysisPerVolumeId: action.payload?.costAnalysisPerVolumeId,
          name: action.payload?.name,
          description: action.payload?.description,
          uoMCode: action.payload?.uoMCode,
          currencyCode: action.payload?.currencyCode,
          formatCode: action.payload?.formatCode,
          customFields: customFieldsUpdate,
          adds: [],
          updates: action.payload?.updates || [],
          deletes: action.payload?.deletes || [],
        };
        state.dataToSend = data;
      }
    },
    setOneCustomField: (state, action) => {
      let itemIndex = state.dataToSend.customFields.findIndex((item: any) => {
        return item.customFieldId == action.payload.customFieldId;
      });
      if (itemIndex >= 0) {
        state.dataToSend.customFields[itemIndex] = action.payload;
      } else {
        state.dataToSend.customFields.push(action.payload);
      }
    },
    changeTmpChangeRowIsNotCustomField: (state, action) => {
      let itemIndex = state.tmpChangeRow.findIndex((item: any) => {
        return (
          item.component === action.payload.component &&
          item.parentCostDriverId === action.payload.parentCostDriverId
        );
      });
      if (
        state?.tmpChangeRow[itemIndex]?.before ||
        state?.tmpChangeRow[itemIndex + 1]?.before
      ) {
        state.tmpChangeRow[itemIndex].before = action.payload.value;
      } else {
        state.tmpChangeRow.push(action.payload);
      }
    },

    changeTmpChangeRowComponentByIndex: (state, action) => {
      const { index, component, before, name, componentName, idRow, currencyTo } = action.payload;
      let getComponentName = componentName
      if (componentName === "") {
        const numberBeforeHappened = component.split('-')[0].trim();
        getComponentName = numberBeforeHappened
      }
      if(componentName){
        state.tmpChangeRow[index].component = component
      state.tmpChangeRow[index].componentName = getComponentName
      state.tmpChangeRow[index].idRow = idRow
      }
      if (name === undefined) {
        state.tmpChangeRow[index].currencyTo = currencyTo
      }
      if (name === state.tmpChangeRow[index].name) {
        state.tmpChangeRow[index].before = before
        state.tmpChangeRow[index].currencyTo = currencyTo
      }
      if (state.tmpChangeRow[index].name === "currencyId" && currencyTo) {
        state.tmpChangeRow[index].currencyTo = currencyTo
      }

    },

    changeBeforeChangeRow: (state, action) => {
      let itemIndex = state.tmpChangeRow.findIndex((item: any) => {
        return (
          item.parentCostDriverId === action.payload.parentCostDriverId &&
          item.idRow === action.payload.idRow
        );
      });
      if (itemIndex >= 0 && state.tmpChangeRow[itemIndex].before !== action.payload.before) {
        state.tmpChangeRow[itemIndex].before = action.payload.before;
        state.tmpChangeRow[itemIndex].component = action.payload.component;
      }
    },

    changeTmpChangeRow: (state, action) => {
      let itemIndex = state.tmpChangeRow.findIndex((item: any) => {
        return (
          item.component === action.payload.component &&
          item.customFieldConfigurationId ===
          action.payload.customFieldConfigurationId &&
          item.parentCostDriverId === action.payload.parentCostDriverId
        );
      });
      if (itemIndex >= 0 && action.payload.value) {
        state.tmpChangeRow[itemIndex].before = action.payload.value;
      }
    },

    settmpChangeRow: (state, action) => {
      let itemIndex = state.tmpChangeRow.findIndex((item: any) => {
        return (
          item.component === action.payload.component &&
          item.costDriver === action.payload.costDriver &&
          item.parentCostDriverId === action.payload.parentCostDriverId
        );
      });
      if (itemIndex >= 0) {
        state.tmpChangeRow[itemIndex] = action.payload;
      } else {
        if (action.payload.before) {
          state.tmpChangeRow.push(action.payload);
        }
      }
    },
    removeLatesttmpChangeRow: (state) => {
      const findIndexId = state.tmpChangeRow.findIndex(
        (item: any) => item.tmpId !== null
      );
      state.tmpChangeRow.splice(findIndexId, 1);
    },

    deleteOneTmpChangeRow: (state, action) => {
      state.tmpChangeRow = state.tmpChangeRow.filter(({ id }: any) => !action.payload.includes(id));
      // const findIndex = state.tmpChangeRow.indexOf(action.payload)
      // state.tmpChangeRow.splice(findIndex, 1)
    },

    setPerVolumeId: (state, action) => {
      state.dataToSend = action.payload;
    },

    removeOneLogistic: (state, action) => {
      let indexAdd = state.dataToSend.adds.findIndex(
        (el: any) => el.tmpId === action.payload
      );
      state.dataToSend.adds.splice(indexAdd, 1);
    },

    removeOnetmpChangeRow: (state, action) => {
      const { tmpChangeToDeletes } = action.payload;
      state.tmpChangeRow.map((item: any, index: number) => {
        if (
          tmpChangeToDeletes.component === item.component &&
          tmpChangeToDeletes.name === item.id &&
          tmpChangeToDeletes.constDriverId === item.parentCostDriverId
          ||
          tmpChangeToDeletes.component === item.component &&
          tmpChangeToDeletes.name === item.id &&
          tmpChangeToDeletes.constDriverId === item.idRow
        ) {
          state.tmpChangeRow.splice(index, 1);
        }
      });
    },

    removeTmpChangeRowByIndex: (state, action) => {
      const index = action.payload;
      state.tmpChangeRow.splice(index);
      // state.tmpChangeRow = state.tmpChangeRow.filter((_:any, i: number) => i !== index);
    },

    needToRemoveTmpChangeRowByIndex: (state, action) => {
      const { idRow, constDriverId } = action.payload;

      state.tmpChangeRow.map((item: any, index:number) => {
        // console.log("idRow, constDriverId", idRow, constDriverId)
        // console.log("item", item)
        if(idRow === item.idRow){
          state.tmpChangeRow.splice(index, 1);
        }

      })
      // state.tmpChangeRow.splice();
      // state.tmpChangeRow = state.tmpChangeRow.filter((data: any, i: number) => data?.idRow === idRow);
    },

    setBackTemporaryDataCostDriverIfError: (state, action) => {
      const temporaryData = action.payload.temporaryData;
      const indexCostDriversSimulateBySku =
        action.payload.indexCostDriversSimulateBySku;
      const indexDataAdd = action.payload.indexDataAdd;
      const indexDataDelete = action.payload.indexDataDelete;
      const indexDataUpdate = action.payload.indexDataUpdate;
      const errorSendDataDuplicate = action.payload.errorSendDataDuplicate;
      state.dataToSend.adds.splice(indexDataAdd, 1);
      state.dataToSend.deletes.splice(indexDataDelete, 1);
      state.dataToSend.updates.splice(indexDataUpdate, 1);
      if (errorSendDataDuplicate) {
        state.temporaryDataCostDriver[indexCostDriversSimulateBySku[0]].data[
          indexCostDriversSimulateBySku[1]
        ].data.splice(indexCostDriversSimulateBySku[2], 1);
      } else {
        state.temporaryDataCostDriver[indexCostDriversSimulateBySku[0]].data[
          indexCostDriversSimulateBySku[1]
        ].data.push(temporaryData);
      }
    },

    setRemoveIfError: (state, action) => {
      const indexCostDriversSimulateBySku =
        action.payload.indexCostDriversSimulateBySku;
      const indexDataAdd = action.payload.indexDataAdd;
      const indexTmpChangeRow = action.payload.indexTmpChangeRow;
      state.dataToSend.adds.splice(indexDataAdd, 1);

      if (!action.payload.idNotRemove) {
        state.temporaryDataCostDriver[indexCostDriversSimulateBySku[0]].data[
          indexCostDriversSimulateBySku[1]
        ].data.splice(indexCostDriversSimulateBySku[2], 1);
      }

      state.tmpChangeRow.splice(indexTmpChangeRow, 1);
    },

    replaceUpdateTemporaryCostDriver: (state, action) => {
      const arrayIndexs = action.payload.arrayIndexs;
      const value = action.payload.value;
      const customFieldId = action.payload.customFieldId;
      const index = state.temporaryDataCostDriver[arrayIndexs[0]].data[
        arrayIndexs[1]
      ].data[arrayIndexs[2]]?.customFields.findIndex((item: any) => {
        return item.id === customFieldId;
      });
      state.temporaryDataCostDriver[arrayIndexs[0]].data[arrayIndexs[1]].data[
        arrayIndexs[2]
      ].customFields[index].value = value;
    },
    setDataEmptyCostDrivers: (state, action) => {
      let findIndex = state.emptyCostDrivers?.findIndex(
        (el: any) => el.id === action.payload.id
      );

      if (findIndex === -1) {
        state.emptyCostDrivers.push(action.payload);
      }
    },
    setObjectsIndexToDelete: (state, actions) => {
      state.objectsIndexToDelete = actions.payload;
    },
    setChangeAddRowByIndex: (state, action) => {
      const index = action.payload.index;
      const payload = action.payload.data;
      // disini
      state.dataToSend.adds[index].customFields = payload;
    },
    changeValueUpdates: (state, action) => {
      const { costDriverId } = action.payload;
      state.dataToSend.updates.find((item: any, index: number) => {
        if (item.costDriverId === costDriverId) {
          state.dataToSend.updates.splice(index, 1);
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDetailCostDriversVolume.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getDetailCostDriversVolume.fulfilled, (state, action) => {
      state.detailPerVolumeId = action.payload;
      state.responseSimulate = action.payload;
      state.loading = false;
    });

    builder.addCase(getDetailCostDriversVolume.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(updateAsNewVersion.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(updateAsNewVersion.fulfilled, (state, action) => {
      state.detailPerVolumeId = action.payload;
      state.responseSimulate = action.payload;
      state.loading = false;
    });

    builder.addCase(updateAsNewVersion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(simulate.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(simulate.fulfilled, (state, action) => {
      state.detailPerVolumeId = action.payload;
      state.loading = false;
    });

    builder.addCase(simulate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(historyReport.pending, (state) => {
      state.loadingHistoryReport = true;
      state.error = null;
    });

    builder.addCase(historyReport.fulfilled, (state, action) => {
      state.loadingHistoryReport = false;
    });

    builder.addCase(historyReport.rejected, (state, action) => {
      state.loadingHistoryReport = false;
      state.error = action.payload;
    });

    builder.addCase(simulationReport.pending, (state) => {
      state.loadingReport = true;
      state.error = null;
    });

    builder.addCase(simulationReport.fulfilled, (state, action) => {
      state.loadingReport = false;
    });

    builder.addCase(simulationReport.rejected, (state, action) => {
      state.loadingReport = false;
      state.error = action.payload;
    });
  },
});

export const actions = {
  ...pricingCostDriverSlice.actions,
  getDetailCostDriversVolume,
  updateAsNewVersion,
  simulate,
  historyReport,
  simulationReport,
};

export const reducer = pricingCostDriverSlice.reducer;
