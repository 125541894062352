const ButtonStyled = {
    baseStyle: {
        fontWeight: 700
    },
    sizes: {
        md: {
            h: '54px',
            fontSize: '16px',
            minW: 60,
            lineHeight: '19.36px',
            _hover:{
                filter:'none'
            }
        },
        custom: {
            fontSize: '16px',
            lineHeight: '19.36px',
            _hover:{
                filter:'none'
            }
        },
    },
    variants: {
        base: {
            bg: '#0070C7',
            borderRadius: '5px',
            color: '#ffffff',
            // p: '0 82px',
            _hover: {
                bg: "#005394",
                color:"#ffffff"
            },
            _disabled: {
                bg: "#8A8A8D",
                color: "#ffffff",
                _hover: {
                    background: "#8A8A8D!important",
                    color:"#ffffff"
                }
            }
        },
        outline: {
            bg: '#FFFFFF',
            borderRadius: '5px',
            border:'2px solid #0070C7',
            borderColor:'#0070C7',
            color: '#0070C7',
            _hover: {
                bg: "#FFFFFF",
                color:"#005394",
                border:'2px solid #005394',
                borderColor:"#005394",
                filter:'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.56))'
            },
            _disabled: {
                bg: "#8A8A8D",
                color: "#ffffff",
                _hover: {
                    background: "#8A8A8D!important",
                    color:"#ffffff"
                }
            }
        },
        link: {
            color: 'rgba(0, 112, 199, 1)',
            fontSize:'14px',
            fontWeight:700
        },
    },
    
    defaultProps: {
        variant: "base",
        size: 'md'
    }
}


export default ButtonStyled;