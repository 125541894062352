import { RootState } from "state/store";
import { ReactElement, useEffect } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { Box, Center, Flex, Spacer, Stack } from "@chakra-ui/layout";
import { Image, Button, Spinner } from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import { useHistory, useLocation } from "react-router-dom";
import OptipediaToast from "utils/notification-util";
import { actions as masterDataAction } from "state/masterData/slice";
import UploadIcon from "assets/images/uploadIcon.png";

const DataUpload = (): ReactElement => {
 const dispatch = useAppDispatch();
 const router = useHistory();
 const location = useLocation();
 const masterDataId = location.pathname.split("/")[2];
 const {
  loading,
 } = useSelector(
  (state: RootState) => ({
   loading: state.masterData.loading,
  }),
  shallowEqual
 );

 useEffect(() => {
  if (loading) {
   const unloadCallback = (event: any) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
   };

   window.addEventListener("beforeunload", unloadCallback);
   return () => window.removeEventListener("beforeunload", unloadCallback);
  }
  window.scrollTo(0, 0);
 }, [loading]);

 const handleDrop = (acceptedFiles: any) => {
  dispatch(
   masterDataAction.uploadMasterData({
    payload: {
     configurationId: masterDataId,
     file: acceptedFiles as any,
    },
   })
  ).then(() => {
    router.goBack()
  })
 };

 return (
  <Box w="100%" mt={'8rem'} >
   
   <Box textAlign={'center'}>
     {!loading ? (
      <Button
      variant={"link"}
       onClick={() => {
        dispatch(masterDataAction.downloadMasterData(masterDataId))
       }}
       isLoading={loading}
       loadingText="Downloading..."
      >
       Download template
      </Button>
     ) : (
      <Button
      variant={"link"}
       isLoading={loading}
       loadingText="Downloading..."
       onClick={() => {
        dispatch(masterDataAction.downloadMasterData(masterDataId))
       }}
      >
       Download template
      </Button>
     )}
     <Box>
     <Dropzone
      onDrop={handleDrop}
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
     >
      {({ getRootProps, getInputProps }) => (
       <Center>
        <Box
        {...getRootProps({ className: "dropzone" })}
        textAlign={'center'}
        mt={5}
        w="461px"
        h="247px"
        borderRadius="5px"
        border="1px dashed #9DA1A7"
        // box-sizing="border-box"
        display="inline-block"
       >
        <Center><Image src={UploadIcon} w="33.75px" h="45px" alt="Bulp" mt={5} /></Center>
        <Flex>
         <Spacer />
         {!loading ? (
          <Box fontSize="18" textColor="#43464B" pt="23px" textAlign="center">
           Drag and drop <br />
           or
          </Box>
         ) : (
          <Box fontSize="16" textColor="#43464B" pt="23px" textAlign="center">
           {/* {fileUpload.arg.payload.file[0].name} */}
           {loading && <Spacer />}
           file name
          </Box>
         )}
         <Spacer />
        </Flex>

        <Button
         bg="#0072CF"
         mt={5}
         w="200px"
         h="59"
         borderRadius="5px"
         fontSize="18"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
        >
         Select file
         <input {...getInputProps()} />
        </Button>
       </Box>
       </Center>
      )}
     </Dropzone>
     </Box>
    </Box>
  </Box>
 );
};

export default DataUpload;
