import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";

export const getSizeUnit = createAsyncThunk("getSizeUnit", async (params) => {
 const response: any = await HttpService.get("SizeUnit", {
  params,
 });
 return response.result;
});

export const getByIdSizeUnit = createAsyncThunk(
 "getByIdSizeUnit",
 async (payload: any) => {
  const response: any = await HttpService.put(`SizeUnit/${payload.id}`);
  return response.result;
 }
);

export const addSizeUnit = createAsyncThunk(
 "addSizeUnit",
 async (payload: any) => {
  const response: any = await HttpService.post(
   `SizeUnit/${payload.type?.replace(/\s/g, "")}`,
   payload
  );
  return response.result;
 }
);

export const editSizeUnit = createAsyncThunk(
 "editSizeUnit",
 async (payload: any) => {
  const response: any = await HttpService.put(
   `SizeUnit/${payload.id}`,
   payload
  );
  return response.result;
 }
);

export const deleteSizeUnit = createAsyncThunk(
 "deleteSizeUnit",
 async (payload: any) => {
  const response: any = await HttpService.delete(`SizeUnit/${payload.id}`);
  return response.result;
 }
);
