import { ReactElement } from "react";
import { Box, Button, Flex, Spacer } from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import Swal from "sweetalert2";
import { useAppDispatch } from "hooks";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { actions as masterDataConfigurationAction } from "state/masterDataConfiguration/slice";

const ToolbarBottom = ({ onOpen, onSubmit, loading, isValid }: any): ReactElement => {
 const dispatch = useAppDispatch();
 const history = useHistory();
 const { location } = history;
 const { search } = location;
 const query = queryString.parse(search);

 return (
  <Box
   position="fixed"
   bottom="0"
   right="0"
   backgroundColor="#ffffff"
   height="4.5rem"
   w="85%"
  >
   <Flex p="auto" mr="10">
    {/* <Box> */}
    <Button
     variant="link"
     w="236px"
     h="45px"
     ml="3rem"
     isDisabled={false}
     onClick={onOpen}
    >
     View Additional Field
    </Button>
    {/* </Box> */}
    <Spacer />
    <Button
    variant={"outline"}
     w="136px"
     h="45px"
     mr="1rem"
     fontSize="16"
     _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
     onClick={async () => {
      Swal.fire({
       showCancelButton: true,
       html: "The changes that you have made will be lost. Are you sure you want to proceed?",
       confirmButtonText: "Yes",
       cancelButtonText: "No",
      }).then(async (result) => {
       if (result.isConfirmed) {
        dispatch(masterDataConfigurationAction.clear());
        history.goBack();
       }
      });
     }}
    >
     Cancel
    </Button>
    <Button
     type="submit"
     bg="#0072CF"
     w="136px"
     h="45px"
     borderRadius="5px"
     fontSize="16"
     color="white"
     isDisabled={loading || isValid}
     _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
     onClick={onSubmit}
     loadingText="Loading..."
    >
     Save
    </Button>
   </Flex>
  </Box>
 );
};

export default ToolbarBottom;
