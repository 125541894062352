import { ChangeEvent, useRef, useState } from "react";
import {
 FormControl,
 FormLabel,
 FormErrorMessage,
 FormHelperText,
 Stack,
} from "@chakra-ui/react";
import AsyncSelect from "react-select/async";
import { useField } from "formik";
import { useAppDispatch } from "hooks";
import { actions as masterDataAction } from "state/masterData/slice";
import { useErrorFocus } from "hooks";

const AutoComplete: any = ({
 name = "",
 id = "",
 label = "",
 code = "",
 thirdPartyId = "",
 description = "",
 isDisabled = false,
 isReadOnly = false,
 isClearable = true,
 isSearchable = true,
 isRequired = false,
 isCustomField = false,
 costDriver = [],
 isLoading = false,
 width = 0,
 backgroundColor = "",
 onChange = (e: ChangeEvent<HTMLInputElement>) => {},
 ...props
}) => {
 const [{ value }, meta, { setValue }] = useField(name);
 const selectRef = useRef(null);
 const dispatch = useAppDispatch();
 useErrorFocus(selectRef, name);
 const [options, setOptions] = useState<any>([]);

 const fetchListFormat = async (value: any) => {
  return await dispatch(masterDataAction.masterDataList(value)).then(
   (masterDataResponse: any) => {
    const options = masterDataResponse?.payload?.result?.items;
    if (options) {
     const newOptions: any = [];
     options.map((item: any) => {
      newOptions.push({
       label: item.code,
       value: item.code,
      });
     });
     const sort = (x: any, y: any) => {
      return x.label.localeCompare(y.label);
     };
     return newOptions.sort(sort);
    }
   }
  );
 };

 const filterColors = async (inputValue: string) => {
  const response = await fetchListFormat({
   skipCount: 0,
   maxResultCount: 100,
   searchKey: inputValue,
   configurationId: code,
   type: 0,
   thirdPartyId: thirdPartyId ? thirdPartyId : "",
  });
  setOptions(response);
  return response;
 };

 const promiseOptions = (inputValue: string) =>
  new Promise<any>((resolve) => {
   setTimeout(() => {
    resolve(filterColors(inputValue));
   }, 1000);
  });
  
 return (
  <FormControl
   id={id}
   isInvalid={Boolean(meta.error && meta.touched)}
   isDisabled={isDisabled}
   isReadOnly={isReadOnly}
   isRequired={isRequired}
  >
   <Stack spacing={4} direction="row">
    {label && (
     <FormLabel
      alignSelf="center"
      margin="0"
      minW="40%"
      fontWeight="bold"
      textTransform="capitalize"
      opacity="1!important"
     >
      {label}
     </FormLabel>
    )}
    <AsyncSelect
     className="basic-single"
     classNamePrefix="select"
     isClearable={!isClearable}
     isSearchable={isSearchable}
    //  menuPortalTarget={document.body}
     name={name}
     placeholder={isLoading ? "Loading":"Please select.."}
     cacheOptions
     defaultOptions
     loadOptions={promiseOptions}
     value={
      value ? options?.filter((item: any) => item.label === value) : ""
     }
     isDisabled={isDisabled}
     onChange={(e: any) => {
      setValue(e.value);
      if (onChange) {
       onChange(e);
      }
     }}
     //  menuPosition="fixed"
     styles={{
      control: (base) => ({
       ...base,
       height: 51,
       minHeight: 51,
       width: width ? width : 300,
       minWidth: 200,
       marginTop: 9,
       pointerEvents: "auto",
       cursor: isDisabled ? "not-allowed !important" : "" ,
       backgroundColor: backgroundColor ? backgroundColor : "",
      }),
      indicatorSeparator: (base) => ({
       width: 0,
       cursor: isDisabled ? "not-allowed !important" : "" ,
      }),
      menu : (base)=> ({
        ...base,
        zIndex: 9999
      })
     }}
     {...props}
    />
    {description && <FormHelperText>{description}</FormHelperText>}
   </Stack>
   <Stack spacing={4} direction="column-reverse" align={"end"}>
    {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
   </Stack>
  </FormControl>
 );
};

export default AutoComplete;
