import { ChangeEvent } from "react";
import { Box } from "@chakra-ui/layout";
import {
 Image,
 Input,
 InputGroup,
 InputLeftElement,
 InputRightElement,
 Spinner,
} from "@chakra-ui/react";
import { SearchIcon } from "components/Icons";

interface SearchBarProps {
 value?: string;
 placeholder: string;
 onChange: (event: ChangeEvent<HTMLInputElement>) => void;
 isLoading?: boolean;
}

const SearchBar = ({
 value,
 placeholder,
 onChange,
 isLoading,
}: SearchBarProps) => {
 return (
  <Box>
   <InputGroup>
    <InputLeftElement
     pointerEvents="none"
     children={<SearchIcon mt="0.5rem" />}
    />
    <Input
     value={value}
     onChange={onChange}
     placeholder={placeholder}
     border="1px solid"
     borderColor="blackAlpha.300"
     bgColor={"white"}
     minW="331px"
     minH="46px"
     borderRadius="5px"
    />
    <InputRightElement
     children={
      isLoading && (
       <Spinner
        size="xs"
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
       />
      )
     }
    />
   </InputGroup>
  </Box>
 );
};

export default SearchBar;
