import { FC } from "react";
import { useHistory} from 'react-router-dom'
import queryString from 'query-string'
import { Box, Grid, Circle } from "@chakra-ui/react";

type LevelTargetProps = {
  level?: string;
};

const LevelTarget: FC<LevelTargetProps> = ({level}) => {
  const router = useHistory()
  const {location} = router
  const query = queryString.parse(location.search)

  const stepLevelDetail = query.page === 'level-details'
  const stepTargetDiscover = query.page === 'target-discovery-step-1' || query.page === 'target-discovery-step-2' || query.page === 'target-discovery-step-3' || query.page === 'target-discovery-step-4'
  const stepNameJourney = query.page === 'name-journey'

  return (
    <Box display='flex' alignItems='center' w="100%">
      <Grid w="60%" templateColumns='repeat(5, 1fr)' gap={6} bg='#F7F9FD' borderRadius="3xl" color="#00050D" p="1rem">
        <Box as="span" w='100%'>
          <Box display='flex' alignItems='center'>
            <Circle size='40px' bg={query.page === 'level-overview'?'#ffffff':''} color='white' border="1px solid #0072CF">
              <Box as="span">
                <Circle size={query.page !== 'level-overview'?'40px':'34px'} bg={query.page !== 'level-overview' ?"#00439E":"#0072CF"} color='white' fontWeight="600" fontSize="16px">1</Circle>
              </Box>
            </Circle>
            <Box w="90px" h="4px" bgColor={query.page !== 'level-overview'?"#00439E": "#D7DBE2"} ml="1.5rem"/>
          </Box>
          <Box as="p" fontWeight="600" pt="2" color={query.page === 'level-overview' ? "":"#7B7F85"}>Level overview</Box>
        </Box>
        <Box as="span" w='100%'>
          <Box display='flex' alignItems='center'>
            <Circle size='40px' bg={stepLevelDetail?'#ffffff':'#D7DBE2'} color='white' border={stepLevelDetail ? "1px solid #0072CF":"none"}>
              <Box as="span">
                <Circle size={stepTargetDiscover? "40px": stepLevelDetail?'34px':'40px'} bg={stepTargetDiscover || stepNameJourney ? "#00439E": (stepLevelDetail?'#0072CF':'#D7DBE2')} color='white' border={stepLevelDetail || stepTargetDiscover ? "1px solid #0072CF":"none"} fontWeight="600" fontSize="16px">2</Circle>
              </Box>
            </Circle>
            <Box w="90px" h="4px" bgColor={stepTargetDiscover || stepTargetDiscover || stepNameJourney?"#00439E": "#D7DBE2"} ml="1.5rem" borderRadius="2px"/>
          </Box>
          <Box as="p" fontWeight="600" pt="2" color={stepLevelDetail ? "":"#7B7F85"}>Level details</Box>
        </Box>

        <Box as="span" w='100%'>
          <Box display='flex' alignItems='center'>
            <Circle size='40px' bg={stepTargetDiscover?'#ffffff':'#D7DBE2'} color='white' border={stepTargetDiscover ? "1px solid #0072CF":"none"}>
              <Box as="span">
                <Circle size={stepTargetDiscover? "34px": stepLevelDetail ?'34px':'40px'} bg={stepNameJourney ?'#00439E':(stepTargetDiscover?'#0072CF':'#D7DBE2')} color='white' border={stepTargetDiscover || stepNameJourney ? "1px solid #0072CF":"none"} fontWeight="600" fontSize="16px">3</Circle>
              </Box>
            </Circle>
            <Box w="90px" h="4px" bgColor={stepNameJourney ?"#00439E": "#D7DBE2"} ml="1.5rem" borderRadius="2px"/>
          </Box>
          <Box as="p" fontWeight="600" pt="2" color={stepTargetDiscover ? "":"#7B7F85"}>Target & Discovery</Box>
        </Box>
        
        <Box as="span" w='100%'>
          <Box display='flex' alignItems='center'>
            <Circle size='40px' bg={stepNameJourney?'#ffffff':'#D7DBE2'} color='white' border={stepNameJourney ? "1px solid #0072CF":"none"}>
              <Box as="span">
                <Circle size={stepNameJourney?'34px':'40px'} bg={stepNameJourney?'#0072CF':'#D7DBE2'} color='white' border={stepNameJourney ? "1px solid #0072CF":"none"} fontWeight="600" fontSize="16px">4</Circle>
              </Box>
            </Circle>
            <Box w="90px" h="4px" ml="1.5rem"  borderRadius="2px"/>
          </Box>
          <Box as="p" fontWeight="600" pt="2" color={stepNameJourney ? "":"#7B7F85"}>Name journey</Box>
        </Box>
      </Grid>
      <Box width="45%" as='p' color='white' fontSize='18px' ml="10" textColor="#00050D" fontWeight="500">
        {query?.page === 'level-overview' && 'Optipedia is configurable and unlocking the levels of Should Cost becomes your journey of cost discovery.'}
        {query?.page === 'level-details' && 'With each level of your journey that you unlock, Optipedia features will give you greater insights, understanding and value of your business.'}
        {query?.page === 'target-discovery-step-1' && 'Should Cost target setting and Optipedia discovery stage give you the opportunity to target the level or levels that you want to unlock in your journey and establish the proposed starting point in Optipedia.'}
      </Box>
    </Box>
  );
};

export default LevelTarget;
