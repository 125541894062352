import Table from "components/Table/Table";
import Pagination from "components/Pagination";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { TableLoading } from "components/Loading";
import { Box, Image, Stack, Heading, Text, Center, Button } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import OptipediaToast from "utils/notification-util";
import { actions as demandForecastActions } from "state/demandForecast/slice";
import "utils/date-utils";
import Swal from "sweetalert2";
import IconDelete from "assets/images/Delete.png";
import { useHistory } from "react-router-dom";
import EmptyState from "components/EmptyState";
import { isEmpty } from "lodash";

const TABLE_HEADERS = [
 { name: "name", label: "Product ID", sequence: 1 },
 { name: "description", label: " Product Description", sequence: 2 },
 { name: "reference", label: "Reference", sequence: 3 },
 { name: "demand", label: "Demand", sequence: 4 },
 { name: "orderFrequency", label: "Order Frequency", sequence: 5 },
];

const TABLE_HEADERS_WITH = [
    { name: "name", label: "Product ID", sequence: 1 },
    { name: "description", label: " Product Description", sequence: 2 },
    { name: "thirdPartyName", label: "Third-Party", sequence: 3 },
    { name: "reference", label: "Reference", sequence: 4 },
    { name: "demand", label: "Demand", sequence: 5 },
    { name: "orderFrequency", label: "Order Frequency", sequence: 6 },
   ];

const DemandForecastTable: FC = () => {
 const dispatch = useAppDispatch();
 const history = useHistory();
 const maxResultCount = 10;
 const [pageNumber, setPageNumber] = useState(1);

 const {
  lists,
  loading,
  totalPage,
  totalCount,
  searchKey,
  isEnableThirdPartyStatus,
  thirdPartySelection,
  journeySelection
 } = useSelector(
  (state: RootState) => ({
   lists: state.demandForecast.result.items,
   totalPage: Math.ceil(
    state.demandForecast.result.totalCount / maxResultCount
   ),
   totalCount: state.demandForecast.result.totalCount,
   loading: state.demandForecast.loading,
   searchKey: state.demandForecast.searchQuery,
   isEnableThirdPartyStatus: state.thirdPartySetting.isEnableThirdPartyStatus,
   thirdPartySelection: state.demandForecast.thirdPartySelection,
   journeySelection: state.demandForecast.journeySelection
  }),
  shallowEqual
 );

 const fetchListOfDemandForecast = (skipCount: number, searchKey: string) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: maxResultCount,
   searchKey: searchKey,
   thirdPartyId: thirdPartySelection,
   productConfigurationId: journeySelection,
  };

  return dispatch(demandForecastActions.getList(payload));
 };

//  useEffect(() => {
//   if (isEnableThirdPartyStatus) {
//    TABLE_HEADERS.push({
//     name: "thirdPartyName",
//     label: "Third-Party",
//     sequence: 4,
//    });
//   }
//  }, [isEnableThirdPartyStatus]);

 useEffect(() => {
  fetchListOfDemandForecast(0, searchKey);
 }, []);

 const handleChangePagination = (pageNumber: number) => {
  setPageNumber(pageNumber);
  var skipCount = (pageNumber - 1) * maxResultCount;
  fetchListOfDemandForecast(skipCount, searchKey);
 };

 let listDemandForecast: any = [];
 lists.map((item: any) => {
  listDemandForecast.push({
   ...item,
   thirdPartyName: item?.thirdParty?.description,
  });
 });

 if (loading) {
  return <TableLoading />;
 }

 const deleteConfirm = async (params: any) => {
  await dispatch(demandForecastActions.deleteById(params)).then((res) => {
   if (res.payload !== undefined) {
    OptipediaToast.Success("Has been removed successfully.");
   }
  });

  var skipCount = 0;
  if (lists?.length - 1 > 0) {
   skipCount = (pageNumber - 1) * maxResultCount;
   setPageNumber(pageNumber);
  }
  fetchListOfDemandForecast(skipCount, searchKey);
 };

 return (
  <>
   {(!loading && listDemandForecast.length) > 0 ? (
    <Box>
     <Table
      loading={loading}
      columnHeaders={ !isEnableThirdPartyStatus ? TABLE_HEADERS : TABLE_HEADERS_WITH}
      data={listDemandForecast}
      actionButtons={(id: string, rowData: any) => {
       return (
        <Stack direction="row" align="center" float="right">
         {rowData.isDeletable && (
          <Image
           src={IconDelete}
           cursor="pointer"
           onClick={async () => {
            Swal.fire({
             showCancelButton: true,
             html: "Are you sure you want to delete this record?",
             confirmButtonText: "Yes",
             customClass: {
              confirmButton: "swal2-confirm",
             },
            }).then(async (result) => {
             if (result.isConfirmed) {
              const params = {
               id,
               orderId: rowData.orderId,
              };
              deleteConfirm(params);
             }
            });
           }}
           alt="IoMdTrash"
           mr="1.5rem"
           w="132px"
          />
         )}
        </Stack>
       );
      }}
     />
     <Box w="100%" mt="1rem" p="20px">
      {listDemandForecast.length > 0 && (
       <Pagination
        onChange={handleChangePagination}
        totalRowsPage={maxResultCount}
        pageNumber={totalPage}
        currentPage={pageNumber}
        totalAllPage={totalCount}
       />
      )}
     </Box>
    </Box>
   ) : (
   <EmptyState isSearch={!isEmpty(searchKey)}>
    <Button
        onClick={() => history.push("/demand-forecast/upload-dataset")}
        h="50px"
        w="200px"
        borderRadius={"5px"}
        colorScheme={"blue"}
       >
        Upload
       </Button>
   </EmptyState>
   )}
  </>
 );
};

export default DemandForecastTable;
