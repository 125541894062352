import { ReactElement, useEffect, useMemo, useState } from "react";
import { useAppDispatch, usePrevious } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { useHistory, useLocation } from "react-router-dom";
import { NumberLoading, TextLoading, TableLoading } from "components/Loading";
import { Box, Heading, Grid, Flex, Spacer } from "@chakra-ui/layout";
import { Button, Stack, Image, Center, Text } from "@chakra-ui/react";
import { actions as pricingListAction } from "state/pricings/pricingList/slice";
import Layout from "views/Layout";
import Table from "components/Table/Table";
import Pagination from "components/Pagination";
import SearchBar from "components/SearchBar";
import debounce from "lodash/debounce";
import StepInfo from "components/StepInfo";
import EmptyState from "components/EmptyState";
import { isEmpty } from "lodash";

const COLUMN_HEADERS = [
 { name: "name", label: "Product ID" },
 { name: "description", label: "Product Description" },
 { name: "format", label: "Format" },
 { name: "currencyCode", label: "Currency" },
];

const PricingListSimulationBySku = (): ReactElement => {
 const dispatch = useAppDispatch();
 const history = useHistory();
 const location = useLocation<{
  status: string;
  volumeId: string;
  isEditing: boolean;
 }>();
 const [pageNumber, setPageNumber] = useState(1);
 const maxResultCount = 10;

 const { loading, pricingListData, totalPage, searchQuery, totalCountPricing } =
  useSelector(
   (state: RootState) => ({
    loading: state.pricingList.loading,
    pricingListData: state.pricingList.lists.result.items,
    totalPage: Math.ceil(
     state.pricingList.lists.result.totalCount / maxResultCount
    ),
    totalCountPricing: state.pricingList.lists.result.totalCount,
    searchQuery: state.pricingList.searchQuery,
    detailPerVolumeId: state.pricingCostDrivers.detailPerVolumeId.result,
   }),
   shallowEqual
  );

 const previousSearchQuery = usePrevious(searchQuery, "");

 const debouncedRefreshList = useMemo(
  () =>
   debounce((args) => {
    return dispatch(pricingListAction.pricingList(args));
   }, 800),
  [dispatch]
 );

 useEffect(() => {
  if (
   (searchQuery.length > 0 || searchQuery.length === 0) &&
   searchQuery !== previousSearchQuery
  ) {
   const payload = {
    skipCount: 0,
    maxResultCount: maxResultCount,
    searchKey: searchQuery,
    isSimulation: true,
    status: 2,
    levels: [10, 20, 30],
   };
   setPageNumber(1);
   debouncedRefreshList(payload);
  } else if (searchQuery === previousSearchQuery) {
   fetchList();
  }
 }, [dispatch, searchQuery]);

 const fetchList = () => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   searchKey: "",
   isSimulation: true,
   status: 2,
   levels: [10, 20, 30],
  };
  setPageNumber(1);
  dispatch(pricingListAction.pricingList(payload));
 };

 const handleChangeSearchBar = (event: any) => {
  dispatch(
   pricingListAction.setSearchQuery({ value: event.currentTarget.value })
  );
 };

 return (
  <Layout>
   <Flex>
    <Heading>
    Simulations By SKU
   </Heading>
   <Spacer />
     <Box pt="5px">
      <SearchBar
       value={searchQuery}
       onChange={handleChangeSearchBar}
       placeholder={"Search"}
       isLoading={loading}
      />
     </Box>
     </Flex>
    <Box mt="4" color="rgba(26, 26, 26, 0.36)" fontWeight={400} fontSize="16px">
      In this module, you can see all the pricing that has been uploaded and{" "}
      <br />
      check all the detailed information of the pricing including the cost
      summary.
     </Box>

     <Box pb="1rem">
     <Grid templateColumns="repeat(4, 1fr)" gap={8} pt="2.5rem" pb="1rem">
      <StepInfo>
      <Box
       as="p"
       pb="3px"
       fontSize="48"
       textColor="rgba(26, 26, 26, 1)"
       fontWeight="600"
      >
       {loading ? <NumberLoading /> : "01"}
      </Box>
      <Box
       as="p"
       fontSize="18px"
       fontWeight="500"
       lineHeight="23.4px"
       color="rgba(26, 26, 26, 0.56)"
      >
       {loading ? (
        <TextLoading />
       ) : (
        "Upload multiple pricing’s data into the system"
       )}
      </Box>
      </StepInfo>
      <StepInfo>
      <Box
       as="p"
       pb="3px"
       fontSize="48"
       textColor="rgba(26, 26, 26, 1)"
       fontWeight="600"
      >
       {loading ? <NumberLoading /> : "02"}
      </Box>
      <Box
       as="p"
       fontSize="18px"
       fontWeight="500"
       lineHeight="23.4px"
       color="rgba(26, 26, 26, 0.56)"
      >
       {loading ? (
        <TextLoading />
       ) : (
        "Check all the detailed information of the pricing"
       )}
      </Box>
     </StepInfo>
     <StepInfo>
      <Box
       as="p"
       pb="3px"
       fontSize="48"
       textColor="rgba(26, 26, 26, 1)"
       fontWeight="600"
      >
       {loading ? <NumberLoading /> : "03"}
      </Box>
      <Box
       as="p"
       fontSize="18px"
       fontWeight="500"
       lineHeight="23.4px"
       color="rgba(26, 26, 26, 0.56)"
      >
       {loading ? (
        <TextLoading />
       ) : (
        "Cost Summary that is calculated by the system"
       )}
      </Box>
     </StepInfo>
     <StepInfo>
      <Box
       as="p"
       pb="3px"
       fontSize="48"
       textColor="rgba(26, 26, 26, 1)"
       fontWeight="600"
      >
       {loading ? <NumberLoading /> : "04"}
      </Box>
      <Box
       as="p"
       fontSize="18px"
       fontWeight="500"
       lineHeight="23.4px"
       color="rgba(26, 26, 26, 0.56)"
      >
       {loading ? <TextLoading /> : "Simulation for individual pricing"}
      </Box>
     </StepInfo>
     </Grid>
     </Box>
   {loading && <TableLoading />}
   {!loading &&
    Array.isArray(pricingListData) &&
    pricingListData.length > 0 && (
     <>
       <Heading size="sm" my="5">Pricing List</Heading>
      <Box w="100%">
       <Table
        loading={loading}
        columnHeaders={COLUMN_HEADERS}
        data={pricingListData}
        actionButtons={(pricingId: string, { status }: any) => (
         <Stack spacing={4} direction="row" align="center" float="right">
          <Button
           variant="link"
           onClick={() => {
            history.push({
             pathname: `/simulation-by-sku/sku-characteristic/${pricingId}`,
             state: {
              status: status,
              volumeId: null,
             },
            });
           }}
          >
           Simulate
          </Button>
         </Stack>
        )}
       />
      </Box>

      <Box w="100%" mt="1rem" p="20px">
       <Pagination
        onChange={(pageNumber) => {
         setPageNumber(pageNumber);
         var skipCountNew = (pageNumber - 1) * maxResultCount;
         dispatch(
          pricingListAction.pricingList({
           skipCount: skipCountNew,
           maxResultCount: maxResultCount,
           searchKey: searchQuery,
           status: 2,
           levels: [10, 20, 30],
          })
         );
        }}
        totalRowsPage={maxResultCount}
        pageNumber={totalPage}
        currentPage={pageNumber}
        totalAllPage={totalCountPricing}
       />
      </Box>
     </>
    )}
    {!loading &&
    Array.isArray(pricingListData) &&
    pricingListData.length === 0 &&
     (
      <EmptyState isSearch={!isEmpty(searchQuery)}>

      </EmptyState>
    )}
  </Layout>
 );
};

export default PricingListSimulationBySku;
