import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Box, Grid, Circle } from "@chakra-ui/react";

const Stepper = (): ReactElement => {
 const router = useHistory();
 const { location } = router;
 const query = queryString.parse(location.search);

 const stepBusinessCase = query.page === "select-business-case";
 const stepSummary = query.page === "summary";

 return (
  <Box display="flex" alignItems="center" w="100%">
   <Grid
    w="700px"
    templateColumns="repeat(5, 1fr)"
    gap={6}
    bg="#F7F9FD"
    borderRadius="5px"
    color="#00050D"
    p="1rem"
   >
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={query.page === "select-journey" ? "#ffffff" : ""}
       color="white"
       border="1px solid #0072CF"
      >
       <Box as="span">
        <Circle
         size={query.page !== "select-journey" ? "40px" : "34px"}
         bg={query.page !== "select-journey" ? "#00439E" : "#0072CF"}
         color="white"
         fontWeight="600"
         fontSize="16px"
        >
         1
        </Circle>
       </Box>
      </Circle>
      <Box
       w="200px"
       h="4px"
       bgColor={query.page !== "select-journey" ? "#00439E" : "#D7DBE2"}
       ml="1.5rem"
      />
     </Box>
     <Box
      as="p"
      fontWeight="600"
      pt="2"
      color={query.page === "select-journey" ? "" : "#7B7F85"}
     >
      Select journey
     </Box>
    </Box>
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={stepBusinessCase ? "#ffffff" : "#D7DBE2"}
       color="white"
       border={stepBusinessCase ? "1px solid #0072CF" : "none"}
      >
       <Box as="span">
        <Circle
         size={stepSummary ? "40px" : stepBusinessCase ? "34px" : "40px"}
         bg={stepSummary ? "#00439E" : stepBusinessCase ? "#0072CF" : "#D7DBE2"}
         color="white"
         border={stepBusinessCase ? "1px solid #0072CF" : "none"}
         fontWeight="600"
         fontSize="16px"
        >
         2
        </Circle>
       </Box>
      </Circle>
      <Box
       w="200px"
       h="4px"
       bgColor={stepSummary ? "#00439E" : "#D7DBE2"}
       ml="1.5rem"
       borderRadius="2px"
      />
     </Box>
     <Box
      as="p"
      fontWeight="600"
      pt="2"
      position="relative"
      right="55"
      color={stepBusinessCase ? "" : "#7B7F85"}
     >
      Select Business Case
     </Box>
    </Box>
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={stepSummary ? "#ffffff" : "#D7DBE2"}
       color="white"
       border={stepSummary ? "1px solid #0072CF" : "none"}
      >
       <Box as="span">
        <Circle
         size={stepSummary ? "34px" : "40px"}
         bg={stepSummary ? "#0072CF" : "#D7DBE2"}
         color="white"
         border={stepSummary ? "1px solid #0072CF" : "none"}
         fontWeight="600"
         fontSize="16px"
        >
         3
        </Circle>
       </Box>
      </Circle>
      {/* <Box w="200px" h="4px" ml="1.5rem" borderRadius="2px" /> */}
     </Box>
     <Box as="p" fontWeight="600" pt="2" color={stepSummary ? "" : "#7B7F85"}>
      Summary
     </Box>
    </Box>
   </Grid>
  </Box>
 );
};
export default Stepper;
