import { ReactElement, useEffect, useState } from "react";
import { Box, Flex, Button, Text, Heading } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextInput from "components/TextInput";
import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import { actions as settingAction } from "state/settings/slice";
import { actions as productConfigurationsActions } from "state/productConfigurations/slice";
import { useAppDispatch } from "hooks";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import DatePickerInput from "components/DatePicker";
import { CalendarIcon } from "@chakra-ui/icons";
import Swal from "sweetalert2";

interface Values {
 id: string;
 currencyCode: string;
 description: string;
 dateFrom: string;
 dateTo: string;
 rate: string;
 thirdPartyCode: string;
 journeyCode: string;
}

const ExchangeCreated = (): ReactElement => {
 const {
  exchangeCreated,
  exchangeUpdated,
  exchangeDataToEdit,
  searchQuery,
  listCurrency,
  listThirdParty,
  listsJourney,
 } = useSelector(
  (state: RootState) => ({
   exchangeCreated: state.multiCurrency.exchangeCreated,
   exchangeUpdated: state.multiCurrency.exchangeUpdated,
   exchangeDataToEdit: state.multiCurrency.exchangeDataToEdit,
   searchQuery: state.multiCurrency.searchQuery,
   listCurrency: state.multiCurrency.lists,
   listThirdParty: state.thirdPartySetting.thirdParty,
   listsJourney: state.productConfigurations.result.items,
  }),
  shallowEqual
 );
 const maxResultCount = 10;
 const maxResultCountCurrency = 1000;
 const [initialState, setInitialState] = useState<Values>({
  id: "",
  currencyCode: "",
  description: "",
  dateFrom: "",
  dateTo: "",
  rate: "",
  thirdPartyCode: "",
  journeyCode: "",
 });
 const [currenciesOpt, setCurrenciesOpt] = useState([]);
 const [thirdpartyOpt, setThirdpartyOpt] = useState([]);
 const [journeyOpt, setJourneyOpt] = useState([]);
 const [currencies, setCurrencies] = useState([]);
 const [keyCurrency, setKeyCurrency] = useState("");
 const [keyJourney, setKeyJourney] = useState("");
 const [keyThirdparty, setKeyThirdparty] = useState("");
 const dispatch = useAppDispatch();

 useEffect(() => {
  setInitialState(exchangeDataToEdit);
 }, [exchangeUpdated]);

 useEffect(() => {
  if(exchangeUpdated || exchangeCreated){
    fetchListCurrency();
  }
  // fetchListThirdparty();
  fetchListJourney();
 }, [keyJourney, keyThirdparty]);

 useEffect(() => {
  setCurrencies(
   listCurrency?.items?.map((item: any) => {
    return {
     label: item.code,
     value: item.code,
     desc: item.description,
    };
   })
  );
 }, [listCurrency]);

 const validation = Yup.object().shape({
  currencyCode: Yup.string().required("Currency code is a required field"),
  description: Yup.string().required(
   "Currency description is a required field"
  ),
 });
 const fetchList = () => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   keyword: searchQuery,
  };
  dispatch(multiCurrencyAction.listExchange(payload));
 };

 const fetchListCurrency = async () => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCountCurrency,
   keyword: keyCurrency,
   ManageExchangeRate: false
  };
  await dispatch(multiCurrencyAction.listMultiCurrency(payload));
 };

 const fetchListThirdparty = () => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   keyword: keyThirdparty,
  };
  dispatch(settingAction.getThirdPartyToShow(payload));
  setThirdpartyOpt(
   listThirdParty?.items?.map((item: any) => {
    return {
     label: item.code,
     value: item.code,
    };
   })
  );
 };
 const fetchListJourney = () => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   searchKey: keyJourney,
  };
  dispatch(productConfigurationsActions.getList(payload));
  setJourneyOpt(
   listsJourney?.map((item: any) => {
    return {
     label: item.journeyCode,
     value: item.journeyCode,
    };
   })
  );
 };

 return (
  <Box>
   <Heading fontSize={18} pb={5}>
    {exchangeCreated ? "Create New Currency" : "Update Currency"}
   </Heading>
   <Formik
    enableReinitialize
    initialValues={initialState}
    validationSchema={validation}
    onSubmit={async (values: Values, {resetForm}) => {
     const { id } = exchangeDataToEdit;
     if (id) {
      const payload = {
       id: id,
       values,
      };
      await dispatch(multiCurrencyAction.editExchange(payload)).then(
       (res: any) => {
        if (res.payload) {
          resetForm()
         fetchList();
         dispatch(multiCurrencyAction.resetPage(false));
        }
       }
      );
     } else {
      await dispatch(multiCurrencyAction.addExchange(values)).then(
       (res: any) => {
        if (res.payload) {
          resetForm()
         fetchList();
         dispatch(multiCurrencyAction.resetPage(false));
        }
       }
      );
     }
    }}
   >
    {({
     values,
     setFieldValue,
     errors,
     touched,
     handleChange,
     handleBlur,
     handleSubmit,
     isSubmitting,
    }) => (
     <Form>
      <Box mb="5rem">
       <Box width={500} py="5px">
        <Text color={"gray.500"}>Currency Code</Text>
        <SelectWithAutoComplete
         width={500}
         name="currencyCode"
         id="currencyCode"
         options={currencies}
         onInputChange={(e: any) => {
          setKeyCurrency(e);
         }}
         onChange={(e: any) => {
          setFieldValue("currencyCode", e.value);
          setFieldValue("description", e.desc);
         }}
        />
       </Box>
       <Box width={500} py="5px">
        <Text color={"gray.500"}>Currency Description</Text>
        <TextInput
         isReadOnly
         isDisabled
         name="description"
         id="description"
         placeholder="e.g United States Dollar"
        />
       </Box>
       <Box width={500} py="5px">
        <Text color={"gray.500"}>Date from</Text>
        <DatePickerInput
         maxDate={(values.dateTo && new Date(values.dateTo)) || undefined}
         minW="500px"
         name="dateFrom"
         id="dateFrom"
         RightElement={<CalendarIcon />}
        />
       </Box>
       <Box width={500} py="5px">
        <Text color={"gray.500"}>Date to</Text>
        <DatePickerInput
         minDate={(values.dateFrom && new Date(values.dateFrom)) || undefined}
         placeholder="dd/mm/yyyy"
         minW="500px"
         name="dateTo"
         id="dateTo"
         RightElement={<CalendarIcon />}
        />
       </Box>
       <Box width={500} py="5px">
        <Text color={"gray.500"}>Rate</Text>
        <TextInput name="rate" id="rate" placeholder="e.g 14,545,03" />
       </Box>
       {/* <Box width={500} py="5px">
        <Text color={"gray.500"}>Third-Party</Text>
        <SelectWithAutoComplete
         width={500}
         name="thirdPartyCode"
         defaultValue={values.thirdPartyCode}
         id="thirdPartyCode"
         onInputChange={(e: any)=> {
          setKeyThirdparty(e)
         }}
         options={thirdpartyOpt || []}
        />
       </Box> */}
       {/* <Box width={500} py="5px">
        <Text color={"gray.500"}>Journey</Text>
        <SelectWithAutoComplete
         width={500}
         name="journeyCode"
         defaultValue={values.journeyCode}
         id="journeyCode"
         onInputChange={(e: any)=> {
          setKeyJourney(e)
         }}
         options={journeyOpt || []}
        />
       </Box> */}
      </Box>
      <Box
       position="fixed"
       bottom="0"
       backgroundColor="#ffffff"
       height="5.5rem"
       w="100%"
      >
       <Flex float="right" mr="20rem">
        <Box mt={4}>
         <Flex alignItems="center" gap="2">
          <>
           <Button
            variant={"outline"}
            onClick={()=> Swal.fire({
              showCancelButton: true,
              html: "The changes that you have made will be lost. Are you sure you want to proceed.",
              cancelButtonText: "No",
              confirmButtonText: "Yes",
             }).then(async (result) => {
               if (result.isConfirmed) {
                dispatch(multiCurrencyAction.clear());
                fetchList()
               }
              })}
           >
            Cancel
           </Button>
           <Button type="submit">Save</Button>
          </>
         </Flex>
        </Box>
       </Flex>
      </Box>
     </Form>
    )}
   </Formik>
  </Box>
 );
};

export default ExchangeCreated;
