import { useEffect, useRef } from "react";
import Layout from "views/Layout";
import { Box, Flex, Heading, Spacer, VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { useAppDispatch } from "hooks";
import queryString from "query-string";
import Steppers from "views/AnnualSpendImpact/Comparison/Steppers";
import Summary from "./Summary";
import Lamp from "assets/images/bulp.png";
import ThirdParty from "./ThirdParty";
import SearchBar from "components/SearchBar";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { actions as approvalAction } from "state/approval/slice";
import Pricings from "./Pricings";

export default function Home() {
 const router = useHistory();
 const messagesEndRef = useRef<null | HTMLDivElement>(null);
 const dispatch = useAppDispatch();
 const { location } = router;
 const { search } = location;
 const query = queryString.parse(search);
 const {
    loading,
    searchQuery,
   } = useSelector(
    (state: RootState) => ({
     loading: state.approval.loading,
     searchQuery: state.approval.searchQuery,
    }),
    shallowEqual
   );

 const handleChangeSearchBar = (event: any) => {
    dispatch(
     approvalAction.setSearchQuery({
      value: event.currentTarget.value,
     })
    );
   };

 return (
  <Layout>
   <VStack align="flex-start" ref={messagesEndRef}>
    <Box display="flex" w="100%" my={5}>
     <Heading as="h2" fontSize="38px" mt={2}>
      {query?.page === "select-third-party" && "Select Third-party"}
      {query?.page === "select-pricing" && "Select Pricing"}
      {query?.page === "summary" && "Summary"}
     </Heading>
     <Box width="300px" borderRadius="5px" mb="1rem" ml="25px">
      <Box
       as="p"
       p="1rem"
       color="#7B7F85"
       fontSize="14px"
       fontWeight="500"
       justifyContent="left"
      >
       {query?.page === "summary" &&
        `This is the result of your simulation. Click here to see your changes.`}
      </Box>
     </Box>
     {query?.page === "summary" && (
      <Box
       width="450px"
       borderRadius="5px"
       mb="1rem"
       ml="25px"
       backgroundColor="#F7F9FD"
      >
       <Image src={Lamp} alt="lampicon" float="left" m="1.5rem" />
       <Box
        as="p"
        p="1rem"
        color="#7B7F85"
        fontSize="14px"
        fontWeight="500"
        justifyContent="center"
       >
        To unlock more data, contact InnoArk staff here <br /> to unlock more
        levels of your cost journey.
       </Box>
      </Box>
     )}
    </Box>
    <Flex>
    <Steppers />
    <Spacer />
    {query?.page === "summary " && <Box m="auto" pt="1.5rem">
      <SearchBar
       value={searchQuery}
       onChange={handleChangeSearchBar}
       placeholder={"Search"}
       isLoading={false}
      />
     </Box>}
    </Flex>
    <Box pt="rem" w="100%">
     {/* {query?.page === "level-overview" && <LevelOverview />}
     {query?.page === "level-details" && <LevelDetail />}
     {query?.page === "target-discovery-step-1" && <TargetDiscoveryStep1 />}
     {query?.page === "target-discovery-step-2" && <TargetDiscoveryStep2 />}
     {query?.page === "target-discovery-step-3" && <TargetDiscoveryStep3 />}
     {query?.page === "target-discovery-step-4" && <TargetDiscoveryStep4 />}
     {query?.page === "name-journey" && <NameJourney />} */}
     {query?.page === "select-third-party" && <ThirdParty />}
     {query?.page === "select-pricing" && <Pricings />}
     {query?.page === "summary" && <Summary />}
    </Box>
   </VStack>
  </Layout>
 );
}
