import { RootState } from "state/store";
import {
 createRef,
 FC,
 ReactElement,
 useEffect,
 useRef,
 useState,
} from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { Box, Button, Stack, Flex, Checkbox } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { TextLoading } from "components/Loading";
import OptipediaToast from "utils/notification-util";
import { actions as simulateAction } from "state/simulation/slice";

const BusinessCase = (): ReactElement => {
 const dispatch = useAppDispatch();
 const router = useHistory();
 const finalCostRef = useRef<HTMLInputElement>(null);

 const [active, setActive] = useState(false);

 let { loading, businessCase, selectCompare } = useSelector(
  (state: RootState) => ({
   loading: state.simulationAggregate.loading,
   businessCase: state.simulationAggregate.businessCase,
   selectCompare: state.simulationAggregate.selectCompare,
  }),
  shallowEqual
 );

 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);

 const setCheckBox = (isChecked: boolean, value: string) => {
  if (isChecked) {
   dispatch(simulateAction.setselectCompare(value));
  } else {
   dispatch(simulateAction.removeArrayselectCompare(value));
  }
 };

 const compareAction = () => {
  if (selectCompare.length === 2) {
   dispatch(
    simulateAction.compareBusinessCase({
     simulationIds: selectCompare,
    })
   );
   router.push("/simulations/comparasion/create?page=summary");
  } else {
   OptipediaToast.Warning(
    "Please select 2 business case before proceeding to the next step."
   );
  }
 };

 return (
  <Box mb="6rem">
   <Box>
    <Flex
     pt="2rem"
     flexWrap="wrap"
     _after={{
      width: "100%",
     }}
    >
     {!loading ? (
      businessCase &&
      businessCase.length !== undefined &&
      businessCase &&
      businessCase?.map((dataToCompare: any, index: number) => {
       const ref = createRef<HTMLInputElement>();
       return (
        <Box key={index} padding="1rem">
         <Box fontWeight="700" fontSize="24px">
          {loading ? (
           <TextLoading />
          ) : (
           <>
            <Button
             h="50"
             pl="50"
             pr="50"
             borderRadius="50"
             fontSize="18"
             border="1px"
             isDisabled={
              selectCompare.length == 2
               ? selectCompare.includes(dataToCompare.id)
                 ? false
                 : true
               : false
             }
             borderColor="#0072CF"
             name={dataToCompare.name}
             color={
              selectCompare.includes(dataToCompare.id) ? "white" : "#0072CF"
             }
             onClick={(e: any) => {
              ref.current?.click();
             }}
             bg={selectCompare.includes(dataToCompare.id) ? "#0072CF" : ""}
             _disabled={{
              bgColor: "#C3C7CD",
              cursor: "not-allowed",
              color: "white",
              border: "solid 1px white",
             }}
            >
             {dataToCompare.name}
            </Button>
            <Checkbox
             ref={ref}
             display="none"
             defaultChecked={selectCompare.includes(dataToCompare.id)}
             value={dataToCompare.id}
             onChange={(val) => {
              setCheckBox(val.target.checked, val.currentTarget.value);
             }}
            >
             {dataToCompare.name}
            </Checkbox>
           </>
          )}
         </Box>
        </Box>
       );
      })
     ) : (
      <TextLoading />
     )}
    </Flex>
   </Box>
   <Box
    position="fixed"
    bottom="0"
    backgroundColor="#ffffff"
    height="5.5rem"
    w="100%"
    zIndex={2}
   >
    <Flex float="right" mr="20rem">
     <Box mt={4}>
      <Flex alignItems="center" gap="2">
       <>
        <Button
         bg="#C3C7CD"
         w="202px"
         h="59"
         borderRadius="50"
         fontSize="18"
         color="white"
         _hover={{ bgColor: "#bcbfc4" }}
         onClick={() => router.goBack()}
        >
         Back
        </Button>
        <Button
         bg="#0072CF"
         w="202px"
         h="59"
         borderRadius="50"
         fontSize="18"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         onClick={compareAction}
        >
         Next
        </Button>
       </>
      </Flex>
     </Box>
    </Flex>
   </Box>
  </Box>
 );
};

export default BusinessCase;
