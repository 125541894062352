import { ChangeEvent, useRef } from "react";
import {
 FormControl,
 FormLabel,
 FormErrorMessage,
 FormHelperText,
 Stack,
} from "@chakra-ui/react";
import Select from "react-select";
import { useField } from "formik";
import { useErrorFocus } from "hooks";
const SelectWithAutoComplete = ({
 name = "",
 id = "",
 label = "",
 code = "",
 placeholder = "",
 thirdPartyId = "",
 description = "",
 defaultValue = "",
 isDisabled = false,
 isReadOnly = false,
 isClearable = false,
 isSearchable = true,
 isRequired = false,
 isCustomField = false,
 options = [],
 width = 0,
 minWidth = 0,
 maxWidth = 0,
 isMulti = false,
 noBorder = false,
 isLoading = false,
 insideTable = false,
 mt = 0,
 onChange = (e: ChangeEvent<HTMLInputElement>) => {},
 ...props
}) => {
 const [{ value }, meta, { setValue }] = useField(name);
 const selectRef = useRef(null);
 useErrorFocus(selectRef, name);
 return (
  <FormControl
   id={id}
   isInvalid={Boolean(meta.error && meta.touched)}
  //  isDisabled={isDisabled}
   isReadOnly={isReadOnly}
   isRequired={isRequired}
  >
   <Stack spacing={2} direction="row">
    {label && (
     <FormLabel
      alignSelf="center"
      mr="1"
      minW="41%"
      fontWeight="bold"
      textTransform="capitalize"
      opacity="1!important"
     >
      {label}
     </FormLabel>
    )}
    <Select
     className={isMulti ? "basic-multi-select" : "basic-single"}
     classNamePrefix="select"
     isClearable={isClearable}
     isSearchable={isSearchable}
     isMulti={isMulti}
     name={name}
     menuPortalTarget={insideTable ? document.body : null}
     placeholder={isLoading ? "Loading":placeholder || "Please select.."}
     value={
      isMulti
       ? value
       : value
       ? options.filter((item: any) =>
          item.value.toString().includes(value.toString())
         )
       : defaultValue
       ? options.filter((item: any) =>
          item.value.toString().includes(defaultValue.toString())
         )
       : ""
     }
     isDisabled={isDisabled}
     options={options}
     onChange={(e: any) => {
      setValue(e.value);
      if (onChange) {
       onChange(e);
      }
     }}
     styles={{
      control: (base) => ({
       ...base,
       minHeight: 51,
       pointerEvents: "auto",
       cursor: isDisabled ? "not-allowed !important" : "" ,
       minWidth: `${minWidth} !important`,
       width: width ? width : 190,
       marginTop: mt,
       border: noBorder ? 0 : "1px solid rgba(216, 216, 217, 1)",
       backgroundColor: "rgba(216, 216, 217, 0.36) !important",
      }),
      option: (base) => ({
        ...base,
        cursor: isDisabled ? "not-allowed !important" : "",
      }),
      indicatorSeparator: (base) => ({
        ...base,
       width: 0,
       cursor: isDisabled ? "not-allowed !important" : "",
      }),
      menu : (base)=> ({
        ...base,
        zIndex: 9999
      })
     }}
     {...props}
    />
    {description && <FormHelperText>{description}</FormHelperText>}
   </Stack>
   <Stack spacing={4} direction="column-reverse" align={"end"}>
    {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
   </Stack>
  </FormControl>
 );
};

export default SelectWithAutoComplete;
