import { Box } from '@chakra-ui/react';
import { FC, ChangeEvent } from 'react';
import Select, {components} from 'react-select';

interface MultiSelectProps {
    data?: any
    valueDisplayed?: number
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const MultiSelectCheckBox: FC<MultiSelectProps> = ({ data, valueDisplayed = 1, onChange }) => {
    const maxToShow = valueDisplayed;
    const MoreSelectedBadge = ({ items }: any) => {
        const style = {
        marginLeft: "auto",
        background: "#d4eefa",
        borderRadius: "4px",
        fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        order: 99
        };
    
        const title = items.join(", ");
        const length = items.length;
        const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;
    
        return (
        <div style={style} title={title}>
            {label}
        </div>
        );
    };
    
    const MultiValue = ({ index, getValue, ...props }: any) => {
      const overflow = getValue()
        .slice(maxToShow)
        .map((x:any) => x.label);
    
      return index < maxToShow ? (
        <components.MultiValue {...props} />
      ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
      ) : null;
    };
    
    return (
        <Box h="45px">
            <Select
              closeMenuOnSelect={false}
              isMulti
              noOptionsMessage={() => null} 
              options={data}
              onChange={(e:any) => onChange(e)}
              components={{ MultiValue }}
            />
        </Box>
    )
}

export default MultiSelectCheckBox