import { ReactElement, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
 Box,
 Button,
 Spacer,
 Flex,
 Text,
 Spinner,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalFooter,
 ModalBody,
 ModalCloseButton,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
} from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import Swal from "sweetalert2";
import { useAppDispatch } from "hooks";
import { actions as pricingCostDriverAction } from "state/pricings/costDrivers/slice";
import { actions as pricingCostSummaryAction } from "state/pricings/costSummary/slice";

const ToolbarBottom = ({
 costAnalysis,
 costAnalysisId,
 resetForm,
}: any): ReactElement => {
 const dispatch = useAppDispatch();
 const history = useHistory();
 const location = useLocation();
 const searchParams = new URLSearchParams(location.search);
 const [auditLog, setAuditLog] = useState<boolean>(false);

 const currentPageName = location.pathname.split("/")[2];
 const pricingId = location.pathname.split("/")[3];

 const {
  isEditing,
  dataToSend,
  isSimulate,
  valueIsIncludeCalculation,
  changeRowSimulate,
  detailPerVolumeId,
  loadingReport,
  status
 } = useSelector(
  (state: RootState) => ({
   volumeId: state.pricingCostDrivers.volumeId,
   isEditing: state.pricingCostDrivers.isEditing,
   isSimulate: state.pricingCostDrivers.isSimulate,
   dataToSend: state.pricingCostDrivers.dataToSend,
   costAnalysisPerVolumeId: state.pricingCostDrivers.costAnalysisPerVolumeId,
   valueIsIncludeCalculation:
    state.pricingCostDrivers.valueIsIncludeCalculation,
   changeRowSimulate: state.pricingCostDrivers.tmpChangeRow,
   detailPerVolumeId: state.pricingCostDrivers.detailPerVolumeId.result,
   loadingReport: state.pricingCostDrivers.loadingReport,
   status: state.pricingCostDrivers.status
  }),
  shallowEqual
 );

 var mainCostDrivers = costAnalysis.costDrivers
  .filter((i: any) => i.parentCostDriver === null)
  .sort((a: any, b: any) =>
   a.configuration.sequence < b.configuration.sequence ? -1 : 1
  );

 var componentConfigurations: any = [];

 var componentCostSummary = {
  sequence: 1,
  name: "cost-summary-by-sku",
  displayName: "Cost Summary",
 };

 var componentGeneralData = {
  sequence: 2,
  name: "sku-characteristic",
  displayName: "General Data",
 };

 const openAuditLog = () => {
  setAuditLog(true);
 };

 componentConfigurations.push(componentCostSummary);
 componentConfigurations.push(componentGeneralData);

 var pageNumber = 3;
 mainCostDrivers.map((costDriver: any, index: number) => {
  const component = {
   sequence: pageNumber + index,
   name: costDriver.configuration.identityName
    .replace(/\s+/g, "-")
    .toLowerCase(),
   description: costDriver.configuration.identityName,
  };
  componentConfigurations.push(component);
 });

 const getCurrentComponentSequence = () => {
  var currentComponent = componentConfigurations.find(
   (i: any) => i.name == currentPageName
  );
  if (!currentComponent) return 1;
  else return currentComponent.sequence;
 };

 const getComponentConfigurationBySequence = (sequence: any) => {
  return componentConfigurations.find((i: any) => i.sequence == sequence);
 };

 const navigateToPrevComponent = () => {
  var currentSequence = getCurrentComponentSequence();
  var prevComponent = getComponentConfigurationBySequence(currentSequence - 1);
  history.push(`/pricings/${prevComponent.name}/${costAnalysisId}`);
  window.scrollTo(0, 0);
 };

 const navigateToNextComponent = () => {
  var currentSequence = getCurrentComponentSequence();
  var nextComponent = getComponentConfigurationBySequence(currentSequence + 1);

  history.push(`/pricings/${nextComponent.name}/${costAnalysisId}`);
  window.scrollTo(0, 0);
 };

 const isFirstComponent = () => {
  var currentSequence = getCurrentComponentSequence();
  return currentSequence == 1;
 };

 const isLastComponent = () => {
  var currentSequence = getCurrentComponentSequence();
  return currentSequence == componentConfigurations.length;
 };

 let isDisabled = false;

 changeRowSimulate.map((item: any) => {
  if (item.before === undefined && changeRowSimulate.length === 1) {
   isDisabled = true;
  }
 });

 return (
  <Box
   position="fixed"
   bottom="0"
   right="0"
   backgroundColor="#ffffff"
   height="4.5rem"
   w="100%"
  >
   <Flex>
    <Box ml="19rem" mt={5}>
     {(isSimulate || isEditing) && valueIsIncludeCalculation && (
      <Flex alignItems="center" gap="2">
       <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="sm"
       />
       <Text>Processing…</Text>
      </Flex>
     )}
    </Box>
    <Spacer />
    <Box mt={4} mr="12rem">
     {isEditing || isSimulate ? (
      <>
       <Button
        bg="#0072CF"
        w="136px"
        h="40px"
        mr="4"
        isDisabled={valueIsIncludeCalculation}
        borderRadius="50"
        fontSize="16"
        color="white"
        _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
        onClick={() => {
         Swal.fire({
          showCancelButton: true,
          html:
           "The changes that you have made will be lost. Are you sure you want to proceed?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
         }).then(async (result) => {
          if (result.isConfirmed) {
           if (resetForm) {
            resetForm();
           }
           history.push("/simulation-by-sku");
           dispatch(pricingCostDriverAction.clear());
           dispatch(pricingCostSummaryAction.clear());
           dispatch(pricingCostDriverAction.setIsEditing(false));
           dispatch(pricingCostDriverAction.setIsSimulate(false));
           window.location.reload();
          }
         });
        }}
       >
        Exit
       </Button>
       {isSimulate && (
        <Button
         bg="#0072CF"
         w="136px"
         h="40px"
         mr="4"
         borderRadius="50"
         isDisabled={valueIsIncludeCalculation}
         fontSize="16"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         onClick={async () => {
          Swal.fire({
           showCancelButton: true,
           html:
            "The changes that you have made will be lost. Are you sure you want to reset the simulation?",
           confirmButtonText: "Yes",
           cancelButtonText: "No",
          }).then(async (result) => {
           if (result.isConfirmed) {
            if (resetForm) {
             resetForm();
            }
            // await dispatch(pricingCostDriverAction.clear());
            await dispatch(pricingCostDriverAction.setIsSimulate(true));
            const payload2 = {
             costAnalysisId: pricingId,
             perVolumeId: detailPerVolumeId?.id,
            };
            await dispatch(pricingCostDriverAction.resetTmpChangeRow());
            await dispatch(
             //  pricingCostSummaryAction.pricingCostSummary(payload2)
             pricingCostDriverAction.getDetailCostDriversVolume(payload2)
            );
           }
          });
         }}
        >
         Reset
        </Button>
       )}

       <Button
        mr="1rem"
        bg={loadingReport ? "#C3C7CD" : "#0072CF"}
        w="160px"
        h="40px"
        borderRadius="50"
        isDisabled={
         changeRowSimulate.length === 0 ||
         //  isOpenViewChange(changeRowSimulate) ||
         valueIsIncludeCalculation ||
         isDisabled
        }
        fontSize="16"
        color="white"
        _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
        _disabled={{
         bgColor: "#C3C7CD",
         cursor: "not-allowed",
         color: "white",
         border: "solid 1px white",
         _hover: { bgColor: "#bcbfc4" },
        }}
        onClick={async () => {
         let value = {
          ...dataToSend,
         };
         await dispatch(pricingCostDriverAction.simulationReport(value));
        }}
       >
        {loadingReport ? "Downloading..." : "Download Report"}
       </Button>
       <Button
        bg="#0072CF"
        w="160px"
        h="40px"
        borderRadius="50"
        isDisabled={
         changeRowSimulate.length === 0 ||
         //  isOpenViewChange(changeRowSimulate) ||
         valueIsIncludeCalculation ||
         isDisabled
        }
        fontSize="16"
        color="white"
        _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
        _disabled={{
         bgColor: "#C3C7CD",
         cursor: "not-allowed",
         color: "white",
         border: "solid 1px white",
         _hover: { bgColor: "#bcbfc4" },
        }}
        onClick={async () => {
         let value = {
          ...dataToSend,
         };

         if (isEditing) {
          const payload2 = {
           id: pricingId,
           status: status
          };
          await dispatch(
           pricingCostDriverAction.updateAsNewVersion(value)
          ).then(async (response: any) => {
           if (response?.payload) {
            await dispatch(pricingCostDriverAction.clear());
            dispatch(pricingCostDriverAction.setIsEditing(false));
            dispatch(pricingCostDriverAction.setIsSimulate(false));
            await dispatch(
             pricingCostSummaryAction.pricingCostSummary(payload2)
            );
           }
          });
         }

         if (isSimulate) {
          openAuditLog();
         }
        }}
       >
        View Changes
       </Button>
      </>
     ) : (
      <>
       {!isFirstComponent() && (
        <Button
         bg="#bcbfc4"
         w="136px"
         h="40px"
         mr="4"
         borderRadius="50"
         fontSize="16"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         onClick={navigateToPrevComponent}
        >
         Back
        </Button>
       )}
       {!isLastComponent() && (
        <Button
         bg="#0072CF"
         w="136px"
         h="40px"
         borderRadius="50"
         fontSize="16"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         onClick={navigateToNextComponent}
        >
         Next
        </Button>
       )}
      </>
     )}
    </Box>
   </Flex>
   <Modal
    closeOnOverlayClick={false}
    isOpen={auditLog}
    onClose={() => setAuditLog(false)}
    motionPreset="slideInBottom"
    scrollBehavior="inside"
    size="xl"
   >
    <ModalOverlay />
    <ModalContent minW="60%" borderRadius="25px" p="1rem">
     <ModalHeader>Simulation by SKU - Changes Log</ModalHeader>
     <ModalCloseButton mr="1rem" mt="1rem" />
     <ModalBody
      sx={{
       "&::-webkit-scrollbar": { width: "6px", height: "6px" },
       "&::-webkit-scrollbar-track": { width: "6px", height: "6px" },
       "&::-webkit-scrollbar-thumb": {
        borderRadius: "24px",
        backgroundColor: "#0072CF",
       },
      }}
     >
      <TableContainer>
       <Table variant="mytable">
        <Thead borderBottom="1px solid #D7DBE2">
         <Tr>
          <Th>Cost Drivers</Th>
          <Th>Components</Th>
          <Th textAlign="right" isNumeric>
           Change From
          </Th>
          <Th textAlign="right" isNumeric>
           Change To
          </Th>
         </Tr>
        </Thead>
        <Tbody>
         {changeRowSimulate.length > 0 &&
          changeRowSimulate.map((item: any, index: number) => {
           if (item.before)
            return (
             <Tr key={index}>
              <Td>{item.costDriver}</Td>
              <Td>{item.component}</Td>
              <Td textAlign="right">{item.after || 0}</Td>
              <Td textAlign="right">{item.before}</Td>
             </Tr>
            );
          })}
        </Tbody>
       </Table>
       <Table variant="mytable">
        <Tbody>
         <Tr>
          <Td width="100vh" textAlign="right" fontWeight={600}>
           Total Unit Cost (Current) :
          </Td>
          <Td textAlign="right" fontWeight={600}>
           {isNaN(detailPerVolumeId?.currentUnitCost)
            ? String.toFormatSeparator(detailPerVolumeId?.unitCost)
            : String.toFormatSeparator(detailPerVolumeId?.currentUnitCost)}
          </Td>
         </Tr>
         <Tr>
          <Td textAlign="right" fontWeight={600}>
           Total Unit Cost (What-If) :
          </Td>
          <Td textAlign="right" fontWeight={600}>
           {isNaN(detailPerVolumeId?.whatIfUnitCost)
            ? "-"
            : String.toFormatSeparator(detailPerVolumeId?.whatIfUnitCost)}
          </Td>
         </Tr>
        </Tbody>
       </Table>
      </TableContainer>
     </ModalBody>

     <ModalFooter></ModalFooter>
    </ModalContent>
   </Modal>
  </Box>
 );
};

export default ToolbarBottom;
