import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpService from "services/http-service";
import parameterDto from "./dtos";

export const getComponent = createAsyncThunk(
  'massUpdate/getComponent',
  async (params: parameterDto) => {
    const response: any = await HttpService.get(`/MassUpdate/PricingComponents`, { params: params });
    return response.result;
  }
)

export const postComponent = createAsyncThunk(
  'massUpdate/postComponent',
  async (payload: any) => {
    const response: any = await HttpService.post(`/MassUpdate/PricingCostDrivers`, payload);
    return response.result;
  }
)
export const postDownloadComponent = createAsyncThunk(
  'massUpdate/postDownloadComponent',
  async (payload: any) => {
    const response: any = await HttpService.post(`/MassUpdate/PricingCostDriverTemplate`, payload, {
      responseType: "blob",
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new window.Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Optipedia Mass Update Template.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
     });;
    return response;
  }
)

export const postComponentChanges = createAsyncThunk(
  'massUpdate/postComponentChanges',
  async (payload: any) => {
    const config = {
      headers: {
       notUseToast: true,
      },
     };
    const response: any = await HttpService.post(`/MassUpdate/PricingUpdate`, payload, config);
    return response;
  }
)

export const postUploadComponentChanges = createAsyncThunk(
  'massUpdate/postUploadComponentChanges',
  async (payload: any) => {
    const config = {
      headers: {
       notUseToast: true,
      },
     };
     const formData = new FormData();
     const file = payload.file[0] as any;
     const costDriverIds = payload.costDriverIds;
     const thirdPartyIds = payload.thirdPartyIds;
     formData.append("file", file);
     formData.append("costDriverIds", costDriverIds);
     formData.append("thirdPartyIds", thirdPartyIds)
    const response: any = await HttpService.post(`/MassUpdate/Upload`, formData, config);
    return response;
  }
)

