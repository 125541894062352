import { ReactElement } from "react";
import Layout from "views/Layout";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { actions as productConfigurationsActions } from "state/productConfigurations/slice";
import { Link, useLocation, Redirect } from "react-router-dom";
import { Box, Heading, VStack } from "@chakra-ui/layout";
import { Image, Button, Flex, Spacer } from "@chakra-ui/react";
import SearchBar from "components/SearchBar";
import ListTable from "../Journeys/ListTable";
import Bulp from "assets/images/Ideas.png";
import Question from "assets/images/Group30.png";

const Home = (): ReactElement => {
 let location = useLocation();
 const dispatch = useAppDispatch();

 const { loading, search, listJourney } = useSelector(
  (state: RootState) => ({
   listJourney: state.productConfigurations.result.items,
   loading: state.productConfigurations.loading,
   search: state.productConfigurations.searchQuery,
  }),
  shallowEqual
 );

 if (location.pathname === "/") {
  return <Redirect to="/journeys" />;
 }

 const handleChangeSearchKey = (event: any) => {
  dispatch(
   productConfigurationsActions.setSearchQuery({
    value: event.currentTarget.value,
   })
  );
 };

 return (
  <Layout>
   <Heading as="h1" fontSize="24px" marginBottom="30px">
    Journey
   </Heading>
   <Flex>
    <Box
     maxW="550px"
     color="white"
     fontSize="14px"
     textColor="#43464B"
    >
     Let us take you on a journey to find out how much it should cost you to buy or manufacture your products. Each specific product category in your portfolio can go on a different journey, based on what level of maturity your category is at.
    </Box>
    <Spacer />
   <Box
    maxW="420px"
    h="58px"
    // ml="2rem"
    bg="#F7F9FD"
    p={2}
    color="white"
    borderRadius="lg"
    boxShadow="0px 0px 38px #E6EAF1"
   >
    <Box display="flex" alignItems="flex-start">
     <Box mt={2}>
      <Image src={Bulp} w="10" alt="Bulp" />
     </Box>
     <Box as="p" fontSize="13px" ml="4" textColor="#7B7F85" fontWeight="500">
      You know how much it{" "}
      <Box as="span" color="#0072CF">
       does cost
      </Box>{" "}
      to buy or manufacture your product, but do you know how much it{" "}
      <Box as="span" color="#0072CF">
       should cost
      </Box>
      ?
     </Box>
    </Box>
   </Box>
   </Flex>
    <ListTable />
   <Box
   position="fixed"
   bottom="10"
   right="10"
   backgroundColor="#ffffff"
  >
    <Link to="/journeys/create?page=level-overview">
      <Button
       zIndex="9"
       bg="#0072CF"
       w="214px"
       h="54"
       fontSize="16px"
       color="white"
       _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
      >
       Start journey
      </Button>
     </Link>
   </Box>
  </Layout>
 );
};

export default Home;