import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpService from "services/http-service";

export const getUserInfo = createAsyncThunk(
  'auth/getUserInfo',
  async () => {
    const response: any = await HttpService.get('me');
    return response.result
  }
);
