import { FC, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TableLoading } from "components/Loading";
import {
 Box,
 Button,
 Flex,
 Table,
 Thead,
 Tr,
 Th,
 Td,
 Tbody,
 Input,
 InputGroup,
 InputRightElement,
 Select,
} from "@chakra-ui/react";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import "utils/date-utils";
import { Formik } from "formik";

import { isEmpty } from "lodash";
interface Values {
 thirdPartyId: string;
}

const TABLE_HEADERS = [
 { name: "name", label: "User Name" },
 { name: "email", label: "Email" },
 { name: "thirdParty", label: "Third-Party" },
];

const data = [{ name: "-", email: "user@mail.com", thirdParty: "-" }];

const ThirdPartyTableEdit: FC = () => {
 const history = useHistory();
 const params: any = useParams();
 const dispatch = useAppDispatch();
 let { loading, thirdPartyUserById, thirdParty } = useSelector(
  (state: RootState) => ({
   loading: state.thirdPartySetting.loading,
   thirdPartyUserById: state.thirdPartySetting.thirdPartyUserById,
   thirdParty: state.thirdPartySetting.thirdParty,
  }),
  shallowEqual
 );
 const fetchListOf3rdParty = (skipCount: number) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: 100,
  };
  return dispatch(thirdPartySettingAction.getThirdPartyToShow(payload));
 };

 useEffect(() => {
  dispatch(thirdPartySettingAction.getThirdPartyUserById(params.id));

  fetchListOf3rdParty(0);
 }, []);
 return (
  <Formik
   enableReinitialize
   initialValues={{ thirdPartyId: thirdPartyUserById?.thirdParty?.id || "" }}
   onSubmit={(values: Values) => {
    const payload = {
     id: params.id,
     data: values,
    };
    dispatch(thirdPartySettingAction.editThirdPartyUserById(payload)).then(
     (i) => history.push("/settings/third-party/user-management")
    );
   }}
  >
   {({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
   }) => (
    <form onSubmit={handleSubmit}>
     <Box>
      <Table variant="mytable" colorScheme="facebook">
       <Thead borderBottom="1px solid #D7DBE2">
        <Tr>
         {TABLE_HEADERS.map((item: any, index: number) => (
          <Th
           key={index}
           textTransform="capitalize"
           color="#9DA1A7"
           fontSize="16px"
          >
           {item.label}
          </Th>
         ))}
        </Tr>
       </Thead>
       <Tbody>
        <Tr>
         <Td>{thirdPartyUserById?.user?.userName}</Td>
         <Td>{thirdPartyUserById?.user?.emailAddress}</Td>
         <Td>
          <InputGroup>
           <InputRightElement
            pt="9px"
            pointerEvents="none"
            children={<ArrowDownIcon color="gray.300" />}
           />
           <Select
            color="grey"
            h={50}
            border="1px solid #0072CF"
            name="thirdPartyId"
            value={values.thirdPartyId}
            defaultValue={values.thirdPartyId}
            onChange={handleChange}
            borderRadius="14px"
           >
            {!isEmpty(thirdParty) &&
             thirdParty.items.map((item: any, index: number) => (
              <option key={index} value={item.id}>
               {item.code}
              </option>
             ))}
           </Select>
          </InputGroup>
         </Td>
        </Tr>
       </Tbody>
      </Table>
      <Box
       position="fixed"
       bottom="0"
       backgroundColor="#ffffff"
       height="5.5rem"
       w="100%"
       zIndex={2}
      >
       <Flex float="right" mr="20rem">
        <Box mt={4}>
         <Flex alignItems="center" gap="2">
          <>
           <Button
            bg="#C3C7CD"
            w="202px"
            h="59"
            borderRadius="50"
            fontSize="18"
            color="white"
            _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
            onClick={() => history.goBack()}
           >
            Back
           </Button>
           <Button
            bg="#0072CF"
            w="202px"
            h="59"
            borderRadius="50"
            fontSize="18"
            color="white"
            _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
            type="submit"
           >
            Save
           </Button>
          </>
         </Flex>
        </Box>
       </Flex>
      </Box>
     </Box>
    </form>
   )}
  </Formik>
 );
};

export default ThirdPartyTableEdit;