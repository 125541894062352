import { createSlice } from "@reduxjs/toolkit";
import {
 listMultiCurrency,
 deletes,
 add,
 edit,
 upload,
 getAllCurrencies,
 downloadTemplate,
 addExchange,
 editExchange,
 listExchange,
 deletesExchange,
 uploadExchange,
 downloadTemplateExchange,
 validCurrencies,
 exchangeDetail
} from "./thunk";
interface InitialState {
 searchQuery: string;
 exchangeUpload: boolean;
 exchangeCreated: boolean;
 exchangeUpdated: boolean;
 exchangeView: boolean;
 exchangeDataToEdit: any;
 currencies: any;
 currencyUpload: boolean;
 currencyCreated: boolean;
 currencyUpdated: boolean;
 currencyView: boolean;
 currencyDataToEdit: any;
 lists: any;
 listsExchange: any;
 newListsExchange: any;
 loading: boolean;
 error: unknown;
 successUpload: boolean;
 successDownload: boolean;
 listsValidCurrencies: any;
 exchangeRateDetail: any;
}

const initialState: InitialState = {
 searchQuery: "",
 exchangeUpload: false,
 exchangeCreated: false,
 exchangeUpdated: false,
 currencies: {},
 currencyUpload: false,
 currencyCreated: false,
 currencyUpdated: false,
 exchangeDataToEdit: {},
 exchangeView: false,
 currencyView: false,
 currencyDataToEdit: {},
 lists: {
 },
 listsExchange: {
  result: []
 },
 newListsExchange: {
  items: []
 },
 loading: false,
 error: null,
 successUpload: false,
 successDownload: false,
 listsValidCurrencies: [],
 exchangeRateDetail: {}
};

const masterDataConfigurationSlice = createSlice({
 name: "multiCurrency",
 initialState,
 reducers: {
  clear: () => initialState,
  setSearchQuery: (state, action) => {
   const { value } = action.payload;
   state.searchQuery = value;
  },
  resetPage: (state, action) => {
   state.exchangeUpload = action.payload;
   state.exchangeCreated = action.payload;
   state.exchangeUpdated = action.payload;
   state.exchangeView = action.payload;

   state.currencyUpload = action.payload;
   state.currencyCreated = action.payload;
   state.currencyUpdated = action.payload;
   state.currencyView = action.payload;

   state.exchangeDataToEdit = {};
  },
  setExchangeUpload: (state, action) => {
   state.exchangeUpload = action.payload;
  },
  setExchangeCreated: (state, action) => {
   state.exchangeCreated = action.payload;
  },
  setExchangeUpdated: (state, action) => {
   state.exchangeUpdated = action.payload;
  },
  setExchangeView: (state, action) => {
   state.exchangeView = action.payload;
  },
  setExchangeDataToEdit: (state, action) => {
   state.exchangeDataToEdit = action.payload;
  },
  setCurrencyUpload: (state, action) => {
   state.currencyUpload = action.payload;
  },
  setCurrencyCreated: (state, action) => {
   state.currencyCreated = action.payload;
  },
  setCurrencyUpdated: (state, action) => {
   state.currencyUpdated = action.payload;
  },
  setCurrencyView: (state, action) => {
   state.currencyView = action.payload;
  },
  setCurrencyDataToEdit: (state, action) => {
   state.currencyDataToEdit = action.payload;
  },
  updateSuccessUpload: (state, action) => {
   state.successUpload = action.payload;
  },
 },
 extraReducers: (builder) => {
  // GET ALL CURRENCIES
  builder.addCase(getAllCurrencies.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getAllCurrencies.fulfilled, (state, action) => {
   state.loading = false;
   state.currencies = action.payload
  });

  builder.addCase(getAllCurrencies.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  // ADD
  builder.addCase(add.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(add.fulfilled, (state, action) => {
   state.loading = false;
  });

  builder.addCase(add.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // ADD Exchange
  builder.addCase(addExchange.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
 
   builder.addCase(addExchange.fulfilled, (state, action) => {
    state.loading = false;
   });
 
   builder.addCase(addExchange.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });
  // EDIT Currencies

  builder.addCase(edit.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(edit.fulfilled, (state, action) => {
   state.loading = false;
  });

  builder.addCase(edit.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // EDIT Exchange

  builder.addCase(editExchange.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(editExchange.fulfilled, (state, action) => {
   state.loading = false;
  });

  builder.addCase(editExchange.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // LIST Currencies
  builder.addCase(listMultiCurrency.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(listMultiCurrency.fulfilled, (state, action) => {
   state.lists = action.payload;
   state.loading = false;
  });

  builder.addCase(listMultiCurrency.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // LIST Exchange
  builder.addCase(listExchange.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(listExchange.fulfilled, (state, action) => {
   state.listsExchange = action.payload;
   state.newListsExchange = action.payload;
   state.loading = false;
  });

  builder.addCase(listExchange.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // DELETE Currencies
  builder.addCase(deletes.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(deletes.fulfilled, (state, action) => {
   state.loading = false;
  });
  builder.addCase(deletes.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // DELETE Exchange
  builder.addCase(deletesExchange.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
   builder.addCase(deletesExchange.fulfilled, (state, action) => {
    state.loading = false;
   });
   builder.addCase(deletesExchange.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });

  // UPLOAD Currencies
  builder.addCase(upload.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(upload.fulfilled, (state, action) => {
   state.successUpload = action.payload;
   state.loading = false;
  });

  builder.addCase(upload.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // UPLOAD Exchange
  builder.addCase(uploadExchange.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
 
   builder.addCase(uploadExchange.fulfilled, (state, action) => {
    state.successUpload = action.payload;
    state.loading = false;
   });
 
   builder.addCase(uploadExchange.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });

  // DOWNLOAD Currencies
  builder.addCase(downloadTemplate.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(downloadTemplate.fulfilled, (state, action) => {
   state.loading = false;
  });

  builder.addCase(downloadTemplate.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // DOWNLOAD Exchange
  builder.addCase(downloadTemplateExchange.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
 
   builder.addCase(downloadTemplateExchange.fulfilled, (state, action) => {
    state.loading = false;
   });
 
   builder.addCase(downloadTemplateExchange.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });
   //GET VALID CURRENCY
   builder.addCase(validCurrencies.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
   builder.addCase(validCurrencies.fulfilled, (state, action) => {
    state.listsValidCurrencies = action.payload;
    state.loading = false;
   });
 
   builder.addCase(validCurrencies.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });

   //GET EXCHANGE ID
   builder.addCase(exchangeDetail.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
   builder.addCase(exchangeDetail.fulfilled, (state, action) => {
    state.exchangeRateDetail = action.payload;
    state.loading = false;
   });
 
   builder.addCase(exchangeDetail.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });
 },
});

export const actions = {
 ...masterDataConfigurationSlice.actions,
 add,
 edit,
 listMultiCurrency,
 deletes,
 upload,
 getAllCurrencies,
 downloadTemplate,
 addExchange,
 editExchange,
 listExchange,
 deletesExchange,
 uploadExchange,
 downloadTemplateExchange,
 validCurrencies,
 exchangeDetail
};

export const reducer = masterDataConfigurationSlice.reducer;
