import { ReactElement, useEffect, useState, useRef } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { StepperLoading } from "components/Loading";
import "utils/number-utils";
import { useHistory, useLocation } from "react-router-dom";
import LeftIcon from "assets/images/arrow-left-stepper.png";
import RightIcon from "assets/images/arrow-right-stepper.png";
import { Flex, Box, Circle, Image } from "@chakra-ui/react";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import { actions as pricingCostDriverActionBySKU } from "state/pricings/costDrivers/slice";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation } from "swiper";
import Swal from "sweetalert2";
import "swiper/swiper-bundle.css";
import { pricingCostSummary } from "state/pricings/costSummary/thunk";

const Stepper = (): ReactElement => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation<{
    status: number;
    volumeId: string;
    isEditing: boolean;
    isApproval: boolean;
  }>();

  const pricingId = location.pathname.split("/")[3];
  const pricingPage = location.pathname.split("/")[2];
  const startSequence = 2;
  const costSummarySequence = 0;
  const generalDataSequence = 1;
  const [data, setData] = useState<any>({});
  const [indexStepper, setIndexStepper] = useState<number>(0);
  const currentSeq: any = [];

  const stepperReff = useRef<null | HTMLDivElement>(null);
  const scrollToStepper = () => {
    stepperReff.current?.scrollIntoView({ behavior: "smooth" });
  };
  const {
    costAnalysis,
    volumes,
    levelName,
    costAnalysisPerVolume,
    volumeId,
    loading,
    dataToSendAdds,
    prevPage,
    dataToSendDelete,
    isDisable,
    dataToSend
  } = useSelector(
    (state: RootState) => ({
      costAnalysis: state.pricingCostSummary.costSummaryById,
      volumes: state.pricingCostSummary.costSummaryById.volumes,
      levelName: state.pricingCostSummary.costSummaryById.levelName,
      costAnalysisPerVolume: state.editPricingCostDrivers.detailPerVolumeId.result,
      volumeId: state.editPricingCostDrivers.volumeId,
      loading: state.pricingCostSummary.loading,
      dataToSendAdds: state.editPricingCostDrivers.dataToSend.adds,
      prevPage: state.editPricingCostDrivers.prevPage,
      dataToSendDelete: state.editPricingCostDrivers.dataToSend.deletes,
      isDisable: state.editPricingCostDrivers.isDisabled,
      dataToSend: state.editPricingCostDrivers.dataToSend
    }),
    shallowEqual
  );
  const searchParams = new URLSearchParams(location.search);
  const thirdPartyId = searchParams.get("thirdPartyId");

  useEffect(() => {
    if (prevPage !== "") {
      dataToSendAdds.map((item: any, index: number) => {
        const { customFields } = item;
        let isNull = false;
        Array.isArray(customFields) &&
          customFields.length > 0 &&
          customFields.map((itemCustomField) => {
            isNull = itemCustomField.value === "";
          });
      });
    }
  }, [pricingPage, dataToSendAdds, prevPage]);
  useEffect(() => {
    const newCostDrivers: any = [];
    costAnalysisPerVolume?.costDrivers?.map((item: any) => {
      if (item.parentCostDriver === null) {
        newCostDrivers.push(item);
      }
    });
  }, [costAnalysisPerVolume]);
  useEffect(() => {
    Array.isArray(volumes) &&
      volumes.length > 0 &&
      volumes.map((defaultVolume: any) => {
        let costDriverData = [] as any;
        const { costDrivers, id, volume } = defaultVolume;

        if (!volumeId) {
          const volumeIdValue = location.state.volumeId || id;
          dispatch(pricingCostDriverAction.setVolumeId(volumeIdValue));
        }
        dispatch(pricingCostDriverActionBySKU.setVolumeId(id));
        dispatch(pricingCostDriverAction.setLevelName(levelName));

        const costDriverDefaultVolume = [] as any;
        costDrivers.map((v: any, i: number) => {
          if (v.parentCostDriver === null) {
            costDriverDefaultVolume.push({
              sequence: v.configuration.sequence,
              data: v,
            });
          }
        });

        costDriverDefaultVolume.sort(function (a: any, b: any) {
          let keyA = a.sequence,
            keyB = b.sequence;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

        costDriverDefaultVolume.map((value: any) => {
          costDriverData.push(value.data);
        });
        setData(costDriverData);
      });
    if (!stepperReff) {
      scrollToStepper();
    }
  }, [volumes, loading, thirdPartyId]);

  const stepperData: any = [];
  Array.isArray(data) &&
    data.length > 0 &&
    data.map((value: any, index: number) => {
      const pageName = value.configuration.identityName
        .replace(/\s+/g, "-")
        .toLowerCase();
      const sequence = startSequence + index;
      const description = value.configuration.identityName;

      const newStepper = {
        sequence: sequence,
        pageName: pageName,
        description: description,
      };
      if (pageName === pricingPage) {
        currentSeq.push(sequence);
      }
      stepperData.push(newStepper);
    });

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    if (stepperData) {
      var stepperTo = stepperData.find((i: any) => i.pageName === pricingPage);
      setIndexStepper(stepperTo?.sequence);
    } else {
      setIndexStepper(0);
    }
  }, [pricingPage, stepperData]);

  const dataDefault = {
    ...dataToSend,
    adds: dataToSend.adds,
    // costAnalysisPerVolumeId: costAnalysisPerVolume?.id,
    currencyCode: costAnalysis?.currency?.code,
    currencyId: costAnalysis?.currency?.id,
    customFields: [],
    // deletes: dataToSend.deletes,
    description: costAnalysis?.description,
    formatCode: dataToSend.formatCode,
    name: costAnalysis?.name,
    uoMCode: costAnalysis?.uoM?.code,
    updates: dataToSend.updates,
    // volume: costAnalysisPerVolume?.volume
  }

  if (loading) {
    return (
      <Box
        pl="10"
        backgroundColor="#f7f9fd"
        borderRadius="40px"
        p="25px 3rem 19px 3rem"
      // w={levelName === "Does Cost" ? "35%" : "80%"}
      >
        <Box gap={7} display="flex" alignItems="center">
          <StepperLoading />
          <StepperLoading />
          <StepperLoading />
          <StepperLoading />
        </Box>
      </Box>
    );
  }
  const totalValue = levelName === "Does Cost" ? 3 : 2;
  const totalSlideStepper: number = stepperData.length + totalValue;
  // console.log("AB",dataDefault);
  // console.log("ABC",costAnalysis);

  return (
    <Box>
      {stepperData.length > 0 ? (
        <Box
          //  position="absolute"
          w={levelName === "Does Cost" ? "35%" : "80%"}
          //  h="105.5px"
          mt="2rem"
        >
          {!loading && (
            <>
              <Image
                src={LeftIcon}
                alt=""
                position="absolute"
                left="-30px"
                h="30px"
                cursor="pointer"
                zIndex="3"
                top="2rem"
                ref={prevRef}
              />
              <Image
                src={RightIcon}
                alt=""
                position="absolute"
                right="-30px"
                h="30px"
                cursor="pointer"
                zIndex="3"
                top="2rem"
                ref={nextRef}
              />
            </>
          )}

          {!loading && (
            <Swiper
              slidesPerView={totalSlideStepper}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              navigation={{
                prevEl: prevRef.current ? prevRef.current : undefined,
                nextEl: nextRef.current ? nextRef.current : undefined,
              }}
              modules={[Navigation]}
              className="mySwiper"
              onInit={(swiper: any) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              onSwiper={(swiper) => {
                swiper.slideTo(indexStepper, 1000);
              }}
            >
              {!loading && (
                <>
                  <SwiperSlide>
                    {!loading && (
                      //  <Box as="span">
                      <Flex>
                        <Box>
                          <Circle
                            size="20px"
                            bg={pricingPage === "cost-summary-by-sku" ? "#ffffff" : ""}
                            color="white"
                            border="1px solid #0072CF"
                          >
                            <Box as="span">
                              <Circle
                                size={pricingPage !== "cost-summary-by-sku" ? "30px" : "27px"}
                                cursor="pointer"
                                bg={"#0072CF"}
                                color="white"
                                fontWeight="600"
                                fontSize="12px"
                                ref={stepperReff}
                                onClick={() => {
                                  if (!isDisable) {
                                    Swal.fire({
                                      showCancelButton: true,
                                      html: "Any changes made for SKU Characteristic will be removed. Do  you want to proceed?",
                                      confirmButtonText: "Yes",
                                      cancelButtonText: "No",
                                    }).then(async (result) => {
                                      if (result.isConfirmed) {
                                        dispatch(pricingCostDriverAction.resetTmpChangeRow())
                                        const payload = {
                                          costAnalysisId: pricingId,
                                          perVolumeId: location.state.volumeId || volumeId,
                                         };
                                         await dispatch(pricingCostDriverAction.setDataToSendToDefault(dataDefault))
                                         dispatch(pricingCostDriverAction.setNeedReFetch(true))
                                         dispatch(pricingCostDriverAction.setDisable(true))
                                        history.push({
                                          pathname: `/pricings/cost-summary-by-sku/${pricingId}`,
                                          state: {
                                            status: location.state.status,
                                            volumeId: location.state.volumeId,
                                            isApproval: location.state.isApproval || null,
                                          },
                                        });
                                      }
                                    });
                                  } else {
                                    dispatch(pricingCostDriverAction.setIsEditOpen(false));
                                  dispatch(pricingCostDriverAction.setPrevPage(pricingPage));

                                  if (searchParams.get("thirdPartyId")) {
                                    history.push({
                                      pathname: `/pricings/cost-summary-by-sku/${pricingId}?thirdPartyName=${searchParams.get(
                                        "thirdPartyName"
                                      )}&thirdPartyId=${searchParams.get(
                                        "thirdPartyId"
                                      )}&defauldVolume=${searchParams.get("defauldVolume")}`,
                                      state: {
                                        status: location.state.status,
                                        volumeId: location.state.volumeId,
                                        isApproval: location.state.isApproval || null,
                                      },
                                    });
                                  } else {
                                    history.push({
                                      pathname: `/pricings/cost-summary-by-sku/${pricingId}`,
                                      state: {
                                        status: location.state.status,
                                        volumeId: location.state.volumeId,
                                        isApproval: location.state.isApproval || null,
                                      },
                                    });
                                  }
                                  if (searchParams.get("defauldVolume")) {
                                    if (searchParams.get("thirdPartyId")) {
                                      history.push({
                                        pathname: `/pricings/cost-summary-by-sku/${pricingId}?defauldVolume=${searchParams.get(
                                          "defauldVolume"
                                        )}&thirdPartyName=${searchParams.get(
                                          "thirdPartyName"
                                        )}&thirdPartyId=${searchParams.get("thirdPartyId")}`,
                                        state: {
                                          status: location.state.status,
                                          volumeId: location.state.volumeId,
                                          isApproval: location.state.isApproval || null,
                                        },
                                      });
                                    } else {
                                      history.push({
                                        pathname: `/pricings/cost-summary-by-sku/${pricingId}?defauldVolume=${searchParams.get(
                                          "defauldVolume"
                                        )}`,
                                        state: {
                                          status: location.state.status,
                                          volumeId: location.state.volumeId,
                                          isApproval: location.state.isApproval || null,
                                        },
                                      });
                                    }
                                  }
                                  }
                                }}
                              >
                                0
                              </Circle>
                            </Box>
                          </Circle>
                        </Box>
                        <Box
                          fontSize="13px"
                          cursor="pointer"
                          fontWeight="600"
                          pl="3"
                          color={pricingPage === "cost-summary-by-sku" ? "" : "#7B7F85"}
                          onClick={() => {
                            if (!isDisable) {
                              Swal.fire({
                                showCancelButton: true,
                                html: "Any changes made for SKU Characteristic will be removed. Do  you want to proceed?",
                                confirmButtonText: "Yes",
                                cancelButtonText: "No",
                              }).then(async (result) => {
                                if (result.isConfirmed) {
                                  dispatch(pricingCostDriverAction.resetTmpChangeRow())
                                  const payload = {
                                    costAnalysisId: pricingId,
                                    perVolumeId: location.state.volumeId || volumeId,
                                   };
                                   await dispatch(pricingCostDriverAction.setDataToSendToDefault(dataDefault))
                                   dispatch(pricingCostDriverAction.setNeedReFetch(true))
                                    dispatch(pricingCostDriverAction.setDisable(true))
                                  history.push({
                                    pathname: `/pricings/cost-summary-by-sku/${pricingId}`,
                                    state: {
                                      status: location.state.status,
                                      volumeId: location.state.volumeId,
                                      isApproval: location.state.isApproval || null,
                                    },
                                  });
                                }
                              });
                            }
                           else { dispatch(pricingCostDriverAction.setIsEditOpen(false));
                            dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
                            if (searchParams.get("thirdPartyId")) {
                              history.push({
                                pathname: `/pricings/cost-summary-by-sku/${pricingId}?thirdPartyName=${searchParams.get(
                                  "thirdPartyName"
                                )}&thirdPartyId=${searchParams.get(
                                  "thirdPartyId"
                                )}&defauldVolume=${searchParams.get("defauldVolume")}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            } else {
                              history.push({
                                pathname: `/pricings/cost-summary-by-sku/${pricingId}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            }

                            if (searchParams.get("defauldVolume")) {
                              history.push({
                                pathname: `/pricings/cost-summary-by-sku/${pricingId}?defauldVolume=${searchParams.get(
                                  "defauldVolume"
                                )}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            }}
                          }}
                        >
                          Cost Summary
                        </Box>
                        <Box
                          w="100px"
                          h="2px"
                          mt="0.5rem"
                          bgColor={
                            pricingPage === "sku-characteristic" ||
                              pricingPage === "final-cost"
                              ? "#0072CF"
                              : currentSeq > costSummarySequence
                                ? "#0072CF"
                                : "#D7DBE2"
                          }
                          ml="0.7rem"
                        />
                      </Flex>
                      //  </Box>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {!loading && (
                      <Flex>
                        <Box>
                          <Circle
                            size="20px"
                            bg={pricingPage === "sku-characteristic" ? "#ffffff" : ""}
                            color="white"
                            ml="0.5rem"
                            mr="0.5rem"
                            border={
                              pricingPage !== "sku-characteristic"
                                ? "none"
                                : "1px solid #0072CF"
                            }
                          >
                            <Box as="span">
                              <Circle
                                size={pricingPage !== "sku-characteristic" ? "30px" : "27px"}
                                cursor="pointer"
                                bg={
                                  pricingPage === "sku-characteristic" ||
                                    pricingPage === "final-cost"
                                    ? "#0072CF"
                                    : currentSeq > generalDataSequence
                                      ? "#0072CF"
                                      : "#D7DBE2"
                                }
                                color="white"
                                fontWeight="600"
                                fontSize="12px"
                                ref={stepperReff}
                                onClick={() => {
                                  dispatch(pricingCostDriverAction.setIsEditOpen(false));
                                  dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
                                  if (searchParams.get("thirdPartyId")) {
                                    history.push({
                                      pathname: `/pricings/sku-characteristic/${pricingId}?thirdPartyName=${searchParams.get(
                                        "thirdPartyName"
                                      )}&thirdPartyId=${searchParams.get("thirdPartyId")}`,
                                      state: {
                                        status: location.state.status,
                                        volumeId: location.state.volumeId,
                                        isApproval: location.state.isApproval || null,
                                      },
                                    });
                                  } else {
                                    history.push({
                                      pathname: `/pricings/sku-characteristic/${pricingId}`,
                                      state: {
                                        status: location.state.status,
                                        volumeId: location.state.volumeId,
                                        isApproval: location.state.isApproval || null,
                                      },
                                    });
                                  }
                                }}
                              >
                                1
                              </Circle>
                            </Box>
                          </Circle>
                        </Box>
                        <Box
                          fontSize="13px"
                          cursor="pointer"
                          fontWeight="600"
                          pl="3"
                          color={pricingPage === "sku-characteristic" ? "" : "#7B7F85"}
                          onClick={() => {
                            dispatch(pricingCostDriverAction.setIsEditOpen(false));
                            dispatch(pricingCostDriverAction.setPrevPage(pricingPage));

                            if (searchParams.get("thirdPartyName")) {
                              history.push({
                                pathname: `/pricings/sku-characteristic/${pricingId}?thirdPartyName=${searchParams.get(
                                  "thirdPartyName"
                                )}&thirdPartyId=${searchParams.get(
                                  "thirdPartyId"
                                )}&defauldVolume=${searchParams.get("defauldVolume")}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            } else {
                              history.push({
                                pathname: `/pricings/sku-characteristic/${pricingId}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            }

                            if (searchParams.get("defauldVolume")) {
                              history.push({
                                pathname: `/pricings/sku-characteristic/${pricingId}?defauldVolume=${searchParams.get(
                                  "defauldVolume"
                                )}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            }
                          }}
                        >
                          SKU Characteristic
                        </Box>
                        {levelName && levelName !== "Does Cost" && (
                          <Box
                            w="100px"
                            h="2px"
                            mt="0.5rem"
                            bgColor={
                              pricingPage !== "sku-characteristic" &&
                                pricingPage !== "cost-summary-by-sku"
                                ? "#0072CF"
                                : "#D7DBE2"
                            }
                            ml="0.7rem"
                          />
                        )}
                        {levelName && levelName === "Does Cost" && (
                          <Box
                            w="100px"
                            h="4px"
                            bgColor={
                              pricingPage !== "sku-characteristic" &&
                                pricingPage !== "cost-summary-by-sku"
                                ? "#0072CF"
                                : "#D7DBE2"
                            }
                            ml="0.7rem"
                          />
                        )}
                      </Flex>
                    )}
                  </SwiperSlide>
                  {levelName && levelName === "Does Cost" && (
                    <SwiperSlide>
                      {!loading && (
                        <Flex>
                          <Box>
                            <Circle
                              size="20px"
                              bg={pricingPage === "final-cost" ? "#ffffff" : ""}
                              color="white"
                              ml="0.5rem"
                              mr="0.5rem"
                              border={
                                pricingPage !== "final-cost" ? "none" : "1px solid #0072CF"
                              }
                            >
                              <Box as="span">
                                <Circle
                                  size={pricingPage !== "final-cost" ? "30px" : "27px"}
                                  cursor="pointer"
                                  bg={
                                    pricingPage === "final-cost"
                                      ? "#0072CF"
                                      : currentSeq > generalDataSequence
                                        ? "#0072CF"
                                        : "#D7DBE2"
                                  }
                                  color="white"
                                  fontWeight="600"
                                  fontSize="12px"
                                  ref={stepperReff}
                                  onClick={() => {
                                    dispatch(pricingCostDriverAction.setIsEditOpen(false));
                                    dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
                                    if (searchParams.get("thirdPartyId")) {
                                      history.push({
                                        pathname: `/pricings/final-cost/${pricingId}?thirdPartyName=${searchParams.get(
                                          "thirdPartyName"
                                        )}&thirdPartyId=${searchParams.get("thirdPartyId")}`,
                                        state: {
                                          status: location.state.status,
                                          volumeId: location.state.volumeId,
                                          isApproval: location.state.isApproval || null,
                                        },
                                      });
                                    } else {
                                      history.push({
                                        pathname: `/pricings/final-cost/${pricingId}`,
                                        state: {
                                          status: location.state.status,
                                          volumeId: location.state.volumeId,
                                          isApproval: location.state.isApproval || null,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  2
                                </Circle>
                              </Box>
                            </Circle>
                          </Box>
                          <Box
                            as="p"
                            fontSize="13px"
                            cursor="pointer"
                            fontWeight="600"
                            pl="3"
                            color={pricingPage === "final-cost" ? "" : "#7B7F85"}
                            onClick={() => {
                              dispatch(pricingCostDriverAction.setIsEditOpen(false));
                              dispatch(pricingCostDriverAction.setPrevPage(pricingPage));

                              if (searchParams.get("thirdPartyName")) {
                                history.push({
                                  pathname: `/pricings/final-cost/${pricingId}?thirdPartyName=${searchParams.get(
                                    "thirdPartyName"
                                  )}&thirdPartyId=${searchParams.get(
                                    "thirdPartyId"
                                  )}&defauldVolume=${searchParams.get("defauldVolume")}`,
                                  state: {
                                    status: location.state.status,
                                    volumeId: location.state.volumeId,
                                    isApproval: location.state.isApproval || null,
                                  },
                                });
                              } else {
                                history.push({
                                  pathname: `/pricings/final-cost/${pricingId}`,
                                  state: {
                                    status: location.state.status,
                                    volumeId: location.state.volumeId,
                                    isApproval: location.state.isApproval || null,
                                  },
                                });
                              }

                              if (searchParams.get("defauldVolume")) {
                                history.push({
                                  pathname: `/pricings/final-cost/${pricingId}?defauldVolume=${searchParams.get(
                                    "defauldVolume"
                                  )}`,
                                  state: {
                                    status: location.state.status,
                                    volumeId: location.state.volumeId,
                                    isApproval: location.state.isApproval || null,
                                  },
                                });
                              }
                            }}
                          >
                            Final Cost
                          </Box>
                          {levelName && levelName !== "Does Cost" && (
                            <Box
                              w="100px"
                              h="4px"
                              bgColor={
                                pricingPage !== "sku-characteristic" &&
                                  pricingPage !== "cost-summary-by-sku" &&
                                  pricingPage !== "final-cost"
                                  ? "#0072CF"
                                  : "#D7DBE2"
                              }
                              ml="0.7rem"
                            />
                          )}
                        </Flex>
                      )}
                    </SwiperSlide>
                  )}
                </>
              )}

              {Array.isArray(stepperData) &&
                !loading &&
                stepperData.length > 0 &&
                stepperData.map((value: any, index: number) => {
                  const maxIndex = startSequence + data.length;
                  const { sequence, pageName, description } = value;
                  return (
                    <SwiperSlide key={index}>
                      <Flex
                        key={sequence}
                        onClick={() => {
                          if (!isDisable) {
                            Swal.fire({
                              showCancelButton: true,
                              html: "Any changes made for SKU Characteristic will be removed. Do  you want to proceed?",
                              confirmButtonText: "Yes",
                              cancelButtonText: "No",
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                dispatch(pricingCostDriverAction.resetTmpChangeRow())
                                const payload = {
                                  costAnalysisId: pricingId,
                                  perVolumeId: location.state.volumeId || volumeId,
                                 };
                                 await dispatch(pricingCostDriverAction.setDataToSendToDefault(dataDefault))
                                 dispatch(pricingCostDriverAction.setNeedReFetch(true))
                                 dispatch(pricingCostDriverAction.setDisable(true))
                                history.push({
                                  pathname: `/pricings/${pageName}/${pricingId}`,
                                  state: {
                                    status: location.state.status,
                                    volumeId: location.state.volumeId,
                                    isApproval: location.state.isApproval || null,
                                  },
                                });
                              }
                            });
                          } else {
                            dispatch(pricingCostDriverAction.setIsEditOpen(false));
                            dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
                            dispatch(pricingCostDriverAction.setNullCustomFields());
                            if (searchParams.get("thirdPartyId")) {
                              history.push({
                                pathname: `/pricings/${pageName}/${pricingId}?thirdPartyName=${searchParams.get(
                                  "thirdPartyName"
                                )}&thirdPartyId=${searchParams.get(
                                  "thirdPartyId"
                                )}&defauldVolume=${searchParams.get("defauldVolume")}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            } else {
                              history.push({
                                pathname: `/pricings/${pageName}/${pricingId}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            }

                            if (searchParams.get("defauldVolume")) {
                              history.push({
                                pathname: `/pricings/${pageName}/${pricingId}?defauldVolume=${searchParams.get(
                                  "defauldVolume"
                                )}&thirdPartyName=${searchParams.get(
                                  "thirdPartyName"
                                )}&thirdPartyId=${searchParams.get("thirdPartyId")}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            }
                          }
                        }}
                      >
                        <Box>
                          <Circle
                            size="20px"
                            ml="0.5rem"
                            mr="0.5rem"
                            bg={pricingPage === pageName ? "#ffffff" : ""}
                            color="white"
                            border={pricingPage !== pageName ? "none" : "1px solid #0072CF"}
                          >
                            <Box as="span">
                              <Circle
                                size={pricingPage !== pageName ? "30px" : "27px"}
                                cursor="pointer"
                                bg={
                                  pricingPage === pageName
                                    ? "#0072CF"
                                    : currentSeq > sequence
                                      ? "#0072CF"
                                      : "#D7DBE2"
                                }
                                color="white"
                                fontWeight="600"
                                fontSize="12px"
                                ref={stepperReff}
                              >
                                {sequence}
                              </Circle>
                            </Box>
                          </Circle>
                        </Box>
                        <Box
                          fontSize="13px"
                          cursor="pointer"
                          fontWeight="600"
                          pl="3"
                          color={pricingPage === pageName ? "" : "#7B7F85"}
                        >
                          {description}
                        </Box>
                        {maxIndex !== sequence + 1 && (
                          <Box
                            mt="0.5rem"
                            w="50px"
                            h="2px"
                            bgColor={currentSeq > sequence ? "#0072CF" : "#D7DBE2"}
                            ml="0.7rem"
                          />
                        )}
                      </Flex>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          )}
        </Box>
      ) : levelName === "Does Cost" ? (
        <Box
          position="relative"
          w={levelName === "Does Cost" ? "73%" : ""}
          mt="2rem"
        >
          {!loading && (
            <>
              <Image
                src={LeftIcon}
                alt=""
                position="absolute"
                left="-30px"
                h="30px"
                cursor="pointer"
                zIndex="3"
                top="2rem"
                ref={prevRef}
              />
              <Image
                src={RightIcon}
                alt=""
                position="absolute"
                right="-30px"
                h="30px"
                cursor="pointer"
                zIndex="3"
                top="2rem"
                ref={nextRef}
              />
            </>
          )}

          {!loading && (
            <Swiper
              slidesPerView={totalSlideStepper}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              navigation={{
                prevEl: prevRef.current ? prevRef.current : undefined,
                nextEl: nextRef.current ? nextRef.current : undefined,
              }}
              modules={[Navigation]}
              className="mySwiper"
              onInit={(swiper: any) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              onSwiper={(swiper) => {
                swiper.slideTo(indexStepper, 1000);
              }}
            >
              {!loading && (
                <>
                  <SwiperSlide>
                    {!loading && (
                      <Flex>
                        <Box>
                          <Circle
                            size="20px"
                            bg={pricingPage === "cost-summary-by-sku" ? "#ffffff" : ""}
                            color="white"
                            border="1px solid #0072CF"
                          >
                            <Box as="span">
                              <Circle
                                size={pricingPage !== "cost-summary-by-sku" ? "30px" : "27px"}
                                cursor="pointer"
                                bg={"#0072CF"}
                                color="white"
                                fontWeight="600"
                                fontSize="12px"
                                ref={stepperReff}
                                onClick={() => {
                                  dispatch(pricingCostDriverAction.setIsEditOpen(false));
                                  dispatch(pricingCostDriverAction.setPrevPage(pricingPage));

                                  if (searchParams.get("thirdPartyId")) {
                                    history.push({
                                      pathname: `/pricings/cost-summary-by-sku/${pricingId}?thirdPartyName=${searchParams.get(
                                        "thirdPartyName"
                                      )}&thirdPartyId=${searchParams.get(
                                        "thirdPartyId"
                                      )}&defauldVolume=${searchParams.get("defauldVolume")}`,
                                      state: {
                                        status: location.state.status,
                                        volumeId: location.state.volumeId,
                                        isApproval: location.state.isApproval || null,
                                      },
                                    });
                                  } else {
                                    history.push({
                                      pathname: `/pricings/cost-summary-by-sku/${pricingId}`,
                                      state: {
                                        status: location.state.status,
                                        volumeId: location.state.volumeId,
                                        isApproval: location.state.isApproval || null,
                                      },
                                    });
                                  }
                                  if (searchParams.get("defauldVolume")) {
                                    if (searchParams.get("thirdPartyId")) {
                                      history.push({
                                        pathname: `/pricings/cost-summary-by-sku/${pricingId}?defauldVolume=${searchParams.get(
                                          "defauldVolume"
                                        )}&thirdPartyName=${searchParams.get(
                                          "thirdPartyName"
                                        )}&thirdPartyId=${searchParams.get("thirdPartyId")}`,
                                        state: {
                                          status: location.state.status,
                                          volumeId: location.state.volumeId,
                                          isApproval: location.state.isApproval || null,
                                        },
                                      });
                                    } else {
                                      history.push({
                                        pathname: `/pricings/cost-summary-by-sku/${pricingId}?defauldVolume=${searchParams.get(
                                          "defauldVolume"
                                        )}`,
                                        state: {
                                          status: location.state.status,
                                          volumeId: location.state.volumeId,
                                          isApproval: location.state.isApproval || null,
                                        },
                                      });
                                    }
                                  }
                                }}
                              >
                                0
                              </Circle>
                            </Box>
                          </Circle>
                        </Box>
                        <Box
                          fontSize="13px"
                          cursor="pointer"
                          fontWeight="600"
                          pl="3"
                          color={pricingPage === "cost-summary-by-sku" ? "" : "#7B7F85"}
                          onClick={() => {
                            dispatch(pricingCostDriverAction.setIsEditOpen(false));
                            dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
                            if (searchParams.get("thirdPartyId")) {
                              history.push({
                                pathname: `/pricings/cost-summary-by-sku/${pricingId}?thirdPartyName=${searchParams.get(
                                  "thirdPartyName"
                                )}&thirdPartyId=${searchParams.get(
                                  "thirdPartyId"
                                )}&defauldVolume=${searchParams.get("defauldVolume")}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            } else {
                              history.push({
                                pathname: `/pricings/cost-summary-by-sku/${pricingId}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            }

                            if (searchParams.get("defauldVolume")) {
                              history.push({
                                pathname: `/pricings/cost-summary-by-sku/${pricingId}?defauldVolume=${searchParams.get(
                                  "defauldVolume"
                                )}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            }
                          }}
                        >
                          Cost Summary
                        </Box>
                        <Box
                          w="100px"
                          h="2px"
                          mt="0.5rem"
                          bgColor={
                            pricingPage === "sku-characteristic" ||
                              pricingPage === "final-cost"
                              ? "#0072CF"
                              : currentSeq > costSummarySequence
                                ? "#0072CF"
                                : "#D7DBE2"
                          }
                          ml="0.7rem"
                        />
                      </Flex>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {!loading && (
                      <Flex>
                        <Box>
                          <Circle
                            size="20px"
                            bg={pricingPage === "sku-characteristic" ? "#ffffff" : ""}
                            color="white"
                            border={
                              pricingPage !== "sku-characteristic"
                                ? "none"
                                : "1px solid #0072CF"
                            }
                          >
                            <Box as="span">
                              <Circle
                                size={pricingPage !== "sku-characteristic" ? "30px" : "27px"}
                                cursor="pointer"
                                bg={
                                  pricingPage === "sku-characteristic" ||
                                    pricingPage === "final-cost"
                                    ? "#0072CF"
                                    : currentSeq > generalDataSequence
                                      ? "#0072CF"
                                      : "#D7DBE2"
                                }
                                color="white"
                                fontWeight="600"
                                fontSize="12px"
                                ref={stepperReff}
                                onClick={() => {
                                  dispatch(pricingCostDriverAction.setIsEditOpen(false));
                                  dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
                                  if (searchParams.get("thirdPartyId")) {
                                    history.push(
                                      `/pricings/sku-characteristic/${pricingId}?thirdPartyName=${searchParams.get(
                                        "thirdPartyName"
                                      )}&thirdPartyId=${searchParams.get("thirdPartyId")}`
                                    );
                                  } else {
                                    history.push({
                                      pathname: `/pricings/sku-characteristic/${pricingId}`,
                                      state: {
                                        status: location.state.status,
                                        volumeId: location.state.volumeId,
                                        isApproval: location.state.isApproval || null,
                                      },
                                    });
                                  }
                                  // ini tidak dipakai lagi
                                  // if (searchParams.get("defauldVolume")) {
                                  //  history.push(
                                  //   `/pricings/sku-characteristic/${pricingId}?defaultVolume=${searchParams.get(
                                  //    "defauldVolume"
                                  //   )}`
                                  //  );
                                  // }
                                }}
                              >
                                1
                              </Circle>
                            </Box>
                          </Circle>
                          {levelName && levelName !== "Does Cost" && (
                            <Box
                              w="100px"
                              h="2px"
                              bgColor={
                                pricingPage !== "sku-characteristic" &&
                                  pricingPage !== "cost-summary-by-sku"
                                  ? "#0072CF"
                                  : "#D7DBE2"
                              }
                              ml="0.7rem"
                            />
                          )}
                        </Box>
                        <Box
                          as="p"
                          fontSize="13px"
                          cursor="pointer"
                          fontWeight="600"
                          pl="3"
                          color={pricingPage === "sku-characteristic" ? "" : "#7B7F85"}
                          onClick={() => {
                            dispatch(pricingCostDriverAction.setIsEditOpen(false));
                            dispatch(pricingCostDriverAction.setPrevPage(pricingPage));

                            if (searchParams.get("thirdPartyName")) {
                              history.push(
                                `/pricings/sku-characteristic/${pricingId}?thirdPartyName=${searchParams.get(
                                  "thirdPartyName"
                                )}&thirdPartyId=${searchParams.get(
                                  "thirdPartyId"
                                )}&defauldVolume=${searchParams.get("defauldVolume")}`
                              );
                            } else {
                              history.push({
                                pathname: `/pricings/sku-characteristic/${pricingId}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            }

                            if (searchParams.get("defauldVolume")) {
                              history.push({
                                pathname: `/pricings/sku-characteristic/${pricingId}?defauldVolume=${searchParams.get(
                                  "defauldVolume"
                                )}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            }
                          }}
                        >
                          SKU Characteristic
                        </Box>
                        {levelName && levelName === "Does Cost" && (
                          <Box
                            w="100px"
                            h="2px"
                            mt="0.5rem"
                            bgColor={
                              pricingPage !== "sku-characteristic" &&
                                pricingPage !== "cost-summary-by-sku"
                                ? "#0072CF"
                                : "#D7DBE2"
                            }
                            ml="0.7rem"
                          />
                        )}
                      </Flex>
                    )}
                  </SwiperSlide>
                  {levelName && levelName === "Does Cost" && (
                    <SwiperSlide>
                      {!loading && (
                        <Flex>
                          <Box display="flex" alignItems="center">
                            <Circle
                              size="20px"
                              bg={pricingPage === "final-cost" ? "#ffffff" : ""}
                              color="white"
                              ml="0.5rem"
                              mr="0.5rem"
                              border={
                                pricingPage !== "final-cost" ? "none" : "1px solid #0072CF"
                              }
                            >
                              <Box as="span">
                                <Circle
                                  size={pricingPage !== "final-cost" ? "30px" : "27px"}
                                  cursor="pointer"
                                  bg={
                                    pricingPage === "final-cost"
                                      ? "#0072CF"
                                      : currentSeq > generalDataSequence
                                        ? "#0072CF"
                                        : "#D7DBE2"
                                  }
                                  color="white"
                                  fontWeight="600"
                                  fontSize="12px"
                                  ref={stepperReff}
                                  onClick={() => {
                                    dispatch(pricingCostDriverAction.setIsEditOpen(false));
                                    dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
                                    if (searchParams.get("thirdPartyId")) {
                                      history.push({
                                        pathname: `/pricings/final-cost/${pricingId}?thirdPartyName=${searchParams.get(
                                          "thirdPartyName"
                                        )}&thirdPartyId=${searchParams.get("thirdPartyId")}`,
                                        state: {
                                          status: location.state.status,
                                          volumeId: location.state.volumeId,
                                          isApproval: location.state.isApproval || null,
                                        },
                                      });
                                    } else {
                                      history.push({
                                        pathname: `/pricings/final-cost/${pricingId}`,
                                        state: {
                                          status: location.state.status,
                                          volumeId: location.state.volumeId,
                                          isApproval: location.state.isApproval || null,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  2
                                </Circle>
                              </Box>
                            </Circle>
                            {levelName && levelName !== "Does Cost" && (
                              <Box
                                w="100px"
                                h="2px"
                                bgColor={
                                  pricingPage !== "sku-characteristic" &&
                                    pricingPage !== "cost-summary-by-sku" &&
                                    pricingPage !== "final-cost"
                                    ? "#0072CF"
                                    : "#D7DBE2"
                                }
                                ml="0.7rem"
                              />
                            )}
                          </Box>
                          <Box
                            as="p"
                            fontSize="13px"
                            cursor="pointer"
                            fontWeight="600"
                            pl="3"
                            color={pricingPage === "final-cost" ? "" : "#7B7F85"}
                            onClick={() => {
                              dispatch(pricingCostDriverAction.setIsEditOpen(false));
                              dispatch(pricingCostDriverAction.setPrevPage(pricingPage));

                              if (searchParams.get("thirdPartyName")) {
                                history.push({
                                  pathname: `/pricings/final-cost/${pricingId}?thirdPartyName=${searchParams.get(
                                    "thirdPartyName"
                                  )}&thirdPartyId=${searchParams.get(
                                    "thirdPartyId"
                                  )}&defauldVolume=${searchParams.get("defauldVolume")}`,
                                  state: {
                                    status: location.state.status,
                                    volumeId: location.state.volumeId,
                                    isApproval: location.state.isApproval || null,
                                  },
                                });
                              } else {
                                history.push({
                                  pathname: `/pricings/final-cost/${pricingId}`,
                                  state: {
                                    status: location.state.status,
                                    volumeId: location.state.volumeId,
                                    isApproval: location.state.isApproval || null,
                                  },
                                });
                              }

                              if (searchParams.get("defauldVolume")) {
                                history.push({
                                  pathname: `/pricings/final-cost/${pricingId}?defauldVolume=${searchParams.get(
                                    "defauldVolume"
                                  )}`,
                                  state: {
                                    status: location.state.status,
                                    volumeId: location.state.volumeId,
                                    isApproval: location.state.isApproval || null,
                                  },
                                });
                              }
                            }}
                          >
                            Final Cost
                          </Box>
                        </Flex>
                      )}
                    </SwiperSlide>
                  )}
                </>
              )}

              {Array.isArray(stepperData) &&
                !loading &&
                stepperData.length > 0 &&
                stepperData.map((value: any, index: number) => {
                  const maxIndex = startSequence + data.length;
                  const { sequence, pageName, description } = value;
                  return (
                    <SwiperSlide key={index}>
                      <Box
                        as="span"
                        key={sequence}
                        // w="200px"
                        onClick={() => {
                          if (!isDisable) {
                            Swal.fire({
                              showCancelButton: true,
                              html: "Any changes made for SKU Characteristic will be removed. Do  you want to proceed?",
                              confirmButtonText: "Yes",
                              cancelButtonText: "No",
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                dispatch(pricingCostDriverAction.resetTmpChangeRow())
                                const payload = {
                                  costAnalysisId: pricingId,
                                  perVolumeId: location.state.volumeId || volumeId,
                                 };
                                 await dispatch(pricingCostDriverAction.setDataToSendToDefault(dataDefault))
                                 dispatch(pricingCostDriverAction.setNeedReFetch(true))
                                 dispatch(pricingCostDriverAction.setDisable(true))
                                history.push({
                                  pathname: `/pricings/${pageName}/${pricingId}`,
                                  state: {
                                    status: location.state.status,
                                    volumeId: location.state.volumeId,
                                    isApproval: location.state.isApproval || null,
                                  },
                                });
                              }
                            });
                          } else {
                            dispatch(pricingCostDriverAction.setIsEditOpen(false));
                            dispatch(pricingCostDriverAction.setPrevPage(pricingPage));
                            if (searchParams.get("thirdPartyId")) {
                              history.push({
                                pathname: `/pricings/${pageName}/${pricingId}?thirdPartyName=${searchParams.get(
                                  "thirdPartyName"
                                )}&thirdPartyId=${searchParams.get(
                                  "thirdPartyId"
                                )}&defauldVolume=${searchParams.get("defauldVolume")}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            } else {
                              history.push({
                                pathname: `/pricings/${pageName}/${pricingId}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            }

                            if (searchParams.get("defauldVolume")) {
                              history.push({
                                pathname: `/pricings/${pageName}/${pricingId}?defauldVolume=${searchParams.get(
                                  "defauldVolume"
                                )}&thirdPartyName=${searchParams.get(
                                  "thirdPartyName"
                                )}&thirdPartyId=${searchParams.get("thirdPartyId")}`,
                                state: {
                                  status: location.state.status,
                                  volumeId: location.state.volumeId,
                                  isApproval: location.state.isApproval || null,
                                },
                              });
                            }
                          }

                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <Circle
                            size="20px"
                            ml="0.5rem"
                            mr="0.5rem"
                            bg={pricingPage === pageName ? "#ffffff" : ""}
                            color="white"
                            border={pricingPage !== pageName ? "none" : "1px solid #0072CF"}
                          >
                            <Box as="span">
                              <Circle
                                size={pricingPage !== pageName ? "30px" : "27px"}
                                cursor="pointer"
                                bg={
                                  pricingPage === pageName
                                    ? "#0072CF"
                                    : currentSeq > sequence
                                      ? "#0072CF"
                                      : "#D7DBE2"
                                }
                                color="white"
                                fontWeight="600"
                                fontSize="12px"
                                ref={stepperReff}
                              >
                                {sequence}
                              </Circle>
                            </Box>
                          </Circle>
                          {maxIndex !== sequence + 1 && (
                            <Box
                              w="100px"
                              h="4px"
                              bgColor={currentSeq > sequence ? "#0072CF" : "#D7DBE2"}
                              ml="0.7rem"
                            />
                          )}
                        </Box>
                        <Box
                          as="p"
                          fontSize="13px"
                          cursor="pointer"
                          fontWeight="600"
                          pl="3"
                          color={pricingPage === pageName ? "" : "#7B7F85"}
                        >
                          {description}
                        </Box>
                      </Box>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          )}
        </Box>
      ) : (
        <Box
          pl="10"
          backgroundColor="#f7f9fd"
          borderRadius="40px"
          p="25px 3rem 19px 3rem"
          w={levelName === "Does Cost" ? "35%" : "75%"}
        >
          <Box gap={7} display="flex" alignItems="center">
            <StepperLoading />
            <StepperLoading />
            <StepperLoading />
            <StepperLoading />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Stepper;
