export const JourneyAssessments = [
    {
        label: 'Unit Price'
    },
    {
        label: 'Overall Material Cost'
    },
    {
        label: 'Overall Logistic Cost'
    },
    {
        label: 'Marketing Element'
    },
    {
        label: 'Other Operational Element'
    },
    {
        label: 'Profit Element'
    },
    {
        label: 'Material By Category'
    },
    {
        label: 'Material Name'
    },
    {
        label: 'Material Price'
    },
    {
        label: 'Material Specification'
    },
    {
        label: 'Primary Manufacturing'
    },
    {
        label: 'Secondary Manufacturing'
    },
    {
        label: 'Specific Production Process'
    },
    {
        label: 'Setup For Production'
    },
    {
        label: 'Good Production'
    },
    {
        label: 'Production Waste'
    },
    {
        label: 'Transportation Mode'
    },
    {
        label: 'Packaging Information'
    },
    {
        label: 'Shipping Terms'
    }
  ]
  