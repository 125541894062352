import { Box, Image, Center, Heading, Text } from "@chakra-ui/react";
import EmptyIcon from "assets/images/EmptyData.png";

interface EmptyProps {
 children: any;
 isSearch: boolean
}
const EmptyState = ({ children, isSearch = false }: EmptyProps) => {
 return (
  <Box mt="8rem">
   <Center>
    <Image src={EmptyIcon} alt="Empty" />
   </Center>
   <Box pt={5} m="auto" w={"567px"} textAlign={"center"}>
    <Heading as={"h2"} fontSize="20px">
     {isSearch ? 'No results found' : 'No Data Available'}
    </Heading>
    <Text py={6}>
     {isSearch ? <>Please refine your search with <br /> defferent keywords</> : <>It seems like there are no records available to display in the table. <br /> You can populate it with data to start visualizing and analyzing information.</>}
    </Text>
   </Box>
   <Center>{children}</Center>
  </Box>
 );
};

export default EmptyState;
