import { createSlice } from '@reduxjs/toolkit';
import { updateAsNewVersion } from 'state/pricings/costAnalysis/thunk';

interface InitialState {
  loading: boolean
  error: unknown
}

const initialState: InitialState = {
  loading: false,
  error: null,
}

const pricingCostDriverSlice = createSlice({
  name: 'updateAsNewVersion',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(updateAsNewVersion.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(updateAsNewVersion.fulfilled, (state, action) => {
      state.loading = false
    })

    builder.addCase(updateAsNewVersion.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const actions = {
  ...pricingCostDriverSlice.actions,
  updateAsNewVersion
}

export const reducer = pricingCostDriverSlice.reducer;
