import { useEffect, useRef } from "react";
import Layout from "views/Layout";
import { useAppDispatch } from "hooks";
import { Box, Heading, VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import queryString from "query-string";
import Stepper from "views/Simulation/Stepper";
import DatasetOverview from "views/Simulation/CreateSimulation/Dataset";
import JourneyOverview from "views/Simulation/CreateSimulation/SelectJourney";
import ComponentStep1Overview from "views/Simulation/CreateSimulation/SelectComponent/step1";
import ComponentStep2Overview from "views/Simulation/CreateSimulation/SelectComponent/step2";
import DescribeOverview from "views/Simulation/CreateSimulation/DescribeChange";
import SummaryOverview from "views/Simulation/CreateSimulation/Summary";
import Lamp from "assets/images/bulp.png";

import { actions as simulateAction } from "state/simulation/slice";

export default function Create() {
 const router = useHistory();
 const dispatch = useAppDispatch();
 const messagesEndRef = useRef<null | HTMLDivElement>(null);
 const { location } = router;
 const { search } = location;
 const query = queryString.parse(search);

 useEffect(() => {
  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
 }, [search]);

 useEffect(() => {
  window.scrollTo(0, 0);
  if (query?.page !== "select-journey") {
   resetSimulationCreationState();
   window.location.href = "/simulations/create?page=select-journey";
  }
 }, []);

 const resetSimulationCreationState = () => {
  dispatch(simulateAction.removeArrayAllComponent([]));
  dispatch(simulateAction.removeArrayAllSubComponent([]));
 };

 return (
  <Layout>
   <VStack h="90vh" align="flex-start" ref={messagesEndRef}>
    <Box display="flex" w="100%">
     <Heading as="h1" fontSize="36px" pb="36px">
      {query?.page === "select-journey" && "Select journey"}
      {query?.page === "select-dataset" && "Select dataset"}
      {query?.page === "select-component-step-1" && "Select component"}
      {query?.page === "select-component-step-2" && "Select component"}
      {query?.page === "describe-change" && "Describe changes"}
      {query?.page === "summary" && "Summary"}
     </Heading>
     <Box
      width="276px"
      as="p"
      color="white"
      fontSize="16px"
      ml="25px"
      textColor="#9DA1A7"
      fontWeight="500"
     >
      {query?.page === "summary" &&
       `This is the result of your simulation. Click here to see your changes.`}
     </Box>
     {query?.page === "summary" && (
      <Box
       width="500px"
       borderRadius="20px"
       mt="-1rem"
       mb="1rem"
       ml="25px"
       backgroundColor="#F7F9FD"
      >
       <Image src={Lamp} alt="lampicon" float="left" m="1.5rem" />
       <Box
        as="p"
        p="1rem"
        color="#7B7F85"
        fontSize="16px"
        fontWeight="500"
        justifyContent="center"
       >
        To unlock more data, contact InnoArk staff here <br /> to unlock more
        levels of your cost journey.
       </Box>
      </Box>
     )}
    </Box>
    {query?.page !== "summary" ? <Stepper /> : <></>}
    <Box w="100%">
     {query?.page === "select-journey" && <JourneyOverview />}
     {query?.page === "select-dataset" && <DatasetOverview />}
     {query?.page === "select-component-step-1" && <ComponentStep1Overview />}
     {query?.page === "select-component-step-2" && <ComponentStep2Overview />}
     {query?.page === "describe-change" && <DescribeOverview />}
     {query?.page === "summary" && <SummaryOverview />}
    </Box>
   </VStack>
  </Layout>
 );
}
