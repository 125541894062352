import { ReactElement, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Layout from "views/Layout";
import {
 Box,
 Flex,
 Grid,
 GridItem,
 Heading,
 Spacer,
} from "@chakra-ui/layout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import { actions as masterDataAction } from "state/masterData/slice";
import { actions as masterDataConfigurationAction } from "state/masterDataConfiguration/slice";
import { Button, Image } from "@chakra-ui/react";
import Lamp from "assets/images/Ideas.png";
import { isEmpty, orderBy } from "lodash";
import PaginationNumber from "components/PaginationNumber";
import NewPagination from "components/NewPagination";
import SearchBar from "components/SearchBar";
import EmptyState from "components/EmptyState";
import { TableLoading } from "components/Loading";

const MasterData = (): ReactElement => {
 const router = useHistory();
 const dispatch = useDispatch();
 const maxResultCount = 15;

 const {
  loading,
  masterDataList,
  searchQuery,
  totalPage,
  totalCount,
  profile,
 } = useSelector((state: RootState) => ({
  loading: state.masterDataConfiguration.loading,
  masterDataList: state.masterDataConfiguration.lists.result,
  searchQuery: state.masterDataConfiguration.searchQuery,
  totalPage: Math.ceil(
   state.masterDataConfiguration.lists.result?.totalCount / maxResultCount
  ),
  totalCount: state.masterDataConfiguration.lists.result?.totalCount || 15,
  profile: state.auth.result,
 }));

 const [pageNumber, setPageNumber] = useState(1);

 const fetchList = (data: any) => {
  dispatch(masterDataConfigurationAction.masterDataConfigurationsList(data));
 };
 useEffect(() => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   searchKey: searchQuery,
   type: profile?.isAdmin ? 0 : 4,
  };
  fetchList(payload);
 }, [searchQuery, profile?.isAdmin]);

 const handleChangePagination = (pageNumber: number) => {
  setPageNumber(pageNumber);
  var skipCount = (pageNumber - 1) * maxResultCount;
  const payload = {
   skipCount,
   maxResultCount: maxResultCount,
   searchKey: searchQuery,
   type: profile?.isAdmin ? 0 : 4,
  };
  fetchList(payload);
 };

 return (
  <Layout>
   <Heading as="h2" fontSize="38px" mt={2}>
    Master Data
   </Heading>
   <Flex>
    <Box
     as="p"
     py="1rem"
     color="#7B7F85"
     fontSize="14px"
     fontWeight="500"
     justifyContent="left"
     maxW={"480"}
    >
     In this module, you can see the master data that has been created and view
     the detailed information of a master data.
    </Box>
    <Spacer />
    <Box
     width="450px"
     borderRadius="20px"
     mb="1rem"
     ml="25px"
     backgroundColor="#F7F9FD"
     maxW={"350"}
    >
     <Image src={Lamp} alt="lampicon" float="left" m="1.5rem" />
     <Box
      as="p"
      p="1rem"
      color="#7B7F85"
      fontSize="14px"
      fontWeight="500"
      justifyContent="center"
     >
      You can create a new master data via Setting {">"} Master Data
      Configuration
     </Box>
    </Box>
   </Flex>
   {profile?.isAdmin && masterDataList?.items?.length > 0 && !loading && (
    <Box mb="2rem">
     <Button
      onClick={() =>
       router.push("/settings/master-data/configuration?page=created")
      }
      h="50px"
      w="200px"
      borderRadius={"5px"}
      colorScheme={"blue"}
     >
      Create New
     </Button>
    </Box>
   )}
   <Flex>
    <Box>
     {masterDataList?.items?.length > 0 && (
      <PaginationNumber
       pageNumber={totalPage}
       currentPage={pageNumber}
       totalAllPage={totalCount}
       totalRowsPage={maxResultCount}
      />
     )}
    </Box>
    <Spacer />
    <Box>
     <SearchBar
      isLoading={loading}
      placeholder="Search..."
      onChange={(val) => {
       if (val.currentTarget.value === "") {
        setPageNumber(1);
       }
       dispatch(
        masterDataConfigurationAction.setSearchQuery({
         value: val.currentTarget.value,
        })
       );
      }}
     />
    </Box>
   </Flex>
   <Box>
    {loading  ? ( <TableLoading />) : Array.isArray(masterDataList?.items) &&
    masterDataList?.items?.length === 0 ? ( <EmptyState isSearch={!isEmpty(searchQuery)}>
    <Button
     onClick={() =>
      router.push("/settings/master-data/configuration?page=created")
     }
     h="50px"
     w="200px"
     borderRadius={"5px"}
     colorScheme={"blue"}
    >
     Create New
    </Button>
   </EmptyState>) : (<Grid my={5} templateColumns="repeat(3, 1fr)" gap={6}>
     {orderBy(masterDataList?.items, ["identityName"], ["asc"]).map(
      (item: any, index: number) => {
       return (
        <GridItem key={index}>
         <Flex
          border="1px"
          borderColor="#1A1A1A29"
          borderRadius="5"
          align="center"
          p="5"
         >
          <Box>{item.identityName}</Box>
          <Spacer />
          <Box>
           <Button
            justifyContent="right"
            colorScheme="blue"
            variant="link"
            onClick={() => {
             dispatch(masterDataAction.setMasterDataTitle(item.identityName));
             router.push({
              pathname: `/master-data/${item.id}`,
              state: item.identityName,
             });
            }}
           >
            View
           </Button>
          </Box>
         </Flex>
        </GridItem>
       );
      }
     )}
    </Grid>)}
   </Box>
   {!loading && masterDataList?.items?.length > 0 && (
    <Box pt={5}>
     <NewPagination
      onChange={handleChangePagination}
      totalRowsPage={maxResultCount}
      pageNumber={Math.ceil(totalCount / maxResultCount)}
      currentPage={pageNumber}
      totalAllPage={totalCount}
     />
    </Box>
   )}
  </Layout>
 );
};

export default MasterData;
