import { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { RootState } from "state/store";
import { useSelector, shallowEqual } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
 Box,
 Center,
 Button,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalBody,
 ModalCloseButton,
 Text,
 FormControl,
 FormLabel,
 Input,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { actions as myJourneyActions } from "state/myJourney/slice";

const initialValues = {
 journeyName: "",
 journeyCode: "",
 type: "",
};

const validation = Yup.object().shape({
 journeyCode: Yup.string()
  .max(8, "The text input has exceed the maximum characters.")
  .required("Journey code is a required field"),
 journeyName: Yup.string()
  .max(24, "The text input has exceed the maximum characters.")
  .required("Journey name is a required field"),
});

const NameJourney: FC = () => {
 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);
 const dispatch = useAppDispatch();
 const [submitted, setSubmitted] = useState(false);
 const history = useHistory();
 const { levelName } = useSelector(
  (state: RootState) => ({
   levelName: state.myJourney.levelName,
  }),
  shallowEqual
 );

 const [isOpenModal, setOpenModal] = useState(true);

 const formik = useFormik({
  initialValues,
  validationSchema: validation,
  onSubmit: async (values) => {
   values.type = levelName;
   await dispatch(myJourneyActions.add(values))
    .then(unwrapResult)
    .then((response: any) => {
     if (response) {
      history.push(`/journeys/${response.id}`);
      setSubmitted(true);
     } else {
      setSubmitted(false);
     }
    })
    .finally(() => {
     if (submitted) {
      dispatch(myJourneyActions.removeAllWhatInformationDoYouHave([]));
      dispatch(myJourneyActions.removeAllWhatInformationCanYouGain([]));
      dispatch(myJourneyActions.setJourneyLevel("Does Cost"));
      dispatch(myJourneyActions.setLevelName(""));
     }
    });
  },
 });
 return (
  <>
   <Center>
    <Box width="30%" pt="7rem">
     <form onSubmit={formik.handleSubmit}>
      <FormControl>
       <FormLabel
        pb="10px"
        alignSelf="center"
        margin="0"
        minW="40%"
        fontWeight="bold"
        textTransform="capitalize"
        opacity="1!important"
       >
        Journey Code
       </FormLabel>
       <Input
        bgColor="#E6EAF1"
        borderRadius="12px"
        minH="60px"
        type="text"
        name="journeyCode"
        value={formik.values.journeyCode}
        onChange={formik.handleChange}
        onKeyUp={formik.handleBlur}
        placeholder="e.g. BOTTLE"
       />
       {formik.touched.journeyCode && formik.errors.journeyCode ? (
        <Text fontSize={12} p="2" color="red.400">
         {formik.errors.journeyCode}
        </Text>
       ) : null}
      </FormControl>
      <FormControl>
       <FormLabel
        pb="10px"
        alignSelf="center"
        margin="0"
        minW="40%"
        fontWeight="bold"
        textTransform="capitalize"
        opacity="1!important"
       >
        Journey Name
       </FormLabel>
       <Input
        bgColor="#E6EAF1"
        borderRadius="12px"
        minH="60px"
        type="text"
        name="journeyName"
        value={formik.values.journeyName}
        onChange={formik.handleChange}
        onKeyUp={formik.handleBlur}
        placeholder="e.g. Bottle Category"
       />
      </FormControl>
      {formik.touched.journeyName && formik.errors.journeyName ? (
       <Text fontSize={12} p="2" color="red.400">
        {formik.errors.journeyName}
       </Text>
      ) : null}
      <Button
       mt="1.5rem"
       isFullWidth
       isLoading={formik.isSubmitting}
       type="submit"
       disabled={!(formik.isValid && formik.dirty)}
      >
       Complete
      </Button>
     </form>
    </Box>
   </Center>
   <Modal isOpen={isOpenModal} onClose={() => setOpenModal(false)}>
    <ModalOverlay />
    <ModalContent
     boxShadow="0px 0px 56px rgba(157, 161, 167, 0.37)"
     borderRadius="63px"
     p="1rem"
     mt="10%"
    >
     <ModalHeader>Note</ModalHeader>
     <ModalCloseButton mt="1.5rem" mr="1rem" _focus={{ boxShadow: "none" }} />
     <ModalBody>
      <Text pb="1rem">
       When naming your Optipedia Journey(s) it is important to make its name
       relevant to the Category breakdown within your business, for example:
      </Text>
      <Text>Example 1: ‘Bottles Category’</Text>
      <Text>Example 2: ‘Bottle Caps Category’</Text>
      <Text>Example 3: ‘Bottles and Caps Category’</Text>
      <Box textAlign="center" mt="4rem" mb="1rem">
       <Button
        onClick={() => setOpenModal(false)}
       >
        Got it
       </Button>
      </Box>
     </ModalBody>
    </ModalContent>
   </Modal>
  </>
 );
};

export default NameJourney;
