import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";
import parameterDto from "./dtos/parameterDtos";


export const getAllCurrencies = createAsyncThunk(
  'currencies/getAllCurrencies',
  async () => {
    const response: any = await HttpService.get(`Currencies/getAll`)
    return response.result
  }
)

export const add = createAsyncThunk("currencies/add", async (payload: any) => {
    const response: any = await HttpService.post(
        "/Currencies",
        payload
    );
    return response.result;
});

export const edit = createAsyncThunk("currencies/edit", async (payload: any) => {
    const response: any = await HttpService.put(
        `Currencies/${payload.id}`,
        payload.values
    );
    return response.result;
});

export const listMultiCurrency = createAsyncThunk(
    "currencies",
    async (parameterDto: parameterDto) => {
        const response: any = await HttpService.get('Currencies', {params: parameterDto});
        return response.result;
    }
);

export const deletes = createAsyncThunk(
    "currencies/delete",
    async (id: string) => {
        const response: any = await HttpService.delete(
            `Currencies/${id}`
        );
        return response;
    }
);

export const upload = createAsyncThunk(
    'currencies/upload',
    async ({ payload }:any) => {
      const config = {
        headers: {
          notUseToast: true,
        },
      };
      const formData = new FormData();
      const file = payload[0] as any;
      formData.append('file', file);
      const response:any = await HttpService.post('Currencies/Upload', formData, config);
      return response.success;
    }
  )

  export const downloadTemplate = createAsyncThunk(
    'currencies/downloadTemplate',
    async () => {
      HttpService.get(`Currencies/DownloadTemplate`, { responseType: 'blob' }).then((response:any) => {
        const url = window.URL.createObjectURL(new window.Blob([response]))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Currency_Template.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        return true;
      })
    }
  )
  
export const addExchange = createAsyncThunk("exchangerates/add", async (payload: any) => {
    const response: any = await HttpService.post(
        "/ExhangeRates",
        payload
    );
    return response.result;
});

export const exchangeDetail = createAsyncThunk("exchangerates/detail", async (payload: any) => {
  const response: any = await HttpService.get(
      `/ExhangeRates/${payload}`
  );
  return response.result;
});

export const editExchange = createAsyncThunk("exchangerates/edit", async (payload: any) => {
    const response: any = await HttpService.put(
        `ExhangeRates/${payload.id}`,
        payload.values
    );
    return response.result;
});

export const listExchange = createAsyncThunk(
    "exchangerates",
    async (parameterDto: parameterDto) => {
        const response: any = await HttpService.get('ExhangeRates', {params: parameterDto});
        return response.result;
    }
);

export const deletesExchange = createAsyncThunk(
    "exchangerates/delete",
    async (id: string) => {
        const response: any = await HttpService.delete(
            `ExhangeRates/${id}`
        );
        return response;
    }
);

export const uploadExchange = createAsyncThunk(
    'exchangerates/upload',
    async ({ payload }:any) => {
      const config = {
        headers: {
          notUseToast: true,
        },
      };
      const formData = new FormData();
      const file = payload[0] as any;
      formData.append('file', file);
      const response:any = await HttpService.post('ExhangeRates/Upload', formData, config);
      return response.success;
    }
  )

  export const downloadTemplateExchange = createAsyncThunk(
    'exchangerates/downloadTemplate',
    async () => {
      HttpService.get(`ExhangeRates/DownloadTemplate`, { responseType: 'blob' }).then((response:any) => {
        const url = window.URL.createObjectURL(new window.Blob([response]))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Exchange_Template.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        return true;
      })
    }
  )
  export const validCurrencies = createAsyncThunk(
    'exhangeRates/ValidCurrencies',
    async () => {
      const response: any = await HttpService.get(`ExhangeRates/ValidCurrencies`)
      return response.result
    }
  )