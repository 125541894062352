import { ReactElement } from "react";
import { Box, Button, Flex, Spacer, Spinner, Text } from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import Swal from "sweetalert2";
import { useAppDispatch } from "hooks";
import { actions as approvalAction } from "state/approval/slice";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { isEmpty } from "lodash";

const ToolbarBottom = ({ status, user, handleChange }: any): ReactElement => {
 const dispatch = useAppDispatch();
 const maxResultCount = 100;
 const history = useHistory();
 const { location } = history;
 const { search } = location;
 const query = queryString.parse(search);
 

 let { loading, thirdPartySelection, nonThirdPartySelection, pricings } = useSelector(
  (state: RootState) => ({
   loading: state.approval.loading,
   thirdPartySelection: state.approval.thirdPartySelection,
   nonThirdPartySelection: state.approval.nonThirdPartySelection,
   pricings: state.approval.pricings,
  }),
  shallowEqual
 );
 
 return (
  <Box
   position="fixed"
   bottom="0"
   right="0"
   backgroundColor="#ffffff"
   height="4.5rem"
   w="100%"
  >
   <Flex p="1rem" mr="3rem">
    <Box>
     {loading && (
      <Flex alignItems="center" gap="2">
       <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="sm"
       />
       <Text>Processing…</Text>
      </Flex>
     )}
    </Box>
    <Spacer />
    <Button
     bg="#C3C7CD"
     mr="1rem"
     onClick={async () => {
      history.goBack()
     }}
    >
     Back
    </Button>
    {query?.page !== "summary" && (
     <Button
      bg="#0072CF"
      isDisabled={
       query?.page === "select-third-party"
        ? isEmpty(thirdPartySelection.concat(nonThirdPartySelection))
        : isEmpty(pricings)
      }
      onClick={async () => {
          dispatch(approvalAction.resetQuery());
          query?.page === "select-third-party"
           ? history.push("comparison?page=select-pricing")
           : history.push("comparison?page=summary");
      }}
     >
      Next
     </Button>
    )}
    {query?.page === "summary" && (
     <Button
      bg="#0072CF"
      borderRadius="5px"
      fontSize="16"
      color="white"
      onClick={handleChange}
     >
      View Changes
     </Button>
    )}
   </Flex>
  </Box>
 );
};

export default ToolbarBottom;
