import Table from "components/Table/Table";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { TableLoading } from "components/Loading";
import {
 Box,
 Image,
 Stat,
 StatArrow,
 StatHelpText,
 Stack,
 Button,
 Text
} from "@chakra-ui/react";
import { FC } from "react";
import "utils/date-utils";
import ModalCompare from "./modalCompare";

const TABLE_HEADERS = [
 { name: "businessCaseName", label: "Business case" },
 { name: "currentTotalSpend", label: "Total spend (current)" },
 { name: "whatIfTotalSpend", label: "Total spend (what-if)" },
 { name: "differenceInPercentage", label: "% Change" },
];

const SimulationTable: FC = () => {
 const { loading, compare } = useSelector(
  (state: RootState) => ({
   loading: state.simulationAggregate.loading,
   compare: state.simulationAggregate.compare,
  }),
  shallowEqual
 );

 if (loading) {
  return <TableLoading />;
 }

 let newData: any = [];
 compare.details?.map((item: any) => {
  newData.push({
   businessCaseName: item.businessCaseName,
   costDrivers: item.costDrivers.map((item: any) => {
    const { currentTotalSpend, identityName, sequence, whatIfTotalSpend } =
     item;
    return {
     currentTotalSpend: <Text align="right">{currentTotalSpend}</Text>,
     identityName: identityName,
     sequence: sequence,
     currency: "USD",
     whatIfTotalSpend: <Text align="right">{whatIfTotalSpend}</Text>,
    };
   }),
   currentTotalSpend: Math.ceil(item.currentTotalSpend).toLocaleString("en-US"),
   difference: item.difference.toLocaleString("en-US"),
   differenceInPercentage: (
    <Stat>
     <StatHelpText>
      <StatArrow
       type={item.differenceInPercentage >= 0 ? "increase" : "decrease"}
      />
      {`${(item.differenceInPercentage * 100)?.toLocaleString(
           undefined,
           {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
           }
          )} %`}
     </StatHelpText>
    </Stat>
   ),
   whatIfTotalSpend: Math.ceil(item.whatIfTotalSpend).toLocaleString("en-US"),
  });
 });

 return (
  <>
   {(compare && newData.length) > 0 && (
    <Box>
     <Table
      loading={loading}
      key={newData.index}
      columnHeaders={TABLE_HEADERS}
      data={newData}
      actionModal={(costDrivers: any) => (
       <Stack spacing={4} direction="row" align="center" float="right">
        <ModalCompare data={costDrivers} />
       </Stack>
      )}
     />
    </Box>
   )}
  </>
 );
};

export default SimulationTable;
