import { FC, ReactNode, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { Box } from "@chakra-ui/react";
import Sidebar from "views/Layout/Sidebar";

type LayoutProps = {
 children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => {
 const router = useHistory();
 const { location } = router;
 const { isSidebarOpen } = useSelector(
  (state: RootState) => ({
   isSidebarOpen: state.thirdPartySetting.sidebarOpen,
  }),
  shallowEqual
 );
 const timeout = 3600000;
 const [remaining, setRemaining] = useState(timeout);

 const { getRemainingTime } = useIdleTimer({
  timeout,
 });

 useEffect(() => {
  setRemaining(getRemainingTime());

  setInterval(() => {
   setRemaining(getRemainingTime());
  }, 1000);
 }, []);

 if (remaining === 0) {
  window.location.href = "/logout";
 }

 return (
  <div>
   <Box as="main">
    {location.pathname === "/journeys/create" ? <></> : <Sidebar />}

    <Box
     as="section"
     marginLeft={
      location.pathname !== "/journeys/create"
       ? !isSidebarOpen
         ? "80px"
         : "260px"
       : 0
     }
    >
     <Box minH="900" padding={["7", "5"]} paddingLeft={["7", "10"]} paddingRight={["7", "10"]}>
      {children}
     </Box>
    </Box>
   </Box>
  </div>
 );
};

export default Layout;
