import { ReactElement, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { Box, Text, Heading, Flex, Spacer } from "@chakra-ui/layout";
import {
 Button,
 Breadcrumb,
 BreadcrumbItem,
 BreadcrumbLink,
 HStack,
 Image,
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import { ChevronRightIcon } from "@chakra-ui/icons";
import Layout from "views/Layout";
import SearchBar from "components/SearchBar";
import Table from "components/Table/Table";
import queryString from "query-string";
import FormInput from "./FormInput";
import IconEdit from "assets/images/Edit.png";
import IconDelete from "assets/images/Delete.png";
import { actions as masterDataAction } from "state/masterDataConfiguration/slice";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { isEmpty, orderBy } from "lodash";
import NewPagination from "components/NewPagination";
import PaginationNumber from "components/PaginationNumber";
import EmptyState from "components/EmptyState";
import { TableLoading } from "components/Loading";

const Configuration = (): ReactElement => {
 const history = useHistory();
 const { location } = history;
 const { search } = location;
 const query = queryString.parse(search);
 const dispatch = useAppDispatch();
 const maxResultCount = !query.page ? 10 : 100;
 const [pageNumber, setPageNumber] = useState(1);

 const { totalPage, totalCount, masterData, searchQuery, loading } =
  useSelector((state: RootState) => ({
   masterData: state.masterDataConfiguration.lists.result?.items,
   totalPage: Math.ceil(
    state.masterDataConfiguration.lists?.result?.totalCount / maxResultCount
   ),
   totalCount: state.masterDataConfiguration.lists?.result?.totalCount,
   searchQuery: state.masterDataConfiguration.searchQuery,
   loading: state.masterDataConfiguration.loading,
  }));
 const fetchList = (data: any) => {
  dispatch(masterDataAction.masterDataConfigurationsList(data));
 };
 useEffect(() => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   searchKey: searchQuery,
   type: location.search ? 2 : 0,
  };
  fetchList(payload);
 }, [location.search, searchQuery]);
 const handleChangePagination = (pageNumber: number) => {
  setPageNumber(pageNumber);
  var skipCount = (pageNumber - 1) * maxResultCount;
  fetchList({
   skipCount,
   maxResultCount: maxResultCount,
   searchKey: searchQuery,
  });
 };

 const COLUMN_HEADERS = [
  { name: "identityCode", label: "Master Data Code" },
  { name: "identityName", label: "Master Data Name" },
 ];
 const DataTable = masterData?.map((item: any) => ({
  ...item,
  identityCode: item.identityCode.toUpperCase(),
 }));

 return (
  <Layout>
   <Box ml="2rem">
    <Heading as="h2" mb="2rem">
     Settings
    </Heading>
    <Box pl="0">
     <Breadcrumb
      borderRadius="5px"
      padding="1.5"
      w="50%"
      h="50"
      spacing="8px"
      separator={<ChevronRightIcon color="gray.500" />}
     >
      <BreadcrumbItem onClick={() => history.push("/settings")}>
       <BreadcrumbLink color="gray.500" fontWeight={"600"}>
        Settings
       </BreadcrumbLink>
      </BreadcrumbItem>
       <BreadcrumbItem>
        <BreadcrumbLink
         fontWeight={"600"}
         color={query.page ? "gray.500" : ""}
         onClick={() => history.push("/settings/master-data/configuration")}
        >
         Master Data Configuration
        </BreadcrumbLink>
       </BreadcrumbItem>
       {query.page &&
       <BreadcrumbItem>
        <BreadcrumbLink
         fontWeight={"600"}
         onClick={() => history.push("/settings/master-data/configuration")}
        >
         {query.page === "created" ? "Create New" : "Edit"}
        </BreadcrumbLink>
       </BreadcrumbItem>}
     </Breadcrumb>
    </Box>
    {!query.page && (
     <Flex mt={10}>
      <Box>
      {masterData?.length !== 0 && <Link to="/settings/master-data/configuration?page=created">
        <Button
         onClick={() => setPageNumber(1)}
         h="50px"
         w="200px"
         borderRadius={"5px"}
         colorScheme={"blue"}
        >
         Create New
        </Button>
       </Link>}
      </Box>
      <Spacer />
      <SearchBar
       isLoading={loading}
       placeholder="Search"
       onChange={(val) => {
        if (val.currentTarget.value === "") {
         setPageNumber(1);
        }
        dispatch(
         masterDataAction.setSearchQuery({ value: val.currentTarget.value })
        );
       }}
      />
     </Flex>
    )}
    {!query.page && (
     <>
      <Box py={5}>
       {masterData?.length > 0 && (
        <PaginationNumber
         pageNumber={totalPage}
         currentPage={pageNumber}
         totalAllPage={totalCount || 10}
         totalRowsPage={maxResultCount}
        />
       )}
      </Box>
      {masterData?.length > 0 ? (
       <Box>
        <Table
         loading={loading}
         columnHeaders={COLUMN_HEADERS}
         data={orderBy(DataTable, ["identityName"], ["asc"]) || []}
         actionButtons={(id: string) => (
          <HStack spacing="24px">
           <Box w="40px" h="40px">
            <Link
             to={{
              pathname: `/settings/master-data/configuration`,
              search: `?page=updated`,
              state: {
               id: id,
               isEditing: true,
              },
             }}
            >
             <Image
              src={IconEdit}
              onClick={() => setPageNumber(1)}
              cursor="pointer"
              alt="IoMdEdit"
              mr="1rem"
              w="24px"
              h="24px"
             />
            </Link>
           </Box>
           <Box w="40px" h="40px">
            <Image
             src={IconDelete}
             onClick={async () => {
              Swal.fire({
               showCancelButton: true,
               html: "Are you sure you want to delete this Master Data?",
               confirmButtonText: "Yes",
               cancelButtonText: "No",
              }).then(async (result) => {
               if (result.isConfirmed) {
                dispatch(
                 masterDataAction.deleteMasterDataConfigurations(id)
                ).then(() => {
                 setPageNumber(1);
                 fetchList({
                  skipCount: 0,
                  maxResultCount: 10,
                  searchKey: searchQuery,
                 });
                });
               }
              });
             }}
             cursor="pointer"
             alt="IoMdTrash"
             mr="1rem"
             w="24px"
             h="24px"
            />
           </Box>
          </HStack>
         )}
        />
       </Box>
      ) : loading ? (<TableLoading />) : (<EmptyState isSearch={!isEmpty(searchQuery)}>
        <Button
       onClick={() =>
        history.push("/settings/master-data/configuration?page=created")
       }
       h="50px"
       w="200px"
       borderRadius={"5px"}
       colorScheme={"blue"}
      >
       Create New
      </Button>
      </EmptyState>)}
      <Box w="100%" mt="1rem" p="20px">
       {masterData?.length > 0 && (
        <NewPagination
         onChange={handleChangePagination}
         totalRowsPage={maxResultCount}
         pageNumber={Math.ceil((totalCount || 0) / maxResultCount)}
         currentPage={pageNumber}
         totalAllPage={totalCount || 0}
        />
       )}
      </Box>
     </>
    )}
    {query.page === "created" && <FormInput />}
    {query.page === "updated" && <FormInput />}
   </Box>
  </Layout>
 );
};

export default Configuration;
