import { ReactElement, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { NumberLoading, TextLoading } from "components/Loading";
import { Box, Heading, Grid, Flex, Text, Spacer } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import Layout from "views/Layout";
import SearchBar from "components/SearchBar";
import ListTable from "./ListTable";
import StepInfo from "components/StepInfo";

const AnnualSpend = (): ReactElement => {
 const [loading, setLoading] = useState(false);
 const history = useHistory();

 return (
  <Layout>
   <Flex>
    <Heading>Simulation</Heading>
    <Spacer />
    <SearchBar
     value=""
     onChange={(e) => e.preventDefault()}
     placeholder={"Search"}
     isLoading={false}
    />
   </Flex>
   <Box textColor="#43464B">
    In this module, you can perform what-if simulation on the prices of various
    components.
    <br /> There are four steps to perform Annual Spend Simulations:
   </Box>
   <Box pb="1rem">
    <Grid templateColumns="repeat(4, 1fr)" gap={8} pt="2.5rem" pb="1rem">
     <StepInfo>
      <Box
       as="p"
       pb="3px"
       fontSize="48"
       textColor="rgba(26, 26, 26, 1)"
       fontWeight="600"
      >
       {loading ? <NumberLoading /> : "01"}
      </Box>
      <Box
       as="p"
       fontSize="18px"
       fontWeight="500"
       lineHeight="23.4px"
       color="rgba(26, 26, 26, 0.56)"
      >
       {loading ? <TextLoading /> : "Select which Journey for your simulation"}
      </Box>
     </StepInfo>
     <StepInfo>
      <Box
       as="p"
       pb="3px"
       fontSize="48"
       textColor="rgba(26, 26, 26, 1)"
       fontWeight="600"
      >
       {loading ? <NumberLoading /> : "02"}
      </Box>
      <Box
       as="p"
       fontSize="18px"
       fontWeight="500"
       lineHeight="23.4px"
       color="rgba(26, 26, 26, 0.56)"
      >
       {loading ? (
        <TextLoading />
       ) : (
        "Upload your pricing list and the demand for the simulation"
       )}
      </Box>
     </StepInfo>
     <StepInfo>
      <Box
       as="p"
       pb="3px"
       fontSize="48"
       textColor="rgba(26, 26, 26, 1)"
       fontWeight="600"
      >
       {loading ? <NumberLoading /> : "03"}
      </Box>
      <Box
       as="p"
       fontSize="18px"
       fontWeight="500"
       lineHeight="23.4px"
       color="rgba(26, 26, 26, 0.56)"
      >
       {loading ? (
        <TextLoading />
       ) : (
        "Select which pricing’s components for the simulation"
       )}
      </Box>
     </StepInfo>
     <StepInfo>
      <Box
       as="p"
       pb="3px"
       fontSize="48"
       textColor="rgba(26, 26, 26, 1)"
       fontWeight="600"
      >
       {loading ? <NumberLoading /> : "04"}
      </Box>
      <Box
       as="p"
       fontSize="18px"
       fontWeight="500"
       lineHeight="23.4px"
       color="rgba(26, 26, 26, 0.56)"
      >
       {loading ? (
        <TextLoading />
       ) : (
        "Simulate the components cost by percentage"
       )}
      </Box>
     </StepInfo>
    </Grid>
   </Box>
   <Box width="80%" mt="4rem" mb="2rem">
   </Box>
   <Box mt="2rem">
    <ListTable />
   </Box>
  </Layout>
 );
};

export default AnnualSpend;
