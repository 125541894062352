import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";

export const journey = createAsyncThunk("annualSpend/journey", async () => {
 const response: any = await HttpService.get("SimulationAnnualSpends/journeys");
 return response.result;
});
export const download = createAsyncThunk(
 "annualSpend/download",
 async ({ id }: any) => {
  return await HttpService.get(`/SimulationAnnualSpends/template/${id}`, {
   responseType: "blob",
  }).then((response: any) => {
   const url = window.URL.createObjectURL(new window.Blob([response]));
   const link = document.createElement("a");
   link.href = url;
   link.setAttribute("download", "Optipedia Simulation Data Upload.xlsx");
   document.body.appendChild(link);
   link.click();
   link.remove();
  });
 }
);

export const upload = createAsyncThunk(
 "annualSpend/template",
 async ({ payload }: any) => {
    const config = {
        headers: {
         notUseToast: true,
        },
       };
  const formData = new FormData();
  const file = payload.file[0] as any;
  const journeyId = payload.journeyId;
  formData.append("file", file);
  formData.append("journeyId", journeyId);
  const response: any = await HttpService.post(
   "SimulationAnnualSpends/template",
   formData,
   config
  );
  return response;
 }
);

export const components = createAsyncThunk(
 "annualSpend/component",
 async ({ payload }: any) => {
  const data = payload as any;
  const response: any = await HttpService.post(
   "SimulationAnnualSpends/components",
   data
  );
  return response;
 }
);
export const subComponents = createAsyncThunk(
 "annualSpend/subComponent",
 async ({ payload }: any) => {
  const data = payload as any;
  const response: any = await HttpService.post(
   "SimulationAnnualSpends/subComponents",
   data
  );
  return response;
 }
);
export const simulate = createAsyncThunk(
 "annualSpend/simulate",
 async ({ payload }: any) => {
    const config = {
        headers: {
         notUseToast: true,
        },
       };
  const data = payload as any;
  const response: any = await HttpService.post(
   "SimulationAnnualSpends/simulate",
   data, config
  );
  return response;
 }
);
export const simulateBarChart = createAsyncThunk(
 "annualSpend/simulateBarChart",
 async ({ payload, isCurrent, level }: any) => {
  const data = payload as any;
  const response: any = await HttpService.post(
   "SimulationAnnualSpends/simulate/charts",
   data,
   {
    params: {
     isCurrent,
     level,
    },
    headers: {
        notUseToast: true,
       },
   }
  );
  return response;
 }
);
export const simulateTreemapChart = createAsyncThunk(
 "annualSpend/simulateTreemapChart",
 async ({ payload, isCurrent, level }: any) => {
  const data = payload as any;
  const response: any = await HttpService.post(
   "SimulationAnnualSpends/simulate/charts",
   data,
   {
    params: {
     isCurrent,
     level,
    },
    headers: {
        notUseToast: true,
       },
   }
  );
  return response;
 }
);
export const save = createAsyncThunk(
 "annualSpend/save",
 async (payload: any) => {
  const data = payload as any;
  const response: any = await HttpService.post(
   "SimulationAnnualSpends/SaveBusinessCase",
   data
  );
  return response;
 }
);
export const simulation = createAsyncThunk(
 "annualSpend/simulation",
 async (params: any) => {
  const response: any = await HttpService.get("SimulationAnnualSpends", {
   params,
  });
  return response.result;
 }
);
export const deletes = createAsyncThunk(
 "annualSpend/delete",
 async ({ id }: any) => {
  const response: any = await HttpService.delete(
   `SimulationAnnualSpends/${id}`
  );
  return response.success;
 }
);
export const journeys = createAsyncThunk(
 "annualSpend/comparation/journeys",
 async ({ id }: any) => {
  const response: any = await HttpService.get(
   `SimulationAnnualSpends/journeys/${id}`
  );
  return response;
 }
);
export const compare = createAsyncThunk(
 "annualSpend/compare",
 async (data: any) => {
  const response: any = await HttpService.post(
   "SimulationAnnualSpends/compare",
   data
  );
  return response;
 }
);
export const simulationById = createAsyncThunk(
 "annualSpend/getSimulationId",
 async ({ id, isCurrent }: any) => {
  const response: any = await HttpService.get(`SimulationAnnualSpends/${id}`, {
   params: {
    isCurrent,
   },
  }
  );
  return response.result;
 }
);
export const getTreemapChartSimulationId = createAsyncThunk(
 "annualSpend/getTreemapChartSimulationId",
 async ({ id, isCurrent, level }: any) => {
  const response: any = await HttpService.get(
   `SimulationAnnualSpends/${id}/charts`,
   {
    params: {
     isCurrent,
     level,
    },
   }
  );
  return response.result;
 }
);
export const getBarChartSimulationId = createAsyncThunk(
 "annualSpend/getBarChartSimulationId",
 async ({ id, isCurrent, level }: any) => {
  const response: any = await HttpService.get(
   `SimulationAnnualSpends/${id}/charts`,
   {
    params: {
     isCurrent,
     level,
    },
   }
  );
  return response.result;
 }
);
