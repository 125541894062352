import { ReactElement, useState, useRef, useEffect, useMemo } from "react";
import { Box, Flex, Spacer } from "@chakra-ui/layout";
import { Image, Center, Text } from "@chakra-ui/react";
import { RootState } from "state/store";
import { useSelector, shallowEqual } from "react-redux";
import { useAppDispatch } from "hooks";
import { FieldArray, Form, Formik } from "formik";
import { ArrowDownIcon } from "components/Icons";

import { actions as pricingCostDriverAction } from "state/pricings/costDrivers/slice";
import TableView from "views/NewSimulationBySku/CostDrivers/SimulateTableCostDriver/TableView";
// import AddRowView from "views/NewSimulationBySku/CostDrivers/SimulateTableCostDriver/AddRow";

const SimulateTableCostDriver = ({
 costDriver,
 paramName,
 level,
}: any): ReactElement => {
 const dispatch = useAppDispatch();
 const [isShowTableDetail, toogleShowTableDetail] = useState(true);
 const [removeLogistic, setRemoveLogistic] = useState<boolean>(false);
 const [widthBox, setWidthBox] = useState(0);
 const ref = useRef<HTMLDivElement>(null);
 const [initialValues, setInitialValues] = useState<any>();
 const {
  detailPerVolumeId,
  temporaryDataCostDriver,
  constDriversOri,
  tmpChangeRow,
  stateTmpId,
  isAddNew,
  volumeId,
 } = useSelector(
  (state: RootState) => ({
   detailPerVolumeId: state.pricingCostDrivers.detailPerVolumeId.result,
   dataToSendadds: state.pricingCostDrivers.dataToSend.adds,
   dataToSend: state.pricingCostDrivers.dataToSend,
   loadingSave: state.pricingCostDrivers.loading,
   temporaryDataCostDriver: state.pricingCostDrivers.temporaryDataCostDriver,
   valueIsIncludeCalculation:
    state.pricingCostDrivers.valueIsIncludeCalculation,
   constDriversOri: state.pricingCostDrivers.constDriversOri,
   tmpChangeRow: state.pricingCostDrivers.tmpChangeRow,
   stateTmpId: state.pricingCostDrivers.stateTmpId,
   isAddNew: state.pricingCostDrivers.isAddNew,
   oldValue: state.pricingCostDrivers.oldValue,
   volumeId: state.pricingCostDrivers.volumeId,
  }),
  shallowEqual
 );

 const onToogleShowTableDetail = () => {
  toogleShowTableDetail(!isShowTableDetail);
 };

 useEffect(() => {
  if (ref.current && ref.current.offsetWidth) {
   setWidthBox(ref.current.offsetWidth);
  }
 }, [ref.current]);

 useEffect(() => {
  const costDrivers: any = [];
  Array.isArray(costDriver?.data) &&
   costDriver?.data?.map(async (item: any) => {
    var dataItem = Object.assign({}, {...item});
    let itemIndex = tmpChangeRow.find((item2: any) => {
     return item2.idRow == dataItem.id;
     //  if (dataItem.customFields.length > 0) {
     //   const findIdCustomField = dataItem.customFields.findIndex((el: any) => {
     //    return el.id === item2.idRow;
     //   });

     //   return findIdCustomField;
     //  }
     //  return item2.idRow == dataItem.id;
    });

    dataItem.unitCost =
     item?.unitCost === 0
      ? 0
      : isNaN(item?.unitCost)
      ? Number.isNaN(item.unitCost)
        ? ""
        : item?.unitCost && item?.unitCost?.replace("%", "")
        ? item?.unitCost
        : ""
      : item?.unitCost;

    const findIdParam = temporaryDataCostDriver.findIndex(
     (el: any) => el.name === paramName
    );

    let ishaveBgColor = false;

    if (itemIndex?.addBgColor) {
     ishaveBgColor = true;
     temporaryDataCostDriver[findIdParam].data.map(
      (itemTmp: any, index: number) => {
       if (constDriversOri?.data) {
        itemTmp.data.map((tmpD: any, inx: number) => {
         if (
          constDriversOri?.data.length > 0 &&
          constDriversOri?.data[index]?.data.length > 0 &&
          constDriversOri?.data[index]?.data[inx] !== undefined
         ) {
         }
        });
       }
      }
     );
    } else {
     temporaryDataCostDriver[findIdParam].data.map(
      (itemTmp: any, index: number) => {
       if (constDriversOri?.data) {
        itemTmp?.data?.map((tmpD: any, inx: number) => {
         if (
          constDriversOri?.data.length > 0 &&
          constDriversOri?.data[index]?.data.length > 0 &&
          constDriversOri?.data[index]?.data[inx] === undefined
         ) {
          if (!constDriversOri?.data[index]?.data[inx]) {
           ishaveBgColor = true;
          }
         }
        });
       }
      }
     );
    }

    dataItem.bgColor =
     itemIndex?.addBgColor || ishaveBgColor ? "#BBDEFB" : "#ffffff";
    const objectArray: any = [];
    Array.isArray(item.customFields) &&
     item.customFields.length > 0 &&
     item.customFields
      .filter((item: any) => item.isDisplayOnPricing !== false)
      .map(async (itemCustomField: any) => {
       const value = itemCustomField.name;
       if (itemCustomField?.isMaintenanceByMasterData) {
        dataItem[`${value}-From-Masterdata`] = itemCustomField.value;
       } else {
        dataItem[value] = itemCustomField.value;
       }
       const data = {
        name: itemCustomField.isMaintenanceByMasterData
         ? `${value}-From-Masterdata`
         : value,
        value: itemCustomField.value,
        masterData: itemCustomField.masterData,
        typeData: itemCustomField.typeData,
        isIncludeInCalculation: itemCustomField.isIncludeInCalculation || false,
        isRequired: itemCustomField.isRequired,
        isDisplayOnPricing: itemCustomField.isDisplayOnPricing,
        customFieldId: itemCustomField.id,
        originalValue: itemCustomField.value,
        exchangeRateId: itemCustomField.exchangeRateId,
        totalCostCurrency: itemCustomField.totalCostCurrency,
        exchangeRateToCurrency: itemCustomField.exchangeRateToCurrency,
        totalCostToCurrency: itemCustomField.totalCostToCurrency,
        currency: itemCustomField.currency,
        currencyId: itemCustomField?.currencyId || null,
        isMaintenanceByMasterData: itemCustomField.isMaintenanceByMasterData,
        customFieldConfigurationId: itemCustomField.customFieldConfigurationId,
       };
       objectArray.push(data);
      });
    Array.isArray(item?.masterDataCustomFields) &&
     item.masterDataCustomFields.map((itemCustomField: any) => {
      dataItem[itemCustomField.name] = itemCustomField.value;
     });
    dataItem.customFields = objectArray;
    costDrivers.push(dataItem);
   });
  setInitialValues({
   customFields: costDrivers,
  });

  dispatch(pricingCostDriverAction.setAddNew(false));
 }, [costDriver, tmpChangeRow, isAddNew, volumeId]);

 useEffect(() => {
  if (removeLogistic) {
   dispatch(pricingCostDriverAction.removeOneLogistic(stateTmpId));
   dispatch(
    pricingCostDriverAction.removeOnetmpChangeRow(costDriver.costDriverId)
   );
   setRemoveLogistic(false);
  }
 }, [removeLogistic]);

 useEffect(() => {
  dispatch(
   pricingCostDriverAction.setCostAnalysisPerVolumeId(detailPerVolumeId.id)
  );
 }, [detailPerVolumeId, volumeId]);

 return (
  <Formik
   enableReinitialize
   initialValues={initialValues}
   onSubmit={async (values) => {}}
   validateOnChange
  >
   {({ setFieldValue, values }) => {
    return (
     <Form>
      <Box
       w="100%"
       h={!isShowTableDetail ? "7rem" : "100%"}
       mb="2rem"
       position="relative"
       padding="0.5rem"
      >
       <Flex ref={ref} alignItems="baseline">
        <Box as="p" fontWeight="bold" ml="0.5rem" mt="1rem">
         {costDriver.identityName}
        </Box>
       </Flex>
       {isShowTableDetail && (
        <Box overflowX="auto" maxW={`${widthBox}px`}>
         <FieldArray
          name="customFields"
          render={(arrayHelpers) => {
           return (
            <>
             <TableView
              costDriver={costDriver}
              paramName={paramName}
              level={level}
              values={values}
              arrayHelpers={arrayHelpers}
              setFieldValue={setFieldValue}
             />
            </>
           );
          }}
         />
        </Box>
       )}
      </Box>
     </Form>
    );
   }}
  </Formik>
 );
};

export default SimulateTableCostDriver;
