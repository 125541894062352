import { RootState } from "state/store";
import { ReactElement, useEffect } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { Box, Flex, Spacer, Stack } from "@chakra-ui/layout";
import { Image, Button } from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import OptipediaToast from "utils/notification-util";
import { actions as simulateAction } from "state/simulation/slice";
import UploadIcon from "assets/images/uploadIcon.png";

const DatasetOverview = (): ReactElement => {
 const dispatch = useAppDispatch();
 const history = useHistory();
 const router = useHistory();
 const {
  loading,
  loadingDownload,
  journeyId,
  successUpload,
  fileUpload,
  uploadResponse,
 } = useSelector(
  (state: RootState) => ({
   loading: state.simulationAggregate.loading,
   loadingDownload: state.simulationAggregate.loadingDownload,
   successUpload: state.simulationAggregate.successUpload,
   fileUpload: state.simulationAggregate.fileUpload,
   uploadResponse: state.simulationAggregate.uploadResponse,
   journeyId: state.simulationAggregate.journeyId,
  }),
  shallowEqual
 );

 useEffect(() => {
  if (loading) {
   const unloadCallback = (event: any) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
   };

   window.addEventListener("beforeunload", unloadCallback);
   return () => window.removeEventListener("beforeunload", unloadCallback);
  }
  window.scrollTo(0, 0);
 }, [successUpload, loading]);

 const onClickDownload = () => {
  dispatch(simulateAction.download({ id: journeyId }));
 };
 const handleDrop = (acceptedFiles: any) => {
  dispatch(
   simulateAction.upload({
    payload: {
     journeyId: journeyId,
     file: acceptedFiles as any,
    },
   })
  );
  dispatch(simulateAction.updateFileUpload(acceptedFiles as any));
 };

 const handleNextStep = () => {
  if (successUpload) {
   dispatch(
    simulateAction.components({
     payload: {
      costAnalysises: uploadResponse,
     },
    })
   );
   router.push("/simulations/create?page=select-component-step-1");
  } else {
   OptipediaToast.Warning(
    "Please upload the template before proceeding to the next step."
   );
  }
 };

 return (
  <Box w="100%" mb="6rem">
   {loading && (
    <Box>
     <Button
      bg="#9DA1A7"
      mt="32px"
      ml="350px"
      w="252px"
      h="59"
      borderRadius="50"
      fontSize="18"
      color="white"
      disabled
     >
      Download template
     </Button>
     <Box
      mt="1.688rem"
      ml="16.25rem"
      w="26.25rem"
      h="25.75rem"
      borderRadius="2.5rem"
      border="2px dashed #9DA1A7"
      box-sizing="border-box"
     >
      <Image src={UploadIcon} alt="Bulp" pl="190px" pr="190px" pt="93px" />
      <Box
       w="78px"
       display="flex"
       alignItems="center"
       ml="auto"
       mr="auto"
       mt={5}
      >
       <Loader type="TailSpin" color="#9DA1A7" height="78" width="79" />
      </Box>
      <Button
       bg="#9DA1A7"
       mt="24px"
       ml="90px"
       mr="101px"
       mb="108px"
       w="252px"
       h="59"
       borderRadius="50"
       fontSize="18"
       color="white"
       disabled
      >
       Select file
      </Button>
     </Box>
    </Box>
   )}
   {!loading && (
    <Box>
     {!loadingDownload ? (
      <Button
       bg="#0072CF"
       mt="32px"
       ml="350px"
       w="252px"
       h="59"
       borderRadius="50"
       fontSize="18"
       color="white"
       _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
       onClick={() => onClickDownload()}
       isLoading={loadingDownload}
       loadingText="Downloading..."
      >
       Download template
      </Button>
     ) : (
      <Button
       bg="#0072CF"
       mt="32px"
       ml="350px"
       w="252px"
       h="59"
       borderRadius="50"
       fontSize="18"
       color="white"
       _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
       onClick={() => onClickDownload()}
       isLoading={loadingDownload}
       loadingText="Downloading..."
      >
       Download template
      </Button>
     )}
     <Dropzone
      onDrop={handleDrop}
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
     >
      {({ getRootProps, getInputProps }) => (
       <Box
        {...getRootProps({ className: "dropzone" })}
        mt="1.688rem"
        ml="16.25rem"
        w="26.25rem"
        h="23.75rem"
        borderRadius="2.5rem"
        border="2px dashed #0072CF"
        box-sizing="border-box"
       >
        <Image src={UploadIcon} alt="Bulp" pl="190px" pr="190px" pt="93px" />
        <Flex>
         <Spacer />
         {!successUpload ? (
          <Box fontSize="18" textColor="#43464B" pt="23px" textAlign="center">
           Drag and drop <br />
           or
          </Box>
         ) : (
          <Box fontSize="18" textColor="#43464B" pt="23px" textAlign="center">
           {fileUpload.arg.payload.file[0].name}
          </Box>
         )}
         <Spacer />
        </Flex>

        <Button
         bg="#0072CF"
         mt="24px"
         ml="90px"
         mr="101px"
         mb="108px"
         w="252px"
         h="59"
         borderRadius="50"
         fontSize="18"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
        >
         Select file
         <input {...getInputProps()} />
        </Button>
       </Box>
      )}
     </Dropzone>
    </Box>
   )}
   <Box
    position="fixed"
    bottom="0"
    backgroundColor="#ffffff"
    height="5.5rem"
    w="100%"
    zIndex={2}
   >
    <Flex float="right" mr="20rem">
     <Box mt={4}>
      <Flex alignItems="center" gap="2">
       <>
        <Button
         bg="#C3C7CD"
         w="202px"
         h="59"
         borderRadius="50"
         fontSize="18"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         onClick={() => router.goBack()}
        >
         Back
        </Button>
        <Button
         bg="#0072CF"
         w="202px"
         h="59"
         borderRadius="50"
         fontSize="18"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         onClick={handleNextStep}
        >
         Next
        </Button>
       </>
      </Flex>
     </Box>
    </Flex>
   </Box>
  </Box>
 );
};

export default DatasetOverview;
