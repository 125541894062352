import { createSlice } from "@reduxjs/toolkit";
import { includes, isArray } from "lodash";
import {
 approvalList,
 approvalSubmit,
 approvalReject,
 getApprovalStatus,
 getApprovalThirdParty,
 approvalAnalyze,
 approvalAnalyzeBarChart,
 approvalAnalyzeMapChart,
 pendingApprovalList,
 getApprovalConfiguration,
} from "state/approval/thunk";

interface InitialState {
 lists: any;
 searchQuery: string;
 loading: boolean;
 barLoading: boolean;
 mapLoading: boolean;
 error: unknown;
 sidebarItemOnClick: string;
 ids: any;
 thirdPartySelection: any;
 nonThirdPartySelection: any;
 pricings: any;
 approvalSelect: string[];
 approvalStatus: any;
 statusUpdate: any;
 thirdParties: any;
 analyze: any;
 pendingList: any;
 barChart: any;
 mapChart: any;
 comparison: boolean;
 configuration: any;
}

const initialState: InitialState = {
 lists: {
  result: [],
 },
 sidebarItemOnClick: "",
 searchQuery: "",
 loading: false,
 barLoading: false,
 mapLoading: false,
 error: null,
 ids: [],
 thirdPartySelection: [],
 nonThirdPartySelection: [],
 pricings: [],
 approvalSelect: [],
 approvalStatus: {
  result: [],
 },
 statusUpdate: {
  result: [],
  success: false,
 },
 thirdParties: [],
 analyze: [],
 pendingList: {
  result: [],
 },
 barChart: [],
 mapChart: [],
 comparison: false,
 configuration: [],
};

const approvalListSlice = createSlice({
 name: "approvalList",
 initialState,
 reducers: {
  clear: () => initialState,
  setComparison: (state, action) => {
   const value: boolean = action.payload;
   state.comparison = value;
  },
  setSearchQuery: (state, action) => {
   const { value } = action.payload;
   state.searchQuery = value;
  },
  resetQuery: (state) => {
   state.searchQuery = "";
  },
  thirdPartySelection: (state, action) => {
   const newArray = [
    "00000000-0000-0000-0000-000000000000",
    "99999999-9999-9999-9999-999999999999",
   ];
   const data = state.thirdParties?.result?.filter(
    (item: any) => !newArray.includes(item.id)
   );
   const items = data.map((item: any) => item.id);
   if (isArray(action.payload)) {
    state.thirdPartySelection = action.payload;
   } else {
    state.thirdPartySelection.push(action.payload);
    if (state.thirdPartySelection.length === items.length) {
     state.thirdPartySelection.push("00000000-0000-0000-0000-000000000000");
    }
   }
  },
  removeThirdPartySelection: (state, action) => {
   function removeItem(array: any, item: string) {
    for (var i in array) {
     if (array[i] == item) {
      array.splice(i, 1);
      break;
     }
    }
   }
   const newArray = [
    "00000000-0000-0000-0000-000000000000",
    "99999999-9999-9999-9999-999999999999",
   ];
   const data = state.thirdParties?.result?.filter(
    (item: any) => !newArray.includes(item.id)
   );
   const items = data.map((item: any) => item.id);
   if (data.length === items.length) {
    removeItem(
     state.thirdPartySelection,
     "00000000-0000-0000-0000-000000000000"
    );
   }
   if (action.payload === "00000000-0000-0000-0000-000000000000") {
    state.thirdPartySelection = [];
   } else {
    removeItem(state.thirdPartySelection, action.payload);
   }
  },
  nonThirdPartySelection: (state, action) => {
   state.nonThirdPartySelection.push(action.payload);
  },
  removeNonThirdPartySelection: (state, action) => {
   function removeItem(array: any, item: string) {
    for (var i in array) {
     if (array[i] == item) {
      array.splice(i, 1);
      break;
     }
    }
   }
   removeItem(state.nonThirdPartySelection, action.payload);
  },
  setPricingSelection: (state, action) => {
   state.pricings.push(action.payload);
  },
  setApprovalSelection: (state, action) => {
   state.approvalSelect.push(action.payload);
  },
  setAllApprovalSelection: (state, action) => {
    state.approvalSelect = action.payload;
   },
  removePricingSelection: (state, action) => {
   function removeItem(array: any, item: string) {
    for (var i in array) {
     if (array[i] == item) {
      array.splice(i, 1);
      break;
     }
    }
   }
   removeItem(state.pricings, action.payload);
  },
  removeApprovalSelection: (state, action) => {
   function removeItem(array: any, item: string) {
    for (var i in array) {
     if (array[i] == item) {
      array.splice(i, 1);
      break;
     }
    }
   }
   removeItem(state.approvalSelect, action.payload);
  },
  removeAllSelection: (state) => {
   state.ids = [];
  },
  setSidebarOnClick: (state, action) => {
   const { value } = action.payload;
   state.sidebarItemOnClick = value;
  },
 },
 extraReducers: (builder) => {
  builder.addCase(approvalList.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(approvalList.fulfilled, (state, response) => {
   state.lists = response && response.payload;
   state.loading = false;
  });

  builder.addCase(approvalList.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  // Approval SUbmit
  builder.addCase(approvalSubmit.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(approvalSubmit.fulfilled, (state, action) => {
   state.loading = false;
   state.statusUpdate = action.payload;
  });

  builder.addCase(approvalSubmit.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  // Reject
  // Approval SUbmit
  builder.addCase(approvalReject.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(approvalReject.fulfilled, (state, action) => {
   state.loading = false;
   state.statusUpdate = action.payload;
  });

  builder.addCase(approvalReject.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  // Approval Status
  builder.addCase(getApprovalStatus.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getApprovalStatus.fulfilled, (state, action) => {
   state.loading = false;
   state.approvalStatus = action && action.payload;
  });

  builder.addCase(getApprovalStatus.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  // Approval ThirdParties
  builder.addCase(getApprovalThirdParty.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getApprovalThirdParty.fulfilled, (state, action) => {
   state.loading = false;
   state.thirdParties = action && action.payload;
  });

  builder.addCase(getApprovalThirdParty.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  // Approval Analyze
  builder.addCase(approvalAnalyze.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(approvalAnalyze.fulfilled, (state, action) => {
   state.loading = false;
   state.analyze = action && action.payload;
  });

  builder.addCase(approvalAnalyze.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  // Approval Analyze Bar Chart
  builder.addCase(approvalAnalyzeBarChart.pending, (state) => {
   state.barLoading = true;
   state.error = null;
  });

  builder.addCase(approvalAnalyzeBarChart.fulfilled, (state, action) => {
   state.barLoading = false;
   state.barChart = action && action.payload;
  });

  builder.addCase(approvalAnalyzeBarChart.rejected, (state, action) => {
   state.barLoading = false;
   state.error = action.payload;
  });

  // Approval Analyze Map Chart
  builder.addCase(approvalAnalyzeMapChart.pending, (state) => {
   state.mapLoading = true;
   state.error = null;
  });

  builder.addCase(approvalAnalyzeMapChart.fulfilled, (state, action) => {
   state.mapLoading = false;
   state.mapChart = action && action.payload;
  });

  builder.addCase(approvalAnalyzeMapChart.rejected, (state, action) => {
   state.mapLoading = false;
   state.error = action.payload;
  });
  // List
  builder.addCase(pendingApprovalList.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(pendingApprovalList.fulfilled, (state, response) => {
   state.pendingList = response && response.payload;
   state.loading = false;
  });

  builder.addCase(pendingApprovalList.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // Product Configuration
  builder.addCase(getApprovalConfiguration.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getApprovalConfiguration.fulfilled, (state, response) => {
   state.configuration = response && response.payload;
   state.loading = false;
  });

  builder.addCase(getApprovalConfiguration.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
 },
});

export const actions = {
 ...approvalListSlice.actions,
 approvalList,
 approvalSubmit,
 approvalReject,
 getApprovalStatus,
 getApprovalThirdParty,
 approvalAnalyze,
 approvalAnalyzeBarChart,
 approvalAnalyzeMapChart,
 pendingApprovalList,
 getApprovalConfiguration,
};

export const reducer = approvalListSlice.reducer;
