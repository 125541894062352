import { ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Layout from "views/Layout";
import { Box, Flex, Text, HStack, Heading, Spacer } from "@chakra-ui/layout";
import { useSelector } from "react-redux";
import { useAppDispatch } from "hooks";
import { RootState } from "state/store";
import { numberWithCommas } from "utils";
import { actions as masterDataAction } from "state/masterData/slice";
import {
 Button,
 IconButton,
 Image,
 Menu,
 MenuButton,
 MenuItem,
 MenuList,
 Center,
 Select,
 InputRightElement,
 InputGroup,
} from "@chakra-ui/react";
import IconBack from "assets/images/Back.png";
import Swal from "sweetalert2";
import queryString from "query-string";
import Table from "components/Table/Table";
import SearchBar from "components/SearchBar";
import IconKebab from "assets/images/3dots.png";
import FormInput from "./FormInput";
import { TableLoading } from "components/Loading";
import DataUpload from "./Upload";
import { isArray, isEmpty, orderBy } from "lodash";
import NewPagination from "components/NewPagination";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import { MdArrowDropDown } from "react-icons/md";
import OptipediaToast from "utils/notification-util";
import DownloadIcon from "assets/images/Download.png";
import EmptyState from "components/EmptyState";

const MasterDataDetail = (): ReactElement => {
 const history = useHistory();
 const dispatch = useAppDispatch();
 const router = useLocation();
 const masterDataId = router.pathname.split("/")[2];
 const maxResultCount = 10;
 const { location } = history;
 const { search } = location;
 const query = queryString.parse(search);

 const {
  totalPage,
  masterDataList,
  totalCount,
  searchQuery,
  loading,
  title,
  isEnableThirdPartyStatus,
  thirdParty,
  loadingDelete,
  user,
 } = useSelector((state: RootState) => ({
  masterDataList: state.masterData.lists?.result,
  totalPage: Math.ceil(
   state.masterData.lists?.result?.totalCount / maxResultCount
  ),
  totalCount: state.masterData.lists?.result?.totalCount,
  searchQuery: state.masterData.searchQuery,
  loading: state.masterData.loading,
  title: state.masterData.title,
  loadingDelete: state.masterData.loadingDelete,
  isEnableThirdPartyStatus: state.thirdPartySetting.isEnableThirdPartyStatus,
  thirdParty: state.thirdPartySetting.thirdParty.items,
  user: state.auth.result,
 }));

 const [pageNumber, setPageNumber] = useState(1);
 const [thirdPartyId, setThirdPartyId] = useState<string>("");

 useEffect(() => {
  dispatch(thirdPartySettingAction.configurationStatus());
 }, [isEnableThirdPartyStatus]);

 const fetchListOf3rdParty = () => {
  const payload = {
   skipCount: 0,
   maxResultCount: 100,
  };
  return dispatch(thirdPartySettingAction.getThirdPartyToShow(payload));
 };
 useEffect(() => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   searchKey: searchQuery,
   configurationId: masterDataId,
   thirdPartyId: user.idThirdParty || thirdPartyId,
  };
  if (user) {
   fetchlist(payload);
  }
  if (isEnableThirdPartyStatus) {
   fetchListOf3rdParty();
  }
  dispatch(masterDataAction.setMasterDataTitle(location.state));
 }, [
  location,
  searchQuery,
  thirdPartyId,
  isEnableThirdPartyStatus,
  loadingDelete,
 ]);

 const fetchlist = (data: any) => {
  dispatch(masterDataAction.masterDataList(data));
 };
 const COLUMN_HEADERS =
  masterDataList?.items?.length > 0
   ? [
      { name: "code", label: masterDataList?.items[0]?.configuration?.code },
      {
       name: "description",
       label: masterDataList?.items[0]?.configuration?.description,
      },
     ]
   : [];

 const COLUMN_HEADERS_WITH_COST =
  masterDataList?.items?.length > 0
   ? [
      { name: "code", label: masterDataList?.items[0]?.configuration?.code },
      {
       name: "description",
       label: masterDataList?.items[0]?.configuration?.description,
      },
      { name: "cost", label: masterDataList?.items[0]?.configuration?.cost },
     ]
   : [];

 if (masterDataList?.items[0]?.thirdParty) {
  COLUMN_HEADERS_WITH_COST.push({
   name: `thirdParty`,
   label: `Third-Party`,
  });
  COLUMN_HEADERS.push({
   name: `thirdParty`,
   label: `Third-Party`,
  });
 }

 if (Array.isArray(masterDataList?.items[0]?.customFields)) {
  orderBy(
   masterDataList?.items[0]?.customFields,
   (item) => item.configuration.sequence,
   ["asc"]
  ).map((item: any, index: number) => {
   COLUMN_HEADERS_WITH_COST.push({
    name: `value${index}`,
    label: item.configuration.displayName,
   });
   COLUMN_HEADERS.push({
    name: `value${index}`,
    label: item.configuration.displayName,
   });
  });
 }

 const dataTable: any = [];
 const newDataTable: any = [];
 if (Array.isArray(masterDataList?.items)) {
  if (masterDataList?.items[0]?.customFields.length > 0) {
   masterDataList?.items.map((item: any) => {
    orderBy(
     item.customFields,
     (data) => data.configuration.sequence,
     "asc"
    ).map((items: any, index: number) => {
     return item.thirdParty !== undefined
      ? dataTable.push({
         id: item.id,
         configuration: item.configuration || "-",
         code: item.code || "-",
         description: item.description || "-",
         cost: item.cost || "-",
         [`value${index}`]:
          items.configuration.dataType !== 30
           ? items.value || "-"
           : items.masterDataReference?.code || "-",
         thirdParty: item.thirdParty?.code || "-",
        })
      : dataTable.push({
         id: item.id,
         configuration: item.configuration || "-",
         code: item.code || "-",
         description: item.description || "-",
         cost: item.cost || "-",
         [`value${index}`]: items.value || "-",
        });
    });

    newDataTable.push(Object.assign({}, ...dataTable));
   });
  } else {
   masterDataList?.items.map((item: any) => {
    console.log(
     "items",
     numberWithCommas(parseFloat(parseFloat(item.cost).toFixed(5)))
    );
    return item.thirdParty !== undefined
     ? dataTable.push({
        id: item.id,
        configuration: item.configuration,
        code: item.code,
        description: item.description,
        cost: numberWithCommas(parseFloat(parseFloat(item.cost).toFixed(5))),
        thirdParty: item.thirdParty?.code,
       })
     : dataTable.push({
        id: item.id,
        configuration: item.configuration,
        code: item.code,
        description: item.description,
        cost: numberWithCommas(parseFloat(parseFloat(item.cost).toFixed(5))),
       });
   });
  }
 }
 const handleChangePagination = (pageNumber: number) => {
  setPageNumber(pageNumber);
  var skipCount = (pageNumber - 1) * maxResultCount;
  const payload = {
   skipCount,
   maxResultCount: maxResultCount,
   searchKey: searchQuery,
   configurationId: masterDataId,
   thirdPartyId: user.idThirdParty || thirdPartyId,
  };
  fetchlist(payload);
 };
 const handleDelete = async ({ id, rowData }: any) => {
  const payload = {
   configurationId: rowData,
   id: id,
  };
  await dispatch(masterDataAction.deleteMasterDataDetail(payload)).then(
   (response: any) => {
    if (response.payload.success) {
     OptipediaToast.Success("The data has been removed successfully.");
    }
   }
  );
 };
 const thirdPartyOption: any = [];
 if (isArray(thirdParty?.items)) {
  thirdParty?.items?.map((item: any) =>
   thirdPartyOption.push({
    label: item.code,
    value: item.id,
   })
  );
 }

 return (
  <Layout>
   <Flex
    onClick={() => {
     dispatch(masterDataAction.setCreated(false));
     dispatch(masterDataAction.setView(false));
     dispatch(masterDataAction.setEditing(false));
     dispatch(masterDataAction.setSearchQuery(""));
     history.goBack();
     const payload = {
      skipCount: 0,
      maxResultCount: maxResultCount,
      searchKey: searchQuery,
      configurationId: masterDataId,
     };
     fetchlist(payload);
    }}
    cursor="pointer"
   >
    <Image src={IconBack} alt="iconBack" />
    {query.page === "upload" ? (
     <Heading mx="1rem">Upload Dataset</Heading>
    ) : (
     <Heading mx="1rem">{title}</Heading>
    )}
   </Flex>
   {!query.page && (
    <>
     <Flex my="1.3rem">
      {!loading && masterDataList?.items?.length > 0 && (
       <>
        <Box>
         <Button
          h="50px"
          w="200px"
          borderRadius={"5px"}
          colorScheme={"blue"}
          onClick={() => {
           setPageNumber(1);
           dispatch(masterDataAction.setCreated(true));
           history.push({
            pathname: `${masterDataId}`,
            search: `page=created`,
            state: location.state,
           });
          }}
         >
          Create New
         </Button>
        </Box>
        <Box mx="1rem">
         <Button
          onClick={() => {
           history.push({
            pathname: `/master-data/${masterDataId}`,
            search: `page=upload`,
           });
          }}
          variant="outline"
          h="50px"
          w="200px"
          border={"1px"}
          borderColor={"#0072CF"}
          borderRadius={"5px"}
         >
          Upload
         </Button>
        </Box>
        <Box>
         <Button
          loadingText="Process..."
          isLoading={loading}
          size="sm"
          fontSize={16}
          variant={"link"}
          pt="3"
          onClick={() => {
           dispatch(
            masterDataAction.downloadMasterDataModule({
             configurationId: masterDataId,
             thirdPartId: user.idThirdParty,
            })
           );
          }}
          leftIcon={<Image src={DownloadIcon} />}
         >
          Download
         </Button>
        </Box>
       </>
      )}
      <Spacer />
      <SearchBar
       isLoading={loading}
       placeholder="Search..."
       onChange={(val) => {
        if (val.currentTarget.value === "") {
         setPageNumber(1);
        }
        dispatch(
         masterDataAction.setSearchQuery({
          value: val.currentTarget.value,
         })
        );
       }}
      />
     </Flex>
     {user.idThirdParty === null &&
      masterDataList?.items.length > 0 &&
      masterDataList.items[0].configuration?.isAssociateWithThirdParty && (
       <Box position="absolute" right={0} mr="1rem">
        <InputGroup size="md" mr="0.5rem">
         <Select
          border="none"
          _focus={{ border: "none" }}
          variant={"outline"}
          w={230}
          color="gray.500"
          onChange={(e) => setThirdPartyId(e.currentTarget.value)}
          placeholder="All Third-Party"
          bgColor={"white"}
         >
          {!isEmpty(thirdParty) &&
           thirdParty.map((item: any, index: number) => (
            <option key={index} value={item.id}>
             {item.description}
            </option>
           ))}
         </Select>
         <InputRightElement mr={2} children={<MdArrowDropDown />} />
        </InputGroup>
       </Box>
      )}
     {!loading &&
      Array.isArray(masterDataList?.items) &&
      masterDataList?.items?.length > 0 && (
       <Table
        havePagination={true}
        loading={loading}
        columnHeaders={
         masterDataList?.items[0]?.configuration?.isCostDisplayOnPricing
          ? COLUMN_HEADERS_WITH_COST.slice(0, 8)
          : COLUMN_HEADERS.slice(0, 8)
        }
        data={
         !isEmpty(newDataTable)
          ? orderBy(newDataTable, (e) => {
             return Number(e.code) || e.code;
            })
          : orderBy(dataTable, (e) => {
             return Number(e.code) || e.code;
            })
        }
        pageNumber={totalPage}
        currentPage={pageNumber}
        totalAllPage={masterDataList.totalCount}
        totalRowsPage={maxResultCount}
        actionButtons={(id, rowData) => {
         return (
          <Box>
           <Menu>
            <MenuButton
             as={IconButton}
             variant="ghost"
             aria-label="Options"
             _focus={{
              borderColor: "#ffffff",
             }}
             icon={
              <Image src={IconKebab} cursor="pointer" alt="Option" w="4px" />
             }
            />
            <MenuList>
             <MenuItem
              onClick={() => {
               dispatch(masterDataAction.setEditing(true));
               dispatch(masterDataAction.setCreated(false));
               dispatch(masterDataAction.setView(false));
               dispatch(masterDataAction.setMasterDataID(id));
               history.push({
                pathname: `/master-data/${masterDataId}`,
                search: `page=updated`,
                state: rowData.code,
               });
              }}
             >
              Edit
             </MenuItem>
             <MenuItem
              onClick={() => {
               Swal.fire({
                showCancelButton: true,
                html: `Are you sure you want to delete ${title}?`,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
               }).then((result) => {
                if (result.isConfirmed) {
                 handleDelete({
                  id: id,
                  rowData: rowData.configuration.id,
                 });
                }
               });
               dispatch(masterDataAction.setView(false));
               dispatch(masterDataAction.setCreated(false));
               dispatch(masterDataAction.setEditing(false));
              }}
             >
              Delete
             </MenuItem>
             <MenuItem
              onClick={() => {
               dispatch(masterDataAction.setView(true));
               dispatch(masterDataAction.setMasterDataID(id));
               dispatch(masterDataAction.setCreated(false));
               dispatch(masterDataAction.setEditing(false));
               history.push({
                pathname: `/master-data/${masterDataId}`,
                search: `page=views`,
                state: rowData.code,
               });
              }}
             >
              View
             </MenuItem>
            </MenuList>
           </Menu>
          </Box>
         );
        }}
       />
      )}
     {loading && <TableLoading />}
     {!loading &&
      Array.isArray(masterDataList?.items) &&
      masterDataList?.items?.length > 0 && (
       <Box w="100%" mt="1rem" p="20px">
        <NewPagination
         onChange={handleChangePagination}
         totalRowsPage={maxResultCount}
         pageNumber={Math.ceil(totalCount / maxResultCount)}
         currentPage={pageNumber}
         totalAllPage={totalCount && totalCount}
        />
       </Box>
      )}
     {Array.isArray(masterDataList?.items) &&
      masterDataList?.items?.length === 0 &&
      !loading && (
       <EmptyState isSearch={!isEmpty(searchQuery)}>
        {masterDataList?.items?.length === 0 && (
         <Center>
          <Box>
           <Button
            h="50px"
            w="200px"
            borderRadius={"5px"}
            colorScheme={"blue"}
            onClick={() => {
             setPageNumber(1);
             dispatch(masterDataAction.setCreated(true));
             history.push({
              pathname: `${masterDataId}`,
              search: `page=created`,
              state: location.state,
             });
            }}
           >
            Create New
           </Button>
          </Box>
          <Box mx="1rem">
           <Button
            variant="outline"
            onClick={() => {
             history.push({
              pathname: `/master-data/${masterDataId}`,
              search: `page=upload`,
             });
            }}
            h="50px"
            w="200px"
            border={"1px"}
            borderRadius={"5px"}
           >
            Upload
           </Button>
          </Box>
         </Center>
        )}
       </EmptyState>
      )}
    </>
   )}
   {query.page === "created" && <FormInput />}
   {query.page === "updated" && <FormInput />}
   {query.page === "views" && <FormInput />}
   {query.page === "upload" && <DataUpload />}
  </Layout>
 );
};

export default MasterDataDetail;
