import { Box, Center, Flex } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export const TreemapChart = (data: any) => {
 const [treemapDefaultData, setTreemapDefaultData] = useState<any>([]);
 const [treemapData, setTreemapData] = useState<any>([]);
 const [treemapColor, setTreemapColor] = useState<any>(undefined);
 useEffect(()=> {
  setTreemapDefaultData(data?.data)
  if(!isEmpty(treemapData)){
    const dataFilter = data?.data?.filter((item: any, index: number) => item.name === treemapData[0]?.name)
    setTreemapData(dataFilter)
  }
  
 }, [data?.data])
 const optColor = [
  "#2274A5",
  "#840032",
  "#E59500",
  "#3A7D44",
  "#D0DB97",
  "#F6E27F",
  "#E2C391",
  "#4BB3FD",
  "#B118C8",
  "#CDD3CE",
  "#519872",
  "#F52F57",
  "#F3752B",
  "#AEF78E",
  "#92AFD7",
  "#FA9500",
  "#A18276",
  "#659B5E",
  "#99DDC8",
  "#cff4fc",
  "#9eeaf9",
  "#6edff6",
  "#3dd5f3",
  "#0dcaf0",
  "#0aa2c0",
  "#087990",
  "#055160",
  "#032830",
  "#d2f4ea",
  "#a6e9d5",
  "#79dfc1",
  "#4dd4ac",
  "#20c997",
  "#1aa179",
  "#13795b",
  "#0d503c",
  "#06281e",
  "#198754",
  "#479f76",
  "#d1e7dd",
  "#a3cfbb",
  "#75b798",
  "#146c43",
  "#0f5132",
  "#0a3622",
  "#051b11",
  "#fff3cd",
  "#ffe69c",
  "#ffda6a",
  "#ffcd39",
  "#ffc107",
  "#cc9a06",
  "#997404",
  "#664d03",
  "#664d03",
  "#FFE5D0",
  "#fecba1",
  "#feb272",
  "#fd9843",
  "#fd7e14",
  "#ca6510",
  "#984c0c",
  "#653208",
  "#331904",
  "#f8d7da",
  "#f1aeb5",
  "#ea868f",
  "#e35d6a",
  "#dc3545",
  "#b02a37",
  "#842029",
  "#58151c",
  "#58151c",
  "#f7d6e6",
  "#efadce",
  "#e685b5",
  "#de5c9d",
  "#d63384",
  "#ab296a",
  "#801f4f",
  "#561435",
  "#2b0a1a",
  "#e2d9f3",
  "#c5b3e6",
  "#a98eda",
  "#8c68cd",
  "#6f42c1",
  "#59359a",
  "#432874",
  "#2c1a4d",
  "#160d27",
  "#e0cffc",
  "#c29ffa",
  "#a370f7",
  "#8540f5",
  "#6610f2",
  "#520dc2",
  "#3d0a91",
  "#290661",
  "#140330",
  "#140330",
  "#9ec5fe",
  "#6ea8fe",
  "#3d8bfd",
  "#0d6efd",
  "#0a58ca",
  "#084298",
  "#052c65",
  "#031633",
   ];
 const options = {
  chart: {
   layoutAlgorithm: "squarified",
   allowDrillToNode: true,
   toolbar: {
    show: false,
   },
  },
  colors: treemapColor || optColor,
  // legend: {
  //  show: true,
  //  showForSingleSeries: true,
  //  showForNullSeries: true,
  //  showForZeroSeries: true,
  //  fontSize: "14px",
  //  fontFamily: "Helvetica, Arial",
  //  fontWeight: 400,
  //  inverseOrder: true,
  //  itemMargin: {
  //   horizontal: 5,
  //   vertical: 5,
  //  },
  // },
  tooltip: {
   custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
    var items = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
    return (
     '<ul style="margin: 1rem; list-style-type:none;">' +
     `<li><b>- ${items.x}: </b>` +
     items.y.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
     }) +
     "</li>" +
     `<li><b>- ${data.isPricing ? "Total Cost:" : "Total Spend:"} </b> ` +
     items.z.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
     }) +
     "</li>" +
     `${items?.addon
      ?.map(
       (item: any) =>
        `<li>
      <b>- ${item.name}: </b>
     ${item.value.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
     })}
    </li>`
      )
      .join("")}` +
     "</ul>"
    );
   },
   style: {
    fontSize: "12px",
    padding: "2rem",
   },
  },
  plotOptions: {
   treemap: {
    enableShades: false,
    shadeIntensity: 0.5,
    distributed: data?.isDistribute,
    useFillColorAsStroke: false,
   },
  },
  dataLabels: {
   enabled: true,
   style: {
    fontSize: "16px",
    width: "50px",
   },
   formatter: function (text: any, op: any) {
    const testData: any =
     op.value !== 0
      ? [
         text,
         op.w.globals.initialSeries[op.seriesIndex].data[
          op.dataPointIndex
         ].y.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         }),
        ]
      : "";
    const dataLabel = Object.assign(testData);
    return dataLabel;
   },
  },
 };
 const handleLegend = (index: any, color: any) => {
  if (treemapDefaultData) {
   setTreemapColor([color]);
   setTreemapData([treemapDefaultData[index]]);
  }
 };
 const handleReset = () => {
  setTreemapData([]);
  setTreemapColor(undefined);
 };

 return (
  <Box>
   <ReactApexChart
    type="treemap"
    options={options}
    series={treemapData.length > 0 ? treemapData : treemapDefaultData}
    height={data.height || 300}
   />
   <Center>
    <Box style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
    {data?.data.length > 0 &&
     data?.data.map((item: any, index: number) => (
      <Button
       key={index}
       variant={"outline"}
       onClick={() => handleLegend(index, optColor[index])}
       bgColor={optColor[index]}
       borderColor={optColor[index]}
       color="white"
       size="xs"
       m={1}
      >
       {item.name}
      </Button>
     ))}
     {data?.data.length > 0 && <Button onClick={() => handleReset()} variant={"outline"} size="xs" m={1}>
      All
     </Button>}
    </Box>
   </Center>
  </Box>
 );
};
export const BarChart = (data: any) => {
 const options = {
  chart: {
   toolbar: {
    show: false,
   },
   parentHeightOffset: 0,
  },
  plotOptions: {
   bar: {
    horizontal: false,
    barWidth: "50%",
    dataLabels: {
     position: "bottom",
    },
   },
  },
  dataLabels: {
   enabled: false,
  },
  xaxis: {
   categories: data?.data[0]?.labelName,
  },
  yaxis: {
   labels: {
    formatter: function (val: any) {
     return val.toLocaleString("en-US");
    },
   },
  },
  legend: {
   show: true,
   showForSingleSeries: false,
   showForNullSeries: true,
   showForZeroSeries: true,
   fontSize: "14px",
   fontFamily: "Helvetica, Arial",
   fontWeight: 400,
   inverseOrder: false,
  },
 };
 return (
  <Box>
   <ReactApexChart
    options={options}
    series={data?.data}
    height={data?.height}
    type="bar"
   />
  </Box>
 );
};
