import { ReactElement } from "react";
import {
 Box,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalFooter,
 ModalBody,
 Button,
 ModalCloseButton,
 Flex,
 Spacer
} from "@chakra-ui/react";

const CustomField = ({ onClose, isOpen, isDisabled, isDisabledSave, onClick, onSave, children, isShow }: any): ReactElement => {
    
 return (
  <Box>
   <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen}>
    <ModalOverlay />
    <ModalContent minW="90%">
     <ModalHeader fontSize={'20px'}>Additional Field</ModalHeader>
     <ModalCloseButton color="rgb(26, 26, 26, 0.36)"/>
     <ModalBody m={"auto"}>
        {children}
     </ModalBody>
     <Flex>
     <ModalFooter justifyContent={"flex-start"}>
      <Button
      variant={'link'}
      size="custom"
       h="45px"
       fontSize={'16px'}
       ml="1.5rem"
       isDisabled={isDisabled}
       display={isShow}
       onClick={onClick}
      >
       Add New Field
      </Button>
     </ModalFooter>
     <Spacer/>
     <ModalFooter justifyContent={"flex-end"}>
      <Button
      variant={'outline'}
       w="136px"
       h="45px"
       mr="1rem"
       fontSize="16"
       display={isShow}
       onClick={onClose}
      >
       Cancel
      </Button>
      <Button
       bg="#0072CF"
       display={isShow}
       w="136px"
       h="45px"
       borderRadius="5px"
       fontSize="16"
       color="white"
       isDisabled={isDisabledSave}
       _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
       onClick={onSave}
      >
       Save
      </Button>
     </ModalFooter>
     </Flex>
    </ModalContent>
   </Modal>
  </Box>
 );
};

export default CustomField;
