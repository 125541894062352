import {
 FC,
 ReactNode,
 LegacyRef,
 useRef,
 ChangeEvent,
 forwardRef,
} from "react";
import {
 FormControl,
 FormLabel,
 FormErrorMessage,
 Input,
 FormHelperText,
 InputGroup,
 InputLeftElement,
 InputRightElement,
 InputProps,
 Stack,
} from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { useErrorFocus } from "hooks";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface TextInputProps extends InputProps {
 name: string;
 id: string;
 fieldName?: string;
 dataId?: string;
 label?: any;
 placeholder?: string;
 description?: string;
 type?: string;
 isDisabled?: boolean;
 isReadOnly?: boolean;
 isRequired?: boolean;
 topLabel?: boolean;
 isLeftElementClickable?: boolean;
 isRightElementClickable?: boolean;
 LeftElement?: ReactNode;
 RightElement?: ReactNode;
 ref?: LegacyRef<HTMLInputElement>;
 minW?: string;
 onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
 isDuplicate?: boolean;
 selectedDate?: Date | undefined;
 showPopperArrow?: boolean;
 minDate?: Date | undefined;
 maxDate?: Date | undefined;
}

const DatePickerInput: FC<TextInputProps> = ({
 name,
 id,
 fieldName = "",
 dataId = "",
 label = "",
 placeholder = "",
 description = "",
 type = "text",
 isDisabled = false,
 isReadOnly = false,
 isRequired = false,
 topLabel = false,
 isLeftElementClickable = false,
 isRightElementClickable = false,
 LeftElement = null,
 RightElement = null,
 ref = null,
 minW = "",
 selectedDate,
 minDate,
 maxDate,
 showPopperArrow = false,
 onChange = () => {},
 isDuplicate,
 ...props
}) => {
 const [field, meta, { setValue, setTouched }] = useField(name);
 const inputRef = useRef(null);
 const { setFieldValue } = useFormikContext();
 //  useErrorFocus(inputRef, name);
 const handleBlur = (e: any) => {
  field.onBlur(e);
 };
 const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
  <Input
   autoComplete="off"
   bgColor="white"
   value={value}
   ref={ref}
   h="51px"
   border="1px solid"
   borderColor={"rgba(216, 216, 217, 1)"}
//    bgColor="rgba(216, 216, 217, 0.36)"
   borderRadius="5px"
   placeholder="dd/mm/yyyy"
   minW={minW}
   onClick={onClick}
   onChange={onChange}
   onKeyUp={(e) => handleBlur(e)}
   {...props}
  />
 );
 const CustomInput = forwardRef(customDateInput);

 return (
  <FormControl
   id={id}
   isInvalid={Boolean(meta.error && meta.touched)}
   isDisabled={isDisabled}
   isReadOnly={isReadOnly}
   isRequired={isRequired}
  >
   {topLabel && label && (
    <FormLabel
     pb="10px"
     alignSelf="center"
     margin="0"
     minW="40%"
     fontWeight="bold"
     textTransform="capitalize"
     opacity="1!important"
    >
     {label}
    </FormLabel>
   )}
   <Stack spacing={4} direction="row">
    {!topLabel && label && (
     <FormLabel
      alignSelf="center"
      margin="0"
      minW="40%"
      fontWeight="bold"
      textTransform="capitalize"
      opacity="1!important"
     >
      {label}
     </FormLabel>
    )}
    <InputGroup>
     {LeftElement && (
      <InputLeftElement
       pointerEvents={isLeftElementClickable ? undefined : "none"}
       color="gray.300"
       fontSize="1.2em"
      >
       {LeftElement}
      </InputLeftElement>
     )}
     <ReactDatePicker
      selected={(field.value && new Date(field.value)) || null}
      showPopperArrow={showPopperArrow}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(e: any) => {
       if (onChange) {
        onChange(e);
       }
       setFieldValue(field.name, Date.toLocale(e))
       setTouched(true, true);
      }}
      className={minW === "500px" ? "react-datapicker__input-text w500" : "react-datapicker__input-text w300"}
      dateFormat="dd/MM/yyyy"
      placeholderText="dd/mm/yyyy"
      // customInput={<CustomInput />}
     />
     {RightElement && (
      <InputRightElement
       pointerEvents={isRightElementClickable ? undefined : "none"}
       color="gray.500"
       fontSize="1.2em"
       pt="2"
      >
       {RightElement}
      </InputRightElement>
     )}
    </InputGroup>
   </Stack>

   {description && <FormHelperText>{description}</FormHelperText>}
   <Stack spacing={4} direction="column-reverse" align={"start"}>
    {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
   </Stack>
  </FormControl>
 );
};

export default DatePickerInput;
