import { FC, ReactElement } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { SigninOIDC } from "../Auth/signin-oidc";
import { PrivateRoute } from "views/Router/IdentityPrivateRoute";
import { Logout } from "../Auth/logout";
import { LogoutCallback } from "../Auth/logout-callback";
import { SilentRenew } from "../Auth/silentRenew";
import Journeys from "views/Journeys";
import JourneysDetail from "views/Journeys/detail";
import CreateJourneys from "views/Journeys/create";
import Pricings from "views/EditPricings";
import DataLoading from "views/EditPricings/DataLoading";
import PricingCostSummary from "views/EditPricings/CostSummary";
import PricingGeneralData from "views/EditPricings/GeneralData";
import PricingCostDrivers from "views/EditPricings/CostDrivers";
import Simulations from "views/Simulation";
import SimulationSummary from "views/Simulation/detail";
import CreateSimulations from "views/Simulation/create";
import Comparison from "views/Simulation/comparison";
import AnnualSpend from "views/AnnualSpend";
import CreateAnnualSpend from "views/AnnualSpend/create";
import CreateComparation from "views/AnnualSpend/comparation";
import Detail from "views/AnnualSpend/detail";
import Settings from "views/Settings";
import UserManagement from "views/Settings/ThirdParty/User";
import PredefinedThirdParty from "views/Settings/ThirdParty/Predefined";
import GetStarted from "views/GetStarted";
import Approval from "views/Approval";
import MasterData from "views/MasterData";
import MasterDataDetail from "views/MasterData/Detail";
import AnnualSpendImpact from "views/AnnualSpendImpact";
import AnnualSpendImpactComparison from "views/AnnualSpendImpact/Comparison";

import SimulationBySku from "views/NewSimulationBySku";
import SimulationBySkuPricingCostSummary from "views/NewSimulationBySku/CostSummary";
import SimulationBySkuPricingGeneralData from "views/NewSimulationBySku/GeneralData";
import SimulationBySkuPricingCostDrivers from "views/NewSimulationBySku/CostDrivers";
import Configuration from "views/Settings/MasterData/Configuration";
import MultiCurrency from "views/Settings/MultiCurrency/Configuration";
import DemandForecast from "views/DemandForecast";
import UploadDataset from "views/DemandForecast/UploadDataset";

import MassUpdate from "views/MassUpdate";

const Router = (): ReactElement => {
 return (
  <BrowserRouter>
   <Switch>
    <Route exact path="/signin-oidc" component={SigninOIDC} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/logout/callback" component={LogoutCallback} />
    <Route exact path="/silentrenew" component={SilentRenew} />
    <PrivateRoute exact path="/" component={GetStarted} />
    <PrivateRoute exact path="/getStarted" component={GetStarted} />
    <PrivateRoute exact path="/journeys" component={Journeys} />
    <PrivateRoute exact path="/journeys/create" component={CreateJourneys} />
    <PrivateRoute exact path="/journeys/:id" component={JourneysDetail} />
    <PrivateRoute exact path="/pricings" component={Pricings} />
    <PrivateRoute
     exact
     path="/pricings/:costDrivers/:id"
     component={PricingCostDrivers}
    />
    <PrivateRoute
     exact
     path="/pricings/cost-summary-by-sku/:id"
     component={PricingCostSummary}
    />
    <PrivateRoute
     exact
     path="/pricings/sku-characteristic/:id"
     component={PricingGeneralData}
    />
    <PrivateRoute
     exact
     path="/pricings/final-cost/:id"
     component={PricingGeneralData}
    />
    <PrivateRoute exact path="/pricings/data-loading" component={DataLoading} />
    <PrivateRoute exact path="/approval" component={Approval} />
    <PrivateRoute
     exact
     path="/approval/annual-spend-impact"
     component={AnnualSpendImpact}
    />
    <PrivateRoute
     exact
     path="/approval/annual-spend-impact/comparison"
     component={AnnualSpendImpactComparison}
    />
    <PrivateRoute exact path="/simulations" component={Simulations} />
    <PrivateRoute
     exact
     path="/simulations/create"
     component={CreateSimulations}
    />
    <PrivateRoute exact path="/simulations/:id" component={SimulationSummary} />
    <PrivateRoute
     exact
     path="/simulations/comparasion/create"
     component={Comparison}
    />

    <PrivateRoute exact path="/simulation-by-sku" component={SimulationBySku} />
    <PrivateRoute
     exact
     path="/simulation-by-sku/:costDrivers/:id"
     component={SimulationBySkuPricingCostDrivers}
    />
    <PrivateRoute
     exact
     path="/simulation-by-sku/cost-summary-by-sku/:id"
     component={SimulationBySkuPricingCostSummary}
    />
    <PrivateRoute
     exact
     path="/simulation-by-sku/sku-characteristic/:id"
     component={SimulationBySkuPricingGeneralData}
    />
    <PrivateRoute
     exact
     path="/annual-spend-simulations"
     component={AnnualSpend}
    />
    <PrivateRoute
     exact
     path="/annual-spend-simulations/create"
     component={CreateAnnualSpend}
    />
    <PrivateRoute
     exact
     path="/annual-spend-simulations/comparation/create"
     component={CreateComparation}
    />
    <PrivateRoute
     exact
     path="/annual-spend-simulations/:id"
     component={Detail}
    />
    <PrivateRoute exact path="/settings" component={Settings} />
    <PrivateRoute
     exact
     path="/settings/third-party/user-management"
     component={UserManagement}
    />
    <PrivateRoute
     exact
     path="/settings/third-party/user-management/:id"
     component={UserManagement}
    />
    <PrivateRoute
     exact
     path="/settings/third-party/"
     component={PredefinedThirdParty}
    />
    <PrivateRoute
     exact
     path="/settings/master-data/configuration"
     component={Configuration}
    />
    <PrivateRoute exact path="/master-data" component={MasterData} />
    <PrivateRoute
     exact
     path="/settings/multi-currency"
     component={MultiCurrency}
    />
    <PrivateRoute exact path="/master-data/:id" component={MasterDataDetail} />
    {/* </RouterWrapper> */}
    <PrivateRoute exact path="/demand-forecast" component={DemandForecast} />
    <PrivateRoute
     exact
     path="/demand-forecast/upload-dataset"
     component={UploadDataset}
    />
    {/* Mass Update  */}
    {/* <PrivateRoute exact path="/mass-update" component={MassUpdate} /> */}
    
   </Switch>
  </BrowserRouter>
 );
};

export default Router;
