export const IsColumnNumeric = (headerName: string, tableData: any) => {
  let result = false;
  for (let i = 0; i < tableData.length; i++) {
    let itemData = tableData[i];

    let itemText =
      headerName === "unitCost"
        ? isNaN(itemData[headerName])
          ? !Number.isNaN(itemData[headerName])
            ? ""
            : itemData[headerName].replace("%", "")
          : itemData[headerName] || ""
        : itemData[headerName] || "";

    let isColumnNumeric = !isNaN(itemText);
    if (isColumnNumeric) {
      result = true;
    }
    break;
  }
  return result;
};

export const FindIndexDataAdd = (dataToSendadds: any, temporaryData: any) => {
  return dataToSendadds.findIndex(
    (itemDelete: any) => itemDelete?.tmpId === temporaryData.tmpId
  );
};

export const FindIndexDataUpdate = (
  dataToSendUpdates: any,
  temporaryData: any
) => {
  return dataToSendUpdates.findIndex(
    (itemUpdate: any) =>
      itemUpdate.costDriverId === temporaryData.constDriverId &&
      itemUpdate.name === temporaryData.name
  );
};

export const FindIndexDataDelete = (
  dataToSendDelete: any,
  temporaryData: any
) => {
  return dataToSendDelete.findIndex(
    (itemDelete: any) =>
      itemDelete.costDriverId === temporaryData?.constDriverId &&
      itemDelete.name === temporaryData.name
  );
};

export const FindExitingDataSend = (
  data: any,
  rowName: string,
  parentCostDriverId: string,
  costAnalysisPerVolumeId: string
) => {
  return (
    Array.isArray(data) &&
    data.length > 0 &&
    data?.find((item: any) => {
      return (
        item.name === rowName &&
        item.parentCostDriverId === parentCostDriverId &&
        item.costAnalysisPerVolumeId === costAnalysisPerVolumeId
      );
    })
  );
};
export const SaveNewSliceDataToSendUpdate2 = (
  costDriverData: any,
  updatedCustomFieldId: string,
  value: string | number,
  costAnalysisPerVolumeId?: string,
  costDriverId?: string,
  parentCostDriverId?: string,
  name?: string,
  tmpChangeRow?: any,
  masterDataId?: string
) => {
  var customFieldData = [] as any;
  let unitCostValue = null;
  costDriverData.customFields.filter((i:any) => i.customFieldConfigurationId)?.map((customFieldItem: any) => {
    var newCustomField = {
      customFieldId: customFieldItem.id,
      value: customFieldItem.value,
      customFieldConfigurationId: customFieldItem.customFieldConfigurationId,
    };
    const findSameId = tmpChangeRow?.find(
      (data: any) =>
        data.customFieldConfigurationId ===
        customFieldItem.customFieldConfigurationId &&
        data.idRow === customFieldItem.costDriverId
    );

    const findSameUnitCost = tmpChangeRow?.find(
      (data: any) => data.id === "unitCost" && data.idRow === costDriverData.id
    );

    if (findSameUnitCost) {
      unitCostValue = findSameUnitCost?.before;
    }
    if (name === customFieldItem.name) {
      newCustomField.value = value;
    }

    if (findSameId) {
      newCustomField.value = findSameId?.before;
    }
    customFieldData.push(newCustomField);
  });



  const newCustomFieldDataToUpdate: any = [];
  customFieldData.map((customField: any) => {
    if (!customField.isMaintenanceByMasterData) {
      newCustomFieldDataToUpdate.push(customField);
    }
  });

  var data = {
    costAnalysisPerVolumeId: costAnalysisPerVolumeId,
    costDriverId: costDriverId,
    name: costDriverData.name,
    description: costDriverData.description,
    unitCost: unitCostValue ?? costDriverData.unitCost,
    customFields: newCustomFieldDataToUpdate,
    parentCostDriverId,
    masterDataId: costDriverData?.get ? costDriverData?.get("masterDataId") : costDriverData?.masterDataId,
  } as any;

  return data;
};

export const SaveNewSliceDataToSendUpdate = (
  costDriverData: any,
  updatedCustomFieldId: string,
  value: string | number,
  costAnalysisPerVolumeId?: string,
  costDriverId?: string,
  parentCostDriverId?: string,
  name?: string,
  masterDataId?: string
) => {
  var customFieldData = [] as any;
  costDriverData.get("customFields").map((customFieldItem: any) => {
    var newCustomField = {
      customFieldId: customFieldItem.id,
      value: customFieldItem.value,
      customFieldConfigurationId: customFieldItem.customFieldConfigurationId,
    };
    if (name === customFieldItem.name) {
      newCustomField.value = value;
    }
    customFieldData.push(newCustomField);
  });

  var data = {
    costAnalysisPerVolumeId: costAnalysisPerVolumeId,
    costDriverId: costDriverId,
    name: costDriverData.get("name"),
    description: costDriverData.get("description"),
    unitCost: costDriverData.get("unitCost"),
    customFields: customFieldData,
    parentCostDriverId,
    masterDataId: costDriverData.get("name"),
  } as any;

  return data;
};

export const SaveExistingDataSliceDataToSendUpdate = (
  existingDataToSendUpdate: any,
  updatedCustomFieldId: string,
  value: string | number,
  parentCostDriverId?: string,
  customFieldConfigurationId?: string,
  tmpChangeRow?: any
) => {
  var data = Object.assign({}, existingDataToSendUpdate);
  var customFieldData = [] as any;
  data.customFields.map((customField: any) => {
    var newCustomField = Object.assign({}, customField);
    const findSameId = tmpChangeRow?.find(
      (data: any) =>
        data.customFieldConfigurationId === customField.customFieldConfigurationId
    );
    if (
      customField.customFieldConfigurationId === updatedCustomFieldId ||
      customFieldConfigurationId === customField.customFieldConfigurationId
    ) {
      newCustomField.value = value;
    }
    customFieldData.push(newCustomField);
  });

  data["parentCostDriverId"] = parentCostDriverId;
  data["customFields"] = customFieldData;
  return data;
};

export const CustomFieldData = (
  costDriverData: any,
  updatedCustomFieldId?: string,
  value?: string | number | any
) => {
  var customFieldData = [] as any;
  costDriverData.get("customFields").map((customField: any) => {
    if (updatedCustomFieldId === customField.customFieldConfigurationId) {
      var newCustomField = {
        customFieldConfigurationId: customField.customFieldConfigurationId,
        value: value,
      };
      customFieldData.push(newCustomField);
    } else {
      var newCustomField = {
        customFieldConfigurationId: customField.customFieldConfigurationId,
        value: customField.value,
      };
      customFieldData.push(newCustomField);
    }
  });

  return customFieldData;
};

export const SaveExistingDataSliceDataToSendAdd = (
  existingDataToSendUpdate: any,
  updatedCustomFieldId: string,
  value: string | number,
  parentCostDriverId?: string,
  arrayIndexs?: string[]
) => {
  var data = Object.assign({}, existingDataToSendUpdate);
  var customFieldData = [] as any;
  data.customFields.map((customField: any) => {
    var newCustomField = Object.assign({}, customField);
    if (customField.id === updatedCustomFieldId) {
      newCustomField.value = value;
    }
    customFieldData.push(newCustomField);
  });

  data["parentCostDriverId"] = parentCostDriverId;
  data["customFields"] = customFieldData;
  return data;
};

export const CompareDataFromOriginalAndRecent = (
  originalData: any,
  currentRow: any,
  convertName: string
) => {
  const findMatch = originalData.data.findIndex((item: any) => {
    let itemToObject = Object.assign({}, item);
    item.customFields.length > 0 &&
      item.customFields.map((customField: any) => {
        itemToObject[customField.name] = customField.value;
      });
    const originalNewMap = new Map(Object.entries(itemToObject));
    if (item.id === currentRow?.get("id")) {
      return originalNewMap.get(convertName) !== currentRow?.get(convertName);
    }
  });
  return findMatch;
};
