import { ReactElement, useEffect, useState } from "react";
import { useAppDispatch } from "hooks";
import {
 Box,
 Flex,
 Spacer,
 Button,
 Image,
 Center,
 Spinner,
 Modal,
 ModalHeader,
 ModalBody,
 ModalContent,
 ModalOverlay
} from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import UploadIcon from "assets/images/uploadIcon.png";
import Success from "assets/images/successImage.png";
import { actions as multiCurrencyAction } from "state/multiCurency/slice";

const CurrencyUpload = (): ReactElement => {
 const dispatch = useAppDispatch();
 const [isOpenModal, setOpenModal] = useState(false);
 const [modalDialog, setModalDialog] = useState("");
 const { loading, successUpload } = useSelector(
  (state: RootState) => ({
   loading: state.multiCurrency.loading,
   successUpload: state.multiCurrency.successUpload,
  }),
  shallowEqual
 );

 useEffect(() => {
  if (loading) {
   const unloadCallback = (event: any) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
   };

   window.addEventListener("beforeunload", unloadCallback);
   return () => window.removeEventListener("beforeunload", unloadCallback);
  }

  if (successUpload === true) {
   setOpenModal(true);
   setModalDialog("Data has been uploaded successfully");
   dispatch(multiCurrencyAction.updateSuccessUpload(false));
  }
  window.scrollTo(0, 0);
 }, [successUpload, loading]);

 const onClickDownload = () => {
    dispatch(multiCurrencyAction.downloadTemplate())
 };
 const handleDrop = (acceptedFiles: any) => {
  dispatch(
   multiCurrencyAction.upload({
    payload: acceptedFiles as any,
   })
  );
 };
 const fetchList = () => {
   const payload = {
       skipCount: 0,
       maxResultCount: 10,
       keyword: "",
      };
 dispatch(multiCurrencyAction.listMultiCurrency(payload));
};

 return (
  <Box textAlign={"center"}>
   <Button
    variant={"link"}
    onClick={() => onClickDownload()}
    isLoading={loading}
    loadingText="Downloading..."
   >
    Download template
   </Button>
   <Dropzone
    onDrop={(acceptedFiles) => {
     handleDrop(acceptedFiles);
    }}
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
   >
    {({ getRootProps, getInputProps }) => (
     <Center>
      <Box
       {...getRootProps({ className: "dropzone" })}
       textAlign={"center"}
       mt={5}
       w="461px"
       h="247px"
       borderRadius="5px"
       border="1px dashed #9DA1A7"
       display="inline-block"
      >
       <Center>
        <Image src={UploadIcon} w="33.75px" alt="Bulp" mt={5} />
       </Center>
       <Flex>
        <Spacer />
        {!loading ? (
         <Box fontSize="18" textColor="#43464B" pt="23px" textAlign="center">
          Drag and drop <br />
          or
         </Box>
        ) : (
         <Box fontSize="16" textColor="#43464B" pt="23px" textAlign="center">
          <Spinner mb={"4rem"} mt={"4rem"} color="#9DA1A7" size="xl" />
         </Box>
        )}
        <Spacer />
       </Flex>
       <Button
        bg="#0072CF"
        mt={5}
        w="200px"
        h="59"
        borderRadius="5px"
        fontSize="18"
        color="white"
        _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
       >
        Select file
        <input {...getInputProps()} />
       </Button>
      </Box>
     </Center>
    )}
   </Dropzone>
   <Modal isOpen={isOpenModal} onClose={() => setOpenModal(false)}>
    <ModalOverlay />
    <ModalContent
     w="33.188rem"
     h="18.rem"
     margin="auto"
     boxShadow="0px 0px 56px rgba(157, 161, 167, 0.37)"
     borderRadius="5"
     p="1rem"
     mt="10%"
    >
     <ModalHeader fontSize="1.5rem" textAlign="center">
      {modalDialog}
     </ModalHeader>
     <ModalBody>
      <Image display="block" ml="auto" mr="auto" src={Success} alt="Bulp" />
      <Button
       onClick={() => {
         dispatch(multiCurrencyAction.resetPage(false))
         fetchList()
       }}
       bg="#0072CF"
       display="block"
       mt="1.594rem"
       ml="auto"
       mr="auto"
       borderRadius="5"
       fontSize="18"
       color="white"
       _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
      >
       Got it
      </Button>
     </ModalBody>
    </ModalContent>
   </Modal>
  </Box>
 );
};

export default CurrencyUpload;
