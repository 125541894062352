import { ReactElement, useEffect, useState, useMemo } from "react";
import { Box } from "@chakra-ui/layout";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";
import { FieldArray, Form, Formik } from "formik";
import debounce from "lodash/debounce";
import NewTextInputCustomNumber from "components/NewTextInputCustomNumber";
import {
 Table,
 Thead,
 Tbody,
 Tr,
 Th,
 Td,
 TableContainer,
} from "@chakra-ui/react";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";

const ResultFinalCostTable = (): ReactElement => {
 const dispatch = useAppDispatch();
 const [initialValues, setInitialValues] = useState<any>();
 const [finalCost, setFinalCost] = useState<any>(0);
 const {
  detailPerVolumeId,
  isEditing,
  isCopy,
  isReplace,
  tmpChangeRow,
  needReFetch,
  dataToSend,
  responseSimulate,
 } = useSelector(
  (state: RootState) => ({
   isEditing: state.editPricingCostDrivers.isEditing,
   isCopy: state.copyAndReplaceCostDrivers.isCopy,
   isReplace: state.copyAndReplaceCostDrivers.isReplace,
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId.result,
   dataToSendUpdates: state.editPricingCostDrivers.dataToSend.updates,
   dataToSendadds: state.editPricingCostDrivers.dataToSend.adds,
   dataToSend: state.editPricingCostDrivers.dataToSend,
   temporaryDataCostDriver:
    state.editPricingCostDrivers.temporaryDataCostDriver,
   tmpChangeRow: state.editPricingCostDrivers.tmpChangeRow,
   needReFetch: state.editPricingCostDrivers.needReFetch,
   responseSimulate: state.editPricingCostDrivers.responseSimulate,
  }),
  shallowEqual
 );
 const runSimulate = useMemo(
  () =>
   debounce(() => {
    dispatch(pricingCostDriverAction.setNeedReFetch(true));
   }, 800),
  [dispatch]
 );

 useEffect(() => {
  const runFunction = async () => {
   await dispatch(pricingCostDriverAction.setValueIsIncludeCalculation(true));
   dispatch(pricingCostDriverAction.setDisable(true))
   await dispatch(pricingCostDriverAction.simulate(dataToSend));
   await dispatch(pricingCostDriverAction.setValueIsIncludeCalculation(false));
   await dispatch(pricingCostDriverAction.setNeedReFetch(false));
   dispatch(pricingCostDriverAction.setDisable(false))
  };
  if (needReFetch) {
   runFunction();
  }
 }, [needReFetch]);

 useEffect(() => {
  setInitialValues({
   summaryCostDrivers: detailPerVolumeId?.summaryCostDrivers,
  });
  setFinalCost(
   detailPerVolumeId?.summaryCostDrivers.length > 0 &&
    detailPerVolumeId?.summaryCostDrivers[0].totalCost
  );
 }, [detailPerVolumeId, isEditing]);

 return (
  <Formik
   enableReinitialize
   initialValues={initialValues}
   onSubmit={async (values) => {}}
   validateOnChange
  >
   {({ values, setFieldValue }: any) => {
    return (
     <Form>
      <Box
       w="100%"
       border="1px solid #D7DBE2"
       borderRadius="40px"
       h="100%"
       mb="2rem"
       position="relative"
       pl="1rem"
       pr="1rem"
      >
       <Box as="p" fontWeight="bold" ml="1.5rem" mt="1rem">
        Final Cost
       </Box>
       <TableContainer
        sx={{
         "&::-webkit-scrollbar": { width: "6px", height: "6px" },
         "&::-webkit-scrollbar-track": { width: "6px", height: "6px" },
         "&::-webkit-scrollbar-thumb": {
          borderRadius: "24px",
          backgroundColor: "#0072CF",
         },
        }}
       >
        <Table variant="simple">
         <Thead>
          <Tr>
           <Th
            color="#9DA1A7"
            sx={{
             textAlign: "left",
            }}
           >
            Cost Breakdown
           </Th>
           <Th
            color="#9DA1A7"
            sx={{
             textAlign: "left",
            }}
           >
            Currency
           </Th>
           <Th
            color="#9DA1A7"
            sx={{
             textAlign: "right",
            }}
           >
            Final Cost per Unit
           </Th>
          </Tr>
         </Thead>
         <Tbody>
          {!isEditing &&
           !isCopy &&
           !isReplace &&
           Array.isArray(detailPerVolumeId?.summaryCostDrivers) &&
           detailPerVolumeId?.summaryCostDrivers.length > 0 &&
           detailPerVolumeId?.summaryCostDrivers.map(
            (item: any, index: number) => {
             return (
              <Tr key={index}>
               <Td
                sx={{
                 textAlign: "left",
                }}
               >
                {item?.code}
               </Td>
               <Td
                sx={{
                 textAlign: "left",
                }}
               >
                {detailPerVolumeId?.costAnalysis?.currencyCode}
               </Td>
               <Td
                sx={{
                 textAlign: "right",
                }}
               >
                {item.totalCost?.toLocaleString(undefined, {
                 minimumFractionDigits: 2,
                 maximumFractionDigits: 2,
                })}
               </Td>
              </Tr>
             );
            }
           )}
          {(isEditing || isCopy || isReplace) && (
           <FieldArray
            name="summaryCostDrivers"
            render={(arrayHelpers) => {
             return (
              <>
               {Array.isArray(values?.summaryCostDrivers) &&
                values.summaryCostDrivers.length > 0 &&
                values.summaryCostDrivers.map((item: any, index: number) => {
                 return (
                  <Tr>
                   <Td
                    sx={{
                     textAlign: "left",
                    }}
                   >
                    {item?.code}
                   </Td>
                   <Td
                    sx={{
                     textAlign: "left",
                    }}
                   >
                    {detailPerVolumeId?.costAnalysis?.currencyCode}
                   </Td>
                   <Td
                    sx={{
                     textAlign: "right",
                     w: "200px",
                    }}
                   >
                    <NewTextInputCustomNumber
                     name={`summaryCostDrivers[${index}].totalCost`}
                     label=""
                     sx={{
                      textAlign: "right",
                     }}
                     id={`summaryCostDrivers[${index}].totalCost`}
                     onChange={async (value: any) => {
                      setFieldValue(
                       `summaryCostDrivers[${index}].unitCost`,
                       value
                      );
                      const dataCurrent = detailPerVolumeId?.currentUnitCost
                       ? detailPerVolumeId?.currentUnitCost
                       : detailPerVolumeId?.unitCost;
                      if (dataCurrent !== parseFloat(value)) {
                       await dispatch(
                        pricingCostDriverAction.settmpChangeRow({
                         id: "final-cost",
                         addBgColor: true,
                         costDriver: "Total Cost",
                         component: `Total Cost - FINAL COST PER UNIT`,
                         before: "",
                         after: item.totalCost,
                         idRow: "",
                         parentCostDriverId: "",
                        })
                       );
                       await dispatch(
                        pricingCostDriverAction.setUpdateUnitCost(value)
                       );
                       runSimulate();
                      } else {
                       const indexTmpChangeRow = tmpChangeRow.findIndex(
                        (itemTmpMustDelete: any, index: number) => {
                         if (itemTmpMustDelete?.id === "final-cost") {
                          return index;
                         }
                        }
                       );
                       await dispatch(
                        pricingCostDriverAction.removeTmpChangeForFinalCost(
                         indexTmpChangeRow
                        )
                       );

                       await dispatch(
                        pricingCostDriverAction.setUpdateUnitCost(value)
                       );
                       runSimulate();
                      }
                     }}
                    />
                   </Td>
                  </Tr>
                 );
                })}
              </>
             );
            }}
           />
          )}
         </Tbody>
        </Table>
       </TableContainer>
      </Box>
      {/* </Flex> */}
     </Form>
    );
   }}
  </Formik>
 );
};

export default ResultFinalCostTable;
