import { RootState } from "state/store";
import { FC, useEffect, useState } from "react";
import { Box, Button, Flex, SimpleGrid, Progress } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { actions as simulateAction } from "state/simulation/slice";
import { Formik, Form, FieldArray } from "formik";
import FormInput from "./FormInput";
import _ from "lodash";

const DescribeChange: FC = () => {
 const dispatch = useAppDispatch();
 const router = useHistory();

 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);
 let { loading, level, uploadResponse, describeResponse } = useSelector(
  (state: RootState) => ({
   loading: state.simulationAggregate.loading,
   level: state.simulationAggregate.level,
   uploadResponse: state.simulationAggregate.uploadResponse,
   component: state.simulationAggregate.component,
   subComponent: state.simulationAggregate.subComponent,
   subComponentsResponse: state.simulationAggregate.subComponentsResponse,
   describeResponse: state.simulationAggregate.describeResponse,
  }),
  shallowEqual
 );

 const [initialValues, setInitialValues] = useState<any>();

 let result: any = {};
 describeResponse.forEach((item: any) => {
  if (item.name) {
   if (result[item.name]) {
    result[item.name] = result[item.name].concat(item.data);
   } else {
    result[item.name] = item.data;
   }
  }
 });

 let dataManipulation: any = [];
 Object.getOwnPropertyNames(result).map((item: any, index: number) => {
  dataManipulation.push({
   name: item,
   data: Object.values(result)[index],
  });
 });

 useEffect(() => {
  const dataToSend: any = [];
  dataManipulation.map((item: any) => {
   const { data } = item;
   data.map((item: any) => {
    const { identityCode, identityName, costLabelName } = item;
    dataToSend.push({
     identityCode,
     identityName,
     costLabelName,
     changePercentage: 0,
    });
   });
  });

  setInitialValues({
   dataSubmit: dataManipulation,
  });
 }, [describeResponse]);

 return (
  <Box mt="1.5rem" mb="6rem">
   <Box fontWeight="700" fontSize="24px" pb="2rem">
    Select percentage change
   </Box>
   {loading && <Progress m="1rem" mb="2rem" size="xs" isIndeterminate />}

   <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={async (values) => {
     const valueToSend: any = [];
     values.dataSubmit.map((items: any, index: number) => {
      const { data } = items;
      data.map((item: any) => {
       const { identityCode, identityName, changePercentage } = item;
       valueToSend.push({
        changePercentage: Number(changePercentage) / 100,
        identityCode,
        identityName,
       });
      });
     });
     dispatch(simulateAction.setAuditLog(valueToSend));
     const sendData = {
      payload: {
       costAnalysises: uploadResponse,
       components: valueToSend,
      },
     };
     await dispatch(simulateAction.describeChange(sendData)).then(
      (response: any) => {
       if (response) {
        router.push("/simulations/create?page=summary");
       }
      }
     );
    }}
    render={({ values, setFieldValue }) => (
     <Form noValidate>
      <Box border="1px solid #D7DBE2" borderRadius="40px" p="2rem" mb="1rem">
       {describeResponse.length == 0 && !loading && (
        <Box padding="1rem" textAlign="center" fontWeight="700" fontSize="18px">
         There is no component that can be simulated. Please proceed to the next
         step directly.
        </Box>
       )}
       <FieldArray
        name="dataSubmit"
        render={() => {
         return (
          <Box>
           {values?.dataSubmit.map(
            (item: { name: string; data: any[] }, index: number) => {
             return (
              <Box mt="1rem" key={index} pb="10px" fontWeight="bold">
               {" "}
               {item.name}
               <SimpleGrid minChildWidth="120px" spacing="40px">
                {item.data.map((data: any, idx: number) => {
                 return (
                  <Box mt="1rem" key={idx}>
                   <FormInput
                    name={`dataSubmit[${index}].data[${idx}].changePercentage`}
                    label={`${data.identityName} ${data.costLabelName}`}
                    onChange={(value) => {
                     const val = value.currentTarget.value;
                     let itemState = Object.assign({}, data);
                     itemState.changePercentage = val;
                     setFieldValue(
                      `dataSubmit[${index}].data[${idx}].changePercentage`,
                      val
                     );
                    }}
                   />
                  </Box>
                 );
                })}
               </SimpleGrid>
              </Box>
             );
            }
           )}
          </Box>
         );
        }}
       />
      </Box>
      <Box
       position="fixed"
       bottom="0"
       backgroundColor="#ffffff"
       height="5.5rem"
       w="100%"
       zIndex={2}
      >
       <Flex float="right" mr="20rem">
        <Box mt={4}>
         <Flex alignItems="right" gap="2">
          <>
           <Button
            bg="#C3C7CD"
            w="202px"
            h="59"
            borderRadius="50"
            fontSize="18"
            color="white"
            _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
            onClick={() => router.goBack()}
           >
            Back
           </Button>
           <Button
            bg="#0072CF"
            w="202px"
            h="59"
            borderRadius="50"
            fontSize="18"
            color="white"
            _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
            type="submit"
           >
            Next
           </Button>
          </>
         </Flex>
        </Box>
       </Flex>
      </Box>
     </Form>
    )}
   ></Formik>
  </Box>
 );
};

export default DescribeChange;
