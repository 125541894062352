import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "state/store";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import Router from "views/Router";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./providers/authProvider";
import "styles/global.scss";
import "react-confirm-alert/src/react-confirm-alert.css";
import Fonts from "styles/Fonts";
import theme from "styles/System";
import { createRoot } from "react-dom/client";

const root = createRoot(document.getElementById("root") as HTMLElement);
ReactDOM.render(
 <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
   <React.StrictMode>
    <AuthProvider>
     <ChakraProvider theme={theme}>
      <Fonts />
      <Router />
     </ChakraProvider>
    </AuthProvider>
    <ReduxToastr />
   </React.StrictMode>
  </PersistGate>
 </Provider>,
 document.getElementById("root")
);

reportWebVitals();
