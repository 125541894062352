import { createSlice } from '@reduxjs/toolkit'
import { getList, downloadTemplate, upload, deleteById } from 'state/demandForecast/thunk'

interface InitialState {
  result: any
  loading: boolean
  loadingDownload: boolean
  successDelete: boolean
  searchQuery: string
  error: unknown
  successUpload: boolean
  thirdPartySelection: string
  journeySelection: string
}

const initialState: InitialState = {
  result: {
    items: []
  },
  successDelete: false,
  searchQuery: '',
  loading: false,
  loadingDownload: false,
  error: null,
  successUpload: false,
  thirdPartySelection: "",
  journeySelection: ""
}

const demandForecastSlice = createSlice({
  name: 'demandForecast',
  initialState,
  reducers: {
    clear: () => initialState,
    setSearchQuery: (state, action) => {
      const { value } = action.payload
      state.searchQuery = value
    },
    setJourneySelection: (state, action) => {
      state.journeySelection = action.payload
    },
     setThirdPartySelection: (state, action) => {
      state.thirdPartySelection = action.payload
    },
    updateSuccessUpload: (state, action) => {
      state.successUpload = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(getList.fulfilled, (state, action) => {
      state.result = action.payload
      state.loading = false
    })

    builder.addCase(getList.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    builder.addCase(downloadTemplate.pending, (state) => {
      state.loadingDownload = true;
      state.error = null;
    })

    builder.addCase(downloadTemplate.fulfilled, (state, action) => {
      state.loadingDownload = false;
    })

    builder.addCase(downloadTemplate.rejected, (state, action) => {
      state.loadingDownload = false;
      state.error = action.payload;
    })

    builder.addCase(upload.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(upload.fulfilled, (state, action) => {
      state.successUpload = action.payload
      state.loading = false
    })

    builder.addCase(upload.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    builder.addCase(deleteById.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(deleteById.fulfilled, (state, action) => {
      state.successDelete = action.payload
      state.loading = false
    })

    builder.addCase(deleteById.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...demandForecastSlice.actions,
  getList,
  downloadTemplate,
  upload,
  deleteById
}

export const reducer = demandForecastSlice.reducer
