import { ReactElement, FC, MouseEvent } from "react";
import { GridItem, VStack, Heading, Text } from "@chakra-ui/react";

interface TData {
 id: string;
 name: string;
 description: string;
 currency: string;
 totalCostPricing: {
   amount: number,
   currency: {
      code: string,
      description: string,
      id: string
   }
 };
 totalCostToCurrency: number;
 convertedCurrency: {
    code: string;
 }
}

interface TCard {
 item: TData;
 onClick?: (id: string) => void;
}

const Card: FC<TCard> = ({ item, onClick }): ReactElement => {
 const handleClick = (event: MouseEvent<HTMLDivElement>) => {
  event.preventDefault();
  if (onClick) {
   onClick(item.id);
  }
 };

 return (
  <GridItem
   // colSpan={1}
   border="1px solid rgba(26, 26, 26, 0.16)"
   width={"100%"}
   p="6"
   rounded="md"
   bg="white"
   _hover={{
    border: "1px solid rgba(0, 112, 199, 1)",
    cursor: "pointer",
   }}
   onClick={handleClick}
  >
   <VStack spacing={2} align="stretch">
    <Heading lineHeight={1} fontSize="16px">
     {item.name}
    </Heading>
    <Text
     fontSize="16px"
     lineHeight="19.36px"
     fontWeight={400}
     color="rgba(26, 26, 26, 0.56)"
    >
     {/* {`${item.description.substring(0, item.description.length)}lorem ipsum dkjsahdoahdosakdnwionlnl`} */}
     {item.description.length > 17 ?`${item.description.substring(0, 17)}...` : item.description}
    </Text>
    <Text
     fontSize="16px"
     lineHeight="19.36px"
     fontWeight={400}
     color="rgba(26, 26, 26, 0.56)"
    >
     {`${item?.convertedCurrency?.code} ${item.totalCostPricing?.amount?.toFixed(2)}`}
    </Text>
   </VStack>
  </GridItem>
 );
};

export default Card;
