import { ReactElement, useMemo, useState, useEffect } from "react";
import { removeThousandSeparator } from "utils";
import { useSelector, shallowEqual } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";
import debounce from "lodash/debounce";
import OptipediaToast from "utils/notification-util";
import { ValidationData } from "utils/validation-values";
import { actions as pricingCostDriverAction } from "state/pricings/costDrivers/slice";
import MasterDataAutoComplete from "components/MasterDataAutoComplete";

const SelectNameCustomFieldFromMasterData = ({
 index,
 convertName,
 row,
 costDriver,
 values,
 currentRow,
 paramName,
 code,
 thirdPartyId,
 isRequired,
 isAssociateWithThirdParty,
 isLoading
}: any): ReactElement => {
 const dispatch = useAppDispatch();
 const [newRow, setNewRow] = useState<any>(row);
 const [getname, setname] = useState<any>(null);
 let customsFields: any = [];
 const {
  dataConstDriversOri,
  dataToSendUpdates,
  valueIsIncludeCalculation,
  detailPerVolumeId,
  isEditOpen,
  temporaryDataCostDriver,
  changeRowSimulate,
  masterDataLists,
  loading,
  dataToSendAdds,
  tmpChangeRow
 } = useSelector(
  (state: RootState) => ({
    loading: state.pricingCostDrivers.loading,
   dataConstDriversOri: state.pricingCostDrivers.constDriversOri,
   constDrivers: state.pricingCostDrivers.constDrivers,
   detailPerVolumeId: state.pricingCostDrivers.detailPerVolumeId.result,
   dataToSendUpdates: state.pricingCostDrivers.dataToSend.updates,
   dataToSendAdds: state.pricingCostDrivers.dataToSend.adds,
   valueIsIncludeCalculation:
    state.pricingCostDrivers.valueIsIncludeCalculation,
   tmpChangeRow: state.pricingCostDrivers.tmpChangeRow,
   isEditOpen: state.pricingCostDrivers.isEditOpen,
   temporaryDataCostDriver: state.pricingCostDrivers.temporaryDataCostDriver,
   changeRowSimulate: state.pricingCostDrivers.tmpChangeRow,
   customFieldsDatas: state.pricingCostDrivers.customFields,
   masterDataLists: state.masterData.lists?.result?.items,
  }),
  shallowEqual
 );

 useEffect(() => {
  setNewRow(row);
  const findIndexDataToSend = dataToSendAdds.findIndex(
   (dataAdd: any) => dataAdd.simulationCostDriverId === row.id
  );
  const dataCreateToUpdate = {
   index: findIndexDataToSend,
   data: row.customFields,
  };
  if (findIndexDataToSend !== -1) {
   dispatch(pricingCostDriverAction.setChangeAddRowByIndex(dataCreateToUpdate));
  }
 }, [row]);

 useEffect(() => {
  
  if (row.name === "") {
   return;
  }

  const findLastConstDriver = detailPerVolumeId?.costDrivers.find(
   (item: any) => item.id === row.constDriverId
  );

  const isBaseLevel = detailPerVolumeId?.costDrivers.find(
    (item: any) => item.name === row.name
  )
  
  const firstName = findLastConstDriver?.costDrivers.find(
   (costDriver: any) => costDriver.id === row?.id
  );

  changeRowSimulate.find((tmp: any, indexRow: number) => {
   const findDataFromCustomFields = row.customFields.find(
    (customField: any) => customField.name === tmp.name
   );
   
   if(!row.constDriverId){
    const findSameCustomFieldConfigurationId = isBaseLevel?.customFields?.find(
      (customField: any) =>
       customField?.id === tmp?.customFieldConfigurationId
     );
     if(findSameCustomFieldConfigurationId){
      dispatch(
        pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
         index: indexRow,
         // component: componentNameChange,
         idRow: firstName?.id,
         // componentName: findLastConstDriver?.name,
         currencyTo: findSameCustomFieldConfigurationId?.currency?.code
        })
       );
     }
   }

   if (
    tmp?.idRow === findLastConstDriver?.id &&
    tmp?.name === findDataFromCustomFields?.name
   ) {
    const componentNameChange = `${firstName?.name} - ${findDataFromCustomFields?.name}`;
    dispatch(
     pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
      index: indexRow,
      component: componentNameChange,
      idRow: findLastConstDriver?.id,
      componentName: findLastConstDriver?.name,
     })
    );
   }

   const findSameCustomFieldConfigurationId = firstName?.customFields?.find(
    (customField: any) =>
     customField.configuration.id === tmp?.customFieldConfigurationId
   );

   if (
    findSameCustomFieldConfigurationId &&
    tmp?.customFieldConfigurationId ===
     findSameCustomFieldConfigurationId?.configuration?.id &&
    findSameCustomFieldConfigurationId?.costDriver?.id === row?.id
   ) {
    const componentNameChange = `${firstName?.name} - ${findSameCustomFieldConfigurationId?.configuration?.name}Code`;
    dispatch(
     pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
      index: indexRow,
      // component: componentNameChange,
      idRow: firstName?.id,
      // componentName: findLastConstDriver?.name,
      currencyTo: findSameCustomFieldConfigurationId?.currency?.code
     })
    );
   }
   if (
    tmp?.idRow === row?.id 
    && tmp.name === row?.masterDataConfiguration?.configuration?.code
   ) {
    const componentNameChange = `${firstName?.name} - ${firstName?.configuration?.labelName}`;
    const findSameCustomFieldConfigurationId = firstName?.customFields?.find(
      (customField: any) =>
       customField?.id === tmp?.customFieldConfigurationId
     );
    dispatch(
     pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
      index: indexRow,
      component: componentNameChange,
      idRow: findLastConstDriver?.id,
      componentName: findLastConstDriver?.name,
      currencyTo: findSameCustomFieldConfigurationId?.currency?.code,
      before: findLastConstDriver?.unitCost,
      name: findLastConstDriver?.configuration?.labelName,
     })
    );
   }
  });
 }, [detailPerVolumeId]);

 const findOriginalCostDriver =
  dataConstDriversOri &&
  dataConstDriversOri?.data &&
  dataConstDriversOri?.data.find(
   (item: any) => item.costDriverId === costDriver.costDriverId
  );

 const indexSimulateBySku = temporaryDataCostDriver.findIndex(
  (el: any) => el.name == paramName
 );

 const findOneCostDriverOriginal =
  temporaryDataCostDriver[indexSimulateBySku] &&
  temporaryDataCostDriver[indexSimulateBySku]?.data &&
  temporaryDataCostDriver[indexSimulateBySku]?.data.find(
   (item: any) => item.costDriverId === costDriver.costDriverId
  );

 const findIndexOneCostDriverOriginal =
  temporaryDataCostDriver[indexSimulateBySku] &&
  temporaryDataCostDriver[indexSimulateBySku]?.data &&
  temporaryDataCostDriver[indexSimulateBySku]?.data.findIndex(
   (item: any) => item.identityName === costDriver.identityName
  );

 const findIndex = findOneCostDriverOriginal?.data.findIndex((item: any) => {
  return item.id === currentRow?.get("id");
 });

 const runSimulate = useMemo(
  () =>
   debounce(async (dataToUpdate, dataToSet) => {
    const newCustomFieldDataToUpdate: any = [];
    dataToUpdate.customFields.map((customField: any) => {
     if (!customField.isMaintenanceByMasterData) {
      newCustomFieldDataToUpdate.push(customField);
     }
    });
    const newDataToUpdate = {
     ...dataToUpdate,
     customFields: newCustomFieldDataToUpdate,
    };

    await dispatch(
     pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(
      newDataToUpdate
     )
    );

    const dataAfterSimulate = findOneCostDriverOriginal?.data[findIndex];
    if (changeRowSimulate.length > 0) {
    } else {
     const codeName = costDriver.headers.find(
      (header: any) => header.name === convertName
     );
     dispatch(
      pricingCostDriverAction.settmpChangeRow({
       id: dataToSet.id,
       costDriver: findOriginalCostDriver?.tableName,
       component: `${dataAfterSimulate?.name} - ${codeName.label}`,
       before: dataAfterSimulate?.unitCost,
       after: dataAfterSimulate?.isNew
        ? 0
        : dataToSet
        ? dataToSet.after
        : dataAfterSimulate.unitCost,
       idRow: row.id,
       customFieldConfigurationId: dataToSet?.customFieldConfigurationId,
       parentCostDriverId: costDriver.costDriverId,
       componentName: dataToSet.componentName,
       name: dataToSet.name === "unitCost" ? "Cost" : codeName.label,
      })
     );
    }
   }, 1000),
  [dispatch, dataToSendUpdates, changeRowSimulate, detailPerVolumeId]
 );

 if (row?.isNew) {
  const indexNumberToDelete = {
   index1: indexSimulateBySku,
   index2: findIndexOneCostDriverOriginal,
  };
  dispatch(
   pricingCostDriverAction.setObjectsIndexToDelete(indexNumberToDelete)
  );
 }

 const isNewDebounce = useMemo(
  () =>
   debounce(async (dataToAdd) => {
    const {
     name,
     value,
     latestCustomFields,
     tableName,
     customFieldConfigurationId,
     customFieldsData,
     currencyId
    } = dataToAdd;
    const { dataCustomFields, datalatest, newRow } = dataToAdd;
    let myuuid = uuidv4();

    let dataRow = { ...row };
    const isItName = name === "name";
    const isItMasterData = name === "masterDataId";
    const isItDes = name === "description";
    const isItUnitCost = name === "unitCost";
    const isName = isItName ? value : newRow?.name;
    const isMasterData = isItMasterData ? value : dataRow?.masterDataId;
    const isDes = isItDes ? value : newRow?.description;
    const isUnitCost = isItUnitCost ? value : newRow?.unitCost;
    dataRow.name = isName;
    dataRow.masterDataId = isMasterData;
    dataRow.description = isDes;
    dataRow.unitCost = isUnitCost;
    let newData: any = {};
    if (!isItName || (!isItMasterData && !isItDes && !isItUnitCost)) {
     for (var i = 0; i < dataRow.customFields.length; i++) {
      if (dataRow.customFields[i].name === name) {
       const dataToPush = {
        ...dataRow.customFields[i],
        value,
       };
       newData = dataToPush;
      }
     }
    }

    const findCustomFieldWithValueNotNull: any = [];
    dataRow?.customFields.map((item: any) => {
     if (!item?.predefinedData !== null && item.value === "") {
      const data = {
       ...item,
       value: item?.predefinedData?.code,
      };
      return findCustomFieldWithValueNotNull.push(data);
     } else {
      return findCustomFieldWithValueNotNull.push(item);
     }
    });

    const newCustomFieldNotMaintenanceWithMasterData: any = [];
    newRow?.customFields.length > 0 &&
     newRow?.customFields.map((item: any) => {
      if (
       !item.isMaintenanceByMasterData &&
       item.customFieldConfigurationId === customFieldConfigurationId
      ) {
       const itemData = {
        ...item,
        value,
       };
       newCustomFieldNotMaintenanceWithMasterData.push(itemData);
      } else {
       if (item.value === "") {
        const valueData = newRow[item.name];
        newCustomFieldNotMaintenanceWithMasterData.push({
         ...item,
         value: valueData,
        });
       } else {
        newCustomFieldNotMaintenanceWithMasterData.push(item);
       }
      }
     });

    if (dataCustomFields?.customFields.length > 0) {
     dataCustomFields?.customFields.map((item: any) => {
      if (!item.predefinedData !== null && item.value === "") {
       const data = {
        ...item,
        value: item.value,
       };
       return findCustomFieldWithValueNotNull.push(data);
      } else {
       return findCustomFieldWithValueNotNull.push(item);
      }
     });
    }

    let totalNotIncludeCalculations = 0;
    let totalRowCustomFields = 0;
    dataCustomFields?.customFields.map((customField: any) => {
     if (customField.isIncludeInCalculation) {
      customsFields.push(customField);
     } else {
      totalNotIncludeCalculations += 1;
     }
     if (customField.name === name && dataCustomFields[customField.name]) {
      customsFields.push({
       ...customField,
       value,
      });
     } else {
      if (customField.name === name || dataCustomFields[customField.name]) {
       dispatch(
        pricingCostDriverAction.setCustomFields({
         ...customField,
         value: dataCustomFields[customField.name] || value,
        })
       );
       customsFields.push({
        ...customField,
        value: dataCustomFields[customField.name] || value,
       });
      }
     }
    });

    function getDifference(array1: any, array2: any) {
     return array1.filter((object1: any) => {
      return !array2.some((object2: any) => {
       return (
        object1.customFieldConfigurationId ===
        object2.customFieldConfigurationId
       );
      });
     });
    }
    if (latestCustomFields?.length > 0) {
     const diference = getDifference(latestCustomFields, customsFields);

     if (diference.length > 0) {
      diference.map((item: any) => {
       customsFields.push(item);
      });
     }
    }

    const dataSendEdit = customsFields.map((o: any) => o.name);
    const filteredCustomFields = customsFields.filter(
     ({ name }: any, index: number) => !dataSendEdit.includes(name, index + 1)
    );

    const newCustomFieldDataToUpdate: any = [];
    filteredCustomFields.map((customField: any) => {
     if (customField.customFieldId) {
      newCustomFieldDataToUpdate.push(customField);
     }
    });

    newCustomFieldDataToUpdate.map((filteredCustomField: any) => {
     if (!filteredCustomField.isIncludeInCalculation) {
      totalRowCustomFields += 1;
     }
    });
    const data = {
     costAnalysisPerVolumeId: detailPerVolumeId?.id,
     costDriverId: dataRow?.id,
     simulationCostDriverId: myuuid,
     name: dataRow?.masterDataId ? "T" : dataRow?.name,
     masterDataId: dataRow?.masterDataConfiguration?.id,
     description: dataRow?.masterDataId ? "T" : dataRow?.description,
     unitCost: dataRow?.unitCost || 0,
     customFields: customFieldsData,
     //  customFields: dataRow.customFields,
     currencyId: currencyId,
     newData,
    };

    const findNull = newCustomFieldNotMaintenanceWithMasterData.find(
     (item: any) => item.value === null || item.value === ""
    );
    const validateToAdd =
     dataRow.masterDataId !== "" ||
     (dataRow.name !== "" && dataRow.description !== "");
    if (validateToAdd) {
     await dispatch(
      pricingCostDriverAction.directSetAddOneToTemporaryDataCostDriver(data)
     );

     dispatch(pricingCostDriverAction.setNullCustomFields());
    }
   }, 800),
  [dispatch]
 );

 const debouncedRunSimulate = useMemo(
  () =>
   debounce(async (dataOnChange: any) => {
    const {
     name,
     value,
     currentRow1,
     tableName,
     dataFromSimulate,
     isSameData,
     datalatest,
     customFieldsData,
    } = dataOnChange;
    let isNewCostDriver: any;

    if (dataFromSimulate || currentRow1) {
     isNewCostDriver = dataFromSimulate.isNew ? dataFromSimulate : currentRow1;
    } else {
     isNewCostDriver = row;
    }

    let newCustomField: any = [];
    let itemToObject = Object.assign({}, isNewCostDriver);
    // data asli yang tidak di edit
    const originalNewMap = new Map(Object.entries(itemToObject));
    if (convertName !== "unitCost") {
     const tmpChangeToDeletes = {
      component: `${originalNewMap.get("name")} - ${
       name === "unitCost" ? "Cost" : name
      }`,
      name: convertName,
      constDriverId:
       originalNewMap.get("constDriverId") || dataFromSimulate?.constDriverId,
     };
     dispatch(
      pricingCostDriverAction.removeOnetmpChangeRow({ tmpChangeToDeletes })
     );
    } else {
    }
    const findCost = changeRowSimulate.find(
     (item: any) =>
      item.name === "Cost" && item.componentName === isNewCostDriver.name
    );

    if (
     (row.isNew && 0 === value) ||
     removeThousandSeparator(originalNewMap.get(name) as string) === value
    ) {
     const tmpChangeToDeletes = {
      component: `${originalNewMap.get("name")} - ${
       name === "unitCost" ? "Cost" : name
      }`,
      name: convertName,
      constDriverId: originalNewMap.get("constDriverId"),
     };
     dispatch(
      pricingCostDriverAction.removeOnetmpChangeRow({ tmpChangeToDeletes })
     );

     const customFields: any = [];

     isNewCostDriver?.customFields.map((item: any) => {
      if (item.name === name) {
       customFields.push({
        ...item,
        value,
       });
      } else {
       customFields.push(item);
      }
     });
     const dataToUpdate = {
      costAnalysisPerVolumeId: detailPerVolumeId.id,
      costDriverId: isNewCostDriver.id,
      parentCostDriverId: costDriver.costDriverId,
      name: isNewCostDriver.name,
      masterDataId: dataFromSimulate.masterDataId,
      description: isNewCostDriver.description,
      unitCost: dataFromSimulate.unitCost,
      customFields:
       dataFromSimulate.customFields.length > 0
        ? dataFromSimulate.customFields
        : customFieldsData,
     };

     let isCustomFieldAllHaveValue = true;

     row?.customFields.length > 0 &&
      row?.customFields.map((item: any, index: number) => {
       if (item.value === 0 || item.value === "") {
        isCustomFieldAllHaveValue = false;
       }
      });
     runSimulate(dataToUpdate, null);
    } else {
     let haveSameValue = false;
     let isCustomFieldAllHaveValue = true;
     newCustomField.length > 0 &&
      newCustomField.map((item: any) => {
       if (item.predefinedData !== null && item.value === "") {
        isCustomFieldAllHaveValue = false;
       }
      });
     const customFields: any = [];
     isNewCostDriver?.customFields.map((item: any) => {
      if (item.name === name) {
       if (changeRowSimulate.length > 0) {
        for (let i = 0; i < changeRowSimulate.length; i++) {
         if (
          item.customFieldConfigurationId ===
           changeRowSimulate[i].customFieldConfigurationId &&
          item.costDriverId === changeRowSimulate[i].idRow &&
          item.name === changeRowSimulate[i].name
         ) {
          customFields.push({
           ...item,
           value,
          });
         } else {
          if (
           item?.name === name &&
           item?.costDriverName === currentRow1?.name
          ) {
           customFields.push({
            ...item,
            value,
           });
          } else {
           const sameValue = changeRowSimulate.find(
            (data: any) =>
             data.idRow === item.costDriverId &&
             data.customFieldConfigurationId ===
              item.customFieldConfigurationId &&
             item.name === data.name
           );
           if (item.value === value) {
            haveSameValue = true;
           }
           if (sameValue) {
            customFields.push({
             ...item,
             value: sameValue.before,
            });
           } else {
            customFields.push(item);
           }
          }
         }
        }
       } else {
        customFields.push({
         ...item,
         value,
        });
       }
      } else {
       for (let i = 0; i < changeRowSimulate.length; i++) {
        if (
         item.customFieldConfigurationId ===
          changeRowSimulate[i].customFieldConfigurationId &&
         item.costDriverName === changeRowSimulate[i].componentName &&
         item.name === changeRowSimulate[i].name
        ) {
         customFields.push({
          ...item,
          value: changeRowSimulate[i].before,
         });
        } else {
         if (item.name === name && item.costDriverName === row.name) {
          customFields.push({
           ...item,
           value,
          });
         } else {
          const sameValue = changeRowSimulate.find(
           (data: any) =>
            data.idRow === item.costDriverId &&
            data.customFieldConfigurationId ===
             item.customFieldConfigurationId &&
            item.name === data.name
          );
          if (sameValue) {
           customFields.push({
            ...item,
            value: sameValue.before,
           });
          } else {
           customFields.push(item);
          }
         }
        }
       }
      }
     });

     const customFieldsFromSimulate: any = [];

     row?.customFields.map((customField: any) => {
      if (customField.name === name && row[customField.name]) {
       customFieldsFromSimulate.push({
        ...customField,
        value,
       });
      } else {
       if (row[customField.name]) {
        customFieldsFromSimulate.push({
         ...customField,
         value: row[customField.name],
        });
       }
      }
     });

     const findDuplicate = datalatest?.data.findIndex(
      (item: any) => item.masterDataId === value
     );

     const validateToEdit =
      row.name !== "" ||
      (row.masterDataId !== "" &&
       row.description !== "" &&
       isCustomFieldAllHaveValue);
     const sameValue = changeRowSimulate.find(
      (data: any) => data.id === name && parseFloat(data.after) === value
     );

     if (validateToEdit) {
      const isRowMasterData = row?.masterDataConfiguration;
      const dataToUpdate = {
       costAnalysisPerVolumeId: detailPerVolumeId?.id,
       costDriverId: isRowMasterData ? dataFromSimulate?.id : row?.id,
       parentCostDriverId: costDriver.costDriverId,
       name: isRowMasterData ? dataFromSimulate?.name : row?.name,
       masterDataId: isRowMasterData
        ? dataFromSimulate.masterDataId
        : row?.masterDataId,
       currencyId: dataFromSimulate?.currencyId,
       description: isRowMasterData
        ? dataFromSimulate?.description
        : row?.description,
       unitCost: isRowMasterData ? dataFromSimulate?.unitCost : row?.unitCost,
       customFields: isRowMasterData
        ? customFieldsFromSimulate
        : customFieldsData,
       haveSameValue: sameValue !== undefined,
      };
      if (
       findDuplicate !== -1
       // && name === "masterDataId"
      ) {
       dispatch(pricingCostDriverAction.setIsErrorDuplicate(true));
       return OptipediaToast.Warning(
        "The name you typed is duplicated, please use another name"
       );
      }
      {
       dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
       const findCust = customFields.find((item: any) => item.name === name);
       const codeName = costDriver.headers.find(
        (header: any) => header.name === convertName
       );
       let after = isNewCostDriver?.customFields?.find(
        (item: any) => item.name === convertName
       );
       const dataToSet = {
        id: convertName,
        costDriver: tableName,
        component: `${responseFetch?.name} - ${codeName.label}`,
        before: responseFetch?.unitCost,
        after: isNewCostDriver?.isNew
         ? 0
         : after
         ? after.value
         : isNewCostDriver?.unitCost,
        idRow: row.id,
        currencyId: responseFetch?.currencyId,
        customFieldConfigurationId: findCust?.customFieldConfigurationId,
        parentCostDriverId: costDriver?.costDriverId,
        componentName: originalNewMap.get("name"),
        name: name === "unitCost" ? "Cost" : codeName?.label,
       };
       runSimulate(dataToUpdate, dataToSet);
      }
     }
    }
   }, 10),
  [dataToSendUpdates, row]
 );

 const isNew = newRow.isNew !== undefined && newRow.isNew;
 const responseFetch = findOneCostDriverOriginal?.data[findIndex];
 if (row.masterDataId) {
  if (changeRowSimulate.length > 0) {
   const findCost = changeRowSimulate.find(
    (item: any) => item.name === "masterDataId" && item.idRow === row.id
   );

   if (responseFetch && responseFetch.id !== findCost && findCost?.idRow) {
    changeRowSimulate.map((tmpRow: any, index: number) => {
     if (tmpRow.idRow === responseFetch.id) {
      const componentNameChange = `${responseFetch?.name} - ${tmpRow.name}`;
      dispatch(
       pricingCostDriverAction.changeTmpChangeRowComponentByIndex({
        index,
        component: componentNameChange,
        componentName: responseFetch?.name,
       })
      );
     }
    });

    dispatch(
     pricingCostDriverAction.changeBeforeChangeRow({
      ...findCost,
      before: responseFetch?.unitCost,
      component: `${responseFetch?.name} - ${costDriver?.headers[0].label}`,
     })
    );
   }
  }
 }
 let findUOM = newRow?.customFields.find(
  (item: any) => item.predefinedData !== null
 );

 let nullUom = false;

 if (newRow.isNew && findUOM) {
  if (findUOM?.value === "") {
   nullUom = true;
  }
 }

 let isDesNameNothaveValue = false;
 let findNameFromCustomField = -1;
 let isNeedDisableBeforeSimulate = false;
 let isCustomField: any = [];

 if (row.isNew && row.customFields.length > 0) {
  row?.customFields.find((item: any) => {
   if (item.predefinedData === null || item.typeData !== 20) {
    isCustomField.push(item);
   }
  });
  isDesNameNothaveValue = row.name !== "" && row.description !== "" && !nullUom;

  findNameFromCustomField = isCustomField.findIndex(
   (item: any) => item.name === convertName
  );
  isNeedDisableBeforeSimulate = findNameFromCustomField !== -1;
 }
 return (
  <>
   {
    <MasterDataAutoComplete
    name={`customFields[${index}][${convertName}]`}
    id={convertName}
    costDriver={costDriver?.data}
    dataId={row?.id}
    fieldName={`customFields[${index}].masterDataId`}
    isDisabled={loading || (isEditOpen && isNew && isRequired) || !isNew || !isEditOpen }
    // isDisabled={
    //  loading ||
    //  valueIsIncludeCalculation ||
    //  (isEditOpen && !isNew)
     // ||
     // (isEditOpen && row?.index === undefined) ||
     // (isEditOpen && !isNew) || 
    //  (isErrorDuplicate && convertName !== "name")
    // }
    code={code}
    thirdPartyId={isAssociateWithThirdParty ? thirdPartyId : null}
    onChange={(value: any) => {
     const dataToAdd = {
      name: convertName === "name" ? "masterDataId" : convertName,
      value: value?.value,
      dataCustomFields: findOneCostDriverOriginal?.data[findIndex],
      datalatest: costDriver,
      tableName: findOriginalCostDriver?.tableName,
      latestCustomFields:
       findOneCostDriverOriginal?.data[findIndex].customFields,
      customFieldConfigurationId:
       row.customFields[index]?.customFieldConfigurationId,
      newRow: row,
      currencyId: values?.currencyId
     };

     const dataFromServer = findOneCostDriverOriginal?.data[findIndex];
     const isSameId =
      row?.isNew && dataFromServer?.name === ""
       ? true
       : dataFromServer?.id !== row?.id;
     if (row?.name === "") {
      const findDuplicate = costDriver?.data.findIndex(
       (item: any) => item.masterDataId === value?.value
      );

      if (findDuplicate !== -1 && dataToAdd.name === "masterDataId") {
       dispatch(pricingCostDriverAction.setIsErrorDuplicate(true));
       return OptipediaToast.Warning(
        "The name you typed is duplicated, please use another name"
       );
      } else {
       dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
       isNewDebounce(dataToAdd);
      }
     } else {
      let dataToEdit = {
       name: convertName === "name" ? "masterDataId" : convertName,
       value: value?.value,
       currentRow1: findOriginalCostDriver?.data[findIndex],
       tableName: findOriginalCostDriver?.tableName,
       dataFromSimulate: findOneCostDriverOriginal?.data[findIndex],
       datalatest: costDriver,
       newRow: row,
       currencyId: values?.currencyId,
       customFieldsData: [],
      };
      const findDuplicate = costDriver?.data.findIndex(
       (item: any) =>
        item.masterDataId === value?.value && item.name === value?.name
      );
      if (findDuplicate !== -1 && dataToAdd.name === "masterDataId") {
       dispatch(pricingCostDriverAction.setIsErrorDuplicate(true));
       return OptipediaToast.Warning(
        "The name you typed is duplicated, please use another name"
       );
      } else {
       dispatch(pricingCostDriverAction.setIsErrorDuplicate(false));
       setname(dataToEdit.name);
       // add validation isRequired Here!!

       const customFields: any = [];

       newRow?.customFields.map((customField: any, index: number) => {
        if (customField.name === convertName) {
         customFields.push({
          ...customField,
          value: customField.typeData === 30 ? value.value : value,
         });
         //  row.customFields[index].value = value;
        } else {
         customFields.push({
          ...customField,
          value: newRow[customField.name],
         });
        }
       });
       const isRequired = customFields?.find(
        (item: any) =>
         !item.isMaintenanceByMasterData &&
         item.typeData !== 40 &&
         item.isRequired &&
         (item.value === "" || item.value === null)
       );

       dataToEdit.customFieldsData = customFields;
       if (!isRequired || isRequired === undefined) {
        if (newRow?.index !== undefined) {
         isNewDebounce(dataToEdit);
        } else {
         debouncedRunSimulate(dataToEdit);
        }
       }
      }

      const isSameData = ValidationData(
       findOriginalCostDriver?.data[findIndex],
       convertName,
       value?.value,
       false,
       "isNotIncludeCalculation"
      );
      if (isSameData) {
       const tmpChangeToDeletes = {
        component: `${findOriginalCostDriver?.data[findIndex].name} - ${convertName}`,
        name: convertName,
        constDriverId: findOriginalCostDriver?.data[findIndex].constDriverId,
       };
       dispatch(
        pricingCostDriverAction.removeOnetmpChangeRow({ tmpChangeToDeletes })
       );
      }
     }
    }}
   />
   }
  </>
 );
};

export default SelectNameCustomFieldFromMasterData;
