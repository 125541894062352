import { ReactElement, useEffect, useState, useMemo } from "react";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { Box, Center, Heading, Text, VStack } from "@chakra-ui/layout";
import { Button, Image } from "@chakra-ui/react";

import { useAppDispatch, usePrevious } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { actions as stratedAction } from "state/started/slice";
import { RootState } from "state/store";
import BGImage from "assets/images/startPage.png";
import { TableLoading } from "components/Loading";
import { SmallIcon } from "components/Icons";
import { actions as thirdPartySettingAction } from "state/settings/slice";

import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import { Form, Formik } from "formik";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
import { isEmpty } from "lodash";

const GetStarted = (): ReactElement => {
 const dispatch = useAppDispatch();
 const router = useHistory();

 const { started, isAdmin, loading, loadingSubmit, currencyId, lists } =
  useSelector(
   (state: RootState) => ({
    started: state.started.started,
    isAdmin: state.auth.result.isAdmin,
    loading: state.started.loading,
    loadingSubmit: state.started.loadingSubmit,
    currencyId: state.thirdPartySetting.currencyId,
    lists: state.multiCurrency.currencies,
   }),
   shallowEqual
  );
 const fetchList = () => {
  dispatch(multiCurrencyAction.getAllCurrencies());
 };
 const getDefaultCurrency = async () => {
  await dispatch(thirdPartySettingAction.configurationStatus());
 };
 const isStarted = async () => {
  await dispatch(stratedAction.isStarted());
 };
 useEffect(() => {
   isStarted();
  getDefaultCurrency();
 }, []);

 useEffect(() => {
  fetchList();
 }, [started]);

 useEffect(() => {
  if (started && !isEmpty(currencyId)) {
   isAdmin
    ? dispatch(stratedAction.isStarted()).then(
       (data) => data.payload && router.push("/journeys")
      )
    : dispatch(stratedAction.isStarted()).then(
       (data) => data.payload && router.push("/pricings")
      );
  }
 }, [currencyId, started]);

 const handleStarted = () => {
  dispatch(stratedAction.getStarted({}));
 };
 const currencyOpt: any = [];
 lists?.length > 0 && lists?.map((item: any) => {
  currencyOpt.push({
   label: item?.code,
   value: item?.code,
  });
 });

 //  if (started) {
 //   return isAdmin ? <Redirect to="/journeys" /> : <Redirect to="/pricings" />;
 //  }
 if (loading) {
  return <TableLoading />;
 }
 return (
  <Box h={"100vh"}>
   <Box pt={"20vh"}>
    <Center alignItems={"center"}>
     <SmallIcon fontSize={56} m="2rem" />
    </Center>
    <Center>
     <Heading as="h2" fontSize="24px" mb="2rem">
      Embark on your digitalization journey now!
     </Heading>
    </Center>
    <Box>
     <Text textAlign={"center"} mb="1rem" color={"rgba(26, 26, 26, 0.36)"}>
      Ready to unlock data-driven insights with our new digital costing tools?
      This powerful application is designed to <br /> elevate your understanding
      of business costs. With it, you can perform what-if simulations for
      insightful analysis and <br /> accurate spend forecasts.
     </Text>
     {!isEmpty(currencyId) && (
      <Text
       textAlign={"center"}
       mb="2rem"
       fontWeight={600}
       color="rgba(26, 26, 26, 0.76)"
      >
       Before diving in, please select your preferred company currency.
      </Text>
     )}
     {!started ? (
      <Center>
       <Button
        w={250}
        my={"1rem"}
        onClick={() => handleStarted()}
        isLoading={loadingSubmit}
        loadingText="loading..."
       >
        Get Started
       </Button>
      </Center>
     ) : (
      <Center>
       <Formik
        enableReinitialize
        initialValues={{ currencyCode: currencyId }}
        onSubmit={(values) => {
         dispatch(thirdPartySettingAction.currencyConfiguration(values));
         router.push(isAdmin ? "/journeys" : "/pricings");
        }}
       >
        {({ values, setValues }) => (
         <Form>
          <VStack>
           <SelectWithAutoComplete
            defaultValue={currencyId}
            options={currencyOpt}
            name="currencyCode"
            id="currencyCode"
            width={250}
           />
          </VStack>
          <Button
           w={250}
           my={"1rem"}
           type="submit"
           isDisabled={isEmpty(values.currencyCode)}
          >
           Start
          </Button>
         </Form>
        )}
       </Formik>
      </Center>
     )}
    </Box>
    <Image position={"absolute"} right={0} bottom={0} src={BGImage} alt="Bg" />
   </Box>
  </Box>
 );
};

export default GetStarted;
