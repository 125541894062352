import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Box, Grid, Circle } from "@chakra-ui/react";

const SimulationStepper = (): ReactElement => {
 const router = useHistory();
 const { location } = router;
 const query = queryString.parse(location.search);

 const stepDataset = query.page === "select-dataset";
 const stepComponent =
  query.page === "select-component-step-1" ||
  query.page === "select-component-step-2";
 const stepDescribe = query.page === "describe-change";
 const stepSummary = query.page === "summary";

 return (
  <Box display="flex" alignItems="center" w="100%">
   <Grid
    w="75%"
    templateColumns="repeat(5, 1fr)"
    gap={6}
    bg="#F7F9FD"
    borderRadius="3xl"
    color="#00050D"
    p="1rem"
   >
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={query.page === "select-journey" ? "#ffffff" : ""}
       color="white"
       border="1px solid #0072CF"
      >
       <Box as="span">
        <Circle
         size={query.page !== "select-journey" ? "40px" : "34px"}
         bg={query.page !== "select-journey" ? "#00439E" : "#0072CF"}
         color="white"
         fontWeight="600"
         fontSize="16px"
        >
         1
        </Circle>
       </Box>
      </Circle>
      <Box
       w="90px"
       h="4px"
       bgColor={query.page !== "select-journey" ? "#00439E" : "#D7DBE2"}
       ml="1.5rem"
      />
     </Box>
     <Box
      as="p"
      fontWeight="600"
      pt="2"
      color={query.page === "select-journey" ? "" : "#7B7F85"}
     >
      Select journey
     </Box>
    </Box>
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={stepDataset ? "#ffffff" : "#D7DBE2"}
       color="white"
       border={stepDataset ? "1px solid #0072CF" : "none"}
      >
       <Box as="span">
        <Circle
         size={stepComponent ? "40px" : stepDataset ? "34px" : "40px"}
         bg={
          stepComponent || stepDescribe || stepSummary
           ? "#00439E"
           : stepDataset
           ? "#0072CF"
           : "#D7DBE2"
         }
         color="white"
         border={stepDataset || stepComponent ? "1px solid #0072CF" : "none"}
         fontWeight="600"
         fontSize="16px"
        >
         2
        </Circle>
       </Box>
      </Circle>
      <Box
       w="90px"
       h="4px"
       bgColor={
        stepComponent || stepDescribe || stepSummary ? "#00439E" : "#D7DBE2"
       }
       ml="1.5rem"
       borderRadius="2px"
      />
     </Box>
     <Box as="p" fontWeight="600" pt="2" color={stepDataset ? "" : "#7B7F85"}>
      Select dataset
     </Box>
    </Box>
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={stepComponent ? "#ffffff" : "#D7DBE2"}
       color="white"
       border={stepComponent ? "1px solid #0072CF" : "none"}
      >
       <Box as="span">
        <Circle
         size={stepDescribe ? "40px" : stepComponent ? "34px" : "40px"}
         bg={
          stepDescribe || stepSummary
           ? "#00439E"
           : stepComponent
           ? "#0072CF"
           : "#D7DBE2"
         }
         color="white"
         border={stepComponent || stepDescribe ? "1px solid #0072CF" : "none"}
         fontWeight="600"
         fontSize="16px"
        >
         3
        </Circle>
       </Box>
      </Circle>
      <Box
       w="90px"
       h="4px"
       bgColor={stepDescribe || stepSummary ? "#00439E" : "#D7DBE2"}
       ml="1.5rem"
       borderRadius="2px"
      />
     </Box>
     <Box as="p" fontWeight="600" pt="2" color={stepComponent ? "" : "#7B7F85"}>
      Select component
     </Box>
    </Box>
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={stepDescribe ? "#ffffff" : "#D7DBE2"}
       color="white"
       border={stepDescribe ? "1px solid #0072CF" : "none"}
      >
       <Box as="span">
        <Circle
         size={stepSummary ? "40px" : stepDescribe ? "34px" : "40px"}
         bg={stepSummary ? "#00439E" : stepDescribe ? "#0072CF" : "#D7DBE2"}
         color="white"
         border={stepDescribe || stepSummary ? "1px solid #0072CF" : "none"}
         fontWeight="600"
         fontSize="16px"
        >
         4
        </Circle>
       </Box>
      </Circle>
      <Box
       w="90px"
       h="4px"
       bgColor={stepSummary ? "#00439E" : "#D7DBE2"}
       ml="1.5rem"
       borderRadius="2px"
      />
     </Box>
     <Box as="p" fontWeight="600" pt="2" color={stepDescribe ? "" : "#7B7F85"}>
      Describe change
     </Box>
    </Box>
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={stepSummary ? "#ffffff" : "#D7DBE2"}
       color="white"
       border={stepSummary ? "1px solid #0072CF" : "none"}
      >
       <Box as="span">
        <Circle
         size={stepSummary ? "34px" : "40px"}
         bg={stepSummary ? "#0072CF" : "#D7DBE2"}
         color="white"
         border={stepSummary ? "1px solid #0072CF" : "none"}
         fontWeight="600"
         fontSize="16px"
        >
         5
        </Circle>
       </Box>
      </Circle>
      <Box w="90px" h="4px" ml="1.5rem" borderRadius="2px" />
     </Box>
     <Box as="p" fontWeight="600" pt="2" color={stepSummary ? "" : "#7B7F85"}>
      Summary
     </Box>
    </Box>
   </Grid>
  </Box>
 );
};
export default SimulationStepper;
