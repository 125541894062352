import { FC } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Box, Grid, Circle } from "@chakra-ui/react";
import { RootState } from "state/store";
import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";

type SteppersProps = {
 step?: string;
};

const Steppers: FC<SteppersProps> = ({ step }) => {
 const router = useHistory();
 const { location } = router;
 const query = queryString.parse(location.search);

 const stepThirdParty = query.page === "select-third-party";
 const stepPricing =
  query.page === "select-pricing"
 const stepSummary = query.page === "summary";

 let { isEnableThirdPartyStatus } = useSelector(
  (state: RootState) => ({
   isEnableThirdPartyStatus: state.thirdPartySetting.isEnableThirdPartyStatus
  }),
  shallowEqual
 );

 return (
  <Box display="flex" alignItems="center" w="100%">
   <Grid
    w="60%"
    templateColumns="repeat(5, 1fr)"
    gap={6}
    bg="#F7F9FD"
    borderRadius="3xl"
    color="#00050D"
    p="1rem"
   >
    {!isEnableThirdPartyStatus ? 
    <>
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={stepPricing ? "#ffffff" : "#D7DBE2"}
       color="white"
       border={stepPricing ? "1px solid #0072CF" : "none"}
      >
       <Box as="span">
        <Circle
         size={ stepPricing ? "34px" : "40px"}
         bg={
          stepPricing || stepSummary
           ? "#0072CF"
           : "#D7DBE2"
         }
         color="white"
         border={
          stepPricing ? "1px solid #0072CF" : "none"
         }
         fontWeight="600"
         fontSize="16px"
        >
         1
        </Circle>
       </Box>
      </Circle>
      <Box
       w="90px"
       h="4px"
       bgColor={
         stepSummary
         ? "#00439E"
         : "#D7DBE2"
       }
       ml="1.5rem"
       borderRadius="2px"
      />
     </Box>
     <Box
      as="p"
      fontWeight="600"
      pt="2"
      color={!stepPricing ? "" : "#7B7F85"}
     >
      Select Pricing
     </Box>
    </Box>
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={!stepPricing ? "#ffffff" : "#D7DBE2"}
       color="white"
       border={!stepPricing ? "1px solid #0072CF" : "none"}
      >
       <Box as="span">
        <Circle
         size={stepPricing ? "34px" : stepThirdParty ? "34px" : "40px"}
         bg={
          !stepPricing
           ? "#0072CF"
           : "#D7DBE2"
         }
         color="white"
         border={
          stepSummary ? "1px solid #0072CF" : "none"
         }
         fontWeight="600"
         fontSize="16px"
        >
         2
        </Circle>
       </Box>
      </Circle>
     </Box>
     <Box
      as="p"
      fontWeight="600"
      pt="2"
      color={stepSummary ? "" : "#7B7F85"}
     >
      Summary
     </Box>
    </Box>
    </> : 
    <>
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={stepThirdParty ? "#ffffff" : "#D7DBE2"}
       color="white"
       border={stepThirdParty ? "1px solid #0072CF" : "none"}
      >
       <Box as="span">
        <Circle
         size={stepPricing ? "40px" : stepThirdParty ? "34px" : "40px"}
         bg={
          stepPricing || stepSummary
           ? "#00439E"
           : stepThirdParty
           ? "#0072CF"
           : "#D7DBE2"
         }
         color="white"
         border={
          stepThirdParty ? "1px solid #0072CF" : "none"
         }
         fontWeight="600"
         fontSize="16px"
        >
         1
        </Circle>
       </Box>
      </Circle>
      <Box
       w="90px"
       h="4px"
       bgColor={
        stepPricing || stepSummary
         ? "#00439E"
         : "#D7DBE2"
       }
       ml="1.5rem"
       borderRadius="2px"
      />
     </Box>
     <Box
      as="p"
      fontWeight="600"
      pt="2"
      color={stepThirdParty ? "" : "#7B7F85"}
     >
      Select Third-Party
     </Box>
    </Box>
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={stepPricing ? "#ffffff" : "#D7DBE2"}
       color="white"
       border={stepPricing ? "1px solid #0072CF" : "none"}
      >
       <Box as="span">
        <Circle
         size={stepThirdParty ? "40px" : stepPricing ? "34px" : "40px"}
         bg={
          stepSummary
           ? "#00439E"
           : stepPricing
           ? "#0072CF"
           : "#D7DBE2"
         }
         color="white"
         border={
          stepPricing ? "1px solid #0072CF" : "none"
         }
         fontWeight="600"
         fontSize="16px"
        >
         2
        </Circle>
       </Box>
      </Circle>
      <Box
       w="90px"
       h="4px"
       bgColor={
        stepSummary
         ? "#00439E"
         : "#D7DBE2"
       }
       ml="1.5rem"
       borderRadius="2px"
      />
     </Box>
     <Box
      as="p"
      fontWeight="600"
      pt="2"
      color={stepThirdParty ? "" : "#7B7F85"}
     >
      Select Pricing
     </Box>
    </Box>
    <Box as="span" w="100%">
     <Box display="flex" alignItems="center">
      <Circle
       size="40px"
       bg={stepSummary ? "#ffffff" : "#D7DBE2"}
       color="white"
       border={stepSummary ? "1px solid #0072CF" : "none"}
      >
       <Box as="span">
        <Circle
         size={stepPricing ? "34px" : stepThirdParty ? "34px" : "40px"}
         bg={
          stepSummary
           ? "#00439E"
           : "#D7DBE2"
         }
         color="white"
         border={
          stepSummary ? "1px solid #0072CF" : "none"
         }
         fontWeight="600"
         fontSize="16px"
        >
         3
        </Circle>
       </Box>
      </Circle>
     </Box>
     <Box
      as="p"
      fontWeight="600"
      pt="2"
      color={stepSummary ? "" : "#7B7F85"}
     >
      Summary
     </Box>
    </Box>
    </>}
   </Grid>
  </Box>
 );
};

export default Steppers;
