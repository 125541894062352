import { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
 Box,
 Flex,
 Menu,
 MenuButton,
 MenuList,
 MenuItem,
 Button,
 IconButton,
 Image,
 Spacer,
} from "@chakra-ui/react";
import EmptyState from "components/EmptyState";
import CurrencyExchangeUpload from "./Upload";
import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import CurrencyExchangeCreate from "./CreatedForm";
import Table from "components/Table/Table";
import IconKebab from "assets/images/3dots.png";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
import { Form, Formik } from "formik";
import ExchangeDetails from "./Details";
import Swal from "sweetalert2";
import NewPagination from "components/NewPagination";
import { isEmpty, orderBy } from "lodash";

import { actions as settingAction } from "state/settings/slice";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import OptipediaToast from "utils/notification-util";

const ExchangeRate = (): ReactElement => {
 const maxResultCount = 10;
 const {
  exchangeUpload,
  exchangeCreated,
  exchangeUpdated,
  exchangeView,
  listExchange,
  searchQuery,
  totalPage,
  totalCount,
  listCurrency,
  listThirdParty,
  currencyId,
 } = useSelector(
  (state: RootState) => ({
   exchangeUpload: state.multiCurrency.exchangeUpload,
   exchangeCreated: state.multiCurrency.exchangeCreated,
   exchangeUpdated: state.multiCurrency.exchangeUpdated,
   exchangeView: state.multiCurrency.exchangeView,
   listExchange: state.multiCurrency.listsExchange,
   searchQuery: state.multiCurrency.searchQuery,
   totalPage: Math.ceil(
    state.multiCurrency.listsExchange.totalCount / maxResultCount
   ),
   totalCount: state.multiCurrency.listsExchange.totalCount,
   listCurrency: state.multiCurrency.lists,
   listThirdParty: state.thirdPartySetting.thirdParty,
   currencyId: state.thirdPartySetting.currencyId,
  }),
  shallowEqual
 );

 const [pageNumber, setPageNumber] = useState(1);
 const [currenciesOpt, setCurrenciesOpt] = useState<any>([]);
 const [thirdpartyOpt, setThirdpartyOpt] = useState<any>([]);
 const [keyCurrency, setKeyCurrency] = useState("");
 const [keyThirdparty, setKeyThirdparty] = useState("");
 const [Currency, setCurrency] = useState("");
 const [Thirdparty, setThirdparty] = useState("");

 const dispatch = useAppDispatch();
 const history = useHistory();
 const { location } = history;
 const { search } = location;

 const fetchList = (payload: any) => {
  dispatch(multiCurrencyAction.listExchange(payload));
 };
 const getDefaultCurrency = () => {
  dispatch(thirdPartySettingAction.configurationStatus());
 };
 useEffect(() => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   //  currencyCode: Currency,
   //  thirdPartyCode: Thirdparty
  };
  getDefaultCurrency();
  fetchList(payload);
 }, [Currency, Thirdparty]);
 useEffect(() => {
  // fetchListThirdparty()
  fetchListCurrency();
 }, [keyThirdparty, keyCurrency, listExchange]);

 const fetchListCurrency = () => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   keyword: keyCurrency,
  };
  dispatch(multiCurrencyAction.listMultiCurrency(payload));
  setCurrenciesOpt(
   listCurrency?.items?.map((item: any) => {
    return {
     label: item.code,
     value: item.code,
    };
   })
  );
 };

 const fetchListThirdparty = () => {
  const payload = {
   skipCount: 0,
   maxResultCount: maxResultCount,
   keyword: keyThirdparty,
  };
  dispatch(settingAction.getThirdPartyToShow(payload));
  setThirdpartyOpt(
   listThirdParty?.items?.map((item: any) => {
    return {
     label: item.code,
     value: item.code,
    };
   })
  );
 };
 const handleChangePagination = (pageNumber: number) => {
  setPageNumber(pageNumber);
  var skipCount = (pageNumber - 1) * maxResultCount;
  const payload = {
   skipCount,
   maxResultCount: maxResultCount,
   keyword: searchQuery,
  };
  fetchList(payload);
 };
 const COLUMN_HEADER = [
  { name: "currencyCode", label: "Currency Code" },
  { name: "description", label: "Currency Description" },
  { name: "dateFrom", label: "Date From" },
  { name: "dateTo", label: "Date To" },
  { name: "rate", label: "Rate" },
 ];

 const dataTable: any = [];
 Array.isArray(listExchange?.items) &&
  listExchange?.items?.length > 0 &&
  listExchange?.items.map((item: any) =>
   dataTable.push({
    id: item.id,
    currencyCode: item.currency?.code,
    currencyId: item.currency?.id,
    dateFrom: Date.toLocale(item.dateFrom),
    dateTo: Date.toLocale(item.dateTo),
    description: item.currency?.description,
    thirdPartyCode: item.thirdParty?.code,
    rate: parseFloat(parseFloat(item.rate).toFixed(5)),
    journeyCode: item.productConfiguration?.journeyCode,
   })
  );

 return (
  <Box mt={5}>
   {!exchangeUpload &&
    !exchangeCreated &&
    !exchangeUpdated &&
    !exchangeView && (
     <>
      <Flex>
       <Box>
        <Flex>
         {isEmpty(searchQuery) && listExchange?.items?.length !== 0 && (
          <>
           <Button
            onClick={() =>
             dispatch(multiCurrencyAction.setExchangeCreated(true))
            }
            mr={5}
           >
            Create New
           </Button>
           <Button
            onClick={() =>
             dispatch(multiCurrencyAction.setExchangeUpload(true))
            }
            variant={"outline"}
           >
            Upload
           </Button>
          </>
         )}
        </Flex>
       </Box>
       <Spacer />
       <Box>
        {/* <Formik
        initialValues={{ optSelect: "", optThirdparty: "" }}
        onSubmit={() => {}}
       >
        {({ values }) => (
         <Form>
          <Flex>
           <Box mr="10px">
            <SelectWithAutoComplete
             label="Currency"
             defaultValue={Currency}
             placeholder="Type here..."
             name="currency"
             onChange={(e: any) => {
              setCurrency(e.value)
             }}
             onInputChange={(e: any)=> {
              setKeyCurrency(e)
             }}
             options={currenciesOpt || []}
            />
           </Box>
           <Box width={300}>
            <SelectWithAutoComplete
            defaultValue={Thirdparty}
             label="Third-Party"
             placeholder="Type here..."
             name="thirdparty"
             clearValue={()=> setKeyThirdparty("")}
             onChange={(e: any) => {
              setThirdparty(e.value)
             }}
             onInputChange={(e: any)=> {
              setKeyThirdparty(e)
             }}
             options={thirdpartyOpt}
            />
           </Box>
          </Flex>
         </Form>
        )}
       </Formik> */}
       </Box>
      </Flex>
      {listExchange?.items?.length > 0 && (
       <Table
        havePagination={true}
        loading={false}
        columnHeaders={COLUMN_HEADER}
        data={dataTable || []}
        pageNumber={1}
        currentPage={1}
        totalAllPage={1}
        totalRowsPage={10}
        actionButtons={(id: string, rowData: any) => {
         return (
          <Menu>
           <MenuButton
            as={IconButton}
            variant="ghost"
            size="custom"
            height="30px"
            width="30px"
            aria-label="Options"
            _focus={{
             borderColor: "#ffffff",
            }}
            icon={
             <Image src={IconKebab} cursor="pointer" alt="Option" w="4px" />
            }
           />
           <MenuList>
            {currencyId !== rowData.currencyId && (
             <MenuItem
              onClick={() => {
               dispatch(multiCurrencyAction.setExchangeDataToEdit(rowData));
               dispatch(multiCurrencyAction.setExchangeUpdated(true));
              }}
             >
              Edit
             </MenuItem>
            )}
            {currencyId !== rowData.currencyId && (
             <MenuItem
              onClick={() =>
               Swal.fire({
                showCancelButton: true,
                html: "Are you sure you want to delete this Exchange Rate?",
                cancelButtonText: "No",
                confirmButtonText: "Yes",
               }).then(async (result) => {
                if (result.isConfirmed) {
                 await dispatch(multiCurrencyAction.deletesExchange(id)).then(
                  (res: any) => {
                   if (res.payload !== undefined) {
                    OptipediaToast.Success(
                     "The Exchange rate has been removed successfully."
                    );
                   }
                  }
                 );
                 const payload = {
                  skipCount: 0,
                  maxResultCount: maxResultCount,
                  keyword: searchQuery,
                 };
                 fetchList(payload);
                }
               })
              }
             >
              Delete
             </MenuItem>
            )}
            <MenuItem
             onClick={() => {
              dispatch(multiCurrencyAction.setExchangeDataToEdit(rowData));
              dispatch(multiCurrencyAction.setExchangeView(true));
             }}
            >
             View
            </MenuItem>
           </MenuList>
          </Menu>
         );
        }}
       />
      )}
     </>
    )}
   {Array.isArray(listExchange?.items) &&
    listExchange?.items?.length > 0 &&
    !exchangeUpload &&
    !exchangeCreated &&
    !exchangeUpdated &&
    !exchangeView && (
     <Box w="100%" mt="1rem" p="20px">
      <NewPagination
       onChange={handleChangePagination}
       totalRowsPage={maxResultCount}
       pageNumber={totalPage}
       currentPage={pageNumber}
       totalAllPage={totalCount}
      />
     </Box>
    )}
   {listExchange?.items?.length === 0 &&
    !exchangeUpload &&
    !exchangeCreated && (
     <EmptyState isSearch={false}>
      <Flex>
       <Button
        onClick={() => dispatch(multiCurrencyAction.setExchangeCreated(true))}
        mr={5}
       >
        Create New
       </Button>
       <Button
        onClick={() => dispatch(multiCurrencyAction.setExchangeUpload(true))}
        variant={"outline"}
       >
        Upload
       </Button>
      </Flex>
     </EmptyState>
    )}
   {exchangeUpload && <CurrencyExchangeUpload />}
   {exchangeCreated && <CurrencyExchangeCreate />}
   {exchangeUpdated && <CurrencyExchangeCreate />}
   {exchangeView && <ExchangeDetails />}
  </Box>
 );
};

export default ExchangeRate;
