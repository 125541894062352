import { createSlice } from "@reduxjs/toolkit";

import {
 user,
 getThirdPartyToShow,
 getThirdPartyToAdd,
 getThirdPartyById,
 addThirdParty,
 editThirdPartyById,
 deleteThirdPartyById,
 configuration,
 configurationStatus,
 getThirdPartyUser,
 getThirdPartyUserById,
 editThirdPartyUserById,
 postThirdPartyUser,
 deleteThirdPartyUserById,
 currencyConfiguration
} from "state/settings/thunk";
interface historyTs {
 userName: string;
 userFullName: string;
 startUploadTime: string;
 endUploadTime: string;
}
interface InitialState {
 histories: historyTs;
 error: unknown;
 loading: boolean;
 thirdPartyloading: boolean;
 searchQuery: string;
 searchKey: string;
 users: any;
 user: any;
 thirdParty: any;
 thirdPartyToAdd:any;
 thirdPartyById: any;
 isEnableThirdParty: boolean;
 thirdPartyUser: any;
 isEnableThirdPartyStatus: boolean;
 thirdPartyUserById: any;
 activeButton: string;
 success: boolean;
 sidebarOpen: boolean;
 navbarOpen: boolean;
 currencyId: string;
}
const initialState: InitialState = {
 histories: {
  userName: "",
  userFullName: "",
  startUploadTime: "",
  endUploadTime: "",
 },
 error: null,
 loading: false,
 thirdPartyloading: false,
 searchQuery: "",
 searchKey: "",
 users: {},
 user: {},
 thirdParty: {},
 thirdPartyToAdd: {},
 thirdPartyById: {},
 isEnableThirdParty: false,
 thirdPartyUser: {},
 thirdPartyUserById: {},
 isEnableThirdPartyStatus: false,
 activeButton: "General Configuration",
 success: false,
 sidebarOpen: true,
 navbarOpen: false,
 currencyId: ""
};

const thirdPartySetting = createSlice({
 name: "settings",
 initialState,
 reducers: {
  clear: () => initialState,
  setSearchQuery: (state, action) => {
   const value = action.payload;
   state.searchQuery = value;
  },
  setSidebarOpen: (state, action) => {
   state.sidebarOpen = action.payload;
  },
  setUser: (state, action) => {
   state.user = action.payload;
  },
  setUsers: (state) => {
   state.users = {};
  },
  setActiveButton: (state, action) => {
   state.activeButton = action.payload;
  },
  setNavbarOpen: (state, action) => {
    state.navbarOpen = action.payload;
   },
  setEnableThirdParty: (state, action) => {
    state.isEnableThirdParty = action.payload
  }
 },
 extraReducers: (builder) => {
  //  GET USERS
  builder.addCase(user.pending, (state) => {
   state.thirdPartyloading = true;
   state.error = null;
  });

  builder.addCase(user.fulfilled, (state, action) => {
   state.thirdPartyloading = false;
   state.users = action.payload;
  });

  builder.addCase(user.rejected, (state, action) => {
   state.thirdPartyloading = false;
   state.error = action.payload;
  });

  //   GET THIRD_PARTY TO SHOW
  builder.addCase(getThirdPartyToShow.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getThirdPartyToShow.fulfilled, (state, action) => {
   state.loading = false;
   state.thirdParty = action.payload;
  });

  builder.addCase(getThirdPartyToShow.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   GET THIRD_PARTY TO ADD
  builder.addCase(getThirdPartyToAdd.pending, (state) => {
    state.thirdPartyloading = true;
    state.error = null;
   });
 
   builder.addCase(getThirdPartyToAdd.fulfilled, (state, action) => {
    state.thirdPartyloading = false;
    state.thirdPartyToAdd = action.payload;
   });
 
   builder.addCase(getThirdPartyToAdd.rejected, (state, action) => {
    state.thirdPartyloading = false;
    state.error = action.payload;
   });
  //   ADD THIRD_PARTY
  builder.addCase(addThirdParty.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(addThirdParty.fulfilled, (state, action) => {
   state.loading = false;
   state.thirdParty = action.payload;
  });

  builder.addCase(addThirdParty.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   GET THIRD_PARTY BY ID
  builder.addCase(getThirdPartyById.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getThirdPartyById.fulfilled, (state, action) => {
   state.loading = false;
   state.thirdPartyById = action.payload;
  });

  builder.addCase(getThirdPartyById.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   THIRDPARTY CONFIGURATION
  builder.addCase(configuration.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(configuration.fulfilled, (state, action) => {
   state.loading = false;
   state.isEnableThirdParty = action.payload;
  });

  builder.addCase(configuration.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   THIRDPARTY CONFIGURATION STATUS
  builder.addCase(configurationStatus.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(configurationStatus.fulfilled, (state, action) => {
   state.loading = false;
   state.isEnableThirdPartyStatus = action.payload.isEnableThirdParty;
   state.currencyId = action.payload.currencyId
  });

  builder.addCase(configurationStatus.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   THIRDPARTY USER LIST
  builder.addCase(getThirdPartyUser.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getThirdPartyUser.fulfilled, (state, action) => {
   state.loading = false;
   state.thirdPartyUser = action.payload;
  });

  builder.addCase(getThirdPartyUser.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   THIRDPARTY USER LIST BY ID
  builder.addCase(getThirdPartyUserById.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getThirdPartyUserById.fulfilled, (state, action) => {
   state.loading = false;
   state.thirdPartyUserById = action.payload;
  });

  builder.addCase(getThirdPartyUserById.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   UPDATE THIRDPARTY USER LIST BY ID
  builder.addCase(editThirdPartyUserById.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(editThirdPartyUserById.fulfilled, (state, action) => {
   state.loading = false;
  });

  builder.addCase(editThirdPartyUserById.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  //   THIRDPARTY USER CREATED
  builder.addCase(postThirdPartyUser.pending, (state) => {
   state.loading = true;
   state.success = false;
   state.error = null;
  });

  builder.addCase(postThirdPartyUser.fulfilled, (state, action) => {
   state.loading = false;
   state.success = true;
  });

  builder.addCase(postThirdPartyUser.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  // DELETE THIRDPARTY USER LIST BY ID
  builder.addCase(deleteThirdPartyUserById.pending, (state) => {
   state.loading = true;
   state.success = false;
   state.error = null;
  });
  builder.addCase(deleteThirdPartyUserById.fulfilled, (state, action) => {
   state.loading = false;
  });
  builder.addCase(deleteThirdPartyUserById.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  // UPDATE CURRENCY ID
  builder.addCase(currencyConfiguration.pending, (state) => {
    state.loading = true;
    state.success = false;
    state.error = null;
   });
   builder.addCase(currencyConfiguration.fulfilled, (state, action) => {
    state.loading = false;
    state.currencyId = action.payload.currencyId
   });
   builder.addCase(currencyConfiguration.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });
 },
});
export const actions = {
 ...thirdPartySetting.actions,
 user,
 getThirdPartyToShow,
 getThirdPartyToAdd,
 getThirdPartyById,
 addThirdParty,
 editThirdPartyById,
 deleteThirdPartyById,
 configuration,
 getThirdPartyUser,
 getThirdPartyUserById,
 editThirdPartyUserById,
 postThirdPartyUser,
 configurationStatus,
 deleteThirdPartyUserById,
 currencyConfiguration
};

export const reducer = thirdPartySetting.reducer;
