import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";

export const isStarted = createAsyncThunk("third-party/isStarted", async () => {
 const response: any = await HttpService.get(`Tenant/IsStarted`);
 return response.result;
});
export const getStarted = createAsyncThunk(
 "third-party/getStarted",
 async (objects: any) => {
  const config = {
   headers: {
    notUseToast: true,
   },
  };
  const response: any = await HttpService.post(
   `Tenant/GetStarted`,
   objects,
   config
  );
  localStorage.setItem("isStarted", response.result);
  return response.result;
 }
);
