import { ReactElement } from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, Text, Heading } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";

const MasterData = (): ReactElement => {
 const router = useHistory();

 return (
  <Box>
   <Heading pl="4" as="h2" fontSize="22px" mb="2rem">
    Master Data Configuration
   </Heading>

   <Box borderRadius={20} bg="#F7F9FD" p={"2rem"}>
    <Text textAlign={"center"} fontSize="18px">
     By using Master Data Configuration menu, you can design your own master
     data structure and you can able to adjust it based on your business case.
    </Text>
    <Text mt="5" textAlign={"center"} fontSize="18px">
     Please click{" "}
     <Link style={{textDecoration:"underline", color:"#0072CF", fontWeight:700}} to="/master-data">
       here
     </Link>{" "}
     to see the master data that already maintained in the system or you may
     click on Configure button below to create a new master data configuration.
    </Text>
   </Box>

   <Box textAlign={"center"} mt="3rem">
    <Button
     zIndex="9"
     bg="#0072CF"
     w="320px"
     h="79"
     borderRadius="5px"
     color="white"
     _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
     onClick={() => {
        router.push("/settings/master-data/configuration")
     }}
    >
     Go to Configuration
    </Button>
   </Box>
  </Box>
 );
};

export default MasterData;
