import { ReactElement } from "react";
import { Box, Button, Flex, Spacer, Spinner, Text } from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import Swal from "sweetalert2";
import { useAppDispatch } from "hooks";
import { actions as approvalListAction } from "state/approval/slice";

const ToolbarBottom = ({ status, user }: any): ReactElement => {
 const dispatch = useAppDispatch();
 const maxResultCount = 100;
 
 let { loading, selection, approvalSelect } =
 useSelector(
  (state: RootState) => ({
   loading: state.approval.loading,
   selection: state.approval.ids,
   approvalSelect: state.approval.approvalSelect
  }),
  shallowEqual
 );

 const handleSubmitApprove = async (value: any) => {
    const payload = {
        status: value,
        ids : approvalSelect
    }
    await dispatch(approvalListAction.approvalSubmit(payload))
    dispatch(approvalListAction.removeAllSelection())
    window.location.reload();
 }
 const handleSubmitReject = async (value: any) => {
  const payload = {
   status: value,
   ids: approvalSelect,
  };
  await dispatch(approvalListAction.approvalReject(payload));
  dispatch(approvalListAction.removeAllSelection());
  
  window.location.reload();
  //  const payload2 = {
  //      skipCount: 0,
  //      maxResultCount: maxResultCount,
  //      search: "",
  //      journeyCode: "",
  //      thirdPartyCode: "",
  //     };
  //     dispatch(approvalListAction.approvalList(payload2));
 };

 return (
  <Box
   position="fixed"
   bottom="0"
   right="0"
   backgroundColor="#ffffff"
   height="4.5rem"
   w="100%"
  >
   <Flex p="1rem" mr="1rem">
    <Box>
     {loading && (
      <Flex alignItems="center" gap="2">
       <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="sm"
       />
       <Text>Processing…</Text>
      </Flex>
     )}
    </Box>
    <Spacer />
    <Button
    mx={2}
    variant={'outline'}
     onClick={async () => {
        Swal.fire({
         showCancelButton: true,
         html: "Are you sure you want to reject the selected pricing volume?",
         confirmButtonText: "Yes",
         cancelButtonText: "No",
        }).then(async (result) => {
         if (result.isConfirmed) {
            handleSubmitReject(status)
         }
        });
       }}
    >
     Reject
    </Button>
    <Button
     onClick={async () => {
        Swal.fire({
         showCancelButton: true,
         html: "Are you sure you want to approve the selected pricing volume?",
         confirmButtonText: "Yes",
         cancelButtonText: "No",
        }).then(async (result) => {
         if (result.isConfirmed) {
            handleSubmitApprove(status)
         }
        });
       }}
    >
     Approve
    </Button>
   </Flex>
  </Box>
 );
};

export default ToolbarBottom;
