import { useEffect, useRef } from "react";
import Layout from "views/Layout";
import { Box, Heading, VStack } from "@chakra-ui/layout";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import Stepper from "views/Simulation/createCompare/stepper";
import BusinessCase from "views/Simulation/createCompare/businessCase";
import JourneyOverview from "views/Simulation/createCompare/journey";
import SummaryOverview from "views/Simulation/createCompare/summary";

export default function Comparison() {
 const router = useHistory();
 const messagesEndRef = useRef<null | HTMLDivElement>(null);
 const { location } = router;
 const { search } = location;
 const query = queryString.parse(search);

 useEffect(() => {
  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
 }, [search]);

 useEffect(() => {
  window.scrollTo(0, 0);
  if (query?.page !== "select-journey") {
   window.location.href = "/simulations/comparasion/create?page=select-journey";
  }
 }, []);

 return (
  <Layout>
   <VStack h="90vh" align="flex-start" ref={messagesEndRef}>
    <Box display="flex" w="100%">
     <Heading as="h1" fontSize="36px" pb="36px">
      {query?.page === "select-journey" && "Comparison"}
      {query?.page === "select-business-case" && "Comparison"}
      {query?.page === "summary" && "Comparison"}
     </Heading>
    </Box>
    <Stepper />
    {/* {query?.page !== "summary" ? <Stepper /> : <></>} */}
    <Box w="100%">
     {query?.page === "select-journey" && <JourneyOverview />}
     {query?.page === "select-business-case" && <BusinessCase />}
     {query?.page === "summary" && <SummaryOverview />}
    </Box>
   </VStack>
  </Layout>
 );
}
