import { createSlice } from '@reduxjs/toolkit'
import { groupBy } from 'lodash';
import { getComponent, postComponent, postDownloadComponent, postComponentChanges, postUploadComponentChanges } from './thunk';

interface InitialState {
  loading: boolean;
  error: unknown;
  fileUpload: any;
  components: [
    {
      id? : string,
      name? : string,
      productConfigurationId? : string,
      journeyName? : string
    }?
  ];
  tmpCostDriver : [
    {
      costDriver? : string,
      data? : []
    }?
  ];
  pages: string;
  thirdPartyIds: any[];
  costDriverIds: any[];
  dataTable: any;
  dataToSend: {}[];
  updates:{
    result: []
  }
}

const initialState: InitialState = {
  loading: false,
  error: null,
  fileUpload: {},
  components: [],
  pages: '',
  thirdPartyIds: [],
  costDriverIds: [],
  dataTable: {},
  tmpCostDriver : [],
  dataToSend: [],
  updates: {
    result: []
  }
}

const massUpdateSlice = createSlice({
  name: 'massUpdate',
  initialState,
  reducers: {
    clear: () => initialState,
    setPages: (state, action) => {
      state.pages = action.payload
    },
    setThirdPartyIds: (state, action) => {
        state.thirdPartyIds = action.payload
    },
    setCostDriverIds: (state, action) => {
      state.costDriverIds = action.payload
  },
    setDataToSend: (state, action) => {
      const findDataIndex = state.dataToSend.findIndex((item: any) => item.id === action.payload?.id)
      if(findDataIndex !== -1){
        state.dataToSend[findDataIndex] = action.payload
      } else {
        state.dataToSend.push(action.payload)
      }
    },
    setUploadFile: (state, action) => {
      state.fileUpload = action.payload
    }
  },
  extraReducers: (builder) => {
    // GET ALL COMPONENTS
  builder.addCase(getComponent.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
 
   builder.addCase(getComponent.fulfilled, (state, action) => {
    state.loading = false;
    state.components = action.payload
   });
 
   builder.addCase(getComponent.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });

   // POST COMPONENTS SELECTED
 builder.addCase(postComponent.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(postComponent.fulfilled, (state, action) => {
   state.loading = false;
   if(action.payload){
    const defaultData = groupBy(action.payload, data => data.configuration?.identityCode)
    if(state.tmpCostDriver.length > 0){
      state.tmpCostDriver = []
    }
    for (const [key, value] of Object.entries(defaultData)) {
      let newData : {costDriver: string, data: any} = {
        costDriver : key,
        data: value
      }
      state.tmpCostDriver.push(newData)
     
  }
  }
   state.dataTable = action.payload;
  });

  builder.addCase(postComponent.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

     // POST COMPONENTS CHANGES
 builder.addCase(postComponentChanges.pending, (state) => {
  state.loading = true;
  state.error = null;
 });

 builder.addCase(postComponentChanges.fulfilled, (state, action) => {
  state.loading = false;
  state.updates = action.payload;
 });

 builder.addCase(postComponentChanges.rejected, (state, action) => {
  state.loading = false;
  state.error = action.payload;
 });

   // DOWNLOAD TEMPLATE
   builder.addCase(postDownloadComponent.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
 
   builder.addCase(postDownloadComponent.fulfilled, (state, action) => {
    state.loading = false;
   });
 
   builder.addCase(postDownloadComponent.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });

   // UPLOAD TEMPLATE
   builder.addCase(postUploadComponentChanges.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
 
   builder.addCase(postUploadComponentChanges.fulfilled, (state, action) => {
    state.loading = false;
    state.updates = action.payload
   });
 
   builder.addCase(postUploadComponentChanges.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });
  }
})

export const actions = {
  ...massUpdateSlice.actions,
  getComponent,
  postComponent,
  postDownloadComponent,
  postComponentChanges,
  postUploadComponentChanges
}

export const reducer = massUpdateSlice.reducer
