import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";

export const getJourney = createAsyncThunk(
 "simulations/getJourney",
 async () => {
  const response: any = await HttpService.get("simulations/journeys");
  return response.result;
 }
);

export const download = createAsyncThunk(
 "simulations/download",
 async ({ id }: any) => {
  return await HttpService.get(`/simulations/template/${id}`, {
   responseType: "blob",
  }).then((response: any) => {
   const url = window.URL.createObjectURL(new window.Blob([response]));
   const link = document.createElement("a");
   link.href = url;
   link.setAttribute("download", "Optipedia Simulation Data Upload.xlsx");
   document.body.appendChild(link);
   link.click();
   link.remove();
  });
 }
);

export const upload = createAsyncThunk(
 "simulations/upload",
 async ({ payload }: any) => {
  const formData = new FormData();
  const file = payload.file[0] as any;
  const journeyId = payload.journeyId;
  formData.append("file", file);
  formData.append("journeyId", journeyId);
  const response: any = await HttpService.post(
   "simulations/template",
   formData
  );
  return response;
 }
);

// tidak dipakai lagi
export const levels = createAsyncThunk(
 "simulations/level",
 async ({ payload }: any) => {
  const data = payload as any;
  const response: any = await HttpService.post("simulations/levels", data);
  return response;
 }
);

export const components = createAsyncThunk(
 "simulations/component",
 async ({ payload }: any) => {
  const data = payload as any;
  const response: any = await HttpService.post("simulations/components", data);
  return response;
 }
);

export const subComponents = createAsyncThunk(
 "simulations/subComponent",
 async ({ payload }: any) => {
  const data = payload as any;
  const response: any = await HttpService.post(
   "simulations/subComponents",
   data
  );
  return response;
 }
);

export const describeChange = createAsyncThunk(
 "simulations/describeChange",
 async ({ payload }: any) => {
  const data = payload as any;
  const response: any = await HttpService.post("simulations/simulate", data);
  return response;
 }
);

export const saveBusiness = createAsyncThunk(
 "simulations/saveBusiness",
 async ({ payload }: any) => {
  const data = payload as any;
  const response: any = await HttpService.post(
   "/simulations/SaveBusinessCase",
   data
  );
  return response;
 }
);

export const getSimulation = createAsyncThunk(
 "simulations/getSimulation",
 async (params: any) => {
  const response: any = await HttpService.get("simulations", { params });
  return response.result;
 }
);

export const getSimulationById = createAsyncThunk(
 "simulations/getSimulationId",
 async ({ id }: any) => {
  const response: any = await HttpService.get(`/simulations/${id}`);
  return response.result;
 }
);

export const deleteSimulation = createAsyncThunk(
 "simulations/delete",
 async ({ id }: any) => {
  const response: any = await HttpService.delete(`/simulations/${id}`);
  return response.success;
 }
);

export const compareBusinessCase = createAsyncThunk(
 "simulations/comparasion/compareBusinessCase",
 async (data: any) => {
  const response: any = await HttpService.post("/simulations/compare", data);
  return response;
 }
);

export const getJourneytorCompare = createAsyncThunk(
 "simulations/comparasion/getJourneytoCompare",
 async ({ id }: any) => {
  const response: any = await HttpService.get(`simulations/journeys/${id}`);
  return response;
 }
);
