import { ReactElement, useEffect, useState, useMemo } from "react";
import { Box, Grid, GridItem } from "@chakra-ui/layout";
import {
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalFooter,
 ModalBody,
 ModalCloseButton,
 Button,
 useDisclosure,
 Input,
 InputGroup,
 InputLeftElement,
 InputRightElement,
 Select,
 Text,
 Stat,
 StatLabel,
 StatHelpText,
 FormHelperText,
} from "@chakra-ui/react";
import { SearchIcon, EmailIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { useAppDispatch, usePrevious } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import { RootState } from "state/store";
import { isEmpty } from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";

interface Values {
 userName: string;
 emailAddress: string;
 thirdPartyId: string;
 userId: string;
}

const ModalAddUser = (): ReactElement => {
 const [isShow, setShow] = useState(false);
 const [getUserId, setgetUserId] = useState<any>({});
 const dispatch = useAppDispatch();
 const { isOpen, onOpen, onClose } = useDisclosure();
 const maxResultCount = 10;

 let { loading, searchQuery, users, user, thirdPartyToAdd, success } = useSelector(
  (state: RootState) => ({
   loading: state.thirdPartySetting.thirdPartyloading,
   searchQuery: state.thirdPartySetting.searchQuery,
   searchKey: state.thirdPartySetting.searchKey,
   users: state.thirdPartySetting.users,
   user: state.thirdPartySetting.user,
   thirdPartyToAdd: state.thirdPartySetting.thirdPartyToAdd,
   success: state.thirdPartySetting.success,
  }),
  shallowEqual
 );
 useEffect(() => {
  window.scrollTo(0, 0);
 }, [searchQuery, loading]);

 const validation = Yup.object().shape({
  userName: Yup.string().required("User cannot be found"),
  emailAddress: Yup.string()
   .email("Invalid email")
   .required("User cannot be found"),
  thirdPartyId: Yup.string().required(
   "Please select third-party before submit!"
  ),
 });

 const handleChangeSearchBar = (value: any) => {
  dispatch(thirdPartySettingAction.setSearchQuery(value));
  if (isEmpty(value)) {
   dispatch(thirdPartySettingAction.setUser(''));
  //  dispatch(thirdPartySettingAction.clear());
  } else {
   fetchListOfUser(0, value);
  }
 };

 const fetchListOfUser = (skipCount: number, keywoard: string) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: maxResultCount,
   keyword: keywoard,
  };

  return dispatch(thirdPartySettingAction.user(payload));
 };

 const fetchListOf3rdParty = (skipCount: number) => {
  const payload = {
    skipCount: skipCount,
    maxResultCount: 100,
   };
  return dispatch(thirdPartySettingAction.getThirdPartyToAdd(payload));
 };
 const handleReset = () => {
  dispatch(thirdPartySettingAction.setSearchQuery(""));
  dispatch(thirdPartySettingAction.setUser({}));
  onClose();
 };
 const handleSelect = (data: any) => {
  setgetUserId(data);
  dispatch(thirdPartySettingAction.setUser(data));
  dispatch(thirdPartySettingAction.setSearchQuery(data.userName));
  dispatch(thirdPartySettingAction.setUsers());
 };
 const fetchListOfUserList = (skipCount: number) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: maxResultCount,
  };

  return dispatch(thirdPartySettingAction.getThirdPartyUser(payload));
 };
 useEffect(() => {
  fetchListOf3rdParty(0);
 }, []);

 return (
  <Box>
   <Button
    m="0rem 0rem 2rem 0rem"
    h="50px"
    w="200px"
    onClick={onOpen}
    borderRadius={"5px"}
    colorScheme={"blue"}
   >
    Add User
   </Button>
   <Modal
    closeOnOverlayClick={false}
    blockScrollOnMount={false}
    isOpen={isOpen}
    onClose={onClose}
    isCentered
   >
    <ModalOverlay />
    <Formik
     enableReinitialize
     initialValues={
      user || { userName: "", emailAddress: "", thirdPartyId: "", userId: "" }
     }
     validationSchema={validation}
     onSubmit={(values: Values) => {
      dispatch(
       thirdPartySettingAction.postThirdPartyUser({
        userId: getUserId.id,
        thirdPartyId: values.thirdPartyId,
       })
      ).then((x)=> fetchListOfUserList(0))
       .then((x) => handleReset());
     }}
    >
     {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
     }) => (
      <form onSubmit={handleSubmit}>
       <ModalContent>
        <ModalHeader>Add User</ModalHeader>
        <ModalCloseButton onClick={() => handleReset()} />
        <ModalBody>
         <Box pt="1rem">
          <Text pb="1rem" fontWeight={"500"}>
           User Name
          </Text>
          <InputGroup>
           <InputLeftElement
            pt="9px"
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
           />
           <Input
            value={searchQuery || values.userName}
            h={50}
            type="text"
            borderRadius="14px"
            placeholder="Search User"
            onChange={(e) => handleChangeSearchBar(e.target.value)}
            onClick={() => setShow(true)}
            name="userName"
           />
          </InputGroup>
          {errors.userName && (
           <Text p="2" fontSize="12px" color="red">
            {errors.userName}
           </Text>
          )}
          <Box
           display={!isShow ? "none" : !isEmpty(users) ? "" : "none"}
           m="0.5rem"
           borderRadius="14px"
           backgroundColor={!isEmpty(users?.items) ? "#E6EAF1" : ""}
          >
           <Grid p="1rem" templateColumns="repeat(1, 1fr)" gap={4}>
            {!isEmpty(users?.items)
             ? users.items.slice(0, 5).map((item: any, index: number) => (
                <GridItem key={index} onClick={() => handleSelect(item)}>
                 <Stat>
                  <StatLabel fontSize={18}>{item.userName}</StatLabel>
                  <StatHelpText>{item.emailAddress}</StatHelpText>
                 </Stat>
                </GridItem>
               ))
             : null}
           </Grid>
          </Box>
         </Box>
         <Box pt="1rem">
          <Text pb="1rem" fontWeight={"500"}>
           Email
          </Text>
          <InputGroup>
           <InputLeftElement
            pt="9px"
            pointerEvents="none"
            children={<EmailIcon color="gray.300" />}
           />
           <Input
            value={values.emailAddress}
            border="1px solid #0072CF"
            disabled
            type="text"
            borderRadius="14px"
            h={50}
            placeholder="Email"
            name="emailAddress"
            backgroundColor={"#E6EAF1"}
           />
          </InputGroup>
          {errors.emailAddress && (
           <Text p="2" fontSize="12px" color="red">
            {errors.emailAddress}
           </Text>
          )}
         </Box>
         <Box pt="1rem">
          <Text pb="1rem" fontWeight={"500"}>
           [Third-Party] Description
          </Text>
          <InputGroup>
           <InputRightElement
            pt="9px"
            pointerEvents="none"
            children={<ArrowDownIcon color="gray.300" />}
           />
           <Select
            color="grey"
            h={50}
            border="1px solid #0072CF"
            borderRadius="14px"
            placeholder="Select Third-Party"
            onChange={handleChange}
            name="thirdPartyId"
           >
            {!isEmpty(thirdPartyToAdd) &&
             thirdPartyToAdd.items.map((item: any, index: number) => (
              <option key={index} value={item.id}>
               {item.code}
              </option>
             ))}
           </Select>
          </InputGroup>
          {errors.thirdPartyId && (
           <Text p="2" fontSize="12px" color="red">
            {errors.thirdPartyId}
           </Text>
          )}
         </Box>
        </ModalBody>
        <ModalFooter>
         <Button mr="10px" h='54px' w={200} size={"custom"} onClick={handleReset} variant="outline">
          Cancel
         </Button>
         <Button
         ml="10px"
          w={200}
          h='54px'
         size={"custom"}
          type="submit"
         >
          Add user
         </Button>
        </ModalFooter>
       </ModalContent>
      </form>
     )}
    </Formik>
   </Modal>
  </Box>
 );
};

export default ModalAddUser;
