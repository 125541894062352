import { FC, useState } from "react";
import {
 Box,
 Button,
 Heading,
 Radio,
 Stack,
 RadioGroup,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { actions as myJourneyActions } from "state/myJourney/slice";

const LevelDetail: FC = () => {
 const dispatch = useAppDispatch();
 const router = useHistory();

 let { level } = useSelector(
  (state: RootState) => ({
   level: state.myJourney.levelStep1,
  }),
  shallowEqual
 );

 const onClickRadioButton = (valueLevel: string) => {
  dispatch(myJourneyActions.setJourneyLevel(valueLevel));
 };

 return (
  <>
   <Heading as="h2" size="lg" pt="1rem" pb="1rem">
    Which level do you want to be at?
   </Heading>
   <RadioGroup
    onChange={(valueLevel) => myJourneyActions.setJourneyLevel(valueLevel)}
    value={level}
   >
    <Box border="1px solid #D7DBE2" borderRadius="40px" w="20%" padding="1rem">
     <Radio name="doescost" id="doescost" value="Does Cost" onChange={() => onClickRadioButton("Does Cost")}>
      Does cost
     </Radio>
    </Box>
    <Box
     border="1px solid #D7DBE2"
     borderRadius="40px"
     w="20%"
     padding="1rem"
     mt="1rem"
    >
     <Radio
      value="Base Level"
      id="baselevel"
      name="baselevel"
      onChange={() => onClickRadioButton("Base Level")}
     >
      Base level
     </Radio>
    </Box>
    <Box
     border="1px solid #D7DBE2"
     borderRadius="40px"
     w="20%"
     padding="1rem"
     mt="1rem"
    >
     <Radio id="level1" name="level1" value="Level 1" onChange={() => onClickRadioButton("Level 1")}>
      Level 1
     </Radio>
    </Box>
    <Box
     border="1px solid #D7DBE2"
     borderRadius="40px"
     w="20%"
     padding="1rem"
     mt="1rem"
    >
     <Radio id="level2" name="level2" value="Level 2" onChange={() => onClickRadioButton("Level 2")}>
      Level 2
     </Radio>
    </Box>
    <Box
     border="1px solid #D7DBE2"
     borderRadius="40px"
     w="20%"
     padding="1rem"
     mt="1rem"
    >
     <Radio id="level3" name="level3" value="Level 3" onChange={() => onClickRadioButton("Level 3")}>
      Level 3
     </Radio>
    </Box>
    <Box
     border="1px solid #D7DBE2"
     borderRadius="40px"
     w="20%"
     padding="1rem"
     mt="1rem"
    >
     <Radio id="level4" name="level4" value="Level 4" onChange={() => onClickRadioButton("Level 4")}>
      Level 4
     </Radio>
    </Box>
    <Box pt="2rem" textAlign="right" w="100%">
     <Stack
      spacing={4}
      direction="row"
      align="center"
      float="right"
      bottom="1rem"
      right="1rem"
     >
      <Button
       variant={"outline"}
       onClick={() => router.goBack()}
      >
       Back
      </Button>
      <Button
       onClick={() =>
        router.push("/journeys/create?page=target-discovery-step-2")
       }
      >
       Next
      </Button>
     </Stack>
    </Box>
   </RadioGroup>
  </>
 );
};

export default LevelDetail;
