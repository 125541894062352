import { RootState } from "state/store";
import { ReactElement, useEffect } from "react";
import { useAppDispatch } from "hooks";
import { TextLoading } from "components/Loading";
import { useSelector, shallowEqual } from "react-redux";
import {
 Box,
 Button,
 Heading,
 Radio,
 Stack,
 RadioGroup,
 Flex,
 Text,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import OptipediaToast from "utils/notification-util";
import { actions as simulateAction } from "state/simulation/slice";
import { isEmpty } from "lodash";
const JourneyCompare = (): ReactElement => {
 const dispatch = useAppDispatch();
 const router = useHistory();

 let { loading, journey, journeyId } = useSelector(
  (state: RootState) => ({
   loading: state.simulationAggregate.loading,
   journey: state.simulationAggregate.journey,
   journeyId: state.simulationAggregate.journeyId,
  }),
  shallowEqual
 );
 useEffect(() => {
  window.scrollTo(0, 0);
  dispatch(simulateAction.getJourney());
 }, []);

 const onClickRadioButton = (journeyID: string) => {
  dispatch(simulateAction.setJourneyId(journeyID));
 };

 const handleBack = () => {
  dispatch(simulateAction.clear());
  router.goBack();
 };

 const handleNextStep = () => {
  if (!isEmpty(journey)) {
   if (!isEmpty(journeyId)) {
    dispatch(simulateAction.getJourneytorCompare({ id: journeyId }));
    router.push("/simulations/comparasion/create?page=select-business-case");
   } else {
    OptipediaToast.Error(
     "Please select 1 journey before proceeding with the next step."
    );
   }
  } else {
   OptipediaToast.Error(
    "Please maintain at least 1 journey in the tenant and 1 valid pricing in it before proceeding with the simulation."
   );
  }
 };

 return (
  <Box w="100%" mb="6rem">
   {!loading && isEmpty(journey) ? (
    <Box
     border="1px solid #D7DBE2"
     borderRadius="40px"
     p="2rem"
     mt="1rem"
     textAlign="center"
     fontWeight="700"
     fontSize="18px"
    >
     “There is no journey available for the comparasion.“
    </Box>
   ) : (
    <Heading as="h3" fontSize="22px" pt="1rem" pb="1rem">
     Which journey do you want to perform the business case comparison on?
    </Heading>
   )}
   <RadioGroup
    onChange={(journeyID) => simulateAction.setJourneyId(journeyID)}
    value={journeyId}
   >
    {!loading ? (
     journey.length !== undefined &&
     journey.map(
      (
       item: { id: string; journeyCode: string; journeyName: string },
       index: number
      ) => (
       <Stack spacing={4} key={index} direction="row" pt="12px" align="center">
        <Box
         border="1px solid #D7DBE2"
         borderRadius="40px"
         minW="20%"
         padding="1rem"
         fontWeight="600"
        >
         <Radio value={item.id} onChange={() => onClickRadioButton(item.id)}>
          {item.journeyName} / {item.journeyCode}
         </Radio>
        </Box>
       </Stack>
      )
     )
    ) : (
     <TextLoading />
    )}
    <Box
     position="fixed"
     bottom="0"
     backgroundColor="#ffffff"
     height="5.5rem"
     w="100%"
    >
     <Flex float="right" mr="20rem">
      <Box mt={4}>
       <Flex alignItems="center" gap="2">
        <>
         <Button
          bg="#C3C7CD"
          w="202px"
          h="59"
          borderRadius="50"
          fontSize="18"
          color="white"
          _hover={{ bgColor: "#bcbfc4" }}
          onClick={handleBack}
         >
          Back
         </Button>
         <Button
          bg="#0072CF"
          w="202px"
          h="59"
          borderRadius="50"
          fontSize="18"
          color="white"
          _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
          onClick={handleNextStep}
         >
          Next
         </Button>
        </>
       </Flex>
      </Box>
     </Flex>
    </Box>
   </RadioGroup>
  </Box>
 );
};

export default JourneyCompare;
