import { createSlice } from '@reduxjs/toolkit'
import { getList, deleteJourney } from 'state/productConfigurations/thunk'

interface InitialState {
  result: any
  loading: boolean
  successDelete: boolean
  searchQuery: string
  error: unknown
}

const initialState: InitialState = {
  result: {
    items: []
  },
  successDelete: false,
  searchQuery: '',
  loading: false,
  error: null
}

const productConfigurationsSlice = createSlice({
  name: 'productConfigurations',
  initialState,
  reducers: {
    clear: () => initialState,
    setSearchQuery: (state, action) => {
      const { value } = action.payload
      state.searchQuery = value
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(getList.fulfilled, (state, action) => {
      state.result = action.payload
      state.loading = false
    })

    builder.addCase(getList.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    builder.addCase(deleteJourney.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(deleteJourney.fulfilled, (state, action) => {
      state.successDelete = action.payload
      state.loading = false
    })

    builder.addCase(deleteJourney.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...productConfigurationsSlice.actions,
  getList,
  deleteJourney,
}

export const reducer = productConfigurationsSlice.reducer
