import { ReactElement } from "react";
import { Box, Flex, Text, Heading, Spacer } from "@chakra-ui/react";
import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import Swal from "sweetalert2";
import OptipediaToast from "utils/notification-util";

const CurrencyDetails = (): ReactElement => {
 const { currencyDataToEdit } = useSelector(
  (state: RootState) => ({
   currencyDataToEdit: state.multiCurrency.currencyDataToEdit,
  }),
  shallowEqual
 );
 const dispatch = useAppDispatch();
 const fetchList = (payload: any) => {
  dispatch(multiCurrencyAction.listMultiCurrency(payload));
 };
 return (
  <Box>
   <Heading fontSize={18} pb={5}>
    View Currency
   </Heading>
   <Box
    border="1px solid rgba(26, 26, 26, 0.16)"
    borderRadius="5px"
    p={10}
    mt="1"
    w={"40%"}
   >
    <Box py="5px">
     <Flex>
      <Text color={"gray.500"}>Currency Code</Text>
      <Spacer />
      <EditIcon
       cursor={"pointer"}
       onClick={() => {
        dispatch(multiCurrencyAction.setCurrencyView(false));
        dispatch(multiCurrencyAction.setCurrencyUpdated(true));
       }}
       mr={5}
      />
      <DeleteIcon
       cursor={"pointer"}
       onClick={() =>
        Swal.fire({
         showCancelButton: true,
         html: "Are you sure you want to delete this Exchange Rate?",
         cancelButtonText: "No",
         confirmButtonText: "Yes",
        }).then(async (result) => {
         if (result.isConfirmed) {
          dispatch(
           multiCurrencyAction.setSearchQuery({
            value: "",
           })
          );
          await dispatch(
           multiCurrencyAction.deletes(currencyDataToEdit?.id)
          ).then((res: any) => {
           if (res.payload !== undefined) {
            OptipediaToast.Success(
             "The Currencies has been removed successfully."
            );
           }
          });
          await fetchList({
           maxResultCount: 10,
          });
          dispatch(multiCurrencyAction.setCurrencyDataToEdit({}));
          dispatch(multiCurrencyAction.resetPage(false));
         }
        })
       }
      />
     </Flex>
     <Text py="3">{currencyDataToEdit.code}</Text>
    </Box>
    <Box py="3px">
     <Text color={"gray.500"}>Currency Description</Text>
     <Text py="3">{currencyDataToEdit.description}</Text>
    </Box>
   </Box>
  </Box>
 );
};

export default CurrencyDetails;
