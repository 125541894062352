import { RootState } from "state/store";
import { FC, useEffect, useState, useRef } from "react";
import {
 Box,
 Button,
 Stack,
 Switch,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 Breadcrumb,
 BreadcrumbItem,
 BreadcrumbLink,
 IconButton,
 Tooltip,
 useDisclosure,
} from "@chakra-ui/react";
import { FiZoomIn } from "react-icons/fi";
import { useAppDispatch } from "hooks";
import { useHistory } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { isEmpty, orderBy } from "lodash";

export interface ModalProps {
 data: {};
 chartType: string;
}

const ChartModal = (data: any) => {
 const { isOpen, onOpen, onClose } = useDisclosure();
 const [treemapSummary, setTeemapSummary] = useState("summary");
 const [barSummary, setBarSummary] = useState("summary");
 const [chartModel, setChartModel] = useState(false);

 const [level, setLevel] = useState(false);

 const changeBar = (value: any) => {
  setBarSummary(value);
 };
 const changeTreemap = (value: any) => {
  setTeemapSummary(value);
 };
 const newData = data.data;

 const treemapDataSummary = newData.summaryChart;
 const treemapDataLevel = newData.chartLevel1;
 const treemapDataLevel2 = newData.chartLevel2;
 const tmpTreemap =
  treemapSummary == "summary"
   ? treemapDataSummary?.map((x: any) => ({
      x: x.identityName,
      y: !chartModel ? x.currentContribution * 100 : x.whatIfContribution * 100,
      z: x.whatIfTotalSpend,
     }))
   : treemapSummary === "level1"
   ? treemapDataLevel?.map((x: any) => ({
      x: x.identityName,
      y: !chartModel ? x.currentContribution * 100 : x.whatIfContribution * 100,
      z: x.currentTotalSpend,
     }))
   : treemapDataLevel2?.map((x: any) => ({
      x: x.identityName,
      y: !chartModel ? x.currentContribution * 100 : x.whatIfContribution * 100,
      z: x.currentTotalSpend,
     }));

 const series = [
  {
   name: "Name",
   data: orderBy(tmpTreemap, "y", "desc"),
  },
 ];

 const options = {
  chart: {
   layoutAlgorithm: "squarified",
   allowDrillToNode: true,
   toolbar: {
    show: false,
   },
  },
  colors: ["#0061BD"],
  legend: {
   show: true,
   showForSingleSeries: false,
   showForNullSeries: true,
   showForZeroSeries: true,
   fontSize: "14px",
   fontFamily: "Helvetica, Arial",
   fontWeight: 400,
   inverseOrder: false,
  },
  tooltip: {
   custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
    var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

    return (
     '<ul style="margin: 1rem; list-style-type:none;">' +
     `<li><b>- ${data.x}: </b>` +
     data.y.toLocaleString("en-US") +
     "%" +
     "</li>" +
     "<li><b>- Total Spend: </b>" +
     "$" +
     data.z.toLocaleString("en-US") +
     "</li>" +
     "</ul>"
    );
   },
   style: {
    fontSize: "12px",
    padding: "2rem",
   },
  },
  plotOptions: {
   treemap: {
    enableShades: true,
   },
  },
  dataLabels: {
   enabled: true,
   style: {
    fontSize: "16px",
   },
   formatter: function (text: any, op: any) {
    const data: any =
     op.value !== 0 ? [text, op.value.toLocaleString("en-US") + " %"] : "";
    return data;
   },
  },
 };

 const barDataSummary = newData.summaryChart;
 const barDataLevel = newData.chartLevel1;
 const barDataLevel2 = newData.chartLevel2;

 const tmpBarCurrent =
  barSummary == "summary"
   ? barDataSummary?.map((x: any) => ({
      x: x.identityName,
      y: x.currentCost,
     }))
   : barSummary == "level1"
   ? barDataLevel?.map((x: any) => ({
      x: x.identityName,
      y: x.currentCost,
     }))
   : barDataLevel2?.map((x: any) => ({
      x: x.identityName,
      y: x.currentCost,
     }));
 const tmpBarWhatif =
  barSummary == "summary"
   ? barDataSummary?.map((x: any) => ({
      x: x.identityName,
      y: x.whatIfCost,
     }))
   : barSummary === "level1"
   ? barDataLevel?.map((x: any) => ({
      x: x.identityName,
      y: x.whatIfCost,
     }))
   : barDataLevel2?.map((x: any) => ({
      x: x.identityName,
      y: x.whatIfCost,
     }));

 const seriesBar = [
  {
   name: "Current",
   data: tmpBarCurrent,
  },
  {
   name: "What-If",
   data: tmpBarWhatif,
  },
 ];
 const barOptions = {
  colors: ["#C3C7CD", "#0061BD"],
  chart: {
   toolbar: {
    show: false,
   },
   parentHeightOffset: 0,
  },
  plotOptions: {
   bar: {
    horizontal: false,
    barWidth: "50%",
    dataLabels: {
     position: "bottom",
    },
   },
  },
  dataLabels: {
   enabled: false,
  },
  xaxis: {
   categories: tmpBarCurrent?.map((x: any) => x.x),
  },
  yaxis: {
   labels: {
    formatter: function (val: any) {
     return val.toLocaleString("en-US");
    },
   },
  },
  legend: {
   show: true,
   showForSingleSeries: false,
   showForNullSeries: true,
   showForZeroSeries: true,
   fontSize: "14px",
   fontFamily: "Helvetica, Arial",
   fontWeight: 400,
   inverseOrder: false,
  },
 };

 return (
  <Box>
   <Tooltip label="Open chart" fontSize="14px" bgColor="white" color="#0061BD">
    <IconButton
     onClick={onOpen}
     float="right"
     mr="0.7rem"
     color="#FFFFFF"
     bgColor="#C3C7CD"
     borderRadius="50%"
     _hover={{ bg: "#0061BD", color: "white" }}
     fontSize="22px"
     aria-label="Search database"
     icon={<FiZoomIn />}
    />
   </Tooltip>
   <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent minW="90%" borderRadius="25px">
     <ModalCloseButton />
     {/* <ModalHeader>Summary</ModalHeader> */}
     <ModalBody>
      {data.chartType === "treemap" ? (
       <Box border="1px solid #D7DBE2" m="1.5rem" borderRadius="40px">
        <Box pl="2rem" pt="2rem">
         <Breadcrumb separator="">
          <BreadcrumbItem>
           <BreadcrumbLink
            onClick={() => changeTreemap("summary")}
            color={treemapSummary == "summary" ? "#0061BD" : "#C3C7CD"}
           >
            Summary
           </BreadcrumbLink>
          </BreadcrumbItem>

          {!isEmpty(newData.chartLevel1) && (
           <BreadcrumbItem>
            <BreadcrumbLink
             onClick={() => changeTreemap("level1")}
             color={treemapSummary == "level1" ? "#0061BD" : "#C3C7CD"}
            >
             Level 1
            </BreadcrumbLink>
           </BreadcrumbItem>
          )}
          {!isEmpty(newData.chartLevel1) && (
           <BreadcrumbItem>
            <BreadcrumbLink
             onClick={() => changeTreemap("level2")}
             color={treemapSummary == "level2" ? "#0061BD" : "#C3C7CD"}
            >
             Level 2
            </BreadcrumbLink>
           </BreadcrumbItem>
          )}
         </Breadcrumb>

         <Box alignItems="center" fontWeight="600" mt="1rem">
          {chartModel ? "What-If" : "Current"}{" "}
          <Switch
           defaultChecked={chartModel ? true : false}
           pr="15px"
           onChange={() => setChartModel(!chartModel)}
          />
         </Box>
        </Box>
        <Box ml="1rem" p="1rem">
         <ReactApexChart
          options={options}
          series={series}
          type="treemap"
          height={"320%"}
         />
        </Box>
       </Box>
      ) : (
       <Box border="1px solid #D7DBE2" m="2rem" borderRadius="40px">
        <Box m="2rem">
         <Breadcrumb separator="">
          <BreadcrumbItem>
           <BreadcrumbLink
            onClick={() => changeBar("summary")}
            color={barSummary == "summary" ? "#0061BD" : "#C3C7CD"}
           >
            Summary
           </BreadcrumbLink>
          </BreadcrumbItem>

          {!isEmpty(newData.chartLevel1) && (
           <BreadcrumbItem>
            <BreadcrumbLink
             onClick={() => changeBar("level1")}
             color={barSummary == "level1" ? "#0061BD" : "#C3C7CD"}
            >
             Level 1
            </BreadcrumbLink>
           </BreadcrumbItem>
          )}
          {!isEmpty(newData.chartLevel1) && (
           <BreadcrumbItem>
            <BreadcrumbLink
             onClick={() => changeBar("level2")}
             color={barSummary == "level2" ? "#0061BD" : "#C3C7CD"}
            >
             Level 2
            </BreadcrumbLink>
           </BreadcrumbItem>
          )}
         </Breadcrumb>
        </Box>
        <ReactApexChart
         options={barOptions}
         series={seriesBar}
         type="bar"
         height={"215%"}
        />
       </Box>
      )}
     </ModalBody>
    </ModalContent>
   </Modal>
  </Box>
 );
};

export default ChartModal;
