import {
 Box,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalCloseButton,
 ModalBody,
 Button,
 Tooltip,
 useDisclosure,
} from "@chakra-ui/react";
import { FiZoomIn } from "react-icons/fi";

const Magnificiant = (data: any) => {
 const { isOpen, onOpen, onClose } = useDisclosure();
 return (
  <Box>
   {/* <Tooltip label="Open chart" fontSize="14px" bgColor="white" color="#0061BD"> */}
    <Button size="sm" variant={'link'} disabled={data.isActive}
     onClick={onOpen}
     _focus={{border: 'none'}}
     >Open Chart</Button>
   {/* </Tooltip> */}
   <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent minW="95%" minH={500} borderRadius="5px">
     <ModalCloseButton />
     <ModalBody>{data.data}</ModalBody>
    </ModalContent>
   </Modal>
  </Box>
 );
};

export default Magnificiant;
