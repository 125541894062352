import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpService from "services/http-service";

export const getList = createAsyncThunk(
  'productConfigurations/getList',
  async (params: any) => {
    const response: any = await HttpService.get('ProductConfigurations', { params });
    return response.result;
  }
)

export const deleteJourney = createAsyncThunk(
  'productConfigurations/delete',
  async ({ id }: any) => {
    const response: any = await HttpService.delete(`/ProductConfigurations/${id}`);
    return response.success
  }
)
