import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpService from "services/http-service";

export const getMasterData = createAsyncThunk(
  'costAnalysis/predefinedData',
  async (identityName: string) => {
    const response: any = await HttpService.get(`/MasterData?IdentityName=${identityName}`);
    return response.result
  }
)
