import { FC } from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, Heading } from "@chakra-ui/layout";
import { Button, Text } from "@chakra-ui/react";
import { Formik } from "formik";
import { useAppDispatch } from "hooks";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import TextInput from "components/TextInput";
import * as Yup from "yup";
interface Values {
 code: string;
 description: string;
}

const PredefinedThirdPartyCreated: FC = () => {
 const router = useHistory();
 const dispatch = useAppDispatch();
 const validation = Yup.object().shape({
    code : Yup.string().required("This information are required"),
    description: Yup.string().required("This information are required"),
 })
 return (
  <Box mt="1rem">
   <Formik
    enableReinitialize
    initialValues={{ code: "", description: "" }}
    validationSchema={validation}
    onSubmit={async (values: Values) => {
     await dispatch(thirdPartySettingAction.addThirdParty(values));
     router.push("/settings/third-party");
    }}
   >
    {({ handleSubmit }) => (
     <form onSubmit={handleSubmit}>
      <Box py="1rem" maxW={300}>
       <Text fontWeight={"500"}>
        Third-Party Code*
       </Text>
       <TextInput id="code" name="code" placeholder="e.g Code" />
      </Box>
      <Box py="1rem" maxW={300}>
       <Text fontWeight={"500"}>
        Third-Party Description*
       </Text>
       <TextInput
        id="description"
        name="description"
        placeholder="Description"
       />
      </Box>
      <Box
       position="fixed"
       bottom="0"
       backgroundColor="#ffffff"
       height="5.5rem"
       w="100%"
       zIndex={2}
      >
       <Flex float="right" mr="20rem">
        <Box mt={4}>
         <Flex alignItems="center" gap="2">
          <>
           <Button variant={"outline"} onClick={() => router.goBack()}>
            Cancel
           </Button>
           <Button type="submit" loadingText="Loading...">
            Save
           </Button>
          </>
         </Flex>
        </Box>
       </Flex>
      </Box>
     </form>
    )}
   </Formik>
  </Box>
 );
};

export default PredefinedThirdPartyCreated;
