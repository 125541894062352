import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks";
import Swal from "sweetalert2";
import { Formik, Form, FieldArray, useFormikContext } from "formik";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { Box, Stack, Text } from "@chakra-ui/layout";
import { FormControl, FormLabel, Grid, InputGroup } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import ToolbarBottom from "../ToolbarBottom";
import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import TextInput from "components/TextInput";
import Select from "components/Select";
import { isEmpty, orderBy } from "lodash";
import TextInputCustomNumber from "components/NewTextInputCustomNumber";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import { actions as masterDataAction } from "state/masterData/slice";
import { actions as costAnalysisAction } from "state/costAnalysis/slice";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
import { removeThousandSeparator } from "utils";
import * as Yup from "yup";

interface Item {
 code: string;
}

interface PredefinedDataResponse {
 payload?: {
  result: {
   items: Item[];
  };
 };
}

const GeneralData = () => {
 const dispatch = useAppDispatch();
 const [isChecked, setIsChecked] = useState<boolean>(false);
 const [checkedNull, setCheckedNull] = useState<boolean>(true);
 const [dataAdditional, setDataAdditional] = useState<any>([]);
 const [optionFormat, setOptionFormat] = useState<any>([]);
 const [optionThirdParty, setOptionThirdParty] = useState<any>([]);
 const [optionCurrency, setOptionCurrency] = useState<any>([]);
 const [optionUoM, setOptionUoM] = useState<any>([]);
 const [optionCountry, setOptionCountry] = useState<any>([]);
 const [optionBrand, setOptionBrand] = useState<any>([]);
 const [optionSupplier, setOptionSupplier] = useState<any>([]);
 const [optionFactory, setOptionFactory] = useState<any>([]);
 const location = useLocation();
 const [initialValues, setInitialValues] = useState<any>();
 const [keyCurrency, setKeyCurrency] = useState("");
 const [changeCurrency, setChangeCurrency] = useState(false);
 const pricingId = location.pathname.split("/")[3];

 const {
  costAnalysis,
  volumes,
  isEditing,
  detailPerVolumeId,
  dataToSendUpdates,
  dataTOSendDeletes,
  dataTOSend,
  isReplace,
  isCopy,
  isEnableThirdPartyStatus,
  isExist,
  thirdPartyGetCode,
  listCurrency,
 } = useSelector(
  (state: RootState) => ({
   costAnalysis: state.pricingCostSummary.costSummaryById,
   volumes: state.pricingCostSummary.costSummaryById.volumes,
   thirdPartyId: state.editPricingCostDrivers.thirdPartyId,
   isEditing: state.editPricingCostDrivers.isEditing,
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId.result,
   dataToSendUpdates: state.editPricingCostDrivers.dataToSend.updates,
   dataTOSendDeletes: state.editPricingCostDrivers.dataToSend.deletes,
   dataTOSendCustomFields: state.editPricingCostDrivers.dataToSend.customFields,
   dataTOSend: state.editPricingCostDrivers.dataToSend,
   statusAndVolume: state.editPricingCostDrivers.statusAndVolume,
   isReplace: state.copyAndReplaceCostDrivers.isReplace,
   isCopy: state.copyAndReplaceCostDrivers.isCopy,
   thirdParty: state.thirdPartySetting.thirdParty,
   initialCustomFields: state.editPricingCostDrivers.initialCustomFields,
   thirdPartyGetCode:
    state.editPricingCostDrivers?.responseSimulate?.result?.thirdParty?.code,
   isEnableThirdPartyStatus: state.thirdPartySetting.isEnableThirdPartyStatus,
   isExist: state.costAnalysis.isExist,
   isExistLoading: state.costAnalysis.isExistLoading,
   listCurrency: state.multiCurrency.lists,
  }),
  shallowEqual
 );

 const fetchListCurrency = async () => {
  const payload = {
   skipCount: 0,
   maxResultCount: 1000,
   keyword: keyCurrency,
  };
  await dispatch(multiCurrencyAction.listMultiCurrency(payload));
 };

 useEffect(() => {
  fetchListCurrency();
 }, []);

 useEffect(() => {
  fetchListCurrency();
 }, [keyCurrency]);

 useEffect(() => {
  const fetchThirdParty = async (value: number) => {
   let dataOption: any = [];
   dispatch(
    thirdPartySettingAction.getThirdPartyToShow({ skipCount: value })
   ).then((response: any) => {
    response.payload.items.map((item: any) => {
     const { code, description } = item;
     dataOption.push({
      label: description,
      value: code,
     });
    });
   });
   setOptionThirdParty(dataOption);
  };
  if (costAnalysis?.thirdParties?.length > 0) {
   fetchThirdParty(0);
  }
 }, [costAnalysis?.thirdParties]);

 useEffect(() => {
  const newListCurrencies = listCurrency?.items?.map((item: any) => {
   return {
    label: item.code,
    value: item.id,
    desc: item.description,
   };
  });
  setOptionCurrency(newListCurrencies);
 }, [listCurrency]);

 const fetchListFormat = async (value: any) => {
  await dispatch(masterDataAction.masterDataList(value)).then(
   async (predefinedDataResponse: any) => {
    const options = await predefinedDataResponse;

    if (options.payload) {
     if (options.payload.result.items.length > 0) {
      const newOptions: any = [];
      options.payload.result.items.map((item: any) => {
       newOptions.push({
        label: item.code,
        value: item.code,
       });
      });
      const sort = (x: any, y: any) => {
       return x.label.localeCompare(y.label);
      };
      if (
       options.payload.result.items[0]?.configuration?.identityCode === "Format"
      ) {
       setOptionFormat(newOptions.sort(sort));
      }
      if (
       options.payload.result.items[0]?.configuration?.identityCode ===
       "Currency"
      ) {
       //  setOptionCurrency(newOptions.sort(sort));
      }
      if (
       options.payload.result.items[0]?.configuration?.identityCode === "UoM"
      ) {
       setOptionUoM(newOptions.sort(sort));
      }
      if (
       options.payload.result.items[0]?.configuration?.identityCode ===
       "Country"
      ) {
       setOptionCountry(newOptions.sort(sort));
      }
      if (
       options.payload.result.items[0]?.configuration?.identityCode === "Brand"
      ) {
       setOptionBrand(newOptions.sort(sort));
      }
      if (
       options.payload.result.items[0]?.configuration?.identityCode ===
       "Supplier"
      ) {
       setOptionSupplier(newOptions.sort(sort));
      }
      if (
       options.payload.result.items[0]?.configuration?.identityCode ===
       "Factory"
      ) {
       setOptionFactory(newOptions.sort(sort));
      }
     }
    }
   }
  );
 };
 useEffect(() => {
  const productConfigList: any = [];
  if (!isEmpty(detailPerVolumeId)) {
   productConfigList.push(
    detailPerVolumeId.currency,
    // detailPerVolumeId.format,
    costAnalysis.uoM
   );
  }
  detailPerVolumeId &&
   detailPerVolumeId?.customFields &&
   detailPerVolumeId?.customFields.length &&
   detailPerVolumeId?.customFields.map((item: any) =>
    fetchListFormat({
     skipCount: 0,
     maxResultCount: 100,
     searchKey: "",
     configurationId: item.configuration?.masterDataConfiguration?.id,
     type: 0,
     thirdPartyId: "",
    })
   );

  productConfigList.map((item: any) =>
   fetchListFormat({
    skipCount: 0,
    maxResultCount: 100,
    searchKey: "",
    configurationId: item?.configuration?.id,
    type: 0,
    thirdPartyId: "",
   })
  );
 }, [isCopy, isEditing, isReplace]);

 //  useEffect(() => {
 //   const volume = costAnalysis?.volumes?.find(
 //    (element: any) => element?.volume === costAnalysis?.summary?.volume
 //   );

 //   if (volume) {
 //    const payload = {
 //     costAnalysisId: pricingId,
 //     perVolumeId: volume.id,
 //    };
 //    if (initialValues !== isEmpty()) {
 //     setInitialValues(initialValues);
 //    } else {
 //     dispatch(pricingCostDriverAction.getDetailCostDriversVolume(payload));
 //    }
 //   }
 //  }, [costAnalysis]);

 useEffect(() => {
  dispatch(thirdPartySettingAction.configurationStatus());
 }, [isEnableThirdPartyStatus]);

 useEffect(() => {
  if (initialValues && initialValues.customFields) {
   dispatch(
    pricingCostDriverAction.setInitialCustomFields(initialValues.customFields)
   );
  }
 }, [initialValues]);

 useEffect(() => {
  if (!detailPerVolumeId) return;
  if (Object.keys(detailPerVolumeId).length == 0) return;
 }, [detailPerVolumeId]);

 useEffect(() => {
  if (!costAnalysis || !detailPerVolumeId) return;
  if (
   Object.keys(costAnalysis).length == 0 &&
   Object.keys(detailPerVolumeId).length == 0
  )
   return;

  const runFunction = async () => {
   if (detailPerVolumeId.customFields.length > 0) {
    var customFields: any[] = await Promise.all(
     detailPerVolumeId.customFields.map(
      async (customField: any): Promise<any> => {
       if (!customField.costDriver) {
        var customFieldUpdated = dataTOSend.customFields.find(
         (i: any) => i.customFieldId === customField.id
        );
        const dataId = {
         configurationId:
          customField?.configuration?.masterDataConfiguration?.id,
         skipCount: 0,
         maxResultCount: 100,
         searchKey: "",
         type: 0,
         thirdPartyId: customField?.masterData?.thirdParty?.id || "",
        };

        const arrayOptions: any = [];
        await dispatch(masterDataAction.masterDataList(dataId)).then(
         async (predefinedDataResponse: any) => {
          const options = await predefinedDataResponse;
          if (options.payload) {
           if (options.payload.result.items.length > 0) {
            options.payload.result.items.map((item: any) => {
             arrayOptions.push({
              label: item.code,
              value: item.code,
             });
            });
           }
          }
         }
        );

        const sort = (x: any, y: any) => {
         return x.label.localeCompare(y.label);
        };
        const data = {
         customFieldId: customField.id,
         customFieldConfigurationId: customField.configuration.id,
         name: customFieldUpdated
          ? customFieldUpdated.value
          : customField.value,
         displayName: customField.configuration.displayName,
         type: customField.configuration.type,
         sequence: customField.configuration.sequence,
         masterDataConfigurationName:
          customField?.masterData?.configuration?.identityName,
         options: arrayOptions.sort(sort),
        };
        return data;
        // customFields.push(data);
       }
      }
     )
    );
    if (!isEmpty(detailPerVolumeId)) {
     setDataAdditional([
      {
       label: "Valid Date From",
       value:
        detailPerVolumeId.validDateFrom !== null
         ? detailPerVolumeId.validDateFrom
         : "-",
      },
      {
       label: "Valid Date To",
       value:
        detailPerVolumeId.validDateTo !== null
         ? detailPerVolumeId.validDateTo
         : "-",
      },
      {
       label: "Approved Date",
       value:
        detailPerVolumeId.approvedDate !== null
         ? detailPerVolumeId.approvedDate
         : "-",
      },
      {
       label: "Last Modified Date",
       value:
        detailPerVolumeId.modifiedDate !== null
         ? detailPerVolumeId.modifiedDate
         : "-",
      },
      {
       label: "Approved By",
       value:
        detailPerVolumeId.approvedBy !== null
         ? detailPerVolumeId.approvedBy
         : "-",
      },
      {
       label: "Last Modified By",
       value:
        detailPerVolumeId.updatedBy !== null
         ? detailPerVolumeId.updatedBy
         : "-",
      },
     ]);
    }
    const volume = detailPerVolumeId;
    let newCostAnalysis = {
     costAnalysisPerVolumeId: volume.id,
     name: isEditing
      ? dataTOSend.name || costAnalysis.name
      : dataTOSend.name || "",
     volume: dataTOSend.volume || detailPerVolumeId.volume,
     thirdPartyCode: thirdPartyGetCode,
     description: dataTOSend.description || costAnalysis.description,
     uoMCode: dataTOSend.uoMCode || costAnalysis?.uoM?.code,
     currencyCode: dataTOSend.currencyCode || costAnalysis?.currency?.code,
     currencyId: dataTOSend.currencyId || costAnalysis?.currency?.id,
     adds: dataTOSend.adds,
     updates: dataToSendUpdates,
     deletes: dataTOSendDeletes,
     customFields: customFields,
    };
    setInitialValues(newCostAnalysis);
   }
  };
  runFunction();
 }, [costAnalysis, detailPerVolumeId, isCopy, isEditing, isReplace]);
 const handleChangeForm = async (value: any) => {
  setInitialValues(value);

  const { description, name, format, currency, uoM, volumes } = costAnalysis;
  const { customFields } = detailPerVolumeId;
  let {
   description: descValue,
   name: nameValue,
   uoMCode,
   currencyCode,
   unitCost,
   currencyId,
   customFields: customFieldsValue,
   thirdPartyCode,
   volume,
  } = value;
  // value.currencyId = currency.id;

  const newCustomFieldsValue: any = [];

  customFieldsValue.map((item: any) => {
   newCustomFieldsValue.push({
    name: item.displayName,
    value: item.name,
   });
  });

  const results = newCustomFieldsValue.filter(
   ({ value: id1 }: any) =>
    !customFields.some(({ value: id2 }: any) => id2 === id1)
  );

  if (isReplace || isCopy) {
   const getVolume = volumes[0].volume;
   const checkName = nameValue !== "";
   const checkVolume = parseFloat(volume) !== getVolume;
   const checkThirdParty = thirdPartyCode !== "";

   if (costAnalysis?.configuration?.isEnableThirdParty) {
    if (checkName && checkThirdParty) {
     dispatch(pricingCostDriverAction.setDisable(false));
    } else {
     dispatch(pricingCostDriverAction.setDisable(true));
    }
   } else {
    if (checkName) {
     dispatch(pricingCostDriverAction.setDisable(false));
    } else {
     dispatch(pricingCostDriverAction.setDisable(true));
    }
   }
  } else {
   const checkDesc = description !== descValue;
   const checkName = name !== nameValue;
   const checkCurrency = currency?.code !== currencyCode;
   const checkUoM = uoM?.code !== uoMCode;
   if (
    checkDesc ||
    checkName ||
    checkCurrency ||
    checkUoM ||
    results.length > 0
   ) {
    dispatch(pricingCostDriverAction.setDisable(false));
   } else {
    dispatch(pricingCostDriverAction.setDisable(true));
   }
  }

  if (detailPerVolumeId?.costAnalysis?.level === 0) {
   value.unitCost = detailPerVolumeId.unitCost;
   value.level = 0;
  }
  if (value.volume === "") {
   value.volume = 0;
  }

  await dispatch(
   pricingCostDriverAction.setDirectSetTemporaryDataCostDriver(value)
  );
  if (changeCurrency) {
   const sentData = {
    ...dataTOSend,
    currencyId: value.currencyId,
    currencyCode: value.currencyCode,
   };
   await dispatch(pricingCostDriverAction.setValueIsIncludeCalculation(true));
   await dispatch(pricingCostDriverAction.simulate(sentData)).then(
    (res: any) => {
     setChangeCurrency(false);
     dispatch(pricingCostDriverAction.setValueIsIncludeCalculation(false));
    }
   );
  }
 };

 const validation = Yup.object().shape({
  name: Yup.string(),
  thirdPartyCode: detailPerVolumeId?.thirdParty ? Yup.string() : Yup.string(),
  volume: Yup.number(),
 });

 useEffect(() => {
  if (isChecked && isExist !== null && Object.keys(isExist).length !== 0) {
   check();
  }

  if (isExist === null) {
   setCheckedNull(false);
  } else {
   setCheckedNull(true);
  }
  if (isExist === null) {
   const dataCustomFields: any = [];
   detailPerVolumeId?.customFields?.map((item: any) => {
    setInitialValues({
     ...initialValues,
     description:
      dataTOSend.description || detailPerVolumeId?.costAnalysis.description,
     currencyCode: dataTOSend?.currencyCode || costAnalysis?.currency?.code,
     currencyId: dataTOSend.currencyId || costAnalysis?.currency?.id,
     uoMCode: dataTOSend.uoMCode || costAnalysis.uoM.code,
     volume: dataTOSend.volume || detailPerVolumeId.volume,
     thirdPartyCode: dataTOSend.thirdPartyCode || "",
     name: dataTOSend.name || "",
     unitCost: detailPerVolumeId.unitCost,
    });
   });
  }
 }, [isExist, isChecked]);

 const check = () => {
  Swal.fire({
   showCancelButton: true,
   allowOutsideClick: false,
   html: `The ${costAnalysis?.configuration?.productLabelName} ${initialValues?.name} already exists, would you like to use the SKU Characteristic from the existing SKU?`,
   confirmButtonText: "Yes",
   cancelButtonText: "No",
  }).then(async (result: any) => {
   if (result.isConfirmed) {
    setInitialValues({
     ...initialValues,
     name: isExist.name,
     description: isExist.description,
     currencyCode: isExist.currency.code,
     currencyId: dataTOSend.currencyId || costAnalysis?.currency?.id,
     uoMCode: isExist.uoM.code,
     unitCost: detailPerVolumeId.unitCost,
    });
   } else {
    setInitialValues({ ...initialValues, name: "" });
   }
  });
 };

 return (
  <Box>
   {(isEditing || isReplace || isCopy) && (
    <Formik
     enableReinitialize
     initialValues={initialValues}
     validationSchema={validation}
     validateOnBlur
     onSubmit={handleChangeForm}
    >
     {({ setFieldValue, values, resetForm, setFieldError, handleBlur }) => {
      return (
       <Form>
        <FormObserver />
        <Box border={"solid 1px #D7DBE2"} borderRadius="5px">
         <Grid
          // w="80%"
          templateColumns="repeat(2, 1fr)"
          gap={4}
          pl="1.5rem"
          pr="1.5rem"
          my="1.5rem"
         >
          <TextInput
           name={`name`}
           id="name"
           label={
            !isEditing ? (
             <Text>
              {costAnalysis?.configuration?.productLabelName}{" "}
              <span style={{ color: "red" }}>*</span>
             </Text>
            ) : (
             costAnalysis?.configuration?.productLabelName
            )
           }
           onBlur={async (val) => {
            handleBlur(val);
            const value: string = val.currentTarget.value;
            if (value) {
             setIsChecked(true);
             await dispatch(costAnalysisAction.checkIsExistingProductId(value));
            }
           }}
           placeholder="Please Input Product ID"
           opacity="1!important"
           isDisabled={isEditing}
           onChange={(val) => {
            setFieldValue(`name`, val.currentTarget.value);
            if (values.name !== val.currentTarget.value) {
             dispatch(pricingCostDriverAction.setDisable(true));
            }
           }}
           onKeyPress={(event) => {
            if (!/([a-zA-Z0-9_]+)$/.test(event.key)) {
             event.preventDefault();
             setFieldError("name", "The field should have alphanumeric only");
            }
           }}
          />
          {!isEditing && costAnalysis?.thirdParties?.length > 0 && (
           <Select
            name="thirdPartyCode"
            id="thirdPartyCode"
            label={
             <Text>
              Third-Party <span style={{ color: "red" }}>*</span>
             </Text>
            }
            data={optionThirdParty}
            opacity="1!important"
            isDisabled={true}
            onBlur={handleBlur}
            onChange={(val) => {
             setFieldValue(`thirdPartyCode`, val.currentTarget.value);
             if (values.thirdPartyCode !== val.currentTarget.value) {
              dispatch(pricingCostDriverAction.setDisable(true)); //dah
             }
            }}
           />
          )}
          {!isEditing && (
           <TextInput
            name="volume"
            id="volume"
            type="number"
            label={
             <Text>
              Volume <span style={{ color: "red" }}>*</span>
             </Text>
            }
            placeholder="Volume"
            opacity="1!important"
            isDisabled={isEditing}
            onChange={(value) => {
             const val = parseInt(value.currentTarget.value);
             setFieldValue(`volume`, val);
             if (values.volume !== value.currentTarget.value) {
              dispatch(pricingCostDriverAction.setDisable(true)); // dah
             }
            }}
            onBlur={handleBlur}
           />
          )}
          {(isEditing || isCopy || isReplace) && (
           <TextInput
            name="description"
            id="description"
            label={costAnalysis?.configuration?.productLabelDescription}
            placeholder=""
            opacity="1!important"
            isDisabled={!isEditing && checkedNull}
            onChange={(val) => {
             setFieldValue(`description`, val.currentTarget.value);
             if (values.description !== val.currentTarget.value) {
              dispatch(pricingCostDriverAction.setDisable(true)); //dah
             }
            }}
           />
          )}
          {(isEditing || isCopy || isReplace) && costAnalysis?.currency && (
           <Box>
            <SelectWithAutoComplete
             //  width={140}
             noBorder={false}
             name="currencyId"
             defaultValue={values?.currencyId}
             id="currencyId"
             options={optionCurrency}
             label={costAnalysis?.configuration.currencyLabelName}
             onInputChange={(e: any) => {
              setKeyCurrency(e);
             }}
             onChange={(val: any) => {
              setFieldValue(`currencyCode`, val.label);
              setFieldValue(`currencyId`, val.value);
              setChangeCurrency(true);
              if (values.currencyCode !== val.label) {
               dispatch(pricingCostDriverAction.setDisable(true));
              }
             }}
            />
           </Box>
          )}
          {(isEditing || isCopy || isReplace) && costAnalysis?.uoM && (
           <Select
            name="uoMCode"
            id="uoMCode"
            label={
             costAnalysis?.configuration.uoMLabelName === "UoM"
              ? costAnalysis?.uoM?.configuration?.identityName
              : costAnalysis?.configuration.uoMLabelName
            }
            data={optionUoM}
            opacity="1!important"
            isDisabled={!isEditing && checkedNull}
            onChange={(val) => {
             setFieldValue(`uoMCode`, val.currentTarget.value);
             if (values.uoMCode !== val.currentTarget.value) {
              dispatch(pricingCostDriverAction.setDisable(true)); //dah
             }
            }}
           />
          )}

          {(isEditing || isCopy || isReplace) && (
           <FieldArray
            name="customFields"
            render={({ push, replace, remove }) => {
             return (
              <>
               {initialValues &&
                Array.isArray(initialValues.customFields) &&
                initialValues.customFields.length > 0 &&
                initialValues.customFields
                 .sort((prev: any, next: any) =>
                  prev.sequence < next.sequence ? 0 : 1
                 )
                 .map((customField: any, index: number) => {
                  const { name, displayName, type, options } = customField;

                  if ([30, 50].includes(type)) {
                   return (
                    <Select
                     name={`customFields[${index}].name`}
                     key={index}
                     id={`customFields[${index}].name`}
                     label={displayName}
                     placeholder=""
                     opacity="1!important"
                     isDisabled={!isEditing && checkedNull}
                     data={options}
                     onChange={(val) => {
                      setFieldValue(
                       `customFields[${index}].name`,
                       val.currentTarget.value
                      );
                      if (
                       values.customFields[index].name !==
                       val.currentTarget.value
                      ) {
                       dispatch(pricingCostDriverAction.setDisable(true));
                      }
                      dispatch(
                       pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(
                        dataTOSend
                       )
                      );
                     }}
                    />
                   );
                  }
                  if (type === 10) {
                   return (
                    <TextInput
                     name={`customFields[${index}].name`}
                     id={displayName}
                     label={displayName}
                     placeholder=""
                     isDisabled={!isEditing && checkedNull}
                     defaultValue={name ? name : "-"}
                     opacity="1!important"
                     onChange={(val) => {
                      setFieldValue(
                       `customFields[${index}].name`,
                       val.currentTarget.value
                      );
                      if (
                       values.customFields[index].name !==
                       val.currentTarget.value
                      ) {
                       dispatch(pricingCostDriverAction.setDisable(true)); //dah
                      }
                      if (values.customFields[index].type) {
                       dispatch(
                        pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(
                         dataTOSend
                        )
                       );
                      }
                     }}
                    />
                   );
                  } else {
                   return (
                    <FormControl>
                     <Stack spacing={4} direction={"row"}>
                      <FormLabel
                       alignSelf="center"
                       margin="0"
                       minW="40%"
                       fontWeight="bold"
                       textTransform="capitalize"
                       opacity="1!important"
                      >
                       {displayName}
                      </FormLabel>
                      <InputGroup>
                       <TextInputCustomNumber
                        name={`customFields[${index}].name`}
                        id={`customFields[${index}].name`}
                        placeholder=""
                        isDisabled={type === 40}
                        defaultValue={name ? name : "-"}
                        opacity="1!important"
                        onChange={(val: any) => {
                         setFieldValue(
                          `customFields[${index}].name`,
                          removeThousandSeparator(val)
                         );
                         if (
                          values.customFields[index].name !==
                          val.currentTarget.value
                         ) {
                          dispatch(pricingCostDriverAction.setDisable(true)); //dah
                         }
                         if (values.customFields[index].type) {
                          dispatch(
                           pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(
                            dataTOSend
                           )
                          );
                         }
                        }}
                       />
                      </InputGroup>
                     </Stack>
                    </FormControl>
                   );
                  }
                 })}
              </>
             );
            }}
           />
          )}
         </Grid>
        </Box>
        {volumes && volumes.length > 0 && (
         <ToolbarBottom
          costAnalysis={volumes[0]}
          costAnalysisId={pricingId}
          resetForm={resetForm}
         />
        )}
       </Form>
      );
     }}
    </Formik>
   )}
   {!isEditing && !isReplace && !isCopy && (
    <>
     <Box border={"solid 1px #D7DBE2"} borderRadius="5px">
      <Grid w={"80%"} templateColumns="repeat(2, 1fr)" gap={8} m="1rem">
       <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
        <Text w="140px" fontWeight={600}>
         {costAnalysis?.configuration?.productLabelName}
        </Text>
        <Text>{costAnalysis?.name}</Text>
       </Grid>
       <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
        <Text w="140px" fontWeight={600}>
         {costAnalysis?.configuration?.productLabelDescription}
        </Text>
        <Text>{costAnalysis?.description}</Text>
       </Grid>
       <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
        <Text w="140px" fontWeight={600}>
         {costAnalysis?.configuration?.currencyLabelName}
        </Text>
        <Text>{detailPerVolumeId?.currency?.code}</Text>
       </Grid>
       <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
        <Text w="140px" fontWeight={600}>
         {costAnalysis?.configuration?.uoMLabelName === "UoM"
          ? costAnalysis?.uoM?.configuration?.identityName
          : costAnalysis?.configuration?.uoMLabelName}
        </Text>
        <Text>{costAnalysis?.uoM?.description}</Text>
       </Grid>
       {costAnalysis &&
        Array.isArray(detailPerVolumeId?.customFields) &&
        detailPerVolumeId?.customFields.length > 0 &&
        detailPerVolumeId?.customFields.map(
         (customField: any, index: number) => {
          if (
           customField?.configuration?.type === 20 ||
           customField?.configuration?.type === 10 ||
           customField?.configuration?.type === 40
          ) {
           return (
            <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
             <Text w="140px" fontWeight={600}>
              {customField?.configuration?.displayName}
             </Text>
             <Text>
              {!isEmpty(customField?.configuration?.name) &&
              customField?.configuration?.type === 20
               ? parseFloat(customField?.value).toLocaleString("en-US", {
                  minimumFractionDigits: 5,
                  maximumFractionDigits: 5,
                 })
               : customField?.value}
             </Text>
            </Grid>
           );
          } else {
           return (
            <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
             <Text w="140px" fontWeight={600}>
              {customField?.configuration?.displayName}
             </Text>
             <Text>
              {!isEmpty(customField?.configuration?.name)
               ? customField?.masterData?.code
               : "-"}
             </Text>
            </Grid>
           );
          }
         }
        )}
      </Grid>
     </Box>

     <Box border={"solid 1px #D7DBE2"} borderRadius="5px" my="1rem">
      <Grid w={"80%"} templateColumns="repeat(2, 1fr)" gap={8} m="1rem">
       {dataAdditional?.map((item: any, index: number) => (
        <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8} key={index}>
         <Text w="140px" fontWeight={600}>
          {item.label}
         </Text>
         <Text>{item.value}</Text>
        </Grid>
       ))}
       {detailPerVolumeId?.volumeReferenceType === 10 ||
        (detailPerVolumeId?.volumeReferenceType === 1 &&
         detailPerVolumeId?.volumeReferenceName && (
          <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
           <Text w="140px" fontWeight={600}>
            Copied from
           </Text>
           <Text>{detailPerVolumeId?.volumeReferenceName}</Text>
          </Grid>
         ))}
       {detailPerVolumeId?.volumeReferenceType === 20 &&
        detailPerVolumeId?.volumeReferenceName && (
         <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={8}>
          <Text w="140px" fontWeight={600}>
           Replace from
          </Text>
          <Text>{detailPerVolumeId?.volumeReferenceName}</Text>
         </Grid>
        )}
      </Grid>
     </Box>
    </>
   )}
  </Box>
 );
};

export default GeneralData;

const FormObserver = () => {
 const dispatch = useAppDispatch();
 const { values, submitForm, resetForm } = useFormikContext();
 useEffect(() => {
  // dispatch(pricingCostDriverAction.setResetForm(resetForm));
  if (values) {
   submitForm();
  }
 }, [values, submitForm]);
 return null;
};
