import { ReactElement, useState, useEffect } from "react";
import {
 Grid,
 Drawer,
 useDisclosure,
 DrawerOverlay,
 DrawerContent,
 DrawerHeader,
 DrawerBody,
 VStack,
 Heading,
 Text,
 GridItem,
 Divider,
 Box,
 Center,
 Tooltip,
 Spinner,
} from "@chakra-ui/react";
import SelectNameFromMasterData from "../SelectNameFromMasterData";
import ArrowBack from "../ArrowBackDrawer";
import { RootState } from "state/store";
import SelectNameCustomFieldFromMasterData from "../SelectNameCustomFieldFromMasterData";
import TextInputInTableView from "views/EditPricings/CostDrivers/EditTableCostDriver/TextInputInTableView";
import { useSelector, shallowEqual } from "react-redux";
import { useAppDispatch } from "hooks";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import SelectWithAutoComplete from "components/SelectWithAutoComplete";
import AddRowView from "views/EditPricings/CostDrivers/EditTableCostDriver/AddRow";
import Delete from "views/EditPricings/CostDrivers/EditTableCostDriver/DeleteRow";
import Card from "../Card";
import {
 FindIndexDataAdd,
 FindIndexDataDelete,
 FindIndexDataUpdate,
} from "utils/simulateBySku";
import { isEmpty } from "lodash";

import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import Recalculate from "../Recalculate";
import Swal from "sweetalert2";

let unmounted = false;
interface TCustomFields {
 name: string;
 value: string;
 typeData: number;
}
interface TData {
 id: string;
 name: string;
 description: string;
 currency: string;
 totalCostToCurrency: number;
 totalCost: string;
 customFields: TCustomFields[];
 masterDataConfiguration: any;
}

const TableView = ({
 costDriver,
 paramName,
 level,
 values,
 arrayHelpers,
 setFieldValue,
}: any): ReactElement => {
 const {
  dataToSendUpdates,
  dataToSendadds,
  dataToSend,
  dataToSendDelete,
  tmpChangeRow,
  isDataDuplicate,
  needReFetch,
  temporaryData,
  indexCostDriversSimulateBySku,
  originalCostDrivers,
  isEditing,
  isCopy,
  isReplace,
  isErrorDuplicate,
  detailPerVolumeId,
  listCurrency,
  valueIsIncludeCalculation,
  loading,
  dataToSimulates,
 } = useSelector(
  (state: RootState) => ({
   loading: state.editPricingCostDrivers.loading,
   isEditing: state.editPricingCostDrivers.isEditing,
   isCopy: state.copyAndReplaceCostDrivers.isCopy,
   isReplace: state.copyAndReplaceCostDrivers.isReplace,
   dataToSendUpdates: state.editPricingCostDrivers.dataToSend.updates,
   dataToSendadds: state.editPricingCostDrivers.dataToSend.adds,
   dataToSend: state.editPricingCostDrivers.dataToSend,
   dataToSendDelete: state.editPricingCostDrivers.dataToSend.deletes,
   tmpChangeRow: state.editPricingCostDrivers.tmpChangeRow,
   isDataDuplicate: state.editPricingCostDrivers.isDataDuplicate,
   needReFetch: state.editPricingCostDrivers.needReFetch,
   temporaryData: state.editPricingCostDrivers.temporaryData,
   indexCostDriversSimulateBySku:
    state.editPricingCostDrivers.indexCostDriversSimulateBySku,
   originalCostDrivers: state.editPricingCostDrivers.originalCostDrivers,
   isErrorDuplicate: state.editPricingCostDrivers.isErrorDuplicate,
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId.result,
   listCurrency: state.multiCurrency.lists,
   valueIsIncludeCalculation:
    state.editPricingCostDrivers.valueIsIncludeCalculation,
   dataToSimulates: state.editPricingCostDrivers.dataToSimulate,
  }),
  shallowEqual
 );
 const dispatch = useAppDispatch();
 const { isOpen, onOpen, onClose } = useDisclosure();

 const [currencies, setCurrencies] = useState([]);

 const fetchListCurrency = async () => {
  const payload = {
   skipCount: 0,
   maxResultCount: 1000,
   keyword: "",
  };
  await dispatch(multiCurrencyAction.listMultiCurrency(payload));
 };

 useEffect(() => {
  fetchListCurrency();
 }, []);

 useEffect(() => {
  setCurrencies(
   listCurrency?.items?.map((item: any) => {
    return {
     label: item.code,
     value: item.id,
     desc: item.description,
    };
   })
  );
 }, [listCurrency]);

 //  set state
 const [dataOnclick, setDataOnClick] = useState<TData>();
 const [isAddNew, setAddNew] = useState<boolean>(false);
 const [changeCurrencyDropDown, setChangeCurrencyDropDown] =
  useState<boolean>(false);

 const [errorSendData, setErrorSendDataMusthaveOne] = useState<boolean>(false);
 const [index, setIndex] = useState<number>();

 useEffect(() => {
  const runFunction = async () => {
   await dispatch(pricingCostDriverAction.setValueIsIncludeCalculation(true));
   //  if(!isEmpty(dataToSend.name)){
   await dispatch(pricingCostDriverAction.simulate(dataToSend)).then(
    (res: any) => {
     if (
      res?.error?.message ===
       "At least 1 record must exist for this Cost Driver." ||
      res?.error?.message === "Request failed with status code 500"
     ) {
      setErrorSendDataMusthaveOne(true);
     }
    }
   );
   //  }
   await dispatch(pricingCostDriverAction.setValueIsIncludeCalculation(false));
   await dispatch(pricingCostDriverAction.setNeedReFetch(false));
  };
  if (needReFetch) {
   runFunction();
  }

  return () => {
   unmounted = true;
  };
 }, [needReFetch]);

 useEffect(() => {
  const fetchData = async () => {
   const indexDataAdd = FindIndexDataAdd(dataToSendadds, temporaryData);
   const indexDataDelete = FindIndexDataDelete(dataToSendDelete, temporaryData);

   const indexDataUpdate = FindIndexDataUpdate(
    dataToSendUpdates,
    temporaryData
   );
   const objectSendTmp = {
    temporaryData,
    indexCostDriversSimulateBySku,
    indexDataAdd,
    indexDataDelete,
    indexDataUpdate,
   };
   await dispatch(
    pricingCostDriverAction.setBackTemporaryDataCostDriverIfError(objectSendTmp)
   );
   await dispatch(pricingCostDriverAction.setTemporaryData(null));
   setErrorSendDataMusthaveOne(false);
  };
  if (errorSendData && temporaryData) {
   fetchData();
  }
 }, [temporaryData, errorSendData, indexCostDriversSimulateBySku]);

 useEffect(() => {
  const fetchData = async () => {
   if (isDataDuplicate) {
    const indexDataAdd = FindIndexDataAdd(dataToSendadds, temporaryData);
    const indexTmpChangeRow = tmpChangeRow.findIndex(
     (itemTmpMustDelete: any) => {
      if (itemTmpMustDelete?.idRow === temporaryData?.id) {
       return tmpChangeRow.length;
      }
     }
    );
    // disni monitor
    const totalIndexAddMustRemove =
     dataToSendadds.length > 1 ? indexDataAdd + 1 : indexDataAdd - 1;

    const findIdFromOriginal =
     originalCostDrivers[indexCostDriversSimulateBySku[0]].data[
      indexCostDriversSimulateBySku[1]
     ].data[indexCostDriversSimulateBySku[2]].id;

    const findIdFromTemporary = temporaryData.id;

    const objectSendTmp = {
     temporaryData,
     indexCostDriversSimulateBySku,
     indexDataAdd: totalIndexAddMustRemove,
     indexTmpChangeRow,
     idNotRemove: findIdFromOriginal === findIdFromTemporary,
    };

    await dispatch(pricingCostDriverAction.setRemoveIfError(objectSendTmp));
    dispatch(pricingCostDriverAction.setErrorSendDataDuplicate(false));
   }
  };
  fetchData();
 }, [indexCostDriversSimulateBySku, isDataDuplicate]);

 return (
  <Box
   sx={{
    "&::-webkit-scrollbar": {
     width: "6px",
     height: "6px",
     marginLeft: "1.5rem",
     marginRight: "1.5rem",
    },
    "&::-webkit-scrollbar-track": {
     width: "6px",
     height: "6px",
     marginLeft: "1.5rem",
     marginRight: "1.5rem",
    },
    "&::-webkit-scrollbar-thumb": {
     borderRadius: "24px",
     backgroundColor: "#0072CF",
     marginLeft: "1rem",
    },
   }}
  >
   <Grid
    templateColumns="repeat(4, 1fr)"
    overflow={"hidden"}
    gap={4}
    alignItems="center"
   >
    {values?.customFields.length > 0 &&
     values?.customFields.map((item: any, indexCard: number) => {
      if (item.totalCost !== 0) {
       return (
        <Card
         key={indexCard}
         item={item}
         onClick={() => {
          if (item && !loading) {
           setDataOnClick(item);
           setIndex(indexCard);
           onOpen();
          }
         }}
        />
       );
      }
     })}

    {(level !== 30 && !loading) ||
     (costDriver.canAddNew && !loading ? (
      <AddRowView
       values={values}
       costDriver={costDriver}
       paramName={paramName}
       arrayHelpers={arrayHelpers}
       onClick={(data: any) => {
        if (!loading) {
         setDataOnClick(data);
         setIndex(values?.customFields.length);
         onOpen();
         setAddNew(true);
        }
       }}
      />
     ) : (
      <Spinner
       thickness="4px"
       speed="0.65s"
       emptyColor="gray.200"
       color="blue.500"
       size="sm"
      />
     ))}
   </Grid>
   <Drawer
    isOpen={isOpen}
    size="lg"
    placement="right"
    onClose={() => {
     if (dataToSimulates.length > 0) {
      Swal.fire({
       showCancelButton: true,
       allowOutsideClick: false,
       html:
        "All changes will be lost without recalculating. Are you sure you want to exit?",
       confirmButtonText: "Yes",
       cancelButtonText: "No",
      }).then(async (result) => {
       if (result.isConfirmed) {
        onClose();
        for (let i = 0; i < dataToSimulates.length; i++) {
         const findDataChanges = dataToSimulates[
          i
         ].dataFromSimulate.customFields.find(
          (data: any) => data.name === dataToSimulates[i].name
         );
         dataToSimulates[i].name === "name"
          ? setFieldValue(
             `customFields[${index}].${dataToSimulates[i].name}`,
             dataToSimulates[i].dataFromSimulate.name
            )
          : dataToSimulates[i].name === "description"
          ? setFieldValue(
             `customFields[${index}].${dataToSimulates[i].name}`,
             dataToSimulates[i].dataFromSimulate.description
            )
          : dataToSimulates[i].name === "unitCost"
          ? setFieldValue(
             `customFields[${index}].${dataToSimulates[i].name}`,
             dataToSimulates[i].dataFromSimulate.unitCost
            )
          : setFieldValue(
             `customFields[${index}].${dataToSimulates[i].name}`,
             findDataChanges?.value
            );
        }
        dispatch(pricingCostDriverAction.resetDataSimulate([]));
       }
      });
     } else {
      onClose();
     }
    }}
    closeOnOverlayClick={true}
    closeOnEsc={false}
   >
    <DrawerOverlay />
    <DrawerContent>
     {Array.isArray(values?.customFields) &&
      values?.customFields.map((row: any, indexContent: any) => {
       if (index === indexContent) {
        let currentRow = new Map(Object.entries(row));
        dispatch(
         pricingCostDriverAction.setScrollToId(`costdriver-${indexContent}`)
        );
        let newRow = { ...row };
        const newCustomField: any = [];

        row.customFields.map((itemCustom: any) => {
         if (itemCustom?.isMaintenanceByMasterData) {
          newCustomField.push({
           ...itemCustom,
           name: `${itemCustom.name}`,
           typeData: itemCustom.typeData,
          });
         } else {
          newCustomField.push({ ...itemCustom });
         }
        });
        newRow.customFields = newCustomField?.filter(
         (item: any) => item.isDisplayOnPricing !== false
        );

        if (row || row.isNew) {
         const findName = costDriver.headers.find(
          (header: any) => header.name === "name"
         );
         const findDesc = costDriver.headers.find(
          (header: any) => header.name === "description"
         );

         return (
          <>
           <ArrowBack
            costDriver={costDriver}
            index={index}
            paramName={paramName}
            arrayHelpers={arrayHelpers}
            row={row}
            setFieldValue={setFieldValue}
            onClick={() => {
             onClose();
            }}
           />
           <DrawerHeader>{row?.name}</DrawerHeader>
           <DrawerBody key={indexContent} id={`costdriver-${indexContent}`}>
            <VStack spacing={6} align="stretch" mb={"100px"}>
             {findName && findDesc && (
              <>
               <Heading fontSize="16px" fontWeight={500} mb=".5rem">
                Cost Driver
               </Heading>
               <VStack spacing={4} align="stretch">
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                 {costDriver.headers
                  ?.filter((item: any) => item.isDisplayOnPricing !== false)
                  .map(
                   ({
                    name,
                    label,
                    convertName,
                    maintenanceWithMasterData,
                   }: any) => {
                    const isIncludeInCalculation = newRow.customFields?.find(
                     (i: any) => {
                      return (
                       i.name == name &&
                       i.isIncludeInCalculation !== undefined &&
                       i.isIncludeInCalculation === true
                      );
                     }
                    );
                    let isRequired = false;
                    newRow.customFields?.find((i: any) => {
                     if (name === i.name) {
                      return (isRequired =
                       i.name == name &&
                       !i.isMaintenanceByMasterData &&
                       i.isRequired);
                     }
                    });

                    const isMaintenanceWithMasterData =
                     convertName === "name" && row.masterDataConfiguration;

                    if (
                     convertName === "name" ||
                     convertName === "description"
                    ) {
                     return (
                      <GridItem w="100%" key={convertName}>
                       {(convertName === "name" ||
                        convertName === "description") && (
                        <VStack spacing={0} align="stretch" position="relative">
                         <Text
                          color="rgba(0, 0, 0, 0.6)"
                          fontSize="14px"
                          fontWeight={400}
                          position="absolute"
                          left={2}
                          top="-0.5"
                          bg="linear-gradient(rgba(255, 255, 255, 2), rgba(255, 255, 255, 0))"
                          zIndex={9}
                          px="3"
                          borderRadius={5}
                         >
                          {label}
                         </Text>
                         {isMaintenanceWithMasterData ? (
                          <SelectNameFromMasterData
                           index={indexContent}
                           isErrorDuplicate={isErrorDuplicate}
                           convertName={convertName}
                           row={row}
                           isIncludeInCalculation={isIncludeInCalculation}
                           predefinedData={isMaintenanceWithMasterData}
                           costDriver={costDriver}
                           values={values}
                           currentRow={currentRow}
                           paramName={paramName}
                           setFieldValue={setFieldValue}
                           isRequired={isRequired}
                          />
                         ) : (
                          <TextInputInTableView
                           index={indexContent}
                           convertName={name}
                           name={name}
                           row={row}
                           options={currencies || []}
                           isErrorDuplicate={isErrorDuplicate}
                           isIncludeInCalculation={
                            maintenanceWithMasterData
                             ? false
                             : isIncludeInCalculation
                           }
                           isMaintenanceByMasterData={maintenanceWithMasterData}
                           costDriver={costDriver}
                           values={values}
                           currentRow={currentRow}
                           paramName={paramName}
                           label={label}
                           arrayHelpers={arrayHelpers}
                           setFieldValue={setFieldValue}
                           isRequired={isRequired}
                          />
                         )}
                        </VStack>
                       )}
                      </GridItem>
                     );
                    }
                   }
                  )}
                </Grid>
               </VStack>
              </>
             )}
             <>
              {newRow.customFields.length > 0 && (
               <>
                <Divider borderColor="rgba(26, 26, 26, 0.56)" />
                <Heading fontSize="16px" fontWeight={500} mb=".5rem">
                 Additional Field
                </Heading>
                <VStack spacing={4} align="stretch">
                 <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  {costDriver.headers
                   ?.filter((item: any) => item.isDisplayOnPricing !== false)
                   .map(
                    ({
                     name,
                     label,
                     convertName,
                     maintenanceWithMasterData,
                    }: any) => {
                     const isIncludeInCalculation = newRow.customFields?.find(
                      (i: any) => {
                       return (
                        i.name == name &&
                        i.isIncludeInCalculation !== undefined &&
                        i.isIncludeInCalculation === true
                       );
                      }
                     );
                     let isRequired = false;
                     newRow.customFields?.find((i: any) => {
                      if (name === i.name) {
                       return (isRequired = i.name == name && i.isRequired);
                      }
                     });

                     let masterData;

                     masterData = values?.customFields[
                      indexContent
                     ]?.customFields?.find((i: any) => {
                      if (
                       i.maintenanceWithMasterData &&
                       i.name.replace("-From-Masterdata", "") == name
                      ) {
                       return i;
                      } else {
                       if (i.name == name && i.masterData) {
                        return i;
                       }
                      }
                     });
                     const isMaintenanceWithMasterData =
                      values?.customFields?.find(
                       (i: any) => convertName === "name" && i?.masterData
                      );

                     const mantenanceMasterdata = newRow?.customFields?.find(
                      (i: any) =>
                       `${convertName}-From-Masterdata` === i.name &&
                       i?.isMaintenanceByMasterData
                     );

                     if (
                      convertName !== "name" &&
                      convertName !== "description" &&
                      convertName !== "currency" &&
                      convertName !== "totalCost" &&
                      convertName !== "unitCost" &&
                      convertName !== "exchangeRateToCurrency" &&
                      convertName !== "totalCostToCurrency"
                     ) {
                      return (
                       <GridItem w="100%" key={convertName}>
                        <VStack spacing={0} align="stretch" position="relative">
                         <Text
                          color="rgba(0, 0, 0, 0.6)"
                          fontSize="14px"
                          fontWeight={400}
                          position="absolute"
                          left={2}
                          top="-0.5"
                          bg="linear-gradient(rgba(255, 255, 255, 2), rgba(255, 255, 255, 0))"
                          zIndex={1}
                          px="3"
                          borderRadius={5}
                         >
                          {convertName}
                         </Text>
                         {isMaintenanceWithMasterData ? (
                          <SelectNameFromMasterData
                           index={indexContent}
                           isErrorDuplicate={isErrorDuplicate}
                           convertName={convertName}
                           row={row}
                           isIncludeInCalculation={isIncludeInCalculation}
                           predefinedData={isMaintenanceWithMasterData}
                           costDriver={costDriver}
                           values={values}
                           currentRow={currentRow}
                           paramName={paramName}
                           setFieldValue={setFieldValue}
                           isRequired={isRequired}
                          />
                         ) : masterData ? (
                          <SelectNameCustomFieldFromMasterData
                           index={index}
                           convertName={convertName}
                           row={row}
                           costDriver={costDriver}
                           values={values}
                           currentRow={currentRow}
                           paramName={paramName}
                           setFieldValue={setFieldValue}
                           isAssociateWithThirdParty={
                            masterData?.masterData?.configuration
                             ?.isAssociateWithThirdParty
                           }
                           isErrorDuplicate={isErrorDuplicate}
                           code={masterData?.masterData?.configuration?.id}
                           thirdPartyId={
                            row?.masterDataConfiguration?.thirdParty?.id
                           }
                          />
                         ) : (
                          <TextInputInTableView
                           index={indexContent}
                           convertName={name}
                           name={name}
                           row={row}
                           options={currencies || []}
                           isErrorDuplicate={isErrorDuplicate}
                           isIncludeInCalculation={
                            maintenanceWithMasterData
                             ? false
                             : isIncludeInCalculation
                           }
                           isMaintenanceByMasterData={maintenanceWithMasterData}
                           maintenanceMasterdata={mantenanceMasterdata}
                           costDriver={costDriver}
                           values={values}
                           currentRow={currentRow}
                           paramName={paramName}
                           label={label}
                           arrayHelpers={arrayHelpers}
                           setFieldValue={setFieldValue}
                           isRequired={isRequired}
                          />
                         )}
                        </VStack>
                       </GridItem>
                      );
                     }
                    }
                   )}
                  <Box
                   position="absolute"
                   bottom={8}
                   left="50%"
                   transform="translateX(-50%)"
                   textAlign="center"
                   zIndex={9999}
                  >
                   <Center>
                    {(![10, 30].includes(level) ||
                     costDriver?.parentCostDriver !== null) && (
                     <Delete
                      costDriver={costDriver}
                      index={index}
                      currentRow={currentRow}
                      paramName={paramName}
                      arrayHelpers={arrayHelpers}
                      row={
                       values?.customFields[values?.customFields.length - 1]
                      }
                      onClick={() => onClose()}
                     />
                    )}
                    <Recalculate
                     convertName={""}
                     paramName={paramName}
                     row={
                      row ||
                      values?.customFields[values?.customFields.length - 1]
                     }
                     costDriver={costDriver}
                     isErrorDuplicate={isErrorDuplicate}
                    />
                   </Center>
                  </Box>
                 </Grid>
                </VStack>
               </>
              )}

              <Divider borderColor="rgba(26, 26, 26, 0.56)" />
              <Heading fontSize="16px" fontWeight={500} mb=".5rem">
               Costing
              </Heading>
              <VStack spacing={4} align="stretch">
               <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                {costDriver.headers
                 ?.filter((item: any) => item.isDisplayOnPricing !== false)
                 .map(
                  ({
                   name,
                   label,
                   convertName,
                   maintenanceWithMasterData,
                  }: any) => {
                   const isIncludeInCalculation = newRow.customFields?.find(
                    (i: any) => {
                     return (
                      i.name == name &&
                      i.isIncludeInCalculation !== undefined &&
                      i.isIncludeInCalculation === true
                     );
                    }
                   );
                   let isRequired = false;
                   newRow.customFields?.find((i: any) => {
                    if (name === i.name) {
                     return (isRequired =
                      i.name == name &&
                      !i.isMaintenanceByMasterData &&
                      i.isRequired);
                    }
                   });

                   let isMaintenanceWithMasterData = values?.customFields?.find(
                    (i: any) =>
                     convertName === "name" && i?.masterDataConfiguration
                   );

                   const findCustomfieldName = row.customFields.find(
                    (customField: any) => customField.name === convertName
                   );

                   if (convertName === "unitCost") {
                    isRequired = true;
                   }

                   const findMasterData = newRow.customFields.find(
                    (customField: any) =>
                     customField.name === `${convertName}-From-Masterdata`
                   );

                   if (
                    convertName !== "name" &&
                    convertName !== "description" &&
                    convertName !== "exchangeRateToCurrency" &&
                    convertName !== "totalCostToCurrency" &&
                    convertName !== findCustomfieldName?.name &&
                    !findMasterData
                   ) {
                    return (
                     <>
                      <GridItem w="100%" key={convertName}>
                       <VStack spacing={0} align="stretch" position="relative">
                        <Tooltip
                         label={
                          label
                          // convertName === "unitCost" ? label : convertName
                         }
                         placement="top-start"
                         bg="gray.300"
                         color="rgba(0, 0, 0, 0.6)"
                        >
                         <Text
                          color="rgba(0, 0, 0, 0.6)"
                          fontSize="14px"
                          fontWeight={400}
                          position="absolute"
                          left={2}
                          top="-0.5"
                          bg="linear-gradient(rgba(255, 255, 255, 2), rgba(255, 255, 255, 0))"
                          zIndex={9}
                          //  px="3"
                          // width={150}
                          borderRadius={5}
                          textTransform="capitalize"
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace="nowrap"
                         >
                          {label}
                          {/* {convertName === "unitCost" && label}
                          {convertName !== "unitCost" && convertName} */}
                         </Text>
                        </Tooltip>
                        {isMaintenanceWithMasterData ? (
                         <SelectNameFromMasterData
                          index={indexContent}
                          isErrorDuplicate={isErrorDuplicate}
                          convertName={convertName}
                          row={row}
                          isIncludeInCalculation={isIncludeInCalculation}
                          predefinedData={isMaintenanceWithMasterData}
                          costDriver={costDriver}
                          values={values}
                          currentRow={currentRow}
                          paramName={paramName}
                          setFieldValue={setFieldValue}
                          isRequired={isRequired}
                         />
                        ) : (
                         // : convertName === "currency" ? (
                         //  <>
                         //   <SelectWithAutoComplete
                         //    name="currencyId"
                         //    defaultValue={newRow.currencyId}
                         //    isDisabled={
                         //     loading ||
                         //     row.masterDataConfiguration ||
                         //     isErrorDuplicate ||
                         //     row.index === 0
                         //    }
                         //    onChange={async (e: any) => {
                         //     const newCustomField: any = [];
                         //     row?.customFields.map((custom: any) => {
                         //      if (!custom.isMaintenanceByMasterData) {
                         //       newCustomField.push(custom);
                         //      }
                         //     });

                         //     const dataToUpdate = {
                         //      costAnalysisPerVolumeId: detailPerVolumeId?.id,
                         //      costDriverId: row.id,
                         //      parentCostDriverId: row.constDriverId,
                         //      name: row.name,
                         //      description: row.description,
                         //      masterDataId: row?.masterDataConfiguration?.id,
                         //      unitCost: row?.unitCost,
                         //      customFields: newCustomField,
                         //      currencyId: e?.value,
                         //     };
                         //     await dispatch(
                         //      pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(
                         //       dataToUpdate
                         //      )
                         //     );
                         //    }}
                         //    options={currencies}
                         //    mt={7}
                         //    //  width={300}
                         //    noBorder={true}
                         //   />
                         //  </>
                         // )
                         <TextInputInTableView
                          index={indexContent}
                          convertName={name}
                          name={name}
                          row={row}
                          options={currencies || []}
                          isErrorDuplicate={isErrorDuplicate}
                          isIncludeInCalculation={
                           maintenanceWithMasterData
                            ? false
                            : isIncludeInCalculation
                          }
                          isMaintenanceByMasterData={maintenanceWithMasterData}
                          costDriver={costDriver}
                          values={values}
                          currentRow={currentRow}
                          paramName={paramName}
                          label={label}
                          arrayHelpers={arrayHelpers}
                          setFieldValue={setFieldValue}
                          isRequired={isRequired}
                          isDisabled={convertName === "totalCost"}
                         />
                        )}
                       </VStack>
                      </GridItem>
                     </>
                    );
                   }
                  }
                 )}
                <Box
                 position="absolute"
                 bottom={8}
                 left="50%"
                 transform="translateX(-50%)"
                 textAlign="center"
                 zIndex={9999}
                >
                 <Center>
                  {(![10, 30].includes(level) ||
                   costDriver?.parentCostDriver !== null) && (
                   <Delete
                    costDriver={costDriver}
                    index={index}
                    paramName={paramName}
                    arrayHelpers={arrayHelpers}
                    row={dataOnclick}
                    values={values}
                    onClick={() => onClose()}
                   />
                  )}
                  <Recalculate
                   convertName={""}
                   paramName={paramName}
                   row={
                    row || values?.customFields[values?.customFields.length - 1]
                   }
                   costDriver={costDriver}
                   isErrorDuplicate={isErrorDuplicate}
                  />
                 </Center>
                </Box>
               </Grid>
              </VStack>
              <Divider borderColor="rgba(26, 26, 26, 0.56)" />
              <Heading fontSize="16px" fontWeight={500} mb=".5rem">
               Exchange Rate to {row?.convertedCurrency?.code}
              </Heading>
              <VStack spacing={4} align="stretch">
               <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                {costDriver.headers
                 ?.filter((item: any) => item.isDisplayOnPricing !== false)
                 .map(
                  ({
                   name,
                   label,
                   convertName,
                   maintenanceWithMasterData,
                  }: any) => {
                   const isIncludeInCalculation = newRow.customFields?.find(
                    (i: any) => {
                     return (
                      i.name == name &&
                      i.isIncludeInCalculation !== undefined &&
                      i.isIncludeInCalculation === true
                     );
                    }
                   );
                   let isRequired = false;
                   newRow.customFields?.find((i: any) => {
                    if (name === i.name) {
                     return (isRequired =
                      i.name == name &&
                      !i.isMaintenanceByMasterData &&
                      i.isRequired);
                    }
                   });

                   const isMaintenanceWithMasterData =
                    values?.customFields?.find(
                     (i: any) => convertName === "name" && i?.masterData
                    );
                   const findCustomfieldName = row.customFields.find(
                    (customField: any) => customField.name === convertName
                   );

                   const findMasterData = newRow.customFields.find(
                    (customField: any) =>
                     customField.name === `${convertName}-From-Masterdata`
                   );

                   if (
                    convertName !== "name" &&
                    convertName !== "description" &&
                    convertName !== "totalCost" &&
                    convertName !== "unitCost" &&
                    convertName !== "totalCostToCurrency" &&
                    convertName !== findCustomfieldName?.name &&
                    convertName !== "currency" &&
                    !findMasterData
                   ) {
                    return (
                     <GridItem w="100%" key={convertName}>
                      <VStack spacing={0} align="stretch" position="relative">
                       <Text
                        color="rgba(0, 0, 0, 0.6)"
                        fontSize="14px"
                        fontWeight={400}
                        position="absolute"
                        left={2}
                        top="-0.5"
                        bg="linear-gradient(rgba(255, 255, 255, 2), rgba(255, 255, 255, 0))"
                        zIndex={9}
                        px="3"
                        borderRadius={5}
                        textTransform="capitalize"
                       >
                        {convertName !== "exchangeRateToCurrency" &&
                         convertName}
                       </Text>

                       {isMaintenanceWithMasterData ? (
                        <SelectNameFromMasterData
                         index={indexContent}
                         isErrorDuplicate={isErrorDuplicate}
                         convertName={convertName}
                         row={row}
                         isIncludeInCalculation={isIncludeInCalculation}
                         predefinedData={isMaintenanceWithMasterData}
                         costDriver={costDriver}
                         values={values}
                         currentRow={currentRow}
                         paramName={paramName}
                         setFieldValue={setFieldValue}
                         isRequired={isRequired}
                        />
                       ) : convertName === "exchangeRateToCurrency" ? (
                        <Grid
                         w="100%"
                         templateColumns="repeat(2, 1fr)"
                         gap={250}
                        >
                         <VStack
                          spacing={0}
                          align="stretch"
                          position="relative"
                         >
                          <Text>Currency</Text>
                          <Text
                           fontWeight={400}
                           borderRadius={5}
                           textTransform="capitalize"
                          >
                           {row?.convertedCurrency?.code}
                          </Text>
                         </VStack>

                         <VStack
                          spacing={0}
                          align="stretch"
                          position="relative"
                         >
                          <Text>Rate</Text>
                          <Text
                           fontWeight={400}
                           borderRadius={5}
                           textTransform="capitalize"
                          >
                           {row?.currencyConversionExchangeRate?.toFixed(5)}
                          </Text>
                         </VStack>
                        </Grid>
                       ) : (
                        <TextInputInTableView
                         index={indexContent}
                         convertName={name}
                         name={name}
                         row={row}
                         options={currencies || []}
                         isDisabled
                         isErrorDuplicate={isErrorDuplicate}
                         isIncludeInCalculation={
                          maintenanceWithMasterData
                           ? false
                           : isIncludeInCalculation
                         }
                         isMaintenanceByMasterData={maintenanceWithMasterData}
                         costDriver={costDriver}
                         values={values}
                         currentRow={currentRow}
                         paramName={paramName}
                         label={label}
                         arrayHelpers={arrayHelpers}
                         setFieldValue={setFieldValue}
                         isRequired={isRequired}
                        />
                       )}
                      </VStack>
                     </GridItem>
                    );
                   }

                   if (
                    convertName !== "name" &&
                    convertName !== "description" &&
                    convertName !== "totalCost" &&
                    convertName !== "unitCost" &&
                    convertName !== "totalCostToCurrency" &&
                    convertName !== findCustomfieldName?.name &&
                    convertName !== "currency"
                   ) {
                   }
                  }
                 )}
                <Box
                 position="absolute"
                 bottom={8}
                 left="50%"
                 transform="translateX(-50%)"
                 textAlign="center"
                 zIndex={9999}
                >
                 <Center>
                  {(![10, 30].includes(level) ||
                   costDriver?.parentCostDriver !== null) && (
                   <Delete
                    costDriver={costDriver}
                    index={index}
                    paramName={paramName}
                    arrayHelpers={arrayHelpers}
                    row={dataOnclick}
                    onClick={() => onClose()}
                   />
                  )}
                  <Recalculate
                   convertName={""}
                   paramName={paramName}
                   row={
                    row || values?.customFields[values?.customFields.length - 1]
                   }
                   costDriver={costDriver}
                   isErrorDuplicate={isErrorDuplicate}
                  />
                 </Center>
                </Box>
               </Grid>
              </VStack>
              <Divider borderColor="rgba(26, 26, 26, 0.56)" />
              <Text color="rgba(0, 0, 0, 0.6)" fontSize="14px">
               Total Cost
              </Text>
              <Text fontWeight="bold" mt="0!important">
               {`${row?.convertedCurrency?.code || ""} ${
                row?.totalCostPricing?.amount?.toFixed(5) || 0
               }`}
              </Text>
             </>
            </VStack>
           </DrawerBody>
          </>
         );
        }
       }
      })}
    </DrawerContent>
   </Drawer>
  </Box>
 );
};

export default TableView;
