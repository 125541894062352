import { ReactElement, useEffect } from "react";
import { TextLoading } from "components/Loading";
import { Box } from "@chakra-ui/layout";
import { Checkbox } from "@chakra-ui/react";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { actions as thirdPartySettingAction } from "state/settings/slice";

const General = (): ReactElement => {
 const dispatch = useAppDispatch();

 let { loading, isEnableThirdPartyStatus } = useSelector(
    (state: RootState) => ({
     loading: state.thirdPartySetting.loading,
     isEnableThirdPartyStatus: state.thirdPartySetting.isEnableThirdPartyStatus
    }),
    shallowEqual
   );

 useEffect(() => {
  ThirdPartyStatus()
 }, [isEnableThirdPartyStatus]);
 const ThirdPartyStatus = () => {
  dispatch(thirdPartySettingAction.configurationStatus());
 }

 const handleCheckConfig = async (isChecked: boolean) => {
  await dispatch(thirdPartySettingAction.configuration({isEnableThirdParty: isChecked}));
  dispatch(thirdPartySettingAction.setEnableThirdParty(isChecked))
  ThirdPartyStatus()
 };
 
 return (
  <Box>
   {!loading ? <Checkbox
   isChecked={isEnableThirdPartyStatus}
    onChange={(val) => {
     handleCheckConfig(val.target.checked);
    }}
    size="lg"
    fontSize={14}
    fontWeight="550"
   >
    Enable Feature Third-Party
   </Checkbox> : <TextLoading />}
  </Box>
 );
};

export default General;
