import { useEffect, useRef } from "react";
import Layout from "views/Layout";
import { Box, Heading, VStack } from "@chakra-ui/layout";
import { Link, useHistory} from 'react-router-dom';
import { MdClose } from "react-icons/md";
import { useAppDispatch } from 'hooks';
import queryString from 'query-string';
import LevelOverview from "views/Journeys/CreateJourney/levelOverview";
import LevelDetail from "views/Journeys/CreateJourney/levelDetail";
import LevelTarget from 'views/Journeys/CreateJourney/levelTarget';
import TargetDiscoveryStep1 from "views/Journeys/CreateJourney/targetDiscovery/step1";
import TargetDiscoveryStep2 from "views/Journeys/CreateJourney/targetDiscovery/step2";
import TargetDiscoveryStep3 from "views/Journeys/CreateJourney/targetDiscovery/step3";
import TargetDiscoveryStep4 from "views/Journeys/CreateJourney/targetDiscovery/step4";
import NameJourney from "views/Journeys/CreateJourney/targetDiscovery/nameJourney";
import { actions as myJourneyActions } from 'state/myJourney/slice';

export default function Home() {
  const router = useHistory();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const {location} = router;
  const {search} = location;
  const query = queryString.parse(search);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  },[search]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if(query?.page !== 'level-overview'){
      resetJourneyCreationState();
      window.location.href = '/journeys/create?page=level-overview';
    }
  }, [])

  const resetJourneyCreationState = () =>{
    dispatch(myJourneyActions.removeAllWhatInformationDoYouHave([]));
    dispatch(myJourneyActions.removeAllWhatInformationCanYouGain([]));
    dispatch(myJourneyActions.setJourneyLevel('Does Cost'));
    dispatch(myJourneyActions.setLevelName(''));
  };

  return (
    <Layout>
      <VStack align="flex-start" ref={messagesEndRef} p="5">
        <Box position="absolute" right="10" fontSize="24" cursor="pointer">
          <Link to="/journeys" onClick={()=> {
            resetJourneyCreationState();
          }}><MdClose/></Link>
        </Box>
        <Heading as="h1" fontSize="48px" marginBottom="5" pb="5">
          Creating your journey
        </Heading>
        <LevelTarget/>
        <Box pt="rem" w="100%">
          {query?.page === 'level-overview' && <LevelOverview/>}
          {query?.page === 'level-details' && <LevelDetail/>}
          {query?.page === 'target-discovery-step-1' && <TargetDiscoveryStep1/>}
          {query?.page === 'target-discovery-step-2' && <TargetDiscoveryStep2/>}
          {query?.page === 'target-discovery-step-3' && <TargetDiscoveryStep3/>}
          {query?.page === 'target-discovery-step-4' && <TargetDiscoveryStep4/>}
          {query?.page === 'name-journey' && <NameJourney/>}
        </Box>
      </VStack>
    </Layout>
  );
}
