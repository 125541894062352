import { FC, useEffect, useState, useMemo } from "react";
import Table from "components/Table/Table";
import Pagination from "components/Pagination";
import { useHistory } from "react-router-dom";
import { TableLoading } from "components/Loading";
import { Box, Image, Button, HStack } from "@chakra-ui/react";
import Swal from "sweetalert2";
import IconDelete from "assets/images/Delete.png";
import IconEdit from "assets/images/Edit.png";
import { RootState } from "state/store";
import { useAppDispatch, usePrevious } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import "utils/date-utils";
import OptipediaToast from "utils/notification-util";

const TABLE_HEADERS = [
 { name: "userName", label: "User Name" },
 { name: "emailAddress", label: "Email" },
 { name: "thirdParty", label: "Third-Party" },
];
const dataSample = [{ userName: "-", emailAddress: "-", thirdParty: "-" }];

const ThirdPartyTable: FC = () => {
 const history = useHistory();
 const maxResultCount = 10;
 const [pageNumber, setPageNumber] = useState(1);

 const dispatch = useAppDispatch();
 let { loading, thirdPartyUser, totalPage, totalCountUser } = useSelector(
  (state: RootState) => ({
   loading: state.thirdPartySetting.loading,
   thirdPartyUser: state.thirdPartySetting.thirdPartyUser,
   totalPage: Math.ceil(
    state.thirdPartySetting.thirdPartyUser.totalCount / maxResultCount
   ),
   totalCountUser: state.thirdPartySetting.thirdPartyUser.totalCount,
  }),
  shallowEqual
 );

 const fetchListOfUser = (skipCount: number) => {
  const payload = {
   skipCount: skipCount,
   maxResultCount: maxResultCount,
   keyword: "",
  };

  return dispatch(thirdPartySettingAction.getThirdPartyUser(payload));
 };
 const deleteThirdParty = async (userId: string) => {
  await dispatch(
   thirdPartySettingAction.deleteThirdPartyUserById({ id: userId })
  ).then((res) => {
   if (res.payload !== undefined) {
    OptipediaToast.Success("The Users has been removed successfully.");
   }
   fetchListOfUser(0);
  });
 };
 useEffect(() => {
  fetchListOfUser(0);
 }, []);
 const dataTable =
  thirdPartyUser &&
  thirdPartyUser?.items?.map((item: any) => ({
   id: item.id,
   userId: item.userId,
   userName: item.user?.userName,
   emailAddress: item.user?.emailAddress,
   thirdParty: item.thirdParty?.code,
  }));
 const handleChangePagination = (pageNumber: number) => {
  setPageNumber(pageNumber);
  var skipCount = (pageNumber - 1) * maxResultCount;
  fetchListOfUser(skipCount);
 };

 return (
  <Box>
   {/* {thirdPartyUser && thirdPartyUser.length > 0 && */}
   <Table
    loading={loading}
    columnHeaders={TABLE_HEADERS}
    data={thirdPartyUser?.items?.length > 0 ? dataTable : dataSample}
    actionButtons={(userId: string) => (
     <HStack spacing="24px">
      {thirdPartyUser?.items?.length > 0 && (
       <>
        <Box w="40px" h="40px">
         <Image
          src={IconEdit}
          cursor="pointer"
          alt="IoMdEdit"
          mr="1rem"
          w="32px"
          h="32px"
          onClick={() =>
           history.push(`/settings/third-party/user-management/${userId}`)
          }
         />
        </Box>
        <Box w="40px" h="40px">
         <Image
          src={IconDelete}
          onClick={async () => {
           Swal.fire({
            showCancelButton: true,
            html: "Are you sure you want to delete this Simulation?",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
           }).then(async (result) => {
            if (result.isConfirmed) {
             deleteThirdParty(userId);
            }
           });
          }}
          cursor="pointer"
          alt="IoMdTrash"
          mr="1rem"
          w="32px"
          h="32px"
         />
        </Box>
       </>
      )}
     </HStack>
    )}
   />
   {/* } */}
   <Box w="100%" mt="1rem" p="20px">
    {thirdPartyUser && thirdPartyUser?.items?.length > 0 && (
     <Pagination
      onChange={handleChangePagination}
      totalRowsPage={maxResultCount}
      pageNumber={totalPage}
      currentPage={pageNumber}
      totalAllPage={totalCountUser}
     />
    )}
   </Box>
  </Box>
 );
};

export default ThirdPartyTable;
