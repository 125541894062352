import { ReactElement } from "react";
import { Box, Heading, Grid, GridItem, Flex, Text } from "@chakra-ui/react";
import Layout from "views/Layout";
import General from "./General";
import ThirdParty from "./ThirdParty";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import {
 BackButton,
 SettingGeneralIcon,
 SettingMasterDataIcon,
 SettingMultiCurrencyIcon,
 SettingTPartyIcon,
} from "components/Icons";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import MasterData from "./MasterData";
import MultiCurrency from "./MultiCurrency";
import { useHistory } from "react-router-dom";
const Settings = (): ReactElement => {
 const dispatch = useAppDispatch();
 const history = useHistory()
 let { activeButton } = useSelector(
  (state: RootState) => ({
   activeButton: state.thirdPartySetting.activeButton,
  }),
  shallowEqual
 );
 const handleButton = (val: any) => {
  dispatch(thirdPartySettingAction.setActiveButton(val));
 };
 return (
  <Layout>
   <Box>
    <Flex cursor={'pointer'} onClick={()=> history.goBack()} alignItems="center" mb="1.5rem" gap="3">
     <BackButton width="24px" height="24px" />
     <Heading as="h2" fontSize="24px" fontWeight={600}>
      {activeButton}
     </Heading>
    </Flex>

    <Grid
     h="200px"
     templateRows="repeat(2, 1fr)"
     templateColumns="repeat(8, 1fr)"
     gap={6}
    >
     <GridItem
      rowSpan={25}
      border="1px solid rgba(26, 26, 26, 0.16)"
      colSpan={2}
      borderRadius="5px"
      p="2.5rem"
     >
      <Grid pr="1rem">
       <GridItem>
        <Box
         fontSize="16"
         fontWeight={activeButton === "General Configuration" ? 700 : 500}
         color={
          activeButton === "General Configuration"
           ? "rgba(0, 112, 199, 1)"
           : "rgba(26, 26, 26, 0.36)"
         }
         cursor="pointer"
         onClick={() => handleButton("General Configuration")}
         mb="1.5rem"
        >
         <Flex alignItems="center" gap="3">
          <SettingGeneralIcon
           width="24px"
           height="24px"
           fill={
            activeButton === "General Configuration"
             ? "rgba(0, 112, 199, 1)"
             : "rgba(26, 26, 26, 0.36)"
           }
          />
          <Text>General Configuration</Text>
         </Flex>
        </Box>
       </GridItem>
       <GridItem>
        <Box
         borderRadius="5"
         fontSize="16"
         color={
          activeButton === "Third-Party Configuration"
           ? "rgba(0, 112, 199, 1)"
           : "rgba(26, 26, 26, 0.36)"
         }
         fontWeight={activeButton === "Third-Party Configuration" ? 700 : 500}
         cursor="pointer"
         onClick={() => handleButton("Third-Party Configuration")}
         mb="1.5rem"
        >
         <Flex alignItems="center" gap="3">
          <SettingTPartyIcon
           width="24px"
           height="24px"
           fill={
            activeButton === "Third-Party Configuration"
             ? "rgba(0, 112, 199, 1)"
             : "rgba(26, 26, 26, 0.36)"
           }
          />
          <Text>Third-Party Configuration</Text>
         </Flex>
        </Box>
       </GridItem>
       <GridItem>
        <Box
         borderRadius="5"
         fontSize="16"
         fontWeight={activeButton === "Master Data" ? 700 : 500}
         cursor="pointer"
         color={
          activeButton === "Master Data"
           ? "rgba(0, 112, 199, 1)"
           : "rgba(26, 26, 26, 0.36)"
         }
         onClick={() => handleButton("Master Data")}
         mb="1.5rem"
        >
         <Flex alignItems="center" gap="3">
          <SettingMasterDataIcon
           width="24px"
           height="24px"
           fill={
            activeButton === "Master Data"
             ? "rgba(0, 112, 199, 1)"
             : "rgba(26, 26, 26, 0.36)"
           }
          />
          <Text>Master Data</Text>
         </Flex>
        </Box>
       </GridItem>
       <GridItem>
        <Box
         borderRadius="5"
         fontSize="16"
         fontWeight={activeButton === "Multi Currency" ? 700 : 500}
         cursor="pointer"
         color={
          activeButton === "Multi Currency"
           ? "rgba(0, 112, 199, 1)"
           : "rgba(26, 26, 26, 0.36)"
         }
         onClick={() => handleButton("Multi Currency")}
         mb="1.5rem"
        >
         <Flex alignItems="center" gap="3">
          <SettingMultiCurrencyIcon
           width="24px"
           height="24px"
           fill={
            activeButton === "Multi Currency"
             ? "rgba(0, 112, 199, 1)"
             : "rgba(26, 26, 26, 0.36)"
           }
          />
          <Text>Multi Currency</Text>
         </Flex>
        </Box>
       </GridItem>
      </Grid>
     </GridItem>
     <GridItem
      colSpan={6}
      border="1px solid rgba(26, 26, 26, 0.16)"
      rowSpan={25}
      borderRadius="5px"
      p="2.5rem"
     >
      {activeButton === "Third-Party Configuration" && <ThirdParty />}
      {activeButton === "General Configuration" && <General />}
      {activeButton === "Master Data" && <MasterData />}
      {activeButton === "Multi Currency" && <MultiCurrency />}
     </GridItem>
    </Grid>
   </Box>
  </Layout>
 );
};

export default Settings;
