import userEvent from "@testing-library/user-event";
import AuthService from "../services/authService";
import { getStorage } from "../utils/storage-util";

interface UnknownPayload {
 [s: string]: unknown;
}

export const getAccessToken = async () => {
 var access_token = getStorage("access_token");
 if (access_token) {
  return access_token;
 } else {
  var oidcAuthService = new AuthService();
  if (oidcAuthService.isAuthenticated()) {
   var user = await oidcAuthService.getUser();
   if (user) return user.access_token;
  }
 }
};

export const checkErrorResponse = async ({ response }: any): Promise<any> => {
 let errorMessage = "";
 const clonedResponse = response.error;
 if (response.success === false) {
  try {
   const json = await response.json();
   errorMessage = json.message;
  } catch (error) {
   const text = await clonedResponse.text();
   errorMessage = text;
  }
 }

 return errorMessage;
};

export const setOptions = async (restOptions: UnknownPayload) => {
 var accessToken = await getAccessToken();
 return {
  headers: {
   Accept: "application/json",
   "Content-Type": "application/json;charset=UTF-8",
   Authorization: "Bearer " + accessToken,
  },
  ...restOptions,
 };
};

export const setOptionAuthorization = async (restOptions: UnknownPayload) => {
 var accessToken = await getAccessToken();
 return {
  headers: {
   Authorization: "Bearer " + accessToken,
  },
  ...restOptions,
 };
};

export const isEmpty = <T,>(value: T): boolean => {
 if (value === null) {
  return true;
 } else if (Array.isArray(value)) {
  return value.length === 0;
 }

 switch (typeof value) {
  case "undefined":
   return true;
  case "number":
   return value === 0;
  case "string":
   return value.length === 0;
  case "object":
   return Object.keys(value).length === 0;
 }

 return false;
};

export const removeThousandSeparator = (value: string) => {
 if (typeof value === "string") {
  return parseFloat(value?.replace(/,(?=\d{3})/g, ""));
 } else {
  return value;
 }
};

export const hasDecimal = (number: number) => {
 return !Number.isInteger(number);
};

export const numberWithCommas = (x: number) => {
return String.toFormatSeparator(x.toString())
};
