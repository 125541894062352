import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";

export const getList = createAsyncThunk("myJourney/getList", async (params) => {
 const response: any = await HttpService.get("ProductConfigurations", {
  params,
 });
 return response.result;
});

export const add = createAsyncThunk("myJourney/add", async (payload: any) => {
 const response: any = await HttpService.post(
  `/ProductConfigurations/${payload.type?.replace(/\s/g, "")}`,
  payload
 );
 return response.result;
});

export const edit = createAsyncThunk("myJourney/edit", async (payload: any) => {
 const response: any = await HttpService.put(
  `ProductConfigurations/${payload.id}`,
  payload
 );
 return response.result;
});

// jangan di edit
export const editWithoutStaticProduct = createAsyncThunk(
 "myJourney/editWithoutStaticProduct",
 async (payload: any) => {
  return await HttpService.put("ProductConfigurations/CostDriver", payload);
 }
);

export const addNewCostDriver = createAsyncThunk(
 "myJourney/addNewCostDriver",
 async (payload: any) => {
  return await HttpService.post("ProductConfigurations/CostDriver", payload);
 }
);

export const getById = createAsyncThunk(
 "myJourney/getByLevelName",
 async ({ id }: any) => {
  return await HttpService.get(`ProductConfigurations/${id}`);
 }
);

export const predefineDataConfigurations = createAsyncThunk(
 "myJourney/predefineDataConfigurations",
 async () => {
  return await HttpService.get("ProductConfigurations");
 }
);

export const addCustomField = createAsyncThunk(
 "myJourney/addCustomField",
 async (payload: any) => {
  return await HttpService.post("ProductConfigurations/CustomField", payload);
 }
);

export const editCustomField = createAsyncThunk(
 "myJourney/editCustomField",
 async (payload: any) => {
  return await HttpService.put("ProductConfigurations/CustomField", payload);
 }
);

export const deleteCustomField = createAsyncThunk(
 "myJourney/deleteCustomField",
 async (payload: any) => {
  const response: any = await HttpService.delete(
   "ProductConfigurations/CustomField",
   { data: payload }
  );
  return { success: true, payload: payload };
 }
);

export const deleteCostDriver = createAsyncThunk(
 "myJourney/deleteCostDriver",
 async ({ costDriverId }: any) => {
  return await HttpService.delete("ProductConfigurations/CostDriver", {
   data: { costDriverId },
  });
 }
);

export const getFormulaField = createAsyncThunk(
 "myJouerney/getFormulaField",
 async () => {
  const response: any = await HttpService.get(
   "/ProductConfigurations/FormulaFunctions"
  );
  return response;
 }
);

export const getAllFormulaCostDriver = createAsyncThunk(
 "myJouerney/getAllFormulaCostDriver",
 async (payload: any) => {
  const response: any = await HttpService.get(
   "/ProductConfigurations/FormulaFields",
   {
    params: payload,
   }
  );
  return response.result;
 }
);

export const getFormulaByCostDriver = createAsyncThunk(
 "myJouerney/getFormulaByCostDriver",
 async (payload: any) => {
  const response: any = await HttpService.get(
   "/ProductConfigurations/FormulaFields",
   {
    params: payload,
   }
  );
  return response.result;
 }
);

export const validateFormula = createAsyncThunk(
 "myJourney/validateFormula",
 async (payload: any) => {
    const config = {
        headers: {
         notUseToast: true,
        },
       };
  const response: any = await HttpService.post(
   "/FormulaCustomization/ValidateFormulaCustomization",
   payload,
   config
  );
  return response.result;
 }
);
