import { RootState } from "state/store";
import { FC, useEffect, useState } from "react";
import {
 Box,
 Button,
 Switch,
 Grid,
 Stat,
 StatLabel,
 StatNumber,
 StatHelpText,
 StatArrow,
 Heading,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 TableContainer,
 Table,
 Thead,
 Tr,
 Th,
 Td,
 Tbody,
 Flex,
 Spacer,
} from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import { useAppDispatch } from "hooks";
import { useDisclosure } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import { actions as annualSpendAction } from "state/annualSpend/slice";
import { BarChartLoading, NumberLoading } from "components/Loading";
import { isEmpty, isNull, orderBy, sum } from "lodash";
import Layout from "views/Layout";
import { TreemapChart, BarChart } from "components/ApexChart";
import Magnificiant from "components/Magnificiant";

const Details: FC = () => {
 const { isOpen, onOpen, onClose } = useDisclosure();
 const dispatch = useAppDispatch();
 const router = useHistory();
 const params: any = useParams();
 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);
 let {
  loading,
  barLoading,
  treemapLoading,
  simulationById,
  barChartSimulationById,
  treemapChartSimulationById,
 } = useSelector(
  (state: RootState) => ({
   loading: state.annualSpendSimulations.loading,
   barLoading: state.annualSpendSimulations.barLoading,
   treemapLoading: state.annualSpendSimulations.treemapLoading,
   simulationById: state.annualSpendSimulations.simulationById,
   barChartSimulationById: state.annualSpendSimulations.barChartSimulationById,
   treemapChartSimulationById:
    state.annualSpendSimulations.treemapChartSimulationById,
  }),
  shallowEqual
 );

 const [barSummary, setBarSummary] = useState(null);
 const [treemapSummary, setTeemapSummary] = useState(null);
 const [chartType, setChartType] = useState(false);
 const [thirdPartyType, setThirdPartyType] = useState(false);
 const [isPercentage, setIsPercentage] = useState<boolean>(false);

 useEffect(() => {
  const fetchData = async () => {
   const payload = {
    id: params.id,
    isCurrent: !chartType,
    level: treemapSummary,
   };
   await dispatch(annualSpendAction.simulationById(payload));
   await dispatch(annualSpendAction.getTreemapChartSimulationId(payload));
   await dispatch(annualSpendAction.getBarChartSimulationId(payload));
  };
  fetchData();
 }, []);

 const changeBar = (value: any) => {
  const payload = {
   id: params.id,
   isCurrent: chartType,
   level: value,
  };
  dispatch(annualSpendAction.getBarChartSimulationId(payload));
  setBarSummary(value);
 };
 const changeTreemap = (value: any) => {
  const payload = {
   id: params.id,
   isCurrent: chartType,
   level: value,
  };
  dispatch(annualSpendAction.getTreemapChartSimulationId(payload));
  setTeemapSummary(value);
 };
 const thirdPartyChart = simulationById?.thirdPartySummaryChart;
 const tmpBarThirdParty = thirdPartyChart?.map((x: any) => ({
  x: x.data.map((item: any) => item.identityName),
  y: !thirdPartyType
   ? x.data.map((item: any) => item.currentTotalSpend)
   : x.data.map((item: any) => item.whatIfTotalSpend),
  label: x.thirdPartyDescription,
 }));

 const seriesThirdPartyBar = tmpBarThirdParty?.map((item: any) => ({
  name: item.label,
  data: item.y,
  labelName: item.x,
 }));

 const series =
  !isEmpty(treemapChartSimulationById) &&
  treemapChartSimulationById?.map((x: any) => ({
   name: x.identityName,
   data: x.data?.map((item: any) => ({
    x: item.identityName,
    y: !chartType
     ? isPercentage
       ? (item.currentContribution * 100).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         }) + "%"
       : item.currentTotalSpend
     : isPercentage
     ? (item.whatIfContribution * 100).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
       }) + "%"
     : item.whatIfTotalSpend,
    i: item.identityName,
    z: !chartType ? item.currentTotalSpend : item.whatIfTotalSpend,
    addon: [],
   })),
  }));

 const barDataBaseOnSequence: any = orderBy(
  barChartSimulationById,
  ["sequence"],
  ["asc"]
 );
 const tmpCurrentDataBarChart =
  !isEmpty(barChartSimulationById) &&
  barDataBaseOnSequence.map((x: any) => ({
   x: x.identityName,
   y: x.data?.map((item: any) => item.currentTotalSpend),
   z: x.data?.map((item: any) => item.whatIfTotalSpend),
  }));

 const tmpWhatIfDataBarChart =
  !isEmpty(barChartSimulationById) &&
  barDataBaseOnSequence?.map((x: any) => ({
   x: x.identityName,
   y: x.data?.map((item: any) => item.whatIfTotalSpend),
  }));

 const seriesBar = !isEmpty(barChartSimulationById) && [
  {
   name: "Current",
   data: tmpCurrentDataBarChart?.map((x: any) => ({
    x: x.x,
    y: sum(x.y),
   })),
   labelName: tmpCurrentDataBarChart?.map((x: any) => {
    return x["x"];
   }),
  },
  {
   name: "What-if",
   data: tmpWhatIfDataBarChart.map((x: any) => ({
    x: x.x,
    y: sum(x.y),
   })),
   labelName: tmpWhatIfDataBarChart.map((x: any) => {
    return x["x"];
   }),
  },
 ];

 return (
  <Layout>
   <Heading>Summary</Heading>
   <Box mb="6rem">
    <Grid templateColumns="repeat(4, 1fr)" gap={2} pt="2rem" pb="1rem">
     {!loading ? (
      <Box
       pl="28px"
       pt="28px"
       w="238px"
       h="139px"
       border="1px solid #D7DBE2"
       borderRadius="5px"
      >
       <Stat>
        <StatLabel color="#9DA1A7" fontSize="16px">
         Current total spend
        </StatLabel>
        <StatNumber fontSize="24px">
         €
         {simulationById.currentTotalSpend?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         })}
        </StatNumber>
       </Stat>
      </Box>
     ) : (
      <Box
       pl="28px"
       pt="28px"
       w="238px"
       h="139px"
       border="1px solid #D7DBE2"
       borderRadius="5px"
      >
       <NumberLoading />
      </Box>
     )}

     {!loading ? (
      <Box
       pl="28px"
       pt="29px"
       w="238px"
       h="139px"
       border="1px solid #D7DBE2"
       borderRadius="5px"
      >
       <Stat>
        <StatLabel color="#9DA1A7" fontSize="16px">
         What-if total spend
        </StatLabel>
        <StatNumber fontSize="24px">
         €
         {simulationById.whatIfTotalSpend?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         })}
        </StatNumber>
       </Stat>
      </Box>
     ) : (
      <Box
       pl="28px"
       pt="28px"
       w="238px"
       h="139px"
       border="1px solid #D7DBE2"
       borderRadius="5px"
      >
       <NumberLoading />
      </Box>
     )}
     {!loading ? (
      <Box
       pl="28px"
       pt="28px"
       w="238px"
       h="139px"
       border="1px solid #D7DBE2"
       borderRadius="5px"
      >
       <Stat>
        <StatLabel color="#9DA1A7" fontSize="16px">
         Difference
        </StatLabel>
        <StatNumber fontSize="24px">
         €
         {simulationById.difference?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         })}
        </StatNumber>
        {simulationById.difference <= 0 ? (
         <StatHelpText>
          <StatArrow
           type={
            simulationById.differenceInPercentage >= 0 ? "increase" : "decrease"
           }
           color={simulationById.differenceInPercentage >= 0 ? "red" : "green"}
          />
          {(simulationById.differenceInPercentage * 100)?.toLocaleString(
           undefined,
           {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
           }
          )}
          %
         </StatHelpText>
        ) : (
         <StatHelpText>
          <StatArrow type="increase" color={"red"} />
          {(simulationById.differenceInPercentage * 100)?.toLocaleString(
           undefined,
           {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
           }
          )}
          %
         </StatHelpText>
        )}
       </Stat>
      </Box>
     ) : (
      <Box
       textAlign="center"
       pt="28px"
       w="238px"
       h="139px"
       border="1px solid #D7DBE2"
       borderRadius="5px"
      >
       <NumberLoading />
      </Box>
     )}
    </Grid>
    {simulationById.isEnableThirdParty && (
     <Box border="1px solid #D7DBE2" w="100%" p={4} borderRadius="5px">
      {!loading ? (
       <>
        <Box pl="1rem" pb="2rem">
         <Box alignItems="center" fontWeight="600" mt="1rem" mr="1rem">
          {thirdPartyType ? "What-If " : "Current "}
          <Switch
           defaultChecked={thirdPartyType ? true : false}
           ml="5px"
           onChange={() => setThirdPartyType(!thirdPartyType)}
          />
         </Box>
        </Box>
        {loading ? <BarChartLoading /> : 
        <BarChart data={seriesThirdPartyBar} height={300} type="bar" />}
       </>
      ) : (
       <BarChartLoading />
      )}
     </Box>
    )}
    
    <Box border="1px solid #D7DBE2" w="100%" p={4} my={4} borderRadius="5px">
      <Box pl="1rem" pb="2rem">
       <Flex>
        <Button
         disabled={isNull(barChartSimulationById) ? true : false}
         size={"sm"}
         variant="ghost"
         color={isNull(barSummary) ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeBar(null)}
        >
         Summary
        </Button>
        <Button
         disabled={isNull(barChartSimulationById) ? true : false}
         size={"sm"}
         variant="ghost"
         color={barSummary === "1" ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeBar("1")}
        >
         Level 1
        </Button>
        <Button
         disabled={isNull(barChartSimulationById) ? true : false}
         size={"sm"}
         variant="ghost"
         color={barSummary === "2" ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeBar("2")}
        >
         Level 2
        </Button>
        <Spacer />
        <Magnificiant
         isActive={isEmpty(treemapChartSimulationById) ? true : false}
         data={
          <Box p="1rem">
           <Button
            disabled={isNull(barChartSimulationById) ? true : false}
            size={"sm"}
            variant="ghost"
            color={isNull(barSummary) ? "#0061BD" : "#C3C7CD"}
            onClick={() => changeBar(null)}
           >
            Summary
           </Button>
           <Button
            disabled={isNull(barChartSimulationById) ? true : false}
            size={"sm"}
            variant="ghost"
            color={barSummary === "1" ? "#0061BD" : "#C3C7CD"}
            onClick={() => changeBar("1")}
           >
            Level 1
           </Button>
           <Button
            disabled={isNull(barChartSimulationById) ? true : false}
            size={"sm"}
            variant="ghost"
            color={barSummary === "2" ? "#0061BD" : "#C3C7CD"}
            onClick={() => changeBar("2")}
           >
            Level 2
           </Button>
           {!barLoading && (
            <BarChart data={seriesBar} type="bar" height={600} />
           )}
           {barLoading && <BarChartLoading />}
          </Box>
         }
        />
       </Flex>
      </Box>
      {!barLoading && <BarChart data={seriesBar} height={300} type="bar" />}
      {barLoading && <BarChartLoading />}
     </Box>
     <Box border="1px solid #D7DBE2" w="100%" p={4} my={4} borderRadius="5px">
      <Box>
       <Flex>
        <Button
         disabled={isEmpty(treemapChartSimulationById) ? true : false}
         size={"sm"}
         variant="ghost"
         color={isEmpty(treemapSummary) ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeTreemap(null)}
        >
         Summary
        </Button>
        <Button
         disabled={isEmpty(treemapChartSimulationById) ? true : false}
         size={"sm"}
         variant="ghost"
         color={treemapSummary === "1" ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeTreemap("1")}
        >
         Level 1
        </Button>
        <Button
         disabled={isEmpty(treemapChartSimulationById) ? true : false}
         size={"sm"}
         variant="ghost"
         color={treemapSummary === "2" ? "#0061BD" : "#C3C7CD"}
         onClick={() => changeTreemap("2")}
        >
         Level 2
        </Button>
        <Spacer />
        <Magnificiant
         isActive={isEmpty(treemapChartSimulationById) ? true : false}
         data={
          <Box p="1rem">
           <Button
            disabled={isEmpty(treemapChartSimulationById) ? true : false}
            size={"sm"}
            variant="ghost"
            color={isEmpty(treemapSummary) ? "#0061BD" : "#C3C7CD"}
            onClick={() => changeTreemap(null)}
           >
            Summary
           </Button>
           <Button
            disabled={isEmpty(treemapChartSimulationById) ? true : false}
            size={"sm"}
            variant="ghost"
            color={treemapSummary === "1" ? "#0061BD" : "#C3C7CD"}
            onClick={() => changeTreemap("1")}
           >
            Level 1
           </Button>
           <Button
            disabled={isEmpty(treemapChartSimulationById) ? true : false}
            size={"sm"}
            variant="ghost"
            color={treemapSummary === "2" ? "#0061BD" : "#C3C7CD"}
            onClick={() => changeTreemap("2")}
           >
            Level 2
           </Button>
           <Flex>
            <Box alignItems="center" fontWeight="600" ml="0.8rem">
             {chartType ? "What-If" : "Current"}
             <Switch
              defaultChecked={chartType ? true : false}
              ml="5px"
              onChange={() => setChartType(!chartType)}
             />
            </Box>
            <Box alignItems="center" fontWeight="600" mr="1rem" ml="1rem">
             {isPercentage ? "Percentage" : "Cost Number"}
             <Switch
              defaultChecked={isPercentage}
              ml="5px"
              mr="5px"
              onChange={() => setIsPercentage(!isPercentage)}
             />
            </Box>
           </Flex>
           {!treemapLoading && (
            <TreemapChart data={series} type="treemap" height={600} />
           )}
           {treemapLoading && <BarChartLoading />}
          </Box>
         }
        />
       </Flex>
       <Flex>
        <Box alignItems="center" fontWeight="600" ml="0.8rem">
         {chartType ? "What-If" : "Current"}
         <Switch
          defaultChecked={chartType ? true : false}
          ml="5px"
          onChange={() => setChartType(!chartType)}
         />
        </Box>
        <Box alignItems="center" fontWeight="600" mr="1rem" ml="1rem">
         {isPercentage ? "Percentage" : "Cost Number"}
         <Switch
          defaultChecked={isPercentage}
          ml="5px"
          mr="5px"
          onChange={() => setIsPercentage(!isPercentage)}
         />
        </Box>
       </Flex>
      </Box>
      {!treemapLoading && (
       <TreemapChart data={series} type="treemap" height={300} />
      )}
      {treemapLoading && <BarChartLoading />}
     </Box>

    {!loading && (
     <Box
      position="fixed"
      bottom="0"
      backgroundColor="#ffffff"
      height="5.5rem"
      w="100%"
      zIndex={2}
     >
      <Flex float="right" mr="20rem">
       <Box mt={4}>
        <Flex alignItems="right" gap="2">
         <>
          <Button variant={"outline"} onClick={() => router.goBack()}>
           Back
          </Button>
          <Button onClick={onOpen}>View Changes</Button>
          <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
           <ModalOverlay />
           <ModalContent minW="60%" borderRadius="5px">
            <ModalCloseButton />
            <ModalHeader>Simulation Changes Log</ModalHeader>
            <ModalBody>
             <TableContainer>
              <Table variant="mytable">
               <Thead borderBottom="1px solid #D7DBE2">
                <Tr>
                 <Th>Components</Th>
                 <Th textAlign="right" isNumeric>
                  Change From
                 </Th>
                 <Th textAlign="right" isNumeric>
                  Change To
                 </Th>
                 <Th textAlign="right" isNumeric>
                  % Change
                 </Th>
                </Tr>
               </Thead>
               <Tbody>
                {!isEmpty(barChartSimulationById) &&
                 tmpCurrentDataBarChart?.map((item: any, index: number) => (
                  <Tr key={index}>
                   <Td>{item.x}</Td>
                   <Td textAlign="right" isNumeric>
                    {String.toFormatSeparator(sum(item.y))}
                   </Td>
                   <Td textAlign="right">
                    {String.toFormatSeparator(sum(item.z))}
                   </Td>
                   <Td textAlign="right">
                    <Stat>
                     <StatHelpText>
                      <StatArrow
                       type={
                        ((sum(item.z) - sum(item.y)) / sum(item.y)) * 100 >= 0
                         ? "increase"
                         : "decrease"
                       }
                       color={
                        ((sum(item.z) - sum(item.y)) / sum(item.y)) * 100 >= 0
                         ? "red"
                         : "green"
                       }
                      />
                      {`(${String.toFormatSeparator(
                       ((sum(item.z) - sum(item.y)) / sum(item.y)) * 100
                      )} %)`}
                     </StatHelpText>
                    </Stat>
                   </Td>
                  </Tr>
                 ))}
               </Tbody>
              </Table>
              <Table variant="mytable">
               <Tbody>
                <Tr>
                 <Td width="100vh" textAlign="right" fontWeight={600}>
                  Total spend (Current) :{" "}
                 </Td>
                 <Td textAlign="right" fontWeight={600}>
                  {String.toFormatSeparator(simulationById?.currentTotalSpend)}
                 </Td>
                </Tr>
                <Tr>
                 <Td textAlign="right" fontWeight={600}>
                  Total spend (What-If) :{" "}
                 </Td>
                 <Td textAlign="right" fontWeight={600}>
                  {String.toFormatSeparator(simulationById?.whatIfTotalSpend)}
                 </Td>
                </Tr>
               </Tbody>
              </Table>
             </TableContainer>
            </ModalBody>
           </ModalContent>
          </Modal>
         </>
        </Flex>
       </Box>
      </Flex>
     </Box>
    )}
   </Box>
  </Layout>
 );
};

export default Details;
