import {
 Table as ChakraTable,
 Tbody,
 Td,
 Th,
 Thead,
 Tr,
 Checkbox,
 Input,
 Image,
 Box,
 TableContainer,
} from "@chakra-ui/react";
import { numberWithCommas } from "utils";
import { ReactElement, ReactNode, createElement, FC } from "react";
import PaginationNumber from "components/PaginationNumber";
import DeleteIcon from "assets/images/Delete.png";

interface TableProps<TableData> {
 columnHeaders: {
  name: string;
  label: string;
  alignRight?: boolean;
  align?: string;
  maintenanceWithMasterData?: boolean;
  type: number;
 }[];
 variant?: string;
 size?: string;
 data: TableData[];
 DropdownStatus?: (
  id: string,
  label: string,
  name: string,
  rowData?: TableData,
 ) => ReactNode;
 checkBoxSelect?: (id: string, rowData: TableData) => ReactNode;
 checkBoxSelectAll?: React.MouseEventHandler<HTMLButtonElement>;
 actionButtons?: (id: string, rowData: TableData) => ReactNode;
 actionModal?: (costDrivers: any, rowData: TableData) => ReactNode;
 loading: boolean;
 columnsRender?: {
  [name: string]: FC<
   {
    name: string;
    label: string;
   } & TableData
  >;
 };
 aligmentRightIfNumber?: boolean;
 isEditing?: boolean;
 massUpdate?: boolean;
 totalRowsPage?: number;
 pageNumber?: number;
 currentPage?: number;
 totalAllPage?: number;
 havePagination?: boolean;
 isSelectAll?: boolean;
 isChecked?: boolean;
}

const Table = <TableData,>({
 data,
 checkBoxSelect,
 checkBoxSelectAll = () => {},
 actionButtons,
 actionModal,
 columnHeaders,
 loading,
 columnsRender = {},
 aligmentRightIfNumber = false,
 isEditing = false,
 massUpdate = true,
 totalRowsPage = 0,
 pageNumber = 0,
 currentPage = 0,
 totalAllPage = 0,
 havePagination = false,
 isSelectAll = true,
 isChecked = false,
 variant = "striped",
 size = "md"
}: TableProps<TableData>) => {
 const isColumnNumeric = (headerName: string, tableData: any) => {
  let result = false;
  for (let i = 0; i < tableData.length; i++) {
   let itemData = tableData[i];
   let itemText = itemData[headerName] || "";
   let isColumnNumeric = !isNaN(itemText);
   if (isColumnNumeric) {
    result = true;
   }
   break;
  }
  return result;
 };

 const tableBody = (): ReactElement[] => {
  const updateFieldChanged = (index: number) => (e: any) => {};

  return data.map((row: any, index) => {
   const isDisplay = row?.customFields?.filter(
    (item: any) => item.isDisplayOnPricing !== false
   );
   const newData = { ...row, customFields: isDisplay };
   let dataCostDriver = Object.assign({}, newData);
   Array.isArray(dataCostDriver.customFields) &&
    dataCostDriver.customFields.map((item: any) => {
     if (item?.masterData) {
      dataCostDriver[item.name] = item?.masterData?.description;
     } else {
      if (item?.isMaintenanceByMasterData) {
       dataCostDriver[`${item.name}-From-Masterdata`] = item.value;
      } else {
       dataCostDriver[item.name] = item.value;
      }
     }
    });

   const currentRow: any = new Map(Object.entries(dataCostDriver));
   return (
    <Tr
     borderTop={(index === 0 && "1px solid rgba(26, 26, 26, 0.16)") || "unset"}
     sx={{
      borderCollapse: "collapse",
     }}
    >
     {checkBoxSelect !== null && checkBoxSelect !== undefined && (
      <Td>{checkBoxSelect(currentRow.get("id"), row)}</Td>
     )}
     {columnHeaders.map(({ name, label, maintenanceWithMasterData, type }) => {
      const nameMaintenanceWithMasterdata = maintenanceWithMasterData
       ? `${name}-From-Masterdata`
       : name;
      if (columnsRender?.[name]) {
       const props = {
        nameMaintenanceWithMasterdata,
        label,
        ...row,
       };
       return (
        <Td key={nameMaintenanceWithMasterdata}>
         {createElement(columnsRender[nameMaintenanceWithMasterdata], props)}
        </Td>
       );
      }
      const isNeedSeparator = type === 20 || type === 40 || type === 50;
      return (
       <Td
        key={nameMaintenanceWithMasterdata}
        fontWeight="400"
        color="rgba(85, 88, 94, 1)"
        fontSize="16px"
        sx={{
         textAlign:
          aligmentRightIfNumber &&
          !isNaN(currentRow.get(nameMaintenanceWithMasterdata))
           ? "right"
           : "",
        }}
       >
        {!isNaN(currentRow.get(nameMaintenanceWithMasterdata)) && isEditing ? (
         <Input
          value={currentRow.get(nameMaintenanceWithMasterdata)}
          placeholder="Basic usage"
          maxW="80px"
          textAlign="right"
          onChange={updateFieldChanged(index)}
         />
        ) : isNeedSeparator ? (
         numberWithCommas(currentRow.get(nameMaintenanceWithMasterdata))
        ) : (
         currentRow.get(nameMaintenanceWithMasterdata)
        )}
       </Td>
      );
     })}
     {isEditing && !massUpdate && (
      <Td w="5%">
       <Image
        src={DeleteIcon}
        w="32px"
        maxW="max-content"
        cursor="pointer"
        onClick={() => {}}
       />
      </Td>
     )}
     {actionButtons !== null && actionButtons !== undefined && (
      <Td w="5%">{actionButtons(currentRow.get("id"), row)}</Td>
     )}
     {actionModal !== null && actionModal !== undefined && (
      <Td w="5%">{actionModal(currentRow.get("costDrivers"), row)}</Td>
     )}
    </Tr>
   );
  });
 };

 return (
  <Box>
   {havePagination && (
    <PaginationNumber
     pageNumber={pageNumber}
     currentPage={currentPage}
     totalAllPage={totalAllPage}
     totalRowsPage={totalRowsPage}
    />
   )}

   <Box>
    <TableContainer p="1rem" border="1px solid rgba(26, 26, 26, 0.16)" borderRadius="5px">
    <ChakraTable size={size} variant={variant} >
     <Thead borderBottom="1px solid">
      <Tr>
      {checkBoxSelect !== null && checkBoxSelect !== undefined && (
        <Th>
         <Checkbox
          isChecked={isChecked}
          isDisabled={isSelectAll}
          onChange={(e: any) => {
           checkBoxSelectAll(e);
          }}
         />
        </Th>
       )}
       {columnHeaders &&
        columnHeaders.map(
         ({ name, label, align, maintenanceWithMasterData }, index) => {
          const nameIsMaintenanceWithMasterData = maintenanceWithMasterData
           ? `${name}-From-Masterdata`
           : name;

          return (
           <Th
            key={index}
            pb={"1rem"}
            textTransform="capitalize"
            minW={
             isColumnNumeric(nameIsMaintenanceWithMasterData, data) && isEditing
              ? "80%"
              : ""
            }
            color="rgba(26, 26, 26, 0.36)"
            fontFamily="'Inter', sans-serif !important"
            letterSpacing="normal"
            fontSize="16px"
            fontWeight={700}
            lineHeight="19.36px"
            sx={{
             textAlign: align
              ? align
              : aligmentRightIfNumber &&
                isColumnNumeric(nameIsMaintenanceWithMasterData, data)
              ? "right"
              : "left",
            }}
           >
            {label}
           </Th>
          );
         }
        )}
      </Tr>
     </Thead>
     <Tbody>{!loading && data && data.length > 0 && tableBody()}</Tbody>
    </ChakraTable>
    </TableContainer>
   </Box>
  </Box>
 );
};
export default Table;
