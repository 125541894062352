import { RootState } from "state/store";
import { FC, useEffect, createRef } from "react";
import { useAppDispatch } from "hooks";
import { useHistory } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import OptipediaToast from "utils/notification-util";
import { Box, Button, Stack, Grid, Checkbox, Flex } from "@chakra-ui/react";
import { TextLoading } from "components/Loading";

import { actions as simulateAction } from "state/simulation/slice";

const Component1Overview: FC = () => {
 const dispatch = useAppDispatch();
 const router = useHistory();
 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);
 let { loading, subComponent, subComponentsResponse } = useSelector(
  (state: RootState) => ({
   loading: state.simulationAggregate.loading,
   subComponent: state.simulationAggregate.subComponent,
   subComponentsResponse: state.simulationAggregate.subComponentsResponse,
  }),
  shallowEqual
 );
 const setCheckBox = (isChecked: boolean, value: string) => {
  if (isChecked) {
   dispatch(simulateAction.setSubComponent(value));
  } else {
   dispatch(simulateAction.removeArraySubComponent(value));
  }
 };
 const dataForCheck =
  subComponentsResponse &&
  subComponentsResponse.length !== undefined &&
  subComponentsResponse &&
  subComponentsResponse.filter((item: any) => {
   if (item.isShown) {
    return true;
   } else {
    return false;
   }
  });
 const handleNextStep = () => {
  if (dataForCheck.length !== 0) {
   if (subComponent.length !== 0) {
    dispatch(simulateAction.setDescribe());
    router.push("/simulations/create?page=describe-change");
   } else {
    OptipediaToast.Warning(
     "Please select which information that you have before proceeding to the next step."
    );
   }
  } else {
   dispatch(simulateAction.setDescribe());
   router.push("/simulations/create?page=describe-change");
  }
 };

 return (
  <Box mb="6rem">
   <Box pb="1rem">
    {!loading ? (
     dataForCheck.length == 0 && (
      <Box
       border="1px solid #D7DBE2"
       borderRadius="40px"
       p="2rem"
       textAlign="center"
       fontWeight="700"
       fontSize="18px"
      >
       There is no components that can be simulated. Please proceed to the next
       step directly.
      </Box>
     )
    ) : (
     <Box fontSize="16px" p="0.5rem">
      Select one or more.
     </Box>
    )}
    <Box>
     {!loading ? (
      subComponentsResponse &&
      subComponentsResponse.length !== undefined &&
      subComponentsResponse &&
      subComponentsResponse.map(
       (
        subComponentData: {
         costLabelName: string;
         details: any[];
         identityCode: string;
         identityName: string;
         isShown: boolean;
         sequence: number;
        },
        index: number
       ) => (
        <Box key={index} borderRadius="40px" p="0.5rem">
         {subComponentData.isShown && (
          <Box
           as="p"
           mb="1rem"
           fontWeight="600"
           lineHeight="130%"
           fontSize="18px"
          >
           {loading ? <TextLoading /> : subComponentData.identityName}
          </Box>
         )}
         <Flex
          flexWrap="wrap"
          _after={{
           width: "100%",
          }}
         >
          {subComponentData.details.length !== 0 &&
           subComponentData.details.map(
            (
             detail: {
              costLabelName: string;
              identityName: string;
              identityCode: string;
              sequence: number;
             },
             index: number
            ) => {
             const ref = createRef<HTMLInputElement>();
             return (
              <Box key={index} p="2" fontWeight="500" fontSize="12px">
               <Button
                h="50"
                pl="50"
                pr="50"
                borderRadius="50"
                fontSize="18"
                border="1px"
                borderColor="#0072CF"
                name={detail.identityName}
                color={
                 subComponent.includes(detail.identityCode)
                  ? "white"
                  : "#0072CF"
                }
                onClick={(e: any) => {
                 ref.current?.click();
                }}
                bg={subComponent.includes(detail.identityCode) ? "#0072CF" : ""}
                _disabled={{
                 bgColor: "#C3C7CD",
                 cursor: "not-allowed",
                 color: "white",
                 border: "solid 1px white",
                }}
               >
                {detail.identityName}
               </Button>
               <Checkbox
                display="none"
                ref={ref}
                defaultChecked={subComponent.includes(detail.identityCode)}
                value={detail.identityCode}
                onChange={(val) => {
                 setCheckBox(val.target.checked, val.currentTarget.value);
                }}
               >
                {detail.identityName}
               </Checkbox>
              </Box>
             );
            }
           )}
         </Flex>
        </Box>
       )
      )
     ) : (
      <TextLoading />
     )}
    </Box>
   </Box>
   <Box
    position="fixed"
    bottom="0"
    backgroundColor="#ffffff"
    height="5.5rem"
    w="100%"
    zIndex={2}
   >
    <Flex float="right" mr="20rem">
     <Box mt={4}>
      <Flex alignItems="center" gap="2">
       <>
        <Button
         bg="#C3C7CD"
         w="202px"
         h="59"
         borderRadius="50"
         fontSize="18"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         onClick={() => router.goBack()}
        >
         Back
        </Button>
        <Button
         bg="#0072CF"
         w="202px"
         h="59"
         borderRadius="50"
         fontSize="18"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
         onClick={handleNextStep}
        >
         Next
        </Button>
       </>
      </Flex>
     </Box>
    </Flex>
   </Box>
  </Box>
 );
};

export default Component1Overview;
