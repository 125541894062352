import { ReactElement, useEffect, useState, useMemo } from "react";
import { useAppDispatch, usePrompt } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import debounce from "lodash/debounce";
import Swal from "sweetalert2";

import { Box, Heading, Flex, Grid, GridItem } from "@chakra-ui/layout";
import {
 Button,
 Menu,
 MenuButton,
 MenuList,
 MenuItem,
 Image,
 VStack,
 Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { actions as pricingCostSummaryAction } from "state/pricings/costSummary/slice";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import { actions as approvalListAction } from "state/approval/slice";
import { actions as copyAndReplaceCostDriverAction } from "state/pricings/copyAndReplace/slice";
import Layout from "views/Layout";
import Stepper from "views/EditPricings/Stepper";
import ToolbarBottom from "../ToolbarBottom";
import TableCostDriver from "./TableCostDriver";
import EditTableCostDriver from "./EditTableCostDriver";
import ReplaceCostDriver from "./ReplaceCostDriver";
import CopyTableCostDriver from "./CopyCostDriver";
import SimulateTableCostDriver from "./SimulateTableCostDriver";
import ResultFinalCostTable from "./ResultFinalCostTable";
import GeneralData from "views/EditPricings/GeneralData";
import CostSummary from "views/EditPricings/CostSummary";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import IconBack from "assets/images/Back.png";
import VolumeSelection from "./VolumeSelection";

const CostDrivers = (): ReactElement => {
 const history = useHistory();
 const dispatch = useAppDispatch();
 const location = useLocation<{
  status: string | number;
  volumeId: string;
  isEditing: boolean;
 }>();
 const searchParams = new URLSearchParams(location.search);
 const { costDrivers }: any = useParams();
 const pricingId = location.pathname.split("/")[3];
 const urls = location.pathname.split("/")[2];
 const [tmpCostDriver, setTmpCostDriver] = useState<any>();
 const [tmpOriginalCostDriver, setTmpOriginalCostDriver] = useState<any>();
 const {
  loading,
  volumeId,
  result,
  volumes,
  isEditing,
  isSimulate,
  temporaryDataCostDriver,
  costAnalysis,
  dataToSendUpdates,
  dataTOSendDeletes,
  dataTOSendCustomFields,
  dataToSend,
  originalCostDrivers,
  resultSimulate,
  detailPerVolumeId,
  costAnalysisPerVolumeId,
  tmpChangeRowNoCustomField,
  temporaryData,
  tmpName,
  statusAndVolume,
  pricingDetail,
  levelName,
  isReplace,
  isCopy,
 } = useSelector(
  (state: RootState) => ({
   loading: state.editPricingCostDrivers.loading,
   volumeId: state.editPricingCostDrivers.volumeId,
   result: state.editPricingCostDrivers.detailPerVolumeId.result,
   resultSimulate: state.editPricingCostDrivers.responseSimulate.result,
   result1: state.editPricingCostDrivers.detailPerVolumeId,
   levelName: state.editPricingCostDrivers.levelName,
   volumes: state.pricingCostSummary.costSummaryById.volumes,
   costAnalysis: state.pricingCostSummary.costSummaryById,
   isEditing: state.editPricingCostDrivers.isEditing,
   loadingDownloadReport: state.editPricingCostDrivers.loadingHistoryReport,
   isSimulate: state.editPricingCostDrivers.isSimulate,
   temporaryDataCostDriver:
    state.editPricingCostDrivers.temporaryDataCostDriver,
   originalCostDrivers: state.editPricingCostDrivers.originalCostDrivers,
   dataToSendAdds: state.editPricingCostDrivers.dataToSend.adds,
   dataToSendUpdates: state.editPricingCostDrivers.dataToSend.updates,
   dataTOSendDeletes: state.editPricingCostDrivers.dataToSend.deletes,
   dataTOSendCustomFields: state.editPricingCostDrivers.dataToSend.customFields,
   dataToSend: state.editPricingCostDrivers.dataToSend,
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId,
   costAnalysisPerVolumeId:
    state.editPricingCostDrivers.costAnalysisPerVolumeId,
   tmpChangeRowNoCustomField:
    state.editPricingCostDrivers.tmpChangeRowNoCustomField,
   temporaryData: state.editPricingCostDrivers.temporaryData,
   tmpName: state.editPricingCostDrivers.tmpName,
   dataUpdateToSend: state.editPricingCostDrivers.dataUpdateToSend,
   valueIsIncludeCalculation:
    state.editPricingCostDrivers.valueIsIncludeCalculation,
   statusAndVolume: state.editPricingCostDrivers.statusAndVolume,
   pricingDetail: state.pricingCostSummary.pricingDetail,
   isReplace: state.copyAndReplaceCostDrivers.isReplace,
   isCopy: state.copyAndReplaceCostDrivers.isCopy,
   detailMasterData: state.masterData.detail,
  }),
  shallowEqual
 );
 //  usePrompt(isEditing);
 useEffect(() => {
  const payload = {
   id: pricingId,
   status: location.state.status,
  };
  dispatch(pricingCostSummaryAction.getPricingStatusAndVolume(payload));
  dispatch(pricingCostSummaryAction.pricingThirdParty(payload));
 }, [pricingId]);

 useEffect(() => {
  if (location.state) {
   dispatch(pricingCostDriverAction.setStatusAndVolume(location.state));
  }
  if (location.state.isEditing) {
   dispatch(pricingCostDriverAction.setIsEditing(location.state.isEditing));
  }
  if (Object.keys(costAnalysis).length !== 0) {
   const { currency, format, uoM, description, name } = costAnalysis;
   const data = {
    costAnalysisPerVolumeId: detailPerVolumeId?.result?.id,
    uoMCode: dataToSend?.uoMCode || uoM?.code,
    // currencyCode: dataToSend?.currencyCode || currency?.code,
    currencyId: detailPerVolumeId?.result?.currency?.id || null,
    formatCode: dataToSend?.formatCode || format?.code,
    description: dataToSend?.description || description,
    name: isEditing ? dataToSend?.name || name : dataToSend?.name || "",
    volume: dataToSend?.volume || 0 || result?.volume,
    thirdPartyCode: dataToSend?.thirdPartyCode || "",
    adds: dataToSend.adds,
    updates: dataToSendUpdates,
    deletes: dataTOSendDeletes,
    customFields: dataTOSendCustomFields,
   };

   dispatch(pricingCostDriverAction.setPerVolumeId(data));
  }
 }, [
  costAnalysis,
  searchParams.get("thirdPartyId"),
  detailPerVolumeId,
  location.key,
 ]);

 useEffect(() => {
  if (!isEditing && !costAnalysisPerVolumeId && !isReplace && !isCopy) {
   const payload = {
    id: pricingId,
    status: location.state.status,
   };
   dispatch(pricingCostSummaryAction.pricingCostSummary(payload));
  }
 }, [pricingId, searchParams.get("thirdPartyId")]);

 const debounced = useMemo(
  () =>
   debounce((args) => {
    const simuateFeat = async () => {
     dispatch(pricingCostDriverAction.setValueIsIncludeCalculation(true));
     await dispatch(pricingCostDriverAction.simulate(args));
     dispatch(pricingCostDriverAction.setValueIsIncludeCalculation(false));
    };

    return simuateFeat();
   }, 800),
  [dispatch]
 );

 useEffect(() => {
  if (tmpChangeRowNoCustomField.length > 0 && !temporaryData?.isNew) {
   const indexTmp =
    Array.isArray(tmpChangeRowNoCustomField) &&
    tmpChangeRowNoCustomField.findIndex((item) => item.id === tmpName);
   if (indexTmp !== -1) {
    debounced(dataToSend);
   }
  }
 }, [dataToSendUpdates, tmpChangeRowNoCustomField, temporaryData]);

 useEffect(() => {
  if (dataTOSendDeletes?.length > 0) {
   debounced(dataToSend);
  }
 }, [dataTOSendDeletes]);

 useEffect(() => {
  if (volumeId) {
   const payload = {
    costAnalysisId: pricingId,
    perVolumeId: costAnalysisPerVolumeId || volumeId || location.state.volumeId,
   };
   dispatch(pricingCostDriverAction.getDetailCostDriversVolume(payload));
  }
 }, [volumeId, pricingId, isEditing, volumes]);

 useEffect(() => {
  if (!isEditing && !isReplace && !isCopy) {
   dispatch(pricingCostDriverAction.clear());
  }
 }, [isEditing, isReplace, isCopy]);

 useEffect(() => {
  if (resultSimulate) {
   const toProcess = async () => {
    const currentCostDriverName = urls.replaceAll("-", " ").toUpperCase();
    var costDriver = resultSimulate.costDrivers?.find(
     (i: any) =>
      i.configuration.identityName.toUpperCase() === currentCostDriverName
    );

    if (costDriver && costDriver.costDrivers.length === 0) {
     var tableHeader = generateHeaderTable(costDriver);
     var tableData = [];
     var data = generateDataTable(costDriver, resultSimulate.costAnalysis);

     tableData.push(data);
     var tablePayload = [
      {
       tableName: costDriver.configuration.identityName,
       identityName: costDriver.configuration.identityName,
       sequence: costDriver.configuration.sequence,
       headers: tableHeader,
       data: tableData,
       costDriverId: costDriver.id,
       identityCode: costDriver.identityCode,
       costDriverUrl: costDrivers,
      },
     ];
     const dataTmpCostDriver = {
      name: costDrivers,
      configurationId: costDriver?.configuration?.id,
      data: tablePayload,
      volume: resultSimulate.volume,
     };
     await dispatch(
      pricingCostDriverAction.setTemporaryOriginalDataCostDriver(
       dataTmpCostDriver
      )
     );
    } else {
     var tablePayloadLevel1 = [] as any;
     if (costDriver) {
      costDriver.costDrivers.forEach(function (costLevel1: any) {
       var tableHeader = [] as any;
       var tableData = [];
       if (costLevel1.costDrivers && costLevel1.costDrivers.length > 0) {
        tableHeader = generateHeaderTable(costLevel1.costDrivers[0]);
        costLevel1.costDrivers.map(function (costLevel2: any) {
         var data = generateDataTable(costLevel2, resultSimulate.costAnalysis);
         tableData.push(data);
        });
       } else {
        tableHeader = generateHeaderTable(costLevel1);
        var data = generateDataTable(costLevel1, resultSimulate.costAnalysis);
        tableData.push(data);
       }
       var payload = {
        tableName: costLevel1.configuration.identityName,
        identityName: costLevel1.configuration.identityName,
        sequence: costLevel1.configuration.sequence,
        headers: tableHeader,
        data: tableData,
        costDriverId: costLevel1.id,
        costDriverUrl: costDrivers,
       };
       tablePayloadLevel1.push(payload);
      });
     }

     tablePayloadLevel1 = tablePayloadLevel1.sort((a: any, b: any) =>
      a.sequence < b.sequence ? -1 : 1
     );

     const dataTmpCostDriver = {
      name: costDrivers,
      configurationId: costDriver?.configuration?.id,
      data: tablePayloadLevel1,
      volume: resultSimulate.volume,
     };

     const haveConstDriver =
      originalCostDrivers.length > 0 &&
      originalCostDrivers.find((item: any) => item.name === costDrivers);
     if (haveConstDriver && haveConstDriver.volume === resultSimulate.volume) {
      await dispatch(
       pricingCostDriverAction.setTemporaryOriginalDataCostDriver(
        haveConstDriver
       )
      );
     } else {
      await dispatch(
       pricingCostDriverAction.setTemporaryOriginalDataCostDriver(
        dataTmpCostDriver
       )
      );
     }
    }
   };
   toProcess();
  }
 }, [
  urls,
  resultSimulate,
  searchParams.get("thirdPartyId"),
  statusAndVolume,
  location.key,
 ]);

 useEffect(() => {
  if (result) {
   const modifyRedux = async () => {
    const currentCostDriverName = urls.replaceAll("-", " ").toUpperCase();
    var costDriver = result.costDrivers?.find(
     (i: any) =>
      i.configuration.identityName.toUpperCase() == currentCostDriverName
    );
    if (costDriver && costDriver.costDrivers.length === 0) {
     // Base Level
     var tableHeader = generateHeaderTable(costDriver);
     var tableData = [];
     var data = generateDataTable(costDriver, result.costAnalysis);
     tableData.push(data);
     var tablePayload = [
      {
       tableName: costDriver.configuration.identityName,
       identityName: costDriver.configuration.identityName,
       sequence: costDriver.configuration.sequence,
       headers: tableHeader,
       data: tableData,
       costDriverId: costDriver.id,
       costDriverLenght: costDriver.costDrivers.length,
       canAddNew:
        costDriver.configuration.costDrivers.length > 0 &&
        costDriver.configuration.costDrivers[0].isMainCostDriver === false,
       parentCostDriver: costDriver.parentCostDriver
        ? costDriver.parentCostDriver?.id
        : null,
      },
     ];
     const dataTmpCostDriver = {
      name: costDrivers,
      configurationId: costDriver?.configuration?.id,
      data: tablePayload,
      volume: result.volume,
     };

     dispatch(
      pricingCostDriverAction.setTemporaryDataCostDriver(dataTmpCostDriver)
     );
    } else {
     var tablePayloadLevel1 = [] as any;
     if (costDriver) {
      costDriver.costDrivers.forEach(function (costLevel1: any) {
       var tableHeader = [] as any;
       var tableData = [];
       if (costLevel1.costDrivers && costLevel1.costDrivers.length > 0) {
        tableHeader = generateHeaderTable(costLevel1.costDrivers[0]);
        costLevel1.costDrivers.map(function (costLevel2: any) {
         var data = generateDataTable(costLevel2, result.costAnalysis);
         tableData.push(data);
        });
       } else {
        tableHeader = generateHeaderTable(costLevel1);
        var data = generateDataTable(costLevel1, result.costAnalysis);
        const level20 = detailPerVolumeId?.result?.costAnalysis?.level === 20;
        const level30 = detailPerVolumeId?.result?.costAnalysis?.level === 30;
        // if (costLevel1.configuration.isMainCostDriver && data.name !== "") {
        //  tableData.push(data);
        // }
        tableData.push(data);
        // if (
        //  level20 ||
        //  costLevel1.costDrivers.length > 0 ||
        //  costLevel1.parentCostDriver.configuration.isPercentage ||
        //  (costLevel1.configuration.isMainCostDriver && data.name !== "")
        // ) {
        //  tableData.push(data);
        // }

        // if (
        //  level30 &&
        //  costLevel1.parentCostDriver.configuration.isMainCostDriver &&
        //  costLevel1.parentCostDriver.configuration.identityCode === "LOG"
        // ) {
        //  tableData.push(data);
        // }
        const findIsMainCostDriverFalse = (costDrivers: any) => {
         return costDrivers;
        };

        if (costLevel1.customFields.length === 0) {
         if (
          costLevel1?.configuration.isMainCostDriver &&
          Array.isArray(costLevel1?.configuration.costDrivers) &&
          costLevel1?.configuration.costDrivers.length > 0
         ) {
          if (
           !findIsMainCostDriverFalse(costLevel1?.configuration.costDrivers[0])
            .isMainCostDriver
          ) {
           costLevel1?.configuration.costDrivers[0].customFields.map(
            (item: any) => {
             const customFieldNeedToPush = {
              customFieldId: item.id,
              customFieldConfigurationId: item.id,
              costDriverId: item.costDriver.id,
              costDriverName: item.costDriver.name,
              costDriverDescription: item.costDriver.description,
              predefinedData:
               item.definedData !== ""
                ? {
                   code: item.definedData,
                   description: item.definedData,
                   id: "",
                   configuration: {
                    identityName: item.definedData,
                   },
                  }
                : null,
              costDriverUnitCost: 0,
              isIncludeInCalculation: item.isIncludeInCalculation,
              name: item.name.replace(/[\[\]']+/g, ""),
              realName: item.name,
              isRequired: item.isRequired,
              value: item.isIncludeInCalculation ? 0 : "",
             };
             data.customFields.push(customFieldNeedToPush);
            }
           );
           dispatch(pricingCostDriverAction.setDataEmptyCostDrivers(data));
          }
         }
        }
       }
       const ids = tableData.map(({ id }) => id);
       const filteredTableData = tableData.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
       );

       var payload = {
        tableName: costLevel1.configuration.identityName,
        identityName: costLevel1.configuration.identityName,
        sequence: costLevel1.configuration.sequence,
        headers: tableHeader,
        data: filteredTableData,
        costDriverId: costLevel1.id,
        parentCostDriver: costLevel1.parentCostDriver
         ? costLevel1.parentCostDriver?.id
         : null,
        costDriverUrl: costDrivers,
        identityCode: costLevel1.configuration.identityCode,
        canAddNew:
         costLevel1.configuration.costDrivers.length > 0 &&
         costLevel1.configuration.costDrivers[0].isMainCostDriver === false,
       };
       tablePayloadLevel1.push(payload);
      });
     }

     tablePayloadLevel1 = tablePayloadLevel1.sort((a: any, b: any) =>
      a.sequence < b.sequence ? -1 : 1
     );
     const dataTmpCostDriver = {
      name: costDrivers,
      configurationId: costDriver?.configuration?.id,
      data: tablePayloadLevel1,
      volume: result.volume,
     };

     const haveConstDriver =
      tablePayloadLevel1.length > 0 &&
      tablePayloadLevel1.find((item: any) => item.name === costDrivers);

     if (haveConstDriver && haveConstDriver.volume === result.volume) {
      // setCostDriverTable(haveConstDriver);
      dispatch(
       pricingCostDriverAction.setTemporaryDataCostDriver(haveConstDriver)
      );
     } else {
      // setCostDriverTable(tablePayloadLevel1);
      dispatch(
       pricingCostDriverAction.setTemporaryDataCostDriver(dataTmpCostDriver)
      );
     }
    }
   };
   modifyRedux();
  }
 }, [
  volumeId,
  result,
  urls,
  // setCostDriverTable,
  isEditing,
  isReplace,
  isCopy,
  searchParams.get("thirdPartyId"),
  statusAndVolume,
  location.key,
 ]);

 const onClickDownload = () => {
  dispatch(
   pricingCostDriverAction.historyReport({
    costAnalysisId: pricingId,
    costaAnalysisName: costAnalysis.name,
    status: statusAndVolume.status,
   })
  );
 };

 const generateHeaderTable = (costDriver: any) => {
  var tableHeader: any = [];
  tableHeader.push({
   name: "name",
   convertName: "name",
   label: costDriver["configuration"]["labelName"],
   isRequired: false,
  });
  tableHeader.push({
   name: "description",
   convertName: "description",
   label: costDriver["configuration"]["labelDescription"],
   isRequired: false,
  });

  if (costDriver.customFields && costDriver.customFields.length > 0) {
   costDriver.customFields.forEach(function (customField: any) {
    console.log("customField", customField);
    tableHeader.push({
     name: customField.configuration.name,
     convertName: customField.configuration.name.replace(/[\[\]']+/g, ""),
     label: customField.configuration.displayName,
     isDisplayOnPricing: customField.configuration.isDisplayOnPricing,
     maintenanceWithMasterData: false,
     isRequired: customField?.configuration?.isRequired,
     type: customField?.configuration?.type,
    });
   });
  }
  if (costDriver.masterData && costDriver.masterData.customFields.length > 0) {
   costDriver.masterData.customFields.forEach(function (customField: any) {
    tableHeader.push({
     name: `${customField.configuration.name}`,
     convertName: customField.configuration.name.replace(/[\[\]']+/g, ""),
     label: customField.configuration.displayName,
     isDisplayOnPricing: customField.configuration.isDisplayOnPricing,
     maintenanceWithMasterData: true,
     sequence: customField.configuration.sequence,
     isRequired: customField?.configuration?.isRequired,
     type: customField?.configuration?.type,
    });
   });
  }

  tableHeader = tableHeader.sort((a: any, b: any) =>
   a.sequence < b.sequence ? -1 : 1
  );

  const findIsMainCostDriverFalse = (costDrivers: any) => {
   return costDrivers;
  };

  if (costDriver.customFields.length === 0) {
   if (
    costDriver?.configuration.isMainCostDriver &&
    Array.isArray(costDriver?.configuration.costDrivers) &&
    costDriver?.configuration.costDrivers.length > 0
   ) {
    if (
     !findIsMainCostDriverFalse(costDriver?.configuration.costDrivers[0])
      .isMainCostDriver
    ) {
     costDriver?.configuration.costDrivers[0].customFields.map((item: any) => {
      const customFieldNeedToPush = {
       name: item.name,
       convertName: item.name.replace(/[\[\]']+/g, ""),
       label: item.displayName,
       isDisplayOnPricing: item.isDisplayOnPricing,
      };
      tableHeader.push(customFieldNeedToPush);
     });
    } else {
    }
   }
  }

  if (!costDriver.configuration.isPercentage) {
   tableHeader.push({
    name: "currency",
    label: "Currency",
    convertName: "currency",
   });
  }

  tableHeader.push({
   name: "unitCost",
   convertName: "unitCost",
   label: costDriver["configuration"]["labelCost"],
  });
  if (!costDriver.configuration.isPercentage) {
   tableHeader.push({
    name: "totalCost",
    label: costDriver.configuration.labelTotalCost,
    convertName: "totalCost",
   });
  }

  tableHeader.push({
   name: "exchangeRateToCurrency",
   convertName: "exchangeRateToCurrency",
   label: `Exchange Rate to ${"IDR"}`,
   isRequired: false,
  });

  tableHeader.push({
   name: "totalCostToCurrency",
   convertName: "totalCostToCurrency",
   label: `Total Cost in ${"IDR"}`,
   isRequired: false,
  });

  return tableHeader;
 };

 const generateDataTable = (costDriver: any, costAnalysis: any) => {
  var data = {
   id: costDriver.id,
   identityCode:
    costDriver.parentCostDriver?.configuration.identityCode ||
    costDriver.identityCode,
   name: costDriver.name,
   description: costDriver.description,
   currency: costAnalysis.currencyCode,
   currencyId: costDriver.currency.id,
   unitCost: String.toFormatSeparator(costDriver.unitCost) || 0,
   //  mas salih
   totalCost: `${String.toFormatSeparator(costDriver.totalCost)}`,
   customFields: [],
   masterDataCustomFields: [],
   masterDataConfiguration: costDriver.masterData,
   masterDataId: costDriver.masterData ? costDriver.masterData.id : "",
   constDriverId: costDriver.configuration.isPercentage
    ? costDriver.id
    : costDriver?.parentCostDriver?.id,
  } as any;

  data.unitCost = data.unitCost;
  data.isNew = costDriver.creatorUserId === null;
  data.isErrorDuplicate = costDriver.creatorUserId === null && false;
  data.totalCostPricing = costDriver.totalCostPricing;
  data.convertedCurrency = costDriver.pricingCurrency;
  let exchangeRateToCurrency = 0;
  data.exchangeRateToCurrency = exchangeRateToCurrency;
  data.totalCostToCurrency = costDriver.totalCostCurrency;
  // data.currencyId = costDriver.currency.id;

  data.currencyConversionExchangeRate = costDriver.pricingCurrencyExchangeRate;
  if (data.masterData !== null) {
   data.currency = costDriver.currency.code;
  } else {
   data.currency = costDriver?.masterData?.currencyCode;
  }

  if (costDriver.customFields.length > 0) {
   costDriver.customFields.forEach(function (customField: any) {
    const valueData = customField.configuration.isIncludeInCalculation
     ? parseFloat(customField.value.replace(/,/g, "")).toLocaleString("en-US")
     : customField.value;
    // console.log(customField);

    const isNeedRounding =
     customField.configuration.type === 20 ||
     customField.configuration.type === 40 ||
     customField.configuration.type === 50;

    data.customFields.push({
     customFieldId: customField.id,
     customFieldConfigurationId: customField.configuration.id,
     costDriverId: customField.costDriver.id,
     costDriverName: customField.costDriver.name,
     costDriverDescription: customField.costDriver.description,
     masterData: customField.masterData,
     costDriverUnitCost: customField.costDriver.unitCost,
     isIncludeInCalculation: customField.configuration.isIncludeInCalculation,
     name: customField.configuration.name.replace(/[\[\]']+/g, ""),
     realName: customField.configuration.name,
     isRequired: customField.configuration.isRequired,
     isDisplayOnPricing: customField.configuration.isDisplayOnPricing,
     exchangeRateId: customField?.exchangeRate?.id,
     totalCostPricing: 0,
     totalCostCurrency: 0,
     typeData: customField.configuration.type,
     description: customField?.masterData?.description,
     isMaintenanceByMasterData: false,
     sequence: customField?.sequence,
     currencyCode: customField?.currency?.code,
     currencyId: customField?.currency?.id,
     value: customField.configuration.isIncludeInCalculation
      ? parseFloat(customField.value.replace(/,/g, "")).toLocaleString("en-US")
      : customField.value,
    });
   });
  }

  if (costDriver?.masterData?.customFields.length > 0) {
   costDriver?.masterData?.customFields.forEach(function (customField: any) {
    const isNeedRounding =
     customField.configuration.dataType === 20 ||
     customField.configuration.dataType === 40 ||
     customField.configuration.dataType === 50;
    data.customFields.push({
     name: `${customField.configuration.name.replace(/[\[\]']+/g, "")}`,
     realName: customField.configuration.name,
     isRequired: customField.configuration.isRequired,
     isDisplayOnPricing: customField.configuration.isDisplayOnPricing,
     typeData: customField.configuration.dataType,
     description: customField?.masterDataReference?.description,
     value: isNeedRounding
      ? parseFloat(parseFloat(customField.value).toFixed(5))
      : customField.value,
     isMaintenanceByMasterData: true,
     sequence: customField?.configuration?.sequence,
     currencyCode: customField.currencyCode,
     currencyId: customField?.currency?.id,
     exchangeRateCurrencyId: customField.exchangeRateCurrencyId,
    });
   });
  }
  const findIsMainCostDriverFalse = (costDrivers: any) => {
   return costDrivers;
  };
  if (costDriver.customFields.length === 0) {
   if (
    costDriver?.configuration.isMainCostDriver &&
    Array.isArray(costDriver?.configuration.costDrivers) &&
    costDriver?.configuration.costDrivers.length > 0
   ) {
    if (
     !findIsMainCostDriverFalse(costDriver?.configuration.costDrivers[0])
      .isMainCostDriver
    ) {
     // joko test
     costDriver?.configuration.costDrivers[0].customFields.map((item: any) => {
      let customFieldNeedToPush: any = {
       customFieldId: item.id,
       customFieldConfigurationId: item.id,
       costDriverId: item.costDriver.id,
       costDriverName: item.costDriver.name,
       costDriverDescription: item.costDriver.description,
       predefinedData:
        item.definedData !== ""
         ? {
            code: item.definedData,
            description: item.definedData,
            id: "",
            configuration: {
             identityName: item.definedData,
            },
           }
         : null,
       costDriverUnitCost: 0,
       isIncludeInCalculation: item.isIncludeInCalculation,
       name: item.name.replace(/[\[\]']+/g, ""),
       realName: item.name,
       isRequired: item.isRequired,
       isDisplayOnPricing: item.isDisplayOnPricing,
       value: item.isIncludeInCalculation ? 0 : "",
      };
     });
    }
   }
  }
  return data;
 };
 useEffect(() => {
  const ids: any =
   Array.isArray(temporaryDataCostDriver) &&
   temporaryDataCostDriver.map((o: any) => o.name);
  const filtered =
   Array.isArray(temporaryDataCostDriver) &&
   temporaryDataCostDriver.filter(
    ({ name }: any, index: any) => !ids.includes(name, index + 1)
   );
  setTmpCostDriver(filtered);
 }, [
  temporaryDataCostDriver,
  result,
  costDrivers,
  volumeId,
  searchParams.get("thirdPartyId"),
  location.key,
 ]);

 let itemCostDriver =
  Array.isArray(tmpCostDriver) &&
  tmpCostDriver.length > 0 &&
  tmpCostDriver.find((costDriver: any) => {
   if (costDriver.name === costDrivers) {
    return costDriver;
   }
  });

 useEffect(() => {
  dispatch(pricingCostDriverAction.setConstDrivers(itemCostDriver));
 }, [itemCostDriver, resultSimulate, searchParams.get("thirdPartyId")]);

 const itemOriginalCostDriver =
  Array.isArray(tmpOriginalCostDriver) &&
  tmpOriginalCostDriver.length > 0 &&
  tmpOriginalCostDriver.find((costDriver: any) => {
   if (costDriver.name === costDrivers) {
    return costDriver;
   }
  });

 useEffect(() => {
  const ids = originalCostDrivers.map((o: any) => o.name);
  const filtered = originalCostDrivers.filter(
   ({ name }: any, index: any) => !ids.includes(name, index + 1)
  );
  setTmpOriginalCostDriver(filtered);
 }, [originalCostDrivers, resultSimulate, searchParams.get("thirdPartyId")]);

 const filteredData: any = itemCostDriver?.data?.reduce(
  (acc: any, obj: any) => {
   const itemFiltered = obj?.data?.filter(
    (data: any) => data.customFields.length
   );
   if (itemFiltered.length) acc.push({ ...obj, orderedItems: itemFiltered });
   else {
    const itemFiltered = obj?.data?.filter(
     (data: any) => data.totalCost !== "0"
    );
    if (itemFiltered.length) acc.push({ ...obj, orderedItems: itemFiltered });
   }
   return acc;
  },
  []
 );
 useEffect(() => {
  dispatch(pricingCostDriverAction.setConstDriversOri(itemOriginalCostDriver));
 }, [itemCostDriver, searchParams.get("thirdPartyId")]);

 useEffect(() => {
  const actionChangeRedux = async () => {
   const findCostDriver =
    Array.isArray(temporaryDataCostDriver) &&
    temporaryDataCostDriver.find((item: any) => item.name === costDrivers);
   Array.isArray(temporaryDataCostDriver) &&
    temporaryDataCostDriver.forEach((state: any) => {
     let itemState = Object.assign({}, state);
     itemState.data =
      Array.isArray(state.data) &&
      state.data.map((item: any) => {
       dataToSendUpdates?.map((updateItem: any) => {
        const exitingConfigurationName = item?.data?.find(
         (item: any) => item.id === updateItem.costDriverId
        );
        if (exitingConfigurationName) {
         let data = Object.assign({}, exitingConfigurationName);
         data.name = updateItem.name;
         data.description = updateItem.description;
         data.unitCost = updateItem.unitCost;
         const newArr: any = [];

         item.data.map((object: any) => {
          const findData =
           Array.isArray(dataToSendUpdates) &&
           dataToSendUpdates?.find((it: any) => it?.costDriverId === object.id);
          if (findData) {
           let costDriver = Object.assign({}, object);
           const newCostdriver: any = [];
           costDriver?.customFields.map((item: any) => {
            let dataCostDriver = Object.assign({}, item);
            const haveCustomField = findData.customFields.find(
             (i: any) => i.customFieldId === item.id
            );
            dataCostDriver.value = haveCustomField.value;
            newCostdriver.push(dataCostDriver);
            costDriver[dataCostDriver.name] = haveCustomField.value;
           });

           costDriver.name = findData.name;
           costDriver.description = findData.description;
           costDriver.unitCost = findData.unitCost;

           costDriver.customFields = newCostdriver;

           newArr.push(costDriver);
          } else {
           newArr.push(object);
          }
         });

         let mergeObject = Object.assign({}, item);
         mergeObject.data = newArr;
         for (let i = 0; i < temporaryDataCostDriver.length; i++) {
          if (
           temporaryDataCostDriver[i]?.configurationId ===
           findCostDriver?.configurationId
          ) {
           const newDataToPush: any = [];

           Array.isArray(temporaryDataCostDriver[i].data) &&
            temporaryDataCostDriver[i].data.map((tmpCostDriver: any) => {
             if (mergeObject.costDriverId === tmpCostDriver.costDriverId) {
              newDataToPush.push(mergeObject);
             } else {
              newDataToPush.push(tmpCostDriver);
             }
            });
          }
         }
        }
       });
      });
    });
  };
  actionChangeRedux();
 }, [
  dataToSendUpdates,
  isEditing,
  isReplace,
  isCopy,
  searchParams.get("thirdPartyId"),
 ]);

 const handleDelete = () => {
  Swal.fire({
   showCancelButton: true,
   allowOutsideClick: false,
   html: "Are you sure you want to delete this Pricing volume?",
   confirmButtonText: "Yes",
   cancelButtonText: "No",
  }).then(async (result) => {
   if (result.isConfirmed) {
    const payload = {
     costAnalysisId: pricingId,
     perVolumeId:
      location.state.volumeId || volumeId || costAnalysisPerVolumeId,
    };
    await dispatch(
     pricingCostDriverAction.deleteDetailCostAnalysisVolume(payload)
    ).then(async (response: any) => {
     if (response?.payload) {
      history.push("/pricings");
      window.location.reload();
     }
    });
   }
  });
 };
 //  usePrompt(isEditing);
 return (
  <Layout>
   <Flex gap={4}>
    {!isEditing && !isCopy && !isReplace && (
     <Image
      cursor={"pointer"}
      onClick={() => {
       if (urls === "cost-summary-by-sku") {
        dispatch(pricingCostDriverAction.setIsEditing(false));
        history.push("/pricings");
       } else {
        history.push("/pricings");
       }
      }}
      src={IconBack}
      alt="iconBack"
     />
    )}
    <Heading textTransform={"capitalize"}>
     {urls === "cost-summary-by-sku" && "Cost Summary"}
     {urls === "sku-characteristic" && "SKU Characteristic"}
     {urls !== "cost-summary-by-sku" &&
      urls !== "sku-characteristic" &&
      urls.replaceAll("-", " ")}
    </Heading>
    <Flex zIndex={2}>
     {isSimulate ||
      (!isEditing && !isReplace && !isCopy && (
       <Menu>
        <MenuButton
         as={Button}
         disabled={
          isEmpty(costAnalysis)
           ? true
           : false || loading || isEmpty(temporaryDataCostDriver)
         }
         bg="#0072CF"
         color="white"
         size="sm"
         borderRadius={"5px"}
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)", color: "white" }}
         rightIcon={<ChevronDownIcon />}
        >
         Option
        </MenuButton>
        <MenuList>
         {statusAndVolume.status === 0 && (
          <MenuItem
           onClick={() => {
            dispatch(pricingCostDriverAction.setIsEditing(true));
            dispatch(pricingCostDriverAction.setDisable(true));
           }}
          >
           Edit
          </MenuItem>
         )}
         {statusAndVolume.status === 2 && (
          <MenuItem
           onClick={async () => {
            if (statusAndVolume.status === 0) {
             return dispatch(pricingCostDriverAction.setIsEditing(true));
            }
            if (pricingDetail?.length > 0) {
             const findVolume = pricingDetail?.filter(
              (item: any) => item.volume === detailPerVolumeId?.result?.volume
             );
             const findThirdParty = findVolume?.filter(
              (item: any) =>
               item.thirdParty?.code ===
               detailPerVolumeId?.result?.thirdParty?.code
             );
             const findDraft = detailPerVolumeId?.result?.thirdParty
              ? findThirdParty?.find((item: any) => item.status === 0)
              : findVolume?.find((item: any) => item.status === 0);
             if (findDraft) {
              Swal.fire({
               showDenyButton: true,
               showCloseButton: true,
               allowOutsideClick: false,
               denyButtonColor: "#bcbfc4",
               html:
                "You already have a draft for this pricing. Do you want to continue edit for your draft pricing or overwrite your old draft pricing?",
               confirmButtonText: "Continue my Draft",
               denyButtonText: "Overwrite my Draft",
              }).then(async (result) => {
               if (result.isConfirmed) {
                dispatch(
                 pricingCostDriverAction.setCostAnalysisPerVolumeId(
                  findDraft.id ||
                   volumeId ||
                   location.state.volumeId ||
                   costAnalysisPerVolumeId
                 )
                );
                if (findDraft.id) {
                 const payload = {
                  costAnalysisId: pricingId,
                  perVolumeId:
                   findDraft.id ||
                   volumeId ||
                   location.state.volumeId ||
                   costAnalysisPerVolumeId,
                 };

                 dispatch(
                  pricingCostDriverAction.getDetailCostDriversVolume(payload)
                 );
                 dispatch(
                  pricingCostDriverAction.setVolumeId(
                   findDraft.id ||
                    volumeId ||
                    location.state.volumeId ||
                    costAnalysisPerVolumeId
                  )
                 );
                }
                history.push({
                 pathname: `/pricings/cost-summary-by-sku/${pricingId}`,
                 state: {
                  status: findDraft.status,
                  volumeId: findDraft.id || location.state.volumeId || volumeId,
                  isEditing: true,
                 },
                });
                window.location.reload();
               }
               if (result.isDenied) {
                dispatch(pricingCostDriverAction.setIsEditing(true));
               } else {
                dispatch(pricingCostDriverAction.setIsEditing(false));
               }
              });
             } else {
              dispatch(pricingCostDriverAction.setIsEditing(true));
             }
            } else {
             dispatch(pricingCostDriverAction.setIsEditing(true));
            }
           }}
          >
           Edit
          </MenuItem>
         )}
         {statusAndVolume.status === 0 && (
          <MenuItem
           onClick={async () => {
            if (pricingDetail?.length > 0) {
             const findVolume = pricingDetail?.filter(
              (item: any) => item.volume === detailPerVolumeId?.result?.volume
             );

             const findThirdParty = findVolume?.filter(
              (item: any) =>
               item.thirdParty?.code ===
               detailPerVolumeId?.result?.thirdParty?.code
             );

             const findAPendingApproval = detailPerVolumeId?.result?.thirdParty
              ? findThirdParty?.find((item: any) => item.status === 1)
              : findVolume?.find((item: any) => item.status === 1);
             const payload = {
              status: location.state.status || statusAndVolume.status,
              ids: [location.state.volumeId || volumeId],
             };
             findAPendingApproval
              ? Swal.fire({
                 showCancelButton: true,
                 allowOutsideClick: false,
                 html:
                  "You already have a pending approval for this pricing. Do you want to remove it and submit a new pending approval pricing?",
                 confirmButtonText: "Yes",
                 cancelButtonText: "No",
                }).then(async (result) => {
                 if (result.isConfirmed) {
                  await dispatch(
                   approvalListAction.approvalSubmit(payload)
                  ).then(() => {
                   history.push({
                    pathname: `/pricings/${urls}/${pricingId}`,
                    state: {
                     status: 1,
                     volumeId: null,
                    },
                   });
                   window.location.reload();
                  });
                 }
                })
              : await dispatch(approvalListAction.approvalSubmit(payload)).then(
                 () => {
                  history.push({
                   pathname: `/pricings/${urls}/${pricingId}`,
                   state: {
                    status: 1,
                    volumeId: null,
                   },
                  });
                  window.location.reload();
                 }
                );
            }
           }}
          >
           Send Approval
          </MenuItem>
         )}
         {statusAndVolume.status === 2 && levelName !== "Does Cost" && (
          <MenuItem
           onClick={async () => {
            await history.push(
             `/simulation-by-sku/sku-characteristic/${pricingId}?volumeId=${detailPerVolumeId?.result?.id}`,
             { status: statusAndVolume.status }
            );
            dispatch(pricingCostDriverAction.setIsSimulate(true));
           }}
          >
           Simulate
          </MenuItem>
         )}
         {statusAndVolume.status === 2 && (
          <MenuItem onClick={() => onClickDownload()}>History Report</MenuItem>
         )}
         {statusAndVolume.status !== 1 && (
          <MenuItem
           onClick={() =>
            dispatch(copyAndReplaceCostDriverAction.setIsCopy(true))
           }
          >
           Copy
          </MenuItem>
         )}
         {statusAndVolume.status === 2 && (
          <MenuItem
           onClick={() =>
            dispatch(copyAndReplaceCostDriverAction.setIsReplace(true))
           }
          >
           Replace
          </MenuItem>
         )}
         {statusAndVolume.status === 3 && (
          <MenuItem onClick={() => onClickDownload()}>History Report</MenuItem>
         )}
         {(statusAndVolume.status === 2 || statusAndVolume.status === 0) && (
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
         )}
         {statusAndVolume.status === 1 && (
          <MenuItem
           onClick={async () => {
            Swal.fire({
             showCancelButton: true,
             allowOutsideClick: false,
             html:
              "Are you sure you want to approve the selected pricing volume?",
             confirmButtonText: "Yes",
             cancelButtonText: "No",
            }).then(async (result) => {
             if (result.isConfirmed) {
              const payload = {
               status: location.state.status,
               ids: [costAnalysisPerVolumeId || volumeId],
              };
              dispatch(approvalListAction.approvalSubmit(payload)).then(
               (response: any) => {
                history.push({
                 pathname: `/pricings/${urls}/${pricingId}`,
                 state: {
                  status: 2,
                  volumeId: costAnalysisPerVolumeId || volumeId,
                 },
                });
                window.location.reload();
               }
              );
             }
            });
           }}
          >
           Approve
          </MenuItem>
         )}
         {statusAndVolume.status === 1 && (
          <MenuItem
           onClick={async () => {
            Swal.fire({
             showCancelButton: true,
             allowOutsideClick: false,
             html:
              "Are you sure you want to reject the selected pricing volume?",
             confirmButtonText: "Yes",
             cancelButtonText: "No",
            }).then(async (result) => {
             if (result.isConfirmed) {
              const payload = {
               status: statusAndVolume.status,
               ids: [location.state.volumeId || volumeId],
              };
              await dispatch(approvalListAction.approvalReject(payload)).then(
               () => {
                history.push({
                 pathname: `/pricings/${urls}/${pricingId}`,
                 state: {
                  status: 0,
                  volumeId: null,
                 },
                });
                window.location.reload();
               }
              );
             }
            });
           }}
          >
           Reject
          </MenuItem>
         )}
        </MenuList>
       </Menu>
      ))}
     {isEditing && <Heading textTransform="capitalize">- Edit</Heading>}

     {isReplace && <Heading textTransform="capitalize">- Replace</Heading>}
     {isCopy && <Heading textTransform="capitalize">- Copy</Heading>}
    </Flex>
   </Flex>
   <Stepper />
   <Grid templateColumns="repeat(6, 1fr)" gap={4}>
    <GridItem colSpan={5}>
     <Box pt="2.188rem" mb="3rem">
      {!isEditing &&
       !isSimulate &&
       !isReplace &&
       !isCopy &&
       Array.isArray(itemCostDriver?.data) &&
       itemCostDriver?.data?.map((item: any, index: number) => {
        return (
         <Box key={index}>
          <TableCostDriver costDriver={item} />
         </Box>
        );
       })}

      {isEditing &&
       itemCostDriver &&
       Array.isArray(itemCostDriver.data) &&
       itemCostDriver.data.length > 0 &&
       itemCostDriver?.data.map((item: any, index: number) => {
        return (
         <Box key={index}>
          <EditTableCostDriver
           costDriver={item}
           paramName={costDrivers}
           level={costAnalysis.level}
          />
         </Box>
        );
       })}
      {isReplace &&
       itemCostDriver &&
       Array.isArray(itemCostDriver.data) &&
       itemCostDriver.data.length > 0 &&
       itemCostDriver?.data.map((item: any, index: number) => {
        return (
         <Box key={index}>
          <ReplaceCostDriver
           costDriver={item}
           paramName={costDrivers}
           level={costAnalysis.level}
          />
         </Box>
        );
       })}
      {isCopy &&
       itemCostDriver &&
       Array.isArray(itemCostDriver.data) &&
       itemCostDriver.data.length > 0 &&
       itemCostDriver?.data.map((item: any, index: number) => {
        return (
         <Box key={index}>
          <CopyTableCostDriver
           costDriver={item}
           paramName={costDrivers}
           level={costAnalysis.level}
          />
         </Box>
        );
       })}
      {isSimulate &&
       itemCostDriver?.data.map((item: any, index: number) => {
        return (
         <Box key={index}>
          <SimulateTableCostDriver
           costDriver={item}
           paramName={costDrivers}
           level={costAnalysis.level}
          />
         </Box>
        );
       })}
      {!isSimulate &&
       !isEditing &&
       !isReplace &&
       !isCopy &&
       urls !== "sku-characteristic" &&
       urls !== "cost-summary-by-sku"}
      {urls === "sku-characteristic" && <GeneralData />}
      {urls === "cost-summary-by-sku" && <CostSummary />}
      {urls === "final-cost" && <ResultFinalCostTable />}
     </Box>
    </GridItem>
    <GridItem colSpan={1}>
     <VStack spacing={4} align="stretch">
      <VolumeSelection />
      <Box pl="1rem">
       <Text fontWeight={600} mb="3">
        Cost Summary
       </Text>
       <Box border="1px solid #D7DBE2" p="1rem" borderRadius="5px">
        <VStack spacing={5} align="stretch">
         <VStack spacing={1} align="stretch">
          <Text fontSize="16px" color="rgba(26, 26, 26, 0.36)">
           Volume
          </Text>
          <Text
           fontSize="16px"
           color="
rgba(26, 26, 26, 1)"
           fontWeight="700"
          >
           {loading ||
           costAnalysis?.configuration?.sizeUnit?.convertion === undefined
            ? "Loading..."
            : costAnalysis?.configuration?.sizeUnit?.convertion?.toLocaleString(
               undefined,
               {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
               }
              )}
          </Text>
         </VStack>
         <VStack spacing={1} align="stretch">
          <Text fontSize="16px" color="rgba(26, 26, 26, 0.36)">
           Pricing Currency
          </Text>
          <Text
           fontSize="16px"
           color="
rgba(26, 26, 26, 1)"
           fontWeight="700"
          >
           {loading || result?.currency?.code === undefined
            ? "Loading..."
            : result?.currency?.code}
          </Text>
         </VStack>
         <VStack spacing={1} align="stretch">
          <Text fontSize="16px" color="rgba(26, 26, 26, 0.36)">
           Final Price
          </Text>
          <Text
           fontSize="16px"
           color="
rgba(26, 26, 26, 1)"
           fontWeight="700"
          >
           {loading || result?.unitCost === undefined
            ? "Loading..."
            : result?.unitCost?.toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
              })}
          </Text>
         </VStack>
         <VStack spacing={1} align="stretch">
          <Text fontSize="16px" color="rgba(26, 26, 26, 0.36)">
           Total Cost in Company Currency
          </Text>
          <Text
           fontSize="16px"
           color="
rgba(26, 26, 26, 1)"
           fontWeight="700"
          >
           {loading || result?.totalCostCompany?.currency?.code === undefined
            ? "Loading..."
            : `${
               result?.totalCostCompany?.currency?.code
              } ${result?.totalCostCompany?.amount.toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
              })}`}
          </Text>
         </VStack>
        </VStack>
       </Box>
      </Box>
     </VStack>
    </GridItem>
   </Grid>

   {volumes && volumes.length > 0 && (
    <ToolbarBottom costAnalysis={volumes[0]} costAnalysisId={pricingId} />
   )}
  </Layout>
 );
};

export default CostDrivers;
