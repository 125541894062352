import { FC, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/layout";
import { Button, Text, Spacer } from "@chakra-ui/react";
import { Formik } from "formik";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { actions as thirdPartySettingAction } from "state/settings/slice";
import { RootState } from "state/store";
import TextInput from "components/TextInput";
import * as Yup from "yup";
interface Values {
 code: string;
 description: string;
}

const PredefinedThirdPartyEdited: FC = () => {
 let { loading, thirdPartyUserById, thirdPartyById } = useSelector(
  (state: RootState) => ({
   loading: state.thirdPartySetting.loading,
   thirdPartyUserById: state.thirdPartySetting.thirdPartyUserById,
   thirdPartyById: state.thirdPartySetting.thirdPartyById,
  }),
  shallowEqual
 );
 const history = useHistory();
 const location = useLocation();
 const dispatch = useAppDispatch();
 const validation = Yup.object().shape({
  code: Yup.string().required("This information are required"),
  description: Yup.string().required("This information are required"),
 });

 useEffect(() => {
  dispatch(thirdPartySettingAction.getThirdPartyById(location.state));
 }, []);
 return (
  <Box w="40%">
   <Formik
    enableReinitialize
    initialValues={
     thirdPartyById || {
      code: "",
      description: "",
     }
    }
    validationSchema={validation}
    onSubmit={async (values: Values) => {
     let payload = {
      id: location.state,
      data: values,
     };
     await dispatch(thirdPartySettingAction.editThirdPartyById(payload));
     history.push("/settings/third-party");
    }}
   >
    {({ handleSubmit }) => (
     <form onSubmit={handleSubmit}>
      <Box pt="1rem">
       <Text pb="1rem" fontWeight={"500"}>
        Third-Party Code*
       </Text>
       <TextInput id="code" name="code" placeholder="e.g Code" />
      </Box>
      <Box pt="1rem">
       <Text pb="1rem" fontWeight={"500"}>
        Third-Party Description*
       </Text>
       <TextInput
        id="description"
        name="description"
        placeholder="Description"
       />
      </Box>
      <Box
       position="fixed"
       bottom="0"
       backgroundColor="#ffffff"
       height="5.5rem"
       w="100%"
       zIndex={2}
      >
       <Flex mr="20rem">
        <Box>{loading && <Text>Loading...</Text>}</Box>
        <Spacer />
        <Box mt={4}>
         <Flex alignItems="center" gap="2">
          <>
           <Button variant={"outline"} onClick={() => history.goBack()}>
            Cancel
           </Button>
           <Button type="submit">Save</Button>
          </>
         </Flex>
        </Box>
       </Flex>
      </Box>
     </form>
    )}
   </Formik>
  </Box>
 );
};

export default PredefinedThirdPartyEdited;
