import { ReactElement } from "react";
import {
 Breadcrumb,
 BreadcrumbItem,
 BreadcrumbLink,
 Box,
 Heading,
 Flex,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import Layout from "views/Layout";
import { ChevronRightIcon } from "@chakra-ui/icons";
import CurrencyExchange from "./Currency";
import ExchangeRate from "./Exchange";

import { actions as multiCurrencyAction } from "state/multiCurency/slice";
import { useAppDispatch } from "hooks";
import { BackButton } from "components/Icons";

import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";

const MultiCurrencyConfiguration = (): ReactElement => {
 const dispatch = useAppDispatch();
 const history = useHistory();
 const { location } = history;
 const { search } = location;
 const query = queryString.parse(search);
 const {
  currencyUpload,
  currencyCreated,
  currencyUpdated,
  currencyView,
  exchangeUpload,
  exchangeCreated,
  exchangeUpdated,
  exchangeView,
 } = useSelector(
  (state: RootState) => ({
   currencyUpload: state.multiCurrency.currencyUpload,
   currencyCreated: state.multiCurrency.currencyCreated,
   currencyUpdated: state.multiCurrency.currencyUpdated,
   currencyView: state.multiCurrency.currencyView,
   exchangeUpload: state.multiCurrency.exchangeUpload,
   exchangeCreated: state.multiCurrency.exchangeCreated,
   exchangeUpdated: state.multiCurrency.exchangeUpdated,
   exchangeView: state.multiCurrency.exchangeView,
  }),
  shallowEqual
 );
 const fetchListExchange = (payload: any) => {
    dispatch(multiCurrencyAction.listExchange(payload));
   };
 const fetchListCurrency = (payload: any) => {
    dispatch(multiCurrencyAction.listMultiCurrency(payload));
   };
 return (
  <Layout>
   <Box>
    <Flex
     cursor={"pointer"}
     onClick={() => {
      dispatch(multiCurrencyAction.resetPage(false));
      if (
       query.page &&
       !currencyUpload &&
       !currencyCreated &&
       !currencyUpdated &&
       !currencyView &&
       !exchangeUpload &&
       !exchangeCreated &&
       !exchangeUpdated &&
       !exchangeView
      ) {
       history.goBack();
      }
      history.push(`/settings/multi-currency?page=${query.page}`);
     }}
     alignItems="center"
     mb="1.5rem"
     gap="3"
    >
     <BackButton width="24px" height="24px" />
     <Heading as="h2">
      {query.page === "currency" ? "Currency" : "Exchange rate"}
     </Heading>
    </Flex>
    <Breadcrumb
     borderRadius="5px"
     padding="1.5"
     w="50%"
     h="50"
     spacing="8px"
     separator={<ChevronRightIcon color="gray.500" />}
    >
     <BreadcrumbItem
      onClick={() => {
       dispatch(multiCurrencyAction.resetPage(false));
       history.push("/settings");
      }}
     >
      <BreadcrumbLink color="gray.500" fontWeight={"600"}>
       Settings
      </BreadcrumbLink>
     </BreadcrumbItem>
     <BreadcrumbItem>
      <BreadcrumbLink
       fontWeight={"600"}
       color="gray.500"
       onClick={() => {
        dispatch(multiCurrencyAction.resetPage(false));
        history.push("/settings");
       }}
      >
       Multi Currency
      </BreadcrumbLink>
     </BreadcrumbItem>
     {query.page && (
      <BreadcrumbItem>
       <BreadcrumbLink
        fontWeight={"600"}
        textTransform="capitalize"
        onClick={() => {
         dispatch(multiCurrencyAction.clear());
         const payload = {
            skipCount: 0,
            maxResultCount: 10,
            keyword: ""
           };
           fetchListExchange(payload);
           fetchListCurrency(payload);
         history.push(`/settings/multi-currency?page=${query.page}`);
        }}
       >
        {query.page === "currency" ? "Currency" : "Exchange rate"}
       </BreadcrumbLink>
      </BreadcrumbItem>
     )}
    </Breadcrumb>
    {query.page === "currency" ? <CurrencyExchange /> : <ExchangeRate />}
   </Box>
  </Layout>
 );
};

export default MultiCurrencyConfiguration;
