import { extendTheme } from '@chakra-ui/react'
import Button from "styles/System/Button"
import Heading from "styles/System/Heading"
import Box from "styles/System/Box"


const overrides = {
    components: {
        Button,
        Heading,
        Box
    }
}

export default extendTheme(overrides)