import { FC, useState, useMemo } from "react";
import { useLocation } from "react-router";
import {
 List,
 ListItem,
 Link,
 Text,
 LinkProps,
 Image,
 Box,
 Flex,
 Center,
} from "@chakra-ui/react";
import { useAppDispatch } from "hooks";
import { actions as SettingActions } from "state/settings/slice";
import { RootState } from "state/store";
import { useSelector, shallowEqual } from "react-redux";
import s from "shortid";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import { ArrowDownIcon, ArrowUpIcon } from "components/Icons";
export type TNavItem = {
 url: string;
 label: string;
 icon?: any | null;
 divider?: boolean;
 activeIcon?: string;
};

export type NavItemProps = TNavItem & {
 submenu?: TNavItem[];
};

type NavLinkProps = LinkProps & {
 label: string;
 url: string;
 isActive: boolean;
 icon: string | null | undefined;
};
const NavLink: FC<NavLinkProps> = ({
 label,
 url,
 icon,
 isActive,
 ...props
}) => {
 const Icon = icon;
 const location = useLocation();
 const { isSidebarOpen } = useSelector(
  (state: RootState) => ({
   isSidebarOpen: state.thirdPartySetting.sidebarOpen,
  }),
  shallowEqual
 );
 const isMatch = (url: string | string[], path: string) => {
  if (isArray(url)) {
   return !isEmpty(
    url.filter((item) => new RegExp(`^${item}`, "m").test(path))
   );
  } else {
   return new RegExp(`^${url}`, "m").test(path);
  }
 };

 return (
  <Link
   {...props}
   _hover={{ textDecoration: "none" }}
   _focus={{ outline: "0" }}
   textDecoration="none"
   display="flex"
   alignItems="center"
   userSelect="none"
   fontWeight="bold"
   fontSize="sm"
   href={url}
  >
   {
    <Flex alignItems="center" gap="6" mr={isSidebarOpen ? "1rem" : "0"}>
     {!isNil(Icon) && (
      <Center
       borderRadius="7px"
       bgColor={isMatch(url, location.pathname) ? "#0070C7" : ""}
       p="5px"
      >
       <Icon
        boxSize={6}
        viewBox="0 -3 18 25"
        stroke={isMatch(url, location.pathname) ? "#ffffff" : "#9DA1A7"}
       />
      </Center>
     )}

     {isSidebarOpen && (
      <Text as="span" fontSize="16px" fontWeight={500}>
       {label}
      </Text>
     )}
    </Flex>
   }
  </Link>
 );
};

const NavItem: FC<NavItemProps> = ({
 url,
 label,
 icon,
 submenu,
 divider,
 activeIcon,
}) => {
 const dispatch = useAppDispatch();
 const { isSidebarOpen, navbarOpen, result } = useSelector(
  (state: RootState) => ({
   isSidebarOpen: state.thirdPartySetting.sidebarOpen,
   navbarOpen: state.thirdPartySetting.navbarOpen,
   result: state.auth.result,
  }),
  shallowEqual
 );
 const location = useLocation();

 const isMatch = (url: string | string[], path: string) => {
  if (isArray(url)) {
   return !isEmpty(
    url.filter((item) => new RegExp(`^${item}`, "m").test(path))
   );
  } else {
   return new RegExp(`^${url}`, "m").test(path);
  }
 };

 const isActiveParent = useMemo(() => {
  if (navbarOpen) return true;

  if (submenu && navbarOpen) {
   const urls = submenu.map((item) => item.url);
   return isMatch(urls, location.pathname);
  }
  return false;
 }, [submenu, navbarOpen, location.pathname]);

 const Icon = icon;
 return (
  <ListItem marginBottom="4">
   {!isNil(submenu) ? (
    <>
     <Link
      position="relative"
      display="flex"
      alignItems="center"
      color={isActiveParent ? "#43464B" : "#9DA1A7"}
      // bgColor={isActiveParent ? "rgba(226, 241, 253, 0.54);" : "transparent"}
      userSelect="none"
      marginBottom="4"
      p="2"
      borderRadius="7px"
      onClick={() => dispatch(SettingActions.setNavbarOpen(!navbarOpen))}
      onMouseOver={() => dispatch(SettingActions.setSidebarOpen(true))}
      _hover={{ textDecoration: "none" }}
     >
      <>
       {!isNil(icon) && (
        <Box
         mr={isSidebarOpen ? "1.5rem" : "unset"}
        //  shadow={
        //   isActiveParent ? "0px 0px 11px rgba(0, 114, 207, 0.32)" : "none"
        //  }
         borderRadius="7px"
         p="5px"
        //  bgColor={isActiveParent ? "#0072CF" : ""}
        //  color={isActiveParent ? "#ffffff" : ""}
        >
         {/* <Image src={icon} alt="icon" /> */}
         <Icon
          boxSize={6}
          viewBox="0 -3 18 25"
          stroke={isActiveParent ? "#000000" : "#9DA1A7"}
         />
        </Box>
       )}
       {isSidebarOpen && (
        <Text as="span" fontSize="16px">
         {label}
        </Text>
       )}
       {isActiveParent
        ? isSidebarOpen && (
          <ArrowDownIcon position="absolute" right="4" bottom="5" boxSize={7} sx={{transform:'rotate(180deg)'}}/>
          )
        : isSidebarOpen && (
           <ArrowDownIcon position="absolute" right="0" top="6" boxSize={7} />
          )}
      </>
     </Link>
     <List
      marginLeft="3"
      hidden={!isActiveParent}
      marginBottom={divider ? "4" : "0"}
     >
      {submenu.map((submenuItem) => (
       <ListItem marginBottom="4" key={s.generate()}>
        <NavLink
         isActive={isMatch(submenuItem.url, location.pathname)}
         color={
          isMatch(submenuItem.url, location.pathname) ? "#43464B" : "#9DA1A7"
         }
         url={submenuItem.url}
         label={submenuItem.label}
         icon={submenuItem.icon}
         fontSize="16px"
         pl="4"
         pt="3"
         pb="3"
         borderRadius="7px"
         background={
          isMatch(submenuItem.url, location.pathname)
           ? "rgba(226, 241, 253, 0.54);"
           : "transparent"
         }
        />
       </ListItem>
      ))}
     </List>
    </>
   ) : (
    <NavLink
     marginBottom={divider ? "2rem" : "0"}
     isActive={isMatch(url, location.pathname)}
     color="#9DA1A7"
     url={url}
     label={label}
     icon={icon}
     onMouseOver={() => dispatch(SettingActions.setSidebarOpen(true))}
     background={
      isSidebarOpen && isMatch(url, location.pathname)
       ? "rgba(226, 241, 253, 0.54);"
       : "transparent"
     }
     p="2"
     borderRadius="7px"
     fontSize="16px"
    />
   )}
   {/* {divider && <Divider borderColor="#D7D7D7" />} */}
  </ListItem>
 );
};

export default NavItem;
