import { ChangeEvent, useRef, useEffect, useState } from "react";
import {
 FormControl,
 FormLabel,
 Select as ChakraSelect,
 SelectProps as ChakraSelectProps,
 FormErrorMessage,
 FormHelperText,
 Stack,
} from "@chakra-ui/react";
import { useAppDispatch } from "hooks";
import { useField } from "formik";
import s from "shortid";
import { useErrorFocus } from "hooks";

// import { actions as masterDataAction } from "state/masterData/slice";

interface SelectProps extends ChakraSelectProps {
 options: any;
 name: string;
 id: string;
 fieldName?: string;
 dataId?: string;
 label?: string;
 description?: string;
 placeholder?: string;
 isDisabled?: boolean;
 isReadOnly?: boolean;
 isRequired?: boolean;
 isMultiple?: boolean;
 isCustomField?: boolean;
 //  thirdPartyId?: string;
 isMaintainWithMasterdata?: boolean;
 onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const Select = ({
 options,
 name,
 id,
 fieldName,
 dataId,
 label,
 placeholder,
 description,
 //  thirdPartyId,
 isDisabled = false,
 isReadOnly = false,
 isRequired = false,
 isMultiple = false,
 isCustomField = false,
 isMaintainWithMasterdata = false,
 onChange = () => {},
 ...props
}: SelectProps) => {
 const [{ value }, meta, { setValue }] = useField(name);
 const selectRef = useRef(null);
 const dispatch = useAppDispatch();
 useErrorFocus(selectRef, name);

 //  const [options, setOptions] = useState<any>([]);
 //  useEffect(() => {
 //   fetchListFormat({
 //    skipCount: 0,
 //    maxResultCount: 100,
 //    searchKey: "",
 //    configurationId: code,
 //    type: 0,
 //    thirdPartyId: thirdPartyId ? thirdPartyId : "",
 //   });
 //  }, [dispatch]);

 //  const fetchListFormat = async (value: any) => {
 //   setOptions([]);
 //   await dispatch(masterDataAction.masterDataList(value)).then(
 //    (masterDataResponse: any) => {
 //     const options = masterDataResponse?.payload?.result?.items;
 //     if (options) {
 //      const newOptions: any = [];
 //      options.map((item: any) =>
 //       newOptions.push({
 //        label: item.code,
 //        value: id === "name" ? item.id : item.code,
 //       })
 //      );
 //      const sort = (x: any, y: any) => {
 //       return x.label.localeCompare(y.label);
 //      };
 //      setOptions(newOptions.sort(sort));
 //     }
 //    }
 //   );
 //  };

 return (
  <FormControl
   id={id}
   isInvalid={Boolean(meta.error && meta.touched)}
   isDisabled={isDisabled}
   isReadOnly={isReadOnly}
   isRequired={isRequired}
  >
   <Stack spacing={4} direction="row">
    {label && (
     <FormLabel
      alignSelf="center"
      margin="0"
      minW="40%"
      fontWeight="bold"
      textTransform="capitalize"
     >
      {label}
     </FormLabel>
    )}
    <ChakraSelect
     name={name}
     multiple={isMultiple}
     value={value ? value : ""}
     minH="60px"
     minW={200}
     bgColor="#E6EAF1"
     data-fieldname={fieldName}
     data-dataId={dataId}
     ref={selectRef}
     onClick={(e) => {
      const selected = e.currentTarget.value;
      if (isMultiple) {
       if (value.includes(selected)) {
        setValue(value.filter((val: string) => val !== selected));
       }
      }
     }}
     onChange={(e) => {
      const selected = e.currentTarget.value;
      if (isMultiple) {
       if (value.includes(selected)) {
        setValue(value.filter((val: string) => val !== value));
       } else {
        setValue([...value, selected]);
       }
      } else {
       setValue(selected);
       if (onChange) {
        onChange(e);
       }
      }
     }}
     {...props}
    >
     <option disabled value="">
      Select One
     </option>
     {Array.isArray(options) &&
      options.map(({ label, value }) => (
       <option key={s.generate()} value={value}>
        {label}
       </option>
      ))}
    </ChakraSelect>
    {description && <FormHelperText>{description}</FormHelperText>}
    <FormErrorMessage>{meta.error}</FormErrorMessage>
   </Stack>
  </FormControl>
 );
};

export default Select;
