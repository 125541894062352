export const ValidationData = (data:any, name:string, value: string| number, isCustomField: boolean, type:string) => {
    if(type === "isIncludeCalculation"){
        if(isCustomField){
            const checkIsSame = data?.customFields.find((item:any)=> item.name === name && item.value === value);
                return checkIsSame !== undefined
            }else{
                return data?.unitCost === value
            }
    }else{
        return name === 'name' && data?.name === value || name === 'description' && data?.description === value
    }
};