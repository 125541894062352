import { ReactElement, useEffect, useState, useRef } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { Flex, Box, Spacer } from "@chakra-ui/layout";
import {
 Image,
 Table as ChakraTable,
 TableContainer,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { actions as pricingCostSummaryAction } from "state/pricings/costSummary/slice";
import ReactApexChart from "react-apexcharts";
import VolumeSection from "views/SimulationBySku/CostSummary/VolumeSection";
import unCollapse from "assets/images/upArrow.png";
import collapse from "assets/images/downArrow.png";
import Checklist from "assets/images/CheckBox.png";
import ToolbarBottom from "views/SimulationBySku/ToolbarBottom";
import "utils/number-utils";
import { BarChart } from "components/ApexChart";

const CostSummary = (): ReactElement => {
 const [data, setData] = useState<any>({});
 const [volumeDefault, setVolumeDefault] = useState<number>(0);
 const [totalCostVolume, setTotalCostVolume] = useState<any>({});
 const [showChart, setShowChart] = useState<boolean>(true);

 const dispatch = useAppDispatch();
 const location = useLocation<{
  status: string;
  volumeId: string;
  isEditing: boolean;
 }>();
 const pricingId = location.pathname.split("/")[3];
 const [currentVolume, setCurrentVolume] = useState<number>(0);
 const [changeVolumeByChart, setChangeVolumeByChart] = useState(false);
 const [volumeTypeLabelHeader, setVolumeTypeLabelHeader] = useState("");
 const [volumeType, setVolumeType] = useState("");
 const [isDefaultVolume, setIsDefaultVolume] = useState(false);
 const changeVolumeReff = useRef<null | HTMLDivElement>(null);

 const { summaryCostChart, isLoading } = useSelector(
  (state: RootState) => ({
   summaryCostChart: state.pricingCostSummary.costSummaryChart,
   isLoading: state.pricingCostSummary.loading,
  }),
  shallowEqual
 );

 const scrollToBottom = () => {
  changeVolumeReff.current?.scrollIntoView({ behavior: "smooth" });
 };

 const {
  volumes,
  loading,
  currency,
  summaryCost,
  detailPerVolumeId,
  isSimulate,
  statusAndVolume,
 } = useSelector(
  (state: RootState) => ({
   volumes: state.pricingCostSummary.costSummaryById.volumes,
   currency: state.pricingCostSummary.costSummaryById.currency,
   loading: state.pricingCostDrivers.loading,
   summaryCost: state.pricingCostSummary.costSummaryById,
   detailPerVolumeId: state.pricingCostDrivers.detailPerVolumeId.result,
   isSimulate: state.pricingCostDrivers.isSimulate,
   statusAndVolume: state.editPricingCostDrivers.statusAndVolume,
  }),
  shallowEqual
 );

 useEffect(() => {
  const payload = {
   id: pricingId,
   status: statusAndVolume.status || location.state.status,
  };
  dispatch(pricingCostSummaryAction.pricingCostSummaryChart(payload));
 }, [pricingId]);

 useEffect(() => {
  Array.isArray(volumes) &&
   volumes.length > 0 &&
   volumes.map((defaultVolume: any) => {
    const { costDrivers, volume, unitCost, volumeType } = defaultVolume;
    setVolumeDefault(volume);

    if (volumeType === 10) {
     setVolumeTypeLabelHeader("Total Cost");
     setVolumeType("Per Annum");
    } else {
     setVolumeTypeLabelHeader("Average Cost");
     setVolumeType("Per Order");
    }

    var mainCostDrivers = costDrivers
     .filter((i: any) => i.parentCostDriver === null)
     .sort((a: any, b: any) =>
      a.configuration.sequence < b.configuration.sequence ? -1 : 1
     );
    setData(mainCostDrivers);

    const totalCost = {
     costBreakdown: "Total Cost",
     currency: currency ? currency.code : "-",
     finalCost: unitCost,
    };
    setTotalCostVolume(totalCost);
   });
 }, [loading, volumes]);

 const columnHeaders = [
  { name: "costBreakdown", label: "Cost Breakdown", align: "left" },
  { name: "currency", label: "Currency", align: "left"  },
  { name: "finalCostCurrent", label: `${summaryCost?.configuration?.sizeUnit?.name} (Current)`, align: "left" },
  { name: "finalCostWhatIf", label: `${summaryCost?.configuration?.sizeUnit?.name} (What-if)`, align: "right" },
 ];
 const volumeSummaryColumnHeaders = [
  { name: "volume", label: "Volume", align: "left" },
  { name: "currency", label: "Currency", align: "left" },
  {
   name: "finalPrice",
   label: `${summaryCost?.configuration?.sizeUnit?.name} (Current)`,
   align: "right",
  },
  {
   name: "finalPrice",
   label: `${summaryCost?.configuration?.sizeUnit?.name} (What-if)`,
   align: "right",
  },
 ];

 const costDriverDetail: any = [];
 const stepperData: any = [];
 Array.isArray(data) &&
  data.length > 0 &&
  data.map((value: any, index: number) => {
   const startSequence = 3;
   const newStepper = {
    sequence: startSequence + index,
    pageName: value.configuration.identityName
     .replace(/\s+/g, "-")
     .toLowerCase(),
    description: value.configuration.identityName,
   };

   const newArray = {
    costBreakdown: value.configuration.identityName,
    isPercentage: value.configuration.isPercentage,
    currency: currency ? currency.code : "-",
    finalCost: value.unitCost,
   };

   stepperData.push(newStepper);
   costDriverDetail.push(newArray);
  });
 costDriverDetail.push(totalCostVolume);

 const seriesCurrent =
  detailPerVolumeId && detailPerVolumeId.currentSummaryCostDrivers
   ? detailPerVolumeId.currentSummaryCostDrivers?.map((x: any) => ({
      x: x.name,
      y: x.totalCost,
     }))
   : detailPerVolumeId?.summaryCostDrivers
   ? detailPerVolumeId?.summaryCostDrivers?.map((x: any) => ({
      x: x.name,
      y: x.totalCost,
     }))
   : {};

 const seriesWhatIf =
  detailPerVolumeId && detailPerVolumeId.whatIfSummaryCostDrivers
   ? detailPerVolumeId.whatIfSummaryCostDrivers?.map((x: any) => ({
      x: x.name,
      y: x.totalCost,
     }))
   : {};
 const seriessVolumes = [
  {
   name: "Current - Cost",
   data: seriesCurrent,
   labelName:
    seriesCurrent.length > 0 ? seriesCurrent?.map((item: any) => item.x) : [],
  },
  {
   name: "What-If - Cost",
   data: seriesWhatIf,
   labelName:
    seriesCurrent.length > 0 ? seriesCurrent?.map((item: any) => item.x) : [],
  },
 ];

 return (
  <Box>
   <Box pb="2rem" mt="1rem" fontSize="18px">
    <Box
     p={"1rem"}
     h="100%"
     borderRadius="5px"
     background="#f8fbff"
     border="1px solid #D7DBE2"
     pb="21px"
    >
     <Flex alignItems="baseline" ml="2rem" mr="1rem">
      <Box
       as="p"
       fontWeight="400"
       color="#8b8e91"
       fontSize="22px"
       p="1rem"
       pl={showChart ? "40%" : ""}
      >
       Cost Breakdown
      </Box>
      <Spacer />
      <Box pr="10px">
       {!showChart && (
        <Image
         src={unCollapse}
         onClick={(e) => setShowChart(true)}
         pt="0.8rem"
         cursor="pointer"
         alt="Bulp"
        />
       )}
       {showChart && (
        <Image
         src={collapse}
         onClick={(e) => setShowChart(false)}
         pt="0.8rem"
         cursor="pointer"
         alt="Bulp"
        />
       )}
      </Box>
     </Flex>
     {showChart && <BarChart data={seriessVolumes} height={300} />}
    </Box>
   </Box>
   {!isLoading && (
    <Box>
     {!isSimulate && <VolumeSection volumes={volumes} />}
     <Box
      w="100%"
      h="100%"
      borderRadius="5px"
      border="1px solid #D7DBE2"
      pt="21px"
      pl="32px"
      pr="32px"
      pb="21px"
      mb="2rem"
      ref={changeVolumeReff}
     >
      <Box>
       <Flex>
        <Box
         as="p"
         pb="3px"
         fontSize="21"
         textColor="#00050D"
         fontWeight="bold"
        >
         Cost Summary - {detailPerVolumeId?.thirdParty?.description}
        </Box>
        <Spacer />
        <Box m="auto">
         <Flex>
          <Spacer />
          <Box w="23.99px" h="23.99px" mt="5px" mr="0.5rem">
           {<Image src={Checklist} alt="Bulp" />}
          </Box>
          <Box
           as="p"
           pb="3px"
           fontSize="21"
           textColor="#00050D"
           fontWeight="bold"
          >
           Volume: {String.toFormatSeparator(detailPerVolumeId?.volume, 0)}
          </Box>
         </Flex>
        </Box>
       </Flex>
      </Box>
      <ChakraTable variant="mytable" colorScheme="facebook">
       <Thead borderBottom="1px solid #D7DBE2">
        <Tr>
         {columnHeaders &&
          columnHeaders.map((item: any, index) => {
           return (
            <Th
             key={index}
             textAlign={item.align}
             textTransform="capitalize"
             color="#9DA1A7"
             fontSize="14px"
            >
             {item.label}
             {/* {label === "Final Cost Per Unit"
              ? `Final Cost Per ${String.toFormatSeparator(volumeDefault, 0)}`
              : label} */}
            </Th>
           );
          })}
        </Tr>
       </Thead>
       <Tbody>
        {!loading &&
        detailPerVolumeId &&
        detailPerVolumeId?.currentSummaryCostDrivers?.length > 0
         ? detailPerVolumeId.currentSummaryCostDrivers.map(
            (row: any, index: number) => {
             return (
              <Tr key={index}>
               <Td>{row.name}</Td>
               <Td>
                {row.isPercentage
                 ? "-"
                 : detailPerVolumeId?.costAnalysis.currency}
               </Td>
               <Td textAlign="right">
                {row.isPercentage
                 ? row?.totalCost &&
                   String.toFormatSeparator(row?.totalCost) + "%"
                 : row?.totalCost && String.toFormatSeparator(row?.totalCost)}
               </Td>
               <Td textAlign="right">
                {detailPerVolumeId?.whatIfSummaryCostDrivers[index].isPercentage
                 ? String.toFormatSeparator(
                    detailPerVolumeId?.whatIfSummaryCostDrivers[index]
                     ?.totalCost
                   ) + "%"
                 : String.toFormatSeparator(
                    detailPerVolumeId?.whatIfSummaryCostDrivers[index]
                     ?.totalCost
                   )}
               </Td>
              </Tr>
             );
            }
           )
         : detailPerVolumeId &&
           detailPerVolumeId.summaryCostDrivers.map(
            (row: any, index: number) => {
             return (
              <Tr key={index}>
               <Td>{row.name}</Td>
               <Td>
                {row.isPercentage
                 ? "-"
                 : detailPerVolumeId?.costAnalysis.currency}
               </Td>
               <Td textAlign="right">
                {row.isPercentage
                 ? row?.totalCost &&
                   String.toFormatSeparator(row?.totalCost) + "%"
                 : row?.totalCost &&
                   row?.totalCost?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                   })}
               </Td>
               <Td textAlign="right">
                {detailPerVolumeId?.whatIfSummaryCostDrivers
                 ? String.toFormatSeparator(
                    detailPerVolumeId?.whatIfSummaryCostDrivers[index]
                     ?.totalCost
                   ) + "%"
                 : "-"}
               </Td>
              </Tr>
             );
            }
           )}
       </Tbody>
      </ChakraTable>
     </Box>

     <Box
      w="100%"
      h="100%"
      borderRadius="5px"
      border="1px solid #D7DBE2"
      pt="21px"
      pl="32px"
      pr="32px"
      pb="21px"
      mb="5rem"
     >
      <Box>
       <Box as="p" pb="3px" fontSize="21" textColor="#00050D" fontWeight="bold">
        Cost Analysis Summary
       </Box>
      </Box>
      <TableContainer
       sx={{
        "&::-webkit-scrollbar": { width: "6px", height: "6px" },
        "&::-webkit-scrollbar-track": { width: "6px", height: "6px" },
        "&::-webkit-scrollbar-thumb": {
         borderRadius: "24px",
         backgroundColor: "#0072CF",
        },
       }}
      >
       <ChakraTable variant="mytable" colorScheme="facebook">
        <Thead borderBottom="1px solid #D7DBE2">
         <Tr>
          {volumeSummaryColumnHeaders &&
           volumeSummaryColumnHeaders.map((item: any, index) => {
            return (
             <Th
              key={index}
              textAlign={item.align}
              textTransform="capitalize"
              color="#9DA1A7"
              fontSize="14px"
             >
              {item.label}
             </Th>
            );
           })}
         </Tr>
        </Thead>
        <Tbody>
         <Tr key={summaryCost?.volume}>
          <Td
           sx={{
            textAlign: "left",
           }}
          >
           {String.toFormatSeparator(detailPerVolumeId?.volume, 0)}
          </Td>
          <Td>{detailPerVolumeId?.costAnalysis?.currencyCode}</Td>
          <Td
           sx={{
            textAlign: "right",
           }}
          >
           {isNaN(detailPerVolumeId?.currentUnitCost)
            ? detailPerVolumeId?.unitCost?.toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
              })
            : detailPerVolumeId?.currentUnitCost?.toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
              })}
          </Td>
          <Td
           sx={{
            textAlign: "right",
           }}
          >
           {isNaN(detailPerVolumeId?.whatIfUnitCost)
            ? "-"
            : detailPerVolumeId?.whatIfUnitCost?.toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
              })}
          </Td>
         </Tr>
        </Tbody>
       </ChakraTable>
      </TableContainer>
     </Box>
     {volumes && volumes.length > 0 && (
      <ToolbarBottom costAnalysis={volumes[0]} costAnalysisId={pricingId} />
     )}
    </Box>
   )}
  </Box>
 );
};

export default CostSummary;
