import { JourneyFormInitial } from "types/journeys";
export const JourneyInitialValues: JourneyFormInitial = {
  identityName: "",
  identityCode: "",
  journeyCode: "",
  journeyName: "",
  id: "",
  labelName: "",
  type: 10,
  customFields: [],
  productLabelName: '',
  isPercentage: false,
  isMainCostDriver: false,
  volumeType: 10,
  productLabelDescription: '',
  uoMLabelName: '',
  currencyLabelName: '',
  volumeLabelName: '',
  formatLabelName: '',
  orderFrequencyLabelName: '',
  labelDescription: '',
  labelCost: '',
  labelTotalCost: '',
  totalCostFormulaExpression: '',
  isEnableThirdParty: false,
  thirdPartyLabelName: '',
  sizeUnit: '',
  sizeUnitId: '',
  isMaintainWithMasterData: false,
  masterDataConfigurationId: '',
  masterDataConfiguration: {}
};