import { ReactElement, useMemo, useEffect, useState } from "react";
import { IconButton } from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { useAppDispatch } from "hooks";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import { removeThousandSeparator } from "utils";
import Swal from "sweetalert2";
import async from "react-select/dist/declarations/src/async/index";

const DeleteRow = ({
 costDriver,
 index,
 paramName,
 arrayHelpers,
 row,
 onClick,
 convertName,
 setFieldValue,
}: any): ReactElement => {
 const dispatch = useAppDispatch();
 const [newRow, setNewRow] = useState<any>(row);
 const {
  temporaryDataCostDriver,
  loading,
  dataToSendUpdates,
  detailPerVolumeId,
  changeRowSimulate,
  dataToSimulates,
 } = useSelector(
  (state: RootState) => ({
   loading: state.editPricingCostDrivers.loading,
   dataToSendUpdates: state.editPricingCostDrivers.dataToSend.updates,
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId.result,
   changeRowSimulate: state.editPricingCostDrivers.tmpChangeRow,
   dataToSimulates: state.editPricingCostDrivers.dataToSimulate,
   temporaryDataCostDriver:
    state.editPricingCostDrivers.temporaryDataCostDriver,
  }),
  shallowEqual
 );

 let notAllowPointer = false;
 if (costDriver.data.length > 1) {
  costDriver.data.map((item: any) => {
   if (item.unitCost === 0) {
    notAllowPointer = false;
   }
  });
 }

 const runSimulate = useMemo(
  () =>
   debounce((dataToUpdate) => {
    const newCustomFieldDataToUpdate: any = [];
    dataToUpdate?.customFields?.map((customField: any) => {
     if (!customField.isMaintenanceByMasterData) {
      newCustomFieldDataToUpdate.push(customField);
     }
    });
    const newDataToUpdate = {
     ...dataToUpdate,
     customFields: newCustomFieldDataToUpdate,
    };
    dispatch(
     pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(
      newDataToUpdate
     )
    );
   }, 600),
  [dispatch, dataToSendUpdates]
 );

 const debouncedRunSimulate = useMemo(
  () =>
   debounce(async (dataOnChange: any) => {
    dataOnChange.map((item: any) => {
     const {
      name,
      value,
      currentRow1,
      tableName,
      dataFromSimulate,
      unitCostLabel,
     } = item;
     let isNewCostDriver: any;
     if (dataFromSimulate || currentRow1) {
      isNewCostDriver = dataFromSimulate?.isNew
       ? dataFromSimulate
       : currentRow1;
     } else {
      isNewCostDriver = row;
     }
     const newCustomField: any = [];
     let itemToObject = Object.assign({}, isNewCostDriver);
     const originalNewMap = new Map(Object.entries(itemToObject));
     const findCost = changeRowSimulate.find(
      (item: any) =>
       item.name === unitCostLabel &&
       item.componentName === dataFromSimulate?.name &&
       item.idRow === dataFromSimulate.id
     );
     if (
      (row.isNew && 0 === value) ||
      removeThousandSeparator(originalNewMap.get(name) as string) === value
     ) {
      const tmpChangeToDeletes = {
       component: `${originalNewMap.get("name")} - ${
        name === "unitCost" ? unitCostLabel : name
       }`,
       name: convertName,
       constDriverId: originalNewMap.get("constDriverId"),
      };
      dispatch(
       pricingCostDriverAction.removeOnetmpChangeRow({ tmpChangeToDeletes })
      );

      let indexCustomField = null;
      const customFields: any = [];
      dataFromSimulate?.customFields?.map((item: any, index: number) => {
       if (item.name === name) {
        indexCustomField = index;
        customFields.push({
         ...item,
         value,
        });
       } else {
        customFields.push(item);
       }
      });
      const dataToUpdate = {
       costAnalysisPerVolumeId: detailPerVolumeId?.id,
       costDriverId: isNewCostDriver.id,
       currencyId: isNewCostDriver.currencyId,
       parentCostDriverId: isNewCostDriver.constDriverId,
       name: isNewCostDriver.name,
       masterDataId: isNewCostDriver.masterDataId,
       description: isNewCostDriver.description,
       unitCost:
        convertName === "unitCost"
         ? value
         : findCost?.before ?? itemToObject?.unitCost,
       customFields: customFields,
      };

      runSimulate(dataToUpdate);
     } else {
      let indexCustomField = null;
      let isCustomFieldAllHaveValue = true;
      newCustomField.length > 0 &&
       newCustomField.map((item: any) => {
        if (item.masterData !== null && item.value === "") {
         isCustomFieldAllHaveValue = false;
        }
       });
      const customFields: any = [];
      isNewCostDriver?.customFields.map(
       (item: any, indexCustomFieldNumber: number) => {
        if (item.name === name) {
         indexCustomField = indexCustomFieldNumber;
         if (changeRowSimulate.length > 0) {
          for (let i = 0; i < changeRowSimulate.length; i++) {
           if (
            item.customFieldConfigurationId ===
             changeRowSimulate[i]?.customFieldConfigurationId &&
            item.costDriverId === changeRowSimulate[i]?.idRow &&
            item.name === changeRowSimulate[i]?.name
           ) {
            customFields.push({
             ...item,
             value,
            });
           } else {
            if (
             item?.name === name &&
             item?.costDriverName === currentRow1?.name
            ) {
             customFields.push({
              ...item,
              value,
             });
            } else {
             const sameValue = changeRowSimulate.find(
              (data: any) =>
               data?.idRow === item?.costDriverId &&
               data?.customFieldConfigurationId ===
                item?.customFieldConfigurationId &&
               item?.name === data.name
             );
             if (sameValue) {
              customFields.push({
               ...item,
               value: sameValue?.before,
              });
             } else {
              customFields.push(item);
             }
            }
           }
          }
         } else {
          customFields.push({
           ...item,
           value,
          });
         }
        } else {
         for (let i = 0; i < changeRowSimulate.length; i++) {
          if (
           item?.customFieldConfigurationId ===
            changeRowSimulate[i]?.customFieldConfigurationId &&
           item?.costDriverName === changeRowSimulate[i]?.componentName &&
           item?.name === changeRowSimulate[i]?.name
          ) {
           customFields.push({
            ...item,
            value: changeRowSimulate[i]?.before,
           });
          } else {
           if (item?.name === name && item?.costDriverName === row?.name) {
            customFields.push({
             ...item,
             value,
            });
           } else {
            const sameValue = changeRowSimulate.find(
             (data: any) =>
              data?.idRow === item?.costDriverId &&
              data?.customFieldConfigurationId ===
               item?.customFieldConfigurationId &&
              item?.name === data?.name
            );
            if (sameValue) {
             customFields.push({
              ...item,
              value: sameValue?.before,
             });
            } else {
             customFields.push(item);
            }
           }
          }
         }
        }
       }
      );

      const customFieldsFromSimulate: any = [];

      row?.customFields.map((customField: any) => {
       if (customField?.name === name) {
        customFieldsFromSimulate.push({
         ...customField,
         value,
        });
       } else {
        if (row[customField?.name] || !customField?.isRequired) {
         if (
          name === `${customField?.name}-currencyId` &&
          customField?.typeData === 50
         ) {
          customFieldsFromSimulate.push({
           ...customField,
           currencyId: value,
          });
         } else {
          customFieldsFromSimulate.push({
           ...customField,
           value: row[customField.name],
          });
         }
        }
       }
      });
      const dataSendEdit = customFieldsFromSimulate?.map((o: any) => o.name);
      const filteredCustomFields = customFieldsFromSimulate?.filter(
       ({ name, isMaintenanceByMasterData }: any, index: number) =>
        !isMaintenanceByMasterData && !dataSendEdit.includes(name, index + 1)
      );

      const dataToUpdate = {
       costAnalysisPerVolumeId: detailPerVolumeId?.id,
       costDriverId: isNewCostDriver
        ? isNewCostDriver?.id
        : dataFromSimulate?.id,
       parentCostDriverId: isNewCostDriver
        ? isNewCostDriver?.constDriverId
        : dataFromSimulate?.constDriverId,
       name: row.masterDataId
        ? dataFromSimulate?.name
        : name === "name"
        ? value
        : dataFromSimulate?.name,
       description: row.masterDataId
        ? dataFromSimulate?.description
        : name === "description"
        ? value
        : dataFromSimulate?.description,
       masterDataId: dataFromSimulate.masterDataConfiguration?.id,
       unitCost: name === "unitCost" ? value : dataFromSimulate?.unitCost,
       customFields: filteredCustomFields,
       remove: row?.isNew && 0 === value,
       currencyId: row.currencyId,
      };

      runSimulate(dataToUpdate);

      let itemNewOriginal;
      if (isNewCostDriver) {
       itemNewOriginal = new Map(Object.entries(isNewCostDriver));
       let after = isNewCostDriver?.customFields.find(
        (item: any) => item?.name === convertName
       );

       if (
        (after && removeThousandSeparator(after?.value) !== value) ||
        (convertName === "unitCost" &&
         removeThousandSeparator(isNewCostDriver?.unitCost) !== value)
       ) {
        const findCust = customFields?.find(
         (item: any) =>
          item?.name === name && item?.costDriverId === isNewCostDriver?.id
        );
        dispatch(
         pricingCostDriverAction.settmpChangeRow({
          id: convertName,
          costDriver: tableName,
          component: `${dataFromSimulate.name} - ${
           name === "unitCost" ? unitCostLabel : name
          }`,
          before: value,
          after: isNewCostDriver.isNew
           ? 0
           : after
           ? after.value
           : itemNewOriginal.get("unitCost"),
          idRow: findCust ? findCust?.costDriverId : row?.id,
          customFieldConfigurationId: after
           ? after?.customFieldConfigurationId
           : costDriver.costDriverId,
          parentCostDriverId: findCust
           ? findCust?.id
           : costDriver?.costDriverId,
          componentName: originalNewMap.get("name"),
          name: name === "unitCost" ? unitCostLabel : name,
         })
        );
       }
      } else {
       itemNewOriginal = new Map(Object.entries(dataFromSimulate));
       let after = dataFromSimulate?.customFields?.find(
        (item: any) => item?.name === convertName
       );

       if (
        (after && removeThousandSeparator(after.value) !== value) ||
        (convertName === "unitCost" &&
         removeThousandSeparator(dataFromSimulate?.unitCost) !== value)
       ) {
        const findCust = customFields.find(
         (item: any) =>
          item?.name === name && item?.costDriverId === dataFromSimulate?.id
        );
        dispatch(
         pricingCostDriverAction.settmpChangeRow({
          id: convertName,
          costDriver: tableName,
          component: `${originalNewMap.get("name")} - ${
           name === "unitCost" ? unitCostLabel : name
          }`,
          before: value,
          after: dataFromSimulate?.isNew
           ? 0
           : after
           ? after.value
           : itemNewOriginal.get("unitCost"),
          idRow: row.id,
          customFieldConfigurationId: findCust?.customFieldConfigurationId,
          parentCostDriverId: costDriver?.costDriverId,
          componentName: originalNewMap.get("name"),
          name: name === "unitCost" ? unitCostLabel : name,
         })
        );
       }
      }
     }
    });
   }, 100),
  [dataToSendUpdates, row]
 );
 useEffect(() => {
  setNewRow(row);
 }, [row]);

 useEffect(() => {
  return () => {
   debouncedRunSimulate.cancel();
  };
 }, []);

 const findIndexCustomField = () => {
  return row.customFields.findIndex(
   (customField: any) => customField.isRequired
  );
 };

 return (
  <IconButton
   minW="auto"
   isDisabled={loading}
   variant="unstyled"
   aria-label="cancel"
   position="absolute"
   _focus={{
    boxShadow: "none",
   }}
   cursor="pointer"
   right={5}
   onClick={async () => {
    if (dataToSimulates.length > 0) {
     Swal.fire({
      showCancelButton: true,
      allowOutsideClick: false,
      html:
       "All changes will be lost without recalculating. Are you sure you want to exit?",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
     }).then(async (result) => {
      if (result.isConfirmed) {
       onClick();
       for (let i = 0; i < dataToSimulates.length; i++) {
        const findDataChanges = dataToSimulates[
         i
        ].dataFromSimulate.customFields.find(
         (data: any) => data.name === dataToSimulates[i].name
        );
        dataToSimulates[i].name === "name"
         ? setFieldValue(
            `customFields[${index}].${dataToSimulates[i].name}`,
            dataToSimulates[i].dataFromSimulate.name
           )
         : dataToSimulates[i].name === "description"
         ? setFieldValue(
            `customFields[${index}].${dataToSimulates[i].name}`,
            dataToSimulates[i].dataFromSimulate.description
           )
         : dataToSimulates[i].name === "unitCost"
         ? setFieldValue(
            `customFields[${index}].${dataToSimulates[i].name}`,
            dataToSimulates[i].dataFromSimulate.unitCost
           )
         : setFieldValue(
            `customFields[${index}].${dataToSimulates[i].name}`,
            findDataChanges?.value
           );
       }
       dispatch(pricingCostDriverAction.resetDataSimulate([]));
       // const indexSimulateBySku = temporaryDataCostDriver.findIndex(
       //  (el: any) => el.name == paramName
       // );
       // const findIndexOneCostDriverOriginal =
       //  temporaryDataCostDriver[indexSimulateBySku] &&
       //  temporaryDataCostDriver[indexSimulateBySku]?.data &&
       //  temporaryDataCostDriver[indexSimulateBySku]?.data.findIndex(
       //   (item: any) => item.identityName === costDriver.identityName
       //  );

       // const findIndex =
       //  temporaryDataCostDriver[indexSimulateBySku].data[
       //   findIndexOneCostDriverOriginal
       //  ].data.length;
       // const newRow =
       //  temporaryDataCostDriver[indexSimulateBySku].data[
       //   findIndexOneCostDriverOriginal
       //  ].data[findIndex - 1];
       //  debouncedRunSimulate(dataToSimulates);
       // if (newRow.isNew) {
       //  if (newRow.index === 0) {
       //   const indexNumberToDelete = {
       //    index1: indexSimulateBySku,
       //    index2: findIndexOneCostDriverOriginal,
       //    index3: findIndex - 1,
       //   };
       //   await dispatch(
       //    pricingCostDriverAction.deleteTemporaryDataCostDriver(
       //     indexNumberToDelete
       //    )
       //   );

       //   arrayHelpers.remove(index);
       //  }
       // }
      }
     });
    } else {
     onClick();
    }
   }}
   icon={<ArrowForwardIcon boxSize={8} />}
  />
 );
};

export default DeleteRow;
