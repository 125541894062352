import { useEffect, useRef, RefObject } from 'react'
import { useDispatch } from 'react-redux'
import { ApolloError } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { useFormikContext } from "formik";

import { useHistory } from 'react-router-dom';
import { UnregisterCallback } from 'history';

import { AppDispatch } from 'state/store'

export function usePrevious<T> (value: T, defaultValue: T): T {
  const ref = useRef(defaultValue)
  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export function useEffectDebugger<T> (effectHook: () => void, dependencies: T[], dependencyNames: string[] = []): void {
  const previousDeps = usePrevious(dependencies, [])

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] !== undefined ? dependencyNames[index] : index
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency
        }
      }
    }

    return accum
  }, {})

  if (Object.keys(changedDeps).length > 0) {
  }

  useEffect(effectHook, dependencies)
}

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useErrorMessage = (error: ApolloError | Error | undefined) => {
  const toast = useToast();

  useEffect(() => {
    const errorToken = ["Failed to verify Token", "Token Expired"];

    if (
      error?.message ==
        `QueryFailedError: ER_WARN_DATA_OUT_OF_RANGE: Out of range value for column 'rank' at row 1` ||
      error?.message ==
        `QueryFailedError: ER_DATA_TOO_LONG: Data too long for column 'timing' at row 1`
    ) {
      toast({
        title: "please ensure you key in the correct value for Timing/Rank",
        position: "bottom",
        isClosable: true,
        status: "error",
      });
    } else if (error) {
      toast({
        title: error.message,
        position: "bottom",
        isClosable: true,
        status: "error",
      });

      // handle token error
      if (errorToken.includes(error.message)) {
        // removeAccessToken();
        window.location.href = "/";
      }
    }
    // eslint-disable-next-line
  }, [error, toast]);
};

export const useErrorFocus = (
  fieldRef: RefObject<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >,
  name: string
) => {
  const { isSubmitting, isValid, errors } = useFormikContext();

  const firstErrorKey = Object.keys(errors)[0];

  useEffect(() => {
    if (!isSubmitting || isValid) return;
    if (!isValid && firstErrorKey === name) {
      return fieldRef?.current?.focus;
    }
  }, [isSubmitting, isValid, fieldRef, firstErrorKey, name]);
};

export const usePrompt = (
  when: boolean,
  message: string = 'Please save your change before move to other page',
) => {
  const history = useHistory();

  const self = useRef<UnregisterCallback | null>();

  const onWindowOrTabClose = (event: BeforeUnloadEvent) => {
    if (!when) {
      return;
    }

    if (typeof event === 'undefined') {
      event = window.event as BeforeUnloadEvent;
    }

    if (event) {
      event.returnValue = message;
    }

    return message;
  };

  useEffect(() => {
      self.current = null;

    window.addEventListener('beforeunload', onWindowOrTabClose);

    return () => {
      if (self.current) {
        self.current();
        self.current = null;
      }

      window.removeEventListener('beforeunload', onWindowOrTabClose);
    };
  }, [message, when, onWindowOrTabClose]);
}