import { RootState } from "state/store";
import { FC, useEffect, useState } from "react";
import { Box, Flex, Button } from "@chakra-ui/react";
import { useSelector, shallowEqual } from "react-redux";
import { useAppDispatch } from "hooks";
import { useHistory } from "react-router-dom";
import { actions as annualSpendAction } from "state/annualSpend/slice";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { BarChartLoading } from "components/Loading";
import TableList from "./ListTable";

const SummaryComparasion: FC = () => {
 const dispatch = useAppDispatch();
 const router = useHistory();
 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);

 let { loading, compare, selection } = useSelector(
  (state: RootState) => ({
   loading: state.annualSpendSimulations.loading,
   compare: state.annualSpendSimulations.compare,
   selection: state.annualSpendSimulations.selection,
  }),
  shallowEqual
 );

 const handleClose = () => {
  dispatch(annualSpendAction.clear());
  router.push("/annual-spend-simulations");
 };

 const radiusData = compare.chart?.map((item: any) => ({
  name: item.businessCaseName,
  data: item.details?.map((item: any) => item.whatIfTotalSpend),
  label: item.details?.map((item: any) => item.identityName),
 }));
 const Label = compare.chart?.map((item: any) =>
  item.details?.map((item: any) => item.identityName)
 );

 const series = radiusData?.map((item: any) => ({
  name: item.name,
  data: item.data,
 }));

 const options: ApexOptions = {
  chart: {
   toolbar: {
    show: false,
   },
   offsetX: -150,
   offsetY: -120,
  },
  colors: ["#0072CF", "#00439E", "#5EB1F3", "#B9DCFA", "#0591F0"],
  tooltip: {
   y: {
    formatter: function (val: any) {
     return val;
    },
   },
  },
  stroke: {
   width: 2,
  },
  fill: {
   colors: ["#f8f8f8", "#fff"],
   opacity: 0.1,
  },
  legend: {
   show: true,
   showForSingleSeries: true,
   showForNullSeries: false,
   showForZeroSeries: false,
   position: "right",
   horizontalAlign: "left",
   fontSize: "14px",
   fontWeight: 400,
   formatter: undefined,
   offsetY: 130,
   offsetX: 50,
   labels: {
    colors: undefined,
    useSeriesColors: false,
   },
   markers: {
    width: 15,
    height: 15,
    strokeWidth: 10,
    strokeColor: undefined,
    fillColors: undefined,
    radius: 0,
    customHTML: undefined,
    onClick: undefined,
    offsetX: -10,
    offsetY: 3,
   },
   itemMargin: {
    horizontal: 5,
    vertical: 10,
   },
   onItemClick: {
    toggleDataSeries: true,
   },
   onItemHover: {
    highlightDataSeries: true,
   },
  },
  markers: {
   size: 0,
  },
  xaxis: {
   categories: Label && Label[0],
  },
  plotOptions: {
   radar: {
    size: 160,
   },
  },
 };
 return (
  <Box>
   <Box>
    {loading ? (
     <BarChartLoading />
    ) : (
     <ReactApexChart series={series && series} options={options} type="radar" />
    )}
   </Box>
   <Box mt="-18rem" mb="6rem">
    <TableList />
   </Box>
   <Box
    position="fixed"
    bottom="0"
    backgroundColor="#ffffff"
    height="5.5rem"
    w="100%"
    zIndex={2}
   >
    <Flex float="right" mr="20rem">
     <Box mt={4}>
      <Flex alignItems="center" gap="2">
       <>
        <Button
        variant={'outline'}
         onClick={() => router.goBack()}
        >
         Back
        </Button>
        <Button
         onClick={handleClose}
        >
         Close
        </Button>
       </>
      </Flex>
     </Box>
    </Flex>
   </Box>
  </Box>
 );
};

export default SummaryComparasion;
