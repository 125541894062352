import { createSlice } from "@reduxjs/toolkit";

import {
 getJourney,
 getSimulation,
 getSimulationById,
 deleteSimulation,
 upload,
 download,
 levels,
 components,
 subComponents,
 describeChange,
 saveBusiness,
 compareBusinessCase,
 getJourneytorCompare,
} from "state/simulation/thunk";

interface historyTs {
 userName: string;
 userFullName: string;
 startUploadTime: string;
 endUploadTime: string;
}
interface InitialState {
 profile: Record<string, unknown>;
 loading: boolean;
 loadingDownload: boolean;
 error: unknown;
 journey: any;
 journeyId: any;
 journeyLevel: any;
 histories: historyTs;
 successUpload: boolean;
 fileUpload: any;
 uploadResponse: any;
 levelName: string;
 level: string;
 levelResponse: any;
 levelCount: string;
 component: string[];
 componentResponse: any;
 subComponent: string[];
 subComponentsResponse: any;
 describeResponse: any;
 describeChangeResponse: any;
 describeChangePayload: any;
 simulation: any;
 successDelete: any;
 searchQuery: string;
 simulationDetail: any;
 finalCost: any;
 businessCase: any;
 selectCompare: any;
 compare: any;
 auditLog:any;
}

const initialState: InitialState = {
 profile: {},
 loading: false,
 loadingDownload: false,
 error: null,
 journey: {},
 journeyId: "",
 journeyLevel:"",
 histories: {
  userName: "",
  userFullName: "",
  startUploadTime: "",
  endUploadTime: "",
 },
 successUpload: false,
 fileUpload: {},
 uploadResponse: {},
 levelName: "",
 level: "",
 levelCount: "",
 component: [],
 levelResponse: {},
 componentResponse: {},
 subComponent: [],
 subComponentsResponse: {},
 describeResponse: [],
 describeChangeResponse: {},
 describeChangePayload: {},
 simulation: {},
 successDelete: false,
 searchQuery: "",
 simulationDetail: {},
 finalCost: "",
 businessCase: {},
 selectCompare: [],
 compare: {},
 auditLog:{}
};

const simulationAggregate = createSlice({
 name: "simulations",
 initialState,
 reducers: {
  clear: () => initialState,
  setSearchQuery: (state, action) => {
    const { value } = action.payload
    state.searchQuery = value
  },
  setJourneyId: (state, action) => {
   state.journeyId = action.payload;
  },
  setJourneyLevel: (state, action) => {
    state.journeyLevel = action.payload;
   },
  removeJourneyId: (state) => {
    state.journeyId = [];
   },
  updateUploadResponse: (state, action) => {
   state.uploadResponse = action.payload;
  },
  updateFileUpload: (state, action) => {
   state.fileUpload = action.payload;
  },
  setLevelName: (state, action) => {
   state.levelName = action.payload;
  },
  setLevel: (state, action) => {
   state.level = action.payload;
  },
  setComponent: (state, action) => {
   state.component.push(action.payload);
  },
  removeArrayAllComponent: (state, action) => {
   state.component = [];
  },
  setFinalCost: (state, action) => {
   state.finalCost = action.payload;
  },
  removeArrayComponent: (state, action) => {
   function removeItem(array: any, item: string) {
    for (var i in array) {
     if (array[i] == item) {
      array.splice(i, 1);
      break;
     }
    }
   }
   removeItem(state.component, action.payload);
  },
  setSubComponent: (state, action) => {
   state.subComponent.push(action.payload);
  },
  removeArraySubComponent: (state, action) => {
   function removeItem(array: any, item: string) {
    for (var i in array) {
     if (array[i] == item) {
      array.splice(i, 1);
      break;
     }
    }
   }
   removeItem(state.subComponent, action.payload);
  },
  removeArrayAllSubComponent: (state, action) => {
   state.subComponent = [];
  },
  setDescribe: (state) => {
   let newData: any = [];
   state.subComponentsResponse.map((item: any) => {
    const { details } = item;
    details.map((data: any) => {
     const { costLabelName, identityCode, identityName, sequence } = data;
     let dataFilter = state.subComponent
      .map((items) => items)
      .includes(identityCode);
     if (dataFilter) {
      newData.push({
       name: item.identityName,
       data: [
        {
         costLabelName,
         identityCode,
         identityName,
         sequence,
         changePercentage: 0,
        },
       ],
      });
     }
    });
   });
   let dataChecking = state.subComponentsResponse.filter((item: any) => {
    const { isShown } = item;
    return !isShown;
   });
   dataChecking.map((item: any) => {
    const { costLabelName, identityCode, identityName, sequence } = item;
    newData.push({
     name: item.identityName,
     data: [
      {
       costLabelName,
       identityCode,
       identityName,
       sequence,
       changePercentage: 0,
      },
     ],
    });
   });
   state.describeResponse = newData;
   // let data = state.subComponentsResponse.map((item:{
   //   costLabelName: string;
   //   details: any[];
   //   identityCode: string;
   //   identityName: string;
   //   isShown: boolean;
   //   sequence: number;
   // }) => item.details.filter((detail: {
   //   costLabelName: string;
   //   identityName: string;
   //   identityCode: string;
   //   sequence: number;
   // }) => state.subComponent.map(items => items).includes(detail.identityCode))).filter((item:any) => item.length).flat()
   // const finalData = state.subComponentsResponse.filter((item: any) => !item.isShown);
   // state.describeResponse = finalData.concat(data);
  },
  setselectCompare: (state, action) => {
   state.selectCompare.push(action.payload);
  },
  removeArrayselectCompare: (state, action) => {
   function removeItem(array: any, item: string) {
    for (var i in array) {
     if (array[i] == item) {
      array.splice(i, 1);
      break;
     }
    }
   }
   removeItem(state.selectCompare, action.payload);
  },
  removeSselectCompare: (state) => {
    state.selectCompare = []
  },
  setAuditLog:(state, action) => {
    state.auditLog = action.payload
  }
 },
 extraReducers: (builder) => {
  builder.addCase(getJourney.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getJourney.fulfilled, (state, action) => {
   state.loading = false;
   state.journey = action.payload.sort((a: any, b: any) =>
    a.journeyName.toLowerCase() > b.journeyName.toLowerCase() ? 1 : -1
   );
  });

  builder.addCase(getJourney.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  builder.addCase(upload.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(upload.fulfilled, (state, action) => {
   state.successUpload = action.payload.success;
   state.fileUpload = action.meta;
   state.loading = false;
   state.uploadResponse = action.payload.result.map(
    ({ id, productName, costAnalysisPerVolumeId, orderFrequency, volume, volumeType, isNewVolume }: any) => {
     return {
      id,
      productName,
      costAnalysisPerVolumeId,
      volume,
      volumeType,
      orderFrequency,
      isNewVolume
     };
    }
   );
  });

  builder.addCase(upload.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(download.pending, (state) => {
   state.loadingDownload = true;
   state.error = null;
  });

  builder.addCase(download.fulfilled, (state, action) => {
   state.loadingDownload = false;
   state.error = action.payload;
  });

  builder.addCase(download.rejected, (state, action) => {
   state.loadingDownload = false;
   state.error = action.payload;
  });

  builder.addCase(levels.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(levels.fulfilled, (state, action) => {
   state.loading = false;
   state.levelResponse = action.payload.result;
  });

  builder.addCase(levels.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(components.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(components.fulfilled, (state, action) => {
   state.loading = false;
   state.componentResponse = action.payload.result;
  });

  builder.addCase(components.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(subComponents.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(subComponents.fulfilled, (state, action) => {
   state.loading = false;
   state.subComponentsResponse = action.payload.result;
  });

  builder.addCase(subComponents.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(describeChange.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(describeChange.fulfilled, (state, action) => {
   state.loading = false;
   state.describeChangePayload = action.meta.arg;
   state.describeChangeResponse = action.payload.result;
  });

  builder.addCase(describeChange.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(saveBusiness.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(saveBusiness.fulfilled, (state, action) => {
   state.loading = false;
   state.describeChangePayload = action.meta.arg;
   state.describeChangeResponse = action.payload.result;
  });

  builder.addCase(saveBusiness.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  builder.addCase(getSimulation.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getSimulation.fulfilled, (state, action) => {
   state.loading = false;
   state.simulation = action.payload;
  });

  builder.addCase(getSimulation.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  builder.addCase(getSimulationById.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getSimulationById.fulfilled, (state, action) => {
   state.loading = false;
   state.simulationDetail = action.payload;
  });

  builder.addCase(getSimulationById.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(deleteSimulation.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(deleteSimulation.fulfilled, (state, action) => {
   state.loading = false;
  });

  builder.addCase(deleteSimulation.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(getJourneytorCompare.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getJourneytorCompare.fulfilled, (state, action) => {
   state.loading = false;
   state.businessCase = action.payload.result;
  });

  builder.addCase(getJourneytorCompare.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(compareBusinessCase.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(compareBusinessCase.fulfilled, (state, action) => {
   state.loading = false;
   state.compare = action.payload.result;
  });

  builder.addCase(compareBusinessCase.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
 },
});

export const actions = {
 ...simulationAggregate.actions,
 getJourney,
 download,
 upload,
 levels,
 components,
 subComponents,
 describeChange,
 saveBusiness,
 getSimulation,
 getSimulationById,
 deleteSimulation,
 getJourneytorCompare,
 compareBusinessCase,
};

export const reducer = simulationAggregate.reducer;
