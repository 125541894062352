import { ReactElement, useState, useEffect } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "state/store";
import { Box, Flex, Heading, Spacer } from "@chakra-ui/layout";
import {
 Image,
 Button,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalBody,
 Center,
 Spinner,
 Grid
} from "@chakra-ui/react";
import { NumberLoading, TextLoading } from "components/Loading";
import { actions as dataLoadingActions } from "state/pricings/dataLoading/slice";
import { actions as pricingListAction } from "state/pricings/pricingList/slice";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import StepInfo from "components/StepInfo";
import Dropzone from "react-dropzone";
import Layout from "views/Layout";
import UploadIcon from "assets/images/uploadIcon.png";
import Success from "assets/images/successImage.png";

const DataLoading = (): ReactElement => {
 const history = useHistory();
 const dispatch = useAppDispatch();
 const [isOpenModal, setOpenModal] = useState(false);
 const [modalDialog, setModalDialog] = useState("");

 const { loading, successUpload } = useSelector(
  (state: RootState) => ({
   loading: state.pricingDataLoading.loading,
   successUpload: state.pricingDataLoading.successUpload,
  }),
  shallowEqual
 );

 useEffect(() => {
  return history.listen((location) => {});
 }, [history]);

 useEffect(() => {
  if (loading) {
   const unloadCallback = (event: any) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
   };

   window.addEventListener("beforeunload", unloadCallback);
   return () => window.removeEventListener("beforeunload", unloadCallback);
  }

  if (successUpload === true) {
   setOpenModal(true);
   setModalDialog("Data has been uploaded successfully");
   dispatch(dataLoadingActions.updateSuccessUpload(false));
  }
  window.scrollTo(0, 0);
 }, [successUpload, loading]);

 const handleDrop = (acceptedFiles: any) => {
  dispatch(
   dataLoadingActions.upload({
    payload: acceptedFiles as any,
   })
  );
 };

 const onClickDownload = () => {
  dispatch(dataLoadingActions.downloadTemplate());
 };

 const onCloseModal = () => {
  setOpenModal(false);
  dispatch(pricingListAction.setSearchQuery({ value: "" }));
  history.push(`/pricings`);
 };

 return (
  <Layout>
  <Heading>Pricing</Heading>
   <Box mt="4" color="rgba(26, 26, 26, 0.36)" fontWeight={400} fontSize="16px">
    In this module, you can see all the pricing that has been uploaded and{" "}
    <br />
    check all the detailed information of the pricing including the cost
    summary.
   </Box>

   <Box pb="1rem">
    <Grid templateColumns="repeat(4, 1fr)" gap={8} pt="2.5rem" pb="1rem">
     <StepInfo>
      <Box
       as="p"
       pb="3px"
       fontSize="48"
       textColor="rgba(26, 26, 26, 1)"
       fontWeight="600"
      >
       {loading ? <NumberLoading /> : "01"}
      </Box>
      <Box
       as="p"
       fontSize="18px"
       fontWeight="500"
       lineHeight="23.4px"
       color="rgba(26, 26, 26, 0.56)"
      >
       {loading ? (
        <TextLoading />
       ) : (
        "Upload multiple pricing’s data into the system"
       )}
      </Box>
     </StepInfo>
     <StepInfo>
      <Box
       as="p"
       pb="3px"
       fontSize="48"
       textColor="rgba(26, 26, 26, 1)"
       fontWeight="600"
      >
       {loading ? <NumberLoading /> : "02"}
      </Box>
      <Box
       as="p"
       fontSize="18px"
       fontWeight="500"
       lineHeight="23.4px"
       color="rgba(26, 26, 26, 0.56)"
      >
       {loading ? (
        <TextLoading />
       ) : (
        "Check all the detailed information of the pricing"
       )}
      </Box>
     </StepInfo>
     <StepInfo>
      <Box
       as="p"
       pb="3px"
       fontSize="48"
       textColor="rgba(26, 26, 26, 1)"
       fontWeight="600"
      >
       {loading ? <NumberLoading /> : "03"}
      </Box>
      <Box
       as="p"
       fontSize="18px"
       fontWeight="500"
       lineHeight="23.4px"
       color="rgba(26, 26, 26, 0.56)"
      >
       {loading ? (
        <TextLoading />
       ) : (
        "Cost Summary that is calculated by the system"
       )}
      </Box>
     </StepInfo>
     <StepInfo>
      <Box
       as="p"
       pb="3px"
       fontSize="48"
       textColor="rgba(26, 26, 26, 1)"
       fontWeight="600"
      >
       {loading ? <NumberLoading /> : "04"}
      </Box>
      <Box
       as="p"
       fontSize="18px"
       fontWeight="500"
       lineHeight="23.4px"
       color="rgba(26, 26, 26, 0.56)"
      >
       {loading ? <TextLoading /> : "Simulation for individual pricing"}
      </Box>
     </StepInfo>
    </Grid>
   </Box>
   {/* {loading && (
        <Box textAlign={"center"}>
     <Button
      variant={"link"}
       isLoading={loading}
       loadingText="Downloading..."
      >
       Download template
      </Button>
     <Center>
     <Box
     mt={5}
      w="20.25rem"
      h="23.75rem"
      borderRadius="2.5rem"
      border="2px dashed #9DA1A7"
      box-sizing="border-box"
     >
        <Spinner mb={'4rem'}  mt={'4rem'} color="#9DA1A7" size='xl' />
      <Box mt={5}>
        <Button
       bg="#9DA1A7"
       w="200px"
       h="59"
       borderRadius="5px"
       fontSize="18"
       color="white"
       disabled
      >
       Select file
      </Button>
      </Box>
     </Box>
     </Center>
    </Box>
   )} */}
    <Box textAlign={'center'}>
      <Button
      variant={"link"}
       onClick={() => onClickDownload()}
       isLoading={loading}
       loadingText="Downloading..."
      >
       Download template
      </Button>
     <Box>
     <Dropzone
      onDrop={handleDrop}
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
     >
      {({ getRootProps, getInputProps }) => (
       <Center>
        <Box
        {...getRootProps({ className: "dropzone" })}
        textAlign={'center'}
        mt={5}
        w="461px"
        h="247px"
        borderRadius="5px"
        border="1px dashed #9DA1A7"
        // box-sizing="border-box"
        display="inline-block"
       >
        <Center><Image src={UploadIcon} w="33.75px"  alt="Bulp" mt={5} /></Center>
        <Flex>
         <Spacer />
         {!loading ? (
          <Box fontSize="18" textColor="#43464B" pt="23px" textAlign="center">
           Drag and drop <br />
           or
          </Box>
         ) : (
          <Box fontSize="16" textColor="#43464B" pt="23px" textAlign="center">
           {/* {fileUpload.arg.payload.file[0].name} */}
           <Spinner mb={'4rem'}  mt={'4rem'} color="#9DA1A7" size='xl' />
          </Box>
         )}
         <Spacer />
        </Flex>

        <Button
         bg="#0072CF"
         mt={5}
         w="200px"
         h="59"
         borderRadius="5px"
         fontSize="18"
         color="white"
         _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
        >
         Select file
         <input {...getInputProps()} />
        </Button>
       </Box>
       </Center>
      )}
     </Dropzone>
     </Box>
    </Box>

   <Modal isOpen={isOpenModal} onClose={() => setOpenModal(false)}>
    <ModalOverlay />
    <ModalContent
     w="33.188rem"
     h="18.rem"
     margin="auto"
     boxShadow="0px 0px 56px rgba(157, 161, 167, 0.37)"
     borderRadius="3.938rem"
     p="1rem"
     mt="10%"
    >
     <ModalHeader fontSize="1.5rem" textAlign="center">
      {modalDialog}
     </ModalHeader>
     <ModalBody>
      <Image display="block" ml="auto" mr="auto" src={Success} alt="Bulp" />
      <Button
       onClick={() => onCloseModal()}
       bg="#0072CF"
       display="block"
       mt="1.594rem"
       ml="auto"
       mr="auto"
       w="252px"
       h="59"
       borderRadius="50"
       fontSize="18"
       color="white"
       _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
      >
       Got it
      </Button>
     </ModalBody>
    </ModalContent>
   </Modal>
  </Layout>
 );
};

export default DataLoading;
