import { Fragment, ReactElement } from "react";
import { Image, Flex, Tooltip, Spacer, Box } from "@chakra-ui/react";
import s from "shortid";
import next from "assets/images/nextIcon.png";
import previous from "assets/images/previousIcon.png";

interface PaginationProps {
 pageNumber: number;
 onChange: (newPage: number) => void;
 currentPage: number;
 totalRowsPage: number;
 totalAllPage: number;
}

const Pagination = ({
 pageNumber,
 onChange,
 currentPage,
 totalRowsPage,
 totalAllPage,
}: PaginationProps): ReactElement => {
 const isLastPage = pageNumber === currentPage;
 const intialCount =
  currentPage === 1 ? 1 : currentPage * totalRowsPage + 1 - totalRowsPage;
 const totalPerPage = isLastPage
  ? totalAllPage
  : currentPage > 1
  ? currentPage * totalRowsPage + 1
  : currentPage * totalRowsPage;
 return (
  <Box w="100%">
   <Flex>
    <Spacer />
    <Box as="p" m="auto" pr="2.3rem">
     {intialCount}-{totalPerPage} of {totalAllPage}
    </Box>
    <Box>
     <Flex>
      <button
       onClick={() => onChange(currentPage - 1)}
       disabled={currentPage === 1}
      >
       <Tooltip label={currentPage !== 1 ? "Previous" : ""}>
        <Image src={previous} alt="Bulp" />
       </Tooltip>
      </button>
      {Array.from(Array(pageNumber).keys()).map((number, index, array) => {
       const properpageNumber = number + 1;
       const isCurrentPage = properpageNumber === currentPage;

       const hasLeftEllipsis = array.length > 5 && properpageNumber - 1 > 1;
       const hasRightEllipsis =
        array.length > 5 && properpageNumber + 1 < array.length;
       const button = (
        <Box
         key={index}
         w="20px"
         textAlign="center"
         mt="3px"
         cursor={isCurrentPage ? "context-menu" : "pointer"}
         onClick={!isCurrentPage ? () => onChange(properpageNumber) : () => {}}
         textDecoration={isCurrentPage ? "underline" : "none"}
        >
         {properpageNumber}
        </Box>
       );
       const ellipsis = (
        <Box w="20px" as="span" mt="3px" textAlign="center">
         &hellip;
        </Box>
       );
       if (array.length <= 5) {
        return button;
       } else {
        if (index === 0 || index === array.length - 1 || isCurrentPage) {
         return button;
        } else if (
         properpageNumber === currentPage - 1 ||
         properpageNumber === currentPage + 1
        ) {
         if (properpageNumber === currentPage - 1) {
          return (
           <Fragment key={s.generate()}>
            {hasLeftEllipsis && ellipsis}
            {button}
           </Fragment>
          );
         } else {
          return (
           <Fragment key={s.generate()}>
            {button}
            {hasRightEllipsis && ellipsis}
           </Fragment>
          );
         }
        } else {
         return null;
        }
       }
      })}
      <button onClick={() => onChange(currentPage + 1)} disabled={isLastPage}>
       <Tooltip label={isLastPage ? "" : "Next"}>
        <Image src={next} alt="Bulp" />
       </Tooltip>
      </button>
     </Flex>
    </Box>
   </Flex>
  </Box>
 );
};

export default Pagination;
