import { createSlice } from '@reduxjs/toolkit';
import { getUserInfo } from 'state/auth/thunk';

interface InitialState {
  profile: Record<string, unknown>
  result: any
  loading: boolean
  error: unknown
}

const initialState: InitialState = {
  profile: {},
  result: {
    id: '',
    userName: '',
    fullName: ''
  },
  loading: false,
  error: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clear: () => initialState,
    logout: () => {
      if (typeof process.env.REACT_APP_COOKIE_NAME === 'string') {
        window.sessionStorage.removeItem(process.env.REACT_APP_COOKIE_NAME)
      }

      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.result = action.payload
      state.loading = false
    })

    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  }
})

export const actions = {
  ...authSlice.actions,
  getUserInfo
}

export const reducer = authSlice.reducer
