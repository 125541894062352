import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";

export const user = createAsyncThunk(
 "third-party/user",
 async (params: any) => {
  const response: any = await HttpService.get(`Users`, {
   params,
  });
  return response.result;
 }
);
export const getThirdPartyToShow = createAsyncThunk(
 "third-party/thirdParty",
 async (params:any) => {
  const response: any = await HttpService.get(`ThirdParties`, { params });
  return response.result;
 }
);
export const getThirdPartyToAdd = createAsyncThunk(
    "third-party/thirdPartyToAdd",
    async (params:any) => {
     const response: any = await HttpService.get(`ThirdParties`, { params });
     return response.result;
    }
   );
export const getThirdPartyById = createAsyncThunk(
 "third-party/getThirdPartyById",
 async (id: any) => {
  const response: any = await HttpService.get(`ThirdParties/${id}`);
  return response.result;
 }
);
export const addThirdParty = createAsyncThunk(
 "third-party/addThirdParty",
 async (payload: any) => {
  const response: any = await HttpService.post(`ThirdParties`, payload);
  return response.result;
 }
);

export const editThirdPartyById = createAsyncThunk(
 "third-party/editThirdPartyById",
 async (payload: any) => {
  const response: any = await HttpService.put(
   `ThirdParties/${payload.id}`,
   payload.data
  );
  return response.result;
 }
);

export const deleteThirdPartyById = createAsyncThunk(
 "third-party/deleteThirdPartyById",
 async ({ id }: any) => {
  const response: any = await HttpService.delete(`ThirdParties/${id}`);
  return response.result;
 }
);

export const getThirdPartyUser = createAsyncThunk(
 "third-party/getThirdPartyUser",
 async (params: any) => {
  const response: any = await HttpService.get(`ThirdParties/Users`, { params });
  return response.result;
 }
);
export const getThirdPartyUserById = createAsyncThunk(
 "third-party/getThirdPartyUserById",
 async (id: any) => {
  const response: any = await HttpService.get(`ThirdParties/Users/${id}`);
  return response.result;
 }
);
export const editThirdPartyUserById = createAsyncThunk(
 "third-party/editThirdPartyUserById",
 async (payload: any) => {
  const response: any = await HttpService.put(
   `ThirdParties/Users/${payload.id}`,
   payload.data
  );
  return response.result;
 }
);
export const postThirdPartyUser = createAsyncThunk(
 "third-party/postThirdPartyUser",
 async (payload: any) => {
  const response: any = await HttpService.post(`ThirdParties/Users`, payload);
  return response.result;
 }
);
export const deleteThirdPartyUserById = createAsyncThunk(
 "third-party/deleteThirdPartyUserById",
 async ({ id }: any) => {
  const response: any = await HttpService.delete(`ThirdParties/Users/${id}`);
  return response.result;
 }
);
export const isStarted = createAsyncThunk("third-party/isStarted", async () => {
 const response: any = await HttpService.get(`Tenant/IsStarted`);
 localStorage.setItem("isStarted", response.result);
 return response.result;
});
export const getStarted = createAsyncThunk(
 "third-party/getStarted",
 async (objects: any) => {
  const config = {
   headers: {
    notUseToast: true,
   },
  };
  const response: any = await HttpService.post(
   `Tenant/GetStarted`,
   objects,
   config
  );
  localStorage.setItem("isStarted", response.result);
  return response.result;
 }
);
export const configuration = createAsyncThunk(
 "third-party/configuration",
 async (payload: any) => {
  const response: any = await HttpService.put(`Tenant/Configurations`, payload);
  return response.result.isEnableThirdParty;
 }
);
export const currencyConfiguration = createAsyncThunk(
    "third-party/currencyConfiguration",
    async (payload: any) => {
     const response: any = await HttpService.put(`Tenant/Configurations/SetupCurrency`, payload);
     return response.result
    }
   );
export const configurationStatus = createAsyncThunk(
 "third-party/configurationStatus",
 async () => {
  const response: any = await HttpService.get(`Tenant/Configurations`);
  return response.result;
 }
);
