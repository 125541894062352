import { ReactElement, useEffect, useState, useRef } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import {
 BarChartLoading,
 TableLoading,
 StepperLoading,
} from "components/Loading";
import { RootState } from "state/store";
import { Flex, Box, Spacer } from "@chakra-ui/layout";
import {
 Image,
 Table as ChakraTable,
 Thead,
 Tr,
 Th,
 Tbody,
 Td,
 Tabs,
 TabList,
 TabPanels,
 Tab,
 TabPanel,
 Select,
 Button,
 Text,
 TabIndicator,
 Center,
 RadioGroup,
 Stack,
 Radio,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { actions as pricingCostSummaryAction } from "state/pricings/costSummary/slice";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import VolumeSection from "views/EditPricings/CostSummary/VolumeSection";
import Checklist from "assets/images/CheckBox.png";
import ToolbarBottom from "views/EditPricings/ToolbarBottom";
import "utils/number-utils";
import { BarChart, TreemapChart } from "components/ApexChart";
import { isEmpty } from "lodash";
import Magnificiant from "components/Magnificiant";
import { ArrowDownIcon } from "components/Icons";
const CostSummary = (): ReactElement => {
 const [data, setData] = useState<any>({});
 const [totalCostVolume, setTotalCostVolume] = useState<any>({});
 const [showChart, setShowChart] = useState<boolean>(true);
 const [isPercentage1st, setIsPercentage1st] = useState<boolean>(true);
 const [isPercentage2nd, setIsPercentage2nd] = useState<boolean>(true);

 const dispatch = useAppDispatch();
 const location = useLocation<{ status: number; volumeId: string }>();
 const searchParams = new URLSearchParams(location.search);
 const pricingId = location.pathname.split("/")[3];
 const pricingPage = location.pathname.split("/")[2];
 const [currentVolume, setCurrentVolume] = useState<number>(0);
 const changeVolumeReff = useRef<null | HTMLDivElement>(null);
 const [treemapSummary1st, setTreemapSummary1st] = useState("summary");
 const [treemapSummary2nd, setTreemapSummary2nd] = useState("summary");

 const { summaryCostChart, isLoading, detailPerVolumeId, isEditing } =
  useSelector(
   (state: RootState) => ({
    summaryCostChart: state.pricingCostSummary.costSummaryChart,
    isLoading: state.pricingCostSummary.loading,
    detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId,
    isEditing: state.editPricingCostDrivers.isEditing,
   }),
   shallowEqual
  );

 const changeTreemap1st = (value: any) => {
  setTreemapSummary1st(value);
 };
 const changeTreemap2nd = (value: any) => {
  setTreemapSummary2nd(value);
 };
 const {
  volumes,
  loading,
  currency,
  summaryCost,
  costSummaryById,
  costBreakdownContributionnDetail,
  costBreakdownContributionnDetail2nd,
  volumeIdTreemap1st,
  volumeIdTreemap2nd,
  volumeId,
  costAnalysisPerVolumeId,
  editPricingCostDrivers,
 } = useSelector(
  (state: RootState) => ({
   volumeId: state.editPricingCostDrivers.volumeId,
   costAnalysisPerVolumeId:
    state.editPricingCostDrivers.costAnalysisPerVolumeId,
   volumes: state.pricingCostSummary.costSummaryById.volumes,
   currency: state.pricingCostSummary.costSummaryById.currency,
   loading: state.editPricingCostDrivers.loading,
   summaryCost: state.pricingCostSummary.costSummaryById.summary,
   thirdPartyId: state.editPricingCostDrivers.thirdPartyId,
   costSummaryById: state.pricingCostSummary.costSummaryById,
   costBreakdownContributionnDetail:
    state.editPricingCostDrivers.costBreakdownContributionnDetail.result,
   costBreakdownContributionnDetail2nd:
    state.editPricingCostDrivers.costBreakdownContributionnDetail2nd.result,
   volumeIdTreemap1st: state.editPricingCostDrivers.volumeIdTreemap1st,
   volumeIdTreemap2nd: state.editPricingCostDrivers.volumeIdTreemap2nd,
   isReplace: state.copyAndReplaceCostDrivers.isReplace,
   isCopy: state.copyAndReplaceCostDrivers.isCopy,
   statusAndVolume: state.editPricingCostDrivers.statusAndVolume,
   editPricingCostDrivers:
    state.editPricingCostDrivers.detailPerVolumeId.result,
  }),
  shallowEqual
 );

 const handleChangeVolume = async (event: any) => {
  const index = event.currentTarget.selectedIndex;
  const volumeId1st = event.currentTarget.options[index].id;
  dispatch(pricingCostDriverAction.setVolumeIdTreemap1st(volumeId1st));
  if (volumeId1st) {
   const payload = {
    perVolumeId: volumeId1st,
   };
   dispatch(
    pricingCostDriverAction.getCostBreakdownContributionnDetail(payload)
   );
  }
 };
 const handleChangeVolume2nd = async (event: any) => {
  const index = event.currentTarget.selectedIndex;
  const volumeId2nd = event.currentTarget.options[index].id;
  dispatch(pricingCostDriverAction.setVolumeIdTreemap2nd(volumeId2nd));
  if (volumeId2nd) {
   const payload = {
    perVolumeId: volumeId2nd,
   };
   dispatch(
    pricingCostDriverAction.getCostBreakdownContributionnDetail2nd(payload)
   );
  }
 };

 useEffect(() => {
  const payload = {
   id: pricingId,
   status: location.state.status,
  };
  dispatch(pricingCostSummaryAction.getPricingStatusAndVolume(payload));
  dispatch(pricingCostSummaryAction.pricingThirdParty(payload));
 }, [pricingId]);

 useEffect(() => {
  const payload = {
   id: pricingId,
   thirdPartyId: searchParams.get("thirdPartyId") || null,
   status: location.state.status,
  };
  dispatch(pricingCostSummaryAction.pricingCostSummaryChart(payload));
 }, [pricingId]);

 useEffect(() => {
  const totalCost = {
   costBreakdown: "Total Cost",
   currency: currency ? detailPerVolumeId?.result?.currency.code : "-",
   finalCost: detailPerVolumeId?.result?.unitCost,
  };
  setTotalCostVolume(totalCost);

  const mainCostDrivers = detailPerVolumeId?.result?.costDrivers
   .filter((i: any) => i.parentCostDriver === null)
   .sort((a: any, b: any) =>
    a.configuration.sequence < b.configuration.sequence ? -1 : 1
   );

  setData(mainCostDrivers);
 }, [volumes, currentVolume, loading, location.state]);

 const columnHeaders = [
  { name: "costBreakdown", label: "Cost Breakdown", align: "left" },
  { name: "currency", label: "Currency", align: "left" },
  {
   name: "finalCost",
   label: `${costSummaryById?.configuration?.sizeUnit?.name}`,
   align: "right",
  },
 ];

 const volumeSummaryColumnHeaders = [
  { name: "volume", label: "Volume", align: "left" },
  { name: "currency", label: "Currency", align: "left" },
  {
   name: "finalPrice",
   label: `${costSummaryById?.configuration?.sizeUnit?.name}`,
   align: "right",
  },
 ];

 const costDriverDetail: any = [];
 const stepperData: any = [];

 Array.isArray(data) &&
  data.length > 0 &&
  data.map((value: any, index: number) => {
   const startSequence = 3;
   const newStepper = {
    sequence: startSequence + index,
    pageName: value.configuration.identityName
     .replace(/\s+/g, "-")
     .toLowerCase(),
    description: value.configuration.identityName,
   };
   const newArray = {
    costBreakdown: value.configuration.identityName,
    isPercentage: value.configuration.isPercentage,
    currency: value?.totalCostPricing?.currency?.code
     ? value?.totalCostPricing?.currency?.code
     : "-",
    finalCost: value.totalCostPricing?.amount,
   };

   stepperData.push(newStepper);
   costDriverDetail.push(newArray);
  });
 costDriverDetail.push(totalCostVolume);
 const thirdPartiesChart = summaryCostChart.thirdParties;
 const volumesChart = summaryCostChart.volumes;
 const tmpBarVolumes = volumesChart?.map((x: any) => ({
  x: x.chartData.map((item: any) => item.costDriver),
  y: x.chartData.map((item: any) => item.totalCost),
  label:
   x.thirdParty?.code !== undefined
    ? x.volume + "-" + x.thirdParty.code
    : x.volume,
 }));
 const seriesVolumes =
  tmpBarVolumes &&
  tmpBarVolumes?.map((item: any, index: number) => ({
   name: item.label,
   data: item.y,
   labelName: item.x,
  }));

 const filterTmpBarThirdParties = thirdPartiesChart?.map((item: any) => ({
  label: item.thirdParty,
  data: item.chartData.filter((x: any) => x.totalCost !== 0),
 }));

 const tmpBarThirdParties = filterTmpBarThirdParties?.map((x: any) => ({
  x: x.data.map((item: any) => item.costDriver),
  y: x.data.map((item: any) => item.totalCost),
  label: x.label,
 }));
 const seriesThirdParty =
  tmpBarThirdParties &&
  tmpBarThirdParties?.map((item: any, index: number) => ({
   name: item.label,
   data: item.y,
   labelName: item.x,
  }));
 const treemapDataSummary = costBreakdownContributionnDetail?.summary?.map(
  (x: any) => ({
   name: x.identityName,
   data: [
    {
     x: x.identityName,
     y: isPercentage1st
      ? (x.contribution * 100).toLocaleString("en-US", {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
        }) + "%"
      : x.cost,
     z: x.cost,
     addon: x.additionalFields,
    },
   ],
  })
 );
 const treemapDataLevel = costBreakdownContributionnDetail?.level1;
 const treemapDataLevel2 = costBreakdownContributionnDetail?.level2;
 const tmpTreemap =
  treemapSummary1st === "level1" ? treemapDataLevel : treemapDataLevel2;
 const series =
  treemapSummary1st === "summary"
   ? !isEmpty(treemapDataSummary) && treemapDataSummary
   : !isEmpty(tmpTreemap) &&
     tmpTreemap?.map((x: any) => ({
      name: x.identityName,
      data: x.details?.map((item: any) => ({
       x: item.identityName,
       y: isPercentage1st
        ? (item.contribution * 100).toLocaleString("en-US", {
           minimumFractionDigits: 2,
           maximumFractionDigits: 2,
          }) + "%"
        : item.cost,
       z: item.cost,
       addon: item.additionalFields,
      })),
     }));
 const treemapDataSummary2nd = costBreakdownContributionnDetail2nd?.summary.map(
  (x: any) => ({
   name: x.identityName,
   data: [
    {
     x: x.identityName,
     y: isPercentage2nd
      ? (x.contribution * 100).toLocaleString("en-US", {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
        }) + "%"
      : x.cost,
     z: x.cost,
     addon: x.additionalFields,
    },
   ],
  })
 );
 const treemapDataLevel2nd = costBreakdownContributionnDetail2nd?.level1;
 const treemapDataLevelTwo2nd = costBreakdownContributionnDetail2nd?.level2;
 const tmpTreemap2nd =
  treemapSummary2nd == "level1" ? treemapDataLevel2nd : treemapDataLevelTwo2nd;

 const series2nd =
  treemapSummary2nd === "summary"
   ? !isEmpty(treemapDataSummary2nd) && treemapDataSummary2nd
   : !isEmpty(tmpTreemap2nd) &&
     tmpTreemap2nd?.map((x: any) => ({
      name: x.identityName,
      data: x.details?.map((item: any) => ({
       x: item.identityName,
       y: isPercentage2nd
        ? (item.contribution * 100).toLocaleString("en-US", {
           minimumFractionDigits: 2,
           maximumFractionDigits: 2,
          }) + "%"
        : item.cost,
       z: item.cost,
       addon: item.additionalFields,
      })),
     }));

 let findThirdParty: string = "";
 if (volumes && volumes.length > 0) {
  const responseVolumeId = location?.state.volumeId || detailPerVolumeId?.id;
  const haveThirdParty = volumes.find((item: any) => {
   if (item.id === responseVolumeId) {
    return item;
   } else {
    if (item.thirdParty?.id === responseVolumeId) {
     return item;
    }
   }
  });
  if (haveThirdParty) {
   findThirdParty = haveThirdParty?.thirdParty?.code;
  }
 }

 let statusName;
 switch (location.state.status) {
  case 0:
   statusName = "Draft";
   break;
  case 1:
   statusName = "Pending Approval";
   break;
  case 2:
   statusName = "Valid";
   break;
  case 3:
   statusName = "Obsolete";
 }
 const optVolume: any = [];
 volumes?.length > 0 &&
  volumes.map(({ volume, id, thirdParty }: any) =>
   optVolume.push({
    label: thirdParty
     ? `${thirdParty?.code} ${String.toFormatSeparator(volume, 0)}`
     : String.toFormatSeparator(volume, 0),
    value: id,
   })
  );

 return (
  <Box maxHeight={"100vh"} overflow="auto">
   {isLoading ? (
    <Box>
     <BarChartLoading />
    </Box>
   ) : (
    <Box fontSize="18px">
     <Box
      as="p"
      fontWeight="600"
      color="#8b8e91"
      fontSize="22px"
      textAlign={"center"}
      mb="1rem"
     >
      Cost Breakdown
     </Box>
     <Tabs position="relative" variant="unstyled">
      <Center>
       <TabList mb="1em">
        <Tab _focus={{ border: "none" }}>Volume</Tab>
        {summaryCostChart && summaryCostChart.isEnableThirdParty && (
         <Tab _focus={{ border: "none" }}>
          {costSummaryById?.configuration?.thirdPartyLabelName}
         </Tab>
        )}
        <Tab _focus={{ border: "none" }}>Contribution (%)</Tab>
       </TabList>
      </Center>
      <TabIndicator mt="-1.5px" height="2px" bg="blue.500" borderRadius="1px" />
      <TabPanels>
       <TabPanel p="-1rem">
        <Box
         p={"1rem"}
         h="100%"
         borderRadius="5px"
         background="#f8fbff"
         border="1px solid #D7DBE2"
         pb="21px"
         mt="2rem"
        >
         {seriesVolumes && showChart && !isLoading && (
          <BarChart data={seriesVolumes} height={300} />
         )}
         <Box
          color="rgba(26, 26, 26, 0.36)"
          textAlign={"center"}
          fontWeight={600}
         >
          {!showChart && (
           <Box cursor={"pointer"} onClick={(e) => setShowChart(true)}>
            <ArrowDownIcon mb="-5" width={24} height="24px" />
            <Text fontSize={"16px"}>Show More</Text>
           </Box>
          )}
          {showChart && (
           <Box cursor={"pointer"} onClick={(e) => setShowChart(false)}>
            <ArrowDownIcon
             ml="-5"
             width={24}
             height="24px"
             sx={{ transform: "rotate(180deg)" }}
            />
            <Text fontSize={"16px"}>Show Less</Text>
           </Box>
          )}
         </Box>
        </Box>
       </TabPanel>
       {summaryCostChart && summaryCostChart.isEnableThirdParty && (
        <TabPanel p="-1rem">
         <Box
          p={"1rem"}
          h="100%"
          borderRadius="5px"
          background="#f8fbff"
          border="1px solid #D7DBE2"
          pb="21px"
          mt="2rem"
         >
          {seriesThirdParty && showChart && (
           <BarChart height={300} data={seriesThirdParty} />
          )}
          <Box
           color="rgba(26, 26, 26, 0.36)"
           textAlign={"center"}
           fontWeight={600}
          >
           {!showChart && (
            <Box cursor={"pointer"} onClick={(e) => setShowChart(true)}>
             <ArrowDownIcon mb="-5" width={24} height="24px" />
             <Text fontSize={"16px"}>Show More</Text>
            </Box>
           )}
           {showChart && (
            <Box cursor={"pointer"} onClick={(e) => setShowChart(false)}>
             <ArrowDownIcon
              ml="-5"
              width={24}
              height="24px"
              sx={{ transform: "rotate(180deg)" }}
             />
             <Text fontSize={"16px"}>Show Less</Text>
            </Box>
           )}
          </Box>
         </Box>
        </TabPanel>
       )}
       <TabPanel p="-1rem">
        <Box
         p={"1rem"}
         h="100%"
         borderRadius="5px"
         background="#f8fbff"
         border="1px solid #D7DBE2"
         pb="21px"
         mt="2rem"
        >
         {showChart && !isLoading && (
          <Flex>
           <Box w="100%">
            <Box>
             <Box textAlign={"center"}>
              <Button
               mx={1.5}
               disabled={!costBreakdownContributionnDetail}
               size={"sm"}
               variant="link"
               color={treemapSummary1st === "summary" ? "#0061BD" : "#C3C7CD"}
               onClick={() => changeTreemap1st("summary")}
              >
               Summary
              </Button>
              <Button
               mx={1.5}
               disabled={!costBreakdownContributionnDetail}
               size={"sm"}
               variant="link"
               color={treemapSummary1st === "level1" ? "#0061BD" : "#C3C7CD"}
               onClick={() => changeTreemap1st("level1")}
              >
               Level 1
              </Button>
              <Button
               mx={1.5}
               disabled={!costBreakdownContributionnDetail}
               size={"sm"}
               variant="link"
               color={treemapSummary1st === "level2" ? "#0061BD" : "#C3C7CD"}
               onClick={() => changeTreemap1st("level2")}
              >
               Level 2
              </Button>
             </Box>
             <Box pl="1rem">
              <Box alignItems="center" fontWeight="600" mt="1rem" mr="1rem">
               <RadioGroup>
                <Flex>
                 <Stack direction={["column", "row"]}>
                  <Radio
                   isDisabled={!costBreakdownContributionnDetail}
                   size={"sm"}
                   onChange={() => setIsPercentage1st(true)}
                   isChecked={
                    costBreakdownContributionnDetail && isPercentage1st
                   }
                  >
                   <Text fontSize={12}>Percentage</Text>
                  </Radio>
                  <Radio
                   isDisabled={!costBreakdownContributionnDetail}
                   size={"sm"}
                   onChange={() => setIsPercentage1st(false)}
                   isChecked={
                    costBreakdownContributionnDetail && !isPercentage1st
                   }
                  >
                   <Text fontSize={12}>Cost Number</Text>
                  </Radio>
                 </Stack>
                 <Spacer />
                 <Box fontWeight="600" right={10}>
                  <Select
                   size="sm"
                   borderRadius={5}
                   variant="outline"
                   color="#0072CF"
                   fontWeight={600}
                   w={"130px"}
                   h={"30px"}
                   fontSize="12px"
                   onChange={(e) => handleChangeVolume(e)}
                   value={volumeIdTreemap1st}
                   placeholder="Please select volume"
                  >
                   {Array.isArray(volumes) &&
                    volumes.length > 0 &&
                    volumes.map(({ volume, id, thirdParty }) => (
                     <option key={volume} value={id} id={id}>
                      Volume: {String.toFormatSeparator(volume, 0)}
                      {thirdParty && " - "}
                      {thirdParty && thirdParty.code}
                     </option>
                    ))}
                  </Select>
                 </Box>
                </Flex>
               </RadioGroup>
              </Box>
             </Box>
            </Box>
            {series.length > 0 && (
             <TreemapChart
              isPricing={true}
              data={series}
              height={300}
              // isDistribute={treemapSummary1st === "summary" ? true : false}
             />
            )}
            <Center>
             {costBreakdownContributionnDetail && (
              <Magnificiant
               isActive={!costBreakdownContributionnDetail}
               data={
                <Box p="1rem">
                 <Box alignItems="center" fontWeight="600" mt="1rem" mr="1rem">
                  <RadioGroup>
                   <Flex>
                    <Stack direction={["column", "row"]}>
                     <Radio
                      isDisabled={!costBreakdownContributionnDetail}
                      size={"sm"}
                      onChange={() => setIsPercentage1st(true)}
                      isChecked={
                       costBreakdownContributionnDetail && isPercentage1st
                        ? true
                        : false
                      }
                     >
                      <Text fontSize={12}>Percentage</Text>
                     </Radio>
                     <Radio
                      isDisabled={!costBreakdownContributionnDetail}
                      size={"sm"}
                      onChange={() => setIsPercentage1st(false)}
                      isChecked={
                       costBreakdownContributionnDetail && !isPercentage1st
                        ? true
                        : false
                      }
                     >
                      <Text fontSize={12}>Cost Number</Text>
                     </Radio>
                    </Stack>
                    <Spacer />
                    <Box fontWeight="600" right={10}>
                     <Select
                      size="sm"
                      borderRadius={5}
                      variant="outline"
                      color="#0072CF"
                      fontWeight={600}
                      w={"200px"}
                      h={"30px"}
                      fontSize="12px"
                      onChange={(e) => handleChangeVolume(e)}
                      value={volumeIdTreemap1st}
                      placeholder="Please select volume"
                     >
                      {Array.isArray(volumes) &&
                       volumes.length > 0 &&
                       volumes.map(({ volume, id, thirdParty }) => (
                        <option key={volume} value={id} id={id}>
                         Volume: {String.toFormatSeparator(volume, 0)}
                         {thirdParty && " - "}
                         {thirdParty && thirdParty.code}
                        </option>
                       ))}
                     </Select>
                    </Box>
                   </Flex>
                  </RadioGroup>
                 </Box>
                 {series.length > 0 && (
                  <TreemapChart
                   data={series}
                   isPricing={true}
                   height={600}
                   //  isDistribute={treemapSummary1st === "summary" ? true : false}
                  />
                 )}
                </Box>
               }
              />
             )}
            </Center>
           </Box>
           <Box w="100%">
            <Box textAlign={"center"}>
             <Button
              textAlign={"center"}
              mx={1.5}
              disabled={
               costBreakdownContributionnDetail2nd === undefined ? true : false
              }
              size={"sm"}
              variant="link"
              _focus={{ border: "none" }}
              color={treemapSummary2nd === "summary" ? "#0061BD" : "#C3C7CD"}
              onClick={() => changeTreemap2nd("summary")}
             >
              Summary
             </Button>
             <Button
              textAlign={"center"}
              mx={1.5}
              disabled={
               costBreakdownContributionnDetail2nd === undefined ? true : false
              }
              size={"sm"}
              variant="link"
              _focus={{ border: "none" }}
              color={treemapSummary2nd === "level1" ? "#0061BD" : "#C3C7CD"}
              onClick={() => changeTreemap2nd("level1")}
             >
              Level 1
             </Button>
             <Button
              textAlign={"center"}
              mx={1.5}
              disabled={
               costBreakdownContributionnDetail2nd === undefined ? true : false
              }
              size={"sm"}
              variant="link"
              _focus={{ border: "none" }}
              color={treemapSummary2nd === "level2" ? "#0061BD" : "#C3C7CD"}
              onClick={() => changeTreemap2nd("level2")}
             >
              Level 2
             </Button>
            </Box>
            <Box pl="1rem">
             <Box alignItems="center" fontWeight="600" mt="1rem" mr="1rem">
              {/* {costBreakdownContributionnDetail2nd && ( */}
              <RadioGroup>
               <Flex>
                <Stack direction={["column", "row"]}>
                 <Radio
                  isDisabled={!costBreakdownContributionnDetail2nd}
                  size={"sm"}
                  onChange={() => setIsPercentage2nd(true)}
                  isChecked={
                   costBreakdownContributionnDetail2nd && isPercentage2nd
                    ? true
                    : false
                  }
                 >
                  <Text fontSize={12}>Percentage</Text>
                 </Radio>
                 <Radio
                  isDisabled={!costBreakdownContributionnDetail2nd}
                  size={"sm"}
                  onChange={() => setIsPercentage2nd(false)}
                  isChecked={
                   costBreakdownContributionnDetail2nd && !isPercentage2nd
                    ? true
                    : false
                  }
                 >
                  <Text fontSize={12}>Cost Number</Text>
                 </Radio>
                </Stack>
                <Spacer />
                <Box fontWeight="600" right={10}>
                 <Select
                  size="sm"
                  borderRadius={5}
                  variant="outline"
                  color="#0072CF"
                  fontWeight={600}
                  w={"130px"}
                  h={"30px"}
                  fontSize="12px"
                  onChange={(e) => handleChangeVolume2nd(e)}
                  value={volumeIdTreemap2nd}
                  placeholder="Please select volume"
                 >
                  {Array.isArray(volumes) &&
                   volumes.length > 0 &&
                   volumes.map(({ volume, id, thirdParty }) => (
                    <option key={volume} value={id} id={id}>
                     Volume: {String.toFormatSeparator(volume, 0)}
                     {thirdParty && " - "}
                     {thirdParty && thirdParty.code}
                    </option>
                   ))}
                 </Select>
                </Box>
               </Flex>
              </RadioGroup>
              {/* )} */}
             </Box>
            </Box>
            {series2nd.length > 0 && (
             <TreemapChart
              isPricing={true}
              data={series2nd}
              height={300}
              // isDistribute={treemapSummary2nd === "summary" ? true : false}
             />
            )}
            <Center>
             {costBreakdownContributionnDetail2nd && (
              <Magnificiant
               isActive={!costBreakdownContributionnDetail2nd}
               data={
                <Box p="1rem">
                 <Box alignItems="center" fontWeight="600" mt="1rem" mr="1rem">
                  {/* {costBreakdownContributionnDetail2nd && ( */}
                  <RadioGroup>
                   <Flex>
                    <Stack direction={["column", "row"]}>
                     <Radio
                      isDisabled={!costBreakdownContributionnDetail2nd}
                      size={"sm"}
                      onChange={() => setIsPercentage2nd(true)}
                      isChecked={
                       costBreakdownContributionnDetail2nd && isPercentage2nd
                        ? true
                        : false
                      }
                     >
                      <Text fontSize={12}>Percentage</Text>
                     </Radio>
                     <Radio
                      isDisabled={!costBreakdownContributionnDetail2nd}
                      size={"sm"}
                      onChange={() => setIsPercentage2nd(false)}
                      isChecked={
                       costBreakdownContributionnDetail2nd && !isPercentage2nd
                        ? true
                        : false
                      }
                     >
                      <Text fontSize={12}>Cost Number</Text>
                     </Radio>
                    </Stack>
                    <Spacer />
                    <Box fontWeight="600" right={10}>
                     <Select
                      size="sm"
                      borderRadius={5}
                      variant="outline"
                      color="#0072CF"
                      fontWeight={600}
                      w={"200px"}
                      h={"30px"}
                      fontSize="12px"
                      onChange={(e) => handleChangeVolume2nd(e)}
                      value={volumeIdTreemap2nd}
                      placeholder="Please select volume"
                     >
                      {Array.isArray(volumes) &&
                       volumes.length > 0 &&
                       volumes.map(({ volume, id, thirdParty }) => (
                        <option key={volume} value={id} id={id}>
                         Volume: {String.toFormatSeparator(volume, 0)}
                         {thirdParty && " - "}
                         {thirdParty && thirdParty.code}
                        </option>
                       ))}
                     </Select>
                    </Box>
                   </Flex>
                  </RadioGroup>
                  {/* )} */}
                 </Box>
                 {series2nd.length > 0 && (
                  <TreemapChart
                   isPricing={true}
                   data={series2nd}
                   height={600}
                   // isDistribute={treemapSummary2nd === "summary" ? true : false}
                  />
                 )}
                </Box>
               }
              />
             )}
            </Center>
           </Box>
          </Flex>
         )}

         <Box
          color="rgba(26, 26, 26, 0.36)"
          textAlign={"center"}
          fontWeight={600}
         >
          {!showChart && (
           <Box cursor={"pointer"} onClick={(e) => setShowChart(true)}>
            <ArrowDownIcon mb="-5" width={24} height="24px" />
            <Text fontSize={"16px"}>Show More</Text>
           </Box>
          )}
          {showChart && (
           <Box cursor={"pointer"} onClick={(e) => setShowChart(false)}>
            <ArrowDownIcon
             ml="-5"
             width={24}
             height="24px"
             sx={{ transform: "rotate(180deg)" }}
            />
            <Text fontSize={"16px"}>Show Less</Text>
           </Box>
          )}
         </Box>
        </Box>
       </TabPanel>
      </TabPanels>
     </Tabs>
    </Box>
   )}
   {!loading && <VolumeSection volumes={volumes} />}
   {loading && (
    <Box py="1rem">
     <StepperLoading />
    </Box>
   )}
   <Box
    w="100%"
    h="100%"
    borderRadius="5px"
    border="1px solid #D7DBE2"
    pt="21px"
    pl="32px"
    pr="32px"
    pb="21px"
    mb="5rem"
    ref={changeVolumeReff}
   >
    <Box>
     <Flex>
      <Box as="p" pb="3px" fontSize="21" textColor="#00050D" fontWeight="bold">
       Cost Summary{" "}
       {detailPerVolumeId?.result?.thirdParty &&
        "-" + detailPerVolumeId?.result?.thirdParty?.description}
      </Box>
      <Spacer />
      <Box m="auto">
       <Flex>
        <Spacer />
        <Box w="23.99px" h="23.99px" mt="5px" mr="0.5rem">
         {<Image src={Checklist} alt="Bulp" />}
        </Box>
        <Box
         as="p"
         pb="3px"
         pr="1.5rem"
         fontSize="21"
         textColor="#00050D"
         fontWeight="bold"
        >
         Volume:{" "}
         {String.toFormatSeparator(detailPerVolumeId?.result?.volume, 0)}
        </Box>
       </Flex>
      </Box>
     </Flex>
    </Box>
    <ChakraTable variant="mytable" colorScheme="facebook">
     <Thead borderBottom="1px solid #D7DBE2">
      <Tr>
       {columnHeaders &&
        columnHeaders.map((item: any, index) => {
         return (
          <Th
           key={index}
           textAlign={item.align}
           textTransform="capitalize"
           color="#9DA1A7"
           fontSize="16px"
          >
           {item.label}
          </Th>
         );
        })}
      </Tr>
     </Thead>
     <Tbody>
      {!loading &&
       costDriverDetail &&
       costDriverDetail?.length > 0 &&
       costDriverDetail.map((row: any, index: number) => {
        return (
         <Tr key={index}>
          <Td>{row.costBreakdown}</Td>
          <Td>{row.isPercentage ? "-" : row.currency}</Td>
          <Td textAlign="right">
           {row.isPercentage
            ? row?.finalCost && String.toFormatSeparator(row?.finalCost) + "%"
            : row?.finalCost &&
              row?.finalCost.toLocaleString("en-US", {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
              })}
          </Td>
         </Tr>
        );
       })}
      <Tr>
       <Td fontWeight="bold">Total Cost in Company Currency</Td>
       <Td fontWeight="bold">
        {editPricingCostDrivers?.totalCostCompany?.currency?.code}
       </Td>
       <Td fontWeight="bold" textAlign="right">
        {editPricingCostDrivers?.totalCostCompany?.amount.toLocaleString(
         "en-US",
         {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         }
        )}
       </Td>
      </Tr>
     </Tbody>
    </ChakraTable>
   </Box>

   {loading ? (
    <TableLoading />
   ) : pricingPage !== "cost-summary-by-sku" ? (
    <Box
     w="100%"
     h="100%"
     borderRadius="40px"
     border="1px solid #D7DBE2"
     pt="21px"
     pl="32px"
     pr="32px"
     pb="21px"
     mb="2rem"
    >
     <Box>
      <Box as="p" pb="3px" fontSize="21" textColor="#00050D" fontWeight="bold">
       Cost Analysis Summary
      </Box>
     </Box>
     <ChakraTable variant="mytable" colorScheme="facebook">
      <Thead borderBottom="1px solid #D7DBE2">
       <Tr>
        {volumeSummaryColumnHeaders &&
         volumeSummaryColumnHeaders.map((item: any, index) => {
          return (
           <Th
            key={index}
            textAlign={item.align}
            textTransform="capitalize"
            color="#9DA1A7"
            fontSize="16px"
           >
            {item.label}
           </Th>
          );
         })}
       </Tr>
      </Thead>
      <Tbody>
       <Tr key={summaryCost?.volume}>
        <Td textAlign="left">
         {String.toFormatSeparator(detailPerVolumeId?.result?.volume, 0)}
        </Td>
        <Td>{currency?.code}</Td>
        <Td textAlign="right">
         {detailPerVolumeId?.result?.unitCost.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
         })}
        </Td>
       </Tr>
      </Tbody>
     </ChakraTable>
    </Box>
   ) : null}
   {volumes && volumes.length > 0 && (
    <ToolbarBottom costAnalysis={volumes[0]} costAnalysisId={pricingId} />
   )}
  </Box>
 );
};

export default CostSummary;
