import { RootState } from "state/store";
import { FC, useEffect, useState, createRef } from "react";
import { Box, Grid, GridItem, Text, Button, Checkbox } from "@chakra-ui/react";
import { TextLoading } from "components/Loading";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { actions as approvalAction } from "state/approval/slice";
import { actions as tenantConfActions } from "state/settings/slice";
import { isEmpty, orderBy } from "lodash";
import ToolbarBottom from "views/AnnualSpendImpact/ToolbarBotton";

const ThirdParty: FC = () => {
 const router = useHistory();
 const dispatch = useAppDispatch();

 let {
  loading,
  searchQuery,
  thirdParties,
  thirdPartySelection,
  nonThirdPartySelection,
  isEnableThirdPartyStatus,
  comparison,
 } = useSelector(
  (state: RootState) => ({
   loading: state.approval.loading,
   thirdParties: state.approval.thirdParties.result,
   thirdPartySelection: state.approval.thirdPartySelection,
   nonThirdPartySelection: state.approval.nonThirdPartySelection,
   searchQuery: state.approval.searchQuery,
   comparison: state.approval.comparison,
   isEnableThirdPartyStatus: state.thirdPartySetting.isEnableThirdPartyStatus,
  }),
  shallowEqual
 );
 useEffect(() => {
  if (!comparison) {
   router.push("/approval/annual-spend-impact");
  }
 }, []);
 useEffect(() => {
  const getThirdParty = async () => {
   dispatch(approvalAction.getApprovalThirdParty(searchQuery));
  };
  if (isEnableThirdPartyStatus) {
   getThirdParty();
  }
 }, [searchQuery]);
 const setCheckBoxForThirdParty = (isChecked: boolean, value: string) => {
  if (isChecked) {
   dispatch(approvalAction.thirdPartySelection(value));
  } else {
   dispatch(approvalAction.removeThirdPartySelection(value));
  }
 };
 const setCheckBoxNonThirdParty = (isChecked: boolean, value: string) => {
  if (isChecked) {
   dispatch(approvalAction.nonThirdPartySelection(value));
  } else {
   dispatch(approvalAction.removeNonThirdPartySelection(value));
  }
 };
 
 return (
  <Box my={5}>
   <Text fontSize="18px" fontWeight={600}>
    Please select one or more
   </Text>
   {thirdParties && isEmpty(thirdParties) && (
    <Box textAlign={"center"} mt={20}>
     No Third Party available to be shown
    </Box>
   )}
   {!loading ? (
    <Grid templateColumns="repeat(2, 1fr)" gap={6} my={5}>
     <GridItem w="80%">
      {orderBy(thirdParties, ["description"], ["asc"])
       ?.filter(
        (item: any) => item.id !== "99999999-9999-9999-9999-999999999999"
       )
       ?.map((item: any, index: number) => {
        const ref = createRef<HTMLInputElement>();
        return (
         <Box
          key={index}
          my={5}
          borderRadius={5}
          border="1px"
          borderColor="gray.300"
         >
          <Box p={3}>
           <Checkbox
            isChecked={thirdPartySelection.includes(item.id)}
            value={item.id}
            onChange={(val) => {
             const all = "00000000-0000-0000-0000-000000000000";
             if (val.target.checked && val.currentTarget.value === all) {
              setCheckBoxForThirdParty(
               val.target.checked,
               thirdParties?.filter((item: any) => item.id !== "99999999-9999-9999-9999-999999999999").map((item: any) => item.id)
              );
             } else {
              setCheckBoxForThirdParty(val.target.checked, val.currentTarget.value);
             }
            }}
           >
            {item.description}
           </Checkbox>
          </Box>
         </Box>
        );
       })}
     </GridItem>
     <GridItem w="80%">
      {orderBy(thirdParties, ["description"], ["asc"])
       ?.filter(
        (item: any) => item.id === "99999999-9999-9999-9999-999999999999"
       )
       ?.map((item: any, index: number) => {
        return (
         <Box
          key={index}
          my={5}
          borderRadius={5}
          border="1px"
          borderColor="gray.300"
         >
          <Box p={3}>
           <Checkbox
            isChecked={nonThirdPartySelection.includes(item.id)}
            value={item.id}
            onChange={(val) => {
              setCheckBoxNonThirdParty(val.target.checked, val.currentTarget.value);
            }}
           >
            {item.description}
           </Checkbox>
          </Box>
         </Box>
        );
       })}
     </GridItem>
    </Grid>
   ) : (
    <TextLoading />
   )}
   <ToolbarBottom />
  </Box>
 );
};

export default ThirdParty;
