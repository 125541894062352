import { ReactElement } from "react";
import { Box, Button, Flex, Spacer } from "@chakra-ui/react";
import { useAppDispatch } from "hooks";
import { useHistory } from "react-router-dom";
import {actions as masterDataAction} from "state/masterData/slice"
import Swal from "sweetalert2";

const ToolbarBottom = ({ onOpen, onSubmit, loading }: any): ReactElement => {
 const dispatch = useAppDispatch();
 const history = useHistory();

 return (
  <Box
   position="fixed"
   bottom="0"
   right="0"
   backgroundColor="#ffffff"
   height="4.5rem"
   w="80%"
  >
   <Flex p="auto" mr="10">
    <Spacer />
    <Button
     variant="outline"
     w="136px"
     h="45px"
     mr="1rem"
     onClick={async () => {
      Swal.fire({
        showCancelButton: true,
        html: "The changes that you have made will be lost. Are you sure you want to proceed?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
       }).then(async (result) => {
        if (result.isConfirmed) {
       dispatch(masterDataAction.setEditing(false))
       dispatch(masterDataAction.setView(false))
       history.goBack()
        }
       });
     }}
    >
     Cancel
    </Button>
    <Button
    type="submit"
     bg="#0072CF"
     w="136px"
     h="45px"
     borderRadius="5px"
     fontSize="16"
     color="white"
     isDisabled={loading}
     _hover={{ bgColor: "rgba(70, 171, 249, 0.8)" }}
     onClick={onSubmit}
     loadingText="loading..."
    >
     Save
    </Button>
   </Flex>
  </Box>
 );
};

export default ToolbarBottom;
