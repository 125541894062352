import { createSlice } from "@reduxjs/toolkit";
import {
 getDetailCostDriversVolume,
 getCostBreakdownContributionnDetail,
 getCostBreakdownContributionnDetail2nd,
 deleteDetailCostAnalysisVolume,
 getExhangeRateByAmountFromToCurrencyCode,
} from "state/pricings/editCostDrivers/thunk";
import {
 updateAsNewVersion,
 simulate,
 historyReport,
} from "state/pricings/costAnalysis/thunk";
import { isEmpty, orderBy } from "lodash";

interface InitialState {
 costAnalysisPerVolumeId: string;
 deleteStatus: boolean;
 detailPerVolumeId: any;
 responseSimulate: any;
 volumeId: string;
 thirdPartyId: string;
 isEditing: boolean;
 isSimulate: boolean;
 loadingDownloadReport: boolean;
 valueIsIncludeCalculation: boolean;
 levelName: string;
 loadingHistoryReport: boolean;
 pricingId: string;
 loading: boolean;
 loadingSave: boolean;
 error: unknown;
 temporaryDataCostDriver: any;
 temporaryDataCustomFields: any;
 dataToSend: any;
 constDrivers: any;
 originalCostDrivers: any;
 constDriversOri: any;
 tmpChangeRow: any;
 needReFetch: boolean;
 prevPage: string;
 resetForm: any;
 dataUpdateToSend: any;
 isAddNew: boolean;
 temporaryData: any;
 indexCostDriversSimulateBySku: string[];
 oldValue: boolean;
 isDataDuplicate: boolean;
 stateTmpId: string;
 costBreakdownContributionnDetail: any;
 costBreakdownContributionnDetail2nd: any;
 scrollToId: string;
 volumeIdTreemap1st: string;
 volumeIdTreemap2nd: string;
 tmpChangeRowNoCustomField: any;
 tmpName: string;
 emptyCostDrivers: any;
 isEditOpen: boolean;
 statusAndVolume: any;
 isErrorDuplicate: boolean;
 objectsIndexToDelete: any;
 skuCharacteristic: any;
 customFields: any;
 initialCustomFields: any;
 isDisabled: boolean;
 idDetailPerVolumeId: string;
 haveEmptyName: boolean;
 fromApprovalPageList: boolean;
 rateResponse: any;
 dataToSimulate: any[];
}

const initialState: InitialState = {
 costAnalysisPerVolumeId: "",
 deleteStatus: false,
 detailPerVolumeId: {},
 responseSimulate: {},
 volumeId: "",
 thirdPartyId: "",
 levelName: "",
 pricingId: "",
 loading: false,
 loadingSave: false,
 error: null,
 isEditing: false,
 isSimulate: false,
 dataUpdateToSend: {},
 loadingDownloadReport: false,
 valueIsIncludeCalculation: false,
 loadingHistoryReport: false,
 temporaryDataCostDriver: [],
 temporaryDataCustomFields: [],
 resetForm: () => {},
 dataToSend: {
  costAnalysisPerVolumeId: "",
  name: "",
  volume: "",
  thirdPartyCode: "",
  description: "",
  uoMCode: "",
  currencyId: "",
  formatCode: "",
  unitCost: "",
  adds: [],
  updates: [],
  deletes: [],
  customFields: [],
 },
 constDrivers: {},
 originalCostDrivers: [],
 constDriversOri: {},
 tmpChangeRow: [],
 needReFetch: false,
 prevPage: "",
 isAddNew: false,
 temporaryData: null,
 indexCostDriversSimulateBySku: [],
 oldValue: false,
 isDataDuplicate: false,
 stateTmpId: "",
 costBreakdownContributionnDetail: {},
 costBreakdownContributionnDetail2nd: {},
 scrollToId: "",
 volumeIdTreemap1st: "",
 volumeIdTreemap2nd: "",
 tmpChangeRowNoCustomField: [],
 tmpName: "",
 emptyCostDrivers: [],
 isEditOpen: false,
 statusAndVolume: {},
 isErrorDuplicate: false,
 objectsIndexToDelete: {
  index1: null,
  index2: null,
 },
 skuCharacteristic: {},
 customFields: [],
 initialCustomFields: [],
 isDisabled: true,
 idDetailPerVolumeId: "",
 haveEmptyName: false,
 fromApprovalPageList: false,
 rateResponse: {},
 dataToSimulate: [],
};
function removeItem(array: any, item: string) {
 for (var i in array) {
  if (array[i] == item) {
   array.splice(i, 1);
   break;
  }
 }
}
const editPricingCostDriverSlice = createSlice({
 name: "editPricingCostDrivers",
 initialState,
 reducers: {
  clear: () => initialState,
  addDataToSimulate: (state, action) => {
   const findData = orderBy(state.dataToSimulate, ["value"], ["desc"]).find(
    (item: any) => item.name === action.payload.name
   );
   if (findData) {
    removeItem(state.dataToSimulate, action.payload);
   }
   state.dataToSimulate.push(action.payload);
  },
  resetDataSimulate: (state, action) => {
   state.dataToSimulate = action.payload;
  },
  removeDataToSimulate: (state, action) => {
   removeItem(state.dataToSimulate, action.payload);
  },
  setIsEditOpen: (state, action) => {
   state.isEditOpen = action.payload;
  },
  setInitialCustomFields: (state, action) => {
   action.payload.map((item: any, index: number) => {
    if (item.customFieldId === action.payload[index].customFieldId) {
     state.initialCustomFields[index] = action.payload[index];
    } else {
     state.initialCustomFields.push(item);
    }
   });
  },
  setUpdateUnitCost: (state, action) => {
   let update = state.dataToSend;
   update.unitCost = action.payload;
   //  state.dataToSend.unitCost = action.payload;
   //  state.needReFetch = true;
  },
  setStatusAndVolume: (state, action) => {
   state.statusAndVolume = action.payload;
  },
  setVolumeFromApprovalPageList: (state, action) => {
   state.fromApprovalPageList = action.payload;
  },
  setOldValue: (state, action) => {
   state.oldValue = action.payload;
  },
  setHaveEmptyName: (state, action) => {
   state.haveEmptyName = action.payload;
  },
  setScrollToId: (state, action) => {
   state.scrollToId = action.payload;
  },
  resetTmpChangeRow: (state) => {
   state.dataToSend = {
    costAnalysisPerVolumeId: "",
    name: "",
    volume: "",
    thirdPartyCode: "",
    description: "",
    uoMCode: "",
    currencyId: "",
    formatCode: "",
    adds: [],
    updates: [],
    deletes: [],
    customFields: [],
   };
   state.tmpChangeRow = [];
  },
  setThirdPartyId: (state, action) => {
   state.thirdPartyId = action.payload;
  },
  setVolumeIdTreemap1st: (state, action) => {
   state.volumeIdTreemap1st = action.payload;
  },
  setVolumeIdTreemap2nd: (state, action) => {
   state.volumeIdTreemap2nd = action.payload;
  },
  setErrorSendDataDuplicate: (state, action) => {
   state.isDataDuplicate = action.payload;
  },
  setAddNew: (state, action) => {
   state.isAddNew = action.payload;
  },
  setTemporaryData: (state, action) => {
   state.temporaryData = action.payload;
  },
  setIndexCostDriversSimulateBySku: (state, action) => {
   state.indexCostDriversSimulateBySku = action.payload;
  },
  setValueIsIncludeCalculation: (state, action) => {
   state.valueIsIncludeCalculation = action.payload;
  },
  setPrevPage: (state, action) => {
   state.prevPage = action.payload;
  },
  setVolumeId: (state, action) => {
   state.volumeId = action.payload;
  },
  setLoadingDownloadReport: (state, action) => {
   state.loadingDownloadReport = action.payload;
  },
  setNeedReFetch: (state, action) => {
   state.needReFetch = action.payload;
  },
  //
  setLevelName: (state, action) => {
   state.levelName = action.payload;
  },
  setIsEditing: (state, action) => {
   state.isEditing = action.payload;
  },
  setIsSimulate: (state, action) => {
   state.isSimulate = action.payload;
  },
  setConstDrivers: (state, action) => {
   state.constDrivers = action.payload;
  },
  setConstDriversOri: (state, action) => {
   state.constDriversOri = action.payload;
  },
  setCostAnalysisPerVolumeId: (state, action) => {
   state.costAnalysisPerVolumeId = action.payload;
  },
  setTmpArrayCustomFields: (state, action) => {
   state.temporaryDataCustomFields = action.payload;
  },
  setTempDataCustomFields: (state, action) => {
   if (state.temporaryDataCustomFields.length > 0) {
    state.temporaryDataCustomFields.map((item: any, index: number) => {
     const customFieldId = action.payload.customFieldId;
     if (customFieldId === item.customFieldId) {
      state.temporaryDataCustomFields[index] = action.payload.customField;
     } else {
      state.temporaryDataCustomFields.push(action.payload);
     }
    });
   } else {
    state.temporaryDataCustomFields.push(action.payload);
   }
  },
  deleteTemporaryDataCostDriver: (state, action) => {
   const index1 = action.payload.index1;
   const index2 = action.payload.index2;
   const index3 = action.payload.index3;
   state.temporaryDataCostDriver.map(() => {
    if (index1 !== -1 && index2 !== -1 && index3 !== -1) {
     state.temporaryDataCostDriver[index1].data[index2].data.splice(index3, 1);
    }
   });
  },
  deleteOneTmpChangeRow: (state, action) => {
   state.tmpChangeRow = state.tmpChangeRow.filter(
    ({ id }: any) => !action.payload.includes(id)
   );
   // const findIndex = state.tmpChangeRow.indexOf(action.payload)
   // state.tmpChangeRow.splice(findIndex, 1)
  },
  setAddTemporaryDataCostDriver: (state, action) => {
   const findIndex = action.payload.index;
   const temporaryDataCostDriverId = action.payload.temporaryDataCostDriverId;
   let index = state.temporaryDataCostDriver[0]?.data[
    findIndex
   ]?.data.findIndex((el: any) => el.isNew === true);

   let indexAdd = state.dataToSend?.adds?.findIndex(
    (el: any) => el.isNew === true
   );

   if (index !== -1) {
    delete state.temporaryDataCostDriver[0]?.data[findIndex]?.data[index]
     ?.isNew;
    delete state.temporaryDataCostDriver[0]?.data[findIndex]?.data[index]
     ?.tempId;
   }
   if (
    indexAdd !== -1 &&
    state.dataToSend?.adds &&
    state.dataToSend?.adds[indexAdd]
   ) {
    delete state.dataToSend?.adds[indexAdd]?.isNew;
   }
   state.temporaryDataCostDriver[temporaryDataCostDriverId]?.data[
    findIndex
   ]?.data?.push(action.payload);
  },
  removeAddDataToSend: (state, action) => {
   const indexSimulateBySku = action.payload.indexSimulateBySku;
   const findIndex = action.payload.index;
   const indexToDelete = action.payload.indexToDelete;
   const newTemporaryDataCostDriver: any = [];
   state.temporaryDataCostDriver[indexSimulateBySku].data[findIndex].data.map(
    (item: any, index: any) => {
     if (index !== indexToDelete) {
      newTemporaryDataCostDriver.push(item);
     }
    }
   );
   state.temporaryDataCostDriver[indexSimulateBySku].data[findIndex].data.push(
    newTemporaryDataCostDriver
   );
  },
  removeOneTemporaryDataCostDriver: (state, action) => {
   const index = action.payload.index;
   state.dataToSend.adds.splice(index, 1);
  },

  updateCurrencyId: (state, action) => {
   // console.log("actioactionn", action)
   state.dataToSend.updates = action.payload;
   // state.needReFetch = true;
  },

  replaceTemporaryDataCostDriverByFieldName: (state, action) => {
   const findIndex = action.payload.index;
   const findOneCostDriverTmp = action.payload.findOneCostDriverTmp;
   const fieldName = action.payload.fieldName;
   const costDriverid = action.payload.costDriverid;
   const lastIndex = action.payload.lastIndex;
   const indexSimulateBySku = action.payload.indexSimulateBySku;
   const customFieldConfigurationId = action.payload.customFieldConfigurationId;
   const dataReal = action.payload.dataReal;

   const value = action.payload.value;
   const isCustomField = action.payload.changeCustomField;
   const name = action.payload.name;
   action.payload[name] = value;
   const isNotCalculation = fieldName === "name" || fieldName === "description";
   if (!isCustomField) {
    let index = state.temporaryDataCostDriver[indexSimulateBySku]?.data[
     findIndex
    ]?.data.findIndex((el: any) => el.isNew === true);
    if (index !== -1 && findIndex !== -1) {
     state.temporaryDataCostDriver[indexSimulateBySku].data[findIndex].data[
      index
     ][fieldName] = value;

     if (!isNotCalculation) {
      state.isEditOpen = false;
      state.needReFetch = true;
     }
    }
   } else {
    // let index = state.temporaryDataCostDriver[indexSimulateBySku]?.data[
    //  findIndex
    // ]?.data.findIndex((el: any) => el.isNew === true);
    let index = findOneCostDriverTmp?.data[findIndex];
    if (index === -1 || index === undefined) {
     action.payload[name] = value;
     delete action.payload.value;
     delete action.payload.changeCustomField;
     delete action.payload.index;

     const data = action.payload.dataReal;

     const findSameUpdate = state.dataToSend.updates.findIndex(
      (item: any) => item.costDriverId === action.payload.costDriverId
     );
     if (findSameUpdate !== -1) {
      state.dataToSend.updates[findSameUpdate] = {
       costAnalysisPerVolumeId: action.payload.costAnalysisPerVolumeId,
       costDriverId: action.payload.costDriverId,
       parentCostDriverId: data.costDriverId,
       name: action.payload.name,
       description: action.payload.description,
       unitCost: action.payload.unitCost,
       customFields: action.payload.customFields,
       masterDataId: action.payload.masterDataId,
      };
     } else {
      state.dataToSend.updates.push({
       costAnalysisPerVolumeId: action.payload.costAnalysisPerVolumeId,
       costDriverId: action.payload.costDriverId,
       parentCostDriverId: data.costDriverId,
       name: action.payload.name,
       description: action.payload.description,
       unitCost: action.payload.unitCost,
       customFields: action.payload.customFields,
       masterDataId: action.payload.masterDataId,
      });
     }

     if (!isNotCalculation) {
      state.isEditOpen = false;
      state.needReFetch = true;
     }
     //  state.needReFetch = true;
    } else {
     if (findIndex !== -1) {
      const findCustomField = findOneCostDriverTmp.data[
       findIndex
      ]?.customFields.findIndex((itemCust: any) => itemCust.name === name);
      state.temporaryDataCostDriver[indexSimulateBySku].data[lastIndex].data[
       findIndex
      ][name] = value;
      state.temporaryDataCostDriver[indexSimulateBySku].data[lastIndex].data[
       findIndex
      ].customFields[findCustomField].value = value;
     }

     const indexSameAdd = state.dataToSend.adds.findIndex(
      (itemAdd: any) => itemAdd.costDriverId === costDriverid
     );

     const indexCustomFieldConfigurationId = state.dataToSend.adds[
      indexSameAdd
     ]?.customFields.findIndex(
      (indexCustomField: any) =>
       indexCustomField.customFieldConfigurationId ===
       customFieldConfigurationId
     );

     if (
      state.dataToSend.adds.length > 0 &&
      state.dataToSend.adds[indexSameAdd]?.customFields
     ) {
      state.dataToSend.adds[indexSameAdd].customFields[
       indexCustomFieldConfigurationId
      ].value = value;
     }

     if (!isNotCalculation) {
      state.isEditOpen = false;
      state.needReFetch = true;
     }
     //  if (isCustomField) {
     //  }
    }
   }
  },

  setTemporaryDataCostDriver: (state, action) => {
   let index = state.temporaryDataCostDriver.findIndex(
    (el: any) => el.name == action.payload.name
   );

   if (index === -1) {
    state.temporaryDataCostDriver.push(action.payload);
   } else {
    const array1 = state.temporaryDataCostDriver[index];
    const array2 = action.payload;
    let merged = {
     ...array1,
     ...array2,
    };
    state.temporaryDataCostDriver[index] = merged;
   }
  },
  setLatestTemporaryDataCostDriver: (state, action) => {
   state.temporaryDataCostDriver = action.payload;
  },

  setTemporaryOriginalDataCostDriver: (state, action) => {
   let index = state.originalCostDrivers.findIndex(
    (el: any) => el.name == action.payload.name
   );

   if (index === -1) {
    state.originalCostDrivers.push(action.payload);
   } else {
    const array1 = state.originalCostDrivers[index];
    const array2 = action.payload;
    let merged = {
     ...array1,
     ...array2,
    };
    state.originalCostDrivers[index] = merged;
   }
  },

  replaceTemporaryDataCostDriver: (state, action) => {
   if (action.payload?.fromCostDriver) {
    let index = state.temporaryDataCostDriver.findIndex(
     (el: any) => el.configurationId == action.payload.configurationId
    );
    if (index !== undefined) {
     state.temporaryDataCostDriver[index] = action.payload;
    }
   }
  },
  setEditOneToTemporaryDataCostDriver: (state, action) => {
   const { customFields, isSame, index, i } = action.payload;
   if (isSame) {
    state.dataToSend.updates[index].customFields[i] = customFields[0];
   } else {
    state.dataToSend.updates[index].customFields.push(customFields[0]);
   }
  },
  removeTmpChangeForFinalCost: (state, action) => {
   const indexTmpChangeRow = action.payload;
   state.tmpChangeRow.splice(indexTmpChangeRow, 1);
  },
  setRemoveIfError: (state, action) => {
   const indexCostDriversSimulateBySku =
    action.payload.indexCostDriversSimulateBySku;
   const indexDataAdd = action.payload.indexDataAdd;
   const indexTmpChangeRow = action.payload.indexTmpChangeRow;
   state.dataToSend.adds.splice(indexDataAdd, 1);

   if (!action.payload.idNotRemove) {
    state.temporaryDataCostDriver[indexCostDriversSimulateBySku[0]].data[
     indexCostDriversSimulateBySku[1]
    ].data.splice(indexCostDriversSimulateBySku[2], 1);
   }

   state.tmpChangeRow.splice(indexTmpChangeRow, 1);
  },
  setBackTemporaryDataCostDriverIfError: (state, action) => {
   const temporaryData = action.payload.temporaryData;
   const indexCostDriversSimulateBySku =
    action.payload.indexCostDriversSimulateBySku;
   const indexDataAdd = action.payload.indexDataAdd;
   const indexDataDelete = action.payload.indexDataDelete;
   const indexDataUpdate = action.payload.indexDataUpdate;
   const errorSendDataDuplicate = action.payload.errorSendDataDuplicate;
   state.dataToSend.adds.splice(indexDataAdd, 1);
   state.dataToSend.deletes.splice(indexDataDelete, 1);
   state.dataToSend.updates.splice(indexDataUpdate, 1);
   if (errorSendDataDuplicate) {
    state.temporaryDataCostDriver[indexCostDriversSimulateBySku[0]].data[
     indexCostDriversSimulateBySku[1]
    ].data.splice(indexCostDriversSimulateBySku[2], 1);
   } else {
    state.temporaryDataCostDriver[indexCostDriversSimulateBySku[0]].data[
     indexCostDriversSimulateBySku[1]
    ].data.push(temporaryData);
   }
  },
  setTmpName: (state, action) => {
   state.tmpName = action.payload;
  },
  setIsErrorDuplicate: (state, action) => {
   state.isErrorDuplicate = action.payload;
  },
  changeValueUpdates: (state, action) => {
   const { costDriverId } = action.payload;
   state.dataToSend.updates.find((item: any, index: number) => {
    if (item.costDriverId === costDriverId) {
     state.dataToSend.updates.splice(index, 1);
    }
   });
  },
  setdataUpdateToSend: (state, action) => {
   state.dataUpdateToSend = action.payload;
  },
  directSetEditOneToTemporaryDataCostDriver: (state, action) => {
   delete action.payload.index;
   delete action.payload.isSame;
   delete action.payload.i;
   const isRequired = action.payload.customFields.find(
    (item: any) => item.isRequired && item.value === ""
   );
   const haveSameValue = action.payload.haveSameValue;
   var itemIndexSame = state?.dataToSend?.adds?.findIndex((item: any) => {
    return item.simulationCostDriverId === action.payload.costDriverId;
   });

   var itemIndex = state?.dataToSend?.updates?.findIndex((item: any) => {
    return item?.costDriverId === action.payload.costDriverId;
   });
   if (state.dataToSend && state.dataToSend.adds.length !== 0) {
    if (itemIndexSame !== -1) {
     state.dataToSend.adds[itemIndexSame].name = action.payload.name;
    }
   }
   if (state.dataToSend && state.dataToSend.updates.length !== 0) {
    if (itemIndex !== -1) {
     if (haveSameValue) {
      state.dataToSend.updates.splice(itemIndex, 1);
     } else {
      state.dataToSend.updates[itemIndex] = action.payload;
     }
     state.needReFetch = true;
    } else {
     state?.dataToSend?.updates.push(action.payload);
     state.needReFetch = true;
    }
   } else {
    if (isRequired) {
     state?.dataToSend?.adds.push(action.payload);
    } else {
     state?.dataToSend?.updates.push(action.payload);
     state.needReFetch = true;
    }
   }
  },

  directSetAddOneToTemporaryDataCostDriver: (state, action) => {
   delete action.payload.index;
   delete action.payload.isSame;
   delete action.payload.id;
   debugger;
   let index = state.dataToSend.adds?.findIndex(
    (el: any) => el.simulationCostDriverId === action.payload.costDriverId
   );
   let indexAdds = state.dataToSend.adds?.findIndex(
    (el: any) => el.costDriverId === action.payload.costDriverId
   );
   let isSame = state.dataToSend.adds?.find(
    (el: any) =>
     el.simulationCostDriverId === action.payload.simulationCostDriverId &&
     el.name === action.payload.name &&
     el.masterDataId === action.payload.masterDataId
   );
   if (index !== -1 && state.dataToSend.adds && state.dataToSend.adds[index]) {
    state.dataToSend.updates.push(action.payload);
    state.needReFetch = true;
   } else {
    if (isSame) {
     state.dataToSend.adds[indexAdds] = action.payload;
     state.needReFetch = true;
    } else {
     state.dataToSend.adds.push(action.payload);
     state.needReFetch = true;
    }
   }
   state.isEditOpen = false;
  },
  setDataToSend: (state, action) => {
   state.dataToSend.adds.push(action.payload);
   state.needReFetch = true;
  },
  setDataToSendToDefault: (state, action) => {
   state.dataToSend = action.payload;
  },
  directSetDeleteOneToTemporaryDataCostDriver: (state, action) => {
   const {
    costAnalysisPerVolumeId,
    costDriverId,
    name,
    parentCostDriverId,
    tmpChangeToDeletes,
    isHaveDataOrigin,
    isNew,
   } = action.payload;
   const indexSimulateBySku = action.payload.indexSimulateBySku;
   const findIndex = action.payload.index;
   const dataToPush = {
    costAnalysisPerVolumeId,
    costDriverId,
    name,
    parentCostDriverId,
   };
   state.dataToSend.adds.find((item: any, index: number) => {
    if (
     item?.costDriverId === action.payload.parentCostDriverId &&
     item?.masterDataId
      ? item?.masterDataId === action.payload.masterDataId
      : item?.name === action.payload.name
    ) {
     state.dataToSend.adds.splice(index, 1);
    }
   });

   //  toDeleteUpdate

   if (action.payload.toDeleteUpdate.length > 0) {
    state.dataToSend.updates.splice(action.payload.toDeleteUpdate, 1);
   }

   state.dataToSend.updates.find((item: any, index: number) => {
    if (
     item?.costDriverId === action.payload.costDriverId &&
     item?.name === action.payload.name
    ) {
     state.dataToSend.updates.splice(index, 0);
    }
   });

   const itemIndexToDelete = state.dataToSend.deletes.findIndex((item: any) => {
    return (
     item.costDriverId === action.payload.parentCostDriverId &&
     item.name === action.payload.name
    );
   });

   const itemIndexUpdateToDelete = state.temporaryDataCostDriver[
    indexSimulateBySku
   ].data[findIndex].data.findIndex((item: any) => {
    return (
     item.constDriverId === action.payload.parentCostDriverId &&
     item.name === action.payload.name
    );
   });

   state.temporaryDataCostDriver[indexSimulateBySku].data[
    findIndex
   ].data.splice(
    isNew ? action.payload.indexToDelete : itemIndexUpdateToDelete,
    1
   );

   const arry: any = [];
   state.tmpChangeRow.map((item: any, index: number) => {
    for (let i = 0; i < tmpChangeToDeletes.length; i++) {
     if (
      tmpChangeToDeletes[i].component === item.component &&
      parentCostDriverId === item.parentCostDriverId
     ) {
      arry.push(index);
     }
    }
   });

   for (var i = arry.length - 1; i >= 0; i--) {
    state.tmpChangeRow.splice(arry[i], 1);
   }

   if (itemIndexToDelete > -1) {
    state.dataToSend.deletes.splice(itemIndexToDelete, 1);
   }
   if (isHaveDataOrigin) {
    state.dataToSend.deletes.push(dataToPush);
   }
   //  state.needReFetch = true;
  },
  directSetNullTemporaryDataCostDriver: (state, action) => {
   state.dataToSend.adds.map((item: any, index: number) => {
    if (item.simulationCostDriverId == action.payload) {
     state.dataToSend.adds.splice(index, 1);
    }
   });

   state.dataToSend.updates.map(
    (item: any, itemIndexUpdateToBeRemove: number) => {
     if (item.costDriverId == action.payload) {
      state.dataToSend.updates.splice(itemIndexUpdateToBeRemove, 1);
     }
    }
   );
  },
  directSetNullAllNewAddDataCostDriver: (state, action) => {
   state.dataToSend.adds.map((item: any, index: number) => {
    if (item.simulationCostDriverId == action.payload) {
     state.dataToSend.adds.splice(index, 1);
    }
   });
  },
  directSetNullTemporaryAddDataCostDriver: (state) => {
   state.dataToSend.adds = [];
  },
  setTmpDataChange: (state, action) => {
   state.skuCharacteristic = action.payload;
  },
  setDirectSetTemporaryDataCostDriver: (state, action) => {
   if (action.payload) {
    const customFieldsUpdate: any = [];
    action.payload?.customFields?.map((item: any, index: number) => {
     const { customFieldId, name, customFieldConfigurationId } = item;
     customFieldsUpdate.push({
      customFieldId,
      value: name,
      customFieldConfigurationId,
     });
    });
    let data: any = {
     costAnalysisPerVolumeId: action.payload?.costAnalysisPerVolumeId,
     name: action.payload?.name,
     volume: action.payload?.volume,
     thirdPartyCode: action.payload?.thirdPartyCode,
     description: action.payload?.description,
     uoMCode: action.payload?.uoMCode,
     currencyCode: action.payload?.currencyCode,
     currencyId: action.payload?.currencyId,
     formatCode: action.payload?.formatCode,
     customFields: customFieldsUpdate,
     adds: action.payload?.adds || [],
     updates: action.payload?.updates || [],
     deletes: action.payload?.deletes || [],
    };
    if (action.payload.level === 0) {
     data["unitCost"] = action.payload.unitCost;
    }
    state.dataToSend = data;
    if (action.payload && action.payload?.runCalculate) {
    }
   }
  },
  setOneCustomField: (state, action) => {
   let itemIndex = state.dataToSend.customFields.findIndex((item: any) => {
    return item.customFieldId == action.payload.customFieldId;
   });
   if (itemIndex >= 0) {
    state.dataToSend.customFields[itemIndex] = action.payload;
   } else {
    state.dataToSend.customFields.push(action.payload);
   }
  },

  settmpChangeRow: (state, action) => {
   const deleteIfSame = state.tmpChangeRow.findIndex(
    (item: any, index: number) =>
     item.component === action.payload.component &&
     item.costDriver === action.payload.costDriver &&
     item.parentCostDriverId === action.payload.parentCostDriverId &&
     item.after === action.payload.before
   );

   if (deleteIfSame === -1) {
    let itemIndex = state.tmpChangeRow.findIndex((item: any) => {
     return (
      item.component === action.payload.component &&
      item.costDriver === action.payload.costDriver &&
      item.parentCostDriverId === action.payload.parentCostDriverId
     );
    });
    if (itemIndex >= 0) {
     state.tmpChangeRow[itemIndex] = action.payload;
    } else {
     state.tmpChangeRow.push(action.payload);
    }
   } else {
    state.tmpChangeRow.splice(deleteIfSame, 1);
   }
  },
  changeTmpChangeRowComponentByIndex: (state, action) => {
   const { index, component, before, name, componentName, idRow } =
    action.payload;
   state.tmpChangeRow[index].component = component;
   state.tmpChangeRow[index].componentName = componentName;
   state.tmpChangeRow[index].idRow = idRow;
   if (name === state.tmpChangeRow[index].name) {
    state.tmpChangeRow[index].before = before;
   } else {
    state.tmpChangeRow[index].before = 0;
   }
  },
  changeBeforeChangeRow: (state, action) => {
   let itemIndex = state.tmpChangeRow.findIndex((item: any) => {
    return (
     item.parentCostDriverId === action.payload.parentCostDriverId &&
     item.idRow === action.payload.idRow
    );
   });
   if (
    itemIndex >= 0 &&
    state.tmpChangeRow[itemIndex].before !== action.payload.before
   ) {
    state.tmpChangeRow[itemIndex].before = action.payload.before;
    state.tmpChangeRow[itemIndex].component = action.payload.component;
   }
  },
  settmpChangeRowNonCustomField: (state, action) => {
   let itemIndex = state.tmpChangeRowNoCustomField.findIndex((item: any) => {
    return (
     item.component === action.payload.component &&
     item.costDriver === action.payload.costDriver &&
     item.parentCostDriverId === action.payload.parentCostDriverId
    );
   });
   if (itemIndex >= 0) {
    state.tmpChangeRowNoCustomField[itemIndex] = action.payload;
   } else {
    state.tmpChangeRowNoCustomField.push(action.payload);
   }
  },

  removeOneTmpChangeRowNonCustomField: (state, action) => {
   let itemIndex = state.tmpChangeRowNoCustomField.findIndex((item: any) => {
    return (
     item.component === action.payload.component &&
     item.costDriver === action.payload.costDriver &&
     item.parentCostDriverId === action.payload.parentCostDriverId
    );
   });
   if (itemIndex >= 0) {
    state.tmpChangeRowNoCustomField.splice(itemIndex, 1);
   }
  },

  setPerVolumeId: (state, action) => {
   state.dataToSend = action.payload;
  },

  removeOneLogistic: (state, action) => {
   let indexAdd = state.dataToSend.adds.findIndex(
    (el: any) => el.tmpId === action.payload
   );

   state.dataToSend.adds.splice(indexAdd, 1);
  },
  setCustomFields: (state, action) => {
   state.customFields.push(action.payload);
  },
  setDisable: (state, action) => {
   state.isDisabled = action.payload;
  },
  setNullCustomFields: (state) => {
   state.customFields = [];
  },
  removeOnetmpChangeRow: (state, action) => {
   const { tmpChangeToDeletes } = action.payload;
   state.tmpChangeRow.map((item: any, index: number) => {
    if (
     tmpChangeToDeletes.component === item.component &&
     tmpChangeToDeletes.name === item.id &&
     tmpChangeToDeletes.constDriverId === item.parentCostDriverId
    ) {
     state.tmpChangeRow.splice(index, 1);
    }
   });

   state.dataToSend.updates.find((item: any, index: number) => {
    if (item?.costDriverId === tmpChangeToDeletes?.constDriverId) {
     state.dataToSend.updates.splice(index, 1);
    }
   });
  },

  replaceUpdateTemporaryCostDriver: (state, action) => {
   const arrayIndexs = action.payload.arrayIndexs;
   const value = action.payload.value;
   const customFieldId = action.payload.customFieldId;
   const index = state.temporaryDataCostDriver[arrayIndexs[0]].data[
    arrayIndexs[1]
   ].data[arrayIndexs[2]]?.customFields.findIndex((item: any) => {
    return item.id === customFieldId;
   });
   state.temporaryDataCostDriver[arrayIndexs[0]].data[arrayIndexs[1]].data[
    arrayIndexs[2]
   ].customFields[index].value = value;
   state.needReFetch = true;
  },
  setStateTmpId: (state, action) => {
   state.stateTmpId = action.payload;
  },
  removeAddNewTrueFromLatestObjectInArray: (state, action) => {
   const index1 = action.payload.index1;
   const index2 = action.payload.index2;
   if (index2 && index2) {
    state.temporaryDataCostDriver[index1].data[index2].data.map(
     (item: any, index: number) => {
      if (item.isNew) {
       state.temporaryDataCostDriver[index1].data[index2].data[index].isNew =
        false;
      }
     }
    );
   }
  },

  directDeleteTemporaryDataCostDriver: (state, action) => {
   const { tmpChangeToDeletes } = action.payload;
   let itemIndex = state.dataToSend.updates.findIndex((item: any) => {
    return (
     item.costAnalysisPerVolumeId === action.payload.costAnalysisPerVolumeId &&
     item.costDriverId === action.payload.costDriverId &&
     item.name === action.payload.name
    );
   });

   state.dataToSend.updates.splice(itemIndex, 1);

   const arry: any = [];
   state.tmpChangeRow.map((item: any, index: number) => {
    for (let i = 0; i < tmpChangeToDeletes.length; i++) {
     if (
      (tmpChangeToDeletes[i].component === item.component &&
       tmpChangeToDeletes[i].name === item.id &&
       tmpChangeToDeletes[i].value === item.after) ||
      tmpChangeToDeletes[i].customFieldConfigurationId ===
       action.payload.customFieldConfigurationId ||
      tmpChangeToDeletes[i].idRow === action.payload.id
     ) {
      arry.push(index);
     }
    }
   });
   if (Array.isArray(arry) && arry.length > 0) {
    for (var i = arry.length - 1; i >= 0; i--) {
     state.tmpChangeRow.splice(arry[i], 1);
    }
   }

   state.tmpChangeRow.map((item: any, index: number) => {
    if (
     item.before === item.after ||
     item.customFieldConfigurationId ===
      action.payload.customFieldConfigurationId ||
     item.idRow === action.payload.id ||
     item.costDriverId === action.payload.id
    ) {
     state.tmpChangeRow.splice(index, 1);
    }
   });
   state.tmpChangeRow.map((item: any, index: number) => {
    if (
     (tmpChangeToDeletes[index].component === item.component &&
      tmpChangeToDeletes[index].name === item.id &&
      item.after === undefined) ||
     (tmpChangeToDeletes[index].component === item.component &&
      tmpChangeToDeletes[index].name === item.id &&
      item.costDriverId === undefined)
    ) {
     state.tmpChangeRow.splice(index, 1);
    }
   });
   //  state.tmpChangeRow.map((item: any, index: number) => {
   //   if (!item.after) {
   //    state.tmpChangeRow.splice(index, 1);
   //   }
   //  });
   state.tmpChangeRow.map((item: any, index: number) => {
    if (item.idRow === action.payload.id) {
     state.tmpChangeRow.splice(index, 1);
    }
   });
  },
  setDataEmptyCostDrivers: (state, action) => {
   let findIndex = state.emptyCostDrivers?.findIndex(
    (el: any) => el.id === action.payload.id
   );

   if (findIndex === -1) {
    state.emptyCostDrivers.push(action.payload);
   }
  },
  setObjectsIndexToDelete: (state, actions) => {
   state.objectsIndexToDelete = actions.payload;
  },
  setChangeAddRowByIndex: (state, action) => {
   const index = action.payload.index;
   const payload = action.payload.data;
   // disini
   state.dataToSend.adds[index].customFields = payload;
  },
 },
 extraReducers: (builder) => {
  builder.addCase(getDetailCostDriversVolume.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(getDetailCostDriversVolume.fulfilled, (state, action) => {
   state.detailPerVolumeId = action.payload;
   state.responseSimulate = action.payload;
   state.loading = false;
  });

  builder.addCase(getDetailCostDriversVolume.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(updateAsNewVersion.pending, (state) => {
   state.loadingSave = true;
   state.error = null;
  });

  builder.addCase(updateAsNewVersion.fulfilled, (state, action) => {
   state.detailPerVolumeId = action.payload;
   state.responseSimulate = action.payload;
   state.loadingSave = false;
  });

  builder.addCase(updateAsNewVersion.rejected, (state, action) => {
   state.loadingSave = false;
   state.error = action.payload;
  });

  builder.addCase(simulate.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(simulate.fulfilled, (state, action) => {
   state.detailPerVolumeId = action.payload;
   state.loading = false;
  });

  builder.addCase(simulate.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  builder.addCase(historyReport.pending, (state) => {
   state.loadingHistoryReport = true;
   state.error = null;
  });

  builder.addCase(historyReport.fulfilled, (state, action) => {
   state.loadingHistoryReport = false;
  });

  builder.addCase(historyReport.rejected, (state, action) => {
   state.loadingHistoryReport = false;
   state.error = action.payload;
  });

  builder.addCase(getCostBreakdownContributionnDetail.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(
   getCostBreakdownContributionnDetail.fulfilled,
   (state, action) => {
    state.loading = false;
    state.costBreakdownContributionnDetail = action.payload;
   }
  );

  builder.addCase(
   getCostBreakdownContributionnDetail.rejected,
   (state, action) => {
    state.loading = false;
    state.error = action.payload;
   }
  );

  builder.addCase(getCostBreakdownContributionnDetail2nd.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(
   getCostBreakdownContributionnDetail2nd.fulfilled,
   (state, action) => {
    state.loading = false;
    state.costBreakdownContributionnDetail2nd = action.payload;
   }
  );

  builder.addCase(
   getCostBreakdownContributionnDetail2nd.rejected,
   (state, action) => {
    state.loading = false;
    state.error = action.payload;
   }
  );

  builder.addCase(deleteDetailCostAnalysisVolume.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(deleteDetailCostAnalysisVolume.fulfilled, (state) => {
   state.loading = false;
   state.deleteStatus = true;
  });

  builder.addCase(deleteDetailCostAnalysisVolume.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // getExhangeRateByAmountFromToCurrencyCode
  builder.addCase(getExhangeRateByAmountFromToCurrencyCode.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(
   getExhangeRateByAmountFromToCurrencyCode.fulfilled,
   (state, action) => {
    state.rateResponse = action.payload;
    state.loading = false;
   }
  );

  builder.addCase(
   getExhangeRateByAmountFromToCurrencyCode.rejected,
   (state, action) => {
    state.loading = false;
    state.error = action.payload;
   }
  );
 },
});

export const actions = {
 ...editPricingCostDriverSlice.actions,
 getDetailCostDriversVolume,
 updateAsNewVersion,
 simulate,
 historyReport,
 getCostBreakdownContributionnDetail,
 getCostBreakdownContributionnDetail2nd,
 deleteDetailCostAnalysisVolume,
 getExhangeRateByAmountFromToCurrencyCode,
};

export const reducer = editPricingCostDriverSlice.reducer;
