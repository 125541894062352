import { RootState } from "state/store";
import { createRef, FC, useEffect } from "react";
import { useAppDispatch } from "hooks";
import { useSelector, shallowEqual } from "react-redux";
import { Box, Button, Flex, Checkbox } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { TextLoading } from "components/Loading";
import OptipediaToast from "utils/notification-util";
import { actions as annualSpendAction } from "state/annualSpend/slice";

const Step1: FC = () => {
 const dispatch = useAppDispatch();
 const router = useHistory();

 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);
 let { loading, uploadResponse, component, componentResponse, journeyId } =
  useSelector(
   (state: RootState) => ({
    loading: state.annualSpendSimulations.loading,
    uploadResponse: state.annualSpendSimulations.uploadResponse,
    component: state.annualSpendSimulations.component,
    componentResponse: state.annualSpendSimulations.componentResponse,
    journeyId: state.annualSpendSimulations.journeyId,
   }),
   shallowEqual
  );

 const setCheckBox = (isChecked: boolean, value: string) => {
  if (isChecked) {
   dispatch(annualSpendAction.setComponent(value));
  } else {
   dispatch(annualSpendAction.removeArrayComponent(value));
  }
 };

 const handleNextStep = () => {
  if (componentResponse && componentResponse.length !== 0) {
   if (component.length !== 0) {
    dispatch(
     annualSpendAction.subComponents({
      payload: {
       journeyId,
       costAnalysises: uploadResponse,
       identityCodes: component,
      },
     })
    );
    router.push(
     "/annual-spend-simulations/create?page=select-component-step-2"
    );
   } else {
    OptipediaToast.Warning(
     "Please select at least 1 of the components before proceeding to the next step"
    );
   }
  } else {
   dispatch(
    annualSpendAction.subComponents({
     payload: {
      journeyId,
      costAnalysises: uploadResponse,
      identityCodes: component,
     },
    })
   );
   router.push("/annual-spend-simulations/create?page=describe-change");
  }
 };

 return (
  <Box mb="6rem">
   <Box>
    <Flex
     pt="2rem"
     flexWrap="wrap"
     _after={{
      width: "100%",
     }}
    >
     {!loading ? (
      componentResponse &&
      componentResponse.length !== undefined &&
      componentResponse &&
      componentResponse.map(
       (
        componentData: {
         identityCode: string;
         costLabelName: string;
         identityName: string;
        },
        index: number
       ) => {
        const ref = createRef<HTMLInputElement>();
        return (
         <Box key={index} padding="1rem">
          <Box fontWeight="700" fontSize="24px">
           {loading ? (
            <TextLoading />
           ) : (
            <>
             <Button
              h="50"
              pl="50"
              pr="50"
              borderRadius="5px"
              fontSize="18"
              border="1px"
              borderColor="#0072CF"
              name={componentData.identityCode}
              color={
               component.includes(componentData.identityCode)
                ? "white"
                : "#0072CF"
              }
              onClick={(e: any) => {
               ref.current?.click();
              }}
              bg={
               component.includes(componentData.identityCode) ? "#0072CF" : ""
              }
              _disabled={{
               bgColor: "#C3C7CD",
               cursor: "not-allowed",
               color: "white",
               border: "solid 1px white",
              }}
             >
              {componentData.identityName}
             </Button>
             <Checkbox
              ref={ref}
              display="none"
              defaultChecked={component.includes(componentData.identityCode)}
              value={componentData.identityCode}
              onChange={(val) => {
               setCheckBox(val.target.checked, val.currentTarget.value);
              }}
             >
              {componentData.identityName}
             </Checkbox>
            </>
           )}
          </Box>
         </Box>
        );
       }
      )
     ) : (
      <TextLoading />
     )}
    </Flex>
   </Box>
   <Box
    position="fixed"
    bottom="0"
    backgroundColor="#ffffff"
    height="5.5rem"
    w="100%"
    zIndex={2}
   >
    <Flex float="right" mr="20rem">
     <Box mt={4}>
      <Flex alignItems="center" gap="2">
       <>
        <Button
         variant={'outline'}
         onClick={() => router.goBack()}
        >
         Back
        </Button>
        <Button
         onClick={handleNextStep}
        >
         Next
        </Button>
       </>
      </Flex>
     </Box>
    </Flex>
   </Box>
  </Box>
 );
};

export default Step1;
