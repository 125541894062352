import { ReactElement, useState } from "react";
import { useAppDispatch } from "hooks";
import { Box, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "state/store";
import { useHistory } from "react-router-dom";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import "utils/number-utils";
import Swal from "sweetalert2";

interface VolumeSectionProps {
 volumes: { volume: number; isDefault: boolean; id: string; thirdParty: any }[];
}

const VolumeSection = ({ volumes }: VolumeSectionProps): ReactElement => {
 const dispatch = useAppDispatch();
 const location = useLocation<{
  status: string;
  volumeId: string;
  isEditing: boolean;
 }>();
 const history = useHistory();
 const searchParams = new URLSearchParams(location.search);
 const pricingId = location.pathname.split("/")[3];
 const [getVolume, setVolume] = useState<number>();

 const {
  isEditing,
  isSimulate,
  loadingChangeDefault,
  detailPerVolumeId,
  isReplace,
  isCopy,
 } = useSelector(
  (state: RootState) => ({
   isEditing: state.editPricingCostDrivers.isEditing,
   isSimulate: state.pricingCostDrivers.isSimulate,
   loadingChangeDefault: state.pricingCostSummary.loadingChangeDefault,
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId,
   isReplace: state.copyAndReplaceCostDrivers.isReplace,
   isCopy: state.copyAndReplaceCostDrivers.isCopy,
  }),
  shallowEqual
 );

 const onClickDefault = async (
  volume: number,
  id: string,
  thirdParty: string
 ) => {
  setVolume(volume);
  const payloadChangeVolume = {
   costAnalysisId: pricingId,
   volumeId: id,
  };
  Swal.fire({
   showCancelButton: true,
   html:
    "Are you sure to change the Default Volume to <b>" +
    String.toFormatSeparator(volume, 0) +
    "</b> ?",
   confirmButtonText: "Yes",
   cancelButtonText: "No",
  }).then(async (result) => {
   if (result.isConfirmed) {
    const payloadChangeVolumePerVolumn = {
     costAnalysisId: pricingId,
     perVolumeId: id,
     thirdPartyId: thirdParty,
    };

    dispatch(pricingCostDriverAction.setVolumeId(id));

    dispatch(pricingCostDriverAction.setCostAnalysisPerVolumeId(id));

    dispatch(
     pricingCostDriverAction.getDetailCostDriversVolume(
      payloadChangeVolumePerVolumn
     )
    );
    history.push({
     pathname: `${location.pathname}`,
     state: {
      status: location.state.status,
      volumeId: location.state.volumeId,
     },
    });
   }
  });
 };

 return (
  <Box mt="2rem">
   <Box as="p" color="#000000" fontWeight="bold" textAlign={"center"}>
    Cost Analysis per Volume:
   </Box>
   <RadioGroup value={detailPerVolumeId?.result?.id}>
    <Stack justifyContent="center" direction="row" spacing={6} my="1rem">
     {Array.isArray(volumes) &&
      volumes.length > 0 &&
      volumes.map(({ volume, id, thirdParty }) => {
       return (
        <Radio
         key={id}
         isDisabled={isEditing || isCopy || isReplace}
         onClick={() =>
          isEditing || isCopy || isReplace
           ? () => {}
           : onClickDefault(volume, id, thirdParty)
         }
         value={id}
        >
         {thirdParty && thirdParty.code} {String.toFormatSeparator(volume, 0)}
        </Radio>
       );
      })}
    </Stack>
   </RadioGroup>
  </Box>
 );
};

export default VolumeSection;
