import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpService from "services/http-service";

export const downloadTemplate = createAsyncThunk(
  'dataloading/downloadTemplate',
  async () => {
    HttpService.get('/DataUploader/template', { responseType: 'blob' }).then((response:any) => {
      const url = window.URL.createObjectURL(new window.Blob([response]))
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Optipedia Data Upload.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      return true;
    })
  }
)

export const upload = createAsyncThunk(
  'dataloading/upload',
  async ({ payload }:any) => {
    const formData = new FormData();
    const file = payload[0] as any;
    formData.append('file', file);
    const response:any = await HttpService.post('DataUploader', formData);
    return response.success;
  }
)