import { createSlice } from "@reduxjs/toolkit";

import {
 journey,
 upload,
 download,
 components,
 subComponents,
 simulate,
 save,
 simulation,
 deletes,
 journeys,
 compare,
 simulationById,
 getBarChartSimulationId,
 getTreemapChartSimulationId,
 simulateBarChart,
 simulateTreemapChart,
} from "state/annualSpend/thunk";
interface historyTs {
 userName: string;
 userFullName: string;
 startUploadTime: string;
 endUploadTime: string;
}
interface InitialState {
 profile: Record<string, unknown>;
 loading: boolean;
 barLoading: boolean;
 treemapLoading: boolean;
 journey: any;
 journeyId: string;
 download: boolean;
 error: unknown;
 upload: boolean;
 file: any;
 uploadResponse: any;
 component: string[];
 subComponent: string[];
 componentResponse: any;
 subComponentsResponse: any;
 describeResponse: any;
 describePayload: any;
 simulate: any;
 simulation: any;
 compare: any;
 selection: string[];
 journeys: any;
 simulationById: any;
 barChartSimulationById: any;
 treemapChartSimulationById: any;
 searchQuery: string;
 simulateBarChart:any;
 simulateTreemapChart:any;
 valueToSend: any;
}

const initialState: InitialState = {
 profile: {},
 loading: false,
 barLoading:false,
treemapLoading:false,
 journey: {},
 journeyId: "",
 download: false,
 error: {},
 upload: false,
 file: {},
 uploadResponse: {},
 component: [],
 subComponent: [],
 componentResponse: {},
 subComponentsResponse: {},
 describeResponse: [],
 describePayload: {},
 simulate: {},
 simulation: {},
 compare: {},
 selection: [],
 journeys: {},
 simulationById: {},
 barChartSimulationById: {},
 treemapChartSimulationById: {},
 searchQuery: "",
 simulateBarChart: {},
 simulateTreemapChart: {},
 valueToSend: {}
};

const annualSpendSimulations = createSlice({
 name: "annualSpend",
 initialState,
 reducers: {
  clear: () => initialState,
  setSearchQuery: (state, action) => {
   const { value } = action.payload;
   state.searchQuery = value;
  },
  setValueToSend: (state, action) => {
    state.valueToSend = action.payload;
  },
  setJourneyId: (state, action) => {
   state.journeyId = action.payload;
  },
  setComponent: (state, action) => {
   state.component.push(action.payload);
  },
  setSubComponent: (state, action) => {
   state.subComponent.push(action.payload);
  },
  updateFileUpload: (state, action) => {
   state.file = action.payload;
  },
  removeArrayComponent: (state, action) => {
   function removeItem(array: any, item: string) {
    for (var i in array) {
     if (array[i] == item) {
      array.splice(i, 1);
      break;
     }
    }
   }
   removeItem(state.component, action.payload);
  },
  removeArraySubComponent: (state, action) => {
   function removeItem(array: any, item: string) {
    for (var i in array) {
     if (array[i] == item) {
      array.splice(i, 1);
      break;
     }
    }
   }
   removeItem(state.subComponent, action.payload);
  },
  setDescribe: (state) => {
   let newData: any = [];
   state.subComponentsResponse.map((item: any) => {
    const { details } = item;
    details?.map((data: any) => {
      const { costLabelName, identityCode, identityName, maxValue, minValue, sequence, additionalFields } = data;
      let dataFilter = state.subComponent
       .map((items) => items)
       .includes(identityCode);
      if (dataFilter) {
       newData.push({
        name: item.identityName,
        data: [
         {
          costLabelName,
          identityCode,
          identityName,
          maxValue,
          minValue,
          sequence,
          value: 0,
          byPercentage: true,
          additional: additionalFields?.map((item: any, index:number) => {
           return(
             {
               id: item.id,
               maxValue: item.maxValue,
               minValue: item.minValue,
               name: item.name,
               sequence: item.sequence,
               value: 0,
               byPercentage: true,
             }
           )
          })
         },
        ]
       });
      }
     });
   });
   let dataChecking = state.subComponentsResponse.filter((item: any) => {
    const { isShown } = item;
    return !isShown;
   });
   dataChecking.map((item: any) => {
    const { costLabelName, identityCode, identityName, maxValue, minValue, sequence, additionalFields } = item;
    newData.push({
     name: item.identityName,
     data: [
      {
       costLabelName,
       identityCode,
       identityName,
       maxValue,
       minValue,
       sequence,
       value: 0,
       byPercentage: true,
       additional: additionalFields?.map((item: any, index:number) => {
        return(
          {
            id: item.id,
            maxValue: item.maxValue,
            minValue: item.minValue,
            name: item.name,
            sequence: item.sequence,
            value: 0,
            byPercentage: true,
          }
        )
       })
      },
     ]
    });
   });
   state.describeResponse = newData;
  },
  setSelection: (state, action) => {
   state.selection.push(action.payload);
  },
  removeArraySelection: (state, action) => {
   function removeItem(array: any, item: string) {
    for (var i in array) {
     if (array[i] == item) {
      array.splice(i, 1);
      break;
     }
    }
   }
   removeItem(state.selection, action.payload);
  },
 },
 extraReducers: (builder) => {
  //  GET JOURNEY
  builder.addCase(journey.pending, (state) => {
   state.loading = true;
  });
  builder.addCase(journey.fulfilled, (state, action) => {
   state.loading = false;
   state.journey = action.payload.sort((a: any, b: any) =>
    a.journeyName.toLowerCase() > b.journeyName.toLowerCase() ? 1 : -1
   );
  });
  builder.addCase(journey.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   UPLAOD TEMPLATE
  builder.addCase(upload.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(upload.fulfilled, (state, action) => {
   state.upload = action.payload.success;
   state.file = action.meta;
   state.loading = false;
   state.uploadResponse = action.payload.result.map(
    ({ id, productName, demand, orderFrequency, thirdPartyId }: any) => {
     return {
      id,
      productName,
      demand,
      orderFrequency,
      thirdPartyId
     };
    }
   );
  });
  builder.addCase(upload.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   POST COMPONENT
  builder.addCase(components.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(components.fulfilled, (state, action) => {
   state.loading = false;
   state.componentResponse = action.payload.result;
  });
  builder.addCase(components.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   POST SUB COMPONENT
  builder.addCase(subComponents.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(subComponents.fulfilled, (state, action) => {
   state.loading = false;
   state.subComponentsResponse = action.payload.result;
  });
  builder.addCase(subComponents.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   SIMULATE
  builder.addCase(simulate.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(simulate.fulfilled, (state, action) => {
   state.loading = false;
   state.describePayload = action.meta.arg;
   state.simulate = action.payload.result;
  });
  builder.addCase(simulate.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   SAVE BUSINESS CASE
  builder.addCase(save.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(save.fulfilled, (state, action) => {
   state.loading = false;
   state.describePayload = action.meta.arg;
   state.describeResponse = action.payload.result;
  });
  builder.addCase(save.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   GET SIMULATION
  builder.addCase(simulation.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(simulation.fulfilled, (state, action) => {
   state.loading = false;
   state.simulation = action.payload;
  });
  builder.addCase(simulation.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  // DELETE SIMULATION
  builder.addCase(deletes.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(deletes.fulfilled, (state, action) => {
   state.loading = false;
  });
  builder.addCase(deletes.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
  //   COMPARE
  builder.addCase(compare.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(compare.fulfilled, (state, action) => {
   state.loading = false;
   state.compare = action.payload.result;
  });
  builder.addCase(compare.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
//   GET JOURNEY FOR COMPARE CASE 
builder.addCase(journeys.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
   builder.addCase(journeys.fulfilled, (state, action) => {
    state.loading = false;
    state.journeys = action.payload.result;
   });
   builder.addCase(journeys.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });
//    GET SIMULATION BY ID 
builder.addCase(simulationById.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
   builder.addCase(simulationById.fulfilled, (state, action) => {
    state.loading = false;
    state.simulationById = action.payload;
   });
   builder.addCase(simulationById.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });
   //    GET TREEMAP CHART SIMULATION BY ID 
builder.addCase(getTreemapChartSimulationId.pending, (state) => {
  state.treemapLoading = true;
  state.error = null;
 });
 builder.addCase(getTreemapChartSimulationId.fulfilled, (state, action) => {
  state.treemapLoading = false;
  state.treemapChartSimulationById = action.payload;
 });
 builder.addCase(getTreemapChartSimulationId.rejected, (state, action) => {
  state.treemapLoading = false;
  state.error = action.payload;
 });
    //    GET BAR CHART SIMULATION BY ID 
builder.addCase(getBarChartSimulationId.pending, (state) => {
  state.barLoading = true;
  state.error = null;
 });
 builder.addCase(getBarChartSimulationId.fulfilled, (state, action) => {
  state.barLoading = false;
  state.barChartSimulationById = action.payload;
 });
 builder.addCase(getBarChartSimulationId.rejected, (state, action) => {
  state.barLoading = false;
  state.error = action.payload;
 });
  //    POST SUMARY BAR CHART  
builder.addCase(simulateBarChart.pending, (state) => {
  state.barLoading = true;
  state.error = null;
 });
 builder.addCase(simulateBarChart.fulfilled, (state, action) => {
  state.barLoading = false;
  state.simulateBarChart = action.payload;
 });
 builder.addCase(simulateBarChart.rejected, (state, action) => {
  state.barLoading = false;
  state.error = action.payload;
 });
  //    POST SUMARY TREEMAP CHART  
builder.addCase(simulateTreemapChart.pending, (state) => {
  state.treemapLoading = true;
  state.error = null;
 });
 builder.addCase(simulateTreemapChart.fulfilled, (state, action) => {
  state.treemapLoading = false;
  state.simulateTreemapChart = action.payload;
 });
 builder.addCase(simulateTreemapChart.rejected, (state, action) => {
  state.treemapLoading = false;
  state.error = action.payload;
 });
 },
});
export const actions = {
 ...annualSpendSimulations.actions,
 journey,
 upload,
 download,
 components,
 subComponents,
 simulate,
 save,
 simulation,
 deletes,
 journeys,
 compare,
 simulationById,
 getBarChartSimulationId,
 getTreemapChartSimulationId,
 simulateBarChart,
 simulateTreemapChart
};
export const reducer = annualSpendSimulations.reducer;
