import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";

export const updateAsNewVersion = createAsyncThunk(
 "costAnalysis/UpdateAsNewVersion",
 async (objects: any) => {
  return await HttpService.put("CostAnalysis/UpdateAsNewVersion", objects);
 }
);

export const simulate = createAsyncThunk(
 "costAnalysis/simulate",
 async (objects: any) => {
  const config = {
   headers: {
    notUseToast: true,
   },
  };
  return await HttpService.put("CostAnalysis/simulate", objects, config);
 }
);

export const historyReport = createAsyncThunk(
 "costAnalysis/HistoryReport",
 async ({ costAnalysisId, costaAnalysisName, status }: any) => {
  return await HttpService.get(
   `CostAnalysis/historyreport`,
   {
    params: {costAnalysisId, status},
    responseType: "blob",
   }
  ).then((response: any) => {
   const url = window.URL.createObjectURL(new window.Blob([response]));
   const link = document.createElement("a");
   link.href = url;
   link.setAttribute("download", `${costaAnalysisName} - History Report.xlsx`);
   document.body.appendChild(link);
   link.click();
   link.remove();
  });
 }
);

export const simulationReport = createAsyncThunk(
 "costAnalysis/simulationReport",
 async (objects: any) => {
  return await HttpService.post(`CostAnalysis/SimulationReport`, objects, {
   responseType: "blob",
   headers: {
    notUseToast: true,
   },
  }).then((response: any) => {
   const url = window.URL.createObjectURL(new window.Blob([response]));
   const link = document.createElement("a");
   link.href = url;
   link.setAttribute("download", "Optipedia Simulation Report.xlsx");
   document.body.appendChild(link);
   link.click();
   link.remove();
  });
 }
);
