import { RootState } from "state/store";
import { FC } from "react";
import {
 Button,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 FormControl,
 useDisclosure,
 Input,
 Box
} from "@chakra-ui/react";
import { Formik } from "formik";
import OptipediaToast from "utils/notification-util";

import { useSelector, shallowEqual } from "react-redux";
import { useAppDispatch } from "hooks";
import { useHistory } from "react-router-dom";
import { actions as annualSpendAction } from "state/annualSpend/slice";

const SaveBusiness: FC = () => {
 const dispatch = useAppDispatch();
 const router = useHistory();
 const { isOpen, onOpen, onClose } = useDisclosure();

 let { describePayload, journeyId } = useSelector(
  (state: RootState) => ({
   describePayload: state.annualSpendSimulations.describePayload.payload,
   journeyId: state.annualSpendSimulations.journeyId,
  }),
  shallowEqual
 );

 return (
  <>
   <Button
    onClick={onOpen}
   >
    Save business case
   </Button>
   <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
     <ModalHeader textAlign="left">Business Case Name</ModalHeader>
     <ModalCloseButton />
     <Formik
      enableReinitialize
      initialValues={{
       businessCaseName: "",
       costAnalysises: "",
       simulationValues: "",
      }}
      onSubmit={async (values) => {
       try {
        const response: any = await dispatch(
         annualSpendAction.save({
          journeyId,
          costAnalysises: describePayload.costAnalysises,
          simulationValues: describePayload.simulationValues,
          businessCaseName: values.businessCaseName,
         })
        );
        if (!response.error) {
         router.push("/annual-spend-simulations");
         dispatch(annualSpendAction.clear());
        }
       } catch (err: any) {
        OptipediaToast.Error(err.message);
       }
      }}
     >
      {({
       values,
       errors,
       touched,
       handleChange,
       handleBlur,
       handleSubmit,
       isSubmitting,
      }) => (
       <form onSubmit={handleSubmit}>
        <ModalBody pb={6}>
         <FormControl>
          <Input
           name="businessCaseName"
           value={values.businessCaseName}
           onChange={handleChange}
           bg="#E6EAF1"
           size="lg"
          />
         </FormControl>
        </ModalBody>
        <Box textAlign={'center'} mb="1rem">
        <Button
         type="submit"
         isDisabled={!values.businessCaseName}
        >
         Complete
        </Button>
        </Box>
       </form>
      )}
     </Formik>
    </ModalContent>
   </Modal>
  </>
 );
};
export default SaveBusiness;
