import { ReactElement, useState, useRef, useEffect, useMemo } from "react";
import { Box, Flex, Spacer } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { RootState } from "state/store";
import Swal from "sweetalert2";
import s from "shortid";
import { useSelector, shallowEqual } from "react-redux";
import { useAppDispatch } from "hooks";
import { FieldArray, Form, Formik } from "formik";
import debounce from "lodash/debounce";
import TextInput from "components/TextInput";
import SelectMasterData from "components/SelectPredefinedData";
import OptipediaToast from "utils/notification-util";
import { actions as pricingCostDriverAction } from "state/pricings/editCostDrivers/slice";
import unCollapse from "assets/images/upArrow.png";
import collapse from "assets/images/downArrow.png";
import DeleteIcon from "assets/images/Delete.png";
import AddIcon from "assets/images/plus-icon.png";
import {
 Table,
 Thead,
 Tbody,
 Tr,
 Th,
 Td,
 TableContainer,
 HStack,
} from "@chakra-ui/react";
// test test
const SimulateTableCostDriver = ({
 costDriver,
 paramName,
 level,
}: any): ReactElement => {
 const dispatch = useAppDispatch();
 const [isShowTableDetail, toogleShowTableDetail] = useState(true);
 const [oldValue, setOldValue] = useState<boolean>(false);
 const [isCalculation, setCalculation] = useState<boolean>(false);
 const [posisitionValue, setPosisitionValue] = useState<any>("");
 const [test12, setTest12] = useState<any>();
 const [nameId, setNameId] = useState<any>("");
 const [widthBox, setWidthBox] = useState(0);
 const ref = useRef<HTMLDivElement>(null);

 const [initialValues, setInitialValues] = useState<any>();

 const {
  isEditing,
  detailPerVolumeId,
  dataToSendUpdates,
  dataToSendadds,
  temporaryDataCostDriver,
  dataToSend,
  valueIsIncludeCalculation,
  constDriversOri,
  tmpChangeRow,
 } = useSelector(
  (state: RootState) => ({
   isEditing: state.editPricingCostDrivers.isEditing,
   detailPerVolumeId: state.editPricingCostDrivers.detailPerVolumeId.result,
   dataToSendUpdates: state.editPricingCostDrivers.dataToSend.updates,
   dataToSendadds: state.editPricingCostDrivers.dataToSend.adds,
   dataToSend: state.editPricingCostDrivers.dataToSend,
   temporaryDataCostDriver:
    state.editPricingCostDrivers.temporaryDataCostDriver,
   valueIsIncludeCalculation:
    state.editPricingCostDrivers.valueIsIncludeCalculation,
   constDriversOri: state.editPricingCostDrivers.constDriversOri,
   tmpChangeRow: state.editPricingCostDrivers.tmpChangeRow,
  }),
  shallowEqual
 );

 const onToogleShowTableDetail = () => {
  toogleShowTableDetail(!isShowTableDetail);
 };

 useEffect(() => {
  if (ref.current && ref.current.offsetWidth) {
   setWidthBox(ref.current.offsetWidth);
  }
 }, [ref.current]);

 useEffect(() => {
  const costDrivers: any = [];
  Array.isArray(costDriver?.data) &&
   costDriver?.data?.map((item: any) => {
    var dataItem = Object.assign({}, item);
    let itemIndex = tmpChangeRow.find((item: any) => {
     return item.id == dataItem.id;
    });
    dataItem.unitCost =
     item?.unitCost === 0
      ? 0
      : isNaN(item?.unitCost)
      ? item?.unitCost === NaN
        ? ""
        : item?.unitCost?.replace("%", "")
      : item?.unitCost;
    dataItem.bgColor = itemIndex?.addBgColor ? "#BBDEFB" : "#ffffff";
    costDrivers.push(dataItem);
   });
  setInitialValues({
   customFields: costDrivers,
  });
 }, [costDriver]);

 const debounceRecalculation = useMemo(
  () =>
   debounce(async (dataToSend: any) => {
    await dispatch(pricingCostDriverAction.simulate(dataToSend));
    await dispatch(pricingCostDriverAction.setValueIsIncludeCalculation(false));
    setOldValue(false);
    setPosisitionValue("");
   }, 1000),
  []
 );
 useEffect(() => {
  if (valueIsIncludeCalculation && oldValue) {
   debounceRecalculation(dataToSend);
  }
 }, [valueIsIncludeCalculation]);

 const findOneCostDriver =
  constDriversOri &&
  constDriversOri?.data &&
  constDriversOri?.data.find(
   (item: any) => item.costDriverId === costDriver.costDriverId
  );

 const isColumnNumeric = (headerName: string, tableData: any) => {
  let result = false;
  for (let i = 0; i < tableData.length; i++) {
   let itemData = tableData[i];

   let itemText =
    headerName === "unitCost"
     ? isNaN(itemData[headerName])
       ? itemData[headerName] !== NaN
         ? ""
         : itemData[headerName].replace("%", "")
       : itemData[headerName] || ""
     : itemData[headerName] || "";
   let isColumnNumeric = !isNaN(itemText);
   if (isColumnNumeric) {
    result = true;
   }
   break;
  }
  return result;
 };

 const debouncedNotCustomField = useMemo(
  () =>
   debounce(
    (
     name: any,
     val: any,
     latestDataToSend: any,
     costAnalysisPerVolumeId?: any,
     costDriverId?: any,
     costDriverData?: any,
     identityName?: string
    ) => {
     var existingDataToSend = latestDataToSend.find((item: any) => {
      return (
       item.costDriverId === costDriverId &&
       item.costAnalysisPerVolumeId === costAnalysisPerVolumeId
      );
     });
     if (!existingDataToSend) {
      var customFieldData = [] as any;
      costDriverData.get("customFields").map((customField: any) => {
       customFieldData.push({
        customFieldId: customField.id,
        value: customField.value,
       });
      });
      var data = {
       costAnalysisPerVolumeId: costAnalysisPerVolumeId,
       costDriverId: costDriverId,
       name: costDriverData.get("name"),
       description: costDriverData.get("description"),
       unitCost: costDriverData.get("unitCost"),
       customFields: customFieldData,
       identityName,
      } as any;
      data[name] = val;
      dispatch(
       pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(data)
      );
     } else {
      var data = Object.assign({}, existingDataToSend);
      data[name] = val;
      dispatch(
       pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(data)
      );
     }
    },
    10
   ),
  []
 );

 const debouncedCustomField = useMemo(
  () =>
   debounce(
    (
     updatedCustomFieldId: any,
     value: any,
     dataToSendUpdated: any,
     costAnalysisPerVolumeId?: any,
     costDriverId?: any,
     costDriverData?: any
    ) => {
     var existingDataToSend = dataToSendUpdated.find((item: any) => {
      return (
       item.costDriverId === costDriverId &&
       item.costAnalysisPerVolumeId === costAnalysisPerVolumeId
      );
     });
     if (!existingDataToSend) {
      var customFieldData = [] as any;
      costDriverData.get("customFields").map((customField: any) => {
       var newCustomField = {
        customFieldId: customField.id,
        value: customField.value,
       };
       if (updatedCustomFieldId === newCustomField.customFieldId) {
        newCustomField.value = value;
       }

       customFieldData.push(newCustomField);
      });

      var data = {
       costAnalysisPerVolumeId: costAnalysisPerVolumeId,
       costDriverId: costDriverId,
       name: costDriverData.get("name"),
       description: costDriverData.get("description"),
       unitCost: costDriverData.get("unitCost"),
       customFields: customFieldData,
      } as any;
      dispatch(
       pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(data)
      );
     } else {
      var data = Object.assign({}, existingDataToSend);
      var customFieldData = [] as any;
      data.customFields.map((customField: any) => {
       var newCustomField = Object.assign({}, customField);
       if (customField.customFieldId === updatedCustomFieldId) {
        newCustomField.value = value;
       }

       customFieldData.push(newCustomField);
      });
      data["customFields"] = customFieldData;

      dispatch(
       pricingCostDriverAction.directSetEditOneToTemporaryDataCostDriver(data)
      );
     }
    },
    0
   ),
  []
 );

 const debouncedNewCustomField = useMemo(
  () =>
   debounce(
    (
     updatedCustomFieldId: any,
     value: any,
     dataToSendaddValues: any,
     costAnalysisPerVolumeId?: any,
     costDriverId?: any,
     costDriverData?: any,
     name?: string
    ) => {
     var existingDataToSend = dataToSendaddValues.find((item: any) => {
      return item.isNew === true;
     });
     const findIndex = temporaryDataCostDriver[0]?.data.findIndex(
      (el: any) => el.costDriverId == costDriverId
     );
     if (!existingDataToSend) {
      var customFieldData = [] as any;
      costDriverData.get("customFields").map((customField: any) => {
       var newCustomField = {
        customFieldConfigurationId: customField.id,
        value: customField.value,
       };
       if (updatedCustomFieldId === newCustomField.customFieldConfigurationId) {
        newCustomField.value = value;
       }

       customFieldData.push(newCustomField);
      });

      var data = {
       costAnalysisPerVolumeId: costAnalysisPerVolumeId,
       costDriverId: costDriverId,
       name: costDriverData.get("name"),
       description: costDriverData.get("description"),
       unitCost: costDriverData.get("unitCost"),
       customFields: customFieldData,
      } as any;

      var dataToUpdate = {
       value: value,
       index: findIndex,
       changeCustomField: true,
       customFields: costDriverData.get("customFields"),
       name,
       id: s.generate(),
       description: "",
       currency: costDriverData.get("currency"),
       unitCost: 0,
       totalCost: costDriverData.get("totalCost"),
      };

      dispatch(
       pricingCostDriverAction.replaceTemporaryDataCostDriverByFieldName(
        dataToUpdate
       )
      );

      dispatch(
       pricingCostDriverAction.directSetAddOneToTemporaryDataCostDriver(data)
      );
     } else {
      var data = Object.assign({}, existingDataToSend);
      var customFieldData = [] as any;
      data.customFields.map((customField: any) => {
       var newCustomField = Object.assign({}, customField);
       if (customField.customFieldConfigurationId === updatedCustomFieldId) {
        newCustomField.value = value;
       }

       customFieldData.push(newCustomField);
      });
      data["customFields"] = customFieldData;

      var dataToUpdate = {
       value: value,
       index: findIndex,
       changeCustomField: true,
       customFields: costDriverData.get("customFields"),
       name,
       id: s.generate(),
       description: "",
       currency: costDriverData.get("currency"),
       unitCost: 0,
       totalCost: costDriverData.get("totalCost"),
      };

      dispatch(
       pricingCostDriverAction.replaceTemporaryDataCostDriverByFieldName(
        dataToUpdate
       )
      );

      dispatch(
       pricingCostDriverAction.directSetAddOneToTemporaryDataCostDriver(data)
      );
     }
    },
    0
   ),
  []
 );

 const debouncedNewNotCustomField = useMemo(
  () =>
   debounce(
    (
     name: any,
     val: any,
     latestDataToSend: any,
     costAnalysisPerVolumeId?: any,
     costDriverId?: any,
     costDriverData?: any
    ) => {
     var existingDataToSend = latestDataToSend.find((item: any) => {
      return item.isNew === true;
     });

     const findIndex = temporaryDataCostDriver[0]?.data.findIndex(
      (el: any) => el.costDriverId == costDriverId
     );
     var dataToUpdate = {
      value: val,
      index: findIndex,
      fieldName: name,
     };

     if (!existingDataToSend) {
      var customFieldData = [] as any;
      costDriverData.get("customFields").map((customField: any) => {
       customFieldData.push({
        customFieldConfigurationId: customField.id,
        value: customField.value,
       });
      });
      var data = {
       costAnalysisPerVolumeId: costAnalysisPerVolumeId,
       costDriverId: costDriverId,
       name: costDriverData.get("name"),
       description: costDriverData.get("description"),
       unitCost: costDriverData.get("unitCost"),
       customFields: customFieldData,
       isNew: true,
       tmpId: s.generate(),
      } as any;
      data[name] = val;
      dispatch(
       pricingCostDriverAction.replaceTemporaryDataCostDriverByFieldName(
        dataToUpdate
       )
      );
      dispatch(
       pricingCostDriverAction.directSetAddOneToTemporaryDataCostDriver(data)
      );
     } else {
      var data = Object.assign({}, existingDataToSend);
      data[name] = val;
      data["isNew"] = true;
      dispatch(
       pricingCostDriverAction.replaceTemporaryDataCostDriverByFieldName(
        dataToUpdate
       )
      );
      dispatch(
       pricingCostDriverAction.directSetAddOneToTemporaryDataCostDriver(data)
      );
     }
    },
    0
   ),
  []
 );
 useEffect(() => {
  
  dispatch(
   pricingCostDriverAction.setCostAnalysisPerVolumeId(detailPerVolumeId.id)
  );
 }, [detailPerVolumeId]);

 const onChangeFieldArray = (
  val: any,
  data: any,
  isWhat: string,
  name: string,
  costDriverData: any,
  costDriverId: string,
  identityName?: string
 ) => {
  if (isWhat === "isCustomField") {
   debouncedCustomField(
    data.id,
    val,
    dataToSendUpdates,
    detailPerVolumeId.id,
    costDriverId,
    costDriverData
   );
  } else {
   const isNotCustomFieldName =
    name === "name" || name === "description" || name === "unitCost";
   if (isNotCustomFieldName) {
    debouncedNotCustomField(
     name,
     val,
     dataToSendUpdates,
     detailPerVolumeId.id,
     costDriverId,
     costDriverData,
     identityName
    );
   }
  }
 };

 const onChangeFieldArrayNew = (
  val: any,
  data: any,
  isWhat: string,
  name: string,
  costDriverData: any,
  costDriverId: string
 ) => {
  if (isWhat === "isCustomField") {
   debouncedNewCustomField(
    data.id,
    val,
    dataToSendadds,
    detailPerVolumeId.id,
    costDriverId,
    costDriverData,
    name
   );
  } else {
   debouncedNewNotCustomField(
    name,
    val,
    dataToSendadds,
    detailPerVolumeId.id,
    costDriverId,
    costDriverData
   );
  }
 };
 return (
  <Formik
   enableReinitialize
   initialValues={initialValues}
   onSubmit={async (values) => {}}
   validateOnChange
  >
   {({ values, setFieldValue }) => (
    <Form>
     <Box
      w="100%"
      border="1px solid #D7DBE2"
      borderRadius="40px"
      h={!isShowTableDetail ? "4rem" : "100%"}
      mb="2rem"
      position="relative"
     >
      <Flex ref={ref} spacing="75%" alignItems="baseline">
       <Box as="p" fontWeight="bold" ml="1.5rem" mt="1rem">
        {costDriver.identityName}
       </Box>
       <Spacer />
       <Box pr="2rem">
        <HStack spacing="24px">
         <Box>
          {!isShowTableDetail && (
           <Image
            src={unCollapse}
            onClick={onToogleShowTableDetail}
            pt="0.8rem"
            cursor="pointer"
            alt="Bulp"
           />
          )}
          {isShowTableDetail && (
           <Image
            src={collapse}
            onClick={onToogleShowTableDetail}
            pt="0.8rem"
            cursor="pointer"
            alt="Bulp"
           />
          )}
         </Box>
        </HStack>
       </Box>
      </Flex>
      {isShowTableDetail && (
       <Box pl="2rem" pr="2rem" overflowX="auto" maxW={`${widthBox}px`}>
        <FieldArray
         name="customFields"
         render={({ push, replace, remove }) => {
          return (
           <>
            <TableContainer
             sx={{
              "&::-webkit-scrollbar": { width: "6px", height: "6px" },
              "&::-webkit-scrollbar-track": { width: "6px", height: "6px" },
              "&::-webkit-scrollbar-thumb": {
               borderRadius: "24px",
               backgroundColor: "#0072CF",
              },
             }}
            >
             <Table variant="simple">
              {Array.isArray(costDriver.headers) &&
               costDriver.headers.map((row: any, index: any) => {
                if (index < 1) {
                 return (
                  <Thead key={index}>
                   <Tr>
                    {costDriver.headers.length > 0 &&
                     costDriver.headers.map(
                      ({ name, label, align }: any, index: number) => {
                       return (
                        <Th
                         key={index}
                         textTransform="capitalize"
                         color="#9DA1A7"
                         fontSize="16px"
                         minW={
                          isColumnNumeric(name, costDriver.data) && isEditing
                           ? "120px"
                           : ""
                         }
                         sx={{
                          textAlign:
                           name === "name" || name === "description"
                            ? "left"
                            : align
                            ? align
                            : name === "unitCost" ||
                              name === "totalCost" ||
                              isColumnNumeric(name, costDriver.data)
                            ? "right"
                            : "left",
                         }}
                        >
                         {label}
                        </Th>
                       );
                      }
                     )}
                   </Tr>
                  </Thead>
                 );
                }
               })}
              {Array.isArray(values?.customFields) &&
               values?.customFields.map((row: any, index: any) => {
                const currentRow = new Map(Object.entries(row));

                return (
                 <Tbody key={index}>
                  <Tr>
                   {costDriver.headers.map(({ name }: any) => {
                    const isIncludeInCalculation = values.customFields[
                     index
                    ].customFields.find(
                     (i: any) =>
                      i.name == name && i.isIncludeInCalculation === true
                    );

                    const predefinedData = values.customFields[
                     index
                    ].customFields.find(
                     (i: any) => i.name == name && i.predefinedData !== null
                    );
                    const staticRow =
                     findOneCostDriver &&
                     findOneCostDriver?.data &&
                     findOneCostDriver?.data[index] &&
                     new Map(Object.entries(findOneCostDriver.data[index]));

                    return (
                     <>
                      <Td key={name}>
                       {name === "currency" || name === "totalCost" ? (
                        <Box
                         sx={{
                          textAlign: name === "totalCost" ? "right" : "left",
                         }}
                        >
                         {currentRow && name && currentRow.get(name)}
                        </Box>
                       ) : predefinedData ? (
                        <SelectMasterData
                         name={`customFields[${index}].${name}`}
                         id={`customFields[${index}].${name}`}
                         dataId={row.id}
                         fieldName={name}
                         isDisabled={
                          name !== "unitCost" && !isIncludeInCalculation
                         }
                         code={
                          predefinedData?.predefinedData?.configuration
                           ?.identityName
                         }
                         onChange={(inputElement) => {
                          const identityName = costDriver.identityName;
                          var fieldName =
                           inputElement.currentTarget.dataset["fieldname"];

                          var fieldId = inputElement.currentTarget.dataset[
                           "dataid"
                          ] as string;

                          var customField = values.customFields[
                           index
                          ].customFields.find((i: any) => i.name == fieldName);
                          var isCustomField = customField !== undefined;

                          var fieldValue = inputElement.currentTarget.value;

                          if (!row.isNew) {
                           if (isCustomField) {
                            onChangeFieldArray(
                             fieldValue,
                             customField,
                             "isCustomField",
                             name,
                             currentRow,
                             fieldId,
                             identityName
                            );
                           } else {
                            onChangeFieldArray(
                             fieldValue,
                             fieldId,
                             "isNotCustomField",
                             name,
                             currentRow,
                             fieldId,
                             identityName
                            );
                           }
                          } else {
                           if (isCustomField) {
                            onChangeFieldArrayNew(
                             fieldValue,
                             customField,
                             "isCustomField",
                             name,
                             currentRow,
                             fieldId
                            );
                           } else {
                            onChangeFieldArrayNew(
                             fieldValue,
                             fieldId,
                             "isNotCustomField",
                             name,
                             currentRow,
                             fieldId
                            );
                           }
                          }
                         }}
                        />
                       ) : (
                        <Box>
                         <TextInput
                          sx={{
                           textAlign:
                            isIncludeInCalculation !== undefined ||
                            name === "unitCost" ||
                            name === "totalCost"
                             ? "right"
                             : "left",
                           bgColor:
                            staticRow?.get(name) !== currentRow.get(name) &&
                            values?.customFields[index].bgColor,
                           ":disabled": {
                            background: "#ffffff",
                           },
                           ":active": {
                            background: "#ffffff",
                           },
                           ":focus": {
                            background: "#ffffff",
                           },
                           ":focus-within": {
                            background: "#ffffff",
                           },
                           ":target": {
                            background: "#ffffff",
                           },
                          }}
                          name={`customFields[${index}].${name}`}
                          id={`customFields[${index}].${name}`}
                          dataId={row.id}
                          isDisabled={
                           name !== "unitCost" &&
                           !isIncludeInCalculation &&
                           !row.isNew
                          }
                          fieldName={name}
                          minW="200px"
                          onBlur={async () => {
                           setCalculation(false);
                           if (oldValue) {
                            if (isIncludeInCalculation || name === "unitCost") {
                             await dispatch(
                              pricingCostDriverAction.setValueIsIncludeCalculation(
                               true
                              )
                             );
                             setOldValue(false);
                             if (
                              staticRow?.get(name) !== currentRow?.get(name)
                             ) {
                              await dispatch(
                               pricingCostDriverAction.settmpChangeRow({
                                id: row.id,
                                addBgColor: true,
                               })
                              );
                             } else {
                              await dispatch(
                               pricingCostDriverAction.settmpChangeRow({
                                id: row.id,
                                addBgColor: false,
                               })
                              );
                             }
                            }
                           }
                          }}
                          onKeyPress={(event) => {
                           if (
                            (isIncludeInCalculation || name === "unitCost") &&
                            !/[0-9\,\.]/.test(event.key)
                           ) {
                            event.preventDefault();
                           }
                          }}
                          onChange={(inputElement) => {
                           setOldValue(true);
                           const identityName = costDriver.identityName;
                           var fieldName =
                            inputElement.currentTarget.dataset["fieldname"];
                           var fieldId = inputElement.currentTarget.dataset[
                            "dataid"
                           ] as string;
                           var customField = values.customFields[
                            index
                           ].customFields.find((i: any) => i.name == fieldName);
                           var isCustomField = customField !== undefined;
                           var fieldValue =
                            name === "unitCost"
                             ? parseFloat(inputElement.currentTarget.value)
                             : inputElement.currentTarget.value;
                           if (!row.isNew) {
                            if (isCustomField) {
                             onChangeFieldArray(
                              fieldValue,
                              customField,
                              "isCustomField",
                              name,
                              currentRow,
                              fieldId,
                              identityName
                             );
                            } else {
                             // disini
                             onChangeFieldArray(
                              fieldValue,
                              fieldId,
                              "isNotCustomField",
                              name,
                              currentRow,
                              fieldId,
                              identityName
                             );
                            }
                           } else {
                            if (isCustomField) {
                             onChangeFieldArrayNew(
                              fieldValue,
                              customField,
                              "isCustomField",
                              name,
                              currentRow,
                              fieldId
                             );
                            } else {
                             onChangeFieldArrayNew(
                              fieldValue,
                              fieldId,
                              "isNotCustomField",
                              name,
                              currentRow,
                              fieldId
                             );
                            }
                           }
                          }}
                         />
                        </Box>
                       )}
                      </Td>
                     </>
                    );
                   })}
                   {level !== 30 ||
                    (paramName !== "profit" && (
                     <Td w="5%">
                      <Image
                       src={DeleteIcon}
                       w="32px"
                       maxW="max-content"
                       cursor={
                        valueIsIncludeCalculation ? "not-allowed" : "pointer"
                       }
                       onClick={async () => {
                        if (!valueIsIncludeCalculation) {
                         Swal.fire({
                          showCancelButton: true,
                          html: "Are you sure you want to remove this record?",
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                         }).then(async (result) => {
                          if (result.isConfirmed) {
                           if (costDriver.data[index]) {
                            remove(index);
                            const findIndex =
                             temporaryDataCostDriver[0]?.data.findIndex(
                              (el: any) =>
                               el.costDriverId == costDriver.costDriverId
                             );
                            const dataToRemove = {
                             index: findIndex,
                             indexToDelete: index,
                            };
                            await dispatch(
                             pricingCostDriverAction.removeOneTemporaryDataCostDriver(
                              dataToRemove
                             )
                            );
                            const toDelete = {
                             costAnalysisPerVolumeId: detailPerVolumeId.id,
                             costDriverId: costDriver.data[index].id,
                            };
                            await dispatch(
                             pricingCostDriverAction.directSetDeleteOneToTemporaryDataCostDriver(
                              toDelete
                             )
                            );
                           } else {
                            remove(index);
                           }
                          }
                         });
                        }
                       }}
                      />
                     </Td>
                    ))}
                  </Tr>
                 </Tbody>
                );
               })}
             </Table>
            </TableContainer>
            {level !== 30 ||
             (paramName !== "profit" && (
              <Box
               float="right"
               pb="5"
               pt="5"
               position="absolute"
               right="4rem"
               top="-1.2rem"
              >
               <Image
                src={AddIcon}
                cursor={valueIsIncludeCalculation ? "not-allowed" : "pointer"}
                onClick={(e) => {
                 if (!valueIsIncludeCalculation) {
                  e.preventDefault();
                  const arr: any = [];
                  let abc: any = {};
                  const latestArray =
                   values?.customFields[values?.customFields.length - 1];

                  if (latestArray) {
                   const entries = Object.entries(latestArray);
                   let isNull = false;
                   entries.forEach(([key, value]) => {
                    const staticValue =
                     key === "currency" ||
                     key === "totalCost" ||
                     key === "isNew" ||
                     key === "id";

                    const isCustomField = key === "customFields";
                    if (!staticValue && value === "") {
                     isNull = true;
                    }
                   });

                   values?.customFields.map((row: any, index: any) => {
                    const currentRow = new Map(Object.entries(row));

                    costDriver.headers.map(({ name }: any) => {
                     arr.push({
                      [name]:
                       name === "currency" || name === "totalCost"
                        ? currentRow.get(name)
                        : "",
                     });
                    });

                    arr.map((obj: any, item: any) => {
                     const objKey = Object.keys(obj)[0];
                     abc[objKey] =
                      objKey === "currency" ? currentRow.get(objKey) : "";
                    });
                    const newCustomFields = [] as any;
                    const customFields = currentRow.get("customFields");
                    Array.isArray(customFields) &&
                     customFields.map((item) => {
                      newCustomFields.push({
                       id: item.customFieldConfigurationId,
                       value: 0,
                       name: item.name,
                       predefinedData: item?.predefinedData,
                       isIncludeInCalculation: item?.isIncludeInCalculation,
                       customFieldConfigurationId:
                        item?.customFieldConfigurationId,
                      });
                     });
                    abc.isNew = true;
                    abc.id = costDriver.costDriverId; //currentRow.get("id");
                    abc.customFields = newCustomFields;
                   });
                   if (isNull) {
                    OptipediaToast.Warning(
                     "First complete the newly added please."
                    );
                   } else {
                    push(abc);
                    const findIndex =
                     temporaryDataCostDriver[0]?.data.findIndex(
                      (el: any) => el.identityName == costDriver.identityName
                     );
                    abc.index = findIndex;
                    abc.totalCost = 0;
                    abc.unitCost = 0;
                    dispatch(
                     pricingCostDriverAction.setAddTemporaryDataCostDriver(abc)
                    );
                   }
                  } else {
                   costDriver.headers.map(({ name }: any) => {
                    arr.push({
                     [name]:
                      name === "currency" || name === "totalCost" ? "" : "",
                    });
                   });

                   arr.map((obj: any) => {
                    const objKey = Object.keys(obj)[0];
                    abc[objKey] =
                     objKey === "currency"
                      ? detailPerVolumeId?.costAnalysis?.currencyCode
                      : "";
                   });

                   abc.isNew = true;
                   abc.id = costDriver.costDriverId;
                   abc.customFields = [];
                   push(abc);
                   const findIndex = temporaryDataCostDriver[0]?.data.findIndex(
                    (el: any) => el.identityName == costDriver.identityName
                   );
                   abc.index = findIndex;
                   abc.totalCost = 0;
                   abc.unitCost = 0;
                   dispatch(
                    pricingCostDriverAction.setAddTemporaryDataCostDriver(abc)
                   );
                  }
                 }
                }}
                pt="0.8rem"
                alt="Bulp"
               />
              </Box>
             ))}
           </>
          );
         }}
        />
       </Box>
      )}
     </Box>
    </Form>
   )}
  </Formik>
 );
};

export default SimulateTableCostDriver;
