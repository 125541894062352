import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "services/http-service";
import { parameterDto, objectParams } from "state/approval/dtos";

export const approvalList = createAsyncThunk(
 "approval/approvalList",
 async (parameterDto: parameterDto) => {
  return await HttpService.get("CostApproval", { params: parameterDto });
 }
);
export const pendingApprovalList = createAsyncThunk(
 "approval/pendingApprovalList",
 async (objectParams: objectParams) => {
  return await HttpService.post("CostApproval/CostAnalysises", objectParams, {
   headers: {
    notUseToast: true,
   },
  });
 }
);
export const approvalSubmit = createAsyncThunk(
 "approval/approvalSubmit",
 async (payload: any) => {
  const data = payload as any;
  return await HttpService.post("CostApproval/Approve", data);
 }
);
export const approvalReject = createAsyncThunk(
 "approval/approvalReject",
 async (payload: any) => {
  const data = payload as any;
  return await HttpService.post("CostApproval/Reject", data);
 }
);

export const getApprovalStatus = createAsyncThunk(
 "getApprovalStatus",
 async () => {
  return await HttpService.get(`CostApproval/GetApprovalStatus`);
 }
);

export const getApprovalConfiguration = createAsyncThunk(
    "getApprovalCOnfiguration",
    async () => {
     return await HttpService.get(`/CostApproval/ProductConfigurations`);
    }
   );

export const getApprovalThirdParty = createAsyncThunk(
 "getApprovalThirdParty",
 async (searchKey: string) => {
  let params = {};
  if (searchKey) {
   params = { searchKey: searchKey };
  }
  return await HttpService.get(`CostApproval/ThirdParties`, {
   params: params,
   headers: {
    notUseToast: true,
   },
  });
 }
);

export const approvalAnalyze = createAsyncThunk(
 "approvalAnalyze",
 async (payload: any) => {
  const data = payload as any;
  const response: any = await HttpService.post("CostApproval/Analyze", data, {
   headers: {
    notUseToast: true,
   },
  });
  return response;
 }
);

export const approvalAnalyzeBarChart = createAsyncThunk(
 "approvalAnalyzeBarChart",
 async ({ payload, level, isCurrent }: any) => {
  const data = payload as any;
  const response: any = await HttpService.post(
   "CostApproval/Analyze/charts",
   data,
   {
    params: {
     isCurrent,
     level,
    },
    headers: {
     notUseToast: true,
    },
   }
  );
  return response;
 }
);

export const approvalAnalyzeMapChart = createAsyncThunk(
 "approvalAnalyzeMapChart",
 async ({ payload, level, isCurrent }: any) => {
  const data = payload as any;
  const response: any = await HttpService.post(
   "CostApproval/Analyze/charts",
   data,
   {
    params: {
     isCurrent,
     level,
    },
    headers: {
     notUseToast: true,
    },
   }
  );
  return response;
 }
);
