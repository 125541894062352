import { createSlice } from "@reduxjs/toolkit";

import {
 isStarted,
 getStarted,
} from "state/started/thunk";
interface historyTs {
 userName: string;
 userFullName: string;
 startUploadTime: string;
 endUploadTime: string;
}
interface InitialState {
 histories: historyTs;
 error: unknown;
 loading: boolean;
 loadingSubmit: boolean;
 started: boolean;
}
const initialState: InitialState = {
 histories: {
  userName: "",
  userFullName: "",
  startUploadTime: "",
  endUploadTime: "",
 },
 error: null,
 loading: false,
 loadingSubmit: false,
 started: false,
};

const started = createSlice({
 name: "started",
 initialState,
 reducers: {
  clear: () => initialState,
 },
 extraReducers: (builder) => {
  //   CHECK THIRDPARTY STATUS
  builder.addCase(isStarted.pending, (state) => {
   state.loading = true;
   state.error = null;
  });

  builder.addCase(isStarted.fulfilled, (state, action) => {
   state.loading = false;
   state.started = action.payload;
  });

  builder.addCase(isStarted.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  //   THIRDPARTY STARTED
  builder.addCase(getStarted.pending, (state) => {
   state.loadingSubmit = true;
   state.error = null;
  });

  builder.addCase(getStarted.fulfilled, (state, action) => {
   state.loadingSubmit = false;
   state.started = action.payload;
  });

  builder.addCase(getStarted.rejected, (state, action) => {
   state.loadingSubmit = false;
   state.error = action.payload;
  });
 },
});
export const actions = {
 ...started.actions,
 isStarted,
 getStarted,
};

export const reducer = started.reducer;
